
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link, Switch, } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment, } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Tooltip, OverlayTrigger, Image } from 'react-bootstrap';
import { ArrowsAngleExpand, ArrowsFullscreen, InfoCircle, FileText, ShieldShaded, Pen, PauseCircle, PlayCircle, People, HeartFill, Collection, Grid, ThreeDots, Stack, List, Files, FolderPlus, FolderCheck, Folder, ChevronRight } from 'react-bootstrap-icons'
import { BrowserView, MobileView } from 'react-device-detect';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import Axios from 'axios'
import { render } from '@testing-library/react';

import DepartmentOfTheInterior from './DepartmentOfTheInterior';


import{ whiteHouseSearch, createPresidentialProclamations, createExecutiveOrders, createWhiteHouseDocuments, createWhiteHouseFeed,
  createWhiteHouseFeedMobile,createDepartmentOfState,
  createDepartmentOfDefense, 
  createDepartmentOfJustice, createDepartmentOfTheInterior,
  createDepartmentOfAgriculture, createDepartmentOfCommerce,
  createDepartmentOfCommerceMobile, createDepartmentOfLabor,
  createDepartmentOfHealthAndHumanServices, createDepartmentOfHousingAndUrbanDevelopment, createDepartmentOfEducation
  ,  createDepartmentOfVeteransAffairs, createDepartmentOfHomelandSecurity
  , createEnvironmentalProtectionAgency,
  createDepartmentOfTreasury, createNationalScienceFoundation, 
   createEnvironmentalProtectionAgencyMobile, createNationalAeronauticsAndSpaceAdministration, Speak, createFederalRegisterDefenseDepartment, createFederalRegisterStateDepartment, createFederalRegisterTreasuryDepartment, createFederalRegisterJusticeDepartment, createFederalRegisterInteriorDepartment, createFederalRegisterAgricultureDepartment, createFederalRegisterCommerceDepartment, createFederalRegisterLaborDepartment, createFederalRegisterHealthAndHumanServicesDepartment, createFederalRegisterHousingAndUrbanDevelopmentDepartment, createFederalRegisterHomelandSecurityDepartment, createFederalRegisterEducationDepartment, createFederalRegisterVeteransAffairsDepartment,
   CreateFeedItem, CreateFeedItemBillUpdates, CreateFeedItemWithDocButton,
   CreateFeedItemPresidentialDocuments, CreateFeedItemExecutiveOrders, CreateFeedItemCongressionalReports, CreateFeedItemExecutiveOffice, CreateFeedItemBillWithDescription, CreateFeedAPIDepartmentUS } from './Functions'
import ExecutiveBranchMobile from './ExecutiveBranchMobile';
import DesktopNavbar from './DesktopNavbar';
import DepartmentOfTheInteriorDocs from './DepartmentOfTheInteriorDocs';
import FishingAndWildlifeService from './FishingAndWildlifeService';
import GeologicalSurvey from './GeologicalSurvey';
import InteriorDeptHearings from './InteriorDeptHearings';
import IndianAffairsBureau from './IndianAffairsBureau';
import LandManagementBureau from './LandManagementBureau';
import NationalIndianGamingCommission from './NationalIndianGamingCommission';
import NationalParkService from './NationalParkService';
import ReclamationBureau from './ReclamationBureau';
import SpecialTrusteeForAmericanIndians from './SpecialTrusteeForAmericanIndians';
import SurfaceMiningReclamation from './SurfaceMiningReclamation';
import OceanEnergyManagmentBureau from './OceanEnergyManagmentBureau';
import SafetyAndEnvironmentEnforcement from './SafetyAndEnvironmentEnforcement';
import NaturalResourcesRevenueOffice from './NaturalResourcesRevenueOffice';
import TransportationMobile from './TransportationMobile';
import InteriorMobile from './InteriorMobile';
import DepartmentOfAgricultureDocuments from './DepartmentOfAgricultureDocuments';
import AgriculturalMarketingService from './AgriculturalMarketingService';
import AgriculturalResearchService from './AgriculturalResearchService';
import AnimalAndPlantHealthInspectionService from './AnimalAndPlantHealthInspectionService';
import CommodityCreditCorporation from './CommodityCreditCorporation';
import EconomicResearchService from './EconomicResearchService';
import FarmServiceAgency from './FarmServiceAgency';
import FederalCropInsuranceCorporation from './FederalCropInsuranceCorporation';
import FoodAndNutritionServices from './FoodAndNutritionServices';
import FoodSafetyAndInspectionService from './FoodSafetyAndInspectionService';
import ForeignAgriculturalService from './ForeignAgriculturalService';
import ForestService from './ForestService';
import NationalAgriculturalLibrary from './NationalAgriculturalLibrary';
import NationalAgriculturalStatisticsServices from './NationalAgriculturalStatisticsServices';
import NationalInstituteOfFoodAndAgriculture from './NationalInstituteOfFoodAndAgriculture';
import NaturalResourcesConservation from './NaturalResourcesConservation';
import RiskManagementAgency from './RiskManagementAgency';
import RuralBusinessCooperativeService from './RuralBusinessCooperativeService';
import RuralHousingService from './RuralHousingService';
import RuralUtilitesService from './RuralUtilitesService';
import ProcurementAndPropertyManagment from './ProcurementAndPropertyManagment';
import AdvocacyAndOutreachOffice from './AdvocacyAndOutreachOffice';
import USCodexOffice from './USCodexOffice';
import PartnershipsAndPublicEngagement from './PartnershipsAndPublicEngagement';
import AgricultureMobile from './AgricultureMobile';
import DepartmentOfAgriculture from './DepartmentOfAgriculture';
import GenerateFeedDepartment from './GenerateFeedDepartment';





var collectionCounter = 0
var carouselInterval = 30000;
var mobileCarouselInterval = 60000;





  

 


  


export const DepartmentOfAgricultureDesk = () =>
 {
   render()
   
   {
    const [show, setShow] = useState(true);

        
        const [fullscreen, setFullScreen] = useState(true);
      
      
        const [fullBillModalIsOpen, setFullBillModalIsOpen] = useState(false)
      
        const [reportModalIsOpen, setReportModalIsOpen] = useState(false)
        const [agricultureModalIsOpen, setAgricultureModalIsOpen] = useState(false)
      
        const [whiteHouseFeedModalIsOpen, setWhiteHouseFeedModalIsOpen] = useState(false)
        const [whiteHouseDocsModalIsOpen, setWhiteHouseDocsModalIsOpen] = useState(false)
      
      
        const [courtNewsModalIsOpen, setCourtNewsModalIsOpen] = useState(false)
        const [courtModalIsOpen, setCourtModalIsOpen] = useState(false)
      
        const [courtAppealsModalIsOpen, setCourtAppealsModalIsOpen] = useState(false)
      
        const [newLawsModalIsOpen, setNewLawsModalIsOpen] = useState(false)
      
        const [billsEnrolledModalIsOpen, setBillsEnrolledModalIsOpen] = useState(false)
      
        const [legislativePageBillsEnrolledModalIsOpen, setLegislativePageBillsEnrolledModalIsOpen] = useState(false)
      
      
        const [departmentOfStateModalIsOpen, setDepartmentOfStateModalIsOpen] = useState(false)
        const [departmentOfTreasuryModalIsOpen, setDepartmentOfTreasuryModalIsOpen] = useState(false)
     
        const [departmentOfJusticeModalIsOpen, setDepartmentOfJusticeModalIsOpen] = useState(false)
        const [departmentOfTheInteriorModalIsOpen, setDepartmentOfTheInteriorModalIsOpen] = useState(false)
        const [departmentOfAgricultureModalIsOpen, setDepartmentOfAgricultureModalIsOpen] = useState(false)
        const [departmentOfCommerceModalIsOpen, setDepartmentOfCommerceModalIsOpen] = useState(false)
        const [departmentOfLaborModalIsOpen, setDepartmentOfLaborModalIsOpen] = useState(false)
        const [departmentOfHealthAndHumanServicesModalIsOpen, setDepartmentOfHealthAndHumanServicesModalIsOpen] = useState(false)
        const [departmentOfHousingAndUrbanDevelopmentModalIsOpen, setDepartmentOfHousingAndUrbanDevelopmentModalIsOpen] = useState(false)
        const [departmentOfTransportationModalIsOpen, setDepartmentOfTransportationModalIsOpen] = useState(false)
        const [departmentOfEnergyModalIsOpen, setDepartmentOfEnergyModalIsOpen] = useState(false)
        const [departmentOfEducationModalIsOpen, setDepartmentOfEducationModalIsOpen] = useState(false)
        const [departmentOfVeteransAffairsModalIsOpen, setDepartmentOfVeteransAffairsModalIsOpen] = useState(false)
        const [departmentOfHomelandSecurityModalIsOpen, setDepartmentOfHomelandSecurityModalIsOpen] = useState(false)
        const [epaModalIsOpen, setEPAModalIsOpen] = useState(false)
        const [nsfModalIsOpen, setNSFModalIsOpen] = useState(false)
        const [nasaModalIsOpen, setNASAModalIsOpen] = useState(false)
        const [trendingBillsModalIsOpen, setTrendingBillsModalIsOpen] = useState(false)
        const [goaReportsModalIsOpen, setGAOReportsModalIsOpen] = useState(false)
        const [executiveOrdersModalIsOpen, setExecutiveOrdersModalIsOpen] = useState(false)
        const [presidentialProclamationsModalIsOpen, setPresidentialProclamationsModalIsOpen] = useState(false)
        const [statesModalIsOpen, setStatesModalIsOpen] = useState(false)
      
      
        const [collectionsModalIsOpen, setCollectionsModalIsOpen] = useState(false)
        const [aboutModalIsOpen, setAboutModalIsOpen] = useState(false)
      
      
        const [federalRegisterDefenseDepartmentModalIsOpen, setFederalRegisterDefenseDepartmentModalIsOpen] = useState(false)
  const [federalRegisterStateDepartmentModalIsOpen, setFederalRegisterStateDepartmentModalIsOpen] = useState(false)
        const [declarationModalIsOpen, setDeclarationModalIsOpen] = useState(false)
        const [constitutionModalIsOpen, setConstitutionModalIsOpen] = useState(false)
        const [billOfRightsnModalIsOpen, setBillOfRightsModalIsOpen] = useState(false)

        const [economicAdModalIsOpen, setEconomicAdModalIsOpen] = useState(false)
        const [environmentQualityModalIsOpen, setEnvironmentQualityModalIsOpen] = useState(false)
        const [domesticPolicyModalIsOpen, setDomesticPolicyModalIsOpen] = useState(false)
        const [genderPolicyModalIsOpen, setGenderPolicyModalIsOpen] = useState(false)
        const [nationalEconomicModalIsOpen, setNationalEconomicModalIsOpen] = useState(false)
        const [securityModalIsOpen, setSecurityModalIsOpen] = useState(false)
        const [budgetModalIsOpen, setBudgetModalIsOpen] = useState(false)
        const [drugModalIsOpen, setDrugModalIsOpen] = useState(false)
        const [publicModalIsOpen, setPublicModalIsOpen] = useState(false)
        const [scienceModalIsOpen, setScienceModalIsOpen] = useState(false)
        const [cyberModalIsOpen, setCyberModalIsOpen] = useState(false)
        const [presidentialModalIsOpen, setPresidentialModalIsOpen] = useState(false)

        const [departmentOfDefenseModalIsOpen, setDepartmentOfDefenseModalIsOpen] = useState(false)
        const [defenseDocumentsModalIsOpen, setDefenseDocumentsModalIsOpen] = useState(false)
        const [armyModalIsOpen, setArmyModalIsOpen] = useState(false)
        const [navyModalIsOpen, setNavyModalIsOpen] = useState(false)
        const [marinesModalIsOpen, setMarinesModalIsOpen] = useState(false)
        const [spaceForceModalIsOpen, setSpaceForceModalIsOpen] = useState(false)
        const [airForceModalIsOpen, setAirForceModalIsOpen] = useState(false)
        const [nationalGuardModalIsOpen, setNationalGuardModalIsOpen] = useState(false)
        const [ciaModalIsOpen, setCIAModalIsOpen] = useState(false)
        const [nsaModalIsOpen, setNSAModalIsOpen] = useState(false)
        const [armyCorpsModalIsOpen, setArmyCorpsModalIsOpen] = useState(false)
        const [showMissionModal, setMissionModal] = useState(false);
  return(
    <>
      <BrowserView>
        <div id='homePage'>

     

        <div id='homebg'>

          <DesktopNavbar/>
          <div id='feedDisplay' 
                  style={{ padding: '20px', borderRadius: '18px', marginTop: '0em'}}>

<div style={{ position: 'relative' }}>

     <div id='profileImage' style={{ width: '150px', height: '150px', borderRadius: '100%', overflow: 'hidden',  marginLeft: '40px' }}>
     <Image id='profileImage-image' src='/departmentOfAgriculture.svg' />
   </div>


   <div style={{ position: 'absolute', top: '0', left: '250px', display: 'flex', flexDirection: 'column' }}>
   <div style={{ color: 'white', fontSize: '24px', fontWeight: 'bold' }}>

</div>
<div  style={{ color: 'white', fontSize: '20px' }}> 
<span> <h3  >Department of Agriculture </h3></span>
<br></br>
<div style={{ color: 'white', fontSize: '20px' }}>
<span> The U.S. Department of Agriculture (USDA) develops and executes policy on farming, agriculture, and food. Its aims include meeting the needs of farmers and ranchers, promoting agricultural trade and production, assuring food safety, protecting forests and other natural resources, fostering rural prosperity, and ending hunger in America and abroad.</span>
<br></br>
<div style={{ color: 'white', fontSize: '18px' }}>

       <span><Link style={{color: '#21cff5'}} onClick={(e) => {
          e.preventDefault();
          window.open("https://www.usda.gov/", "_blank");
        }}>
          https://www.usda.gov/
          </Link></span> 
<br></br>
<Button id='whDocsViewMoreButton' onClick={() =>  setMissionModal(true)}>  <Files size={20}></Files> Read More</Button>
</div>
  </div>

     </div>
     </div>


     </div>

           </div>

           <Modal id='fullBillModal'  size="xl" aria-labelledby="contained-modal-title-vcenter" show={showMissionModal} onHide={() => setMissionModal(false)}>
         <Modal.Header id='billModalHeader' closeButton><h3> Department of Agriculture</h3></Modal.Header>
       
         <div id='fullBillFeed' >
      
         <p>The U.S. Department of Agriculture (USDA) develops and executes policy on farming, agriculture, and food. Its aims include meeting the needs of farmers and ranchers, promoting agricultural trade and production, assuring food safety, protecting forests and other natural resources, fostering rural prosperity, and ending hunger in America and abroad.</p>

<p>The USDA employs nearly 100,000 people and has an annual budget of approximately $150 billion. It consists of 16 agencies, including the Animal and Plant Health Inspection Service, the Food and Nutrition Service, and the Forest Service. The bulk of the department’s budget goes towards mandatory programs that provide services required by law, such as programs designed to provide nutrition assistance, promote agricultural exports, and conserve our environment.</p>

<p>The USDA also plays an important role in overseas aid programs by providing surplus foods to developing countries.</p>

<p>The United States Secretary of Agriculture administers the USDA.</p>

<br></br>
<Link style={{color: '#21cff5'}} onClick={(e) => {
     e.preventDefault();
     window.open("https://www.whitehouse.gov/about-the-white-house/our-government/the-executive-branch/#:~:text=DEPARTMENT%20OF%20AGRICULTURE,administers%20the%20USDA.", "_blank");
   }}>
     https://www.whitehouse.gov/about-the-white-house/our-government/the-executive-branch/#:~:text=DEPARTMENT%20OF%20AGRICULTURE,administers%20the%20USDA.
     </Link>
 </div>

       </Modal>

            <br></br><br></br><br></br>
            <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferTwo'>
                   
                    <Row>

                      <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>
                        <h4 id='whBreifingFeedHeading'  onClick={() => setDepartmentOfDefenseModalIsOpen(true)}><b>News</b></h4>


<div  id='agricultureDepartment'></div>

{CreateFeedItem('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/executive-xml/agriculture.xml	', 'agricultureDepartment', 'Department of Agriculture', 'Executive', 'Environment', DepartmentOfAgriculture)}

  <Modal id='whiteHouseDocsModal' show={departmentOfDefenseModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfDefenseModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Agriculture</h3></Modal.Header>
                  <DepartmentOfAgriculture />
                </Modal>
                        </div>
                      </Col>

                      <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>
                        <h4 id='whBreifingFeedHeading' onClick={() => setDefenseDocumentsModalIsOpen(true)}><b>Documents</b></h4>


<div  id='frDefense'></div>

{CreateFeedItem('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=agriculture-department', 'frDefense', 'Department of Agriculture Documents', 'Executive', 'Environment', DepartmentOfAgricultureDocuments)}

<Modal id='whiteHouseDocsModal' show={defenseDocumentsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDefenseDocumentsModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Agriculture Documents</h3></Modal.Header>
                  <DepartmentOfAgricultureDocuments />
                </Modal>
                        </div>
                      </Col>


                    </Row>


                  </Container>
                </Container>
                </div>    
           
                <div id='branchPage'>
  <Container class='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>

            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/agricultural-marketing-service', "_self")}}><b>Agricultural Marketing Service</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defAms'></div>

            {CreateFeedAPIDepartmentUS('&table_name=environment&agency=Agricultural%20Marketing%20Service', 'defAms',
              'Agricultural Marketing Service Documents', 'Executive',
              'Agriculture', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={agricultureModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setAgricultureModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Agricultural Marketing Service</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=environment&agency=Agricultural%20Marketing%20Service'
                name='Agricultural Marketing Service' branch='Executive' topic='Agriculture' buttonName='Doc' />
            </Modal>
          </div>
        </Col>

        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>

            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/agricultural-research-service', "_self")}}><b>Agricultural Research Service</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defArs'></div>

            {CreateFeedAPIDepartmentUS('&table_name=environment&agency=Agricultural%20Research%20Service', 'defArs',
              'Agricultural Research Service Documents', 'Executive',
              'Agriculture', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={agricultureModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setAgricultureModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Agricultural Research Service</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=environment&agency=Agricultural%20Research%20Service'
                name='Agricultural Research Service' branch='Executive' topic='Agriculture' buttonName='Doc' />
            </Modal>

          </div>
        </Col>
      </Row>
      </Container>
  </Container>
</div>

      <div id='branchPage'>
  <Container class='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>

            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/animal-plant-health', "_self")}}><b>Animal and Plant Health Inspection Service</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defAphis'></div>

            {CreateFeedAPIDepartmentUS('&table_name=environment&agency=Animal%20and%20Plant%20Health%20Inspection%20Service', 'defAphis',
              'Animal and Plant Health Inspection Service Documents', 'Executive',
              'Agriculture', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={agricultureModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setAgricultureModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Animal and Plant Health Inspection Service</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=environment&agency=Animal%20and%20Plant%20Health%20Inspection%20Service'
                name='Animal and Plant Health Inspection Service' branch='Executive' topic='Agriculture' buttonName='Doc' />
            </Modal>
          </div>
        </Col>

        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>

            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/commodity-credit', "_self")}}><b>Commodity Credit Corporation</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defCcc'></div>

            {CreateFeedAPIDepartmentUS('&table_name=environment&agency=Commodity%20Credit%20Corporation', 'defCcc',
              'Commodity Credit Corporation Documents', 'Executive',
              'Agriculture', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={agricultureModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setAgricultureModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Commodity Credit Corporation</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=environment&agency=Commodity%20Credit%20Corporation'
                name='Commodity Credit Corporation' branch='Executive' topic='Agriculture' buttonName='Doc' />
            </Modal>

          </div>
        </Col>
      </Row>
</Container>
</Container>
</div>
<div id='branchPage'>
  <Container class='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>

            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/cooperative-state-research', "_self")}}><b>Cooperative State Research, Education, and Extension Service</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defCsrees'></div>

            {CreateFeedAPIDepartmentUS('&table_name=environment&agency=Cooperative%20State%20Research%2C%20Education%2C%20and%20Extension%20Service', 'defCsrees',
              'Cooperative State Research, Education, and Extension Service Documents', 'Executive',
              'Agriculture', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={agricultureModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setAgricultureModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Cooperative State Research, Education, and Extension Service</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=environment&agency=Cooperative%20State%20Research%2C%20Education%2C%20and%20Extension%20Service'
                name='Cooperative State Research, Education, and Extension Service' branch='Executive' topic='Agriculture' buttonName='Doc' />
            </Modal>
          </div>
        </Col>

        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>

            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/economic-analysis-staff', "_self")}}><b>Economic Analysis Staff</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defEas'></div>

            {CreateFeedAPIDepartmentUS('&table_name=environment&agency=Economic%20Analysis%20Staff', 'defEas',
              'Economic Analysis Staff Documents', 'Executive',
              'Agriculture', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={agricultureModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setAgricultureModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Economic Analysis Staff</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=environment&agency=Economic%20Analysis%20Staff'
                name='Economic Analysis Staff' branch='Executive' topic='Agriculture' buttonName='Doc' />
            </Modal>

          </div>
        </Col>
      </Row>
      </Container>
</Container>
</div>
<div id='branchPage'>
  <Container class='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>

            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/economic-research-service', "_self")}}><b>Economic Research Service</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defErs'></div>

            {CreateFeedAPIDepartmentUS('&table_name=environment&agency=Economic%20Research%20Service', 'defErs',
              'Economic Research Service Documents', 'Executive',
              'Agriculture', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={agricultureModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setAgricultureModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Economic Research Service</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=environment&agency=Economic%20Research%20Service'
                name='Economic Research Service' branch='Executive' topic='Agriculture' buttonName='Doc' />
            </Modal>
          </div>
        </Col>

        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>

            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/farm-service-agency', "_self")}}><b>Farm Service Agency</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defFsa'></div>

            {CreateFeedAPIDepartmentUS('&table_name=environment&agency=Farm%20Service%20Agency', 'defFsa',
              'Farm Service Agency Documents', 'Executive',
              'Agriculture', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={agricultureModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setAgricultureModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Farm Service Agency</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=environment&agency=Farm%20Service%20Agency'
                name='Farm Service Agency' branch='Executive' topic='Agriculture' buttonName='Doc' />
            </Modal>

          </div>
        </Col>
      </Row>
      </Container>
</Container>
</div>
<div id='branchPage'>
  <Container class='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>

            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/federal-crop-insurance', "_self")}}><b>Federal Crop Insurance Corporation</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defFcic'></div>

            {CreateFeedAPIDepartmentUS('&table_name=environment&agency=Federal%20Crop%20Insurance%20Corporation', 'defFcic',
              'Federal Crop Insurance Corporation Documents', 'Executive',
              'Agriculture', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={agricultureModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setAgricultureModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Federal Crop Insurance Corporation</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=environment&agency=Federal%20Crop%20Insurance%20Corporation'
                name='Federal Crop Insurance Corporation' branch='Executive' topic='Agriculture' buttonName='Doc' />
            </Modal>
          </div>
        </Col>

        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>

            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/food-consumer-service', "_self")}}><b>Food and Consumer Service</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defFcs'></div>

            {CreateFeedAPIDepartmentUS('&table_name=environment&agency=Food%20and%20Consumer%20Service', 'defFcs',
              'Food and Consumer Service Documents', 'Executive',
              'Agriculture', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={agricultureModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setAgricultureModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Food and Consumer Service</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=environment&agency=Food%20and%20Consumer%20Service'
                name='Food and Consumer Service' branch='Executive' topic='Agriculture' buttonName='Doc' />
            </Modal>

          </div>
        </Col>
      </Row>
      </Container>
</Container>
</div>
<div id='branchPage'>
  <Container class='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>

            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/food-nutrition-service', "_self")}}><b>Food and Nutrition Service</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defFns'></div>

            {CreateFeedAPIDepartmentUS('&table_name=environment&agency=Food%20and%20Nutrition%20Service', 'defFns',
              'Food and Nutrition Service Documents', 'Executive',
              'Agriculture', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={agricultureModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setAgricultureModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Food and Nutrition Service</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=environment&agency=Food%20and%20Nutrition%20Service'
                name='Food and Nutrition Service' branch='Executive' topic='Agriculture' buttonName='Doc' />
            </Modal>
          </div>
        </Col>

        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>

            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/food-safety-inspection', "_self")}}><b>Food Safety and Inspection Service</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defFsis'></div>

            {CreateFeedAPIDepartmentUS('&table_name=environment&agency=Food%20Safety%20and%20Inspection%20Service', 'defFsis',
              'Food Safety and Inspection Service Documents', 'Executive',
              'Agriculture', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={agricultureModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setAgricultureModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Food Safety and Inspection Service</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=environment&agency=Food%20Safety%20and%20Inspection%20Service'
                name='Food Safety and Inspection Service' branch='Executive' topic='Agriculture' buttonName='Doc' />
            </Modal>

          </div>
        </Col>
      </Row>
      </Container>
</Container>
</div>
<div id='branchPage'>
  <Container class='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>

            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/foreign-agricultural', "_self")}}><b>Foreign Agricultural Service</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defFas'></div>

            {CreateFeedAPIDepartmentUS('&table_name=environment&agency=Foreign%20Agricultural%20Service', 'defFas',
              'Foreign Agricultural Service Documents', 'Executive',
              'Agriculture', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={agricultureModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setAgricultureModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Foreign Agricultural Service</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=environment&agency=Foreign%20Agricultural%20Service'
                name='Foreign Agricultural Service' branch='Executive' topic='Agriculture' buttonName='Doc' />
            </Modal>
          </div>
        </Col>

        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>

            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/forest-service', "_self")}}><b>Forest Service</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defFs'></div>

            {CreateFeedAPIDepartmentUS('&table_name=environment&agency=Forest%20Service', 'defFs',
              'Forest Service Documents', 'Executive',
              'Agriculture', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={agricultureModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setAgricultureModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Forest Service</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=environment&agency=Forest%20Service'
                name='Forest Service' branch='Executive' topic='Agriculture' buttonName='Doc' />
            </Modal>

          </div>
        </Col>
      </Row>
      </Container>
</Container>
</div>
<div id='branchPage'>
  <Container class='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>

            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/national-agricultural-library', "_self")}}><b>National Agricultural Library</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defNal'></div>

            {CreateFeedAPIDepartmentUS('&table_name=environment&agency=National%20Agricultural%20Library', 'defNal',
              'National Agricultural Library Documents', 'Executive',
              'Agriculture', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={agricultureModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setAgricultureModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>National Agricultural Library</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=environment&agency=National%20Agricultural%20Library'
                name='National Agricultural Library' branch='Executive' topic='Agriculture' buttonName='Doc' />
            </Modal>
          </div>
        </Col>

        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>

            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/national-agricultural', "_self")}}><b>National Agricultural Statistics Service</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defNass'></div>

            {CreateFeedAPIDepartmentUS('&table_name=environment&agency=National%20Agricultural%20Statistics%20Service', 'defNass',
              'National Agricultural Statistics Service Documents', 'Executive',
              'Agriculture', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={agricultureModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setAgricultureModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>National Agricultural Statistics Service</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=environment&agency=National%20Agricultural%20Statistics%20Service'
                name='National Agricultural Statistics Service' branch='Executive' topic='Agriculture' buttonName='Doc' />
            </Modal>

          </div>
        </Col>
      </Row>
      </Container>
</Container>
</div>
<div id='branchPage'>
  <Container class='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>

            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/nifa', "_self")}}><b>National Institute of Food and Agriculture</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defNifa'></div>

            {CreateFeedAPIDepartmentUS('&table_name=environment&agency=National%20Institute%20of%20Food%20and%20Agriculture', 'defNifa',
              'National Institute of Food and Agriculture Documents', 'Executive',
              'Agriculture', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={agricultureModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setAgricultureModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>National Institute of Food and Agriculture</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=environment&agency=National%20Institute%20of%20Food%20and%20Agriculture'
                name='National Institute of Food and Agriculture' branch='Executive' topic='Agriculture' buttonName='Doc' />
            </Modal>
          </div>
        </Col>

        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>

            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/natural-resources-conservation', "_self")}}><b>Natural Resources Conservation Service</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defNrcs'></div>

            {CreateFeedAPIDepartmentUS('&table_name=environment&agency=Natural%20Resources%20Conservation%20Service', 'defNrcs',
              'Natural Resources Conservation Service Documents', 'Executive',
              'Agriculture', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={agricultureModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setAgricultureModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Natural Resources Conservation Service</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=environment&agency=Natural%20Resources%20Conservation%20Service'
                name='Natural Resources Conservation Service' branch='Executive' topic='Agriculture' buttonName='Doc' />
            </Modal>

          </div>
        </Col>
      </Row>
      </Container>
</Container>
</div>
<div id='branchPage'>
  <Container class='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>

            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/operations-office', "_self")}}><b>Operations Office</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defOo'></div>

            {CreateFeedAPIDepartmentUS('&table_name=environment&agency=Operations%20Office', 'defOo',
              'Operations Office Documents', 'Executive',
              'Agriculture', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={agricultureModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setAgricultureModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Operations Office</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=environment&agency=Operations%20Office'
                name='Operations Office' branch='Executive' topic='Agriculture' buttonName='Doc' />
            </Modal>
          </div>
        </Col>

        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>

            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/partnerships-public-engagement', "_self")}}><b>Partnerships and Public Engagement Office</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defPpeo'></div>

            {CreateFeedAPIDepartmentUS('&table_name=environment&agency=Partnerships%20and%20Public%20Engagement%20Office', 'defPpeo',
              'Partnerships and Public Engagement Office Documents', 'Executive',
              'Agriculture', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={agricultureModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setAgricultureModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Partnerships and Public Engagement Office</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=environment&agency=Partnerships%20and%20Public%20Engagement%20Office'
                name='Partnerships and Public Engagement Office' branch='Executive' topic='Agriculture' buttonName='Doc' />
            </Modal>

          </div>
        </Col>
      </Row>
      </Container>
</Container>
</div>
<div id='branchPage'>
  <Container class='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>

            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/procurement-property-management', "_self")}}><b> Office of Procurement and Property Management</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defOppm'></div>

            {CreateFeedAPIDepartmentUS('&table_name=environment&agency=Procurement%20and%20Property%20Management%2C%20Office%20of', 'defOppm',
              'Procurement and Property Management, Office of Documents', 'Executive',
              'Agriculture', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={agricultureModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setAgricultureModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3> Office of Procurement and Property Management</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=environment&agency=Procurement%20and%20Property%20Management%2C%20Office%20of'
                name='Procurement and Property Management, Office of' branch='Executive' topic='Agriculture' buttonName='Doc' />
            </Modal>
          </div>
        </Col>

        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>

            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/risk-management-agency', "_self")}}><b>Risk Management Agency</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defRma'></div>

            {CreateFeedAPIDepartmentUS('&table_name=environment&agency=Risk%20Management%20Agency', 'defRma',
              'Risk Management Agency Documents', 'Executive',
              'Agriculture', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={agricultureModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setAgricultureModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Risk Management Agency</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=environment&agency=Risk%20Management%20Agency'
                name='Risk Management Agency' branch='Executive' topic='Agriculture' buttonName='Doc' />
            </Modal>

          </div>
        </Col>
      </Row>
      </Container>
</Container>
</div>
<div id='branchPage'>
  <Container class='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>

            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/rural-business-cooperative', "_self")}}><b>Rural Business-Cooperative Service</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defRbcs'></div>

            {CreateFeedAPIDepartmentUS('&table_name=environment&agency=Rural%20Business-Cooperative%20Service', 'defRbcs',
              'Rural Business-Cooperative Service Documents', 'Executive',
              'Agriculture', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={agricultureModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setAgricultureModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Rural Business-Cooperative Service</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=environment&agency=Rural%20Business-Cooperative%20Service'
                name='Rural Business-Cooperative Service' branch='Executive' topic='Agriculture' buttonName='Doc' />
            </Modal>
          </div>
        </Col>

        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>

            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/rural-housing-service', "_self")}}><b>Rural Housing Service</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defRhs'></div>

            {CreateFeedAPIDepartmentUS('&table_name=environment&agency=Rural%20Housing%20Service', 'defRhs',
              'Rural Housing Service Documents', 'Executive',
              'Agriculture', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={agricultureModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setAgricultureModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Rural Housing Service</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=environment&agency=Rural%20Housing%20Service'
                name='Rural Housing Service' branch='Executive' topic='Agriculture' buttonName='Doc' />
            </Modal>

          </div>
        </Col>
      </Row>
      </Container>
</Container>
</div>
<div id='branchPage'>
  <Container class='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>

            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/rural-utilities-service', "_self")}}><b>Rural Utilities Service</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defRus'></div>

            {CreateFeedAPIDepartmentUS('&table_name=environment&agency=Rural%20Utilities%20Service', 'defRus',
              'Rural Utilities Service Documents', 'Executive',
              'Agriculture', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={agricultureModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setAgricultureModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Rural Utilities Service</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=environment&agency=Rural%20Utilities%20Service'
                name='Rural Utilities Service' branch='Executive' topic='Agriculture' buttonName='Doc' />
            </Modal>
          </div>
        </Col>

        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>

            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/rural-telephone-bank', "_self")}}><b>Rural Telephone Bank</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defRtb'></div>

            {CreateFeedAPIDepartmentUS('&table_name=environment&agency=Rural%20Telephone%20Bank', 'defRtb',
              'Rural Telephone Bank Documents', 'Executive',
              'Agriculture', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={agricultureModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setAgricultureModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Rural Telephone Bank</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=environment&agency=Rural%20Telephone%20Bank'
                name='Rural Telephone Bank' branch='Executive' topic='Agriculture' buttonName='Doc' />
            </Modal>

          </div>
        </Col>
      </Row>
      </Container>
</Container>
</div>
<div id='branchPage'>
  <Container class='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>

            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/transportation-office', "_self")}}><b>Transportation Office</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defTo'></div>

            {CreateFeedAPIDepartmentUS('&table_name=environment&agency=Transportation%20Office', 'defTo',
              'Transportation Office Documents', 'Executive',
              'Agriculture', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={agricultureModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setAgricultureModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Transportation Office</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=environment&agency=Transportation%20Office'
                name='Transportation Office' branch='Executive' topic='Agriculture' buttonName='Doc' />
            </Modal>
          </div>
        </Col>

        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>

            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/us-codex-office', "_self")}}><b>U.S. Codex Office</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defUco'></div>

            {CreateFeedAPIDepartmentUS('&table_name=environment&agency=U.S.%20Codex%20Office', 'defUco',
              'U.S. Codex Office Documents', 'Executive',
              'Agriculture', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={agricultureModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setAgricultureModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>U.S. Codex Office</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=environment&agency=U.S.%20Codex%20Office'
                name='U.S. Codex Office' branch='Executive' topic='Agriculture' buttonName='Doc' />
            </Modal>

          </div>
        </Col>
      </Row>
    </Container>
  </Container>
</div>

   

            <Modal id='aboutModal' show={aboutModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setAboutModalIsOpen(false)}>
            <div id='removedAlert'></div>
            <Modal.Header id='aboutModalHeader' closeButton><h3>About</h3></Modal.Header>
  <ModalBody id='aboutInfo'><p id='aboutParagraph'>Gov Glance was created to drive civic engagement and support a 
              healthier democracy by helping citizens get official Government news in an easy-to-navigate centralized location. 
              There will never be any opinion or political analysis(That's your job), only information from official Government 
              sources. Gov Glance is structured after the U.S. government that is broken into three branches Legislative, 
              Executive, and Judicial. To learn more about the structure and functions of The U.S. Government go to <a href='https://www.usa.gov/branches-of-government'>https://www.usa.gov/branches-of-government</a>.
              </p>

              <p id='aboutParagraph'>
              Gov Glance Foundation Inc. is a non-profit that was created to operate govglance.org and continue to create educational tools that help people learn about the workings of society
              </p>
            <br></br>

            <h3>Privacy</h3>

            <br></br>

              <p id='aboutParagraph'>Gov Glance does not collect any personal user information. 
              Features like collection save news items directly to your browser's local storage for your convenience
              and is not shared with Gov Glance servers. We do not collect, store, or send personal information to Google LLC, 
              but we do use some Google services to deliver our app to you. For any questions into Google’s privacy practices 
              here is a link to their privacy policy: <a href='https://policies.google.com/privacy?hl=en-US#intro'>https://policies.google.com/privacy?hl=en-US#intro</a>

              <br></br><br></br>

              For site analytics we use plausible.io an open source privacy friendly analytics provider. <a href="https://plausible.io/about" target="_blank">https://plausible.io/about</a>
            
            </p>
            <br></br>


            <h3>Contact</h3>

            <p id='aboutParagraph'>
                      Contact us via email: <a href='mailto:contact@govglance.org'>contact@govglance.org</a>

            </p>

            <br></br>


            
            </ModalBody>
          </Modal>
 
          <footer id='homeFooter'>
      <Navbar>
        <Row id='footerNav'>
      <Nav variant='pills'>
        
     <Nav.Link id='footerContent'>Gov Glance Foundation Inc.
</Nav.Link>
<Nav.Item>
<Nav.Link id='footerContent' href='mailto:contact@govglance.org'>Contact</Nav.Link>
</Nav.Item>
<Nav.Item>
<Nav.Link id='footerContent' onClick={(e) => {
                   e.preventDefault();
                   window.open('https://donate.stripe.com/28o3fXbdvbQO2RO288', "_blank")
                 }}> <HeartFill id='donateIcon'/>    Donate</Nav.Link>
</Nav.Item>
<Nav.Link onClick={(e) => {
                   e.preventDefault();
                   window.open('/about', "_self")}} id='footerContent'>Privacy</Nav.Link>
    
     
 </Nav>
 </Row>
 </Navbar>
      </footer>



</div>
         </div>
          

              </BrowserView>

              <MobileView>

    <AgricultureMobile/>

              </MobileView>
              
    </>
  )
}
 }



  






export default DepartmentOfAgricultureDesk;