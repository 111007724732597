import App from "../../App";
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Image, ModalFooter } from 'react-bootstrap';
import { ArrowsAngleExpand, InfoCircle, FileText, PauseCircle, PlayCircle, FolderPlus, ChevronRight, Files, Folder, Pen, ChatLeftQuote, ShieldShaded, People, Coin, HeartFill } from 'react-bootstrap-icons'
import '../../App.css';
import 'bootstrap/dist/css/bootstrap.min.css'

import Axios from 'axios'
import { render } from '@testing-library/react';

import{ ListItem, createFullBillFeed, saveToCollection, createHouseCommitteeOversightAndReform, createHouseCommitteeOnRules,
    createHouseCommitteeOnSmallBusiness, createHouseCommitteeOnScienceSpaceAndTechnology,
    createHouseCommitteeOnTransportationAndInfrastructure,
    createHouseCommitteeOnVeteransAffairs,
    createCongressionalBudgetOffice, createBillFeedTwo,
    createBillFeedTwoMobile, createReports, createHouseCommitteeOnAgriculture,
     createHouseCommitteeOnAppropriations, createHouseCommitteeOnArmedServices,
     createHouseCommitteeOnTheBudget, createHouseCommitteeOnEducationAndLabor,
    createHouseCommitteeOnEnergyAndCommerce, createHouseCommitteeOnEthics,
     createHouseCommitteeOnFinancialServices, createHouseCommitteeOnForeignAffairs, createHouseCommitteeOnHomelandSecurity,
    createHouseCommitteeOnHouseAdministration, 
    createHouseCommitteeOnTheJudiciary, createHouseCommitteeOnNaturalResources, 
     createHouseCommitteeOnNaturalResourcesMobile, createGAOReports,
     createNewLaws, createBillsEnrolled
    , createBillsEnrolledMobile, createTrendingBills
    , createHouseFloorToday, congressSearch, Speak, createGAOReportsMobile, createCongressionalHearings, createBillStatus, CreateFeedItem,
    CreateFeedItemBillUpdates, CreateFeedItemWithDocButton, CreateFeedItemWithLawButton,
    CreateFeedItemGAOReports, CreateFeedItemCongressionalReports, CreateFeedItemWithDocumentFlip, CreateFeedItemWithLawButtonFlip, CreateFeedItemWithDocButtonDateFix, CreateFeedItemCanadaDateFix, CreateFeedItemMexico, CreateFeedAPI} from '../../Functions'


import MexicoMobileTopNavbar from "../MexicoMobileTopNavbar";
import SEAgency from "../Executive/SeAgency";
import SecturAgency from "../Executive/SecturAgency";
import SHCPAgency from "../Executive/SHCPAgency";

import MobileOffCanvasMexico from "../../MobileOffCanvasMexico";
import GenerateFeed from "../../GenerateFeed";


    var response=''

var collectionCounter = 0
var carouselInterval = 30000;
var mobileCarouselInterval = 60000;


  class PlayUpdateButtonMobile extends React.Component { 
      constructor(props) {
        super(props);
        this.state = {
          isCardView: false,
        }
      } 
      
      render() {
        return (
          <Button  id='navbarUpdateButtonMobile' size='lg'  onClick={()=>{this.setState({ isCardView: !this.state.isCardView }); Speak(response);  }}  >
            { this.state.isCardView                                                   
              ? <PauseCircle size={30}   />
              : <PlayCircle size={30}  />
            }
            &nbsp;&nbsp;Update (Beta)
          </Button>
        );
      }
      
    }
  
   
  
  
    
  class PlayUpdateButton extends React.Component { 
    constructor(props) {
      super(props);
      this.state = {
        isCardView: false,
      }
    } 
    
    render() {
      return (
        <Button id='navbarCollectionButtonDesktop' size='lg'  onClick={()=>{this.setState({ isCardView: !this.state.isCardView }); Speak(response);  }}  >
          { this.state.isCardView                                                   
            ? <PauseCircle  />
            : <PlayCircle />
          }
          &nbsp;&nbsp;Update (Beta)
        </Button>
      );
    }
    
  }

export const MexicoEconomyMobile = () =>   {
    render()
    
    {
  
  
        const [show, setShow] = useState(true);

        const [modalIsOpen, setModalIsOpen] = useState(false)
        const [fullscreen, setFullScreen] = useState(true);
      
      
        const [fullBillModalIsOpen, setFullBillModalIsOpen] = useState(false)
      
        const [reportModalIsOpen, setReportModalIsOpen] = useState(false)
      
      
        const [whiteHouseFeedModalIsOpen, setWhiteHouseFeedModalIsOpen] = useState(false)
        const [whiteHouseDocsModalIsOpen, setWhiteHouseDocsModalIsOpen] = useState(false)
      
      
        const [courtNewsModalIsOpen, setCourtNewsModalIsOpen] = useState(false)
        const [courtModalIsOpen, setCourtModalIsOpen] = useState(false)
      
        const [courtAppealsModalIsOpen, setCourtAppealsModalIsOpen] = useState(false)
      
        const [newLawsModalIsOpen, setNewLawsModalIsOpen] = useState(false)
      
        const [billsEnrolledModalIsOpen, setBillsEnrolledModalIsOpen] = useState(false)
      
        const [legislativePageBillsEnrolledModalIsOpen, setLegislativePageBillsEnrolledModalIsOpen] = useState(false)
      
      
        const [departmentOfStateModalIsOpen, setDepartmentOfStateModalIsOpen] = useState(false)
        const [departmentOfTreasuryModalIsOpen, setDepartmentOfTreasuryModalIsOpen] = useState(false)
        const [departmentOfDefenseModalIsOpen, setDepartmentOfDefenseModalIsOpen] = useState(false)
        const [departmentOfJusticeModalIsOpen, setDepartmentOfJusticeModalIsOpen] = useState(false)
        const [departmentOfTheInteriorModalIsOpen, setDepartmentOfTheInteriorModalIsOpen] = useState(false)
        const [departmentOfAgricultureModalIsOpen, setDepartmentOfAgricultureModalIsOpen] = useState(false)
        const [departmentOfCommerceModalIsOpen, setDepartmentOfCommerceModalIsOpen] = useState(false)
        const [departmentOfLaborModalIsOpen, setDepartmentOfLaborModalIsOpen] = useState(false)
        const [departmentOfHealthAndHumanServicesModalIsOpen, setDepartmentOfHealthAndHumanServicesModalIsOpen] = useState(false)
        const [departmentOfHousingAndUrbanDevelopmentModalIsOpen, setDepartmentOfHousingAndUrbanDevelopmentModalIsOpen] = useState(false)
        const [departmentOfTransportationModalIsOpen, setDepartmentOfTransportationModalIsOpen] = useState(false)
        const [departmentOfEnergyModalIsOpen, setDepartmentOfEnergyModalIsOpen] = useState(false)
        const [departmentOfEducationModalIsOpen, setDepartmentOfEducationModalIsOpen] = useState(false)
        const [departmentOfVeteransAffairsModalIsOpen, setDepartmentOfVeteransAffairsModalIsOpen] = useState(false)
        const [departmentOfHomelandSecurityModalIsOpen, setDepartmentOfHomelandSecurityModalIsOpen] = useState(false)
        const [epaModalIsOpen, setEPAModalIsOpen] = useState(false)
        const [nasaModalIsOpen, setNASAModalIsOpen] = useState(false)
        const [trendingBillsModalIsOpen, setTrendingBillsModalIsOpen] = useState(false)
        const [goaReportsModalIsOpen, setGAOReportsModalIsOpen] = useState(false)
          const [houseCommitteeOnAppropriationsModalIsOpen, setHouseCommitteeOnAppropriationsModalIsOpen] = useState(false)
        const [houseComitteeOnAgricultureModalIsOpen, setHouseComitteeOnAgricultureModalIsOpen] = useState(false)
          const [houseCommitteeOnArmedServicesModalIsOpen, setHouseCommitteeOnOnArmedServicesModalIsOpen] = useState(false)
          const [houseCommitteeOnTheBudgetModalIsOpen, setHouseCommitteeOnTheBudgetModalIsOpen] = useState(false)
          const [houseFloorModalIsOpen, setHouseFloorModalIsOpen] = useState(false)
          const [houseCommitteeOnEnergyAndCommerceModalIsOpen, setHouseCommitteeOnEnergyAndCommerceModalIsOpen] = useState(false)
          const [houseCommitteeOnEducationAndLaborModalIsOpen, setHouseCommitteeOnEducationAndLaborModalIsOpen] = useState(false)
          const [houseCommitteeOnEthicsModalIsOpen, setHouseCommitteeOnEthicsModalIsOpen] = useState(false)
          const [houseCommitteeOnFinancialServicesModalIsOpen, setHouseCommitteeOnFinancialServicesModalIsOpen] = useState(false)
          const [houseCommitteeOnForeignAffairsModalIsOpen, setHouseCommitteeOnForeignAffairsModalIsOpen] = useState(false)
          const [houseCommitteeOnHomelandSecurityModalIsOpen, setHouseCommitteeOnHomelandSecurityModalIsOpen] = useState(false)
          const [houseCommitteeOnHouseAdministrationModalIsOpen, setHouseCommitteeOnHouseAdministrationModalIsOpen] = useState(false)
          const [houseCommitteeOnTheJudiciaryModalIsOpen, setHouseCommitteeOnTheJudiciaryModalIsOpen] = useState(false)
          const [houseCommitteeOnNaturalResourcesModalIsOpen, setHouseCommitteeOnNaturalResourcesModalIsOpen] = useState(false)
          const [houseCommitteeOnOversightAndReformModalIsOpen, setHouseCommitteeOnOversightAndReformModalIsOpen] = useState(false)
          const [congressionalBudgetOfficeModalIsOpen, setCongressionalBudgetOfficeModalIsOpen] = useState(false)
          const [houseCommitteeOnRulesModalIsOpen, setHouseCommitteeOnRulesModalIsOpen] = useState(false)
          const [houseCommitteeOnScienceSpaceAndTechnologyModalIsOpen, setHouseCommitteeOnScienceSpaceAndTechnologyModalIsOpen] = useState(false)
          const [houseCommitteeOnSmallBusinessModalIsOpen, setHouseCommitteeOnSmallBusinessModalIsOpen] = useState(false)
          const [houseCommitteeOnTransportationAndInfrastructureModalIsOpen, setHouseCommitteeOnTransportationAndInfrastructureModalIsOpen] = useState(false)
          const [houseCommitteeOnVeteransAffairsModalIsOpen, setHouseCommitteeOnVeteransAffairsModalIsOpen] = useState(false)
          const [congressionalHearingsModalIsOpen, setCongressionalHearingsModalIsOpen] = useState(false)
          
          const [trendingBillsOptionOne, setTrendingBillsOptionOneModalIsOpen ] = useState(false)
          const [trendingBillsOptionTwo, setTrendingBillsOptionTwoModalIsOpen ] = useState(false)
          const [trendingBillsOptionThree, setTrendingBillsOptionThreeModalIsOpen ] = useState(false)
          const [trendingBillsOptionFour, setTrendingBillsOptionFourModalIsOpen ] = useState(false)
          const [trendingBillsOptionFive, setTrendingBillsOptionFiveModalIsOpen ] = useState(false)
          const [trendingBillsOptionSix, setTrendingBillsOptionSixModalIsOpen ] = useState(false)
          const [trendingBillsOptionSeven, setTrendingBillsOptionSevenModalIsOpen ] = useState(false)
          const [trendingBillsOptionEight, setTrendingBillsOptionEightModalIsOpen ] = useState(false)
          const [trendingBillsOptionNine, setTrendingBillsOptionNineModalIsOpen ] = useState(false)
          const [trendingBillsOptionTen, setTrendingBillsOptionTenModalIsOpen ] = useState(false)
          const [trendingBillsOptionEleven, setTrendingBillsOptionElevenModalIsOpen ] = useState(false)
          const [trendingBillsOptionTweleve, setTrendingBillsOptionTweleveModalIsOpen ] = useState(false)
          const [trendingBillsOptionThirteen, setTrendingBillsOptionThirteenModalIsOpen ] = useState(false)
      
        const [collectionsModalIsOpen, setCollectionsModalIsOpen] = useState(false)
        const [aboutModalIsOpen, setAboutModalIsOpen] = useState(false)
        const [statesModalIsOpen, setStatesModalIsOpen] = useState(false)
      
      
        const [declarationModalIsOpen, setDeclarationModalIsOpen] = useState(false)
        const [constitutionModalIsOpen, setConstitutionModalIsOpen] = useState(false)
        const [billOfRightsnModalIsOpen, setBillOfRightsModalIsOpen] = useState(false)
    
    
        const [billStatusModalIsOpen, setBillStatusModalIsOpen] = useState(false)
        localStorage.setItem('currentPage', 'mexico')
    return(
       <>
    <MexicoMobileTopNavbar/>
  
  
  
  
  <div id='homebg'>
 

{/*Legislative Mobile Carousel*/}

<h5 id='branchHeadingMobile' ><Coin></Coin> Economy </h5>
{/*onClick={() => setModalIsOpen(true)}*/}

<Col>
  <h5 id='presidentialFeedHeadingMobile' onClick={() => setTrendingBillsOptionTenModalIsOpen(true)}>Secretaría de Economía</h5>

  <div id='se'></div>
                          
  {CreateFeedAPI('&skip=0&schema=mexico&table=se&order_by=created_at', 'se', 
                            'Secretaría de Economía', 'Executive', 
                            'Economy', 'Doc', GenerateFeed)}

<Modal id='mobileFullBillModal' show={trendingBillsOptionTen} 
size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsOptionTenModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Secretaría de Economía</h3></Modal.Header>
<GenerateFeed url='&skip=0&schema=mexico&table=se&order_by=created_at'
                                name='Secretaría de Economía' branch='Executive' topic='Economy' buttonName='Doc'/>
</Modal>
</Col>

<Col>
  <h5 id='presidentialFeedHeadingMobile' onClick={() => setTrendingBillsOptionElevenModalIsOpen(true)}>Secretaría de Turismo</h5>

  <div id='sectur'></div>
                          
                          {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
                          {CreateFeedAPI('&skip=0&schema=mexico&table=sectur&order_by=created_at', 'sectur', 
                            'Secretaría de Turismo', 'Executive', 
                            'Economy', 'Doc', GenerateFeed)}

<Modal id='mobileFullBillModal' show={trendingBillsOptionEleven} 
size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsOptionElevenModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Secretaría de Turismo</h3></Modal.Header>
<GenerateFeed url='&skip=0&schema=mexico&table=sectur&order_by=created_at'
                                name='Secretaría de Turismo' branch='Executive' topic='Economy' buttonName='Doc'/>
</Modal>
</Col>



<Col> 
  <h5 id='presidentialFeedHeadingMobile' onClick={() => setDepartmentOfEnergyModalIsOpen(true)}>Secretaría de Hacienda y Crédito Público</h5>

  <div id='shcp'></div>
                          
  {CreateFeedAPI('&skip=0&schema=mexico&table=shcp&order_by=created_at', 'shcp', 
                            'Secretaría de Hacienda y Crédito Público', 'Executive', 
                            'Economy', 'Doc', GenerateFeed)}

<Modal id='mobileFullBillModal' show={departmentOfEnergyModalIsOpen} 
size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfEnergyModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Secretaría de Hacienda y Crédito Público</h3></Modal.Header>
<GenerateFeed url='&skip=0&schema=mexico&table=shcp&order_by=created_at'
                                name='Secretaría de Hacienda y Crédito Público' branch='Executive' topic='Economy' buttonName='Doc' />
</Modal>
</Col>

<Col>

<br></br><br></br><br></br><br></br>
<br></br>
<br></br>
<h5 id='headingSelectHomeCountry'>Support the Gov Glance Foundation  <Button id='donateButton' onClick={(e) => {
                        e.preventDefault();
                        window.open('https://donate.stripe.com/28o3fXbdvbQO2RO288', "_blank")
                      }}><HeartFill id='donateIcon'/>    Donate</Button></h5>

</Col>
<br></br><br></br><br></br><br></br><br></br><br></br>

<Navbar id='navbarMobileBottom' fixed="bottom">  



       
<div id='changeBranchDropdownMobile'>

                  <MobileOffCanvasMexico/>
           
              
            </div>

</Navbar>
 
             
 
 
 
 
 
 

</div>



</>
)
}}
export default MexicoEconomyMobile;


