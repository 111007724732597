import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link, Switch, } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment, } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Tooltip, OverlayTrigger, Image } from 'react-bootstrap';
import { ArrowsAngleExpand, ArrowsFullscreen, InfoCircle, FileText, ShieldShaded, PlayCircle, PauseCircle, HeartFill, Pen, People, Collection, Grid, ThreeDots, Stack, List, Files, FolderPlus, FolderCheck, Folder, Coin, Tree, ChevronRight, Bricks, Lightbulb } from 'react-bootstrap-icons'
import { BrowserView, MobileView } from 'react-device-detect';
import  "../../App.css";
import 'bootstrap/dist/css/bootstrap.min.css'
import { render } from '@testing-library/react';
import CanadaMobileTopNavbar from '../CanadaMobileTopNavbar';
import { CreateFeedItem } from '../../Functions';
import MobileOffCanvas from '../../MobileOffCanvas';
import StandingCommitteeOnNationalDefence from '../Legislative/StandingCommitteeOnNationalDefence';

var collectionCounter = 0
var carouselInterval = 30000;
var mobileCarouselInterval = 60000;

export const CanadaDefenseMobile = () =>   {
    render()
 
    {
      const [trendingBillsOptionNine, setTrendingBillsOptionNineModalIsOpen ] = useState(false)
      const [courtModalIsOpen, setCourtModalIsOpen] = useState(false)
  
    var branch = "all";
    return(
       <>
      <CanadaMobileTopNavbar/>
       <div id='homebg'>
           <h5 id='branchHeadingMobile' ><Bricks/> Defense </h5>

           <Col>
  <h5 id='presidentialFeedHeadingMobile' onClick={() => setTrendingBillsOptionNineModalIsOpen(true)}>Standing Committee on National Defence</h5>
  <div id='committee10'></div>
  {CreateFeedItem('https://gov-glance-canada.nyc3.digitaloceanspaces.com/legislative-xml/committees/nddn.xml', 'committee10', 'Standing Committee on National Defence', 'Legislative', 'N/A', StandingCommitteeOnNationalDefence)}

  <Modal id='mobileFullBillModal' show={trendingBillsOptionNine} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsOptionNineModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Standing Committee on National Defence</h3></Modal.Header>
<StandingCommitteeOnNationalDefence/>
</Modal>

</Col>

<Col>

<br></br><br></br><br></br><br></br>
<br></br>
<br></br>
<h5 id='headingSelectHomeCountry'>Support the Gov Glance Foundation  <Button id='donateButton' onClick={(e) => {
                        e.preventDefault();
                        window.open('https://donate.stripe.com/28o3fXbdvbQO2RO288', "_blank")
                      }}><HeartFill id='donateIcon'/>    Donate</Button></h5>

</Col>


     
           <br></br><br></br><br></br><br></br><br></br><br></br><Navbar id='navbarMobileBottom' fixed="bottom">    

<div id='changeBranchDropdownMobile'>
           <MobileOffCanvas/>
            </div>
</Navbar>
          </div>

          </>
  )
}
 }
export default CanadaDefenseMobile;

