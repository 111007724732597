
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link, Switch, } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment, } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Tooltip, OverlayTrigger, Image } from 'react-bootstrap';
import { ArrowsAngleExpand, ArrowsFullscreen, InfoCircle, FileText, ShieldShaded, Pen, PauseCircle, PlayCircle, People, HeartFill, Collection, Grid, ThreeDots, Stack, List, Files, FolderPlus, FolderCheck, Folder, ChevronRight } from 'react-bootstrap-icons'
import { BrowserView, MobileView } from 'react-device-detect';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import Axios from 'axios'
import { render } from '@testing-library/react';
import FullBillFeed from './FullBillFeed';
import FullWhiteHouseFeed from './FullWhiteHouseFeed';
import FullUSCourtNews from './FullUSCourtNews';
import FullWhiteHouseDocuments from './FullWhiteHouseDocuments';
import DailyDigest from './DailyDigest';
import State from './State';
import Illinois from './state/Illinois';
import CollectionPage from './CollectionPage';
import NewLaws from './NewLaws';
import BillsEnrolled from './BillsEnrolled';
import DepartmentOfState from './DepartmentOfState';
import DepartmentOfTreasury from './DepartmentOfTreasury';
import CourtAppeals from './CourtAppeals';
import Reports from './Reports';
import DepartmentOfDefense from './DepartmentOfDefense';
import DepartmentOfJustice from './DepartmentOfJustice';
import DepartmentOfTheInterior from './DepartmentOfTheInterior';
import DepartmentOfAgriculture from './DepartmentOfAgriculture';
import DepartmentOfCommerce from './DepartmentOfCommerce';
import DepartmentOfLabor from './DepartmentOfLabor';
import DepartmentOfHealthAndHumanServices from './DepartmentOfHealthAndHumanServices';
import DepartmentOfHousingAndUrbanDevelopment from './DepartmentOfHousingAndUrbanDevelopment';
import DepartmentOfTransportation from './DepartmentOfTransportation';
import DepartmentOfEnergy from './DepartmentOfEnergy';
import DepartmentOfEducation from './DepartmentOfEducation';
import DepartmentOfVeteranAffairs from './DepartmentOfVeteranAffairs';
import DepartmentOfHomelandSecurity from './DepartmentOfHomelandSecurity';
import EnvironmentalProtectionAgency from './EnvironmentalProtectionAgency';
import NationalAeronauticsAndSpaceAdministration from './NationalAeronauticsAndSpaceAdministration';
import TrendingBills from './TrendingBills';
import GAOReports from './GAOReports';
import LegislativeBranch from './LegislativeBranch';
import HouseComitteeOnAgriculture from './HouseComitteeOnAgriculture';
import HouseCommitteeOnAppropriations from './HouseCommitteeOnArmedServices';

import App from './App';
import Overlay from 'react-overlays/esm/Overlay';
import ExecutiveOrders from './ExecutiveOrders';
import PresidentialProclamations from './PresidentialProclamations';
import NationalScienceFoundation from './NationalScienceFoundation';

import{ whiteHouseSearch, createPresidentialProclamations, createExecutiveOrders, createWhiteHouseDocuments, createWhiteHouseFeed,
  createWhiteHouseFeedMobile,createDepartmentOfState,
  createDepartmentOfDefense, 
  createDepartmentOfJustice, createDepartmentOfTheInterior,
  createDepartmentOfAgriculture, createDepartmentOfCommerce,
  createDepartmentOfCommerceMobile, createDepartmentOfLabor,
  createDepartmentOfHealthAndHumanServices, createDepartmentOfHousingAndUrbanDevelopment, createDepartmentOfEducation
  ,  createDepartmentOfVeteransAffairs, createDepartmentOfHomelandSecurity
  , createEnvironmentalProtectionAgency,
  createDepartmentOfTreasury, createNationalScienceFoundation, 
   createEnvironmentalProtectionAgencyMobile, createNationalAeronauticsAndSpaceAdministration, Speak, createFederalRegisterDefenseDepartment, createFederalRegisterStateDepartment, createFederalRegisterTreasuryDepartment, createFederalRegisterJusticeDepartment, createFederalRegisterInteriorDepartment, createFederalRegisterAgricultureDepartment, createFederalRegisterCommerceDepartment, createFederalRegisterLaborDepartment, createFederalRegisterHealthAndHumanServicesDepartment, createFederalRegisterHousingAndUrbanDevelopmentDepartment, createFederalRegisterHomelandSecurityDepartment, createFederalRegisterEducationDepartment, createFederalRegisterVeteransAffairsDepartment,
   CreateFeedItem, CreateFeedItemBillUpdates, CreateFeedItemWithDocButton,
   CreateFeedItemPresidentialDocuments, CreateFeedItemExecutiveOrders, CreateFeedItemCongressionalReports, CreateFeedItemExecutiveOffice, CreateFeedItemBillWithDescription, CreateFeedItemNoDate, CreateFeedAPIDepartmentUS } from './Functions'
import ExecutiveBranchMobile from './ExecutiveBranchMobile';
import DesktopNavbar from './DesktopNavbar';
import DepartmentOfTheInteriorDocs from './DepartmentOfTheInteriorDocs';
import FishingAndWildlifeService from './FishingAndWildlifeService';
import GeologicalSurvey from './GeologicalSurvey';
import InteriorDeptHearings from './InteriorDeptHearings';
import IndianAffairsBureau from './IndianAffairsBureau';
import LandManagementBureau from './LandManagementBureau';
import NationalIndianGamingCommission from './NationalIndianGamingCommission';
import NationalParkService from './NationalParkService';
import ReclamationBureau from './ReclamationBureau';
import SpecialTrusteeForAmericanIndians from './SpecialTrusteeForAmericanIndians';
import SurfaceMiningReclamation from './SurfaceMiningReclamation';
import OceanEnergyManagmentBureau from './OceanEnergyManagmentBureau';
import SafetyAndEnvironmentEnforcement from './SafetyAndEnvironmentEnforcement';
import NaturalResourcesRevenueOffice from './NaturalResourcesRevenueOffice';
import TransportationMobile from './TransportationMobile';
import InteriorMobile from './InteriorMobile';
import DepartmentOfCommerceDocuments from './DepartmentOfCommerceDocuments';
import CensusBureau from './CensusBureau';
import EconomicAnalysisBureau from './EconomicAnalysisBureau';
import EconomicDevelopmentAdministration from './EconomicDevelopmentAdministration';
import EconomicsAndStatistics from './EconomicsAndStatistics';
import ForeignTradeZonesBoard from './ForeignTradeZonesBoard';
import IndustryAndSecurityBureau from './IndustryAndSecurityBureau';
import InternationalTradeAdministration from './InternationalTradeAdministration';
import MinorityBusinessDevelopmentAgency from './MinorityBusinessDevelopmentAgency';
import NationalInstituteOfStandardsAndTech from './NationalInstituteOfStandardsAndTech';
import NationalOceanicAndAtmosphericAdministration from './NationalOceanicAndAtmosphericAdministration';
import NationalTechnicalInformationService from './NationalTechnicalInformationService';
import NationalTelecommunicationsAndInformationAdmin from './NationalTelecommunicationsAndInformationAdmin';
import PatentAndTradeMarkOffice from './PatentAndTradeMarkOffice';
import UnderSecretaryForEconomicAffairs from './UnderSecretaryForEconomicAffairs';
import CommerceMobile from './CommerceMobile';

import DepartmentOfHousingAndUrbanDevelopmentDocs from './DepartmentOfHousingAndUrbanDevelopmentDocs';
import FederalHousingEnterprise from './FederalHousingEnterprise';
import HousingMobile from './HousingMobile';
import DepartmentOfLaborDocs from './DepartmentOfLaborDocs';
import DisabilityEmploymentPolicy from './DisabilityEmploymentPolicy';
import EmployeeBenefitsSecuirtyAdministration from './EmployeeBenefitsSecuirtyAdministration';
import EmployeesCompensationAppeals from './EmployeesCompensationAppeals';
import EmploymentAndTrainingAdmin from './EmploymentAndTrainingAdmin';
import FederalContractCompliancePrograms from './FederalContractCompliancePrograms';
import LaborStatsBureau from './LaborStatsBureau';
import LaborManagmentStandardsOffice from './LaborManagmentStandardsOffice';
import MineSafety from './MineSafety';
import OccupationalSafetyAndHealthAdmin from './OccupationalSafetyAndHealthAdmin';
import VeteransAffairsMobile from './VeteransAffairsMobile';
import VeteransEmployment from './VeteransEmployment';
import WageAndHourDivision from './WageAndHourDivision';
import WorkersCompenstaionPrograms from './WorkersCompenstaionPrograms';
import BenefitsReviewBoard from './BenefitsReviewBoard';
import LaborMobile from './LaborMobile';
import GenerateFeedDepartment from './GenerateFeedDepartment';

var collectionCounter = 0
var carouselInterval = 30000;
var mobileCarouselInterval = 60000;





  

 


  


export const DepartmentOfLaborDesk = () =>
 {
   render()
   
   {
    const [show, setShow] = useState(true);

        
    const [fullscreen, setFullScreen] = useState(true);
  
  
    const [fullBillModalIsOpen, setFullBillModalIsOpen] = useState(false)
  
    const [reportModalIsOpen, setReportModalIsOpen] = useState(false)
  
  
    const [whiteHouseFeedModalIsOpen, setWhiteHouseFeedModalIsOpen] = useState(false)
    const [whiteHouseDocsModalIsOpen, setWhiteHouseDocsModalIsOpen] = useState(false)
  
  
    const [courtNewsModalIsOpen, setCourtNewsModalIsOpen] = useState(false)
    const [courtModalIsOpen, setCourtModalIsOpen] = useState(false)
  
    const [courtAppealsModalIsOpen, setCourtAppealsModalIsOpen] = useState(false)
  
    const [newLawsModalIsOpen, setNewLawsModalIsOpen] = useState(false)
  
    const [billsEnrolledModalIsOpen, setBillsEnrolledModalIsOpen] = useState(false)
  
    const [legislativePageBillsEnrolledModalIsOpen, setLegislativePageBillsEnrolledModalIsOpen] = useState(false)
  
  
    const [departmentOfStateModalIsOpen, setDepartmentOfStateModalIsOpen] = useState(false)
    const [departmentOfTreasuryModalIsOpen, setDepartmentOfTreasuryModalIsOpen] = useState(false)
 
    const [departmentOfJusticeModalIsOpen, setDepartmentOfJusticeModalIsOpen] = useState(false)
    const [departmentOfTheInteriorModalIsOpen, setDepartmentOfTheInteriorModalIsOpen] = useState(false)
    const [departmentOfAgricultureModalIsOpen, setDepartmentOfAgricultureModalIsOpen] = useState(false)
    const [departmentOfCommerceModalIsOpen, setDepartmentOfCommerceModalIsOpen] = useState(false)
    const [departmentOfLaborModalIsOpen, setDepartmentOfLaborModalIsOpen] = useState(false)
    const [departmentOfHealthAndHumanServicesModalIsOpen, setDepartmentOfHealthAndHumanServicesModalIsOpen] = useState(false)
    const [departmentOfHousingAndUrbanDevelopmentModalIsOpen, setDepartmentOfHousingAndUrbanDevelopmentModalIsOpen] = useState(false)
    const [departmentOfTransportationModalIsOpen, setDepartmentOfTransportationModalIsOpen] = useState(false)
    const [departmentOfEnergyModalIsOpen, setDepartmentOfEnergyModalIsOpen] = useState(false)
    const [departmentOfEducationModalIsOpen, setDepartmentOfEducationModalIsOpen] = useState(false)
    const [departmentOfVeteransAffairsModalIsOpen, setDepartmentOfVeteransAffairsModalIsOpen] = useState(false)
    const [departmentOfHomelandSecurityModalIsOpen, setDepartmentOfHomelandSecurityModalIsOpen] = useState(false)
    const [epaModalIsOpen, setEPAModalIsOpen] = useState(false)
    const [nsfModalIsOpen, setNSFModalIsOpen] = useState(false)
    const [nasaModalIsOpen, setNASAModalIsOpen] = useState(false)
    const [trendingBillsModalIsOpen, setTrendingBillsModalIsOpen] = useState(false)
    const [goaReportsModalIsOpen, setGAOReportsModalIsOpen] = useState(false)
    const [executiveOrdersModalIsOpen, setExecutiveOrdersModalIsOpen] = useState(false)
    const [presidentialProclamationsModalIsOpen, setPresidentialProclamationsModalIsOpen] = useState(false)
    const [statesModalIsOpen, setStatesModalIsOpen] = useState(false)
  
  
    const [collectionsModalIsOpen, setCollectionsModalIsOpen] = useState(false)
    const [aboutModalIsOpen, setAboutModalIsOpen] = useState(false)
  
  
    const [federalRegisterDefenseDepartmentModalIsOpen, setFederalRegisterDefenseDepartmentModalIsOpen] = useState(false)
const [federalRegisterStateDepartmentModalIsOpen, setFederalRegisterStateDepartmentModalIsOpen] = useState(false)
    const [declarationModalIsOpen, setDeclarationModalIsOpen] = useState(false)
    const [constitutionModalIsOpen, setConstitutionModalIsOpen] = useState(false)
    const [billOfRightsnModalIsOpen, setBillOfRightsModalIsOpen] = useState(false)

    const [economicAdModalIsOpen, setEconomicAdModalIsOpen] = useState(false)
    const [environmentQualityModalIsOpen, setEnvironmentQualityModalIsOpen] = useState(false)
    const [domesticPolicyModalIsOpen, setDomesticPolicyModalIsOpen] = useState(false)
    const [genderPolicyModalIsOpen, setGenderPolicyModalIsOpen] = useState(false)
    const [nationalEconomicModalIsOpen, setNationalEconomicModalIsOpen] = useState(false)
    const [securityModalIsOpen, setSecurityModalIsOpen] = useState(false)
    const [budgetModalIsOpen, setBudgetModalIsOpen] = useState(false)
    const [drugModalIsOpen, setDrugModalIsOpen] = useState(false)
    const [publicModalIsOpen, setPublicModalIsOpen] = useState(false)
    const [scienceModalIsOpen, setScienceModalIsOpen] = useState(false)
    const [cyberModalIsOpen, setCyberModalIsOpen] = useState(false)
    const [presidentialModalIsOpen, setPresidentialModalIsOpen] = useState(false)

    const [departmentOfDefenseModalIsOpen, setDepartmentOfDefenseModalIsOpen] = useState(false)
    const [defenseDocumentsModalIsOpen, setDefenseDocumentsModalIsOpen] = useState(false)
    const [armyModalIsOpen, setArmyModalIsOpen] = useState(false)
    const [navyModalIsOpen, setNavyModalIsOpen] = useState(false)
    const [marinesModalIsOpen, setMarinesModalIsOpen] = useState(false)
    const [spaceForceModalIsOpen, setSpaceForceModalIsOpen] = useState(false)
    const [airForceModalIsOpen, setAirForceModalIsOpen] = useState(false)
    const [nationalGuardModalIsOpen, setNationalGuardModalIsOpen] = useState(false)
    const [ciaModalIsOpen, setCIAModalIsOpen] = useState(false)
    const [nsaModalIsOpen, setNSAModalIsOpen] = useState(false)
    const [armyCorpsModalIsOpen, setArmyCorpsModalIsOpen] = useState(false)

    const [coastGuardModalIsOpen, setCoastGuardModalIsOpen] = useState(false)
    const [femaModalIsOpen, setFEMAModalIsOpen] = useState(false)
    const [secretServiceModalIsOpen, setSecretServiceModalIsOpen] = useState(false)
    const [tsaModalIsOpen, setTSAModalIsOpen] = useState(false)
    const [immigrationModalIsOpen, setImmigrationModalIsOpen] = useState(false)
    const [usCustomsModalIsOpen, setUSCustomsModalIsOpen] = useState(false)
    const [enforcementsModalIsOpen, setEnforcemnetsModalIsOpen] = useState(false)
    const [homelandDocsModalIsOpen, setHomelandDocsModalIsOpen] = useState(false)
    const [laborModalIsOpen, setLaborModalIsOpen] = useState(false)
    const [showBioModal, setBioModal] = useState(false);
    const [showMissionModal, setMissionModal] = useState(false);
  return(
    <>
      <BrowserView>
        <div id='homePage'>

     

        <div id='homebg'>

          <DesktopNavbar/>
          <div id='feedDisplay' 
                  style={{ padding: '20px', borderRadius: '18px', marginTop: '0em'}}>

<div style={{ position: 'relative' }}>

     <div id='profileImage' style={{ width: '150px', height: '150px', borderRadius: '100%', overflow: 'hidden',  marginLeft: '40px' }}>
     <Image id='profileImage-image' src='/departmentOfLabor.png' />
   </div>


   <div style={{ position: 'absolute', top: '0', left: '250px', display: 'flex', flexDirection: 'column' }}>
   <div style={{ color: 'white', fontSize: '24px', fontWeight: 'bold' }}>

</div>
<div  style={{ color: 'white', fontSize: '20px' }}> 
<span> <h3  >Department of Labor </h3></span>
<br></br>
<div style={{ color: 'white', fontSize: '20px' }}>
<span> The Department of Labor oversees federal programs for ensuring a strong American workforce. These programs address job training, safe working conditions, minimum hourly wage and overtime pay, employment discrimination, and unemployment insurance.</span>
<br></br>
<div style={{ color: 'white', fontSize: '18px' }}>

       <span><Link style={{color: '#21cff5'}} onClick={(e) => {
          e.preventDefault();
          window.open("https://www.dol.gov/", "_blank");
        }}>
          https://www.dol.gov/
          </Link></span> 
          <br></br>
<Button id='whDocsViewMoreButton' onClick={() =>  setMissionModal(true)}>  <Files size={20}></Files> Read More</Button>
</div>
  </div>

     </div>
     </div>


     </div>

           </div>

           <Modal id='fullBillModal'  size="xl" aria-labelledby="contained-modal-title-vcenter" show={showMissionModal} onHide={() => setMissionModal(false)}>
         <Modal.Header id='billModalHeader' closeButton><h3> Department of Labor</h3></Modal.Header>
       
         <div id='fullBillFeed' >
         <p>The Department of Labor oversees federal programs for ensuring a strong American workforce. These programs address job training, safe working conditions, minimum hourly wage and overtime pay, employment discrimination, and unemployment insurance.</p>

<p>The Department of Labor’s mission is to foster and promote the welfare of the job seekers, wage earners, and retirees of the United States by improving their working conditions, advancing their opportunities for profitable employment, protecting their retirement and health care benefits, helping employers find workers, strengthening free collective bargaining, and tracking changes in employment, prices, and other national economic measurements.</p>

<p>Offices within the Department of Labor include the Occupational Safety & Health Administration, which promotes the safety and health of America’s working men and women, and the Bureau of Labor Statistics, the federal government’s principal statistics agency for labor economics.</p>

<p>The Secretary of Labor oversees 15,000 employees on a budget of approximately $12 billion.</p>



<br></br>
<Link style={{color: '#21cff5'}} onClick={(e) => {
     e.preventDefault();
     window.open("https://www.whitehouse.gov/about-the-white-house/our-government/the-executive-branch/#:~:text=of%20federal%20laws.-,DEPARTMENT%20OF%20LABOR,-The%20Department%20of", "_blank");
   }}>
     https://www.whitehouse.gov/about-the-white-house/our-government/the-executive-branch/#:~:text=of%20federal%20laws.-,DEPARTMENT%20OF%20LABOR,-The%20Department%20of
     </Link>
 </div>

       </Modal>
           <br></br> <br></br> <br></br>
            
            <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferTwo'>
                   
                    <Row>

                      <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>
                        <h4 id='whBreifingFeedHeading' onClick={() => setDepartmentOfDefenseModalIsOpen(true)}><b>News</b></h4>


<div  id='laborDepartment'></div>

{CreateFeedItemNoDate('https://www.dol.gov/rss/releases.xml', 'laborDepartment', 'Department of Labor', 'Executive', 'Economy', DepartmentOfLabor)}

<Modal id='whiteHouseDocsModal' show={departmentOfDefenseModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfDefenseModalIsOpen(false)}>
                <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Labor</h3></Modal.Header>
                <DepartmentOfLabor/>
              </Modal>
                        </div>
                      </Col>

                      <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>
                        <h4 id='whBreifingFeedHeading' onClick={() => setDefenseDocumentsModalIsOpen(true)}><b>Documents</b></h4>


<div id='frDefense'></div>

{CreateFeedItem('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=labor-department', 'frDefense', 'Department of Labor Documents', 'Executive', 'N/A', DepartmentOfLaborDocs)}

<Modal id='whiteHouseDocsModal' show={defenseDocumentsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDefenseDocumentsModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Labor Documents</h3></Modal.Header>
                  <DepartmentOfLaborDocs/>
                </Modal>
                        </div>
                      </Col>


                    </Row>


                  </Container>
                </Container>
                </div>    
           
                <div id='branchPage'>
  <Container class='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/disability-employment-policy', "_self")}}><b>Disability Employment Policy Office</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defDepo'></div>
            {CreateFeedAPIDepartmentUS('&table_name=labor&agency=Disability%20Employment%20Policy%20Office', 'defDepo',
              'Disability Employment Policy Office Documents', 'Executive',
              'Economy', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={laborModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setLaborModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Disability Employment Policy Office</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=labor&agency=Disability%20Employment%20Policy%20Office'
                name='Disability Employment Policy Office' branch='Executive' topic='Economy' buttonName='Doc' />
            </Modal>
          </div>
        </Col>

        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/employee-benefits-security', "_self")}}><b>Employee Benefits Security Administration</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defEbsa'></div>
            {CreateFeedAPIDepartmentUS('&table_name=labor&agency=Employee%20Benefits%20Security%20Administration', 'defEbsa',
              'Employee Benefits Security Administration Documents', 'Executive',
              'Economy', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={laborModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setLaborModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Employee Benefits Security Administration</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=labor&agency=Employee%20Benefits%20Security%20Administration'
                name='Employee Benefits Security Administration' branch='Executive' topic='Economy' buttonName='Doc' />
            </Modal>
          </div>
        </Col>
      </Row>
    </Container>
  </Container>
</div>

<div id='branchPage'>
  <Container class='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/employees-compensation-appeals', "_self")}}><b>Employees Compensation Appeals Board</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defEcab'></div>
            {CreateFeedAPIDepartmentUS('&table_name=labor&agency=Employees%20Compensation%20Appeals%20Board', 'defEcab',
              'Employees Compensation Appeals Board Documents', 'Executive',
              'Economy', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={laborModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setLaborModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Employees Compensation Appeals Board</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=labor&agency=Employees%20Compensation%20Appeals%20Board'
                name='Employees Compensation Appeals Board' branch='Executive' topic='Economy' buttonName='Doc' />
            </Modal>
          </div>
        </Col>

        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/employment-training', "_self")}}><b>Employment and Training Administration</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defEta'></div>
            {CreateFeedAPIDepartmentUS('&table_name=labor&agency=Employment%20and%20Training%20Administration', 'defEta',
              'Employment and Training Administration Documents', 'Executive',
              'Economy', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={laborModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setLaborModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Employment and Training Administration</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=labor&agency=Employment%20and%20Training%20Administration'
                name='Employment and Training Administration' branch='Executive' topic='Economy' buttonName='Doc' />
            </Modal>
          </div>
        </Col>
      </Row>
    </Container>
  </Container>
</div>

<div id='branchPage'>
  <Container class='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/employment-standards', "_self")}}><b>Employment Standards Administration</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defEsa'></div>
            {CreateFeedAPIDepartmentUS('&table_name=labor&agency=Employment%20Standards%20Administration', 'defEsa',
              'Employment Standards Administration Documents', 'Executive',
              'Economy', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={laborModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setLaborModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Employment Standards Administration</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=labor&agency=Employment%20Standards%20Administration'
                name='Employment Standards Administration' branch='Executive' topic='Economy' buttonName='Doc' />
            </Modal>
          </div>
        </Col>

        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/contract-compliance', "_self")}}><b>Federal Contract Compliance Programs Office</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defFccpo'></div>
            {CreateFeedAPIDepartmentUS('&table_name=labor&agency=Federal%20Contract%20Compliance%20Programs%20Office', 'defFccpo',
              'Federal Contract Compliance Programs Office Documents', 'Executive',
              'Economy', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={laborModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setLaborModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Federal Contract Compliance Programs Office</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=labor&agency=Federal%20Contract%20Compliance%20Programs%20Office'
                name='Federal Contract Compliance Programs Office' branch='Executive' topic='Economy' buttonName='Doc' />
            </Modal>
          </div>
        </Col>
      </Row>
    </Container>
  </Container>
</div>

<div id='branchPage'>
  <Container class='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/labor-statistics', "_self")}}><b>Labor Statistics Bureau</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defLsb'></div>
            {CreateFeedAPIDepartmentUS('&table_name=labor&agency=Labor%20Statistics%20Bureau', 'defLsb',
              'Labor Statistics Bureau Documents', 'Executive',
              'Economy', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={laborModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setLaborModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Labor Statistics Bureau</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=labor&agency=Labor%20Statistics%20Bureau'
                name='Labor Statistics Bureau' branch='Executive' topic='Economy' buttonName='Doc' />
            </Modal>
          </div>
        </Col>

        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/labor-management-standards', "_self")}}><b>Labor-Management Standards Office</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defLmso'></div>
            {CreateFeedAPIDepartmentUS('&table_name=labor&agency=Labor-Management%20Standards%20Office', 'defLmso',
              'Labor-Management Standards Office Documents', 'Executive',
              'Economy', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={laborModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setLaborModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Labor-Management Standards Office</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=labor&agency=Labor-Management%20Standards%20Office'
                name='Labor-Management Standards Office' branch='Executive' topic='Economy' buttonName='Doc' />
            </Modal>
          </div>
        </Col>
      </Row>
    </Container>
  </Container>
</div>

<div id='branchPage'>
  <Container class='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/mine-safety', "_self")}}><b>Mine Safety and Health Administration</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defMsha'></div>
            {CreateFeedAPIDepartmentUS('&table_name=labor&agency=Mine%20Safety%20and%20Health%20Administration', 'defMsha',
              'Mine Safety and Health Administration Documents', 'Executive',
              'Economy', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={laborModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setLaborModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Mine Safety and Health Administration</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=labor&agency=Mine%20Safety%20and%20Health%20Administration'
                name='Mine Safety and Health Administration' branch='Executive' topic='Economy' buttonName='Doc' />
            </Modal>
          </div>
        </Col>

        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/osha', "_self")}}><b>Occupational Safety and Health Administration</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defOsha'></div>
            {CreateFeedAPIDepartmentUS('&table_name=labor&agency=Occupational%20Safety%20and%20Health%20Administration', 'defOsha',
              'Occupational Safety and Health Administration Documents', 'Executive',
              'Economy', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={laborModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setLaborModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Occupational Safety and Health Administration</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=labor&agency=Occupational%20Safety%20and%20Health%20Administration'
                name='Occupational Safety and Health Administration' branch='Executive' topic='Economy' buttonName='Doc' />
            </Modal>
          </div>
        </Col>
      </Row>
    </Container>
  </Container>
</div>

<div id='branchPage'>
  <Container class='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/pension-welfare', "_self")}}><b>Pension and Welfare Benefits Administration</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defPwba'></div>
            {CreateFeedAPIDepartmentUS('&table_name=labor&agency=Pension%20and%20Welfare%20Benefits%20Administration', 'defPwba',
              'Pension and Welfare Benefits Administration Documents', 'Executive',
              'Economy', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={laborModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setLaborModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Pension and Welfare Benefits Administration</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=labor&agency=Pension%20and%20Welfare%20Benefits%20Administration'
                name='Pension and Welfare Benefits Administration' branch='Executive' topic='Economy' buttonName='Doc' />
            </Modal>
          </div>
        </Col>

        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/veterans-employment', "_self")}}><b>Veterans Employment and Training Service</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defVets'></div>
            {CreateFeedAPIDepartmentUS('&table_name=labor&agency=Veterans%20Employment%20and%20Training%20Service', 'defVets',
              'Veterans Employment and Training Service Documents', 'Executive',
              'Economy', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={laborModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setLaborModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Veterans Employment and Training Service</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=labor&agency=Veterans%20Employment%20and%20Training%20Service'
                name='Veterans Employment and Training Service' branch='Executive' topic='Economy' buttonName='Doc' />
            </Modal>
          </div>
        </Col>
      </Row>
    </Container>
  </Container>
</div>

<div id='branchPage'>
  <Container class='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/wage-hour', "_self")}}><b>Wage and Hour Division</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defWhd'></div>
            {CreateFeedAPIDepartmentUS('&table_name=labor&agency=Wage%20and%20Hour%20Division', 'defWhd',
              'Wage and Hour Division Documents', 'Executive',
              'Economy', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={laborModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setLaborModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Wage and Hour Division</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=labor&agency=Wage%20and%20Hour%20Division'
                name='Wage and Hour Division' branch='Executive' topic='Economy' buttonName='Doc' />
            </Modal>
          </div>
        </Col>

        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/workers-compensation', "_self")}}><b>Workers' Compensation Programs Office</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defWcpo'></div>
            {CreateFeedAPIDepartmentUS('&table_name=labor&agency=Workers%27%20Compensation%20Programs%20Office', 'defWcpo',
              'Workers Compensation Programs Office Documents', 'Executive',
              'Economy', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={laborModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setLaborModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Workers' Compensation Programs Office</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=labor&agency=Workers%27%20Compensation%20Programs%20Office'
                name='Workers Compensation Programs Office' branch='Executive' topic='Economy' buttonName='Doc' />
            </Modal>
          </div>
        </Col>
      </Row>
    </Container>
  </Container>
</div>

       


   

            <Modal id='aboutModal' show={aboutModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setAboutModalIsOpen(false)}>
            <div id='removedAlert'></div>
            <Modal.Header id='aboutModalHeader' closeButton><h3>About</h3></Modal.Header>
  <ModalBody id='aboutInfo'><p id='aboutParagraph'>Gov Glance was created to drive civic engagement and support a 
              healthier democracy by helping citizens get official Government news in an easy-to-navigate centralized location. 
              There will never be any opinion or political analysis(That's your job), only information from official Government 
              sources. Gov Glance is structured after the U.S. government that is broken into three branches Legislative, 
              Executive, and Judicial. To learn more about the structure and functions of The U.S. Government go to <a href='https://www.usa.gov/branches-of-government'>https://www.usa.gov/branches-of-government</a>.
              </p>

              <p id='aboutParagraph'>
              Gov Glance Foundation Inc. is a non-profit that was created to operate govglance.org and continue to create educational tools that help people learn about the workings of society
              </p>
            <br></br>

            <h3>Privacy</h3>

            <br></br>

              <p id='aboutParagraph'>Gov Glance does not collect any personal user information. 
              Features like collection save news items directly to your browser's local storage for your convenience
              and is not shared with Gov Glance servers. We do not collect, store, or send personal information to Google LLC, 
              but we do use some Google services to deliver our app to you. For any questions into Google’s privacy practices 
              here is a link to their privacy policy: <a href='https://policies.google.com/privacy?hl=en-US#intro'>https://policies.google.com/privacy?hl=en-US#intro</a>

              <br></br><br></br>

              For site analytics we use plausible.io an open source privacy friendly analytics provider. <a href="https://plausible.io/about" target="_blank">https://plausible.io/about</a>
            
            </p>
            <br></br>


            <h3>Contact</h3>

            <p id='aboutParagraph'>
                      Contact us via email: <a href='mailto:contact@govglance.org'>contact@govglance.org</a>

            </p>

            <br></br>


            
            </ModalBody>
          </Modal>
 
          <footer id='homeFooter'>
      <Navbar>
        <Row id='footerNav'>
      <Nav variant='pills'>
        
     <Nav.Link id='footerContent'>Gov Glance Foundation Inc.
</Nav.Link>
<Nav.Item>
<Nav.Link id='footerContent' href='mailto:contact@govglance.org'>Contact</Nav.Link>
</Nav.Item>
<Nav.Item>
<Nav.Link id='footerContent' onClick={(e) => {
                   e.preventDefault();
                   window.open('https://donate.stripe.com/28o3fXbdvbQO2RO288', "_blank")
                 }}> <HeartFill id='donateIcon'/>    Donate</Nav.Link>
</Nav.Item>
<Nav.Link style={{color: '#21cff5'}} onClick={(e) => {
                   e.preventDefault();
                   window.open('/about', "_self")}} id='footerContent'>Privacy</Nav.Link>
    
     
 </Nav>
 </Row>
 </Navbar>
      </footer>



</div>
         </div>
          

              </BrowserView>

              <MobileView>

    <LaborMobile/>

              </MobileView>
              
    </>
  )
}
 }



  






export default DepartmentOfLaborDesk;