import App from "../../App";
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Image, ModalFooter } from 'react-bootstrap';
import { ArrowsAngleExpand, InfoCircle, FileText, PauseCircle, PlayCircle, FolderPlus, ChevronRight, Files, Folder, Pen, ChatLeftQuote, ShieldShaded, People, Coin, HeartFill } from 'react-bootstrap-icons'
import '../../App.css';
import 'bootstrap/dist/css/bootstrap.min.css'


import { render } from '@testing-library/react';

import{ ListItem, createFullBillFeed, saveToCollection, createHouseCommitteeOversightAndReform, createHouseCommitteeOnRules,
    createHouseCommitteeOnSmallBusiness, createHouseCommitteeOnScienceSpaceAndTechnology,
    createHouseCommitteeOnTransportationAndInfrastructure,
    createHouseCommitteeOnVeteransAffairs,
    createCongressionalBudgetOffice, createBillFeedTwo,
    createBillFeedTwoMobile, createReports, createHouseCommitteeOnAgriculture,
     createHouseCommitteeOnAppropriations, createHouseCommitteeOnArmedServices,
     createHouseCommitteeOnTheBudget, createHouseCommitteeOnEducationAndLabor,
    createHouseCommitteeOnEnergyAndCommerce, createHouseCommitteeOnEthics,
     createHouseCommitteeOnFinancialServices, createHouseCommitteeOnForeignAffairs, createHouseCommitteeOnHomelandSecurity,
    createHouseCommitteeOnHouseAdministration, 
    createHouseCommitteeOnTheJudiciary, createHouseCommitteeOnNaturalResources, 
     createHouseCommitteeOnNaturalResourcesMobile, createGAOReports,
     createNewLaws, createBillsEnrolled
    , createBillsEnrolledMobile, createTrendingBills
    , createHouseFloorToday, congressSearch, Speak, createGAOReportsMobile, createCongressionalHearings, createBillStatus, CreateFeedItem,
    CreateFeedItemBillUpdates, CreateFeedItemWithDocButton, CreateFeedItemWithLawButton,
    CreateFeedItemGAOReports, CreateFeedItemCongressionalReports, CreateFeedItemWithDocumentFlip, CreateFeedItemWithLawButtonFlip, CreateFeedItemWithDocButtonDateFix, CreateFeedItemCanadaDateFix, CreateFeedItemMexico, CreateFeedAPI} from '../../Functions'

import MobileOffCanvas from "../../MobileOffCanvas";
import MexicoMobileTopNavbar from "../MexicoMobileTopNavbar";
import SEAgency from "../Executive/SeAgency";
import SecturAgency from "../Executive/SecturAgency";
import SHCPAgency from "../Executive/SHCPAgency";
import SedatuAgency from "../Executive/SedatuAgency";
import SCTAgency from "../Executive/SCTAgency";
import GenerateFeed from "../../GenerateFeed";




var collectionCounter = 0
var carouselInterval = 30000;
var mobileCarouselInterval = 60000;



export const MexicoInfrastructureMobile = () =>   {
    render()
    {
  
  
        const [show, setShow] = useState(true);

        const [modalIsOpen, setModalIsOpen] = useState(false)
        const [fullscreen, setFullScreen] = useState(true);
      
      
        const [fullBillModalIsOpen, setFullBillModalIsOpen] = useState(false)
      
        const [reportModalIsOpen, setReportModalIsOpen] = useState(false)
      
      
        const [whiteHouseFeedModalIsOpen, setWhiteHouseFeedModalIsOpen] = useState(false)
        const [whiteHouseDocsModalIsOpen, setWhiteHouseDocsModalIsOpen] = useState(false)
      
      
        const [courtNewsModalIsOpen, setCourtNewsModalIsOpen] = useState(false)
        const [courtModalIsOpen, setCourtModalIsOpen] = useState(false)
      
        const [courtAppealsModalIsOpen, setCourtAppealsModalIsOpen] = useState(false)
      
        const [newLawsModalIsOpen, setNewLawsModalIsOpen] = useState(false)
      
        const [billsEnrolledModalIsOpen, setBillsEnrolledModalIsOpen] = useState(false)
      
        const [legislativePageBillsEnrolledModalIsOpen, setLegislativePageBillsEnrolledModalIsOpen] = useState(false)
      
      
        const [departmentOfStateModalIsOpen, setDepartmentOfStateModalIsOpen] = useState(false)
        const [departmentOfTreasuryModalIsOpen, setDepartmentOfTreasuryModalIsOpen] = useState(false)
        const [departmentOfDefenseModalIsOpen, setDepartmentOfDefenseModalIsOpen] = useState(false)
        const [departmentOfJusticeModalIsOpen, setDepartmentOfJusticeModalIsOpen] = useState(false)
        const [departmentOfTheInteriorModalIsOpen, setDepartmentOfTheInteriorModalIsOpen] = useState(false)
        const [departmentOfAgricultureModalIsOpen, setDepartmentOfAgricultureModalIsOpen] = useState(false)
        const [departmentOfCommerceModalIsOpen, setDepartmentOfCommerceModalIsOpen] = useState(false)
        const [departmentOfLaborModalIsOpen, setDepartmentOfLaborModalIsOpen] = useState(false)
        const [departmentOfHealthAndHumanServicesModalIsOpen, setDepartmentOfHealthAndHumanServicesModalIsOpen] = useState(false)
        const [departmentOfHousingAndUrbanDevelopmentModalIsOpen, setDepartmentOfHousingAndUrbanDevelopmentModalIsOpen] = useState(false)
        const [departmentOfTransportationModalIsOpen, setDepartmentOfTransportationModalIsOpen] = useState(false)
        const [departmentOfEnergyModalIsOpen, setDepartmentOfEnergyModalIsOpen] = useState(false)
        const [departmentOfEducationModalIsOpen, setDepartmentOfEducationModalIsOpen] = useState(false)
        const [departmentOfVeteransAffairsModalIsOpen, setDepartmentOfVeteransAffairsModalIsOpen] = useState(false)
        const [departmentOfHomelandSecurityModalIsOpen, setDepartmentOfHomelandSecurityModalIsOpen] = useState(false)
        const [epaModalIsOpen, setEPAModalIsOpen] = useState(false)
        const [nasaModalIsOpen, setNASAModalIsOpen] = useState(false)
        const [trendingBillsModalIsOpen, setTrendingBillsModalIsOpen] = useState(false)
        const [goaReportsModalIsOpen, setGAOReportsModalIsOpen] = useState(false)
          const [houseCommitteeOnAppropriationsModalIsOpen, setHouseCommitteeOnAppropriationsModalIsOpen] = useState(false)
        const [houseComitteeOnAgricultureModalIsOpen, setHouseComitteeOnAgricultureModalIsOpen] = useState(false)
          const [houseCommitteeOnArmedServicesModalIsOpen, setHouseCommitteeOnOnArmedServicesModalIsOpen] = useState(false)
          const [houseCommitteeOnTheBudgetModalIsOpen, setHouseCommitteeOnTheBudgetModalIsOpen] = useState(false)
          const [houseFloorModalIsOpen, setHouseFloorModalIsOpen] = useState(false)
          const [houseCommitteeOnEnergyAndCommerceModalIsOpen, setHouseCommitteeOnEnergyAndCommerceModalIsOpen] = useState(false)
          const [houseCommitteeOnEducationAndLaborModalIsOpen, setHouseCommitteeOnEducationAndLaborModalIsOpen] = useState(false)
          const [houseCommitteeOnEthicsModalIsOpen, setHouseCommitteeOnEthicsModalIsOpen] = useState(false)
          const [houseCommitteeOnFinancialServicesModalIsOpen, setHouseCommitteeOnFinancialServicesModalIsOpen] = useState(false)
          const [houseCommitteeOnForeignAffairsModalIsOpen, setHouseCommitteeOnForeignAffairsModalIsOpen] = useState(false)
          const [houseCommitteeOnHomelandSecurityModalIsOpen, setHouseCommitteeOnHomelandSecurityModalIsOpen] = useState(false)
          const [houseCommitteeOnHouseAdministrationModalIsOpen, setHouseCommitteeOnHouseAdministrationModalIsOpen] = useState(false)
          const [houseCommitteeOnTheJudiciaryModalIsOpen, setHouseCommitteeOnTheJudiciaryModalIsOpen] = useState(false)
          const [houseCommitteeOnNaturalResourcesModalIsOpen, setHouseCommitteeOnNaturalResourcesModalIsOpen] = useState(false)
          const [houseCommitteeOnOversightAndReformModalIsOpen, setHouseCommitteeOnOversightAndReformModalIsOpen] = useState(false)
          const [congressionalBudgetOfficeModalIsOpen, setCongressionalBudgetOfficeModalIsOpen] = useState(false)
          const [houseCommitteeOnRulesModalIsOpen, setHouseCommitteeOnRulesModalIsOpen] = useState(false)
          const [houseCommitteeOnScienceSpaceAndTechnologyModalIsOpen, setHouseCommitteeOnScienceSpaceAndTechnologyModalIsOpen] = useState(false)
          const [houseCommitteeOnSmallBusinessModalIsOpen, setHouseCommitteeOnSmallBusinessModalIsOpen] = useState(false)
          const [houseCommitteeOnTransportationAndInfrastructureModalIsOpen, setHouseCommitteeOnTransportationAndInfrastructureModalIsOpen] = useState(false)
          const [houseCommitteeOnVeteransAffairsModalIsOpen, setHouseCommitteeOnVeteransAffairsModalIsOpen] = useState(false)
          const [congressionalHearingsModalIsOpen, setCongressionalHearingsModalIsOpen] = useState(false)
          
          const [trendingBillsOptionOne, setTrendingBillsOptionOneModalIsOpen ] = useState(false)
          const [trendingBillsOptionTwo, setTrendingBillsOptionTwoModalIsOpen ] = useState(false)
          const [trendingBillsOptionThree, setTrendingBillsOptionThreeModalIsOpen ] = useState(false)
          const [trendingBillsOptionFour, setTrendingBillsOptionFourModalIsOpen ] = useState(false)
          const [trendingBillsOptionFive, setTrendingBillsOptionFiveModalIsOpen ] = useState(false)
          const [trendingBillsOptionSix, setTrendingBillsOptionSixModalIsOpen ] = useState(false)
          const [trendingBillsOptionSeven, setTrendingBillsOptionSevenModalIsOpen ] = useState(false)
          const [trendingBillsOptionEight, setTrendingBillsOptionEightModalIsOpen ] = useState(false)
          const [trendingBillsOptionNine, setTrendingBillsOptionNineModalIsOpen ] = useState(false)
          const [trendingBillsOptionTen, setTrendingBillsOptionTenModalIsOpen ] = useState(false)
          const [trendingBillsOptionEleven, setTrendingBillsOptionElevenModalIsOpen ] = useState(false)
          const [trendingBillsOptionTweleve, setTrendingBillsOptionTweleveModalIsOpen ] = useState(false)
          const [trendingBillsOptionThirteen, setTrendingBillsOptionThirteenModalIsOpen ] = useState(false)
      
        const [collectionsModalIsOpen, setCollectionsModalIsOpen] = useState(false)
        const [aboutModalIsOpen, setAboutModalIsOpen] = useState(false)
        const [statesModalIsOpen, setStatesModalIsOpen] = useState(false)
      
      
        const [declarationModalIsOpen, setDeclarationModalIsOpen] = useState(false)
        const [constitutionModalIsOpen, setConstitutionModalIsOpen] = useState(false)
        const [billOfRightsnModalIsOpen, setBillOfRightsModalIsOpen] = useState(false)
    
    
        const [billStatusModalIsOpen, setBillStatusModalIsOpen] = useState(false)
        localStorage.setItem('currentPage', 'mexico')
    return(
       <>
    <MexicoMobileTopNavbar/>
  
  
  
  
  <div id='homebg'>
 

{/*Legislative Mobile Carousel*/}

<h5 id='branchHeadingMobile' ><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-building" viewBox="0 0 16 16">
  <path d="M4 2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm3.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zM4 5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zM7.5 5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm2.5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zM4.5 8a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm2.5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm3.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5z"/>
  <path d="M2 1a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1zm11 0H3v14h3v-2.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5V15h3z"/>
</svg> Infrastructure  </h5>
{/*onClick={() => setModalIsOpen(true)}*/}
<Col>
  <h5 id='presidentialFeedHeadingMobile' onClick={() => setTrendingBillsOptionNineModalIsOpen(true)}>Secretaría de Comunicaciones y Transportes</h5>

  <div id='sct'></div>
                          
  {CreateFeedAPI('&skip=0&schema=mexico&table=sct&order_by=created_at', 'sct', 
                            'Secretaría de Comunicaciones y Transportes', 'Executive', 
                            'Environment', 'Doc', GenerateFeed)}

<Modal id='mobileFullBillModal' show={trendingBillsOptionNine} 
size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsOptionNineModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Secretaría de Comunicaciones y Transportes</h3></Modal.Header>
<GenerateFeed url='&skip=0&schema=mexico&table=sct&order_by=created_at'
                                name='Secretaría de Comunicaciones y Transportes' branch='Executive' topic='Environment' buttonName='Doc' />
</Modal>
</Col>


  <Col>
  <h5 id='presidentialFeedHeadingMobile' onClick={() => setTrendingBillsOptionTweleveModalIsOpen(true)}>Secretaría de Desarrollo Agrario, Territorial y Urbano</h5>

  <div id='sedatu'></div>
                          
                          
  {CreateFeedAPI('&skip=0&schema=mexico&table=sedatu&order_by=created_at', 'sedatu', 
                            'Secretaría de Desarrollo Agrario, Territorial y Urbano', 'Executive', 
                            'Environment', 'Doc', GenerateFeed)}

<Modal id='mobileFullBillModal' show={trendingBillsOptionTweleve} 
size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsOptionTweleveModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Secretaría de Desarrollo Agrario, Territorial y Urbano</h3></Modal.Header>
<GenerateFeed url='&skip=0&schema=mexico&table=sedatu&order_by=created_at'
                                name='Secretaría de Desarrollo Agrario, Territorial y Urbano' branch='Executive' topic='Environment' buttonName='Doc' />
</Modal>
</Col>


<Col>

<br></br><br></br><br></br><br></br>
<br></br>
<br></br>
<h5 id='headingSelectHomeCountry'>Support the Gov Glance Foundation  <Button id='donateButton' onClick={(e) => {
                        e.preventDefault();
                        window.open('https://donate.stripe.com/28o3fXbdvbQO2RO288', "_blank")
                      }}><HeartFill id='donateIcon'/>    Donate</Button></h5>

</Col>
<br></br><br></br><br></br><br></br><br></br><br></br>

<Navbar id='navbarMobileBottom' fixed="bottom">  



       
<div id='changeBranchDropdownMobile'>

                  <MobileOffCanvas/>
   
              
            </div>

</Navbar>
 
             
 
 
 
 
 
 

</div>



</>
)
}}
export default MexicoInfrastructureMobile;


