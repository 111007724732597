import React, { useState, useEffect } from 'react';
import { Container, Button, Card, Form, Spinner, Modal, Dropdown, Image } from 'react-bootstrap';
import { InfoCircle, FileText, FolderPlus, Search, ChevronDoubleLeft } from 'react-bootstrap-icons';
import './App.css'; // Make sure to import your CSS file
import UseHandleContainerClickCandidate from './UseHandleContainerClickCandidate';
const apiKey = process.env.REACT_APP_API_KEY;

let storedTitle = '';
let storedDetailsLink = '';
let storedDocLink = '';
let storedDate = '';
let storedNote = '';
let storedFeedName = '';
let storedBranchName = '';
let storedTopicName = '';
let storedDescription = '';
let collectionCounter = localStorage.getItem('collectionNumber') || 0;

function SaveFeedItem(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, 
  saveCollectionNote, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName, saveCollectionDescription) {
  storedTitle = saveCollectionTitle;
  storedDetailsLink = saveCollectionDetailLink;
  storedDocLink = saveCollectionDocLink;
  storedDate = saveCollectionDate;
  storedNote = saveCollectionNote;
  storedFeedName = saveCollectionFeedName;
  storedBranchName = saveCollectionBranchName;
  storedTopicName = saveCollectionTopicName;
  storedDescription = saveCollectionDescription;
}

function formatDate(inputDate) {
  const date = new Date(inputDate);
  const utcDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
  const day = utcDate.getDate();
  const month = utcDate.getMonth() + 1;
  const year = utcDate.getFullYear();
  return `${month}-${day}-${year}`;
}

function GenerateFeedStateRepsAll({ candidateType, stateAbbreviation: initialStateAbbreviation }) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [feedLength, setFeedLength] = useState(200);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchPerformed, setSearchPerformed] = useState(false);
  const [collectionModalIsOpen, setCollectionModalIsOpen] = useState(false);
  const [selectedState, setSelectedState] = useState('');
  const [stateAbbreviation, setStateAbbreviation] = useState(initialStateAbbreviation || '');
  const [selectedCandidateType, setSelectedCandidateType] = useState(candidateType || 'House');
  const handleContainerClick = UseHandleContainerClickCandidate();
  const requestOptions = {
    method: 'GET',
    headers: { 
      'Content-Type': 'application/json',
      "Authorization": `Bearer ${apiKey}`,
    },
  };

  const fetchData = () => {
    setLoading(true);
    let url;
    if (stateAbbreviation) {
      url = `https://api.govglance.org/elections/election_candidates?state=${stateAbbreviation}&election_type=${selectedCandidateType}&order_by=load_date&limit=${feedLength}&skip=0`;
    } else {
      url = `https://api.govglance.org/elections/election_candidates?election_type=${selectedCandidateType}&order_by=load_date&limit=${feedLength}&skip=0`;
    }
    // console.log(url);

    fetch(url, requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        const sortedData = data.sort((a, b) => new Date(b.last_file_date) - new Date(a.last_file_date));
        setData(sortedData);
        // console.log(data);
        setLoading(false);
        setError(null);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    if (initialStateAbbreviation) {
      const stateName = getStateName(initialStateAbbreviation);
      handleStateSelect(initialStateAbbreviation, stateName);
    }
  }, [initialStateAbbreviation]);

  useEffect(() => {
    fetchData();
  }, [feedLength, stateAbbreviation, selectedCandidateType]);

  const getStateName = (abbreviation) => {
    const states = {
      'AL': 'Alabama',
      'AK': 'Alaska',
      'AZ': 'Arizona',
      'AR': 'Arkansas',
      'CA': 'California',
      'CO': 'Colorado',
      'CT': 'Connecticut',
      'DE': 'Delaware',
      'FL': 'Florida',
      'GA': 'Georgia',
      'HI': 'Hawaii',
      'ID': 'Idaho',
      'IL': 'Illinois',
      'IN': 'Indiana',
      'IA': 'Iowa',
      'KS': 'Kansas',
      'KY': 'Kentucky',
      'LA': 'Louisiana',
      'ME': 'Maine',
      'MD': 'Maryland',
      'MA': 'Massachusetts',
      'MI': 'Michigan',
      'MN': 'Minnesota',
      'MS': 'Mississippi',
      'MO': 'Missouri',
      'MT': 'Montana',
      'NE': 'Nebraska',
      'NV': 'Nevada',
      'NH': 'New Hampshire',
      'NJ': 'New Jersey',
      'NM': 'New Mexico',
      'NY': 'New York',
      'NC': 'North Carolina',
      'ND': 'North Dakota',
      'OH': 'Ohio',
      'OK': 'Oklahoma',
      'OR': 'Oregon',
      'PA': 'Pennsylvania',
      'RI': 'Rhode Island',
      'SC': 'South Carolina',
      'SD': 'South Dakota',
      'TN': 'Tennessee',
      'TX': 'Texas',
      'UT': 'Utah',
      'VT': 'Vermont',
      'VA': 'Virginia',
      'WA': 'Washington',
      'WV': 'West Virginia',
      'WI': 'Wisconsin',
      'WY': 'Wyoming'
    };
    return states[abbreviation] || '';
  };

  const handleSearchButtonClick = () => {
    if (searchTerm.trim() === '') {
      return;
    }
    setSearchPerformed(true);
  };

  const handleSearchInputChange = (e) => {
    setSearchTerm(e.target.value);
    setSearchPerformed(false);
    if (e.key === 'Enter') {
      setSearchPerformed(true);
      handleSearchButtonClick();
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      setSearchPerformed(true);
      handleSearchButtonClick();
    }
  };

  const handleStateSelect = (abbreviation, stateName) => {
    setStateAbbreviation(abbreviation);
    setSelectedState(stateName);
    console.log(stateName);
  };

  const handleCandidateTypeSelect = (type) => {
    setSelectedCandidateType(type);
    if (type === 'President') {
      setStateAbbreviation('');
      setSelectedState('');
    }
  };

  const renderData = () => {
    const filteredData = data.reduce((filtered, item, index) => {
      const title = item.name || '';
      const statusTitle = item.status_title || '';
      if (statusTitle.toLowerCase().includes(searchTerm.toLowerCase())) {
        filtered.push({ title: statusTitle, index });
      } else if (title.toLowerCase().includes(searchTerm.toLowerCase())) {
        filtered.push({ title, index });
      }
      return filtered;
    }, []);
    if (filteredData.length === 0) {
      return <div id='fullBillFeed'><p>No results found.</p></div>;
    }
    return filteredData.map(({ title, index }) => {
      const titleToDisplay = data[index].name || data[index].nam;
      const link = data[index].url;
      const date = formatDate(data[index].last_file_date);
      const office = data[index].office_full;
      const party = data[index].party_full;
      const state = data[index].state;
      const district = data[index].district;
      const id = data[index].candidate_id
      console.log(id)
      const docButtonName = '';
      return (
        <div key={index}>
          <div id='fullBillFeed'>
            <Container id='feedContainer'>
              <Card.Title id='billText' onClick={() => handleContainerClick(titleToDisplay, office, state, party, district, date, data[index].candidate_id)}>{titleToDisplay}</Card.Title>
              <Card.Body id='billBodyText'>{office} (Candidate)</Card.Body>
              <Card.Body id='billBodyText'>
        {candidateType !== 'President' && `State: ${state} `}
        {party}
        {candidateType !== 'Senate' && candidateType !== 'President' && ` - District: ${district}`}
      </Card.Body>
              <Card.Body id='billBodyText'>Filing date: {date}</Card.Body>
              <Button id='billLink' onClick={(e) => {
                e.preventDefault();
                window.open(link, "_blank");
              }}><InfoCircle /> Details</Button>
              {(data[index].document_link || data[index].download?.pdfLink) && (
                <Button id='viewBillButton' onClick={(e) => {
                  e.preventDefault();
                  const linkToOpen = data[index].document_link || data[index].download?.pdfLink;
                  window.open(linkToOpen, "_blank");
                }}>
                  <FileText /> {docButtonName}
                </Button>
              )}
              <Button id='addToCollectionButton' onClick={() => {
                setCollectionModalIsOpen(true);
                SaveFeedItem(titleToDisplay, link, '', date, '', '', '', '', '');
              }}><FolderPlus /></Button>
            </Container>
          </div>
        </div>
      );
    });
  };

  const saveNote = () => {
    const searchItem = document.getElementById('noteModalFeedItem').value;
    collectionCounter = localStorage.getItem('collectionNumber');
    collectionCounter++;
    localStorage.setItem('collectionNumber', collectionCounter);
    localStorage.setItem(`title${collectionCounter}`, storedTitle);
    localStorage.setItem(`detailLink${collectionCounter}`, storedDetailsLink);
    localStorage.setItem(`docLink${collectionCounter}`, storedDocLink);
    localStorage.setItem(`itemNote${collectionCounter}`, searchItem);
    localStorage.setItem(`itemDate${collectionCounter}`, storedDate);
    localStorage.setItem(`feedName${collectionCounter}`, storedFeedName);
    localStorage.setItem(`branchName${collectionCounter}`, storedBranchName);
    localStorage.setItem(`topicName${collectionCounter}`, storedTopicName);
    localStorage.setItem(`itemDescription${collectionCounter}`, storedDescription);
    setCollectionModalIsOpen(false);
  };

  return (
    <>
      <Modal id='fullBillModal' show={collectionModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCollectionModalIsOpen(false)}>
        <Modal.Header id='billModalHeader' closeButton>
          <h3>{storedTitle}</h3>
        </Modal.Header>
        <Modal.Body id='descriptionModalBody'>
          {storedDescription}
          {storedDate}
          <div id='fullBill'>
            <Form id='noteModalForm'>
              <Form.Group id='noteModalForm' className="mb-3">
                <Form.Control id="noteModalFeedItem" placeholder="Add a note here" type="text" as="textarea" rows={2} />
                <br />
              </Form.Group>
            </Form>
            <Modal.Footer id='addNoteModalFooter'>
              <Button variant="primary" id='noteSaveToCollectionButton' onClick={saveNote}>
                Save To Collection
              </Button>
            </Modal.Footer>
          </div>
        </Modal.Body>
      </Modal>

      {loading ? (
        <div id='fullBillFeed'><Spinner animation="grow" variant="light" /></div>
      ) : (
        <>
          <div id='searchForm' className="d-flex align-items-center">
            <Dropdown className="mr-2">
              <Dropdown.Toggle id='searchCountry'>
                {selectedCandidateType}
              </Dropdown.Toggle>
              <Dropdown.Menu id="docsDropdown">
                <Dropdown.Item id="docsDropdownItem" onClick={() => handleCandidateTypeSelect('House')}>House</Dropdown.Item>
                <Dropdown.Item id="docsDropdownItem" onClick={() => handleCandidateTypeSelect('Senate')}>Senate</Dropdown.Item>
                <Dropdown.Item id="docsDropdownItem" onClick={() => handleCandidateTypeSelect('President')}>President</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            {selectedCandidateType !== 'President' && (
              <Dropdown className="mr-2">
                <Dropdown.Toggle id='searchCountry'>
                  {selectedState ? (
                    <>
                       <Image id='stateFlagDropdown' src={`/${selectedState.replace(/\s+/g, '_')}Flag.png`} />
                    </>
                  ) : (
                    'State'
                  )}
                </Dropdown.Toggle>
                <Dropdown.Menu id="docsDropdown">
                  <Dropdown.Item id="docsDropdownItem" onClick={() => handleStateSelect('', '')}>
                    All States
                  </Dropdown.Item>
                  <Dropdown.Item id="docsDropdownItem" onClick={() => handleStateSelect('AL', 'Alabama')}>
                    <Image id='stateFlagDropdown' src='/alabamaFlag.png' /> Alabama
                  </Dropdown.Item>
                  <Dropdown.Item id="docsDropdownItem" onClick={() => handleStateSelect('AK', 'Alaska')}>
                    <Image id='stateFlagDropdown' src='/alaskaFlag.png' /> Alaska
                  </Dropdown.Item>
                  <Dropdown.Item id="docsDropdownItem" onClick={() => handleStateSelect('AZ', 'Arizona')}>
                    <Image id='stateFlagDropdown' src='/arizonaFlag.png' /> Arizona
                  </Dropdown.Item>
                  <Dropdown.Item id="docsDropdownItem" onClick={() => handleStateSelect('AR', 'Arkansas')}>
                    <Image id='stateFlagDropdown' src='/arkansasFlag.png' /> Arkansas
                  </Dropdown.Item>
                  <Dropdown.Item id="docsDropdownItem" onClick={() => handleStateSelect('CA', 'California')}>
                    <Image id='stateFlagDropdown' src='/californiaFlag.png' /> California
                  </Dropdown.Item>
                  <Dropdown.Item id="docsDropdownItem" onClick={() => handleStateSelect('CO', 'Colorado')}>
                    <Image id='stateFlagDropdown' src='/coloradoFlag.png' /> Colorado
                  </Dropdown.Item>
                  <Dropdown.Item id="docsDropdownItem" onClick={() => handleStateSelect('CT', 'Connecticut')}>
                    <Image id='stateFlagDropdown' src='/connecticutFlag.png' /> Connecticut
                  </Dropdown.Item>
                  <Dropdown.Item id="docsDropdownItem" onClick={() => handleStateSelect('DE', 'Delaware')}>
                    <Image id='stateFlagDropdown' src='/delawareFlag.png' /> Delaware
                  </Dropdown.Item>
                  <Dropdown.Item id="docsDropdownItem" onClick={() => handleStateSelect('FL', 'Florida')}>
                    <Image id='stateFlagDropdown' src='/floridaFlag.png' /> Florida
                  </Dropdown.Item>
                  <Dropdown.Item id="docsDropdownItem" onClick={() => handleStateSelect('GA', 'Georgia')}>
                    <Image id='stateFlagDropdown' src='/georgiaFlag.png' /> Georgia
                  </Dropdown.Item>
                  <Dropdown.Item id="docsDropdownItem" onClick={() => handleStateSelect('HI', 'Hawaii')}>
                    <Image id='stateFlagDropdown' src='/hawaiiFlag.png' /> Hawaii
                  </Dropdown.Item>
                  <Dropdown.Item id="docsDropdownItem" onClick={() => handleStateSelect('ID', 'Idaho')}>
                    <Image id='stateFlagDropdown' src='/idahoFlag.png' /> Idaho
                  </Dropdown.Item>
                  <Dropdown.Item id="docsDropdownItem" onClick={() => handleStateSelect('IL', 'Illinois')}>
                    <Image id='stateFlagDropdown' src='/illinoisFlag.png' /> Illinois
                  </Dropdown.Item>
                  <Dropdown.Item id="docsDropdownItem" onClick={() => handleStateSelect('IN', 'Indiana')}>
                    <Image id='stateFlagDropdown' src='/indianaFlag.png' /> Indiana
                  </Dropdown.Item>
                  <Dropdown.Item id="docsDropdownItem" onClick={() => handleStateSelect('IA', 'Iowa')}>
                    <Image id='stateFlagDropdown' src='/iowaFlag.png' /> Iowa
                  </Dropdown.Item>
                  <Dropdown.Item id="docsDropdownItem" onClick={() => handleStateSelect('KS', 'Kansas')}>
                    <Image id='stateFlagDropdown' src='/kansasFlag.png' /> Kansas
                  </Dropdown.Item>
                  <Dropdown.Item id="docsDropdownItem" onClick={() => handleStateSelect('KY', 'Kentucky')}>
                    <Image id='stateFlagDropdown' src='/kentuckyFlag.png' /> Kentucky
                  </Dropdown.Item>
                  <Dropdown.Item id="docsDropdownItem" onClick={() => handleStateSelect('LA', 'Louisiana')}>
                    <Image id='stateFlagDropdown' src='/louisianaFlag.png' /> Louisiana
                  </Dropdown.Item>
                  <Dropdown.Item id="docsDropdownItem" onClick={() => handleStateSelect('ME', 'Maine')}>
                    <Image id='stateFlagDropdown' src='/maineFlag.png' /> Maine
                  </Dropdown.Item>
                  <Dropdown.Item id="docsDropdownItem" onClick={() => handleStateSelect('MD', 'Maryland')}>
                    <Image id='stateFlagDropdown' src='/marylandFlag.png' /> Maryland
                  </Dropdown.Item>
                  <Dropdown.Item id="docsDropdownItem" onClick={() => handleStateSelect('MA', 'Massachusetts')}>
                    <Image id='stateFlagDropdown' src='/massachusettsFlag.png' /> Massachusetts
                  </Dropdown.Item>
                  <Dropdown.Item id="docsDropdownItem" onClick={() => handleStateSelect('MI', 'Michigan')}>
                    <Image id='stateFlagDropdown' src='/michiganFlag.png' /> Michigan
                  </Dropdown.Item>
                  <Dropdown.Item id="docsDropdownItem" onClick={() => handleStateSelect('MN', 'Minnesota')}>
                    <Image id='stateFlagDropdown' src='/minnesotaFlag.png' /> Minnesota
                  </Dropdown.Item>
                  <Dropdown.Item id="docsDropdownItem" onClick={() => handleStateSelect('MS', 'Mississippi')}>
                    <Image id='stateFlagDropdown' src='/mississippiFlag.png' /> Mississippi
                  </Dropdown.Item>
                  <Dropdown.Item id="docsDropdownItem" onClick={() => handleStateSelect('MO', 'Missouri')}>
                    <Image id='stateFlagDropdown' src='/missouriFlag.png' /> Missouri
                  </Dropdown.Item>
                  <Dropdown.Item id="docsDropdownItem" onClick={() => handleStateSelect('MT', 'Montana')}>
                    <Image id='stateFlagDropdown' src='/montanaFlag.png' /> Montana
                  </Dropdown.Item>
                  <Dropdown.Item id="docsDropdownItem" onClick={() => handleStateSelect('NE', 'Nebraska')}>
                    <Image id='stateFlagDropdown' src='/nebraskaFlag.png' /> Nebraska
                  </Dropdown.Item>
                  <Dropdown.Item id="docsDropdownItem" onClick={() => handleStateSelect('NV', 'Nevada')}>
                    <Image id='stateFlagDropdown' src='/nevadaFlag.png' /> Nevada
                  </Dropdown.Item>
                  <Dropdown.Item id="docsDropdownItem" onClick={() => handleStateSelect('NH', 'New Hampshire')}>
                    <Image id='stateFlagDropdown' src='/newHampshireFlag.png' /> New Hampshire
                  </Dropdown.Item>
                  <Dropdown.Item id="docsDropdownItem" onClick={() => handleStateSelect('NJ', 'New Jersey')}>
                    <Image id='stateFlagDropdown' src='/newJerseyFlag.png' /> New Jersey
                  </Dropdown.Item>
                  <Dropdown.Item id="docsDropdownItem" onClick={() => handleStateSelect('NM', 'New Mexico')}>
                    <Image id='stateFlagDropdown' src='/newMexicoFlag.png' /> New Mexico
                  </Dropdown.Item>
                  <Dropdown.Item id="docsDropdownItem" onClick={() => handleStateSelect('NY', 'New York')}>
                    <Image id='stateFlagDropdown' src='/newYorkFlag.png' /> New York
                  </Dropdown.Item>
                  <Dropdown.Item id="docsDropdownItem" onClick={() => handleStateSelect('NC', 'North Carolina')}>
                    <Image id='stateFlagDropdown' src='/northCarolinaFlag.png' /> North Carolina
                  </Dropdown.Item>
                  <Dropdown.Item id="docsDropdownItem" onClick={() => handleStateSelect('ND', 'North Dakota')}>
                    <Image id='stateFlagDropdown' src='/northDakotaFlag.png' /> North Dakota
                  </Dropdown.Item>
                  <Dropdown.Item id="docsDropdownItem" onClick={() => handleStateSelect('OH', 'Ohio')}>
                    <Image id='stateFlagDropdown' src='/ohioFlag.png' /> Ohio
                  </Dropdown.Item>
                  <Dropdown.Item id="docsDropdownItem" onClick={() => handleStateSelect('OK', 'Oklahoma')}>
                    <Image id='stateFlagDropdown' src='/oklahomaFlag.png' /> Oklahoma
                  </Dropdown.Item>
                  <Dropdown.Item id="docsDropdownItem" onClick={() => handleStateSelect('OR', 'Oregon')}>
                    <Image id='stateFlagDropdown' src='/oregonFlag.png' /> Oregon
                  </Dropdown.Item>
                  <Dropdown.Item id="docsDropdownItem" onClick={() => handleStateSelect('PA', 'Pennsylvania')}>
                    <Image id='stateFlagDropdown' src='/pennsylvaniaFlag.png' /> Pennsylvania
                  </Dropdown.Item>
                  <Dropdown.Item id="docsDropdownItem" onClick={() => handleStateSelect('RI', 'Rhode Island')}>
                    <Image id='stateFlagDropdown' src='/rhodeIslandFlag.png' /> Rhode Island
                  </Dropdown.Item>
                  <Dropdown.Item id="docsDropdownItem" onClick={() => handleStateSelect('SC', 'South Carolina')}>
                    <Image id='stateFlagDropdown' src='/southCarolinaFlag.png' /> South Carolina
                  </Dropdown.Item>
                  <Dropdown.Item id="docsDropdownItem" onClick={() => handleStateSelect('SD', 'South Dakota')}>
                    <Image id='stateFlagDropdown' src='/southDakotaFlag.png' /> South Dakota
                  </Dropdown.Item>
                  <Dropdown.Item id="docsDropdownItem" onClick={() => handleStateSelect('TN', 'Tennessee')}>
                    <Image id='stateFlagDropdown' src='/tennesseeFlag.png' /> Tennessee
                  </Dropdown.Item>
                  <Dropdown.Item id="docsDropdownItem" onClick={() => handleStateSelect('TX', 'Texas')}>
                    <Image id='stateFlagDropdown' src='/texasFlag.png' /> Texas
                  </Dropdown.Item>
                  <Dropdown.Item id="docsDropdownItem" onClick={() => handleStateSelect('UT', 'Utah')}>
                    <Image id='stateFlagDropdown' src='/utahFlag.png' /> Utah
                  </Dropdown.Item>
                  <Dropdown.Item id="docsDropdownItem" onClick={() => handleStateSelect('VT', 'Vermont')}>
                    <Image id='stateFlagDropdown' src='/vermontFlag.png' /> Vermont
                  </Dropdown.Item>
                  <Dropdown.Item id="docsDropdownItem" onClick={() => handleStateSelect('VA', 'Virginia')}>
                    <Image id='stateFlagDropdown' src='/virginiaFlag.png' /> Virginia
                  </Dropdown.Item>
                  <Dropdown.Item id="docsDropdownItem" onClick={() => handleStateSelect('WA', 'Washington')}>
                    <Image id='stateFlagDropdown' src='/washingtonFlag.png' /> Washington
                  </Dropdown.Item>
                  <Dropdown.Item id="docsDropdownItem" onClick={() => handleStateSelect('WV', 'West Virginia')}>
                    <Image id='stateFlagDropdown' src='/westVirginiaFlag.png' /> West Virginia
                  </Dropdown.Item>
                  <Dropdown.Item id="docsDropdownItem" onClick={() => handleStateSelect('WI', 'Wisconsin')}>
                    <Image id='stateFlagDropdown' src='/wisconsinFlag.png' /> Wisconsin
                  </Dropdown.Item>
                  <Dropdown.Item id="docsDropdownItem" onClick={() => handleStateSelect('WY', 'Wyoming')}>
                    <Image id='stateFlagDropdown' src='/wyomingFlag.png' /> Wyoming
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )}
            
            <Form className="d-flex flex-grow-1" onSubmit={(e) => { e.preventDefault(); }}>
              <Form.Group className="d-flex w-100">
                <Form.Control
                  variant="light"
                  id="searchInput"
                  placeholder="Search..."
                  type="text"
                  value={searchTerm}
                  onChange={handleSearchInputChange}
                  onKeyDown={handleKeyDown}
                  className="flex-grow-1"
                />
                <Button id='ggButton' onClick={handleSearchButtonClick}>
                  <Search />
                </Button>
              </Form.Group>
            </Form>
          </div>

          {renderData()}

          <div id='fullBillFeed'>
            <Button id='standardButton' onClick={() => {
              setFeedLength(feedLength + 10);
            }}>More</Button>
          </div>
        </>
      )}
    </>
  );
}

export default GenerateFeedStateRepsAll;
