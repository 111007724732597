
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link, Switch, } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment, } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Tooltip, OverlayTrigger, Image } from 'react-bootstrap';
import {Lightbulb, HeartFill, Pen, People, Collection, Grid, ThreeDots, Stack, List, Files, FolderPlus, FolderCheck, Folder, Tree, FileMedical, FileMedicalFill, Person, ChevronRight  } from 'react-bootstrap-icons'
import { BrowserView, MobileView } from 'react-device-detect';
import { render } from '@testing-library/react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import DesktopNavbar from './DesktopNavbar';
import HealthMobile from './HealthMobile';
import { CreateFeedAPI, CreateFeedAPIDepartmentUS, CreateFeedItem, CreateFeedItemBillWithDescription, CreateFeedItemMaterials } from './Functions';
import DepartmentOfJustice from './DepartmentOfJustice';
import DepartmentOfJusticeDocs from './DepartmentOfJusticeDocs';
import JusticeTopicMobile from './JusticeTopicMobile';
import AlcoholTobaccoFireArms from './AlcoholTobaccoFireArms';
import AntitrustDivision from './AntitrustDivision';
import DrugEnforcementAdministration from './DrugEnforcementAdministration';
import ExecutiveOfficeForImmigrationReview from './ExecutiveOfficeForImmigrationReview';
import FederalBureauOfInvestigation from './FederalBureauOfInvestigation';
import UnitedStatesMarshalsService from './UnitedStatesMarshalsService';
import ParoleCommission from './ParoleCommission';
import PrisonBureau from './PrisonBureau';
import NationalInstituteOfJustice from './NationalInstituteOfJustice';
import NationalInstituteOfCorrections from './NationalInstituteOfCorrections';
import JuvenileJusticeAndDellinquencyPreventionOfficee from './JuvenileJusticeAndDellinquencyPreventionOffice';
import JusticeProgramsOffice from './JusticeProgramsOffice';
import ForeignClaimsSettlement from './ForeignClaimsSettlement';
import HouseCommitteeOnTheJudiciary from './HouseCommitteeOnTheJudiciary';
import GenerateFeed from './GenerateFeed';
import GenerateFeedDepartment from './GenerateFeedDepartment';

var collectionCounter = 0
var carouselInterval = 30000;
var mobileCarouselInterval = 60000;

export const JusticePage = () =>
 {
   render()
   {
 

  const [aboutModalIsOpen, setAboutModalIsOpen] = useState(false)
  const [departmentOfDefenseModalIsOpen, setDepartmentOfDefenseModalIsOpen] = useState(false)
  const [defenseDocumentsModalIsOpen, setDefenseDocumentsModalIsOpen] = useState(false)
  const [immigrationModalIsOpen, setImmigrationModalIsOpen] = useState(false)
  const [departmentOfJusticeModalIsOpen, setDepartmentOfJusticeModalIsOpen] = useState(false)
  const [departmentOfCommerceModalIsOpen, setDepartmentOfCommerceModalIsOpen] = useState(false)
  const [departmentOfLaborModalIsOpen, setDepartmentOfLaborModalIsOpen] = useState(false)
  const [departmentOfHealthAndHumanServicesModalIsOpen, setDepartmentOfHealthAndHumanServicesModalIsOpen] = useState(false)
  const [departmentOfHousingAndUrbanDevelopmentModalIsOpen, setDepartmentOfHousingAndUrbanDevelopmentModalIsOpen] = useState(false)
  const [departmentOfEnergyModalIsOpen, setDepartmentOfEnergyModalIsOpen] = useState(false)
  const [epaModalIsOpen, setEPAModalIsOpen] = useState(false)
  const [armyModalIsOpen, setArmyModalIsOpen] = useState(false)
  const [navyModalIsOpen, setNavyModalIsOpen] = useState(false)
  const [marinesModalIsOpen, setMarinesModalIsOpen] = useState(false)
  const [spaceForceModalIsOpen, setSpaceForceModalIsOpen] = useState(false)
  const [airForceModalIsOpen, setAirForceModalIsOpen] = useState(false)
  const [nationalGuardModalIsOpen, setNationalGuardModalIsOpen] = useState(false)
  const [justiceModalIsOpen, setJusticeModalIsOpen] = useState(false)
  const [modalInfo, setModalInfo] = useState({
    isOpen: false,
    title: '',
    content: null
  });

  const openModal = (title, content) => {
    setModalInfo({
      isOpen: true,
      title,
      content
    });
  };

  const closeModal = () => {
    setModalInfo({
      isOpen: false,
      title: '',
      content: null
    });
  };

  var mobileCarouselInterval = 60000;
  const CustomModal = ({ isOpen, onClose, title, content }) => (
    <Modal id='fullBillModal' show={isOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={onClose}>
      <Modal.Header id='billModalHeader' closeButton><h3>{title}</h3></Modal.Header>
      {content}
    </Modal>
  );
  return(
    <>
      <BrowserView>
        <div id='homePage'>
        <div id='homebg'>
        <DesktopNavbar/>
      
               <div id='sectionHeading'>      
               <Row>
              <h3 id='branchHeading' ><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-person-arms-up" viewBox="0 0 16 16">
  <path d="M8 3a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3"/>
  <path d="m5.93 6.704-.846 8.451a.768.768 0 0 0 1.523.203l.81-4.865a.59.59 0 0 1 1.165 0l.81 4.865a.768.768 0 0 0 1.523-.203l-.845-8.451A1.5 1.5 0 0 1 10.5 5.5L13 2.284a.796.796 0 0 0-1.239-.998L9.634 3.84a.7.7 0 0 1-.33.235c-.23.074-.665.176-1.304.176-.64 0-1.074-.102-1.305-.176a.7.7 0 0 1-.329-.235L4.239 1.286a.796.796 0 0 0-1.24.998l2.5 3.216c.317.316.475.758.43 1.204Z"/>
</svg><b> Justice</b></h3> 
              
              </Row>
              </div> 
         
              <CustomModal isOpen={modalInfo.isOpen} onClose={closeModal} title={modalInfo.title} content={modalInfo.content} />
              <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferTwo'>
                   
                    <Row>

                    <Col id='legislativeColRight'>
                      <div id='columnRightPadding'>

                      <h4 id='departmentOfDefenseHeadingInCol' onClick={() => window.open('/us/departmentofjustice', "_self")}><b>Department of Justice</b><ChevronRight id='clickChevron'></ChevronRight></h4>
                      <Carousel id='carouselInColumn' controls={false}  >
            
      
            
            <Carousel.Item interval={carouselInterval}>



<h4 id='departmentOfJusticeHeading' onClick={() => setDepartmentOfJusticeModalIsOpen(true)}><b>News</b></h4>



<div id='justiceNews2'></div>


{CreateFeedAPI('&skip=0&schema=united_states_of_america&table=justice&order_by=created_at', 'justiceNews2', 
                      'Department of Justice News', 'Executive', 
                      'N/A', 'Document', GenerateFeed)}

<Modal id='whiteHouseDocsModal' show={departmentOfJusticeModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfJusticeModalIsOpen(false)}>
                <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Justice News</h3></Modal.Header>
                <GenerateFeed
                                url='&skip=0&schema=united_states_of_america&table=justice&order_by=created_at'
                                name='Department of Justice News' branch='Executive' topic='N/A' buttonName='Document' />
              </Modal>




</Carousel.Item>

<Carousel.Item interval={carouselInterval}>




<h4 id='departmentOfJusticeHeading' onClick={() => setImmigrationModalIsOpen(true)}><b>Documents</b></h4>


<div id='justiceDocs'></div>

{CreateFeedItem('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=justice-department', 'justiceDocs', 'Department of Justice Documents', 'Executive', 'N/A', DepartmentOfJusticeDocs)}


<Modal id='whiteHouseDocsModal' show={immigrationModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setImmigrationModalIsOpen(false)}>
      <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Justice Documents</h3></Modal.Header>
      <DepartmentOfJusticeDocs/>
    </Modal>




</Carousel.Item>






<Carousel.Item interval={carouselInterval}>

<div id='legislativeConInCol'>

<Col>
<div id='viewFullPageButton'>
<Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/departmentofjustice' >View all Department of Justice news</Button>
</div>
</Col>

</div>



</Carousel.Item>

            
            
            </Carousel>
                      </div>
                    </Col>

                    <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>

                          <h4 id='whBreifingFeedHeading'><b>Committee on the Judiciary</b></h4>
                          <div id='houseCommitteeOnTheJudiciary'></div>
                      

                      {CreateFeedItem('https://docs.house.gov/Committee/RSS.ashx?Code=JU00', 'houseCommitteeOnTheJudiciary', 'House Committee on the Judiciary', 'Legislative', 'N/A', HouseCommitteeOnTheJudiciary)}

                  
                        </div>
                      </Col>


                    </Row>


                  </Container>
                </Container>
                </div>

            
                <div id='branchPage'>
  <Container class='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/antitrust-division', "_self")}}><b>Antitrust Division</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defAd'></div>
            {CreateFeedAPIDepartmentUS('&table_name=justice&agency=Antitrust%20Division', 'defAd',
              'Antitrust Division Documents', 'Executive',
              'Justice', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={justiceModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setJusticeModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Antitrust Division</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=justice&agency=Antitrust%20Division'
                name='Antitrust Division' branch='Executive' topic='Justice' buttonName='Doc' />
            </Modal>
          </div>
        </Col>

        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/dea', "_self")}}><b>Drug Enforcement Administration</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defDea'></div>
            {CreateFeedAPIDepartmentUS('&table_name=justice&agency=Drug%20Enforcement%20Administration', 'defDea',
              'Drug Enforcement Administration Documents', 'Executive',
              'Justice', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={justiceModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setJusticeModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Drug Enforcement Administration</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=justice&agency=Drug%20Enforcement%20Administration'
                name='Drug Enforcement Administration' branch='Executive' topic='Justice' buttonName='Doc' />
            </Modal>
          </div>
        </Col>
      </Row>
    </Container>
  </Container>
</div>

<div id='branchPage'>
  <Container class='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/immigration-review', "_self")}}><b>Executive Office for Immigration Review</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defEoir'></div>
            {CreateFeedAPIDepartmentUS('&table_name=justice&agency=Executive%20Office%20for%20Immigration%20Review', 'defEoir',
              'Executive Office for Immigration Review Documents', 'Executive',
              'Justice', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={justiceModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setJusticeModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Executive Office for Immigration Review</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=justice&agency=Executive%20Office%20for%20Immigration%20Review'
                name='Executive Office for Immigration Review' branch='Executive' topic='Justice' buttonName='Doc' />
            </Modal>
          </div>
        </Col>

        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/fbi', "_self")}}><b>Federal Bureau of Investigation</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defFbi'></div>
            {CreateFeedAPIDepartmentUS('&table_name=justice&agency=Federal%20Bureau%20of%20Investigation', 'defFbi',
              'Federal Bureau of Investigation Documents', 'Executive',
              'Justice', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={justiceModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setJusticeModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Federal Bureau of Investigation</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=justice&agency=Federal%20Bureau%20of%20Investigation'
                name='Federal Bureau of Investigation' branch='Executive' topic='Justice' buttonName='Doc' />
            </Modal>
          </div>
        </Col>
      </Row>
    </Container>
  </Container>
</div>

<div id='branchPage'>
  <Container class='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/federal-prison-industries', "_self")}}><b>Federal Prison Industries</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defFpi'></div>
            {CreateFeedAPIDepartmentUS('&table_name=justice&agency=Federal%20Prison%20Industries', 'defFpi',
              'Federal Prison Industries Documents', 'Executive',
              'Justice', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={justiceModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setJusticeModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Federal Prison Industries</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=justice&agency=Federal%20Prison%20Industries'
                name='Federal Prison Industries' branch='Executive' topic='Justice' buttonName='Doc' />
            </Modal>
          </div>
        </Col>

        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/foreign-claims', "_self")}}><b>Foreign Claims Settlement Commission</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defFcsc'></div>
            {CreateFeedAPIDepartmentUS('&table_name=justice&agency=Foreign%20Claims%20Settlement%20Commission', 'defFcsc',
              'Foreign Claims Settlement Commission Documents', 'Executive',
              'Justice', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={justiceModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setJusticeModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Foreign Claims Settlement Commission</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=justice&agency=Foreign%20Claims%20Settlement%20Commission'
                name='Foreign Claims Settlement Commission' branch='Executive' topic='Justice' buttonName='Doc' />
            </Modal>
          </div>
        </Col>
      </Row>
    </Container>
  </Container>
</div>

<div id='branchPage'>
  <Container class='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/immigration-naturalization', "_self")}}><b>Immigration and Naturalization Service</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defIns'></div>
            {CreateFeedAPIDepartmentUS('&table_name=justice&agency=Immigration%20and%20Naturalization%20Service', 'defIns',
              'Immigration and Naturalization Service Documents', 'Executive',
              'Justice', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={justiceModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setJusticeModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Immigration and Naturalization Service</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=justice&agency=Immigration%20and%20Naturalization%20Service'
                name='Immigration and Naturalization Service' branch='Executive' topic='Justice' buttonName='Doc' />
            </Modal>
          </div>
        </Col>

        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/justice-programs', "_self")}}><b>Justice Programs Office</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defJpo'></div>
            {CreateFeedAPIDepartmentUS('&table_name=justice&agency=Justice%20Programs%20Office', 'defJpo',
              'Justice Programs Office Documents', 'Executive',
              'Justice', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={justiceModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setJusticeModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Justice Programs Office</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=justice&agency=Justice%20Programs%20Office'
                name='Justice Programs Office' branch='Executive' topic='Justice' buttonName='Doc' />
            </Modal>
          </div>
        </Col>
      </Row>
    </Container>
  </Container>
</div>

<div id='branchPage'>
  <Container class='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/juvenile-justice', "_self")}}><b>Juvenile Justice and Delinquency Prevention Office</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defJjdp'></div>
            {CreateFeedAPIDepartmentUS('&table_name=justice&agency=Juvenile%20Justice%20and%20Delinquency%20Prevention%20Office', 'defJjdp',
              'Juvenile Justice and Delinquency Prevention Office Documents', 'Executive',
              'Justice', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={justiceModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setJusticeModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Juvenile Justice and Delinquency Prevention Office</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=justice&agency=Juvenile%20Justice%20and%20Delinquency%20Prevention%20Office'
                name='Juvenile Justice and Delinquency Prevention Office' branch='Executive' topic='Justice' buttonName='Doc' />
            </Modal>
          </div>
        </Col>

        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/national-institute-corrections', "_self")}}><b>National Institute of Corrections</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defNic'></div>
            {CreateFeedAPIDepartmentUS('&table_name=justice&agency=National%20Institute%20of%20Corrections', 'defNic',
              'National Institute of Corrections Documents', 'Executive',
              'Justice', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={justiceModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setJusticeModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>National Institute of Corrections</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=justice&agency=National%20Institute%20of%20Corrections'
                name='National Institute of Corrections' branch='Executive' topic='Justice' buttonName='Doc' />
            </Modal>
          </div>
        </Col>
      </Row>
    </Container>
  </Container>
</div>

<div id='branchPage'>
  <Container class='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/national-institute-justice', "_self")}}><b>National Institute of Justice</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defNij'></div>
            {CreateFeedAPIDepartmentUS('&table_name=justice&agency=National%20Institute%20of%20Justice', 'defNij',
              'National Institute of Justice Documents', 'Executive',
              'Justice', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={justiceModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setJusticeModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>National Institute of Justice</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=justice&agency=National%20Institute%20of%20Justice'
                name='National Institute of Justice' branch='Executive' topic='Justice' buttonName='Doc' />
            </Modal>
          </div>
        </Col>

        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/parole-commission', "_self")}}><b>Parole Commission</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defPc'></div>
            {CreateFeedAPIDepartmentUS('&table_name=justice&agency=Parole%20Commission', 'defPc',
              'Parole Commission Documents', 'Executive',
              'Justice', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={justiceModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setJusticeModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Parole Commission</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=justice&agency=Parole%20Commission'
                name='Parole Commission' branch='Executive' topic='Justice' buttonName='Doc' />
            </Modal>
          </div>
        </Col>
      </Row>
    </Container>
  </Container>
</div>

<div id='branchPage'>
  <Container class='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/prisons-bureau', "_self")}}><b>Prisons Bureau</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defPb'></div>
            {CreateFeedAPIDepartmentUS('&table_name=justice&agency=Prisons%20Bureau', 'defPb',
              'Prisons Bureau Documents', 'Executive',
              'Justice', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={justiceModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setJusticeModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Prisons Bureau</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=justice&agency=Prisons%20Bureau'
                name='Prisons Bureau' branch='Executive' topic='Justice' buttonName='Doc' />
            </Modal>
          </div>
        </Col>

        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/marshals-service', "_self")}}><b>United States Marshals Service</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defUsms'></div>
            {CreateFeedAPIDepartmentUS('&table_name=justice&agency=United%20States%20Marshals%20Service', 'defUsms',
              'United States Marshals Service Documents', 'Executive',
              'Justice', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={justiceModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setJusticeModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>United States Marshals Service</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=justice&agency=United%20States%20Marshals%20Service'
                name='United States Marshals Service' branch='Executive' topic='Justice' buttonName='Doc' />
            </Modal>
          </div>
        </Col>
      </Row>
    </Container>
  </Container>
</div>

       


       
            <Modal id='aboutModal' show={aboutModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setAboutModalIsOpen(false)}>
            <div id='removedAlert'></div>
            <Modal.Header id='aboutModalHeader' closeButton><h3>About</h3></Modal.Header>
  <ModalBody id='aboutInfo'><p id='aboutParagraph'>Gov Glance was created to drive civic engagement and support a 
              healthier democracy by helping citizens get official Government news in an easy-to-navigate centralized location. 
              There will never be any opinion or political analysis(That's your job), only information from official Government 
              sources. Gov Glance is structured after the U.S. government that is broken into three branches Legislative, 
              Executive, and Judicial. To learn more about the structure and functions of The U.S. Government go to <a href='https://www.usa.gov/branches-of-government'>https://www.usa.gov/branches-of-government</a>.
              </p>

              <p id='aboutParagraph'>
              Gov Glance Foundation Inc. is a non-profit that was created to operate govglance.org and continue to create educational tools that help people learn about the workings of society
              </p>
            <br></br>

            <h3>Privacy</h3>

            <br></br>

              <p id='aboutParagraph'>Gov Glance does not collect any personal user information. 
              Features like collection save news items directly to your browser's local storage for your convenience
              and is not shared with Gov Glance servers. We do not collect, store, or send personal information to Google LLC, 
              but we do use some Google services to deliver our app to you. For any questions into Google’s privacy practices 
              here is a link to their privacy policy: <a href='https://policies.google.com/privacy?hl=en-US#intro'>https://policies.google.com/privacy?hl=en-US#intro</a>

              <br></br><br></br>

              For site analytics we use plausible.io an open source privacy friendly analytics provider. <a href="https://plausible.io/about" target="_blank">https://plausible.io/about</a>
            
            </p>
            <br></br>


            <h3>Contact</h3>

            <p id='aboutParagraph'>
                      Contact us via email: <a href='mailto:contact@govglance.org'>contact@govglance.org</a>

            </p>

            <br></br>


            
            </ModalBody>
          </Modal>
 
          <footer id='homeFooter'>
      <Navbar>
        <Row id='footerNav'>
      <Nav variant='pills'>
        
     <Nav.Link id='footerContent'>Gov Glance Foundation Inc.
</Nav.Link>
<Nav.Item>
<Nav.Link id='footerContent' href='mailto:contact@govglance.org'>Contact</Nav.Link>
</Nav.Item>
<Nav.Item>
<Nav.Link id='footerContent' onClick={(e) => {
                   e.preventDefault();
                   window.open('https://donate.stripe.com/28o3fXbdvbQO2RO288', "_blank")
                 }}> <HeartFill id='donateIcon'/>    Donate</Nav.Link>
</Nav.Item>
<Nav.Link onClick={(e) => {
                   e.preventDefault();
                   window.open('/about', "_self")}} id='footerContent'>Privacy</Nav.Link>
    
     
 </Nav>
 </Row>
 </Navbar>
      </footer>
      


            




</div>
         </div>
          

              </BrowserView>

              <MobileView>

              <JusticeTopicMobile/>

              </MobileView>
              
    </>
  )
}
 }



  






export default JusticePage;