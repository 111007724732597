import App from "./App";
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Image } from 'react-bootstrap';
import { ArrowsAngleExpand, InfoCircle, FileText, FolderPlus, GeoAlt } from 'react-bootstrap-icons'
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import Axios from 'axios'
import { render } from '@testing-library/react';
import { Component } from "react";





const states = [
  { name: 'alabama', flag: 'alabamaFlag.png' },
  { name: 'alaska', flag: 'alaskaFlag.png' },
  { name: 'arizona', flag: 'arizonaFlag.png' },
  { name: 'arkansas', flag: 'arkansasFlag.png' },
  { name: 'california', flag: 'californiaFlag.png' },
  { name: 'colorado', flag: 'coloradoFlag.png' },
  { name: 'connecticut', flag: 'connecticutFlag.png' },
  { name: 'delaware', flag: 'delawareFlag.png' },
  { name: 'florida', flag: 'floridaFlag.png' },
  { name: 'georgia', flag: 'georgiaFlag.png' },
  { name: 'hawaii', flag: 'hawaiiFlag.png' },
  { name: 'idaho', flag: 'idahoFlag.png' },
  { name: 'illinois', flag: 'illinoisFlag.png' },
  { name: 'indiana', flag: 'indianaFlag.png' },
  { name: 'iowa', flag: 'iowaFlag.png' },
  { name: 'kansas', flag: 'kansasFlag.png' },
  { name: 'kentucky', flag: 'kentuckyFlag.png' },
  { name: 'louisiana', flag: 'louisianaFlag.png' },
  { name: 'maine', flag: 'maineFlag.png' },
  { name: 'maryland', flag: 'marylandFlag.png' },
  { name: 'massachusetts', flag: 'massachusettsFlag.png' },
  { name: 'michigan', flag: 'michiganFlag.png' },
  { name: 'minnesota', flag: 'minnesotaFlag.png' },
  { name: 'mississippi', flag: 'mississippiFlag.png' },
  { name: 'missouri', flag: 'missouriFlag.png' },
  { name: 'montana', flag: 'montanaFlag.png' },
  { name: 'nebraska', flag: 'nebraskaFlag.png' },
  { name: 'nevada', flag: 'nevadaFlag.png' },
  { name: 'new_hampshire', flag: 'newHampshireFlag.png' },
  { name: 'new_jersey', flag: 'newJerseyFlag.png' },
  { name: 'new_mexico', flag: 'newMexicoFlag.png' },
  { name: 'new_york', flag: 'newYorkFlag.png' },
  { name: 'north_carolina', flag: 'northCarolinaFlag.png' },
  { name: 'north_dakota', flag: 'northDakotaFlag.png' },
  { name: 'ohio', flag: 'ohioFlag.png' },
  { name: 'oklahoma', flag: 'oklahomaFlag.png' },
  { name: 'oregon', flag: 'oregonFlag.png' },
  { name: 'pennsylvania', flag: 'pennsylvaniaFlag.png' },
  { name: 'rhode_island', flag: 'rhodeIslandFlag.png' },
  { name: 'south_carolina', flag: 'southCarolinaFlag.png' },
  { name: 'south_dakota', flag: 'southDakotaFlag.png' },
  { name: 'tennessee', flag: 'tennesseeFlag.png' },
  { name: 'texas', flag: 'texasFlag.png' },
  { name: 'utah', flag: 'utahFlag.png' },
  { name: 'vermont', flag: 'vermontFlag.png' },
  { name: 'virginia', flag: 'virginiaFlag.png' },
  { name: 'washington', flag: 'washingtonFlag.png' },
  { name: 'west_virginia', flag: 'westVirginiaFlag.png' },
  { name: 'wisconsin', flag: 'wisconsinFlag.png' },
  { name: 'wyoming', flag: 'wyomingFlag.png' },
];

const chunkArray = (arr, chunkSize) => {
  let result = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    result.push(arr.slice(i, i + chunkSize));
  }
  return result;
};

const stateRows = chunkArray(states, 4);


function StatePickerElection()

{
  return(
    <>
  <Container id='stateContainer'>
<Col>
  <h3 id='branchHeading' > <GeoAlt/> <b id='branchHeadingBold'> State Election Info </b></h3>
  {stateRows.map((row, rowIndex) => (
        <Row key={rowIndex} className="mb-2">
          {row.map((state, index) => (
            <Button
              size='sm'
              id='statesDesktopButtons'
              onClick={(e) => {
                e.preventDefault();
                window.open(`/us/${state.name}/elections`, '_self');
              }}
              key={index}
              className="me-2"
            >
              <Image id='stateFlagHomePage' src={`../${state.flag}`} className="me-2" />
              {state.name.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
            </Button>
          ))}
        </Row>
      ))}
 </Col>
 </Container>
    </>
  )
                    }

export default StatePickerElection;