import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link, Switch,  } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment,  } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Tooltip, OverlayTrigger, Image, ModalFooter } from 'react-bootstrap';
import { HeartFill, ArrowsAngleExpand, ArrowsFullscreen, InfoCircle, FileText,  ShieldShaded, CircleFill, Pen, People, ChatSquareQuote, ChatLeftQuote, BlockquoteRight, Collection, Grid, ThreeDots, Stack, List, Files, FolderPlus, FolderCheck, Folder, CaretRight, ChevronRight, ChevronCompactRight, CaretRightFill, ArrowRightShort,  ArrowRightCircle, PlayCircle, PauseCircle, GeoAlt } from 'react-bootstrap-icons'
import { BrowserView, MobileView } from 'react-device-detect';
import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css'

import { render } from '@testing-library/react';

import CollectionPage from '../CollectionPage';
import VirginiaBills from './VirginiaBills';

import{ whiteHouseSearch, createPresidentialProclamations, createExecutiveOrders, createWhiteHouseDocuments, createWhiteHouseFeed,
    createWhiteHouseFeedMobile,createDepartmentOfState,
    createDepartmentOfDefense,
    createDepartmentOfJustice, createDepartmentOfTheInterior,
    createDepartmentOfAgriculture, createDepartmentOfCommerce,
    createDepartmentOfCommerceMobile, createDepartmentOfLabor,
    createDepartmentOfHealthAndHumanServices, createDepartmentOfHousingAndUrbanDevelopment, createDepartmentOfEducation
    ,  createDepartmentOfVeteransAffairs, createDepartmentOfHomelandSecurity
    , createEnvironmentalProtectionAgency,
    createDepartmentOfTreasury, createNationalScienceFoundation,
     createEnvironmentalProtectionAgencyMobile, createNationalAeronauticsAndSpaceAdministration, Speak, createVirginiaBills, CreateFeedItemStateBills, CreateFeedItemStateGovernor, CreateFeedItemStateCourt, CreateFeedAPI,
     } from '../Functions'
import MobileTopNavbar from '../MobileTopNavbar';
import MobileOffCanvas from '../MobileOffCanvas';

import GenerateFeed from '../GenerateFeed';


 
var collectionCounter = 0
var carouselInterval = 30000;
var mobileCarouselInterval = 60000;
 

export const VirginiaHomeMobile = () =>   {
    render()
    
    {
        const [show, setShow] = useState(true);
 
        const [modalIsOpen, setModalIsOpen] = useState(false)
        const [fullscreen, setFullScreen] = useState(true);
     
     
        const [fullBillModalIsOpen, setFullBillModalIsOpen] = useState(false)
     
        const [reportModalIsOpen, setReportModalIsOpen] = useState(false)
     
     
        const [whiteHouseFeedModalIsOpen, setWhiteHouseFeedModalIsOpen] = useState(false)
        const [whiteHouseDocsModalIsOpen, setWhiteHouseDocsModalIsOpen] = useState(false)
     
     
        const [courtNewsModalIsOpen, setCourtNewsModalIsOpen] = useState(false)
        const [courtModalIsOpen, setCourtModalIsOpen] = useState(false)
     
        const [courtAppealsModalIsOpen, setCourtAppealsModalIsOpen] = useState(false)
     
        const [newLawsModalIsOpen, setNewLawsModalIsOpen] = useState(false)
     
        const [billsEnrolledModalIsOpen, setBillsEnrolledModalIsOpen] = useState(false)
     
        const [legislativePageBillsEnrolledModalIsOpen, setLegislativePageBillsEnrolledModalIsOpen] = useState(false)
     
     
        const [departmentOfStateModalIsOpen, setDepartmentOfStateModalIsOpen] = useState(false)
        const [departmentOfTreasuryModalIsOpen, setDepartmentOfTreasuryModalIsOpen] = useState(false)
        const [departmentOfDefenseModalIsOpen, setDepartmentOfDefenseModalIsOpen] = useState(false)
        const [departmentOfJusticeModalIsOpen, setDepartmentOfJusticeModalIsOpen] = useState(false)
        const [departmentOfTheInteriorModalIsOpen, setDepartmentOfTheInteriorModalIsOpen] = useState(false)
        const [departmentOfAgricultureModalIsOpen, setDepartmentOfAgricultureModalIsOpen] = useState(false)
        const [departmentOfCommerceModalIsOpen, setDepartmentOfCommerceModalIsOpen] = useState(false)
        const [departmentOfLaborModalIsOpen, setDepartmentOfLaborModalIsOpen] = useState(false)
        const [departmentOfHealthAndHumanServicesModalIsOpen, setDepartmentOfHealthAndHumanServicesModalIsOpen] = useState(false)
        const [departmentOfHousingAndUrbanDevelopmentModalIsOpen, setDepartmentOfHousingAndUrbanDevelopmentModalIsOpen] = useState(false)
        const [departmentOfTransportationModalIsOpen, setDepartmentOfTransportationModalIsOpen] = useState(false)
        const [departmentOfEnergyModalIsOpen, setDepartmentOfEnergyModalIsOpen] = useState(false)
        const [departmentOfEducationModalIsOpen, setDepartmentOfEducationModalIsOpen] = useState(false)
        const [departmentOfVeteransAffairsModalIsOpen, setDepartmentOfVeteransAffairsModalIsOpen] = useState(false)
        const [departmentOfHomelandSecurityModalIsOpen, setDepartmentOfHomelandSecurityModalIsOpen] = useState(false)
        const [epaModalIsOpen, setEPAModalIsOpen] = useState(false)
        const [nsfModalIsOpen, setNSFModalIsOpen] = useState(false)
        const [nasaModalIsOpen, setNASAModalIsOpen] = useState(false)
        const [trendingBillsModalIsOpen, setTrendingBillsModalIsOpen] = useState(false)
        const [goaReportsModalIsOpen, setGAOReportsModalIsOpen] = useState(false)
        const [executiveOrdersModalIsOpen, setExecutiveOrdersModalIsOpen] = useState(false)
        const [presidentialProclamationsModalIsOpen, setPresidentialProclamationsModalIsOpen] = useState(false)
     
     
        const [statesModalIsOpen, setStatesModalIsOpen] = useState(false)
        const [collectionsModalIsOpen, setCollectionsModalIsOpen] = useState(false)
        const [aboutModalIsOpen, setAboutModalIsOpen] = useState(false)
     
     
     
        const [declarationModalIsOpen, setDeclarationModalIsOpen] = useState(false)
        const [constitutionModalIsOpen, setConstitutionModalIsOpen] = useState(false)
        const [billOfRightsnModalIsOpen, setBillOfRightsModalIsOpen] = useState(false)
        const [alertModalIsOpen, setAlertModalIsOpen] = useState(true)
   
   
    var branch = "all";
 
    return(
       <>
 <MobileTopNavbar/>
 
 
 
  <div id='homebg'>
 
               
 
            <h5 id='stateFlagHeadingMobile' > <Image id='stateFlagMobile'  src='../virginiaFlag.png'></Image>Virginia </h5>
            <h5 id='branchHeadingMobile' onClick={() => window.open('/us/virginia', "_self")}> <Pen></Pen> Legislative<ChevronRight id='clickChevron'></ChevronRight></h5>
 
           
 
                <Carousel controls={false}>

<Carousel.Item>
<div id='executiveConMobile' >
             
 
             <Col>
                 <Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile' onClick={() => setWhiteHouseFeedModalIsOpen(true)}>  Bill Updates</h5></Row>
 
              
                  <div class='whMobile' id='stateBills'></div>
                  {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=va_bills&order_by=created_at', 
                     'stateBills', 
                       'Virginia Bill Updates', 'Legislative', 
                       'N/A', 'Bill', GenerateFeed)}

                     <Modal id='whiteHouseDocsModal' show={whiteHouseFeedModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" 
                     centered onHide={() => setWhiteHouseFeedModalIsOpen(false)}>
                       <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>Virginia Bill Updates</h3></Modal.Header>
                       <GenerateFeed url='&skip=0&schema=united_states_of_america&table=va_bills&order_by=created_at' 
                       name='Virginia Bill Updates' branch='Legislative' topic='N/A' buttonName='Bill' />
                     </Modal>
</Col>
</div>

 </Carousel.Item>
 </Carousel>
                 
                  
             
           
             
         

 <h5 id='branchHeadingMobile'><People></People> Executive<ChevronRight id='clickChevron'></ChevronRight></h5>
<Carousel controls={false}>
<Carousel.Item interval={mobileCarouselInterval}>
<div id='executiveConMobile' >
<Col>
 <Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile' onClick={() => setTrendingBillsModalIsOpen(true)}>Governor</h5></Row>
  <div class='courtNewsMobile' id='govFeed'> </div>
  {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=virginia&order_by=created_at', 
                     'govFeed', 
                       'Virginia Governor', 'Executive', 
                       'N/A', 'Document', GenerateFeed)}

                     <Modal id='whiteHouseDocsModal' show={trendingBillsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" 
                     centered onHide={() => setTrendingBillsModalIsOpen(false)}>
                       <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>Virginia Governor</h3></Modal.Header>
                       <GenerateFeed url='&skip=0&schema=united_states_of_america&table=virginia&order_by=created_at' 
                       name='Virginia Governor' branch='Executive' topic='N/A' buttonName='Document' />
                     </Modal>
  </Col>       
</div>
  </Carousel.Item>
</Carousel>


  <h5 id='branchHeadingMobile'><ShieldShaded></ShieldShaded>   Judicial<ChevronRight id='clickChevron'></ChevronRight></h5>
            <Carousel controls={false}>
  <Carousel.Item interval={mobileCarouselInterval}>
<div id='executiveConMobile' >
          <Col>
<Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile' onClick={() => setCourtModalIsOpen(true)}> Supreme Court</h5></Row>
<div class='courtNewsMobile' id='alCourts'> </div>

{CreateFeedAPI('&skip=0&schema=united_states_of_america&table=supreme_vt&order_by=created_at', 
                     'alCourts', 
                       'Virginia Supreme Court', 'Judicial', 
                       'N/A', 'Bill', GenerateFeed)}

                     <Modal id='whiteHouseDocsModal' show={courtModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" 
                     centered onHide={() => setCourtModalIsOpen(false)}>
                       <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>Virginia Supreme Court</h3></Modal.Header>
                       <GenerateFeed url='&skip=0&schema=united_states_of_america&table=supreme_vt&order_by=created_at' 
                       name='Virginia Supreme Court' branch='Judicial' topic='N/A' buttonName='Bill' />
                     </Modal>
</Col>
</div>
</Carousel.Item>
</Carousel>
 
 
 
<Col>


<h5 id='branchHeadingMobile' > <GeoAlt/>  States <ChevronRight id='clickChevron'></ChevronRight></h5>


<div id='homebg'>  
<Container>
<Col>
<Row id='statesRow'>
                <Button size='sm' id='statesModalButtons'  onClick={(e) => {
                      e.preventDefault();
                      window.open('/us/alabama', '_self')
                    }}> <Image id='stateFlagMobile'  src='../alabamaFlag.png'></Image> Alabama</Button>
                      <Button size='sm' id='statesModalButtons'  onClick={(e) => {
                      e.preventDefault();
                      window.open('/us/alaska', '_self')
                    }}><Image id='stateFlagMobile'  src='../alaskaFlag.png'></Image> Alaska</Button>

<Button size='sm' id='statesModalButtons'  onClick={(e) => {
                      e.preventDefault();
                      window.open('/us/arizona', '_self')
                    }}> <Image id='stateFlagMobile'  src='../arizonaFlag.png'></Image> Arizona</Button>
</Row>

<Row>
<Button size='sm' id='statesModalButtons' onClick={(e) => {
                      e.preventDefault();
                      window.open('/us/arkansas', '_self')
                    }}><Image id='stateFlagMobile'  src='../arkansasFlag.png'></Image> Arkansas</Button>

<Button size='sm' id='statesModalButtons' onClick={(e) => {
                      e.preventDefault();
                      window.open('/us/california', '_self')
                    }}><Image id='stateFlagMobile'  src='../californiaFlag.png'></Image> California</Button>


<Button size='sm' id='statesModalButtons'  onClick={(e) => {
                      e.preventDefault();
                      window.open('/us/colorado', '_self')
                    }}><Image id='stateFlagMobile'  src='../coloradoFlag.png'></Image> Colorado</Button>
</Row>

<Row>
<Button size='sm' id='statesModalButtons'  onClick={(e) => {
                      e.preventDefault();
                      window.open('/us/connecticut', '_self')
                    }}><Image id='stateFlagMobile'  src='../connecticutFlag.png'></Image> Connecticut</Button>

<Button size='sm' id='statesModalButtons'  onClick={(e) => {
                      e.preventDefault();
                      window.open('/us/delaware', '_self')
                    }}><Image id='stateFlagMobile'  src='../delawareFlag.png'></Image> Delaware</Button>

<Button size='sm' id='statesModalButtons' onClick={(e) => {
                      e.preventDefault();
                      window.open('/us/florida', '_self')
                    }}><Image id='stateFlagMobile'  src='../floridaFlag.png'></Image> Florida</Button>
</Row>

<Row>
<Button size='sm' id='statesModalButtons' onClick={(e) => {
                      e.preventDefault();
                      window.open('/us/georgia', '_self')
                    }}><Image id='stateFlagMobile'  src='../georgiaFlag.png'></Image> Georgia</Button>

<Button size='sm' id='statesModalButtons' onClick={(e) => {
                      e.preventDefault();
                      window.open('/us/hawaii', '_self')
                    }}><Image id='stateFlagMobile'  src='../hawaiiFlag.png'></Image> Hawaii</Button>

<Button size='sm' id='statesModalButtons' onClick={(e) => {
                      e.preventDefault();
                      window.open('/us/idaho', '_self')
                    }}> <Image id='stateFlagMobile'  src='../idahoFlag.png'></Image> Idaho</Button>
</Row>

<Row>
<Button size='sm' id='statesModalButtons'  onClick={(e) => {
                      e.preventDefault();
                      window.open('/us/illinois', '_self')
                    }}> <Image id='stateFlagMobile'  src='../illinoisFlag.png'></Image> Illinois</Button>

<Button size='sm' id='statesModalButtons' onClick={(e) => {
                      e.preventDefault();
                      window.open('/us/indiana', '_self')
                    }}><Image id='stateFlagMobile'  src='../indianaFlag.png'></Image> Indiana</Button>

<Button size='sm' id='statesModalButtons' onClick={(e) => {
                      e.preventDefault();
                      window.open('/us/iowa', '_self')
                    }}><Image id='stateFlagMobile'  src='../iowaFlag.png'></Image> Iowa</Button>
</Row>

<Row>
<Button size='sm' id='statesModalButtons' onClick={(e) => {
                      e.preventDefault();
                      window.open('/us/kansas', '_self')
                    }}><Image id='stateFlagMobile'  src='../kansasFlag.png'></Image> Kansas</Button>

<Button size='sm' id='statesModalButtons' onClick={(e) => {
                      e.preventDefault();
                      window.open('/us/kentucky', '_self')
                    }}><Image id='stateFlagMobile'  src='../kentuckyFlag.png'></Image> Kentucky</Button>

                   
<Button size='sm' id='statesModalButtons' onClick={(e) => {
                      e.preventDefault();
                      window.open('/us/louisiana', '_self')
                    }}><Image id='stateFlagMobile'  src='../louisianaFlag.png'></Image> Louisiana</Button>
</Row>

<Row>

<Button size='sm' id='statesModalButtons' onClick={(e) => {
                      e.preventDefault();
                      window.open('/us/maine', '_self')
                    }}><Image id='stateFlagMobile'  src='../maineFlag.png'></Image> Maine</Button>

<Button size='sm' id='statesModalButtons' onClick={(e) => {
                      e.preventDefault();
                      window.open('/us/maryland', '_self')
                    }}><Image id='stateFlagMobile'  src='../marylandFlag.png'></Image> Maryland</Button>

<Button size='sm' id='statesModalButtons'  onClick={(e) => {
                      e.preventDefault();
                      window.open('/us/massachusetts', '_self')
                    }}><Image id='stateFlagMobile'  src='../massachusettsFlag.png'></Image> Massachusetts</Button>
</Row>

<Row>
<Button size='sm' id='statesModalButtons' onClick={(e) => {
                      e.preventDefault();
                      window.open('/us/michigan', '_self')
                    }}><Image id='stateFlagMobile'  src='../michiganFlag.png'></Image> Michigan</Button>

<Button size='sm' id='statesModalButtons' onClick={(e) => {
                      e.preventDefault();
                      window.open('/us/minnesota', '_self')
                    }}><Image id='stateFlagMobile'  src='../minnesotaFlag.png'></Image> Minnesota</Button>

<Button size='sm' id='statesModalButtons' onClick={(e) => {
                      e.preventDefault();
                      window.open('/us/mississippi', '_self')
                    }}><Image id='stateFlagMobile'  src='../mississippiFlag.png'></Image> Mississippi</Button>
</Row>

<Row>
<Button size='sm' id='statesModalButtons' onClick={(e) => {
                      e.preventDefault();
                      window.open('/us/missouri', '_self')
                    }}><Image id='stateFlagMobile'  src='../missouriFlag.png'></Image> Missouri</Button>

<Button size='sm' id='statesModalButtons' onClick={(e) => {
                      e.preventDefault();
                      window.open('/us/montana', '_self')
                    }}><Image id='stateFlagMobile'  src='../montanaFlag.png'></Image> Montana</Button>

<Button size='sm' id='statesModalButtons' onClick={(e) => {
                      e.preventDefault();
                      window.open('/us/nebraska', '_self')
                    }}><Image id='stateFlagMobile'  src='../nebraskaFlag.png'></Image> Nebraska</Button>
</Row>

<Row>                    
                    <Button size='sm' id='statesModalButtons'  onClick={(e) => {
                      e.preventDefault();
                      window.open('/us/nevada', '_self')
                    }}><Image id='stateFlagMobile'  src='../nevadaFlag.png'></Image> Nevada</Button>

<Button size='sm' id='statesModalButtons' onClick={(e) => {
                      e.preventDefault();
                      window.open('/us/newhampshire', '_self')
                    }}><Image id='stateFlagMobile'  src='../newHampshireFlag.png'></Image> New Hampshire</Button>

<Button size='sm' id='statesModalButtons' onClick={(e) => {
                      e.preventDefault();
                      window.open('/us/newjersey', '_self')
                    }}><Image id='stateFlagMobile'  src='../newJerseyFlag.png'></Image> New Jersey</Button>
</Row>

<Row>
<Button size='sm' id='statesModalButtons' onClick={(e) => {
                      e.preventDefault();
                      window.open('/us/newmexico', '_self')
                    }}><Image id='stateFlagMobile'  src='../newMexicoFlag.png'></Image> New Mexico</Button>

<Button size='sm' id='statesModalButtons' onClick={(e) => {
                      e.preventDefault();
                      window.open('/us/newyork', '_self')
                    }}><Image id='stateFlagMobile'  src='../newYorkFlag.png'></Image> New York</Button>

<Button size='sm' id='statesModalButtons' onClick={(e) => {
                      e.preventDefault();
                      window.open('/us/northcarolina', '_self')
                    }}><Image id='stateFlagMobile'  src='../northCarolinaFlag.png'></Image>North Carolina</Button>
</Row>

<Row>
<Button size='sm' id='statesModalButtons' onClick={(e) => {
                      e.preventDefault();
                      window.open('/us/northdakota', '_self')
                    }}><Image id='stateFlagMobile'  src='../northDakotaFlag.png'></Image>North Dakota</Button>

<Button size='sm' id='statesModalButtons' onClick={(e) => {
                      e.preventDefault();
                      window.open('/us/ohio', '_self')
                    }}><Image id='stateFlagMobile'  src='../ohioFlag.png'></Image>Ohio</Button>

<Button size='sm' id='statesModalButtons' onClick={(e) => {
                      e.preventDefault();
                      window.open('/us/oklahoma', '_self')
                    }}><Image id='stateFlagMobile'  src='../oklahomaFlag.png'></Image> Oklahoma</Button>
</Row>

<Row>
<Button size='sm' id='statesModalButtons' onClick={(e) => {
                      e.preventDefault();
                      window.open('/us/oregon', '_self')
                    }}><Image id='stateFlagMobile'  src='../oregonFlag.png'></Image> Oregon</Button>

<Button size='sm' id='statesModalButtons' onClick={(e) => {
                      e.preventDefault();
                      window.open('/us/pennsylvania', '_self')
                    }}><Image id='stateFlagMobile'  src='../pennsylvaniaFlag.png'></Image> Pennsylvania</Button>

<Button size='sm' id='statesModalButtons' onClick={(e) => {
                      e.preventDefault();
                      window.open('/us/rhodeisland', '_self')
                    }}><Image id='stateFlagMobile'  src='../rhodeIslandFlag.png'></Image> Rhode Island</Button>
</Row>

<Row>
<Button size='sm' id='statesModalButtons' onClick={(e) => {
                      e.preventDefault();
                      window.open('/us/southcarolina', '_self')
                    }}><Image id='stateFlagMobile'  src='../southCarolinaFlag.png'></Image> South Carolina</Button>

<Button size='sm' id='statesModalButtons' onClick={(e) => {
                      e.preventDefault();
                      window.open('/us/southdakota', '_self')
                    }}><Image id='stateFlagMobile'  src='../southDakotaFlag.png'></Image> South Dakota</Button>

<Button size='sm' id='statesModalButtons' onClick={(e) => {
                      e.preventDefault();
                      window.open('/us/tennessee', '_self')
                    }}><Image id='stateFlagMobile'  src='../tennesseeFlag.png'></Image> Tennessee</Button>
</Row>

<Row>
<Button size='sm' id='statesModalButtons' onClick={(e) => {
                      e.preventDefault();
                      window.open('/us/texas', '_self')
                    }}><Image id='stateFlagMobile'  src='../texasFlag.png'></Image> Texas</Button>

<Button size='sm' id='statesModalButtons' onClick={(e) => {
                      e.preventDefault();
                      window.open('/us/utah', '_self')
                    }}><Image id='stateFlagMobile'  src='../utahFlag.png'></Image> Utah</Button>

<Button size='sm' id='statesModalButtons' onClick={(e) => {
                      e.preventDefault();
                      window.open('/us/vermont', '_self')
                    }}><Image id='stateFlagMobile'  src='../vermontFlag.png'></Image> Vermont</Button>
</Row>

<Row>
<Button size='sm' id='statesModalButtons' onClick={(e) => {
                      e.preventDefault();
                      window.open('/us/virginia', '_self')
                    }}><Image id='stateFlagMobile'  src='../virginiaFlag.png'></Image> Virginia</Button>

<Button size='sm' id='statesModalButtons' onClick={(e) => {
                      e.preventDefault();
                      window.open('/us/washington', '_self')
                    }}><Image id='stateFlagMobile'  src='../washingtonFlag.png'></Image> Washington</Button>

<Button size='sm' id='statesModalButtons' onClick={(e) => {
                      e.preventDefault();
                      window.open('/us/westvirginia', '_self')
                    }}><Image id='stateFlagMobile'  src='../westVirginiaFlag.png'></Image> West Virginia</Button>
</Row>

<Row>
<Button size='sm' id='statesModalButtons' onClick={(e) => {
                      e.preventDefault();
                      window.open('/us/wisconsin', '_self')
                    }}><Image id='stateFlagMobile'  src='../wisconsinFlag.png'></Image>Wisconsin</Button>

<Button size='sm' id='statesModalButtons' onClick={(e) => {
                      e.preventDefault();
                      window.open('/us/wyoming', '_self')
                    }}><Image id='stateFlagMobile'  src='../wyomingFlag.png'></Image>Wyoming</Button>

</Row>




</Col>
</Container>
</div>


          </Col>

<Col>

<br></br><br></br><br></br><br></br>
<br></br>
<br></br>
<h5 id='headingSelectHomeCountry'>Support the Gov Glance Foundation  <Button id='donateButton' onClick={(e) => {
                        e.preventDefault();
                        window.open('https://donate.stripe.com/28o3fXbdvbQO2RO288', "_blank")
                      }}><HeartFill id='donateIcon'/>    Donate</Button></h5>

</Col>


 
         
         
            <br></br><br></br><br></br><br></br><br></br><br></br><Navbar id='navbarMobileBottom' fixed="bottom">    

          


       
<div id='changeBranchDropdownMobile'>

           <MobileOffCanvas/>
   
            </div>

           


           
 
             
 
 
 
 
 
 
</Navbar>
          </div>
 
          </>
  )
}
 }
 
 
 
 
 
 
 
 
 
 
export default VirginiaHomeMobile;
 
 

