import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link, Switch,  } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment,  } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Tooltip, OverlayTrigger, Image, ModalFooter } from 'react-bootstrap';
import { ArrowsAngleExpand, ArrowsFullscreen, InfoCircle, FileText,  ShieldShaded, CircleFill, Pen, People, ChatSquareQuote, ChatLeftQuote, BlockquoteRight, Collection, Grid, ThreeDots, Stack, List, Files, FolderPlus, FolderCheck, Folder, CaretRight, ChevronRight, ChevronCompactRight, CaretRightFill, ArrowRightShort,  ArrowRightCircle, PlayCircle, PauseCircle, Link45deg } from 'react-bootstrap-icons'
import { BrowserView, MobileView } from 'react-device-detect';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import Axios from 'axios'
import { render } from '@testing-library/react';

import{ whiteHouseSearch, createPresidentialProclamations, createExecutiveOrders, createWhiteHouseDocuments, createWhiteHouseFeed,
    createWhiteHouseFeedMobile,createDepartmentOfState,
    createDepartmentOfDefense, 
    createDepartmentOfJustice, createDepartmentOfTheInterior,
    createDepartmentOfAgriculture, createDepartmentOfCommerce,
    createDepartmentOfCommerceMobile, createDepartmentOfLabor,
    createDepartmentOfHealthAndHumanServices, createDepartmentOfHousingAndUrbanDevelopment, createDepartmentOfEducation
    ,  createDepartmentOfVeteransAffairs, createDepartmentOfHomelandSecurity
    , createEnvironmentalProtectionAgency,
    createDepartmentOfTreasury, createNationalScienceFoundation, 
     createEnvironmentalProtectionAgencyMobile, createNationalAeronauticsAndSpaceAdministration, Speak, createFederalRegisterDefenseDepartment, 
     createFederalRegisterStateDepartment, createFederalRegisterDefense,
    CreateFeedItem, CreateFeedItemPresidentialDocuments, CreateExecutiveOrders,
    CreateFeedItemExecutiveOrders,
    CreateFeedItemBillUpdates,
    CreateFeedItemExecutiveOffice,
    CreateFeedItemBillWithDescription,
    CreateFeedItemNoDate,
    CreateFeedAPIDepartmentUS,  } from './Functions'
import NationalScienceFoundation from './NationalScienceFoundation';
import FederalRegisterDefenseDepartment from './FederalRegisterDefenseDepartment';
import FederalRegisterStateDepartment from './FederalRegisterStateDepartment';
import FederalRegisterTreasuryDepartment from './FederalRegisterTreasuryDepartment';
import MobileOffCanvas from './MobileOffCanvas';
import MobileTopNavbar from './MobileTopNavbar';

import DepartmentOfTransportationDocs from './DepartmentOfTransportationDocs';
import CommercialSpaceTransportationOffice from './CommercialSpaceTransportationOffice';
import FederalAviationAdministration from './FederalAviationAdministration';
import FederalHighwayAdmin from './FederalHighwayAdmin';
import FederalMotorCarrierSafetyAdmin from './FederalMotorCarrierSafetyAdmin';
import FederalRailroadAdmin from './FederalRailroadAdmin';
import FederalTransitAdmin from './FederalTransitAdmin';
import NationalHighwayTrafficSafetyAdmin from './NationalHighwayTrafficSafetyAdmin';
import PipelineAndHazardousMaterialsSafetyAdmin from './PipelineAndHazardousMaterialsSafetyAdmin';
import TransportationStatsBureau from './TransportationStatsBureau';
import GreatLakesStLawerenceSeaway from './GreatLakesStLawerenceSeaway';
import MaritimeAdmin from './MaritimeAdmin';
import GenerateFeedDepartment from './GenerateFeedDepartment';



var collectionCounter = 0
var carouselInterval = 30000;
var mobileCarouselInterval = 30000;



  

 


  

export const TransportationMobile = () =>   {
    render()
    
    {
        const [show, setShow] = useState(true);

        
        const [fullscreen, setFullScreen] = useState(true);
      
      
        const [fullBillModalIsOpen, setFullBillModalIsOpen] = useState(false)
      
        const [reportModalIsOpen, setReportModalIsOpen] = useState(false)
      
      
        const [whiteHouseFeedModalIsOpen, setWhiteHouseFeedModalIsOpen] = useState(false)
        const [whiteHouseDocsModalIsOpen, setWhiteHouseDocsModalIsOpen] = useState(false)
      
      
        const [courtNewsModalIsOpen, setCourtNewsModalIsOpen] = useState(false)
        const [courtModalIsOpen, setCourtModalIsOpen] = useState(false)
      
        const [courtAppealsModalIsOpen, setCourtAppealsModalIsOpen] = useState(false)
      
        const [newLawsModalIsOpen, setNewLawsModalIsOpen] = useState(false)
      
        const [billsEnrolledModalIsOpen, setBillsEnrolledModalIsOpen] = useState(false)
      
        const [legislativePageBillsEnrolledModalIsOpen, setLegislativePageBillsEnrolledModalIsOpen] = useState(false)
      
      
        const [departmentOfStateModalIsOpen, setDepartmentOfStateModalIsOpen] = useState(false)
        const [departmentOfTreasuryModalIsOpen, setDepartmentOfTreasuryModalIsOpen] = useState(false)
     
        const [departmentOfJusticeModalIsOpen, setDepartmentOfJusticeModalIsOpen] = useState(false)
        const [departmentOfTheInteriorModalIsOpen, setDepartmentOfTheInteriorModalIsOpen] = useState(false)
        const [departmentOfAgricultureModalIsOpen, setDepartmentOfAgricultureModalIsOpen] = useState(false)
        const [departmentOfCommerceModalIsOpen, setDepartmentOfCommerceModalIsOpen] = useState(false)
        const [departmentOfLaborModalIsOpen, setDepartmentOfLaborModalIsOpen] = useState(false)
        const [departmentOfHealthAndHumanServicesModalIsOpen, setDepartmentOfHealthAndHumanServicesModalIsOpen] = useState(false)
        const [departmentOfHousingAndUrbanDevelopmentModalIsOpen, setDepartmentOfHousingAndUrbanDevelopmentModalIsOpen] = useState(false)
        const [departmentOfTransportationModalIsOpen, setDepartmentOfTransportationModalIsOpen] = useState(false)
        const [departmentOfEnergyModalIsOpen, setDepartmentOfEnergyModalIsOpen] = useState(false)
        const [departmentOfEducationModalIsOpen, setDepartmentOfEducationModalIsOpen] = useState(false)
        const [departmentOfVeteransAffairsModalIsOpen, setDepartmentOfVeteransAffairsModalIsOpen] = useState(false)
        const [departmentOfHomelandSecurityModalIsOpen, setDepartmentOfHomelandSecurityModalIsOpen] = useState(false)
        const [epaModalIsOpen, setEPAModalIsOpen] = useState(false)
        const [nsfModalIsOpen, setNSFModalIsOpen] = useState(false)
        const [nasaModalIsOpen, setNASAModalIsOpen] = useState(false)
        const [trendingBillsModalIsOpen, setTrendingBillsModalIsOpen] = useState(false)
        const [goaReportsModalIsOpen, setGAOReportsModalIsOpen] = useState(false)
        const [executiveOrdersModalIsOpen, setExecutiveOrdersModalIsOpen] = useState(false)
        const [presidentialProclamationsModalIsOpen, setPresidentialProclamationsModalIsOpen] = useState(false)
        const [statesModalIsOpen, setStatesModalIsOpen] = useState(false)
      
      
        const [collectionsModalIsOpen, setCollectionsModalIsOpen] = useState(false)
        const [aboutModalIsOpen, setAboutModalIsOpen] = useState(false)
      
      
        const [federalRegisterDefenseDepartmentModalIsOpen, setFederalRegisterDefenseDepartmentModalIsOpen] = useState(false)
  const [federalRegisterStateDepartmentModalIsOpen, setFederalRegisterStateDepartmentModalIsOpen] = useState(false)
        const [declarationModalIsOpen, setDeclarationModalIsOpen] = useState(false)
        const [constitutionModalIsOpen, setConstitutionModalIsOpen] = useState(false)
        const [billOfRightsnModalIsOpen, setBillOfRightsModalIsOpen] = useState(false)

        const [economicAdModalIsOpen, setEconomicAdModalIsOpen] = useState(false)
        const [environmentQualityModalIsOpen, setEnvironmentQualityModalIsOpen] = useState(false)
        const [domesticPolicyModalIsOpen, setDomesticPolicyModalIsOpen] = useState(false)
        const [genderPolicyModalIsOpen, setGenderPolicyModalIsOpen] = useState(false)
        const [nationalEconomicModalIsOpen, setNationalEconomicModalIsOpen] = useState(false)
        const [securityModalIsOpen, setSecurityModalIsOpen] = useState(false)
        const [budgetModalIsOpen, setBudgetModalIsOpen] = useState(false)
        const [drugModalIsOpen, setDrugModalIsOpen] = useState(false)
        const [publicModalIsOpen, setPublicModalIsOpen] = useState(false)
        const [scienceModalIsOpen, setScienceModalIsOpen] = useState(false)
        const [cyberModalIsOpen, setCyberModalIsOpen] = useState(false)
        const [presidentialModalIsOpen, setPresidentialModalIsOpen] = useState(false)
        const [showBioModal, setBioModal] = useState(false);
        const [showMissionModal, setMissionModal] = useState(false);
        const [departmentOfDefenseModalIsOpen, setDepartmentOfDefenseModalIsOpen] = useState(false)
        const [defenseDocumentsModalIsOpen, setDefenseDocumentsModalIsOpen] = useState(false)
        const [armyModalIsOpen, setArmyModalIsOpen] = useState(false)
        const [navyModalIsOpen, setNavyModalIsOpen] = useState(false)
        const [marinesModalIsOpen, setMarinesModalIsOpen] = useState(false)
        const [spaceForceModalIsOpen, setSpaceForceModalIsOpen] = useState(false)
        const [airForceModalIsOpen, setAirForceModalIsOpen] = useState(false)
        const [nationalGuardModalIsOpen, setNationalGuardModalIsOpen] = useState(false)
        const [ciaModalIsOpen, setCIAModalIsOpen] = useState(false)
        const [nsaModalIsOpen, setNSAModalIsOpen] = useState(false)
        const [armyCorpsModalIsOpen, setArmyCorpsModalIsOpen] = useState(false)
        const [modalInfo, setModalInfo] = useState({
          isOpen: false,
          title: '',
          content: null
        });
      
        const openModal = (title, content) => {
          setModalInfo({
            isOpen: true,
            title,
            content
          });
        };
      
        const closeModal = () => {
          setModalInfo({
            isOpen: false,
            title: '',
            content: null
          });
        };

        var mobileCarouselInterval = 60000;
        const CustomModal = ({ isOpen, onClose, title, content }) => (
          <Modal id='fullBillModal' show={isOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={onClose}>
            <Modal.Header id='billModalHeader' closeButton><h3>{title}</h3></Modal.Header>
            {content}
          </Modal>
        );
    var branch = "all";
  
    return(
       <>
   <MobileTopNavbar/>
  
  
  
  
  <div id='homebg'>

               
  
  <CustomModal isOpen={modalInfo.isOpen} onClose={closeModal} title={modalInfo.title} content={modalInfo.content} />
  <div id="feedDisplayDepartment" style={{ padding: '10px', borderRadius: '18px' }}>
  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div id="profileImageOnPageDepartment">
                <Image id="profileImage-imageOnPageDepartment" src="/departmentOfTransportation.png" />
              </div>
              <div style={{ marginLeft: '10px', color: 'white', fontSize: '18px', fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
                <h5 id="branchHeadingMobile" > Department of Transporation</h5>
              </div>
            </div>
            <Dropdown drop="down-centered">
              <Dropdown.Toggle id="searchCountry"><Link45deg /></Dropdown.Toggle>
              <Dropdown.Menu id="docsDropdown" drop="down-centered">
                <Dropdown.Item id="docsDropdownItem" onClick={(e) => { e.preventDefault(); window.open("https://www.transportation.gov/", "_blank") }}>
                  Official Site
                </Dropdown.Item>

                <Dropdown.Item id="docsDropdownItem" onClick={(e) => { e.preventDefault(); window.open("https://www.govinfo.gov/content/pkg/STATUTE-80/pdf/STATUTE-80-Pg931.pdf", "_blank") }}>
                  Establishing Act
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div id="billText" style={{ color: 'white', fontSize: '16px', marginTop: '10px' }}>
          
          The mission of the Department of Transportation (DOT) is to ensure a fast, safe, efficient, accessible, and convenient transportation system that meets our vital national interests and enhances the quality of life of the American people.
          </div>

        




 </div>
         
 <Button id='presidentViewMoreButton' onClick={() =>  setBioModal(true)}>  <Files size={20}></Files> Read More</Button>


       
<Modal id='fullBillModal'  size="xl" aria-labelledby="contained-modal-title-vcenter" show={showBioModal} onHide={() => setBioModal(false)}>
<Modal.Header id='billModalHeader' closeButton><h3> Department of Transporation</h3></Modal.Header>
       
       <div id='fullBillFeed' >
       <p>The mission of the Department of Transportation (DOT) is to ensure a fast, safe, efficient, accessible, and convenient transportation system that meets our vital national interests and enhances the quality of life of the American people.</p>

<p>Organizations within the DOT include the Federal Highway Administration, the Federal Aviation Administration, the National Highway Traffic Safety Administration, the Federal Transit Administration, the Federal Railroad Administration, and the Maritime Administration.</p>

<p>The U.S. Secretary of Transportation oversees nearly 55,000 employees and a budget of approximately $70 billion.</p>


<br></br>
<Link style={{color: '#21cff5'}} onClick={(e) => {
   e.preventDefault();
   window.open("https://www.whitehouse.gov/about-the-white-house/our-government/the-executive-branch/#:~:text=approximately%20%2435%20billion.-,DEPARTMENT%20OF%20TRANSPORTATION,-The%20mission%20of", "_blank");
 }}>
   https://www.whitehouse.gov/about-the-white-house/our-government/the-executive-branch/#:~:text=approximately%20%2435%20billion.-,DEPARTMENT%20OF%20TRANSPORTATION,-The%20mission%20of
   </Link>
</div>

     </Modal>
 
            <Col>


<h5 id='presidentialFeedHeadingMobile' onClick={() => setDepartmentOfDefenseModalIsOpen(true)}>Documents</h5>




  <div class='courtNewsMobile' id='laborDepartment'></div>

  {CreateFeedItem('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=transportation-department', 'laborDepartment', 'Department of Transporation', 'Executive', 'N/A', DepartmentOfTransportationDocs)}

  <Modal id='whiteHouseDocsModal' show={departmentOfDefenseModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfDefenseModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Transporation Documents</h3></Modal.Header>
                  <DepartmentOfTransportationDocs/>
                </Modal>

  </Col>


  

<Col>
  <Link  id='pageLink' to="/us/commercialspace">
    <h5 id='presidentialFeedHeadingMobile'>Commercial Space Transportation Office<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Commercial Space Transportation Office',
                <GenerateFeedDepartment url='&table_name=transportation&agency=Commercial%20Space%20Transportation%20Office'
                  name='Commercial Space Transportation Office' branch='Executive' topic='Infrastructure' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defCsto'></div>
          {CreateFeedAPIDepartmentUS('&table_name=transportation&agency=Commercial%20Space%20Transportation%20Office', 'defCsto',
            'Commercial Space Transportation Office Documents', 'Executive',
            'Infrastructure', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/commercialspace' >View all Commercial Space Transportation Office news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/us/faa">
    <h5 id='presidentialFeedHeadingMobile'>Federal Aviation Administration<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Federal Aviation Administration',
                <GenerateFeedDepartment url='&table_name=transportation&agency=Federal%20Aviation%20Administration'
                  name='Federal Aviation Administration' branch='Executive' topic='Infrastructure' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defFaa'></div>
          {CreateFeedAPIDepartmentUS('&table_name=transportation&agency=Federal%20Aviation%20Administration', 'defFaa',
            'Federal Aviation Administration Documents', 'Executive',
            'Infrastructure', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/faa' >View all Federal Aviation Administration news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/us/fha">
    <h5 id='presidentialFeedHeadingMobile'>Federal Highway Administration<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Federal Highway Administration',
                <GenerateFeedDepartment url='&table_name=transportation&agency=Federal%20Highway%20Administration'
                  name='Federal Highway Administration' branch='Executive' topic='Infrastructure' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defFhwa'></div>
          {CreateFeedAPIDepartmentUS('&table_name=transportation&agency=Federal%20Highway%20Administration', 'defFhwa',
            'Federal Highway Administration Documents', 'Executive',
            'Infrastructure', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/fha' >View all Federal Highway Administration news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/us/fmcsa">
    <h5 id='presidentialFeedHeadingMobile'>Federal Motor Carrier Safety Administration<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Federal Motor Carrier Safety Administration',
                <GenerateFeedDepartment url='&table_name=transportation&agency=Federal%20Motor%20Carrier%20Safety%20Administration'
                  name='Federal Motor Carrier Safety Administration' branch='Executive' topic='Infrastructure' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defFmcsa'></div>
          {CreateFeedAPIDepartmentUS('&table_name=transportation&agency=Federal%20Motor%20Carrier%20Safety%20Administration', 'defFmcsa',
            'Federal Motor Carrier Safety Administration Documents', 'Executive',
            'Infrastructure', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/fmcsa' >View all Federal Motor Carrier Safety Administration news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/us/fra">
    <h5 id='presidentialFeedHeadingMobile'>Federal Railroad Administration<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Federal Railroad Administration',
                <GenerateFeedDepartment url='&table_name=transportation&agency=Federal%20Railroad%20Administration'
                  name='Federal Railroad Administration' branch='Executive' topic='Infrastructure' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defFra'></div>
          {CreateFeedAPIDepartmentUS('&table_name=transportation&agency=Federal%20Railroad%20Administration', 'defFra',
            'Federal Railroad Administration Documents', 'Executive',
            'Infrastructure', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/fra' >View all Federal Railroad Administration news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/us/fta">
    <h5 id='presidentialFeedHeadingMobile'>Federal Transit Administration<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Federal Transit Administration',
                <GenerateFeedDepartment url='&table_name=transportation&agency=Federal%20Transit%20Administration'
                  name='Federal Transit Administration' branch='Executive' topic='Infrastructure' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defFta'></div>
          {CreateFeedAPIDepartmentUS('&table_name=transportation&agency=Federal%20Transit%20Administration', 'defFta',
            'Federal Transit Administration Documents', 'Executive',
            'Infrastructure', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/fta' >View all Federal Transit Administration news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/us/great-lakes">
    <h5 id='presidentialFeedHeadingMobile'>Great Lakes St. Lawrence Seaway Development Corporation<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Great Lakes St. Lawrence Seaway Development Corporation',
                <GenerateFeedDepartment url='&table_name=transportation&agency=Great%20Lakes%20St.%20Lawrence%20Seaway%20Development%20Corporation'
                  name='Great Lakes St. Lawrence Seaway Development Corporation' branch='Executive' topic='Infrastructure' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defGlslsdc'></div>
          {CreateFeedAPIDepartmentUS('&table_name=transportation&agency=Great%20Lakes%20St.%20Lawrence%20Seaway%20Development%20Corporation', 'defGlslsdc',
            'Great Lakes St. Lawrence Seaway Development Corporation Documents', 'Executive',
            'Infrastructure', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/great-lakes' >View all Great Lakes St. Lawrence Seaway Development Corporation news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/us/maritime">
    <h5 id='presidentialFeedHeadingMobile'>Maritime Administration<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Maritime Administration',
                <GenerateFeedDepartment url='&table_name=transportation&agency=Maritime%20Administration'
                  name='Maritime Administration' branch='Executive' topic='Infrastructure' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defMa'></div>
          {CreateFeedAPIDepartmentUS('&table_name=transportation&agency=Maritime%20Administration', 'defMa',
            'Maritime Administration Documents', 'Executive',
            'Infrastructure', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/maritime' >View all Maritime Administration news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/us/highway-safety">
    <h5 id='presidentialFeedHeadingMobile'>National Highway Traffic Safety Administration<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('National Highway Traffic Safety Administration',
                <GenerateFeedDepartment url='&table_name=transportation&agency=National%20Highway%20Traffic%20Safety%20Administration'
                  name='National Highway Traffic Safety Administration' branch='Executive' topic='Infrastructure' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defNhtsa'></div>
          {CreateFeedAPIDepartmentUS('&table_name=transportation&agency=National%20Highway%20Traffic%20Safety%20Administration', 'defNhtsa',
            'National Highway Traffic Safety Administration Documents', 'Executive',
            'Infrastructure', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/highway-safety' >View all National Highway Traffic Safety Administration news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/us/motor-carrier-safety">
    <h5 id='presidentialFeedHeadingMobile'>Office of Motor Carrier Safety<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Office of Motor Carrier Safety',
                <GenerateFeedDepartment url='&table_name=transportation&agency=Office%20of%20Motor%20Carrier%20Safety'
                  name='Office of Motor Carrier Safety' branch='Executive' topic='Infrastructure' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defOmcs'></div>
          {CreateFeedAPIDepartmentUS('&table_name=transportation&agency=Office%20of%20Motor%20Carrier%20Safety', 'defOmcs',
            'Office of Motor Carrier Safety Documents', 'Executive',
            'Infrastructure', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/motor-carrier-safety' >View all Office of Motor Carrier Safety news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/us/pipeline-hazardous-materials">
    <h5 id='presidentialFeedHeadingMobile'>Pipeline and Hazardous Materials Safety Administration<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Pipeline and Hazardous Materials Safety Administration',
                <GenerateFeedDepartment url='&table_name=transportation&agency=Pipeline%20and%20Hazardous%20Materials%20Safety%20Administration'
                  name='Pipeline and Hazardous Materials Safety Administration' branch='Executive' topic='Infrastructure' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defPhmsa'></div>
          {CreateFeedAPIDepartmentUS('&table_name=transportation&agency=Pipeline%20and%20Hazardous%20Materials%20Safety%20Administration', 'defPhmsa',
            'Pipeline and Hazardous Materials Safety Administration Documents', 'Executive',
            'Infrastructure', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/pipeline-hazardous-materials' >View all Pipeline and Hazardous Materials Safety Administration news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/us/innovative-technology">
    <h5 id='presidentialFeedHeadingMobile'>Research and Innovative Technology Administration<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Research and Innovative Technology Administration',
                <GenerateFeedDepartment url='&table_name=transportation&agency=Research%20and%20Innovative%20Technology%20Administration'
                  name='Research and Innovative Technology Administration' branch='Executive' topic='Infrastructure' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defRita'></div>
          {CreateFeedAPIDepartmentUS('&table_name=transportation&agency=Research%20and%20Innovative%20Technology%20Administration', 'defRita',
            'Research and Innovative Technology Administration Documents', 'Executive',
            'Infrastructure', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/innovative-technology' >View all Research and Innovative Technology Administration news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/us/research-special-programs">
    <h5 id='presidentialFeedHeadingMobile'>Research and Special Programs Administration<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Research and Special Programs Administration',
                <GenerateFeedDepartment url='&table_name=transportation&agency=Research%20and%20Special%20Programs%20Administration'
                  name='Research and Special Programs Administration' branch='Executive' topic='Infrastructure' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defRspa'></div>
          {CreateFeedAPIDepartmentUS('&table_name=transportation&agency=Research%20and%20Special%20Programs%20Administration', 'defRspa',
            'Research and Special Programs Administration Documents', 'Executive',
            'Infrastructure', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/research-special-programs' >View all Research and Special Programs Administration news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/us/transportation-statistics">
    <h5 id='presidentialFeedHeadingMobile'>Transportation Statistics Bureau<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Transportation Statistics Bureau',
                <GenerateFeedDepartment url='&table_name=transportation&agency=Transportation%20Statistics%20Bureau'
                  name='Transportation Statistics Bureau' branch='Executive' topic='Infrastructure' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defTsb'></div>
          {CreateFeedAPIDepartmentUS('&table_name=transportation&agency=Transportation%20Statistics%20Bureau', 'defTsb',
            'Transportation Statistics Bureau Documents', 'Executive',
            'Infrastructure', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/transportation-statistics' >View all Transportation Statistics Bureau news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>











<br></br>
          
          
            <br></br><br></br><br></br><br></br><br></br><br></br><Navbar id='navbarMobileBottom' fixed="bottom">    

          


       
<div id='changeBranchDropdownMobile'>

           <MobileOffCanvas/>
           
            </div>

           


           
 
             
 
 
 
 
 
 
</Navbar>
          </div>

          </>
  )
}
 }



  






export default TransportationMobile;

