import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link, Switch,  } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment,  } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Tooltip, OverlayTrigger, Image, ModalFooter } from 'react-bootstrap';
import { ArrowsAngleExpand, ArrowsFullscreen, InfoCircle, FileText,  ShieldShaded, CircleFill, Pen, People, ChatSquareQuote, ChatLeftQuote, BlockquoteRight, Collection, Grid, ThreeDots, Stack, List, Files, FolderPlus, FolderCheck, Folder, CaretRight, ChevronRight, ChevronCompactRight, CaretRightFill, ArrowRightShort,  ArrowRightCircle, PlayCircle, PauseCircle, Link45deg } from 'react-bootstrap-icons'
import { BrowserView, MobileView } from 'react-device-detect';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import Axios from 'axios'
import { render } from '@testing-library/react';

import DepartmentOfLabor from './DepartmentOfLabor';

import{ whiteHouseSearch, createPresidentialProclamations, createExecutiveOrders, createWhiteHouseDocuments, createWhiteHouseFeed,
    createWhiteHouseFeedMobile,createDepartmentOfState,
    createDepartmentOfDefense, 
    createDepartmentOfJustice, createDepartmentOfTheInterior,
    createDepartmentOfAgriculture, createDepartmentOfCommerce,
    createDepartmentOfCommerceMobile, createDepartmentOfLabor,
    createDepartmentOfHealthAndHumanServices, createDepartmentOfHousingAndUrbanDevelopment, createDepartmentOfEducation
    ,  createDepartmentOfVeteransAffairs, createDepartmentOfHomelandSecurity
    , createEnvironmentalProtectionAgency,
    createDepartmentOfTreasury, createNationalScienceFoundation, 
     createEnvironmentalProtectionAgencyMobile, createNationalAeronauticsAndSpaceAdministration, Speak, createFederalRegisterDefenseDepartment, 
     createFederalRegisterStateDepartment, createFederalRegisterDefense,
    CreateFeedItem, CreateFeedItemPresidentialDocuments, CreateExecutiveOrders,
    CreateFeedItemExecutiveOrders,
    CreateFeedItemBillUpdates,
    CreateFeedItemExecutiveOffice,
    CreateFeedItemBillWithDescription,
    CreateFeedItemNoDate,
    CreateFeedAPIDepartmentUS,  } from './Functions'
import NationalScienceFoundation from './NationalScienceFoundation';
import FederalRegisterDefenseDepartment from './FederalRegisterDefenseDepartment';
import FederalRegisterStateDepartment from './FederalRegisterStateDepartment';
import FederalRegisterTreasuryDepartment from './FederalRegisterTreasuryDepartment';
import MobileOffCanvas from './MobileOffCanvas';
import MobileTopNavbar from './MobileTopNavbar';
import BillUpdates from './BillUpdates';

import DepartmentOfLaborDocs from './DepartmentOfLaborDocs';
import DisabilityEmploymentPolicy from './DisabilityEmploymentPolicy';
import EmployeeBenefitsSecuirtyAdministration from './EmployeeBenefitsSecuirtyAdministration';
import EmployeesCompensationAppeals from './EmployeesCompensationAppeals';
import EmploymentAndTrainingAdmin from './EmploymentAndTrainingAdmin';
import FederalContractCompliancePrograms from './FederalContractCompliancePrograms';
import LaborStatsBureau from './LaborStatsBureau';
import LaborManagmentStandardsOffice from './LaborManagmentStandardsOffice';
import MineSafety from './MineSafety';
import OccupationalSafetyAndHealthAdmin from './OccupationalSafetyAndHealthAdmin';
import VeteransAffairsMobile from './VeteransAffairsMobile';
import VeteransEmployment from './VeteransEmployment';
import WageAndHourDivision from './WageAndHourDivision';
import WorkersCompenstaionPrograms from './WorkersCompenstaionPrograms';
import BenefitsReviewBoard from './BenefitsReviewBoard';
import GenerateFeedDepartment from './GenerateFeedDepartment';



var collectionCounter = 0
var carouselInterval = 30000;
var mobileCarouselInterval = 30000;



  

 


  

export const LaborMobile = () =>   {
    render()
    
    {
        const [show, setShow] = useState(true);

        
        const [fullscreen, setFullScreen] = useState(true);
      
      
        const [fullBillModalIsOpen, setFullBillModalIsOpen] = useState(false)
      
        const [reportModalIsOpen, setReportModalIsOpen] = useState(false)
      
      
        const [whiteHouseFeedModalIsOpen, setWhiteHouseFeedModalIsOpen] = useState(false)
        const [whiteHouseDocsModalIsOpen, setWhiteHouseDocsModalIsOpen] = useState(false)
      
      
        const [courtNewsModalIsOpen, setCourtNewsModalIsOpen] = useState(false)
        const [courtModalIsOpen, setCourtModalIsOpen] = useState(false)
      
        const [courtAppealsModalIsOpen, setCourtAppealsModalIsOpen] = useState(false)
      
        const [newLawsModalIsOpen, setNewLawsModalIsOpen] = useState(false)
      
        const [billsEnrolledModalIsOpen, setBillsEnrolledModalIsOpen] = useState(false)
      
        const [legislativePageBillsEnrolledModalIsOpen, setLegislativePageBillsEnrolledModalIsOpen] = useState(false)
      
      
        const [departmentOfStateModalIsOpen, setDepartmentOfStateModalIsOpen] = useState(false)
        const [departmentOfTreasuryModalIsOpen, setDepartmentOfTreasuryModalIsOpen] = useState(false)
     
        const [departmentOfJusticeModalIsOpen, setDepartmentOfJusticeModalIsOpen] = useState(false)
        const [departmentOfTheInteriorModalIsOpen, setDepartmentOfTheInteriorModalIsOpen] = useState(false)
        const [departmentOfAgricultureModalIsOpen, setDepartmentOfAgricultureModalIsOpen] = useState(false)
        const [departmentOfCommerceModalIsOpen, setDepartmentOfCommerceModalIsOpen] = useState(false)
        const [departmentOfLaborModalIsOpen, setDepartmentOfLaborModalIsOpen] = useState(false)
        const [departmentOfHealthAndHumanServicesModalIsOpen, setDepartmentOfHealthAndHumanServicesModalIsOpen] = useState(false)
        const [departmentOfHousingAndUrbanDevelopmentModalIsOpen, setDepartmentOfHousingAndUrbanDevelopmentModalIsOpen] = useState(false)
        const [departmentOfTransportationModalIsOpen, setDepartmentOfTransportationModalIsOpen] = useState(false)
        const [departmentOfEnergyModalIsOpen, setDepartmentOfEnergyModalIsOpen] = useState(false)
        const [departmentOfEducationModalIsOpen, setDepartmentOfEducationModalIsOpen] = useState(false)
        const [departmentOfVeteransAffairsModalIsOpen, setDepartmentOfVeteransAffairsModalIsOpen] = useState(false)
        const [departmentOfHomelandSecurityModalIsOpen, setDepartmentOfHomelandSecurityModalIsOpen] = useState(false)
        const [epaModalIsOpen, setEPAModalIsOpen] = useState(false)
        const [nsfModalIsOpen, setNSFModalIsOpen] = useState(false)
        const [nasaModalIsOpen, setNASAModalIsOpen] = useState(false)
        const [trendingBillsModalIsOpen, setTrendingBillsModalIsOpen] = useState(false)
        const [goaReportsModalIsOpen, setGAOReportsModalIsOpen] = useState(false)
        const [executiveOrdersModalIsOpen, setExecutiveOrdersModalIsOpen] = useState(false)
        const [presidentialProclamationsModalIsOpen, setPresidentialProclamationsModalIsOpen] = useState(false)
        const [statesModalIsOpen, setStatesModalIsOpen] = useState(false)
      
      
        const [collectionsModalIsOpen, setCollectionsModalIsOpen] = useState(false)
        const [aboutModalIsOpen, setAboutModalIsOpen] = useState(false)
      
      
        const [federalRegisterDefenseDepartmentModalIsOpen, setFederalRegisterDefenseDepartmentModalIsOpen] = useState(false)
  const [federalRegisterStateDepartmentModalIsOpen, setFederalRegisterStateDepartmentModalIsOpen] = useState(false)
        const [declarationModalIsOpen, setDeclarationModalIsOpen] = useState(false)
        const [constitutionModalIsOpen, setConstitutionModalIsOpen] = useState(false)
        const [billOfRightsnModalIsOpen, setBillOfRightsModalIsOpen] = useState(false)
        const [showBioModal, setBioModal] = useState(false);
        const [showMissionModal, setMissionModal] = useState(false);
        const [economicAdModalIsOpen, setEconomicAdModalIsOpen] = useState(false)
        const [environmentQualityModalIsOpen, setEnvironmentQualityModalIsOpen] = useState(false)
        const [domesticPolicyModalIsOpen, setDomesticPolicyModalIsOpen] = useState(false)
        const [genderPolicyModalIsOpen, setGenderPolicyModalIsOpen] = useState(false)
        const [nationalEconomicModalIsOpen, setNationalEconomicModalIsOpen] = useState(false)
        const [securityModalIsOpen, setSecurityModalIsOpen] = useState(false)
        const [budgetModalIsOpen, setBudgetModalIsOpen] = useState(false)
        const [drugModalIsOpen, setDrugModalIsOpen] = useState(false)
        const [publicModalIsOpen, setPublicModalIsOpen] = useState(false)
        const [scienceModalIsOpen, setScienceModalIsOpen] = useState(false)
        const [cyberModalIsOpen, setCyberModalIsOpen] = useState(false)
        const [presidentialModalIsOpen, setPresidentialModalIsOpen] = useState(false)

        const [departmentOfDefenseModalIsOpen, setDepartmentOfDefenseModalIsOpen] = useState(false)
        const [defenseDocumentsModalIsOpen, setDefenseDocumentsModalIsOpen] = useState(false)
        const [armyModalIsOpen, setArmyModalIsOpen] = useState(false)
        const [navyModalIsOpen, setNavyModalIsOpen] = useState(false)
        const [marinesModalIsOpen, setMarinesModalIsOpen] = useState(false)
        const [spaceForceModalIsOpen, setSpaceForceModalIsOpen] = useState(false)
        const [airForceModalIsOpen, setAirForceModalIsOpen] = useState(false)
        const [nationalGuardModalIsOpen, setNationalGuardModalIsOpen] = useState(false)
        const [ciaModalIsOpen, setCIAModalIsOpen] = useState(false)
        const [nsaModalIsOpen, setNSAModalIsOpen] = useState(false)
        const [armyCorpsModalIsOpen, setArmyCorpsModalIsOpen] = useState(false)
        const [modalInfo, setModalInfo] = useState({
          isOpen: false,
          title: '',
          content: null
        });
      
        const openModal = (title, content) => {
          setModalInfo({
            isOpen: true,
            title,
            content
          });
        };
      
        const closeModal = () => {
          setModalInfo({
            isOpen: false,
            title: '',
            content: null
          });
        };

        var mobileCarouselInterval = 60000;
        const CustomModal = ({ isOpen, onClose, title, content }) => (
          <Modal id='fullBillModal' show={isOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={onClose}>
            <Modal.Header id='billModalHeader' closeButton><h3>{title}</h3></Modal.Header>
            {content}
          </Modal>
        );
    var branch = "all";
  
    return(
       <>
   <MobileTopNavbar/>
  
  
   <CustomModal isOpen={modalInfo.isOpen} onClose={closeModal} title={modalInfo.title} content={modalInfo.content} />

  
  <div id='homebg'>

               
  <div id="feedDisplayDepartment" style={{ padding: '10px', borderRadius: '18px' }}>
  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div id="profileImageOnPageDepartment">
                <Image id="profileImage-imageOnPageDepartment" src="/departmentOfLabor.png" />
              </div>
              <div style={{ marginLeft: '10px', color: 'white', fontSize: '18px', fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
                <h5 id="branchHeadingMobile" > Department of Labor</h5>
              </div>
            </div>
            <Dropdown drop="down-centered">
              <Dropdown.Toggle id="searchCountry"><Link45deg/></Dropdown.Toggle>
              <Dropdown.Menu id="docsDropdown" drop="down-centered">
                <Dropdown.Item id="docsDropdownItem" onClick={(e) => { e.preventDefault(); window.open("https://www.dol.gov/", "_blank") }}>
                  Official Site
                </Dropdown.Item>

                
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div id="billText" style={{ color: 'white', fontSize: '16px', marginTop: '10px' }}>
          
          The Department of Labor oversees federal programs for ensuring a strong American workforce. These programs address job training, safe working conditions, minimum hourly wage and overtime pay, employment discrimination, and unemployment insurance.
          </div>

        </div>
        <Button id='presidentViewMoreButton' onClick={() =>  setBioModal(true)}>  <Files size={20}></Files> Read More</Button>


       
<Modal id='fullBillModal'  size="xl" aria-labelledby="contained-modal-title-vcenter" show={showBioModal} onHide={() => setBioModal(false)}>
<Modal.Header id='billModalHeader' closeButton><h3> Department of Labor</h3></Modal.Header>
       
       <div id='fullBillFeed' >
       <p>The Department of Labor oversees federal programs for ensuring a strong American workforce. These programs address job training, safe working conditions, minimum hourly wage and overtime pay, employment discrimination, and unemployment insurance.</p>

<p>The Department of Labor’s mission is to foster and promote the welfare of the job seekers, wage earners, and retirees of the United States by improving their working conditions, advancing their opportunities for profitable employment, protecting their retirement and health care benefits, helping employers find workers, strengthening free collective bargaining, and tracking changes in employment, prices, and other national economic measurements.</p>

<p>Offices within the Department of Labor include the Occupational Safety & Health Administration, which promotes the safety and health of America’s working men and women, and the Bureau of Labor Statistics, the federal government’s principal statistics agency for labor economics.</p>

<p>The Secretary of Labor oversees 15,000 employees on a budget of approximately $12 billion.</p>



<br></br>
<Link onClick={(e) => {
   e.preventDefault();
   window.open("https://www.whitehouse.gov/about-the-white-house/our-government/the-executive-branch/#:~:text=of%20federal%20laws.-,DEPARTMENT%20OF%20LABOR,-The%20Department%20of", "_blank");
 }}>
   https://www.whitehouse.gov/about-the-white-house/our-government/the-executive-branch/#:~:text=of%20federal%20laws.-,DEPARTMENT%20OF%20LABOR,-The%20Department%20of
   </Link>
</div>

     </Modal>
            <Col>


<h5 id='presidentialFeedHeadingMobile' onClick={() => setDepartmentOfDefenseModalIsOpen(true)}>News</h5>




  <div class='courtNewsMobile' id='laborDepartment'></div>

  {CreateFeedItemNoDate('https://www.dol.gov/rss/releases.xml', 'laborDepartment', 'Department of Labor', 'Executive', 'Economy', DepartmentOfLabor)}

  <Modal id='whiteHouseDocsModal' show={departmentOfDefenseModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfDefenseModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Labor</h3></Modal.Header>
                  <DepartmentOfLabor/>
                </Modal>

  </Col>


  <Col>


<h5 id='presidentialFeedHeadingMobile' onClick={() => setDefenseDocumentsModalIsOpen(true)}>Documents</h5>


  <div class='courtNewsMobile' id='frDefense'></div>

  {CreateFeedItem('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=labor-department', 'frDefense', 'Department of Labor Documents', 'Executive', 'N/A', DepartmentOfLaborDocs)}

</Col>
              
                <Modal id='whiteHouseDocsModal' show={defenseDocumentsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDefenseDocumentsModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Labor Documents</h3></Modal.Header>
                  <DepartmentOfLaborDocs/>
                </Modal>
                
                <Col>
  <Link id='pageLink' to="/us/benefits-review-board">
    <h5 id='presidentialFeedHeadingMobile'>Benefits Review Board<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Benefits Review Board',
                <GenerateFeedDepartment url='&table_name=labor&agency=Benefits%20Review%20Board'
                  name='Benefits Review Board' branch='Executive' topic='Economy' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defBrb'></div>
          {CreateFeedAPIDepartmentUS('&table_name=labor&agency=Benefits%20Review%20Board', 'defBrb',
            'Benefits Review Board Documents', 'Executive',
            'Economy', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/benefits-review-board' >View all Benefits Review Board news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link id='pageLink' to="/us/disability-employment-policy">
    <h5 id='presidentialFeedHeadingMobile'>Disability Employment Policy Office<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Disability Employment Policy Office',
                <GenerateFeedDepartment url='&table_name=labor&agency=Disability%20Employment%20Policy%20Office'
                  name='Disability Employment Policy Office' branch='Executive' topic='Economy' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defDepo'></div>
          {CreateFeedAPIDepartmentUS('&table_name=labor&agency=Disability%20Employment%20Policy%20Office', 'defDepo',
            'Disability Employment Policy Office Documents', 'Executive',
            'Economy', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/disability-employment-policy' >View all Disability Employment Policy Office news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link id='pageLink' to="/us/employee-benefits-security">
    <h5 id='presidentialFeedHeadingMobile'>Employee Benefits Security Administration<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Employee Benefits Security Administration',
                <GenerateFeedDepartment url='&table_name=labor&agency=Employee%20Benefits%20Security%20Administration'
                  name='Employee Benefits Security Administration' branch='Executive' topic='Economy' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defEbsa'></div>
          {CreateFeedAPIDepartmentUS('&table_name=labor&agency=Employee%20Benefits%20Security%20Administration', 'defEbsa',
            'Employee Benefits Security Administration Documents', 'Executive',
            'Economy', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/employee-benefits-security' >View all Employee Benefits Security Administration news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link id='pageLink' to="/us/employees-compensation-appeals">
    <h5 id='presidentialFeedHeadingMobile'>Employees Compensation Appeals Board<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Employees Compensation Appeals Board',
                <GenerateFeedDepartment url='&table_name=labor&agency=Employees%20Compensation%20Appeals%20Board'
                  name='Employees Compensation Appeals Board' branch='Executive' topic='Economy' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defEcab'></div>
          {CreateFeedAPIDepartmentUS('&table_name=labor&agency=Employees%20Compensation%20Appeals%20Board', 'defEcab',
            'Employees Compensation Appeals Board Documents', 'Executive',
            'Economy', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/employees-compensation-appeals' >View all Employees Compensation Appeals Board news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link id='pageLink' to="/us/employment-training">
    <h5 id='presidentialFeedHeadingMobile'>Employment and Training Administration<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Employment and Training Administration',
                <GenerateFeedDepartment url='&table_name=labor&agency=Employment%20and%20Training%20Administration'
                  name='Employment and Training Administration' branch='Executive' topic='Economy' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defEta'></div>
          {CreateFeedAPIDepartmentUS('&table_name=labor&agency=Employment%20and%20Training%20Administration', 'defEta',
            'Employment and Training Administration Documents', 'Executive',
            'Economy', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/employment-training' >View all Employment and Training Administration news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link id='pageLink' to="/us/employment-standards">
    <h5 id='presidentialFeedHeadingMobile'>Employment Standards Administration<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Employment Standards Administration',
                <GenerateFeedDepartment url='&table_name=labor&agency=Employment%20Standards%20Administration'
                  name='Employment Standards Administration' branch='Executive' topic='Economy' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defEsa'></div>
          {CreateFeedAPIDepartmentUS('&table_name=labor&agency=Employment%20Standards%20Administration', 'defEsa',
            'Employment Standards Administration Documents', 'Executive',
            'Economy', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/employment-standards' >View all Employment Standards Administration news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link id='pageLink' to="/us/contract-compliance">
    <h5 id='presidentialFeedHeadingMobile'>Federal Contract Compliance Programs Office<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Federal Contract Compliance Programs Office',
                <GenerateFeedDepartment url='&table_name=labor&agency=Federal%20Contract%20Compliance%20Programs%20Office'
                  name='Federal Contract Compliance Programs Office' branch='Executive' topic='Economy' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defFccpo'></div>
          {CreateFeedAPIDepartmentUS('&table_name=labor&agency=Federal%20Contract%20Compliance%20Programs%20Office', 'defFccpo',
            'Federal Contract Compliance Programs Office Documents', 'Executive',
            'Economy', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/contract-compliance' >View all Federal Contract Compliance Programs Office news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link id='pageLink' to="/us/labor-statistics">
    <h5 id='presidentialFeedHeadingMobile'>Labor Statistics Bureau<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Labor Statistics Bureau',
                <GenerateFeedDepartment url='&table_name=labor&agency=Labor%20Statistics%20Bureau'
                  name='Labor Statistics Bureau' branch='Executive' topic='Economy' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defLsb'></div>
          {CreateFeedAPIDepartmentUS('&table_name=labor&agency=Labor%20Statistics%20Bureau', 'defLsb',
            'Labor Statistics Bureau Documents', 'Executive',
            'Economy', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/labor-statistics' >View all Labor Statistics Bureau news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link id='pageLink' to="/us/labor-management-standards">
    <h5 id='presidentialFeedHeadingMobile'>Labor-Management Standards Office<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Labor-Management Standards Office',
                <GenerateFeedDepartment url='&table_name=labor&agency=Labor-Management%20Standards%20Office'
                  name='Labor-Management Standards Office' branch='Executive' topic='Economy' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defLmso'></div>
          {CreateFeedAPIDepartmentUS('&table_name=labor&agency=Labor-Management%20Standards%20Office', 'defLmso',
            'Labor-Management Standards Office Documents', 'Executive',
            'Economy', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/labor-management-standards' >View all Labor-Management Standards Office news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link id='pageLink' to="/us/mine-safety">
    <h5 id='presidentialFeedHeadingMobile'>Mine Safety and Health Administration<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Mine Safety and Health Administration',
                <GenerateFeedDepartment url='&table_name=labor&agency=Mine%20Safety%20and%20Health%20Administration'
                  name='Mine Safety and Health Administration' branch='Executive' topic='Economy' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defMsha'></div>
          {CreateFeedAPIDepartmentUS('&table_name=labor&agency=Mine%20Safety%20and%20Health%20Administration', 'defMsha',
            'Mine Safety and Health Administration Documents', 'Executive',
            'Economy', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/mine-safety' >View all Mine Safety and Health Administration news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link id='pageLink' to="/us/osha">
    <h5 id='presidentialFeedHeadingMobile'>Occupational Safety and Health Administration<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Occupational Safety and Health Administration',
                <GenerateFeedDepartment url='&table_name=labor&agency=Occupational%20Safety%20and%20Health%20Administration'
                  name='Occupational Safety and Health Administration' branch='Executive' topic='Economy' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defOsha'></div>
          {CreateFeedAPIDepartmentUS('&table_name=labor&agency=Occupational%20Safety%20and%20Health%20Administration', 'defOsha',
            'Occupational Safety and Health Administration Documents', 'Executive',
            'Economy', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/osha' >View all Occupational Safety and Health Administration news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link id='pageLink' to="/us/pension-welfare">
    <h5 id='presidentialFeedHeadingMobile'>Pension and Welfare Benefits Administration<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Pension and Welfare Benefits Administration',
                <GenerateFeedDepartment url='&table_name=labor&agency=Pension%20and%20Welfare%20Benefits%20Administration'
                  name='Pension and Welfare Benefits Administration' branch='Executive' topic='Economy' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defPwba'></div>
          {CreateFeedAPIDepartmentUS('&table_name=labor&agency=Pension%20and%20Welfare%20Benefits%20Administration', 'defPwba',
            'Pension and Welfare Benefits Administration Documents', 'Executive',
            'Economy', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/pension-welfare' >View all Pension and Welfare Benefits Administration news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link id='pageLink' to="/us/veterans-employment">
    <h5 id='presidentialFeedHeadingMobile'>Veterans Employment and Training Service<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Veterans Employment and Training Service',
                <GenerateFeedDepartment url='&table_name=labor&agency=Veterans%20Employment%20and%20Training%20Service'
                  name='Veterans Employment and Training Service' branch='Executive' topic='Economy' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defVets'></div>
          {CreateFeedAPIDepartmentUS('&table_name=labor&agency=Veterans%20Employment%20and%20Training%20Service', 'defVets',
            'Veterans Employment and Training Service Documents', 'Executive',
            'Economy', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/veterans-employment' >View all Veterans Employment and Training Service news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link id='pageLink' to="/us/wage-hour">
    <h5 id='presidentialFeedHeadingMobile'>Wage and Hour Division<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Wage and Hour Division',
                <GenerateFeedDepartment url='&table_name=labor&agency=Wage%20and%20Hour%20Division'
                  name='Wage and Hour Division' branch='Executive' topic='Economy' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defWhd'></div>
          {CreateFeedAPIDepartmentUS('&table_name=labor&agency=Wage%20and%20Hour%20Division', 'defWhd',
            'Wage and Hour Division Documents', 'Executive',
            'Economy', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/wage-hour' >View all Wage and Hour Division news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link id='pageLink' to="/us/workers-compensation">
    <h5 id='presidentialFeedHeadingMobile'>Workers' Compensation Programs Office<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Workers\' Compensation Programs Office',
                <GenerateFeedDepartment url='&table_name=labor&agency=Workers%20Compensation%20Programs%20Office'
                  name='Workers Compensation Programs Office' branch='Executive' topic='Economy' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defWcpo'></div>
          {CreateFeedAPIDepartmentUS('&table_name=labor&agency=Workers%20Compensation%20Programs%20Office', 'defWcpo',
            'Workers\' Compensation Programs Office Documents', 'Executive',
            'Economy', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/workers-compensation' >View all Workers' Compensation Programs Office news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>







<br></br>
          
          
            <br></br><br></br><br></br><br></br><br></br><br></br><Navbar id='navbarMobileBottom' fixed="bottom">    

          


       
<div id='changeBranchDropdownMobile'>

           <MobileOffCanvas/>
         
            </div>

           


           
 
             
 
 
 
 
 
 
</Navbar>
          </div>

          </>
  )
}
 }



  






export default LaborMobile;

