import App from "../../App";
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Image, ModalFooter } from 'react-bootstrap';
import { ArrowsAngleExpand, InfoCircle, FileText, PauseCircle, PlayCircle, FolderPlus, ChevronRight, Files, Folder, Pen, ChatLeftQuote, ShieldShaded, People, HeartFill } from 'react-bootstrap-icons'
import '../../App.css';
import 'bootstrap/dist/css/bootstrap.min.css'

import Axios from 'axios'
import { render } from '@testing-library/react';

import{ ListItem, createFullBillFeed, saveToCollection, createHouseCommitteeOversightAndReform, createHouseCommitteeOnRules,
    createHouseCommitteeOnSmallBusiness, createHouseCommitteeOnScienceSpaceAndTechnology,
    createHouseCommitteeOnTransportationAndInfrastructure,
    createHouseCommitteeOnVeteransAffairs,
    createCongressionalBudgetOffice, createBillFeedTwo,
    createBillFeedTwoMobile, createReports, createHouseCommitteeOnAgriculture,
     createHouseCommitteeOnAppropriations, createHouseCommitteeOnArmedServices,
     createHouseCommitteeOnTheBudget, createHouseCommitteeOnEducationAndLabor,
    createHouseCommitteeOnEnergyAndCommerce, createHouseCommitteeOnEthics,
     createHouseCommitteeOnFinancialServices, createHouseCommitteeOnForeignAffairs, createHouseCommitteeOnHomelandSecurity,
    createHouseCommitteeOnHouseAdministration, 
    createHouseCommitteeOnTheJudiciary, createHouseCommitteeOnNaturalResources, 
     createHouseCommitteeOnNaturalResourcesMobile, createGAOReports,
     createNewLaws, createBillsEnrolled
    , createBillsEnrolledMobile, createTrendingBills
    , createHouseFloorToday, congressSearch, Speak, createGAOReportsMobile, createCongressionalHearings, createBillStatus, CreateFeedItem,
    CreateFeedItemBillUpdates, CreateFeedItemWithDocButton, CreateFeedItemWithLawButton,
    CreateFeedItemGAOReports, CreateFeedItemCongressionalReports, CreateFeedItemWithDocumentFlip, CreateFeedItemWithLawButtonFlip, CreateFeedItemWithDocButtonDateFix, CreateFeedItemCanadaDateFix, CreateFeedItemMexico, CreateFeedAPI} from '../../Functions'




import MexicoMobileTopNavbar from "../MexicoMobileTopNavbar";
import AgricultureAgency from "./AgricultureAgency";
import WelfareAgency from "./WelfareAgency";
import CjefAgency from "./CjefAgency";
import ConadeAgency from "./ConadeAgency";
import ConadisAgency from "./ConadisAgency";
import ConaquaAgency from "./ConaquaAgency";
import ConaforAgency from "./ConaforAgency";
import CorreosDeMexicoAgency from "./CorreosDeMexicoAgency";
import INMAgency from "./INMAgency";
import SaludAgency from "./SaludAgency";
import SCTAgency from "./SCTAgency";
import SEAgency from "./SeAgency";
import SecturAgency from "./SecturAgency";
import SedatuAgency from "./SedatuAgency";
import SedenaAgency from "./SedenaAgency";
import SEGOBAgency from "./SegobAgency";
import SemarAgency from "./SemarAgency";
import SemarnatAgency from "./SemarnatAgency";
import SFPAgency from "./SFPAgency";
import SHCPAgency from "./SHCPAgency";
import SREAgency from "./SREAgency";
import SSPCAgency from "./SSPCAgency";
import STPSAgency from "./STPSAgency";
import PresidentUpdates from "./PresidentUpdates";
import MobileOffCanvasMexico from "../../MobileOffCanvasMexico";
import GenerateFeed from "../../GenerateFeed";


    var response=''

var collectionCounter = 0
var carouselInterval = 30000;
var mobileCarouselInterval = 60000;


  class PlayUpdateButtonMobile extends React.Component { 
      constructor(props) {
        super(props);
        this.state = {
          isCardView: false,
        }
      } 
      
      render() {
        return (
          <Button  id='navbarUpdateButtonMobile' size='lg'  onClick={()=>{this.setState({ isCardView: !this.state.isCardView }); Speak(response);  }}  >
            { this.state.isCardView                                                   
              ? <PauseCircle size={30}   />
              : <PlayCircle size={30}  />
            }
            &nbsp;&nbsp;Update (Beta)
          </Button>
        );
      }
      
    }
  
   
  
  
    
  class PlayUpdateButton extends React.Component { 
    constructor(props) {
      super(props);
      this.state = {
        isCardView: false,
      }
    } 
    
    render() {
      return (
        <Button id='navbarCollectionButtonDesktop' size='lg'  onClick={()=>{this.setState({ isCardView: !this.state.isCardView }); Speak(response);  }}  >
          { this.state.isCardView                                                   
            ? <PauseCircle  />
            : <PlayCircle />
          }
          &nbsp;&nbsp;Update (Beta)
        </Button>
      );
    }
    
  }

export const MexicoExecutiveBranchMobile = () =>   {
    render()
    
    {
  
  
        const [show, setShow] = useState(true);

        const [modalIsOpen, setModalIsOpen] = useState(false)
        const [fullscreen, setFullScreen] = useState(true);
      
      
        const [fullBillModalIsOpen, setFullBillModalIsOpen] = useState(false)
      
        const [reportModalIsOpen, setReportModalIsOpen] = useState(false)
      
      
        const [whiteHouseFeedModalIsOpen, setWhiteHouseFeedModalIsOpen] = useState(false)
        const [whiteHouseDocsModalIsOpen, setWhiteHouseDocsModalIsOpen] = useState(false)
      
      
        const [courtNewsModalIsOpen, setCourtNewsModalIsOpen] = useState(false)
        const [courtModalIsOpen, setCourtModalIsOpen] = useState(false)
      
        const [courtAppealsModalIsOpen, setCourtAppealsModalIsOpen] = useState(false)
      
        const [newLawsModalIsOpen, setNewLawsModalIsOpen] = useState(false)
      
        const [billsEnrolledModalIsOpen, setBillsEnrolledModalIsOpen] = useState(false)
      
        const [legislativePageBillsEnrolledModalIsOpen, setLegislativePageBillsEnrolledModalIsOpen] = useState(false)
      
      
        const [departmentOfStateModalIsOpen, setDepartmentOfStateModalIsOpen] = useState(false)
        const [departmentOfTreasuryModalIsOpen, setDepartmentOfTreasuryModalIsOpen] = useState(false)
        const [departmentOfDefenseModalIsOpen, setDepartmentOfDefenseModalIsOpen] = useState(false)
        const [departmentOfJusticeModalIsOpen, setDepartmentOfJusticeModalIsOpen] = useState(false)
        const [departmentOfTheInteriorModalIsOpen, setDepartmentOfTheInteriorModalIsOpen] = useState(false)
        const [departmentOfAgricultureModalIsOpen, setDepartmentOfAgricultureModalIsOpen] = useState(false)
        const [departmentOfCommerceModalIsOpen, setDepartmentOfCommerceModalIsOpen] = useState(false)
        const [departmentOfLaborModalIsOpen, setDepartmentOfLaborModalIsOpen] = useState(false)
        const [departmentOfHealthAndHumanServicesModalIsOpen, setDepartmentOfHealthAndHumanServicesModalIsOpen] = useState(false)
        const [departmentOfHousingAndUrbanDevelopmentModalIsOpen, setDepartmentOfHousingAndUrbanDevelopmentModalIsOpen] = useState(false)
        const [departmentOfTransportationModalIsOpen, setDepartmentOfTransportationModalIsOpen] = useState(false)
        const [departmentOfEnergyModalIsOpen, setDepartmentOfEnergyModalIsOpen] = useState(false)
        const [departmentOfEducationModalIsOpen, setDepartmentOfEducationModalIsOpen] = useState(false)
        const [departmentOfVeteransAffairsModalIsOpen, setDepartmentOfVeteransAffairsModalIsOpen] = useState(false)
        const [departmentOfHomelandSecurityModalIsOpen, setDepartmentOfHomelandSecurityModalIsOpen] = useState(false)
        const [epaModalIsOpen, setEPAModalIsOpen] = useState(false)
        const [nasaModalIsOpen, setNASAModalIsOpen] = useState(false)
        const [trendingBillsModalIsOpen, setTrendingBillsModalIsOpen] = useState(false)
        const [goaReportsModalIsOpen, setGAOReportsModalIsOpen] = useState(false)
          const [houseCommitteeOnAppropriationsModalIsOpen, setHouseCommitteeOnAppropriationsModalIsOpen] = useState(false)
        const [houseComitteeOnAgricultureModalIsOpen, setHouseComitteeOnAgricultureModalIsOpen] = useState(false)
          const [houseCommitteeOnArmedServicesModalIsOpen, setHouseCommitteeOnOnArmedServicesModalIsOpen] = useState(false)
          const [houseCommitteeOnTheBudgetModalIsOpen, setHouseCommitteeOnTheBudgetModalIsOpen] = useState(false)
          const [houseFloorModalIsOpen, setHouseFloorModalIsOpen] = useState(false)
          const [houseCommitteeOnEnergyAndCommerceModalIsOpen, setHouseCommitteeOnEnergyAndCommerceModalIsOpen] = useState(false)
          const [houseCommitteeOnEducationAndLaborModalIsOpen, setHouseCommitteeOnEducationAndLaborModalIsOpen] = useState(false)
          const [houseCommitteeOnEthicsModalIsOpen, setHouseCommitteeOnEthicsModalIsOpen] = useState(false)
          const [houseCommitteeOnFinancialServicesModalIsOpen, setHouseCommitteeOnFinancialServicesModalIsOpen] = useState(false)
          const [houseCommitteeOnForeignAffairsModalIsOpen, setHouseCommitteeOnForeignAffairsModalIsOpen] = useState(false)
          const [houseCommitteeOnHomelandSecurityModalIsOpen, setHouseCommitteeOnHomelandSecurityModalIsOpen] = useState(false)
          const [houseCommitteeOnHouseAdministrationModalIsOpen, setHouseCommitteeOnHouseAdministrationModalIsOpen] = useState(false)
          const [houseCommitteeOnTheJudiciaryModalIsOpen, setHouseCommitteeOnTheJudiciaryModalIsOpen] = useState(false)
          const [houseCommitteeOnNaturalResourcesModalIsOpen, setHouseCommitteeOnNaturalResourcesModalIsOpen] = useState(false)
          const [houseCommitteeOnOversightAndReformModalIsOpen, setHouseCommitteeOnOversightAndReformModalIsOpen] = useState(false)
          const [congressionalBudgetOfficeModalIsOpen, setCongressionalBudgetOfficeModalIsOpen] = useState(false)
          const [houseCommitteeOnRulesModalIsOpen, setHouseCommitteeOnRulesModalIsOpen] = useState(false)
          const [houseCommitteeOnScienceSpaceAndTechnologyModalIsOpen, setHouseCommitteeOnScienceSpaceAndTechnologyModalIsOpen] = useState(false)
          const [houseCommitteeOnSmallBusinessModalIsOpen, setHouseCommitteeOnSmallBusinessModalIsOpen] = useState(false)
          const [houseCommitteeOnTransportationAndInfrastructureModalIsOpen, setHouseCommitteeOnTransportationAndInfrastructureModalIsOpen] = useState(false)
          const [houseCommitteeOnVeteransAffairsModalIsOpen, setHouseCommitteeOnVeteransAffairsModalIsOpen] = useState(false)
          const [congressionalHearingsModalIsOpen, setCongressionalHearingsModalIsOpen] = useState(false)
          
          const [trendingBillsOptionOne, setTrendingBillsOptionOneModalIsOpen ] = useState(false)
          const [trendingBillsOptionTwo, setTrendingBillsOptionTwoModalIsOpen ] = useState(false)
          const [trendingBillsOptionThree, setTrendingBillsOptionThreeModalIsOpen ] = useState(false)
          const [trendingBillsOptionFour, setTrendingBillsOptionFourModalIsOpen ] = useState(false)
          const [trendingBillsOptionFive, setTrendingBillsOptionFiveModalIsOpen ] = useState(false)
          const [trendingBillsOptionSix, setTrendingBillsOptionSixModalIsOpen ] = useState(false)
          const [trendingBillsOptionSeven, setTrendingBillsOptionSevenModalIsOpen ] = useState(false)
          const [trendingBillsOptionEight, setTrendingBillsOptionEightModalIsOpen ] = useState(false)
          const [trendingBillsOptionNine, setTrendingBillsOptionNineModalIsOpen ] = useState(false)
          const [trendingBillsOptionTen, setTrendingBillsOptionTenModalIsOpen ] = useState(false)
          const [trendingBillsOptionEleven, setTrendingBillsOptionElevenModalIsOpen ] = useState(false)
          const [trendingBillsOptionTweleve, setTrendingBillsOptionTweleveModalIsOpen ] = useState(false)
          const [trendingBillsOptionThirteen, setTrendingBillsOptionThirteenModalIsOpen ] = useState(false)
      
        const [collectionsModalIsOpen, setCollectionsModalIsOpen] = useState(false)
        const [aboutModalIsOpen, setAboutModalIsOpen] = useState(false)
        const [statesModalIsOpen, setStatesModalIsOpen] = useState(false)
      
      
        const [declarationModalIsOpen, setDeclarationModalIsOpen] = useState(false)
        const [constitutionModalIsOpen, setConstitutionModalIsOpen] = useState(false)
        const [billOfRightsnModalIsOpen, setBillOfRightsModalIsOpen] = useState(false)
    
    
        const [billStatusModalIsOpen, setBillStatusModalIsOpen] = useState(false)
        localStorage.setItem('currentPage', 'mexico')
    return(
       <>
    <MexicoMobileTopNavbar/>
  
  
  
  
  <div id='homebg'>
 

{/*Legislative Mobile Carousel*/}

<h5 id='branchHeadingMobile'> <People/> Executive   </h5>
{/*onClick={() => setModalIsOpen(true)}*/}













<Modal show={modalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setModalIsOpen(false)}>
<Modal.Header id='iframeModalHeader' closeButton>Congressional Record</Modal.Header>
<Card id='iframeColMobile'>
  <iframe src='https://www.congress.gov/congressional-record' height='1000px' width='410px' frameBorder='0'><div id='modalFeedMobile'></div></iframe></Card></Modal>



  <Col>
  <Row id = 'mobileFeedHeadingRow'><h5 id='presidentialFeedHeadingMobile' onClick={() => setDepartmentOfStateModalIsOpen(true)}>President Newsroom</h5></Row>

  <div id='president'></div>
                          
  {CreateFeedAPI('&skip=0&schema=mexico&table=presidente&order_by=created_at', 'president', 
                            'President Newsroom', 'Executive', 
                            'N/A', 'Doc', GenerateFeed)}


<Modal id='mobileFullBillModal' show={departmentOfStateModalIsOpen} 
size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfStateModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>President Newsroom</h3></Modal.Header>
<GenerateFeed url='&skip=0&schema=mexico&table=presidente&order_by=created_at'
                                name='President Newsroom' branch='Executive' topic='N/A' buttonName='Doc' />
</Modal>

</Col>


<Col>
  <h5 id='presidentialFeedHeadingMobile' onClick={() => setFullBillModalIsOpen(true)}>Secretaría de Agricultura y Desarrollo Rural</h5>

  <div id='executiveNews'></div>
                          
                          {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
                          {CreateFeedAPI('&skip=0&schema=mexico&table=agricultura&order_by=created_at', 'executiveNews', 
                            'Secretaría de Agricultura y Desarrollo Rural', 'Legislative', 
                            'Environment', 'Doc', GenerateFeed)}


<Modal id='mobileFullBillModal' show={fullBillModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setFullBillModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Secretaría de Agricultura y Desarrollo Rural</h3></Modal.Header>
<GenerateFeed url='&skip=0&schema=mexico&table=agricultura&order_by=created_at'
                                name='Secretaría de Agricultura y Desarrollo Rural' branch='Legislative' topic='N/A' buttonName='Doc' />
</Modal>
</Col>


<Col>
  <h5 id='presidentialFeedHeadingMobile' onClick={() => setTrendingBillsModalIsOpen(true)}>Secretaría de Bienestar</h5>

  <div id='welfare'></div>
  {CreateFeedAPI('&skip=0&schema=mexico&table=bienestar&order_by=created_at', 'welfare', 
                            'Secretaría de Bienestar', 'Legislative', 
                            'N/A', 'Doc', GenerateFeed)}




<Modal id='mobileFullBillModal' show={trendingBillsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Secretaría de Bienestar</h3></Modal.Header>
<GenerateFeed url='&skip=0&schema=mexico&table=bienestar&order_by=created_at'
                                name='Secretaría de Bienestar' branch='Legislative' topic='N/A' buttonName='Doc'/>
</Modal>
</Col>


<Col>
  <h5 id='presidentialFeedHeadingMobile' onClick={() => setTrendingBillsOptionOneModalIsOpen(true)}>Consejería Jurídica del Ejecutivo Federal</h5>

  <div id='cjef'></div>
                          
  {CreateFeedAPI('&skip=0&schema=mexico&table=cjef&order_by=created_at', 'cjef', 
                            'Consejería Jurídica del Ejecutivo Federal', 'Executive', 
                            'N/A', 'Doc', GenerateFeed)}




<Modal id='mobileFullBillModal' show={trendingBillsOptionOne} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsOptionOneModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Secretaría de Bienestar</h3></Modal.Header>
<GenerateFeed url='&skip=0&schema=mexico&table=cjef&order_by=created_at'
                                name='Consejería Jurídica del Ejecutivo Federal' branch='Executive' topic='N/A' buttonName='Doc'/>
</Modal>
</Col>

<Col>
  <h5 id='presidentialFeedHeadingMobile' onClick={() => setTrendingBillsOptionTwoModalIsOpen(true)}>Comisión Nacional de Cultura Física y Deporte</h5>

  <div id='conade'></div>
                          
  {CreateFeedAPI('&skip=0&schema=mexico&table=conade&order_by=created_at', 'conade', 
                            'Comisión Nacional de Cultura Física y Deporte', 'Executive', 
                            'Health', 'Doc', GenerateFeed)}



<Modal id='mobileFullBillModal' show={trendingBillsOptionTwo} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsOptionTwoModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Comisión Nacional de Cultura Física y Deporte</h3></Modal.Header>
<GenerateFeed url='&skip=0&schema=mexico&table=conade&order_by=created_at'
                                name='Comisión Nacional de Cultura Física y Deporte' branch='Executive' topic='Health' buttonName='Doc' />
</Modal>
</Col>



<Col>
  <h5 id='presidentialFeedHeadingMobile' onClick={() => setTrendingBillsOptionThreeModalIsOpen(true)}>Consejo Nacional para el Desarrollo y la Inclusión de las Personas con Discapacidad</h5>

  <div id='conadis'></div>
                          
  {CreateFeedAPI('&skip=0&schema=mexico&table=conadis&order_by=created_at', 'conadis', 
                            'Consejo Nacional para el Desarrollo y la Inclusión de las Personas con Discapacidad', 'Executive', 
                            'Health', 'Doc', GenerateFeed)}


<Modal id='mobileFullBillModal' show={trendingBillsOptionThree} 
size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsOptionThreeModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Consejo Nacional para el Desarrollo y la Inclusión de las Personas con Discapacidad</h3></Modal.Header>
<GenerateFeed url='&skip=0&schema=mexico&table=conadis&order_by=created_at'
                                name='Consejo Nacional para el Desarrollo y la Inclusión de las Personas con Discapacidad' branch='Executive' topic='Health' buttonName='Doc' />
</Modal>
</Col>

<Col>
  <h5 id='presidentialFeedHeadingMobile' onClick={() => setTrendingBillsOptionFiveModalIsOpen(true)}>Comisión Nacional Forestal</h5>

  <div id='conafor'></div>
                          
  {CreateFeedAPI('&skip=0&schema=mexico&table=conafor&order_by=created_at', 'conafor', 
                            'Comisión Nacional Forestal', 'Executive', 
                            'Environment', 'Doc', GenerateFeed)}

<Modal id='mobileFullBillModal' show={trendingBillsOptionFive} 
size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsOptionFiveModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Comisión Nacional Forestal</h3></Modal.Header>
<GenerateFeed url='&skip=0&schema=mexico&table=conafor&order_by=created_at'
                                name='Comisión Nacional Forestal' branch='Executive' topic='Environment' buttonName='Doc' />
</Modal>
</Col>

<Col>
  <h5 id='presidentialFeedHeadingMobile' onClick={() => setTrendingBillsOptionFourModalIsOpen(true)}>Comisión Nacional del Agua</h5>

  <div id='conagua'></div>
                          
                          {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
                          {CreateFeedAPI('&skip=0&schema=mexico&table=conagua&order_by=created_at', 'conagua', 
                            'Comisión Nacional del Agua', 'Executive', 
                            'Environment', 'Doc', GenerateFeed)}

<Modal id='mobileFullBillModal' show={trendingBillsOptionFour} 
size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsOptionFourModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Comisión Nacional del Agua</h3></Modal.Header>
<GenerateFeed url='&skip=0&schema=mexico&table=conagua&order_by=created_at'
                                name='Comisión Nacional del Agua' branch='Executive' topic='Environment' buttonName='Doc' />
</Modal>
</Col>

<Col>
  <h5 id='presidentialFeedHeadingMobile' onClick={() => setTrendingBillsOptionSixModalIsOpen(true)}>Servicio Postal Mexicano</h5>

  <div id='correosdemexico'></div>
                          
  {CreateFeedAPI('&skip=0&schema=mexico&table=correosdemexico&order_by=created_at', 'correosdemexico', 
                            'Servicio Postal Mexicano', 'Executive', 
                            'N/A', 'Doc', GenerateFeed)}

<Modal id='mobileFullBillModal' show={trendingBillsOptionFour} 
size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsOptionFourModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Servicio Postal Mexicano</h3></Modal.Header>
<GenerateFeed url='&skip=0&schema=mexico&table=correosdemexico&order_by=created_at'
                                name='Servicio Postal Mexicano' branch='Executive' topic='N/A' buttonName='Doc' />
</Modal>
</Col>

<Col>
  <h5 id='presidentialFeedHeadingMobile' onClick={() => setTrendingBillsOptionSevenModalIsOpen(true)}>Instituto Nacional de Migración</h5>

  <div id='inm'></div>
                          
  {CreateFeedAPI('&skip=0&schema=mexico&table=inm&order_by=created_at', 'inm', 
                             'Instituto Nacional de Migración', 'Executive', 
                             'Foreign Affairs', 'Doc', GenerateFeed)}
 
 <Modal id='mobileFullBillModal' show={trendingBillsOptionSeven} 
 size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsOptionSevenModalIsOpen(false)}>
 <Modal.Header id='mobileBillModalHeader' closeButton><h3>Instituto Nacional de Migración</h3></Modal.Header>
 <GenerateFeed url='&skip=0&schema=mexico&table=inm&order_by=created_at'
                                 name='Instituto Nacional de Migración' branch='Executive' topic='Foreign Affairs' buttonName='Doc' />
 </Modal>
</Col>

<Col>
  <h5 id='presidentialFeedHeadingMobile' onClick={() => setTrendingBillsOptionEightModalIsOpen(true)}>Secretaría de Salud</h5>

  <div id='salud'></div>
  {CreateFeedAPI('&skip=0&schema=mexico&table=salud&order_by=created_at', 'salud', 
                            'Secretaría de Salud', 'Executive', 
                            'Health', 'Doc', GenerateFeed)}

<Modal id='mobileFullBillModal' show={trendingBillsOptionEight} 
size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsOptionEightModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Secretaría de Salud</h3></Modal.Header>
<GenerateFeed url='&skip=0&schema=mexico&table=salud&order_by=created_at'
                                name='Secretaría de Salud' branch='Executive' topic='Health' buttonName='Doc' />
</Modal>
</Col>


<Col>
  <h5 id='presidentialFeedHeadingMobile' onClick={() => setTrendingBillsOptionNineModalIsOpen(true)}>Secretaría de Comunicaciones y Transportes</h5>

  <div id='sct'></div>
                          
                          {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
                          {CreateFeedAPI('&skip=0&schema=mexico&table=sct&order_by=created_at', 'sct', 
                            'Secretaría de Comunicaciones y Transportes', 'Executive', 
                            'Environment', 'Doc', GenerateFeed)}

<Modal id='mobileFullBillModal' show={trendingBillsOptionNine} 
size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsOptionNineModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Secretaría de Comunicaciones y Transportes</h3></Modal.Header>
<GenerateFeed url='&skip=0&schema=mexico&table=sct&order_by=created_at'
                                name='Secretaría de Comunicaciones y Transportes' branch='Executive' topic='Environment' buttonName='Doc' />
</Modal>
</Col>

<Col>
  <h5 id='presidentialFeedHeadingMobile' onClick={() => setTrendingBillsOptionTenModalIsOpen(true)}>Secretaría de Economía</h5>

  <div id='se'></div>
                          
  {CreateFeedAPI('&skip=0&schema=mexico&table=se&order_by=created_at', 'se', 
                            'Secretaría de Economía', 'Executive', 
                            'Economy', 'Doc', GenerateFeed)}

<Modal id='mobileFullBillModal' show={trendingBillsOptionTen} 
size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsOptionTenModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Secretaría de Economía</h3></Modal.Header>
<GenerateFeed url='&skip=0&schema=mexico&table=se&order_by=created_at'
                                name='Secretaría de Economía' branch='Executive' topic='Economy' buttonName='Doc'/>
</Modal>
</Col>

<Col>
  <h5 id='presidentialFeedHeadingMobile' onClick={() => setTrendingBillsOptionElevenModalIsOpen(true)}>Secretaría de Turismo</h5>

  <div id='sectur'></div>
                          
                          {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
                          {CreateFeedAPI('&skip=0&schema=mexico&table=sectur&order_by=created_at', 'sectur', 
                            'Secretaría de Turismo', 'Executive', 
                            'Economy', 'Doc', GenerateFeed)}

<Modal id='mobileFullBillModal' show={trendingBillsOptionEleven} 
size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsOptionElevenModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Secretaría de Turismo</h3></Modal.Header>
<GenerateFeed url='&skip=0&schema=mexico&table=sectur&order_by=created_at'
                                name='Secretaría de Turismo' branch='Executive' topic='Economy' buttonName='Doc'/>
</Modal>
</Col>

<Col>
  <h5 id='presidentialFeedHeadingMobile' onClick={() => setTrendingBillsOptionTweleveModalIsOpen(true)}>Secretaría de Desarrollo Agrario, Territorial y Urbano</h5>

  <div id='sedatu'></div>
                          
  {CreateFeedAPI('&skip=0&schema=mexico&table=sedatu&order_by=created_at', 'sedatu', 
                            'Secretaría de Desarrollo Agrario, Territorial y Urbano', 'Executive', 
                            'Environment', 'Doc', GenerateFeed)}

<Modal id='mobileFullBillModal' show={trendingBillsOptionTweleve} 
size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsOptionTweleveModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Secretaría de Desarrollo Agrario, Territorial y Urbano</h3></Modal.Header>
<GenerateFeed url='&skip=0&schema=mexico&table=sedatu&order_by=created_at'
                                name='Secretaría de Desarrollo Agrario, Territorial y Urbano' branch='Executive' topic='Environment' buttonName='Doc' />
</Modal>
</Col>

<Col>
  <h5 id='presidentialFeedHeadingMobile' onClick={() => setTrendingBillsOptionThirteenModalIsOpen(true)}>Secretaría de la Defensa Nacional</h5>

  <div id='sedena'></div>
                          
  {CreateFeedAPI('&skip=0&schema=mexico&table=sedena&order_by=created_at', 'sedena', 
                             'Secretaría de la Defensa Nacional', 'Executive', 
                             'Foreign Affairs', 'Doc', GenerateFeed)}
 
 <Modal id='mobileFullBillModal' show={trendingBillsOptionThirteen} 
 size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsOptionThirteenModalIsOpen(false)}>
 <Modal.Header id='mobileBillModalHeader' closeButton><h3>Secretaría de la Defensa Nacional</h3></Modal.Header>
 <GenerateFeed url='&skip=0&schema=mexico&table=sedena&order_by=created_at'
                                 name='Secretaría de la Defensa Nacional' branch='Executive' topic='Foreign Affairs' buttonName='Doc' />
 </Modal>
</Col>

<Col>
  <h5 id='presidentialFeedHeadingMobile' onClick={() => setDepartmentOfAgricultureModalIsOpen(true)}>Secretaría de Gobernación</h5>

  <div id='segob'></div>
                          
  {CreateFeedAPI('&skip=0&schema=mexico&table=segob&order_by=created_at', 'segob', 
                             'Secretaría de Gobernación', 'Executive', 
                             'N/A', 'Doc', GenerateFeed)}
 
 <Modal id='mobileFullBillModal' show={departmentOfAgricultureModalIsOpen} 
 size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfAgricultureModalIsOpen(false)}>
 <Modal.Header id='mobileBillModalHeader' closeButton><h3>Secretaría de Gobernación</h3></Modal.Header>
 <GenerateFeed url='&skip=0&schema=mexico&table=segob&order_by=created_at'
                                 name='Secretaría de Gobernación' branch='Executive' topic='N/A' buttonName='Doc' />
 </Modal>
</Col>


<Col>
  <h5 id='presidentialFeedHeadingMobile' onClick={() => setDepartmentOfCommerceModalIsOpen(true)}>Secretaría de Marina</h5>

  <div id='semar'></div>
                          
  {CreateFeedAPI('&skip=0&schema=mexico&table=semar&order_by=created_at', 'semar', 
                             'Secretaría de Marina', 'Executive', 
                             'N/A', 'Doc', GenerateFeed)}
 
 <Modal id='mobileFullBillModal' show={departmentOfCommerceModalIsOpen} 
 size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfCommerceModalIsOpen(false)}>
 <Modal.Header id='mobileBillModalHeader' closeButton><h3>Secretaría de Marina</h3></Modal.Header>
 <GenerateFeed url='&skip=0&schema=mexico&table=semar&order_by=created_at'
                                 name='Secretaría de Marina' branch='Executive' topic='N/A' buttonName='Doc' />
 </Modal>
</Col>

<Col> 
  <h5 id='presidentialFeedHeadingMobile' onClick={() => setDepartmentOfDefenseModalIsOpen(true)}>Secretaría de Medio Ambiente y Recursos Naturales</h5>

  <div id='semarNat'></div>
                          
  {CreateFeedAPI('&skip=0&schema=mexico&table=semarnat&order_by=created_at', 'semarNat', 
                            'Secretaría de Medio Ambiente y Recursos Naturales', 'Executive', 
                            'Environment', 'Doc', GenerateFeed)}

<Modal id='mobileFullBillModal' show={departmentOfDefenseModalIsOpen} 
size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfDefenseModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Secretaría de Medio Ambiente y Recursos Naturales</h3></Modal.Header>
<GenerateFeed url='&skip=0&schema=mexico&table=semarnat&order_by=created_at'
                                name='Secretaría de Medio Ambiente y Recursos Naturales' branch='Executive' topic='Environment' buttonName='Doc' />
</Modal>
</Col>

<Col> 
  <h5 id='presidentialFeedHeadingMobile' onClick={() => setDepartmentOfEducationModalIsOpen(true)}>Secretaría de la Función Pública</h5>

  <div id='sfp'></div>
                          
                             
  {CreateFeedAPI('&skip=0&schema=mexico&table=sfp&order_by=created_at', 'sfp', 
                            'Secretaría de la Función Pública', 'Executive', 
                            'N/A', 'Doc', GenerateFeed)}

<Modal id='mobileFullBillModal' show={departmentOfDefenseModalIsOpen} 
size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfDefenseModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Secretaría de la Función Pública</h3></Modal.Header>
<GenerateFeed url='&skip=0&schema=mexico&table=sfp&order_by=created_at'
                                name='Secretaría de la Función Pública' branch='Executive' topic='N/A' buttonName='Doc' />
</Modal>
</Col>

<Col> 
  <h5 id='presidentialFeedHeadingMobile' onClick={() => setDepartmentOfEnergyModalIsOpen(true)}>Secretaría de Hacienda y Crédito Público</h5>

  <div id='shcp'></div>
                          
  {CreateFeedAPI('&skip=0&schema=mexico&table=shcp&order_by=created_at', 'shcp', 
                            'Secretaría de Hacienda y Crédito Público', 'Executive', 
                            'Economy', 'Doc', GenerateFeed)}

<Modal id='mobileFullBillModal' show={departmentOfEnergyModalIsOpen} 
size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfEnergyModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Secretaría de Hacienda y Crédito Público</h3></Modal.Header>
<GenerateFeed url='&skip=0&schema=mexico&table=shcp&order_by=created_at'
                                name='Secretaría de Hacienda y Crédito Público' branch='Executive' topic='Economy' buttonName='Doc' />
</Modal>
</Col>

<Col> 
  <h5 id='presidentialFeedHeadingMobile' onClick={() => setDepartmentOfHealthAndHumanServicesModalIsOpen(true)}>Secretaría de Relaciones Exteriores</h5>

  <div id='sre'></div>
                          
  {CreateFeedAPI('&skip=0&schema=mexico&table=sre&order_by=created_at', 'sre', 
                             'Secretaría de Relaciones Exteriores', 'Executive', 
                             'Foreign Affairs', 'Doc', GenerateFeed)}
 
 <Modal id='mobileFullBillModal' show={departmentOfHealthAndHumanServicesModalIsOpen} 
 size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfHealthAndHumanServicesModalIsOpen(false)}>
 <Modal.Header id='mobileBillModalHeader' closeButton><h3>Secretaría de Relaciones Exteriores</h3></Modal.Header>
 <GenerateFeed url='&skip=0&schema=mexico&table=sre&order_by=created_at'
                                 name='Secretaría de Relaciones Exteriores' branch='Executive' topic='Foreign Affairs' buttonName='Doc' />
 </Modal>
</Col>

<Col> 
  <h5 id='presidentialFeedHeadingMobile' onClick={() => setDepartmentOfHomelandSecurityModalIsOpen(true)}>Secretaría de Seguridad y Protección Ciudadana</h5>

  <div id='sspc'></div>
                          
  {CreateFeedAPI('&skip=0&schema=mexico&table=sspc&order_by=created_at', 'sspc', 
                             'Secretaría de Seguridad y Protección Ciudadana', 'Executive', 
                             'N/A', 'Doc', GenerateFeed)}
 
 <Modal id='mobileFullBillModal' show={departmentOfHealthAndHumanServicesModalIsOpen} 
 size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfHealthAndHumanServicesModalIsOpen(false)}>
 <Modal.Header id='mobileBillModalHeader' closeButton><h3>Secretaría de Seguridad y Protección Ciudadana</h3></Modal.Header>
 <GenerateFeed url='&skip=0&schema=mexico&table=sspc&order_by=created_at'
                                 name='Secretaría de Seguridad y Protección Ciudadana' branch='Executive' topic='N/A' buttonName='Doc' />
 </Modal>
</Col>

<Col> 
  <h5 id='presidentialFeedHeadingMobile' onClick={() => setDepartmentOfHousingAndUrbanDevelopmentModalIsOpen(true)}>Secretaría del Trabajo y Previsión Social</h5>

  <div id='stps'></div>
  {CreateFeedAPI('&skip=0&schema=mexico&table=stps&order_by=created_at', 'stps', 
                            'Secretaría del Trabajo y Previsión Social', 'Executive', 
                            'Health', 'Doc', GenerateFeed)}
<Modal id='mobileFullBillModal' show={departmentOfHousingAndUrbanDevelopmentModalIsOpen} 
size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfHousingAndUrbanDevelopmentModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Secretaría del Trabajo y Previsión Social</h3></Modal.Header>
<GenerateFeed url='&skip=0&schema=mexico&table=stps&order_by=created_at'
                                name='Secretaría del Trabajo y Previsión Social' branch='Executive' topic='Health' buttonName='Doc' />
</Modal>
</Col>

<Col>

<br></br><br></br><br></br><br></br>
<h5 id='headingSelectHomeCountry'>Support the Gov Glance Foundation  <Button id='donateButton' onClick={(e) => {
                        e.preventDefault();
                        window.open('https://donate.stripe.com/28o3fXbdvbQO2RO288', "_blank")
                      }}><HeartFill id='donateIcon'/>    Donate</Button></h5>

</Col>

<br></br><br></br><br></br><br></br><br></br><br></br>

<Navbar id='navbarMobileBottom' fixed="bottom">  



       
<div id='changeBranchDropdownMobile'>

                  <MobileOffCanvasMexico/>
  
            </div>

</Navbar>
 
             
 
 
 
 
 
 

</div>



</>
)
}}
export default MexicoExecutiveBranchMobile;


