import App from "./App";
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment, lazy, Suspense } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Image, ModalFooter, Spinner } from 'react-bootstrap';
import { ArrowsAngleExpand, InfoCircle, FileText, FolderPlus, Files, Folder, HeartFill, PauseCircle, ChevronRight, Pen, ChatLeftQuote, PlayCircle } from 'react-bootstrap-icons'
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserView, MobileView } from 'react-device-detect';
import { render } from '@testing-library/react';
import GAOReports from "./GAOReports";
import CongressionalBudgetOffice from "./CongressionalBudgetOffice";
import LegislativeBranchMobile from "./LegislativeBranchMobile";
import { congressSearch, CreateFeedItem, CreateFeedItemGAOReports, CreateFeedAPI
} from './Functions'
import DesktopNavbar from "./DesktopNavbar";
import GenerateFeed from "./GenerateFeed";
import GenerateFeedPageCreator from "./GenerateFeedPageCreator";
import RepresenativesDisplay from "./RepresenativesDisplay";
import SenateDisplay from "./SenateDisplay";
import HouseOfRepsDisplay from "./HouseOfRepsDisplay";
import FederalElectionCommissionPageMobile from "./FederalElectionCommissionPageMobile";
import Election from "./Election";
import ElectionAssistancePageMobile from "./ElectionAssistancePageMobile";

var carouselInterval = 30000;
const HeavyDependencyComponent = lazy(() => import('./GenerateFeed'));
const CustomModal = ({ isOpen, onClose, title, content }) => (
  <Modal id='fullBillModal' show={isOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={onClose}>
    <Modal.Header id='billModalHeader' closeButton><h3>{title}</h3></Modal.Header>
    {content}
  </Modal>
);

export const ElectionAssistancePage = () => {
  const [modalInfo, setModalInfo] = useState({
    isOpen: false,
    title: '',
    content: null
  });

  const openModal = (title, content) => {
    setModalInfo({
      isOpen: true,
      title,
      content
    });
  };

  const closeModal = () => {
    setModalInfo({
      isOpen: false,
      title: '',
      content: null
    });
  };
  render()

  {
    const [aboutModalIsOpen, setAboutModalIsOpen] = useState(false)
    const [showBioModal, setBioModal] = useState(false);
    return (
      <>
        <BrowserView>
          <div id='homePage'>


            <div id='homebg'>
              <DesktopNavbar />


     

<div style={{ position: 'relative' }}>

<div id='profileImage' style={{ width: '150px', height: '150px', borderRadius: '100%', overflow: 'hidden',  marginLeft: '40px' }}>
<Image id='profileImage-image' src='/eacLogo.png' />
</div>


<div style={{ position: 'absolute', top: '0', left: '250px', display: 'flex', flexDirection: 'column' }}>
<div style={{ color: 'white', fontSize: '24px', fontWeight: 'bold' }}>

</div>
<div  style={{ color: 'white', fontSize: '20px' }}> 
<span> <h3  >Election Assistance Commission</h3></span>
<br></br>
<div style={{ color: 'white', fontSize: '20px' }}>
<span> 
The U.S. Election Assistance Commission (EAC) is an independent, bipartisan commission whose mission is to help election officials improve the administration of elections and help Americans participate in the voting process.   </span>
 
<br></br>
<div style={{ color: 'white', fontSize: '18px' }}>

  <span><Link style={{color: '#21cff5'}} onClick={(e) => {
     e.preventDefault();
     window.open("https://www.eac.gov/", "_blank");
   }}>
     https://www.eac.gov/
     </Link></span> 
<br></br>
<Button id='whDocsViewMoreButton' onClick={() =>  setBioModal(true)}>  <Files size={20}></Files> Read More</Button> 
</div>
</div>

</div>
</div>


</div>
<br></br>
<br></br><br></br>
<Modal id='fullBillModal'  size="xl" aria-labelledby="contained-modal-title-vcenter" show={showBioModal} onHide={() => setBioModal(false)}>
         <Modal.Header id='billModalHeader' closeButton><h3> Election Assistance Commission</h3></Modal.Header>
       
         <div id='fullBillFeed' >
      
         <p>The U.S. Election Assistance Commission (EAC) is an independent, bipartisan commission whose mission is to help election officials improve the administration of elections and help Americans participate in the voting process.</p>

         <p>The U.S. Election Assistance Commission (EAC) was established by the Help America Vote Act of 2002 (HAVA). The EAC is an independent, bipartisan commission charged with developing guidance to meet HAVA requirements, adopting voluntary voting system guidelines, and serving as a national clearinghouse of information on election administration. The EAC also accredits testing laboratories and certifies voting systems, as well as audits the use of HAVA funds.</p>

<p>Other responsibilities include maintaining the national mail voter registration form developed in accordance with the National Voter Registration Act of 1993.</p>

<p>HAVA established the Standards Board and the Board of Advisors to advise the EAC. The law also established the Technical Guidelines Development Committee to assist the EAC in the development of voluntary voting system guidelines. In 2021, the EAC also established the Local Leadership Council composed of two election official leaders from each state.</p>

<p>The four EAC commissioners are appointed by the president and confirmed by the U.S. Senate. The EAC is required to submit an annual report to Congress as well as testify periodically about HAVA progress and related issues. The commission also holds public meetings and hearings to inform the public about its progress and activities.</p>

<br></br>
<Link style={{color: '#21cff5'}} onClick={(e) => {
     e.preventDefault();
     window.open("https://www.eac.gov/about", "_blank");
   }}>
     https://www.eac.gov/about
     </Link>
 </div>

       </Modal>

       <CustomModal isOpen={modalInfo.isOpen} onClose={closeModal} title={modalInfo.title} content={modalInfo.content} />

              <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferOne'>
                    <Row>

                      <Col id='legislativeColRight' >
                        <div id='columnRightPadding'>
                          <h4 id='billFeedHeading' onClick={() =>
                            openModal('Election Assistance Commission News',
                              <GenerateFeed
                                url='&skip=0&schema=united_states_of_america&table=election_assistance_commission&order_by=created_at'
                                name='Election Assistance Commission News' branch='N/A' topic='Election' buttonName='Doc' />)}><b>News</b></h4>


                          <div id='bills'></div>
                          {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=election_assistance_commission&order_by=created_at', 'bills',
                            'Election Assistance Commission News', 'N/A',
                            'Elections', 'Doc', GenerateFeed)}

                        </div>
                      </Col>



               
                      <Col id='legislativeColRight' >
                        <div id='columnRightPadding'>

                       
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </Container>
              </div>

         




              <footer id='homeFooter'>
                <Navbar>
                  <Row id='footerNav'>
                    <Nav variant='pills'>

                      <Nav.Link id='footerContent'>Gov Glance Foundation Inc.
                      </Nav.Link>
                      <Nav.Item>
                        <Nav.Link id='footerContent' href='mailto:contact@govglance.org'>Contact</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link id='footerContent' onClick={(e) => {
                          e.preventDefault();
                          window.open('https://donate.stripe.com/28o3fXbdvbQO2RO288', "_blank")
                        }}> <HeartFill id='donateIcon' />    Donate</Nav.Link>
                      </Nav.Item>
                      <Nav.Link onClick={(e) => {
                   e.preventDefault();
                   window.open('/about', "_self")}} id='footerContent'>Privacy</Nav.Link>


                    </Nav>
                  </Row>
                </Navbar>
              </footer>





            </div>
          </div>
        </BrowserView>

        <MobileView>
          <ElectionAssistancePageMobile/>

        </MobileView>
      </>
    )
  }
}










export default ElectionAssistancePage;