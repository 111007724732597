
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link, Switch, NavLink,  } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment,  } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Tooltip, OverlayTrigger, Image, ModalFooter } from 'react-bootstrap';
import { ArrowsAngleExpand, ArrowsFullscreen, InfoCircle, FileText, FileMedicalFill, Coin, Tree, Globe2,  ShieldShaded, CircleFill, Pen, People, ChatSquareQuote, ChatLeftQuote, BlockquoteRight, Collection, Grid, ThreeDots, Stack, List, Files, FolderPlus, FolderCheck, Folder, CaretRight, ChevronRight, ChevronCompactRight, CaretRightFill, ArrowRightShort,  ArrowRightCircle, PlayCircle, PauseCircle, GeoAlt, HeartFill, QuestionCircleFill, MicFill, ReceiptCutoff } from 'react-bootstrap-icons'
import { BrowserView, MobileView } from 'react-device-detect';
import '../../App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import { render } from '@testing-library/react';
import
 {
CreateFeedItemCanadaProvence} from '../../Functions'
import NorthwestTerritoriesNews from './NorthwestTerritoriesNews';
import NorthwestTerritoriesMobile from './NorthwestTerritoriesMobile';
import ProvinceDesktopNavbar from '../ProvinceDesktopNavbar';
var collectionCounter = 0
var carouselInterval = 30000;
var mobileCarouselInterval = 60000;

export const NorthwestTerritories = () =>   {
  render()
  
 
  {
  const [whiteHouseFeedModalIsOpen, setWhiteHouseFeedModalIsOpen] = useState(false)
  const [aboutModalIsOpen, setAboutModalIsOpen] = useState(false)
  return (
    <>
      <div className="App">
      <BrowserView>
      <div id='homePage'>
        <div id='homebg'>
        <ProvinceDesktopNavbar province='Northwest Territories' provinceFlag='/northwestTerritoriesFlag.png'/>
         <Navbar id='navbarStates' >
        <Nav variant='pills' className="mr-auto">
            <Nav.Link id ='navbarStatesDesktop' >
                <Dropdown drop='down-centered' > 
<Dropdown.Toggle id='foundingDocumentsDropDownCountry'> <h3 id='branchesHeadingUS'><b id='branchHeadingBold'> 🇨🇦 Canada</b></h3></Dropdown.Toggle>
                        
<Dropdown.Menu id='docsDropdown' drop='down-centered' align={"end"}>
          <Dropdown.Item id='docsDropdownItem' onClick={(e) => {
                        e.preventDefault();
                        window.open('/canada', "_self")}}>🇨🇦 Canada</Dropdown.Item>

<Dropdown.Item id='docsDropdownItem' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us', "_self")}}>🇺🇸 United States</Dropdown.Item>
          </Dropdown.Menu>

          
                        </Dropdown>
                        </Nav.Link> 


                
                        <div id='canadaProvinceDesktop'>  <Image id='provinceFlag'  src='/northwestterritoriesFlag.png'></Image></div>
          
          <div id='canadaProvinceDesktopHeading'><h3 id='branchesHeading'><b id='branchHeadingBold'>Northwest Territories</b></h3> </div>
   

</Nav>
         </Navbar>
            <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferTwo'>
                   
                    <Row>

                      <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>

                          <h4 id='presidentialFeedHeadingMobile' onClick={() => setWhiteHouseFeedModalIsOpen(true)}><b>Updates</b></h4>
                       

                          <div class='whMobile' id='delawareBills'> </div>

{CreateFeedItemCanadaProvence
('https://api.io.canada.ca/io-server/gc/news/en/v2?location=nt61&sort=publishedDate&orderBy=desc&publishedDate%3E=2021-10-25&pick=100&format=atom&atomtitle=Northwest%20Territories',
 'delawareBills', 'Northwest Territories Updates', 'N/A', 'N/A', NorthwestTerritoriesNews )}

<Modal id='mobileWhiteHouseFeedModal' show={whiteHouseFeedModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setWhiteHouseFeedModalIsOpen(false)}>
<Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3>Northwest Territories Updates</h3></Modal.Header>
<NorthwestTerritoriesNews/>
</Modal>
                        </div>
                      </Col>

                      <Col id='legislativeColRight'>
                        <div id='columnRightPadding'>
           
                          <div id='whdocs'></div>
                          
                        
                        </div>
                      </Col>


                    </Row>


                  </Container>
                </Container>
             
              </div>
           
           
        
   
         


          
            
              
             
              
            
          
            
            
          <Modal id='aboutModal' show={aboutModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setAboutModalIsOpen(false)}>
            <div id='removedAlert'></div>
            <Modal.Header id='aboutModalHeader' closeButton><h3>About</h3></Modal.Header>
  <ModalBody id='aboutInfo'><p id='aboutParagraph'>Gov Glance was created to drive civic engagement and support a 
              healthier democracy by helping citizens get official Government news in an easy-to-navigate centralized location. 
              There will never be any opinion or political analysis(That's your job), only information from official Government 
              sources. Gov Glance is structured after the U.S. government that is broken into three branches Legislative, 
              Executive, and Judicial. To learn more about the structure and functions of The U.S. Government go to <a href='https://www.usa.gov/branches-of-government'>https://www.usa.gov/branches-of-government</a>.
              </p>

              <p id='aboutParagraph'>
              Gov Glance Foundation Inc. is a non-profit that was created to operate govglance.org and continue to create educational tools that help people learn about the workings of society
              </p>
            <br></br>

            <h3>Privacy</h3>

            <br></br>

              <p id='aboutParagraph'>Gov Glance does not collect any personal user information. 
              Features like collection save news items directly to your browser's local storage for your convenience
              and is not shared with Gov Glance servers. We do not collect, store, or send personal information to Google LLC, 
              but we do use some Google services to deliver our app to you. For any questions into Google’s privacy practices 
              here is a link to their privacy policy: <a href='https://policies.google.com/privacy?hl=en-US#intro'>https://policies.google.com/privacy?hl=en-US#intro</a>

              <br></br><br></br>

              For site analytics we use plausible.io an open source privacy friendly analytics provider. <a href="https://plausible.io/about" target="_blank">https://plausible.io/about</a>
            
            </p>
            <br></br>


            <h3>Contact</h3>

            <p id='aboutParagraph'>
                      Contact us via email: <a href='mailto:contact@govglance.org'>contact@govglance.org</a>

            </p>

            <br></br>


            
            </ModalBody>
          </Modal>
 
     <footer id='homeFooter'>
      <Navbar>
        <Row id='footerNav'>
      <Nav variant='pills'>
        
     <Nav.Link id='footerContent'>Gov Glance Foundation Inc.
</Nav.Link>
<Nav.Item>
<Nav.Link id='footerContent' href='mailto:contact@govglance.org'>Contact</Nav.Link>
</Nav.Item>
<Nav.Item>
<Nav.Link id='footerContent' onClick={(e) => {
                   e.preventDefault();
                   window.open('https://donate.stripe.com/28o3fXbdvbQO2RO288', "_blank")
                 }}> <HeartFill id='donateIcon'/>    Donate</Nav.Link>
</Nav.Item>
<Nav.Link onClick={(e) => {
                   e.preventDefault();
                   window.open('/about', "_self")}} id='footerContent'>Privacy</Nav.Link>
    
     
 </Nav>
 </Row>
 </Navbar>
      </footer>

      


    
          </div>
  

    </div>
      </BrowserView>
     

      <MobileView> 
        
        
                      <NorthwestTerritoriesMobile/>

     
      </MobileView>
        </div>
      


       
    </>

     
    
  );







}
}


export default NorthwestTerritories;
