import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link, Switch,  } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment,  } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Tooltip, OverlayTrigger, Image, ModalFooter } from 'react-bootstrap';
import { ArrowsAngleExpand, ArrowsFullscreen, InfoCircle, FileText, FileMedicalFill, Coin, Tree, Globe2,  ShieldShaded, CircleFill, Pen, People, ChatSquareQuote, ChatLeftQuote, BlockquoteRight, Collection, Grid, ThreeDots, Stack, List, Files, FolderPlus, FolderCheck, Folder, CaretRight, ChevronRight, ChevronCompactRight, CaretRightFill, ArrowRightShort,  ArrowRightCircle, PlayCircle, PauseCircle } from 'react-bootstrap-icons'
import { BrowserView, MobileView } from 'react-device-detect';
import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import Axios from 'axios'
import { render } from '@testing-library/react';

import CollectionPage from '../CollectionPage';

import BillsEnrolled from '../BillsEnrolled';


import HomeMobile from '../HomeMobile';
import { CreateFeedAPI, CreateFeedItemStateBills, CreateFeedItemStateCourt, CreateFeedItemStateGovernor, createWashingtonBills, Speak } from '../Functions';
import WashingtonBills from './WashingtonBills';
import WashingtonHomeMobile from './WashingtonHomeMobile';
import StatesNavbar from '../StatesNavbar';
import StatePicker from '../StatePicker';
import WashingtonCourts from './WashingtonCourts';
import WashingtonGovernor from './WashingtonGovernor';
import StateDesktopNavbar from './StateDesktopNavbar';
import GenerateFeed from '../GenerateFeed';








var collectionCounter = 0
var carouselInterval = 30000;
var mobileCarouselInterval = 60000;

//https://evening-plains-21303.herokuapp.com/







  








export const WashingtonHome = () =>   {
  render()
  
 
  {

  
  const [show, setShow] = useState(true);
  
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [fullscreen, setFullScreen] = useState(true);

  
  const [fullBillModalIsOpen, setFullBillModalIsOpen] = useState(false)

  const [reportModalIsOpen, setReportModalIsOpen] = useState(false)


  const [whiteHouseFeedModalIsOpen, setWhiteHouseFeedModalIsOpen] = useState(false)
  const [whiteHouseDocsModalIsOpen, setWhiteHouseDocsModalIsOpen] = useState(false)


  const [courtNewsModalIsOpen, setCourtNewsModalIsOpen] = useState(false)
  const [courtModalIsOpen, setCourtModalIsOpen] = useState(false)
  const [courtOpinionsModalIsOpen, setCourtOpinionsModalIsOpen] = useState(false)
  const [courtAppealsModalIsOpen, setCourtAppealsModalIsOpen] = useState(false)

  const [newLawsModalIsOpen, setNewLawsModalIsOpen] = useState(false)

  const [billsEnrolledModalIsOpen, setBillsEnrolledModalIsOpen] = useState(false)
  const [nsfModalIsOpen, setNSFModalIsOpen] = useState(false)
  const [legislativePageBillsEnrolledModalIsOpen, setLegislativePageBillsEnrolledModalIsOpen] = useState(false)
  const [secSAndSModalIsOpen, setSECSAndSModalIsOpen] = useState(false)
  const [economicIndicatorsModalIsOpen, setEconomicIndicatorsModalIsOpen] = useState(false)
  const [departmentOfStateModalIsOpen, setDepartmentOfStateModalIsOpen] = useState(false)
  const [departmentOfTreasuryModalIsOpen, setDepartmentOfTreasuryModalIsOpen] = useState(false)
  const [departmentOfDefenseModalIsOpen, setDepartmentOfDefenseModalIsOpen] = useState(false)
  const [departmentOfJusticeModalIsOpen, setDepartmentOfJusticeModalIsOpen] = useState(false)
  const [departmentOfTheInteriorModalIsOpen, setDepartmentOfTheInteriorModalIsOpen] = useState(false)
  const [departmentOfAgricultureModalIsOpen, setDepartmentOfAgricultureModalIsOpen] = useState(false)
  const [departmentOfCommerceModalIsOpen, setDepartmentOfCommerceModalIsOpen] = useState(false)
  const [departmentOfLaborModalIsOpen, setDepartmentOfLaborModalIsOpen] = useState(false)
  const [departmentOfHealthAndHumanServicesModalIsOpen, setDepartmentOfHealthAndHumanServicesModalIsOpen] = useState(false)
  const [departmentOfHousingAndUrbanDevelopmentModalIsOpen, setDepartmentOfHousingAndUrbanDevelopmentModalIsOpen] = useState(false)
  const [departmentOfTransportationModalIsOpen, setDepartmentOfTransportationModalIsOpen] = useState(false)
  const [departmentOfEnergyModalIsOpen, setDepartmentOfEnergyModalIsOpen] = useState(false)
  const [departmentOfEducationModalIsOpen, setDepartmentOfEducationModalIsOpen] = useState(false)
  const [departmentOfVeteransAffairsModalIsOpen, setDepartmentOfVeteransAffairsModalIsOpen] = useState(false)
  const [departmentOfHomelandSecurityModalIsOpen, setDepartmentOfHomelandSecurityModalIsOpen] = useState(false)
  const [epaModalIsOpen, setEPAModalIsOpen] = useState(false)
  const [nasaModalIsOpen, setNASAModalIsOpen] = useState(false)
  const [trendingBillsModalIsOpen, setTrendingBillsModalIsOpen] = useState(false)
  const [trendingBillsOptionOne, setTrendingBillsOptionOneModalIsOpen ] = useState(false)
  const [trendingBillsOptionTwo, setTrendingBillsOptionTwoModalIsOpen ] = useState(false)
  const [trendingBillsOptionThree, setTrendingBillsOptionThreeModalIsOpen ] = useState(false)
  const [trendingBillsOptionFour, setTrendingBillsOptionFourModalIsOpen ] = useState(false)
  const [trendingBillsOptionFive, setTrendingBillsOptionFiveModalIsOpen ] = useState(false)
  const [trendingBillsOptionSix, setTrendingBillsOptionSixModalIsOpen ] = useState(false)
  const [trendingBillsOptionSeven, setTrendingBillsOptionSevenModalIsOpen ] = useState(false)
  const [trendingBillsOptionEight, setTrendingBillsOptionEightModalIsOpen ] = useState(false)
  const [trendingBillsOptionNine, setTrendingBillsOptionNineModalIsOpen ] = useState(false)
  const [trendingBillsOptionTen, setTrendingBillsOptionTenModalIsOpen ] = useState(false)
  const [trendingBillsOptionEleven, setTrendingBillsOptionElevenModalIsOpen ] = useState(false)
  const [trendingBillsOptionTweleve, setTrendingBillsOptionTweleveModalIsOpen ] = useState(false)
  const [trendingBillsOptionThirteen, setTrendingBillsOptionThirteenModalIsOpen ] = useState(false)
  const [trendingBillsOptionFourteen, setTrendingBillsOptionFourteenModalIsOpen ] = useState(false)
  const [trendingBillsOptionFifteen, setTrendingBillsOptionFifteenModalIsOpen ] = useState(false)
  const [goaReportsModalIsOpen, setGAOReportsModalIsOpen] = useState(false)
    const [houseComitteeOnAgricultureModalIsOpen, setHouseComitteeOnAgricultureModalIsOpen] = useState(false)
    const [houseCommitteeOnAppropriationsModalIsOpen, setHouseCommitteeOnAppropriationsModalIsOpen] = useState(false)
    const [houseCommitteeOnArmedServicesModalIsOpen, setHouseCommitteeOnOnArmedServicesModalIsOpen] = useState(false)
    const [houseCommitteeOnTheBudgetModalIsOpen, setHouseCommitteeOnTheBudgetModalIsOpen] = useState(false)
    const [houseCommitteeOnEnergyAndCommerceModalIsOpen, setHouseCommitteeOnEnergyAndCommerceModalIsOpen] = useState(false)
    const [houseCommitteeOnEducationAndLaborModalIsOpen, setHouseCommitteeOnEducationAndLaborModalIsOpen] = useState(false)
    const [houseCommitteeOnEthicsModalIsOpen, setHouseCommitteeOnEthicsModalIsOpen] = useState(false)
    const [houseCommitteeOnFinancialServicesModalIsOpen, setHouseCommitteeOnFinancialServicesModalIsOpen] = useState(false)
    const [houseCommitteeOnForeignAffairsModalIsOpen, setHouseCommitteeOnForeignAffairsModalIsOpen] = useState(false)
    const [houseCommitteeOnHomelandSecurityModalIsOpen, setHouseCommitteeOnHomelandSecurityModalIsOpen] = useState(false)
    const [houseCommitteeOnHouseAdministrationModalIsOpen, setHouseCommitteeOnHouseAdministrationModalIsOpen] = useState(false)
    const [houseCommitteeOnTheJudiciaryModalIsOpen, setHouseCommitteeOnTheJudiciaryModalIsOpen] = useState(false)
    const [houseCommitteeOnNaturalResourcesModalIsOpen, setHouseCommitteeOnNaturalResourcesModalIsOpen] = useState(false)
    const [houseCommitteeOnOversightAndReformModalIsOpen, setHouseCommitteeOnOversightAndReformModalIsOpen] = useState(false)
    const [congressionalBudgetOfficeModalIsOpen, setCongressionalBudgetOfficeModalIsOpen] = useState(false)
    const [presidentialProclamationsModalIsOpen, setPresidentialProclamationsModalIsOpen] = useState(false)
    const [cdcNewsroomModalIsOpen, setCDCNewsroomModalIsOpen] = useState(false)
    const [executiveOrdersModalIsOpen, setExecutiveOrdersModalIsOpen] = useState(false)
    const [unTopStoriesModalIsOpen, setUNTopStoriesModalIsOpen] = useState(false)
    const [houseCommitteeOnIntelligenceModalIsOpen, setHouseCommitteeOnIntelligenceModalIsOpen] = useState(false)
    const [travelNoticesModalIsOpen, setTravelNoticesModalIsOpen] = useState(false)
  
    const [foodRecallsModalIsOpen, setFoodRecallsModalIsOpen] = useState(false)
    const [foodSafetyModalIsOpen, setFoodSafetyModalIsOpen] = useState(false)

    const [outbreaksModalIsOpen, setOutbreaksModalIsOpen] = useState(false)
    const [congressionalHearingsModalIsOpen, setCongressionalHearingsModalIsOpen] = useState(false)

    const [houseFloorModalIsOpen, setHouseFloorModalIsOpen] = useState(false)



  const [collectionsModalIsOpen, setCollectionsModalIsOpen] = useState(false)
  const [aboutModalIsOpen, setAboutModalIsOpen] = useState(false)
  const [secPressModalIsOpen, setSECPressModalIsOpen] = useState(false)


  const [declarationModalIsOpen, setDeclarationModalIsOpen] = useState(false)
  const [constitutionModalIsOpen, setConstitutionModalIsOpen] = useState(false)
  const [billOfRightsnModalIsOpen, setBillOfRightsModalIsOpen] = useState(false)
 

   




  function BranchesTab() {
  

    return (
      <>
   
      <div id='branchesTab'>

        
        <Row id='branchesRow'>

            {/*Legislative Column */}
          <Col id='homeColumn'>

              <Row id="homePageBranchRow">

                
           
                <h3 id='branchHeading' onClick={() => window.open('/us/washington', "_self")}> <Pen></Pen> <b id='branchHeadingBold'>Legislative</b><ChevronRight id='clickChevron'></ChevronRight></h3>
               {/*
                <Button size='md' id='congressButton' variant='primary' onClick={(e) => {
                  e.preventDefault();
                  window.open('https://www.congress.gov/', "_blank")
                }} >congress.gov</Button>


                <Button size='md' id='dailyDigestButton' variant='primary' onClick={(e) => {
                  e.preventDefault();
                  window.open('https://www.congress.gov/congressional-record', "_blank")
                }} >Congressional Record</Button>

              */}

            
            </Row>
 
            {/*Legislative Desktop Carousel*/}
            
          <Carousel id='homeCarousel' controls={false}>

            

                <Carousel.Item id='legislativeCarousel' interval={carouselInterval} >

                  <div id='legislativeCon'>



                    <Col id='legislativeColRight'>
                      <div id='columnLefttPadding'>


                        <h4 id='feedHeadingBillsEnrolled' onClick={() => setWhiteHouseFeedModalIsOpen(true)}><b>Washington Bill Updates</b></h4>
                        <div id='stateBills'></div>

{CreateFeedAPI('&skip=0&schema=united_states_of_america&table=wa_bills&order_by=created_at', 
   'stateBills', 
     'Washington Bill Updates', 'Legislative', 
     'N/A', 'Bill', GenerateFeed)}

   <Modal id='whiteHouseDocsModal' show={whiteHouseFeedModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" 
   centered onHide={() => setWhiteHouseFeedModalIsOpen(false)}>
     <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>Washington Bill Updates</h3></Modal.Header>
     <GenerateFeed url='&skip=0&schema=united_states_of_america&table=wa_bills&order_by=created_at' 
     name='Washington Bill Updates' branch='Legislative' topic='N/A' buttonName='Bill' />
   </Modal>





                      </div>
                    </Col>
















                  </div>

                </Carousel.Item>

          
                </Carousel>
          </Col>

          <Col id='homeColumn'>
<Row id="homePageBranchRow">
<h3 id='branchHeading' ><People></People> <b id='branchHeadingBold'>Executive</b><ChevronRight id='clickChevron'></ChevronRight></h3>


</Row>
<Carousel id='homeCarousel' controls={false} >

  

<Carousel.Item id='legislativeCarousel' interval={carouselInterval} >

<div id='legislativeCon'>



<Col id='legislativeColRight'>
<div id='columnLefttPadding'>

<h4 id='feedHeadingBillsEnrolled' onClick={() => setTrendingBillsModalIsOpen(true)}><b>Governor</b></h4>
              <div id='govFeed'> </div>
  {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=washington&order_by=created_at', 
                     'govFeed', 
                       'Washington Governor', 'Executive', 
                       'N/A', 'Document', GenerateFeed)}

                     <Modal id='whiteHouseDocsModal' show={trendingBillsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" 
                     centered onHide={() => setTrendingBillsModalIsOpen(false)}>
                       <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>Washington Governor</h3></Modal.Header>
                       <GenerateFeed url='&skip=0&schema=united_states_of_america&table=washington&order_by=created_at' 
                       name='Washington Governor' branch='Executive' topic='N/A' buttonName='Document' />
                     </Modal>





</div>
</Col>
















</div>

</Carousel.Item>




</Carousel>



</Col>

<Col id='homeColumn'>


<Row id="homePageBranchRow">
<h3 id='branchHeading'><ShieldShaded></ShieldShaded><b id='branchHeadingBold'>Judicial</b><ChevronRight id='clickChevron'></ChevronRight></h3>


</Row>

<Carousel id='homeCarousel' controls={false}>

  

<Carousel.Item id='legislativeCarousel' interval={carouselInterval} >

<div id='legislativeCon'>



<Col id='legislativeColRight'>
<div id='columnLefttPadding'>

<h4 id='feedHeadingBillsEnrolled' onClick={() => setCourtNewsModalIsOpen(true)}><b>Supreme Court</b></h4>
              <div id='alCourts'> </div>

{CreateFeedAPI('&skip=0&schema=united_states_of_america&table=supreme_wash&order_by=created_at', 
                     'alCourts', 
                       'Washington Supreme Court', 'Judicial', 
                       'N/A', 'Bill', GenerateFeed)}

                     <Modal id='whiteHouseDocsModal' show={courtModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" 
                     centered onHide={() => setCourtModalIsOpen(false)}>
                       <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>Washington Supreme Court</h3></Modal.Header>
                       <GenerateFeed url='&skip=0&schema=united_states_of_america&table=supreme_wash&order_by=created_at' 
                       name='Washington Supreme Court' branch='Judicial' topic='N/A' buttonName='Bill' />
                     </Modal>





</div>
</Col>

















</div>

</Carousel.Item>





</Carousel>

</Col>

</Row>


<br></br><br></br><br></br>

<StatePicker/>


 
   
      </div>
      </>
    );
  }





  
  
  

  

  
  //onClick={linkToBill()}
  return (
    
    <>
    
      <div className="App">
   
    




      <BrowserView>
      <div id='homePage'>
        <div>


        <div id='homebg'>
         
        <StateDesktopNavbar state='Washington' stateFlag='/washingtonFlag.png'/>

       

            <BranchesTab />
            

            {/*onClick={() => } */}
           
           
        
            <Modal id='fullBillModal' show={legislativePageBillsEnrolledModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setLegislativePageBillsEnrolledModalIsOpen(false)}>
              <Modal.Header id='billModalHeader' closeButton><h3>Bills Enrolled (Sent to President)</h3></Modal.Header>
              <BillsEnrolled />
            </Modal>
         


          
            
              
             
              
            
          
            
            
          
            
           
     
          </div>
    </div>     

    </div>
      </BrowserView>
     

      <MobileView> 
        
        
                      <WashingtonHomeMobile/>

     
      </MobileView>
        </div>
      


       
    </>

     
    
  );







}
}


export default WashingtonHome;
