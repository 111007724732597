
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link, Switch, } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment, } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Tooltip, OverlayTrigger, Image } from 'react-bootstrap';
import { ArrowsAngleExpand,  ArrowsFullscreen, InfoCircle, FileText, ShieldShaded, PlayCircle, PauseCircle, HeartFill, Pen, People, Collection, Grid, ThreeDots, Stack, List, Files, FolderPlus, FolderCheck, Folder, Tree, FileMedical, FileMedicalFill, ChevronRight, Bricks  } from 'react-bootstrap-icons'
import { BrowserView, MobileView } from 'react-device-detect';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import { render } from '@testing-library/react';
import {createDepartmentOfHealthAndHumanServices, 
  createCDCNewsroom, createCDCOutbreaks, 
  createCDCTravelNotices, createFoodSafety, 
  createFoodRecalls, createMedWatch, 
  createHealthyLivingFeatures, 
  createPreventingChronicDiseases, 
  createCovid19, createNovelCoronavirus, 
  createVaccineUpdates, createSeasonalFlu, Speak, CreateFeedItem, CreateFeedItemMaterials, CreateFeedAPI, CreateFeedItemBillWithDescription} from "./Functions";
  import FederalRegisterDefenseDepartment from './FederalRegisterDefenseDepartment';
import GenerateFeed from './GenerateFeed';
import FederalHousingEnterprise from './FederalHousingEnterprise';
import DepartmentOfHousingAndUrbanDevelopmentDocs from './DepartmentOfHousingAndUrbanDevelopmentDocs';
import DepartmentOfHousingAndUrbanDevelopment from './DepartmentOfHousingAndUrbanDevelopment';
import DepartmentOfTransportationDocs from './DepartmentOfTransportationDocs';
import CommercialSpaceTransportationOffice from './CommercialSpaceTransportationOffice';
import FederalAviationAdministration from './FederalAviationAdministration';
import FederalHighwayAdmin from './FederalHighwayAdmin';
import FederalMotorCarrierSafetyAdmin from './FederalMotorCarrierSafetyAdmin';
import FederalRailroadAdmin from './FederalRailroadAdmin';
import FederalTransitAdmin from './FederalTransitAdmin';
import NationalHighwayTrafficSafetyAdmin from './NationalHighwayTrafficSafetyAdmin';
import PipelineAndHazardousMaterialsSafetyAdmin from './PipelineAndHazardousMaterialsSafetyAdmin';
import TransportationStatsBureau from './TransportationStatsBureau';
import GreatLakesStLawerenceSeaway from './GreatLakesStLawerenceSeaway';
import MaritimeAdmin from './MaritimeAdmin';
import MobileOffCanvas from './MobileOffCanvas';
import MobileTopNavbar from './MobileTopNavbar';
import HouseCommitteeOnTransportationAndInfrastructureMaterials from './HouseCommitteeOnTransportationAndInfrastructureMaterials';
import HouseCommitteeOnTransportationAndInfrastructure from './HouseCommitteeOnTransportationAndInfrastructure';
import DepartmentOfEnergyDocs from './DepartmentOfEnergyDocs';
import BonnevillePowerAdministration from './BonnevillePowerAdministration';
import EnergyEfficiencyAndRenewableEnergyOffice from './EnergyEfficiencyAndRenewableEnergyOffice';
import EnergyInformationAdministration from './EnergyInformationAdministration';
import FederalEnergyRegulatoryCommission from './FederalEnergyRegulatoryCommission';
import NationalNuclearSecurityAdministration from './NationalNuclearSecurityAdministration';
import SoutheasternPowerAdministration from './SoutheasternPowerAdministration';
import SouthwesternPowerAdministration from './SouthwesternPowerAdministration';
import WesternAreaPowerAdministration from './WesternAreaPowerAdministration';

import HouseCommitteeOnNaturalResourcesMaterials from './HouseCommitteeOnNaturalResourcesMaterials';
import HouseCommitteeOnNaturalResources from './HouseCommitteeOnNaturalResources';
import NaturalResourcesConservation from './NaturalResourcesConservation';
import SenateEnergyAndNRCommittee from './SenateEnergyAndNRCommittee';

var mobileCarouselInterval = 60000;

export const InfrastructureTopicMobile = () =>
 {
   render()
   {

    const [departmentOfDefenseModalIsOpen, setDepartmentOfDefenseModalIsOpen] = useState(false)
    const [defenseDocumentsModalIsOpen, setDefenseDocumentsModalIsOpen] = useState(false)
    const [armyModalIsOpen, setArmyModalIsOpen] = useState(false)
    const [navyModalIsOpen, setNavyModalIsOpen] = useState(false)
    const [marinesModalIsOpen, setMarinesModalIsOpen] = useState(false)
    const [spaceForceModalIsOpen, setSpaceForceModalIsOpen] = useState(false)
    const [airForceModalIsOpen, setAirForceModalIsOpen] = useState(false)
    const [nationalGuardModalIsOpen, setNationalGuardModalIsOpen] = useState(false)
    const [ciaModalIsOpen, setCIAModalIsOpen] = useState(false)
    const [nsaModalIsOpen, setNSAModalIsOpen] = useState(false)
    const [armyCorpsModalIsOpen, setArmyCorpsModalIsOpen] = useState(false)
    const [armedServicesMaterialsModalIsOpen, setArmedServicesMaterialsModalIsOpen] = useState(false)
    const [committeeOnArmedServicesModalIsOpen, setCommitteeOnArmedServicesModalIsOpen] = useState(false)
    const [committeeOnIntelligenceModalIsOpen, setCommitteeOnIntelligenceModalIsOpen] = useState(false)
    const [intelligenceMaterialsModalIsOpen, setIntelligenceMaterialsModalIsOpen] = useState(false)
    const [committeeOnHomelandSecurityModalIsOpen, setCommitteeOnHomelandSecurityModalIsOpen] = useState(false)
    const [homelandSecurityMaterialsModalIsOpen, setHomelandSecurityMaterialsModalIsOpen] = useState(false)
    const [committeeOnForeignAffairsModalIsOpen, setCommitteeOnForeignAffairsModalIsOpen] = useState(false)
    const [foreignAffairsMaterialsModalIsOpen, setForeignAffairsMaterialsModalIsOpen] = useState(false)
    const [homelandDocsModalIsOpen, setHomelandDocsModalIsOpen] = useState(false)
    const [coastGuardModalIsOpen, setCoastGuardModalIsOpen] = useState(false)
    const [femaModalIsOpen, setFEMAModalIsOpen] = useState(false)
    const [secretServiceModalIsOpen, setSecretServiceModalIsOpen] = useState(false)
    const [tsaModalIsOpen, setTSAModalIsOpen] = useState(false)
    const [immigrationModalIsOpen, setImmigrationModalIsOpen] = useState(false)
    const [usCustomsModalIsOpen, setUSCustomsModalIsOpen] = useState(false)
    const [enforcementsModalIsOpen, setEnforcemnetsModalIsOpen] = useState(false)
    const [counterterrorismModalIsOpen, setCounterterrorismModalIsOpen] = useState(false)
    const [departmentOfCommerceModalIsOpen, setDepartmentOfCommerceModalIsOpen] = useState(false)
        const [departmentOfLaborModalIsOpen, setDepartmentOfLaborModalIsOpen] = useState(false)
        const [departmentOfHealthAndHumanServicesModalIsOpen, setDepartmentOfHealthAndHumanServicesModalIsOpen] = useState(false)
        const [committeeOnTransportationModalIsOpen, setCommitteeOnTransportationModalIsOpen] = useState(false)
      const [transportationMaterialsModalIsOpen, setTransportationMaterialsModalIsOpen] = useState(false)
      const [departmentOfHousingAndUrbanDevelopmentModalIsOpen, setDepartmentOfHousingAndUrbanDevelopmentModalIsOpen] = useState(false)
      const [newLawsModalIsOpen, setNewLawsModalIsOpen] = useState(false)
      const [committeeOnNaturalResourcesModalIsOpen, setCommitteeOnNaturalResourcesModalIsOpen] = useState(false)
      const [naturalResourcesMaterialsModalIsOpen, setNaturalResourcesMaterialsModalIsOpen] = useState(false)
      const [nasaModalIsOpen, setNASAModalIsOpen] = useState(false)
      const [energyModalIsOpen, setEnergyModalIsOpen] = useState(false)
      const [diplomaticSecurityModalIsOpen, setDiplomaticSecurityModalIsOpen] = useState(false)
      
      return(
    <>

      


<MobileTopNavbar/>


        <div id='homebg'>
    

            <h5 id='branchHeadingMobile' ><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-building" viewBox="0 0 16 16">
  <path d="M4 2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm3.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zM4 5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zM7.5 5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm2.5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zM4.5 8a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm2.5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm3.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5z"/>
  <path d="M2 1a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1zm11 0H3v14h3v-2.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5V15h3z"/>
</svg> Infrastructure</h5>

   
<br></br>
                                                                      
<h5 onClick={() => window.open('/departmentofhousing', "_self")} id='presidentialFeedHeadingMobileSmallerV'>Department of Housing and Urban Development<ChevronRight id='clickChevron'></ChevronRight></h5>

            
<Col>

<Carousel controls={false} >
            
      
            
            <Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile'>

<Col>


<h5 id='presidentialFeedHeadingMobile' onClick={() => setDepartmentOfHousingAndUrbanDevelopmentModalIsOpen(true)}>News</h5>




  <div class='courtNewsMobile' id='housingAndUrbanDevelopmentDepartment'></div>

  {CreateFeedItem('https://www.hud.gov/sites/dfiles/Main/documents/hudrss.xml', 'housingAndUrbanDevelopmentDepartment', 'Department of Housing and Urban Development', 'Executive', 'Environment', DepartmentOfHousingAndUrbanDevelopment)}

</Col>



<Modal id='whiteHouseDocsModal' show={departmentOfHousingAndUrbanDevelopmentModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfHousingAndUrbanDevelopmentModalIsOpen(false)(false)}>
  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Housing and Urban Development</h3></Modal.Header>
  <DepartmentOfHousingAndUrbanDevelopment />
</Modal>


    



</div>



</Carousel.Item>

<Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile'>

<Col>


<h5 id='presidentialFeedHeadingMobile' onClick={() => setUSCustomsModalIsOpen(true)}>Documents</h5>


<div class='courtNewsMobile' id='housingDocs'></div>

{CreateFeedItem('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=housing-and-urban-development-department', 'housingDocs', 'Department of Housing and Urban Development Documents', 'Executive', 'N/A', DepartmentOfHousingAndUrbanDevelopmentDocs)}

</Col>

<Modal id='whiteHouseDocsModal' show={usCustomsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setUSCustomsModalIsOpen(false)}>
      <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Housing and Urban Development Documents</h3></Modal.Header>
      <DepartmentOfHousingAndUrbanDevelopmentDocs/>
    </Modal>
  





</div>



</Carousel.Item>






<Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile'>

<Col>
<div id='viewFullPageButton'>
<Button id='viewFullLegislativePageButtonMobile' size='lg' href='/departmentofhousing' >View all Department of Housing and Urban Development news</Button>
</div>
</Col>

</div>



</Carousel.Item>

            
            
            </Carousel>

    </Col>

    <h5 onClick={() => window.open('/us/departmentoftransportation', "_self")} id='presidentialFeedHeadingMobile'>Department of Transportation<ChevronRight id='clickChevron'></ChevronRight></h5>

            
<Col>

<Carousel controls={false} >
            
      
            
            <Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile'>

<Col>


<h5 id='presidentialFeedHeadingMobile' onClick={() => setNewLawsModalIsOpen(true)}>Documents</h5>




  <div class='courtNewsMobile' id='transDepartment'></div>

  {CreateFeedItem('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=transportation-department', 'transDepartment', 'Department of Transporation Docs', 'Executive', 'N/A', DepartmentOfTransportationDocs)}

</Col>



<Modal id='whiteHouseDocsModal' show={newLawsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setNewLawsModalIsOpen(false)}>
  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Transporation</h3></Modal.Header>
  <DepartmentOfTransportationDocs />
</Modal>


    



</div>



</Carousel.Item>

<Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile'>

<Col>


<h5 id='presidentialFeedHeadingMobile' onClick={() => setImmigrationModalIsOpen(true)}>Commercial Space Transporation Office</h5>


<div class='courtNewsMobile' id='spaceDocs'></div>

{CreateFeedItem('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=commercial-space-transportation-office', 'spaceDocs', 'Commercial Space Transporation Office', 'Executive', 'N/A', CommercialSpaceTransportationOffice)}

</Col>

<Modal id='whiteHouseDocsModal' show={immigrationModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setImmigrationModalIsOpen(false)}>
      <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Justice Documents</h3></Modal.Header>
      <CommercialSpaceTransportationOffice/>
    </Modal>
  





</div>



</Carousel.Item>






<Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile'>

<Col>
<div id='viewFullPageButton'>
<Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/departmentoftransportation' >View all Department of Transportation news</Button>
</div>
</Col>

</div>



</Carousel.Item>

            
            
            </Carousel>

    </Col>        
    <h5 onClick={() => window.open('/us/departmentofenergy', "_self")} id='presidentialFeedHeadingMobile'>Department of Energy<ChevronRight id='clickChevron'></ChevronRight></h5>

<Col>

<Carousel controls={false} >
            
      
            
            <Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile'>

<Col>

<h5 id='presidentialFeedHeadingMobile' onClick={() => setDiplomaticSecurityModalIsOpen(true)}>Documents</h5>




<div class='courtNewsMobile' id='energyDepartment'></div>

{CreateFeedItem('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=energy-department', 'energyDepartment', 'Department of Energy', 'Executive', 'N/A', DepartmentOfEnergyDocs)}

    </Col>


    
    <Modal id='whiteHouseDocsModal' show={diplomaticSecurityModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDiplomaticSecurityModalIsOpen(false)}>
      <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Energy Documents</h3></Modal.Header>
      <DepartmentOfEnergyDocs />
    </Modal>



</div>



</Carousel.Item>


<Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile'>

<Col>
<div id='viewFullPageButton'>
<Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/departmentofenergy' >View all Department of Energy news</Button>
</div>
</Col>

</div>



</Carousel.Item>

            
            
            </Carousel>



    </Col>






                <Col>


<h5 id='presidentialFeedHeadingMobile' onClick={() => setNavyModalIsOpen(true)}>Energy Information Administration</h5>


  <div class='courtNewsMobile' id='navyDocs'></div>

  {CreateFeedItemBillWithDescription('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=energy-information-administration', 'navyDocs', 'Energy Information Administration', 'Executive', 'N/A', EnergyInformationAdministration)}

</Col>
              
                <Modal id='whiteHouseDocsModal' show={navyModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setNavyModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Energy Information Administration</h3></Modal.Header>
                  <EnergyInformationAdministration/>
                </Modal>



                <Col>


<h5 id='presidentialFeedHeadingMobile' onClick={() => setAirForceModalIsOpen(true)}>Federal Energy Regulatory Commission</h5>


  <div class='courtNewsMobile' id='airForce'></div>

  {CreateFeedItemBillWithDescription('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=federal-energy-regulatory-commission', 'airForce', 'Federal Energy Regulatory Commission', 'Executive', 'N/A', FederalEnergyRegulatoryCommission)}

</Col>
              
                <Modal id='whiteHouseDocsModal' show={airForceModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setAirForceModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Federal Energy Regulatory Commission</h3></Modal.Header>
                  <FederalEnergyRegulatoryCommission/>
                </Modal>

                <Col>


<h5 id='presidentialFeedHeadingMobile' onClick={() => setMarinesModalIsOpen(true)}>Energy Efficiency and Renewable Energy Office</h5>


  <div class='courtNewsMobile' id='marinesPress'></div>

  {CreateFeedItemBillWithDescription('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=energy-efficiency-and-renewable-energy-office', 'marinesPress', 'Energy Efficiency and Renewable Energy Office', 'Executive', 'N/A', EnergyEfficiencyAndRenewableEnergyOffice)}

</Col>
              
                <Modal id='whiteHouseDocsModal' show={marinesModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setMarinesModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Energy Efficiency and Renewable Energy Office</h3></Modal.Header>
                  <EnergyEfficiencyAndRenewableEnergyOffice/>
                </Modal>

                <Col>


<h5 id='presidentialFeedHeadingMobile' onClick={() => setSpaceForceModalIsOpen(true)}>National Nuclear Security Administration</h5>


  <div class='courtNewsMobile' id='spaceForce'></div>

  {CreateFeedItemBillWithDescription('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=national-nuclear-security-administration', 'spaceForce', 'National Nuclear Security Administration', 'Executive', 'N/A', NationalNuclearSecurityAdministration)}

</Col>
              
                <Modal id='whiteHouseDocsModal' show={spaceForceModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setSpaceForceModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>National Nuclear Security Administration</h3></Modal.Header>
                  <NationalNuclearSecurityAdministration/>
                </Modal>


                <Col>


<h5 id='presidentialFeedHeadingMobile' onClick={() => setNationalGuardModalIsOpen(true)}>Southeastern Power Administration</h5>


  <div class='courtNewsMobile' id='nationalGuard'></div>

  {CreateFeedItemBillWithDescription('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=southeastern-power-administration', 'nationalGuard', 'Southeastern Power Administration', 'Executive', 'N/A', SoutheasternPowerAdministration)}

</Col>
              
                <Modal id='whiteHouseDocsModal' show={nationalGuardModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setNationalGuardModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Southeastern Power Administration</h3></Modal.Header>
                  <SoutheasternPowerAdministration/>
                </Modal>




                
                <Col>


<h5 id='presidentialFeedHeadingMobile' onClick={() => setNSAModalIsOpen(true)}>Southwestern Power Administration</h5>


  <div class='courtNewsMobile' id='nsa'></div>

  {CreateFeedItemBillWithDescription('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=southwestern-power-administration', 'nsa', 'Southwestern Power Administration', 'Executive', 'N/A', SouthwesternPowerAdministration)}

</Col>
              
                <Modal id='whiteHouseDocsModal' show={nsaModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setNSAModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Southwestern Power Administration</h3></Modal.Header>
                  <SouthwesternPowerAdministration/>
                </Modal>

                <Col>


<h5 id='presidentialFeedHeadingMobile' onClick={() => setArmyCorpsModalIsOpen(true)}>Western Area Power Administration</h5>


  <div class='courtNewsMobile' id='armyCorps'></div>

  {CreateFeedItemBillWithDescription('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=western-area-power-administration', 'armyCorps', 'Western Area Power Administration', 'Executive', 'N/A', WesternAreaPowerAdministration)}

</Col>
              
                <Modal id='whiteHouseDocsModal' show={armyCorpsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setArmyCorpsModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Western Area Power Administration</h3></Modal.Header>
                  <WesternAreaPowerAdministration/>
                </Modal>

                <Col>


<h5 id='presidentialFeedHeadingMobile' onClick={() => setArmyModalIsOpen(true)}>Bonneville Power Administration</h5>


  <div class='courtNewsMobile' id='armyDocs'></div>

  {CreateFeedItemBillWithDescription('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=bonneville-power-administration', 'armyDocs', 'Bonneville Power Administration', 'Executive', 'N/A', BonnevillePowerAdministration)}

</Col>
              
                <Modal id='whiteHouseDocsModal' show={armyModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setArmyModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Bonneville Power Administration</h3></Modal.Header>
                  <BonnevillePowerAdministration/>
                </Modal>

           
                <Col>
                    
                <h5 id='presidentialFeedHeadingMobile' onClick={() => setCommitteeOnNaturalResourcesModalIsOpen(true)}>Committee on Natural Resources</h5>
                        <div class='courtNewsMobile'id='houseCommitteeOnNaturalResources'></div>
                  



                        {CreateFeedItem('https://docs.house.gov/Committee/RSS.ashx?Code=II00', 'houseCommitteeOnNaturalResources', 'House Committee on Natural Resources', 'Legislative', 'Environment', HouseCommitteeOnNaturalResources)}


                </Col>

                <Modal id='fullBillModal' show={committeeOnNaturalResourcesModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCommitteeOnNaturalResourcesModalIsOpen(false)}>
<Modal.Header id='billModalHeader' closeButton><h3>Committee on Natural Resources</h3></Modal.Header>

<HouseCommitteeOnNaturalResources/>
</Modal>

                <Col>
                    
                <h5 id='presidentialFeedHeadingMobile' onClick={() => setNaturalResourcesMaterialsModalIsOpen(true)}>Committee on Natural Resources Materials</h5>
                        <div class='courtNewsMobile'id='houseCommitteeOnNaturalResourcesMaterials'></div>
                  



                        {CreateFeedItemMaterials('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/houseCommittees/Committee%20on%20Natural%20Resources.xml', 'houseCommitteeOnNaturalResourcesMaterials', 'House Committee on Natural Resources Materials', 'Legislative', 'Environment', HouseCommitteeOnNaturalResourcesMaterials)}

    
                    </Col>

                    <Modal id='fullBillModal' show={naturalResourcesMaterialsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setNaturalResourcesMaterialsModalIsOpen(false)}>
<Modal.Header id='billModalHeader' closeButton><h3>Committee on Natural Resources Materials</h3></Modal.Header>

<HouseCommitteeOnNaturalResourcesMaterials/>
</Modal>

<Col>


<h5 id='presidentialFeedHeadingMobile' onClick={() => setNASAModalIsOpen(true)}>Natural Resources Conservation Service</h5>


  <div class='courtNewsMobile' id='naturalResources'></div>

  {CreateFeedItemBillWithDescription('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=natural-resources-conservation-service', 'naturalResources', 'Natural Resources Conservation Service', 'Executive', 'N/A', NaturalResourcesConservation)}

</Col>
              
                <Modal id='whiteHouseDocsModal' show={nasaModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setNASAModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Natural Resources Conservation Service</h3></Modal.Header>
                  <NaturalResourcesConservation/>
                </Modal>

                <Col>
              
              <h5 id='presidentialFeedHeadingMobile' onClick={() => setEnergyModalIsOpen(true)}>Committee on Energy and Natural Resources Materials</h5>
                      <div class='courtNewsMobile'id='senateEnergyCommittee'></div>
                
                        {CreateFeedItemMaterials('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/senateCommittees/Committee%20on%20Energy%20and%20Natural%20Resources.xml', 'senateEnergyCommittee', 'Senate Committee on Energy and Natural Resources', 'Legislative', 'Environment', SenateEnergyAndNRCommittee)}

              
                        <Modal id='fullBillModal' show={energyModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" 
                    centered onHide={() => setEnergyModalIsOpen(false)}>
            <Modal.Header id='billModalHeader' closeButton><h3>Senate Committee on Energy and Natural Resources Materials</h3></Modal.Header>
            <SenateEnergyAndNRCommittee/>
          </Modal>

              </Col>
     



                <Col>


<h5 id='presidentialFeedHeadingMobile' onClick={() => setUSCustomsModalIsOpen(true)}>Federal Housing Enterprise Oversight Office</h5>


  <div class='courtNewsMobile' id='federalHousing'></div>

  {CreateFeedItem('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=federal-housing-enterprise-oversight-office', 'federalHousing', 'Federal Housing Enterprise Oversight Office', 'Executive', 'Infrastructure', FederalHousingEnterprise)}

</Col>
              
                <Modal id='whiteHouseDocsModal' show={usCustomsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setUSCustomsModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Federal Housing Enterprise Oversight Office</h3></Modal.Header>
                  <FederalHousingEnterprise/>
                </Modal>



                <Col>


<h5 id='presidentialFeedHeadingMobile' onClick={() => setArmyModalIsOpen(true)}>Federal Aviation Administration</h5>


  <div class='courtNewsMobile' id='disabilityEmployment'></div>

  {CreateFeedItem('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=federal-aviation-administration', 'disabilityEmployment', 'Federal Aviation Administration', 'Executive', 'N/A', FederalAviationAdministration)}

</Col>
              
                <Modal id='whiteHouseDocsModal' show={armyModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setArmyModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Federal Aviation Administration</h3></Modal.Header>
                  <FederalAviationAdministration/>
                </Modal>

                <Col>


<h5 id='presidentialFeedHeadingMobile' onClick={() => setMarinesModalIsOpen(true)}>Federal Highway Administration</h5>


  <div class='courtNewsMobile' id='marinesPress'></div>

  {CreateFeedItemBillWithDescription('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=federal-highway-administration', 'marinesPress', 'Federal Highway Administration', 'Executive', 'N/A', FederalHighwayAdmin)}

</Col>
              
                <Modal id='whiteHouseDocsModal' show={marinesModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setMarinesModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Federal Highway Administration</h3></Modal.Header>
                  <FederalHighwayAdmin/>
                </Modal>

                <Col>


<h5 id='presidentialFeedHeadingMobile' onClick={() => setNavyModalIsOpen(true)}>Federal Motor Carrier Safety Administration</h5>


  <div class='courtNewsMobile' id='navyDocs'></div>

  {CreateFeedItemBillWithDescription('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=federal-motor-carrier-safety-administration', 'navyDocs', 'Federal Motor Carrier Safety Administration', 'Executive', 'N/A', FederalMotorCarrierSafetyAdmin)}

</Col>
              
                <Modal id='whiteHouseDocsModal' show={navyModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setNavyModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Federal Motor Carrier Safety Administration</h3></Modal.Header>
                  <FederalMotorCarrierSafetyAdmin/>
                </Modal>



                <Col>


<h5 id='presidentialFeedHeadingMobile' onClick={() => setAirForceModalIsOpen(true)}>Federal Railroad Administration</h5>


  <div class='courtNewsMobile' id='airForce'></div>

  {CreateFeedItemBillWithDescription('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=federal-railroad-administration', 'airForce', 'Federal Railroad Administration', 'Executive', 'N/A', FederalRailroadAdmin)}

</Col>
              
                <Modal id='whiteHouseDocsModal' show={airForceModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setAirForceModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Federal Railroad Administration</h3></Modal.Header>
                  <FederalRailroadAdmin/>
                </Modal>



                <Col>


<h5 id='presidentialFeedHeadingMobile' onClick={() => setSpaceForceModalIsOpen(true)}>Federal Transit Administration</h5>


  <div class='courtNewsMobile' id='spaceForce'></div>

  {CreateFeedItemBillWithDescription('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=federal-transit-administration', 'spaceForce', 'Federal Transit Administration', 'Executive', 'N/A', FederalTransitAdmin)}

</Col>
              
                <Modal id='whiteHouseDocsModal' show={spaceForceModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setSpaceForceModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Federal Transit Administration</h3></Modal.Header>
                  <FederalTransitAdmin/>
                </Modal>


                <Col>


<h5 id='presidentialFeedHeadingMobile' onClick={() => setNationalGuardModalIsOpen(true)}>Maritime Administration</h5>


  <div class='courtNewsMobile' id='nationalGuard'></div>

  {CreateFeedItemBillWithDescription('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=maritime-administration', 'nationalGuard', 'Maritime Administration', 'Executive', 'N/A', MaritimeAdmin)}

</Col>
              
                <Modal id='whiteHouseDocsModal' show={nationalGuardModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setNationalGuardModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Maritime Administration</h3></Modal.Header>
                  <MaritimeAdmin/>
                </Modal>




                
                <Col>


<h5 id='presidentialFeedHeadingMobile' onClick={() => setNSAModalIsOpen(true)}>National Highway Traffic Safety Administration</h5>


  <div class='courtNewsMobile' id='nsa'></div>

  {CreateFeedItemBillWithDescription('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=national-highway-traffic-safety-administration', 'nsa', 'National Highway Traffic Safety Administration', 'Executive', 'N/A', NationalHighwayTrafficSafetyAdmin)}

</Col>
              
                <Modal id='whiteHouseDocsModal' show={nsaModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setNSAModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>National Highway Traffic Safety Administration</h3></Modal.Header>
                  <NationalHighwayTrafficSafetyAdmin/>
                </Modal>

                <Col>


<h5 id='presidentialFeedHeadingMobile' onClick={() => setArmyCorpsModalIsOpen(true)}>Pipeline and Hazardous Materials Safety Administration</h5>


  <div class='courtNewsMobile' id='armyCorps'></div>

  {CreateFeedItemBillWithDescription('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=pipeline-and-hazardous-materials-safety-administration', 'armyCorps', 'Pipeline and Hazardous Materials Safety Administration', 'Executive', 'N/A', PipelineAndHazardousMaterialsSafetyAdmin)}

</Col>
              
                <Modal id='whiteHouseDocsModal' show={armyCorpsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setArmyCorpsModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Pipeline and Hazardous Materials Safety Administration</h3></Modal.Header>
                  <PipelineAndHazardousMaterialsSafetyAdmin/>
                </Modal>


                <Col>


<h5 id='presidentialFeedHeadingMobile' onClick={() => setDepartmentOfCommerceModalIsOpen(true)}>Transportation Statistics Bureau</h5>


  <div class='courtNewsMobile' id='reclamation'></div>

  {CreateFeedItemBillWithDescription('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=transportation-statistics-bureau', 'reclamation', 'Transportation Statistics Bureau', 'Executive', 'N/A', TransportationStatsBureau)}

</Col>
              
                <Modal id='whiteHouseDocsModal' show={departmentOfCommerceModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfCommerceModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Transportation Statistics Bureau</h3></Modal.Header>
                  <TransportationStatsBureau/>
                </Modal>


                <Col>


<h5 id='presidentialFeedHeadingMobile' onClick={() => setDepartmentOfHealthAndHumanServicesModalIsOpen(true)}>Great Lakes St. Lawrence Seaway Development Corporation</h5>


  <div class='courtNewsMobile' id='surface'></div>

  {CreateFeedItemBillWithDescription('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=great-lakes-st-lawrence-seaway-development-corporation', 'surface', 'Great Lakes St. Lawrence Seaway Development Corporation', 'Executive', 'N/A', GreatLakesStLawerenceSeaway)}

</Col>
              
                <Modal id='whiteHouseDocsModal' show={departmentOfHealthAndHumanServicesModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfHealthAndHumanServicesModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Great Lakes St. Lawrence Seaway Development Corporation</h3></Modal.Header>
                  <GreatLakesStLawerenceSeaway/>
                </Modal>

                <Col>
                    
                    <h5 id='presidentialFeedHeadingMobile' onClick={() =>  setCommitteeOnTransportationModalIsOpen(true)}>Committee on Transportation and Infrastructure</h5>
                <div class='courtNewsMobile'id='houseCommitteeOnTransportationAndInfrastructure'></div>
               

                {CreateFeedItem('https://docs.house.gov/Committee/RSS.ashx?Code=PW00', 'houseCommitteeOnTransportationAndInfrastructure', 'House Committee on Transportation and Infrastructure', 'Legislative', 'Environment', HouseCommitteeOnTransportationAndInfrastructure)}

                  </Col>

                  <Modal id='fullBillModal' show={committeeOnTransportationModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCommitteeOnTransportationModalIsOpen(false)}>
<Modal.Header id='billModalHeader' closeButton><h3>Committee on Transportation and Infrastructure</h3></Modal.Header>

<HouseCommitteeOnTransportationAndInfrastructure/>
</Modal>



                  <Col>
            
                  <h5 id='presidentialFeedHeadingMobile' onClick={() =>  setTransportationMaterialsModalIsOpen(true)}>Committee on Transportation and Infrastructure Materials</h5>
                <div class='courtNewsMobile'id='houseCommitteeOnTransportationAndInfrastructureMaterials'></div>
               

                {CreateFeedItemMaterials('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/houseCommittees/Committee%20on%20Transportation%20and%20Infrastructure.xml', 'houseCommitteeOnTransportationAndInfrastructureMaterials', 'House Committee on Transportation and Infrastructure Materials', 'Legislative', 'Environment', HouseCommitteeOnTransportationAndInfrastructureMaterials)}

          </Col>

          <Modal id='fullBillModal' show={transportationMaterialsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTransportationMaterialsModalIsOpen(false)}>
<Modal.Header id='billModalHeader' closeButton><h3>Committee on Transportation and Infrastructure Materials</h3></Modal.Header>

<HouseCommitteeOnTransportationAndInfrastructureMaterials/>
</Modal>
     
        
          <Col>

            </Col>


            <Col>
            <br></br><br></br><br></br><br></br>
<br></br>
<br></br>
<h5 id='headingSelectHomeCountry'>Support the Gov Glance Foundation  <Button id='donateButton' onClick={(e) => {
                        e.preventDefault();
                        window.open('https://donate.stripe.com/28o3fXbdvbQO2RO288', "_blank")
                      }}><HeartFill id='donateIcon'/>    Donate</Button></h5>

</Col>
            <br></br><br></br><br></br><br></br><br></br><br></br><Navbar id='navbarMobileBottom' fixed="bottom">    

          


       
<div id='changeBranchDropdownMobile'>

           <MobileOffCanvas/>
       
            </div>

           


           
 
             
 
 
 
 
 
 
</Navbar>
</div>




             
              
    </>
  )
}
 }



  






export default InfrastructureTopicMobile;