import App from "../../App";
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Image, ModalFooter } from 'react-bootstrap';
import { ArrowsAngleExpand, InfoCircle, FileText, PauseCircle, PlayCircle, FolderPlus, ChevronRight, Files, Folder, Pen, ChatLeftQuote, ShieldShaded, People, Coin, HeartFill, Bricks } from 'react-bootstrap-icons'
import '../../App.css';
import 'bootstrap/dist/css/bootstrap.min.css'


import { render } from '@testing-library/react';

import{ ListItem, createFullBillFeed, saveToCollection, createHouseCommitteeOversightAndReform, createHouseCommitteeOnRules,
    createHouseCommitteeOnSmallBusiness, createHouseCommitteeOnScienceSpaceAndTechnology,
    createHouseCommitteeOnTransportationAndInfrastructure,
    createHouseCommitteeOnVeteransAffairs,
    createCongressionalBudgetOffice, createBillFeedTwo,
    createBillFeedTwoMobile, createReports, createHouseCommitteeOnAgriculture,
     createHouseCommitteeOnAppropriations, createHouseCommitteeOnArmedServices,
     createHouseCommitteeOnTheBudget, createHouseCommitteeOnEducationAndLabor,
    createHouseCommitteeOnEnergyAndCommerce, createHouseCommitteeOnEthics,
     createHouseCommitteeOnFinancialServices, createHouseCommitteeOnForeignAffairs, createHouseCommitteeOnHomelandSecurity,
    createHouseCommitteeOnHouseAdministration, 
    createHouseCommitteeOnTheJudiciary, createHouseCommitteeOnNaturalResources, 
     createHouseCommitteeOnNaturalResourcesMobile, createGAOReports,
     createNewLaws, createBillsEnrolled
    , createBillsEnrolledMobile, createTrendingBills
    , createHouseFloorToday, congressSearch, Speak, createGAOReportsMobile, createCongressionalHearings, createBillStatus, CreateFeedItem,
    CreateFeedItemBillUpdates, CreateFeedItemWithDocButton, CreateFeedItemWithLawButton,
    CreateFeedItemGAOReports, CreateFeedItemCongressionalReports, CreateFeedItemWithDocumentFlip, CreateFeedItemWithLawButtonFlip, CreateFeedItemWithDocButtonDateFix, CreateFeedItemCanadaDateFix, CreateFeedItemMexico, CreateFeedAPI} from '../../Functions'


import MobileOffCanvas from "../../MobileOffCanvas";


import MexicoMobileTopNavbar from "../MexicoMobileTopNavbar";
import SEAgency from "../Executive/SeAgency";
import SecturAgency from "../Executive/SecturAgency";
import SHCPAgency from "../Executive/SHCPAgency";
import SedatuAgency from "../Executive/SedatuAgency";
import SedenaAgency from "../Executive/SedenaAgency";
import GenerateFeed from "../../GenerateFeed";




var collectionCounter = 0
var carouselInterval = 30000;
var mobileCarouselInterval = 60000;



export const MexicoDefenseMobile = () =>   {
    render()
    {
  
          const [trendingBillsOptionOne, setTrendingBillsOptionOneModalIsOpen ] = useState(false)
          const [trendingBillsOptionTwo, setTrendingBillsOptionTwoModalIsOpen ] = useState(false)
          const [trendingBillsOptionThree, setTrendingBillsOptionThreeModalIsOpen ] = useState(false)
          const [trendingBillsOptionFour, setTrendingBillsOptionFourModalIsOpen ] = useState(false)
          const [trendingBillsOptionFive, setTrendingBillsOptionFiveModalIsOpen ] = useState(false)
          const [trendingBillsOptionSix, setTrendingBillsOptionSixModalIsOpen ] = useState(false)
          const [trendingBillsOptionSeven, setTrendingBillsOptionSevenModalIsOpen ] = useState(false)
          const [trendingBillsOptionEight, setTrendingBillsOptionEightModalIsOpen ] = useState(false)
          const [trendingBillsOptionNine, setTrendingBillsOptionNineModalIsOpen ] = useState(false)
          const [trendingBillsOptionTen, setTrendingBillsOptionTenModalIsOpen ] = useState(false)
          const [trendingBillsOptionEleven, setTrendingBillsOptionElevenModalIsOpen ] = useState(false)
          const [trendingBillsOptionTweleve, setTrendingBillsOptionTweleveModalIsOpen ] = useState(false)
          const [trendingBillsOptionThirteen, setTrendingBillsOptionThirteenModalIsOpen ] = useState(false)
      
        localStorage.setItem('currentPage', 'mexico')
    return(
       <>
    <MexicoMobileTopNavbar/>
  
  
  
  
  <div id='homebg'>
 

{/*Legislative Mobile Carousel*/}

<h5 id='branchHeadingMobile' ><Bricks/> Defense </h5>
{/*onClick={() => setModalIsOpen(true)}*/}
<Col>
  <h5 id='presidentialFeedHeadingMobile' onClick={() => setTrendingBillsOptionThirteenModalIsOpen(true)}>Secretaría de la Defensa Nacional</h5>

  <div id='sedena'></div>
                          
  {CreateFeedAPI('&skip=0&schema=mexico&table=sedena&order_by=created_at', 'sedena', 
                            'Secretaría de la Defensa Nacional', 'Executive', 
                            'Foreign Affairs', 'Doc', GenerateFeed)}

<Modal id='mobileFullBillModal' show={trendingBillsOptionThirteen} 
size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsOptionThirteenModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Secretaría de la Defensa Nacional</h3></Modal.Header>
<GenerateFeed url='&skip=0&schema=mexico&table=sedena&order_by=created_at'
                                name='Secretaría de la Defensa Nacional' branch='Executive' topic='Foreign Affairs' buttonName='Doc' />
</Modal>
</Col>
<Col>

<br></br><br></br><br></br><br></br>
<br></br>
<br></br>
<h5 id='headingSelectHomeCountry'>Support the Gov Glance Foundation  <Button id='donateButton' onClick={(e) => {
                        e.preventDefault();
                        window.open('https://donate.stripe.com/28o3fXbdvbQO2RO288', "_blank")
                      }}><HeartFill id='donateIcon'/>    Donate</Button></h5>

</Col>
<br></br><br></br><br></br><br></br><br></br><br></br>

<Navbar id='navbarMobileBottom' fixed="bottom">  



       
<div id='changeBranchDropdownMobile'>

                  <MobileOffCanvas/>

              
            </div>

</Navbar>
 
             
 
 
 
 
 
 

</div>



</>
)
}}
export default MexicoDefenseMobile;


