
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link, Switch, } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment, } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Tooltip, OverlayTrigger, Image } from 'react-bootstrap';
import { ArrowsAngleExpand, ArrowsFullscreen, InfoCircle, FileText, ShieldShaded, PauseCircle, HeartFill, Pen, People, Collection, Grid, ThreeDots, Stack, List, Files, FolderPlus, FolderCheck, Folder, PlayCircle } from 'react-bootstrap-icons'
import { BrowserView, MobileView } from 'react-device-detect';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import Axios from 'axios'
import { render } from '@testing-library/react';
import FullBillFeed from './FullBillFeed';
import FullWhiteHouseFeed from './FullWhiteHouseFeed';
import FullUSCourtNews from './FullUSCourtNews';
import FullWhiteHouseDocuments from './FullWhiteHouseDocuments';
import DailyDigest from './DailyDigest';
import State from './State';
import Illinois from './state/Illinois';
import CollectionPage from './CollectionPage';
import NewLaws from './NewLaws';
import BillsEnrolled from './BillsEnrolled';
import DepartmentOfState from './DepartmentOfState';
import DepartmentOfTreasury from './DepartmentOfTreasury';
import CourtAppeals from './CourtAppeals';
import Reports from './Reports';
import DepartmentOfDefense from './DepartmentOfDefense';
import DepartmentOfJustice from './DepartmentOfJustice';
import DepartmentOfTheInterior from './DepartmentOfTheInterior';
import DepartmentOfAgriculture from './DepartmentOfAgriculture';
import DepartmentOfCommerce from './DepartmentOfCommerce';
import DepartmentOfLabor from './DepartmentOfLabor';
import DepartmentOfHealthAndHumanServices from './DepartmentOfHealthAndHumanServices';
import DepartmentOfHousingAndUrbanDevelopment from './DepartmentOfHousingAndUrbanDevelopment';
import DepartmentOfTransportation from './DepartmentOfTransportation';
import DepartmentOfEnergy from './DepartmentOfEnergy';
import DepartmentOfEducation from './DepartmentOfEducation';
import DepartmentOfVeteranAffairs from './DepartmentOfVeteranAffairs';
import DepartmentOfHomelandSecurity from './DepartmentOfHomelandSecurity';
import EnvironmentalProtectionAgency from './EnvironmentalProtectionAgency';
import NationalAeronauticsAndSpaceAdministration from './NationalAeronauticsAndSpaceAdministration';
import TrendingBills from './TrendingBills';
import GAOReports from './GAOReports';
import LegislativeBranch from './LegislativeBranch';
import HouseComitteeOnAgriculture from './HouseComitteeOnAgriculture';
import HouseCommitteeOnAppropriations from './HouseCommitteeOnArmedServices';
import CourtOfInternationalTrade from './CourtOfInternationalTrade'
import CourtAppealsFirstCircuit from './CourtAppealsFirstCircuit';
import CourtAppealsSecondCircuit from './CourtAppealsSecondCircuit';
import CourtAppealsThirdCircuit from './CourtAppealsThirdCircuit';
import CourtAppealsFourthCircuit from './CourtAppealsFourthCircuit';
import CourtAppealsFifthCircuit from './CourtAppealsFifthCircuit';
import CourtAppealsSixthCircuit from './CourtAppealsSixthCircuit';
import CourtAppealsSeventhCircuit from './CourtAppealsSeventhCircuit';
import CourtAppealsEighthCircuit from './CourtAppealsEighthCircuit';
import CourtAppealsNinthCircuit from './CourtAppealsNinthCircuit';
import CourtAppealsTenthCircuit from './CourtAppealsTenthCircuit';
import CourtAppealsEleventhCircuit from './CourtAppealsEleventhCircuit';
import CourtAppealsDCCircuit from './CourtAppealsDCCircuit';
import CourtAppealsFederalCircuit from './CourtAppealsFederalCircuit';
import DistrictCourtNorthernAlabama from './District Courts/DistrictCourtNorthernAlabama';
import DistrictCourtSouthernAlabama from './District Courts/DistrictCourtSouthernAlabama';
import DistrictCourtMiddleAlabama from './District Courts/DistrictCourtMiddleAlabama';
import DistrictCourtAlaska from './District Courts/DistrictCourtAlaska';
import DistrictCourtArizona from './District Courts/DistrictCourtArizona';
import DistrictCourtEasternArkansas from './District Courts/DistrictCourtEasternArkansas';
import DistrictCourtEasternCalifornia from './District Courts/DistrictCourtEasternCalifornia';
import DistrictCourtNorthernCalifornia from './District Courts/DistrictCourtNorthernCalifornia';
import DistrictCourtSouthernCalifornia from './District Courts/DistrictCourtSouthernCalifornia';
import DistrictCourtColrado from './District Courts/DistrictCourtColrado';
import DistrictCourtConnecticut from './District Courts/DistrictCourtConnecticut';
import DistrictCourtColumbia from './District Courts/DistrictCourtColumbia';
import DistrictCourtDelaware from './District Courts/DistrictCourtDelaware';
import DistrictCourtSouthernFlorida from './District Courts/DistrictCourtSouthernFlorida';
import DistrictCourtNorthernFlorida from './District Courts/DistrictCourtNorthernFlorida';
import DistrictCourtMiddleGeorgia from './District Courts/DistrictCourtMiddleGeorgia';
import DistrictCourtNorthernGeorgia from './District Courts/DistrictCourtNorthernGeorgia';
import DistrictCourtGuam from './District Courts/DistrictCourtGuam';
import DistrictCourtHawaii from './District Courts/DistrictCourtHawaii';
import DistrictCourtIdaho from './District Courts/DistrictCourtIdaho';
import DistrictCourtNorthernIllinois from './District Courts/DistrictCourtNorthernIllinois';
import DistrictCourtSouthernIndiana from './District Courts/DistrictCourtSouthernIndiana';
import DistrictCourtNorthernIowa from './District Courts/DistrictCourtNorthernIowa';
import DistrictCourtKansas from './District Courts/DistrictCourtKansas';
import DistrictCourtEasternKentucky from './District Courts/DistrictCourtEasternKentucky';
import DistrictCourtWesternKentucky from './District Courts/DistrictCourtWesternKentucky';
import DistrictCourtEasternLouisiana from './District Courts/DistrictCourtEasternLouisiana';
import DistrictCourtMiddleLouisiana from './District Courts/DistrictCourtMiddleLouisiana';
import DistrictCourtMaine from './District Courts/DistrictCourtMaine';
import DistrictCourtMaryland from './District Courts/DistrictCourtMaryland';
import DistrictCourtMassachusetts from './District Courts/DistrictCourtMassachusetts';
import DistrictCourtEasternMichigan from './District Courts/DistrictCourtEasternMichigan';
import DistrictCourtWesternMichigan from './District Courts/DistrictCourtWesternMichigan';
import DistrictCourtMinnesota from './District Courts/DistrictCourtMinnesota';
import DistrictCourtEasternMissouri from './District Courts/DistrictCourtEasternMissouri';
import DistrictCourtWesternMissouri from './District Courts/DistrictCourtWesternMissouri';
import DistrictCourtMontana from './District Courts/DistrictCourtMontana';
import DistrictCourtNebraska from './District Courts/DistrictCourtNebraska';
import DistrictCourtNewHampshire from './District Courts/DistrictCourtNewJersey';
import DistrictCourtNewJersey from './District Courts/DistrictCourtNewHampshire';
import DistrictCourtNewMexico from './District Courts/DistrictCourtNewMexico';
import DistrictCourtEasternNewYork from './District Courts/DistrictCourtEasternNewYork';
import DistrictCourtNorthernNewYork from './District Courts/DistrictCourtNorthernNewYork';
import DistrictCourtMiddleNorthCarolina from './District Courts/DistrictCourtMiddleNorthCarolina';
import DistrictCourtNorthDakota from './District Courts/DistrictCourtNorthDakota';
import DistrictCourtNorthernMarianaIslands from './District Courts/DistrictCourtNorthernMarianaIslands';
import DistrictCourtNorthernOhio from './District Courts/DistrictCourtNorthernOhio';
import DistrictCourtSouthernOhio from './District Courts/DistrictCourtSouthernOhio';
import DistrictCourtEasternOklahoma from './District Courts/DistrictCourtEasternOklahoma';
import DistrictCourtWesternOklahoma from './District Courts/DistrictCourtWesternOklahoma';
import DistrictCourtOregon from './District Courts/DistrictCourtOregon';
import DistrictCourtEasternPennsylvania from './District Courts/DistrictCourtEasternPennsylvania';
import DistrictCourtMiddlePennslyvania from './District Courts/DistrictCourtMiddlePennslyvania';
import DistrictCourtWesternPennsylvania from './District Courts/DistrictCourtWesternPennsylvania';
import DistrictCourtPuertoRico from './District Courts/DistrictCourtPuertoRico';
import DistrictCourtRhodeIsland from './District Courts/DistrictCourtRhodeIsland';
import DistrictCourtSouthDakota from './District Courts/DistrictCourtSouthDakota';
import DistrictCourtEasternTennessee from './District Courts/DistrictCourtEasternTennessee';
import DistrictCourtWesternTennessee from './District Courts/DistrictCourtWesternTennessee';
import DistrictCourtMiddleTennessee from './District Courts/DistrictCourtMiddleTennessee';
import DistrictCourtNorthernTexas from './District Courts/DistrictCourtNorthernTexas';
import DistrictCourtSouthernTexas from './District Courts/DistrictCourtSouthernTexas';
import DistrictCourtWesternTexas from './District Courts/DistrictCourtWesternTexas';
import DistrictCourtUtah from './District Courts/DistrictCourtUtah';
import DistrictCourtVirginIslands from './District Courts/DistrictCourtVirginIslands';
import DistrictCourtVermont from './District Courts/DistrictCourtVermont';
import DistrictCourtEasternWashington from './District Courts/DistrictCourtEasternWashington';
import DistrictCourtNorthernWestVirginia from './District Courts/DistrictCourtNorthernWestVirginia';
import DistrictCourtWyoming from './District Courts/DistrictCourtWyoming';


import App from './App';
import Overlay from 'react-overlays/esm/Overlay';

import{ createUSCourtNewsFeed, createUSCourtNewsFeedMobile, createCourtAppeals, 
  saveToCollection, createFullBillFeed, ListItem,
  createCourtAppealsMobile,supremeCourtSearch, Speak, createCourtOfInternationalTrade,
  createCourtAppealsFirstCircuit, createCourtAppealsSecondCircuit, createCourtAppealsThirdCircuit, 
createCourtAppealsFourthCircuit, createCourtAppealsFifthCircuit, createCourtAppealsSixthCircuit,
createCourtAppealsSeventhCircuit, createCourtAppealsEighthCircuit, createCourtAppealsNinthCircuit, 
createCourtAppealsTenthCircuit, createCourtAppealsEleventhCircuit, createCourtAppealsDCCircuit,
createCourtAppealsFederalCircuit, CreateFeedItem, CreateFeedItemCourtAppeals, CreateCourtAppealsCircuit, CreateFeedItemSupremeCourtArguments, CreateFeedItemSupremeCourtOpinions, CreateFeedItemTest, CreateFeedAPI } from './Functions'
import JudicialBranchMobile from './JudicialBranchMobile';
import DesktopNavbar from './DesktopNavbar';
import SupremeCourtArguments from './SupremeCourtArguments';
import SupremeCourtOpinions from './SupremeCourtOpinions';
import GenerateFeed from './GenerateFeed';
import GenerateFeedAudio from './GenerateFeedAudio';
import JusticeDisplayOnPage from './JusticeDisplayOnPage';




var collectionCounter = 0
var carouselInterval = 30000;
var mobileCarouselInterval = 60000;




  

export const JudicalBranch = () =>
 {
   render()
   
   {
  const [show, setShow] = useState(true);

  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [fullscreen, setFullScreen] = useState(true);


  const [fullBillModalIsOpen, setFullBillModalIsOpen] = useState(false)

  const [reportModalIsOpen, setReportModalIsOpen] = useState(false)


  const [whiteHouseFeedModalIsOpen, setWhiteHouseFeedModalIsOpen] = useState(false)
  const [whiteHouseDocsModalIsOpen, setWhiteHouseDocsModalIsOpen] = useState(false)

  
  const [courtOfInternationalTradeModalIsOpen, setCourtOfInternationalTradeModalIsOpen] = useState(false)
  const [courtNewsModalIsOpen, setCourtNewsModalIsOpen] = useState(false)
  const [courtModalIsOpen, setCourtModalIsOpen] = useState(false)
  const [courtOpinionsModalIsOpen, setCourtOpinionsModalIsOpen] = useState(false)
  const [courtAppealsModalIsOpen, setCourtAppealsModalIsOpen] = useState(false)
  const [courtAppealsFirstCircuitModalIsOpen, setCourtAppealsFirstCircuitModalIsOpen] = useState(false)
  const [courtAppealsSecondCircuitModalIsOpen, setCourtAppealsSecondCircuitModalIsOpen] = useState(false)
  const [courtAppealsThirdCircuitModalIsOpen, setCourtAppealsThirdCircuitModalIsOpen] = useState(false)
  const [courtAppealsFourthCircuitModalIsOpen, setCourtAppealsFourthCircuitModalIsOpen] = useState(false)
  const [courtAppealsFifthCircuitModalIsOpen, setCourtAppealsFifthCircuitModalIsOpen] = useState(false)
  const [courtAppealsSixthCircuitModalIsOpen, setCourtAppealsSixthCircuitModalIsOpen] = useState(false)
  const [courtAppealsSeventhCircuitModalIsOpen, setCourtAppealsSeventhCircuitModalIsOpen] = useState(false)
  const [courtAppealsEighthCircuitModalIsOpen, setCourtAppealsEighthCircuitModalIsOpen] = useState(false)
  const [courtAppealsNinthCircuitModalIsOpen, setCourtAppealsNinthCircuitModalIsOpen] = useState(false)
  const [courtAppealsTenthCircuitModalIsOpen, setCourtAppealsTenthCircuitModalIsOpen] = useState(false)
  const [courtAppealsEleventhCircuitModalIsOpen, setCourtAppealsEleventhCircuitModalIsOpen] = useState(false)
  const [courtAppealsDCCircuitModalIsOpen, setCourtAppealsDCCircuitModalIsOpen] = useState(false)
  const [courtAppealsFederalCircuitModalIsOpen, setCourtAppealsFederalCircuitModalIsOpen] = useState(false)
  const [newLawsModalIsOpen, setNewLawsModalIsOpen] = useState(false)

  const [billsEnrolledModalIsOpen, setBillsEnrolledModalIsOpen] = useState(false)

  const [legislativePageBillsEnrolledModalIsOpen, setLegislativePageBillsEnrolledModalIsOpen] = useState(false)


  const [departmentOfStateModalIsOpen, setDepartmentOfStateModalIsOpen] = useState(false)
  const [departmentOfTreasuryModalIsOpen, setDepartmentOfTreasuryModalIsOpen] = useState(false)
  const [departmentOfDefenseModalIsOpen, setDepartmentOfDefenseModalIsOpen] = useState(false)
  const [departmentOfJusticeModalIsOpen, setDepartmentOfJusticeModalIsOpen] = useState(false)
  const [departmentOfTheInteriorModalIsOpen, setDepartmentOfTheInteriorModalIsOpen] = useState(false)
  const [departmentOfAgricultureModalIsOpen, setDepartmentOfAgricultureModalIsOpen] = useState(false)
  const [departmentOfCommerceModalIsOpen, setDepartmentOfCommerceModalIsOpen] = useState(false)
  const [departmentOfLaborModalIsOpen, setDepartmentOfLaborModalIsOpen] = useState(false)
  const [departmentOfHealthAndHumanServicesModalIsOpen, setDepartmentOfHealthAndHumanServicesModalIsOpen] = useState(false)
  const [departmentOfHousingAndUrbanDevelopmentModalIsOpen, setDepartmentOfHousingAndUrbanDevelopmentModalIsOpen] = useState(false)
  const [departmentOfTransportationModalIsOpen, setDepartmentOfTransportationModalIsOpen] = useState(false)
  const [departmentOfEnergyModalIsOpen, setDepartmentOfEnergyModalIsOpen] = useState(false)
  const [departmentOfEducationModalIsOpen, setDepartmentOfEducationModalIsOpen] = useState(false)
  const [departmentOfVeteransAffairsModalIsOpen, setDepartmentOfVeteransAffairsModalIsOpen] = useState(false)
  const [departmentOfHomelandSecurityModalIsOpen, setDepartmentOfHomelandSecurityModalIsOpen] = useState(false)
  const [epaModalIsOpen, setEPAModalIsOpen] = useState(false)
  const [nasaModalIsOpen, setNASAModalIsOpen] = useState(false)
  const [trendingBillsModalIsOpen, setTrendingBillsModalIsOpen] = useState(false)
  const [goaReportsModalIsOpen, setGAOReportsModalIsOpen] = useState(false)




  const [collectionsModalIsOpen, setCollectionsModalIsOpen] = useState(false)
  const [aboutModalIsOpen, setAboutModalIsOpen] = useState(false)



  const [declarationModalIsOpen, setDeclarationModalIsOpen] = useState(false)
  const [constitutionModalIsOpen, setConstitutionModalIsOpen] = useState(false)
  const [billOfRightsnModalIsOpen, setBillOfRightsModalIsOpen] = useState(false)

  const [districtCourt1ModalIsOpen, setDistrictCourt1ModalIsOpen] = useState(false)
  const [districtCourt2ModalIsOpen, setDistrictCourt2ModalIsOpen] = useState(false)
  const [districtCourt3ModalIsOpen, setDistrictCourt3ModalIsOpen] = useState(false)
  const [districtCourt4ModalIsOpen, setDistrictCourt4ModalIsOpen] = useState(false)
  const [districtCourt5ModalIsOpen, setDistrictCourt5ModalIsOpen] = useState(false)
  const [districtCourt6ModalIsOpen, setDistrictCourt6ModalIsOpen] = useState(false)
  const [districtCourt7ModalIsOpen, setDistrictCourt7ModalIsOpen] = useState(false)
  const [districtCourt8ModalIsOpen, setDistrictCourt8ModalIsOpen] = useState(false)
  const [districtCourt9ModalIsOpen, setDistrictCourt9ModalIsOpen] = useState(false)
  const [districtCourt10ModalIsOpen, setDistrictCourt10ModalIsOpen] = useState(false)
  const [districtCourt11ModalIsOpen, setDistrictCourt11ModalIsOpen] = useState(false)
  const [districtCourt12ModalIsOpen, setDistrictCourt12ModalIsOpen] = useState(false)
  const [districtCourt13ModalIsOpen, setDistrictCourt13ModalIsOpen] = useState(false)
  const [districtCourt14ModalIsOpen, setDistrictCourt14ModalIsOpen] = useState(false)
  const [districtCourt15ModalIsOpen, setDistrictCourt15ModalIsOpen] = useState(false)
  const [districtCourt16ModalIsOpen, setDistrictCourt16ModalIsOpen] = useState(false)
  const [districtCourt17ModalIsOpen, setDistrictCourt17ModalIsOpen] = useState(false)
  const [districtCourt18ModalIsOpen, setDistrictCourt18ModalIsOpen] = useState(false)
  const [districtCourt19ModalIsOpen, setDistrictCourt19ModalIsOpen] = useState(false)
  const [districtCourt20ModalIsOpen, setDistrictCourt20ModalIsOpen] = useState(false)
  const [districtCourt21ModalIsOpen, setDistrictCourt21ModalIsOpen] = useState(false)
  const [districtCourt22ModalIsOpen, setDistrictCourt22ModalIsOpen] = useState(false)
  const [districtCourt23ModalIsOpen, setDistrictCourt23ModalIsOpen] = useState(false)
  const [districtCourt24ModalIsOpen, setDistrictCourt24ModalIsOpen] = useState(false);
  const [districtCourt25ModalIsOpen, setDistrictCourt25ModalIsOpen] = useState(false);
  const [districtCourt26ModalIsOpen, setDistrictCourt26ModalIsOpen] = useState(false);
  const [districtCourt27ModalIsOpen, setDistrictCourt27ModalIsOpen] = useState(false);
  const [districtCourt28ModalIsOpen, setDistrictCourt28ModalIsOpen] = useState(false);
  const [districtCourt29ModalIsOpen, setDistrictCourt29ModalIsOpen] = useState(false);
  const [districtCourt30ModalIsOpen, setDistrictCourt30ModalIsOpen] = useState(false);
  const [districtCourt31ModalIsOpen, setDistrictCourt31ModalIsOpen] = useState(false);
  const [districtCourt32ModalIsOpen, setDistrictCourt32ModalIsOpen] = useState(false);
  const [districtCourt33ModalIsOpen, setDistrictCourt33ModalIsOpen] = useState(false);
  const [districtCourt34ModalIsOpen, setDistrictCourt34ModalIsOpen] = useState(false);
  const [districtCourt35ModalIsOpen, setDistrictCourt35ModalIsOpen] = useState(false);
  const [districtCourt36ModalIsOpen, setDistrictCourt36ModalIsOpen] = useState(false);
  const [districtCourt37ModalIsOpen, setDistrictCourt37ModalIsOpen] = useState(false);
  const [districtCourt38ModalIsOpen, setDistrictCourt38ModalIsOpen] = useState(false);
  const [districtCourt39ModalIsOpen, setDistrictCourt39ModalIsOpen] = useState(false);
  const [districtCourt40ModalIsOpen, setDistrictCourt40ModalIsOpen] = useState(false);
  const [districtCourt41ModalIsOpen, setDistrictCourt41ModalIsOpen] = useState(false);
  const [districtCourt42ModalIsOpen, setDistrictCourt42ModalIsOpen] = useState(false);
  const [districtCourt43ModalIsOpen, setDistrictCourt43ModalIsOpen] = useState(false);
  const [districtCourt44ModalIsOpen, setDistrictCourt44ModalIsOpen] = useState(false);
  const [districtCourt45ModalIsOpen, setDistrictCourt45ModalIsOpen] = useState(false);
  const [districtCourt46ModalIsOpen, setDistrictCourt46ModalIsOpen] = useState(false);
  const [districtCourt47ModalIsOpen, setDistrictCourt47ModalIsOpen] = useState(false);
  const [districtCourt48ModalIsOpen, setDistrictCourt48ModalIsOpen] = useState(false);
  const [districtCourt49ModalIsOpen, setDistrictCourt49ModalIsOpen] = useState(false);
  const [districtCourt50ModalIsOpen, setDistrictCourt50ModalIsOpen] = useState(false);
  const [districtCourt51ModalIsOpen, setDistrictCourt51ModalIsOpen] = useState(false);
  const [districtCourt52ModalIsOpen, setDistrictCourt52ModalIsOpen] = useState(false);
  const [districtCourt53ModalIsOpen, setDistrictCourt53ModalIsOpen] = useState(false);
  const [districtCourt54ModalIsOpen, setDistrictCourt54ModalIsOpen] = useState(false);
  const [districtCourt55ModalIsOpen, setDistrictCourt55ModalIsOpen] = useState(false);
  const [districtCourt56ModalIsOpen, setDistrictCourt56ModalIsOpen] = useState(false);
  const [districtCourt57ModalIsOpen, setDistrictCourt57ModalIsOpen] = useState(false);
  const [districtCourt58ModalIsOpen, setDistrictCourt58ModalIsOpen] = useState(false);
  const [districtCourt59ModalIsOpen, setDistrictCourt59ModalIsOpen] = useState(false);
  const [districtCourt60ModalIsOpen, setDistrictCourt60ModalIsOpen] = useState(false);
  const [districtCourt61ModalIsOpen, setDistrictCourt61ModalIsOpen] = useState(false);
  const [districtCourt62ModalIsOpen, setDistrictCourt62ModalIsOpen] = useState(false);
  const [districtCourt63ModalIsOpen, setDistrictCourt63ModalIsOpen] = useState(false);
  const [districtCourt64ModalIsOpen, setDistrictCourt64ModalIsOpen] = useState(false);
  const [districtCourt65ModalIsOpen, setDistrictCourt65ModalIsOpen] = useState(false);
  const [districtCourt66ModalIsOpen, setDistrictCourt66ModalIsOpen] = useState(false);
  const [districtCourt67ModalIsOpen, setDistrictCourt67ModalIsOpen] = useState(false);
  const [districtCourt68ModalIsOpen, setDistrictCourt68ModalIsOpen] = useState(false);
  const [districtCourt69ModalIsOpen, setDistrictCourt69ModalIsOpen] = useState(false);

  const [showBioModal, setBioModal] = useState(false);

  return(
    <>
      <BrowserView>
        <div id='homePage'>

      

        <div id='homebg'>

        <DesktopNavbar/>

        
              {/* <div id='sectionHeading'>       
          
              <h3 id='branchHeading'><ShieldShaded></ShieldShaded>Judicial
             
              <Button size='md' id='navbarAboutButtonDesktop' variant='primary' onClick={(e) => {
                e.preventDefault();
                window.open('https://www.supremecourt.gov//', "_blank")
              }} >supremecourt.gov</Button>

              <Button size='md' id='navbarAboutButtonDesktop' variant='primary' onClick={(e) => {
                e.preventDefault();
                window.open('https://www.supremecourt.gov//', "_blank")
              }} >uscourts.gov</Button>

              <Form id='supremeCourtSearchForm'>
              
              <Form.Group  className="mb-3 d-flex">

              <Form.Control id="congressSearch" placeholder="Search supremecourt.gov"
                      type="text" />
       <Button variant="primary" id='navbarAboutButtonDesktop' onClick={() => supremeCourtSearch()}>
                    Search
                  </Button>
                  </Form.Group>



           
              </Form>
              </h3>
          
            </div> */}


<div style={{ position: 'relative' }}>

<div id='profileImage' style={{ width: '150px', height: '150px', borderRadius: '100%', overflow: 'hidden',  marginLeft: '40px' }}>
<Image id='profileImage-image' src='/judicialBranch.png' />
</div>


<div style={{ position: 'absolute', top: '0', left: '250px', display: 'flex', flexDirection: 'column' }}>
<div style={{ color: 'white', fontSize: '24px', fontWeight: 'bold' }}>

</div>
<div  style={{ color: 'white', fontSize: '20px' }}> 
<span> <h3  >Judicial Branch</h3></span>
<br></br>
<div style={{ color: 'white', fontSize: '20px' }}>
<span> Article III of the Constitution, which establishes the Judicial Branch, leaves Congress significant discretion to determine the shape and structure of the federal judiciary.
Even the number of Supreme Court Justices is left to Congress — at times there have been as few as six, while the current number (nine, with one Chief Justice and eight Associate ...
</span>

<div style={{ color: 'white', fontSize: '18px' }}>

  <span><Link style={{color: '#21cff5'}} onClick={(e) => {
     e.preventDefault();
     window.open("https://www.whitehouse.gov/about-the-white-house/our-government/the-judicial-branch/", "_blank");
   }}>
     https://www.whitehouse.gov/about-the-white-house/our-government/the-judicial-branch/
     </Link></span> 
<br></br>
<Button id='whDocsViewMoreButton' onClick={() =>  setBioModal(true)}>  <Files size={20}></Files> Read More</Button>

</div>
</div>

</div>
</div>


</div>
<br></br>
<br></br><br></br>
<Modal id='fullBillModal'  size="xl" aria-labelledby="contained-modal-title-vcenter" show={showBioModal} onHide={() => setBioModal(false)}>
         <Modal.Header id='billModalHeader' closeButton><h3> The Judicial Branch</h3></Modal.Header>
       
         <div id='fullBillFeed' >
      
         <p>Where the executive and legislative branches are elected by the people, members of the Judicial Branch are appointed by the President and confirmed by the Senate.</p>

<p>Article III of the Constitution, which establishes the Judicial Branch, leaves Congress significant discretion to determine the shape and structure of the federal judiciary. Even the number of Supreme Court Justices is left to Congress — at times there have been as few as six, while the current number (nine, with one Chief Justice and eight Associate Justices) has only been in place since 1869. The Constitution also grants Congress the power to establish courts inferior to the Supreme Court, and to that end Congress has established the United States district courts, which try most federal cases, and 13 United States courts of appeals, which review appealed district court cases.</p>

<p>Federal judges can only be removed through impeachment by the House of Representatives and conviction in the Senate. Judges and Justices serve no fixed term — they serve until their death, retirement, or conviction by the Senate. By design, this insulates them from the temporary passions of the public, and allows them to apply the law with only justice in mind, and not electoral or political concerns.</p>

<p>Generally, Congress determines the jurisdiction of the federal courts. In some cases, however — such as in the example of a dispute between two or more U.S. states — the Constitution grants the Supreme Court original jurisdiction, an authority that cannot be stripped by Congress.</p>

<p>The courts only try actual cases and controversies — a party must show that it has been harmed in order to bring suit in court. This means that the courts do not issue advisory opinions on the constitutionality of laws or the legality of actions if the ruling would have no practical effect. Cases brought before the judiciary typically proceed from district court to appellate court and may even end at the Supreme Court, although the Supreme Court hears comparatively few cases each year.</p>

<p>Federal courts enjoy the sole power to interpret the law, determine the constitutionality of the law, and apply it to individual cases. The courts, like Congress, can compel the production of evidence and testimony through the use of a subpoena. The inferior courts are constrained by the decisions of the Supreme Court — once the Supreme Court interprets a law, inferior courts must apply the Supreme Court’s interpretation to the facts of a particular case.</p>

<p><strong>The Supreme Court of the United States</strong></p>

<p>The Supreme Court of the United States is the highest court in the land and the only part of the federal judiciary specifically required by the Constitution.</p>

<p>The Constitution does not stipulate the number of Supreme Court Justices; the number is set instead by Congress. There have been as few as six, but since 1869 there have been nine Justices, including one Chief Justice. All Justices are nominated by the President, confirmed by the Senate, and hold their offices under life tenure. Since Justices do not have to run or campaign for re-election, they are thought to be insulated from political pressure when deciding cases. Justices may remain in office until they resign, pass away, or are impeached and convicted by Congress.</p>

<p>The Court’s caseload is almost entirely appellate in nature, and the Court’s decisions cannot be appealed to any authority, as it is the final judicial arbiter in the United States on matters of federal law. However, the Court may consider appeals from the highest state courts or from federal appellate courts. The Court also has original jurisdiction over limited types of cases, including those involving ambassadors and other diplomats, and in cases between states.</p>

<p>Although the Supreme Court may hear an appeal on any question of law provided it has jurisdiction, it usually does not hold trials. Instead, the Court’s task is to interpret the meaning of a law, to decide whether a law is relevant to a particular set of facts, or to rule on how a law should be applied. Lower courts are obligated to follow the precedent set by the Supreme Court when rendering decisions.</p>

<p>In almost all instances, the Supreme Court does not hear appeals as a matter of right; instead, parties must petition the Court for a writ of certiorari. It is the Court’s custom and practice to “grant cert” if four of the nine Justices decide that they should hear the case. Of the approximately 7,500 requests for certiorari filed each year, the Court usually grants cert to fewer than 150. These are typically cases that the Court considers sufficiently important to require their review; a common example is the occasion when two or more of the federal courts of appeals have ruled differently on the same question of federal law.</p>

<p>If the Court grants certiorari, Justices accept legal briefs from the parties to the case, as well as from amicus curiae, or “friends of the court.” These can include industry trade groups, academics, or even the U.S. government itself. Before issuing a ruling, the Supreme Court usually hears oral arguments, where the various parties to the suit present their arguments and the Justices ask them questions. If the case involves the federal government, the Solicitor General of the United States presents arguments on behalf of the United States. The Justices then hold private conferences, make their decision, and (often after a period of several months) issue the Court’s opinion, along with any dissenting arguments that may have been written.</p>

<p><strong>The Judicial Process</strong></p>

<p>Article III of the Constitution of the United States guarantees that every person accused of wrongdoing has the right to a fair trial before a competent judge and a jury of one’s peers.</p>

<p>The Fourth, Fifth, Sixth, and Eighth Amendments to the Constitution provide additional protections for those accused of a crime. These include:</p>

<ul>
  <li>A guarantee that no person shall be deprived of life, liberty, or property without the due process of law</li>
  <li>Protection against being tried for the same crime twice (“double jeopardy”)</li>
  <li>The right to a speedy trial by an impartial jury</li>
  <li>The right to cross-examine witnesses, and to call witnesses to support their case</li>
  <li>The right to legal representation</li>
  <li>The right to avoid self-incrimination</li>
  <li>Protection from excessive bail, excessive fines, and cruel and unusual punishments</li>
</ul>

<p>Criminal proceedings can be conducted under either state or federal law, depending on the nature and extent of the crime. A criminal legal procedure typically begins with an arrest by a law enforcement officer. If a grand jury chooses to deliver an indictment, the accused will appear before a judge and be formally charged with a crime, at which time he or she may enter a plea.</p>

<p>The defendant is given time to review all the evidence in the case and to build a legal argument. Then, the case is brought to trial and decided by a jury. If the defendant is determined to be not guilty of the crime, the charges are dismissed. Otherwise, the judge determines the sentence, which can include prison time, a fine, or even execution.</p>

<p>Civil cases are similar to criminal ones, but instead of arbitrating between the state and a person or organization, they deal with disputes between individuals or organizations. In civil cases, if a party believes that it has been wronged, it can file suit in civil court to attempt to have that wrong remedied through an order to cease and desist, alter behavior, or award monetary damages. After the suit is filed and evidence is gathered and presented by both sides, a trial proceeds as in a criminal case. If the parties involved waive their right to a jury trial, the case can be decided by a judge; otherwise, the case is decided and damages awarded by a jury.</p>

<p>After a criminal or civil case is tried, it may be appealed to a higher court — a federal court of appeals or state appellate court. A litigant who files an appeal, known as an “appellant,” must show that the trial court or administrative agency made a legal error that affected the outcome of the case. An appellate court makes its decision based on the record of the case established by the trial court or agency — it does not receive additional evidence or hear witnesses. It may also review the factual findings of the trial court or agency, but typically may only overturn a trial outcome on factual grounds if the findings were “clearly erroneous.” If a defendant is found not guilty in a criminal proceeding, he or she cannot be retried on the same set of facts.</p>

<p>Federal appeals are decided by panels of three judges. The appellant presents legal arguments to the panel, in a written document called a “brief.” In the brief, the appellant tries to persuade the judges that the trial court made an error, and that the lower decision should be reversed. On the other hand, the party defending against the appeal, known as the “appellee” or “respondent,” tries in its brief to show why the trial court decision was correct, or why any errors made by the trial court are not significant enough to affect the outcome of the case.</p>

<p>The court of appeals usually has the final word in the case, unless it sends the case back to the trial court for additional proceedings. In some cases the decision may be reviewed en banc — that is, by a larger group of judges of the court of appeals for the circuit.</p>

<p>A litigant who loses in a federal court of appeals, or in the highest court of a state, may file a petition for a “writ of certiorari,” which is a document asking the U.S. Supreme Court to review the case. The Supreme Court, however, is not obligated to grant review. The Court typically will agree to hear a case only when it involves a new and important legal principle, or when two or more federal appellate courts have interpreted a law differently. (There are also special circumstances in which the Supreme Court is required by law to hear an appeal.) When the Supreme Court hears a case, the parties are required to file written briefs and the Court may hear oral argument.</p>

<br></br>
<Link style={{color: '#21cff5'}} onClick={(e) => {
     e.preventDefault();
     window.open("https://www.whitehouse.gov/about-the-white-house/our-government/the-judicial-branch/", "_blank");
   }}>
     https://www.whitehouse.gov/about-the-white-house/our-government/the-judicial-branch/
     </Link>
 </div>

       </Modal>
            <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferTwo'>
                   
                    <Row>

                      <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>

                        <h4 id='feedHeadingBillsEnrolled'><b>Justices</b></h4>
                          
                       
                          <JusticeDisplayOnPage/>
                        
                        
                  
                        </div>
                      </Col>

                      <Col id='legislativeColRight'>
                        <div id='columnRightPadding'>

           
                    
                        </div>
                      </Col>


                    </Row>


                  </Container>
                </Container>
                </div>
                   
              <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferThree'>
                    
                    <Row>

                      <Col id='legislativeColLeft'>
                        <div id='columnLefttPadding'>
                          <h4 id='feedHeading'><b>U.S. Court News</b></h4>
                          <div id='courtNews'></div>

                           
{CreateFeedItem('https://www.uscourts.gov/news/rss', 'courtNews', 'U.S. Court News', 'Judicial', 'N/A', FullUSCourtNews)}

                  
                          <Modal id='fullUSCourtModal' show={courtNewsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCourtNewsModalIsOpen(false)}>

                            <Modal.Header id='fullUSCourtNewsModalHeader' closeButton><h3>U.S. Court News</h3></Modal.Header>
                            <FullUSCourtNews />

                          </Modal>

                        </div>
                      </Col>

                      <Col id='legislativeColRight'>
                        <div id='columnRightPadding'>

                          <h4 id='departmentOfStateHeading'><b>U.S. Court of Appeals</b></h4>

                          <div id='courtAppeals'></div>
                          
                          {CreateFeedItemCourtAppeals('https://www.govinfo.gov/rss/uscourts-ca13.xml', 'courtAppeals', 'U.S. Court of Appeals', 'Judicial', 'N/A', CourtAppeals)}
                     

                          <Modal id='departmentOfStateModal' show={courtAppealsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCourtAppealsModalIsOpen(false)}>
                            <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>U.S. Court of Appeals</h3></Modal.Header>
                            <CourtAppeals />
                          </Modal>




                        </div>
                      </Col>





                    </Row>



                  </Container>
                </Container>
              </div>

              <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferThree'>
                    

                    <Row>


                      <Col id='legislativeColLeft'>
                        <div id='columnLefttPadding'>
                          <h4 id='feedHeading'><b>Supreme Court Opinions</b></h4>
                   

                          <div id='courtOpinions'></div>
                          {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=supreme_court_opinions&order_by=created_at', 'courtOpinions', 
                            'Supreme Court Opinions', 'Judicial', 
                            'N/A', 'Document', GenerateFeed)}
                        </div>
                      </Col>







                      <Col id='legislativeColRight'>
                        <div id='columnRightPadding'>
                          <h4 id='feedHeading'><b>Supreme Court Arguments</b></h4>

                          <div id='courtArguments'></div>
                          {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=supreme_court_arguments&order_by=created_at', 'courtArguments', 
                            'Supreme Court Arguments', 'Judicial', 
                            'N/A', 'Document', GenerateFeedAudio)}

                        </div>
                      </Col>






                    </Row>





                  </Container>
                </Container>
              </div>

              <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferThree'>
                    
                    <Row>

                      <Col id='legislativeColLeft'>
                        <div id='columnLefttPadding'>
                          <h4 id='feedHeading'><b> U.S. Court of International Trade</b></h4>
                        <div  id='cit'></div>
                   
                        {CreateFeedItem('https://www.govinfo.gov/rss/uscourts-cit.xml', 'cit', 'U.S. Court of International Trade', 'Judicial', 'N/A', CourtOfInternationalTrade)}
                         
                          <Modal id='fullUSCourtModal' show={courtOfInternationalTradeModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCourtOfInternationalTradeModalIsOpen(false)}>

                            <Modal.Header id='fullUSCourtNewsModalHeader' closeButton><h3>U.S. Court of International Trade</h3></Modal.Header>
                            <CourtOfInternationalTrade />

                          </Modal>

                        </div>
                      </Col>

                      <Col id='legislativeColRight'>
                        <div id='columnRightPadding'>

                          <h4 id='departmentOfStateHeading'><b>Court of Appeals for the First Circuit</b></h4>
{/*1st */}
                        <div id='appealsFC'></div>
                
    
                        {CreateCourtAppealsCircuit('https://www.govinfo.gov/rss/uscourts-ca1.xml', 'appealsFC', 'Court of Appeals for the First Circuit', 'Judicial', 'N/A', CourtAppealsFirstCircuit)}
                         

                          <Modal id='departmentOfStateModal' show={courtAppealsFirstCircuitModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCourtAppealsFirstCircuitModalIsOpen(false)}>
                            <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>Court of Appeals for the First Circuit</h3></Modal.Header>
                            <CourtAppealsFirstCircuit />
                          </Modal>




                        </div>
                      </Col>





                    </Row>



                  </Container>
                </Container>
              </div>

              <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferThree'>
                    
                    <Row>

                      <Col id='legislativeColLeft'>
                        <div id='columnLefttPadding'>
                          <h4 id='feedHeading'><b> Court of Appeals for the Second Circuit</b></h4>
                        <div  id='appealsSC'></div>
                  
                     
                        {CreateCourtAppealsCircuit('https://www.govinfo.gov/rss/uscourts-ca2.xml', 'appealsSC', 'Court of Appeals for the Second Circuit', 'Judicial', 'N/A', CourtAppealsSecondCircuit)}
                         
                          <Modal id='fullUSCourtModal' show={courtAppealsSecondCircuitModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCourtAppealsSecondCircuitModalIsOpen(false)}>

                            <Modal.Header id='fullUSCourtNewsModalHeader' closeButton><h3>Court of Appeals for the Second Circuit</h3></Modal.Header>
                            <CourtAppealsSecondCircuit />

                          </Modal>

                        </div>
                      </Col>

                      <Col id='legislativeColRight'>
                        <div id='columnRightPadding'>

                          <h4 id='departmentOfStateHeading'><b>Court of Appeals for the Third Circuit</b></h4>

                        <div id='appealsTC'></div>
                    
                       
                        {CreateCourtAppealsCircuit('https://www.govinfo.gov/rss/uscourts-ca3.xml', 'appealsTC', 'Court of Appeals for the Third Circuit', 'Judicial', 'N/A', CourtAppealsThirdCircuit)}
                         

                          <Modal id='departmentOfStateModal' show={courtAppealsThirdCircuitModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCourtAppealsThirdCircuitModalIsOpen(false)}>
                            <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>Court of Appeals for the Third Circuit</h3></Modal.Header>
                            <CourtAppealsThirdCircuit />
                          </Modal>




                        </div>
                      </Col>





                    </Row>



                  </Container>
                </Container>
              </div>


              <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferThree'>
                    
                    <Row>

                      <Col id='legislativeColLeft'>
                        <div id='columnLefttPadding'>
                          <h4 id='feedHeading'><b> Court of Appeals for the Fourth Circuit</b></h4>
                        <div  id='appealsFourthC'></div>
                    
                        
                        {CreateCourtAppealsCircuit('https://www.govinfo.gov/rss/uscourts-ca4.xml', 'appealsFourthC', 'Court of Appeals for the Fourth Circuit', 'Judicial', 'N/A', CourtAppealsFourthCircuit)}
                         
                          <Modal id='fullUSCourtModal' show={courtAppealsFourthCircuitModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCourtAppealsFourthCircuitModalIsOpen(false)}>

                            <Modal.Header id='fullUSCourtNewsModalHeader' closeButton><h3>Court of Appeals for the Fourth Circuit</h3></Modal.Header>
                            <CourtAppealsFourthCircuit />

                          </Modal>

                        </div>
                      </Col>

                      <Col id='legislativeColRight'>
                        <div id='columnRightPadding'>

                          <h4 id='departmentOfStateHeading'><b>Court of Appeals for the Fifth Circuit</b></h4>

                        <div id='appealsFifthC'></div>
                       
                         
                        {CreateCourtAppealsCircuit('https://www.govinfo.gov/rss/uscourts-ca5.xml', 'appealsFifthC', 'Court of Appeals for the Fifth Circuit', 'Judicial', 'N/A', CourtAppealsFifthCircuit)}
                         
                          <Modal id='departmentOfStateModal' show={courtAppealsFifthCircuitModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCourtAppealsFifthCircuitModalIsOpen(false)}>
                            <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>Court of Appeals for the Fifth Circuit</h3></Modal.Header>
                            <CourtAppealsFifthCircuit />
                          </Modal>




                        </div>
                      </Col>





                    </Row>



                  </Container>
                </Container>
              </div>

              <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferThree'>
                    
                    <Row>

                      <Col id='legislativeColLeft'>
                        <div id='columnLefttPadding'>
                          <h4 id='feedHeading'><b> Court of Appeals for the Sixth Circuit</b></h4>
                        <div  id='appealsSixthC'></div>
                  
                       
                        {CreateCourtAppealsCircuit('https://www.govinfo.gov/rss/uscourts-ca6.xml', 'appealsSixthC', 'Court of Appeals for the Sixth Circuit', 'Judicial', 'N/A', CourtAppealsSixthCircuit)}
                         
                          <Modal id='fullUSCourtModal' show={courtAppealsSixthCircuitModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCourtAppealsSixthCircuitModalIsOpen(false)}>

                            <Modal.Header id='fullUSCourtNewsModalHeader' closeButton><h3>Court of Appeals for the Sixth Circuit</h3></Modal.Header>
                            <CourtAppealsSixthCircuit />

                          </Modal>

                        </div>
                      </Col>

                      <Col id='legislativeColRight'>
                        <div id='columnRightPadding'>

                          <h4 id='departmentOfStateHeading'><b>Court of Appeals for the Seventh Circuit</b></h4>

                        <div id='appealsSeventhC'></div>
                   
                      
                        {CreateCourtAppealsCircuit('https://www.govinfo.gov/rss/uscourts-ca7.xml', 'appealsSeventhC', 'Court of Appeals for the Seventh Circuit', 'Judicial', 'N/A', CourtAppealsSeventhCircuit)}
                         

                          <Modal id='departmentOfStateModal' show={courtAppealsSeventhCircuitModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCourtAppealsSeventhCircuitModalIsOpen(false)}>
                            <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>Court of Appeals for the Seventh Circuit</h3></Modal.Header>
                            <CourtAppealsSeventhCircuit />
                          </Modal>




                        </div>
                      </Col>





                    </Row>



                  </Container>
                </Container>
              </div>

              <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferThree'>
                    
                    <Row>

                      <Col id='legislativeColLeft'>
                        <div id='columnLefttPadding'>
                          <h4 id='feedHeading'><b> Court of Appeals for the Eighth Circuit</b></h4>
                        <div  id='appealsEC'></div>
                     
                        {CreateCourtAppealsCircuit('https://www.govinfo.gov/rss/uscourts-ca8.xml', 'appealsEC', 'Court of Appeals for the Eighth Circuit', 'Judicial', 'N/A', CourtAppealsEighthCircuit)}
                         
                          <Modal id='fullUSCourtModal' show={courtAppealsEighthCircuitModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCourtAppealsEighthCircuitModalIsOpen(false)}>

                            <Modal.Header id='fullUSCourtNewsModalHeader' closeButton><h3>Court of Appeals for the Eighth Circuit</h3></Modal.Header>
                            <CourtAppealsEighthCircuit />

                          </Modal>

                        </div>
                      </Col>

                      <Col id='legislativeColRight'>
                        <div id='columnRightPadding'>

                          <h4 id='departmentOfStateHeading'><b>Court of Appeals for the Ninth Circuit</b></h4>

                        <div id='appealsNC'></div>
                       
                      
                        {CreateCourtAppealsCircuit('https://www.govinfo.gov/rss/uscourts-ca9.xml', 'appealsNC', 'Court of Appeals for the Ninth Circuit', 'Judicial', 'N/A', CourtAppealsNinthCircuit)}
                         

                          <Modal id='departmentOfStateModal' show={courtAppealsNinthCircuitModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCourtAppealsNinthCircuitModalIsOpen(false)}>
                            <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>Court of Appeals for the Ninth Circuit</h3></Modal.Header>
                            <CourtAppealsNinthCircuit />
                          </Modal>




                        </div>
                      </Col>





                    </Row>



                  </Container>
                </Container>
              </div>

              <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferThree'>
                    
                    <Row>

                      <Col id='legislativeColLeft'>
                        <div id='columnLefttPadding'>
                          <h4 id='feedHeading'><b> Court of Appeals for the Tenth Circuit</b></h4>
                        <div  id='appealsTenthC'></div>
                       
                         
                        {CreateCourtAppealsCircuit('https://www.govinfo.gov/rss/uscourts-ca10.xml', 'appealsTenthC', 'Court of Appeals for the Tenth Circuit', 'Judicial', 'N/A', CourtAppealsTenthCircuit)}
                         
                         
                          <Modal id='fullUSCourtModal' show={courtAppealsTenthCircuitModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCourtAppealsTenthCircuitModalIsOpen(false)}>

                            <Modal.Header id='fullUSCourtNewsModalHeader' closeButton><h3>Court of Appeals for the Tenth Circuit</h3></Modal.Header>
                            <CourtAppealsTenthCircuit />

                          </Modal>

                        </div>
                      </Col>

                      <Col id='legislativeColRight'>
                        <div id='columnRightPadding'>

                          <h4 id='departmentOfStateHeading'><b>Court of Appeals for the Eleventh Circuit</b></h4>

                        <div id='appealsEleventhC'></div>
               
                        {CreateCourtAppealsCircuit('https://www.govinfo.gov/rss/uscourts-ca11.xml', 'appealsEleventhC', 'Court of Appeals for the Eleventh Circuit', 'Judicial', 'N/A', CourtAppealsEleventhCircuit)}
                         

                          <Modal id='departmentOfStateModal' show={courtAppealsEleventhCircuitModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCourtAppealsEleventhCircuitModalIsOpen(false)}>
                            <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>Court of Appeals for the Eleventh Circuit</h3></Modal.Header>
                            <CourtAppealsEleventhCircuit />
                          </Modal>




                        </div>
                      </Col>





                    </Row>



                  </Container>
                </Container>
              </div>

              <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferThree'>
                    
                    <Row>

                      <Col id='legislativeColLeft'>
                        <div id='columnLefttPadding'>
                          <h4 id='feedHeading'><b> Court of Appeals for the District of Columbia Circuit</b></h4>
                        <div  id='appealsDC'></div>
                       
                  
                        {CreateCourtAppealsCircuit('https://www.govinfo.gov/rss/uscourts-cadc.xml', 'appealsDC', 'U.S. Court of International Trade', 'Judicial', 'N/A', CourtAppealsDCCircuit)}
                         
                          <Modal id='fullUSCourtModal' show={courtAppealsDCCircuitModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCourtAppealsDCCircuitModalIsOpen(false)}>

                            <Modal.Header id='fullUSCourtNewsModalHeader' closeButton><h3>Court of Appeals for the District of Columbia Circuit</h3></Modal.Header>
                            <CourtAppealsDCCircuit />

                          </Modal>

                        </div>
                      </Col>

                      <Col id='legislativeColRight'>
                        <div id='columnRightPadding'>

                          <h4 id='departmentOfStateHeading'><b>Court of Appeals for the Federal Circuit</b></h4>

                        <div id='appealsFederalC'></div>
                  
                          
                        {CreateCourtAppealsCircuit('https://www.govinfo.gov/rss/uscourts-ca13.xml', 'appealsFederalC', 'Court of Appeals for the Federal Circuit', 'Judicial', 'N/A', CourtAppealsFederalCircuit)}
                         

                          <Modal id='departmentOfStateModal' show={courtAppealsFederalCircuitModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCourtAppealsFederalCircuitModalIsOpen(false)}>
                            <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>Court of Appeals for the Federal Circuit</h3></Modal.Header>
                            <CourtAppealsFederalCircuit />
                          </Modal>




                        </div>
                      </Col>





                    </Row>



                  </Container>
                </Container>
              </div>

              <div id='branchPage'>
<Container class='bgcolor' >
  <Container id='carouselBufferThree'>
    
    <Row>


<Col>
 
<h4 id='feedHeading' onClick={() => setDistrictCourt1ModalIsOpen(true)}><b>District Court Northern District of Alabama</b></h4>




  <div  id='northernAlabama'></div>
      
 
      {CreateCourtAppealsCircuit('https://www.govinfo.gov/rss/uscourts-alnd.xml', 'northernAlabama', 'District Court Northern District of Alabama', 'Judicial', 'N/A', DistrictCourtNorthernAlabama)}
       
</Col>




<Modal id='departmentOfStateModal' show={districtCourt1ModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDistrictCourt1ModalIsOpen(false)}>
<Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>District Court Northern District of Alabama</h3></Modal.Header>
<DistrictCourtNorthernAlabama/>
</Modal>


<Col>

<h4 id='feedHeading' onClick={() => setDistrictCourt2ModalIsOpen(true)}><b>District Court Southern District of Alabama</b></h4>




  <div  id='southernAlabama'></div>
      
 
      {CreateCourtAppealsCircuit('https://www.govinfo.gov/rss/uscourts-alsd.xml', 'southernAlabama', 'District Court Southern District of Alabama', 'Judicial', 'N/A', DistrictCourtSouthernAlabama)}
       
</Col>

</Row>
</Container>
</Container>
</div>



<Modal id='departmentOfStateModal' show={districtCourt2ModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDistrictCourt2ModalIsOpen(false)}>
<Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>District Court Southern District of Alabama</h3></Modal.Header>
<DistrictCourtSouthernAlabama/>
</Modal>

<div id='branchPage'>
<Container class='bgcolor' >
  <Container id='carouselBufferThree'>
    
    <Row>

<Col>

<h4 id='feedHeading' onClick={() => setDistrictCourt3ModalIsOpen(true)}><b>District Court Middle District of Alabama</b></h4>




  <div  id='middleAlabama'></div>
      
 
      {CreateCourtAppealsCircuit('https://www.govinfo.gov/rss/uscourts-almd.xml', 'middleAlabama', 'District Court Middle District of Alabama', 'Judicial', 'N/A', DistrictCourtMiddleAlabama)}
       
</Col>




<Modal id='departmentOfStateModal' show={districtCourt3ModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDistrictCourt3ModalIsOpen(false)}>
<Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>District Court Middle District of Alabama</h3></Modal.Header>
<DistrictCourtMiddleAlabama/>
</Modal>

<Col>

<h4 id='feedHeading' onClick={() => setDistrictCourt4ModalIsOpen(true)}><b>District Court District of Alaska</b></h4>




  <div  id='dAlaska'></div>
      
 
      {CreateCourtAppealsCircuit('https://www.govinfo.gov/rss/uscourts-akd.xml', 'dAlaska', 'District Court District of Alaska', 'Judicial', 'N/A', DistrictCourtAlaska)}
       
</Col>

</Row>
</Container>
</Container>
</div>


<Modal id='departmentOfStateModal' show={districtCourt4ModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDistrictCourt4ModalIsOpen(false)}>
<Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>District Court District of Alaska</h3></Modal.Header>
<DistrictCourtAlaska/>
</Modal>

<div id='branchPage'>
<Container class='bgcolor' >
  <Container id='carouselBufferThree'>
    
    <Row>

<Col>

<h4 id='feedHeading' onClick={() => setDistrictCourt5ModalIsOpen(true)}><b>District Court District of Arizona</b></h4>




  <div  id='dArizona'></div>
      
 
      {CreateCourtAppealsCircuit('https://www.govinfo.gov/rss/uscourts-azd.xml', 'dArizona', 'District Court District of Arizona', 'Judicial', 'N/A', DistrictCourtArizona)}
       
</Col>




<Modal id='departmentOfStateModal' show={districtCourt5ModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDistrictCourt5ModalIsOpen(false)}>
<Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>District Court District of Arizona</h3></Modal.Header>
<DistrictCourtArizona/>
</Modal>


<Col>

<h4 id='feedHeading' onClick={() => setDistrictCourt6ModalIsOpen(true)}><b> District Court Eastern District of Arkansas</b></h4>




  <div  id='eArkansas'></div>
      
 
      {CreateCourtAppealsCircuit('https://www.govinfo.gov/rss/uscourts-ared.xml', 'eArkansas', 'District Court Eastern District of Arkansas', 'Judicial', 'N/A', DistrictCourtEasternArkansas)}
       
</Col>


</Row>
</Container>
</Container>
</div>

<Modal id='departmentOfStateModal' show={districtCourt6ModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDistrictCourt6ModalIsOpen(false)}>
<Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>District Court Eastern District of Arkansas</h3></Modal.Header>
<DistrictCourtEasternArkansas/>
</Modal>

<div id='branchPage'>
<Container class='bgcolor' >
  <Container id='carouselBufferThree'>
    
    <Row>

<Col>

<h4 id='feedHeading' onClick={() => setDistrictCourt7ModalIsOpen(true)}><b> District Court Eastern District of California</b></h4>




  <div  id='eCali'></div>
      
 
      {CreateCourtAppealsCircuit('https://www.govinfo.gov/rss/uscourts-caed.xml', 'eCali', 'District Court Eastern District of California', 'Judicial', 'N/A', DistrictCourtEasternCalifornia)}
       
</Col>




<Modal id='departmentOfStateModal' show={districtCourt7ModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDistrictCourt7ModalIsOpen(false)}>
<Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>District Court Eastern District of California</h3></Modal.Header>
<DistrictCourtEasternCalifornia/>
</Modal>

<Col>

<h4 id='feedHeading' onClick={() => setDistrictCourt8ModalIsOpen(true)}><b> District Court Northern District of California</b></h4>




  <div  id='nCali'></div>
      
 
      {CreateCourtAppealsCircuit('https://www.govinfo.gov/rss/uscourts-cand.xml', 'nCali', 'District Court Northern District of California', 'Judicial', 'N/A', DistrictCourtNorthernCalifornia)}
       
</Col>

</Row>
</Container>
</Container>
</div>


<Modal id='departmentOfStateModal' show={districtCourt8ModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDistrictCourt8ModalIsOpen(false)}>
<Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>District Court Northern District of California</h3></Modal.Header>
<DistrictCourtNorthernCalifornia/>
</Modal>

<div id='branchPage'>
<Container class='bgcolor' >
  <Container id='carouselBufferThree'>
    
    <Row>

<Col>

<h4 id='feedHeading' onClick={() => setDistrictCourt9ModalIsOpen(true)}><b> District Court Southern District of California</b></h4>




  <div  id='sCali'></div>
      
 
      {CreateCourtAppealsCircuit('https://www.govinfo.gov/rss/uscourts-casd.xml', 'sCali', 'District Court Southern District of California', 'Judicial', 'N/A', DistrictCourtSouthernCalifornia)}
       
</Col>




<Modal id='departmentOfStateModal' show={districtCourt9ModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDistrictCourt9ModalIsOpen(false)}>
<Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>District Court Southern District of California</h3></Modal.Header>
<DistrictCourtSouthernCalifornia/>
</Modal>


<Col>

<h4 id='feedHeading' onClick={() => setDistrictCourt10ModalIsOpen(true)}><b> District Court District of Colorado</b></h4>




  <div  id='colo'></div>
      
 
      {CreateCourtAppealsCircuit('https://www.govinfo.gov/rss/uscourts-cod.xml', 'colo', 'District Court District of Colorado', 'Judicial', 'N/A', DistrictCourtColrado)}
       
</Col>

</Row>
</Container>
</Container>
</div>


<Modal id='departmentOfStateModal' show={districtCourt10ModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDistrictCourt10ModalIsOpen(false)}>
<Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>District Court District of Colorado</h3></Modal.Header>
<DistrictCourtColrado/>
</Modal>

<div id='branchPage'>
<Container class='bgcolor' >
  <Container id='carouselBufferThree'>
    
    <Row>

<Col>

<h4 id='feedHeading' onClick={() => setDistrictCourt11ModalIsOpen(true)}><b> District Court District of Connecticut</b></h4>




  <div  id='ct'></div>
      
 
      {CreateCourtAppealsCircuit('https://www.govinfo.gov/rss/uscourts-ctd.xml', 'ct', 'District Court District of Connecticut', 'Judicial', 'N/A', DistrictCourtConnecticut)}
       
</Col>




<Modal id='departmentOfStateModal' show={districtCourt11ModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDistrictCourt11ModalIsOpen(false)}>
<Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>District Court District of Connecicut</h3></Modal.Header>
<DistrictCourtConnecticut/>
</Modal>

<Col>

<h4 id='feedHeading' onClick={() => setDistrictCourt12ModalIsOpen(true)}><b> District Court District of Columbia</b></h4>




  <div  id='dcd'></div>
      
 
      {CreateCourtAppealsCircuit('https://www.govinfo.gov/rss/uscourts-dcd.xml', 'dcd', 'District Court District of Columbia', 'Judicial', 'N/A', DistrictCourtColumbia)}
       
</Col>

</Row>
</Container>
</Container>
</div>


<Modal id='departmentOfStateModal' show={districtCourt12ModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDistrictCourt12ModalIsOpen(false)}>
<Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>District Court District of Columbia</h3></Modal.Header>
<DistrictCourtColumbia/>
</Modal>

<div id='branchPage'>
<Container class='bgcolor' >
  <Container id='carouselBufferThree'>
    
    <Row>

<Col>

<h4 id='feedHeading' onClick={() => setDistrictCourt13ModalIsOpen(true)}><b> District Court District of Delaware</b></h4>




  <div  id='ded'></div>
      
 
      {CreateCourtAppealsCircuit('https://www.govinfo.gov/rss/uscourts-ded.xml', 'ded', 'District Court District of Delaware', 'Judicial', 'N/A', DistrictCourtDelaware)}
       
</Col>

<Col>

<h4 id='feedHeading' onClick={() => setDistrictCourt14ModalIsOpen(true)}><b> District Court Southern District of Florida</b></h4>




  <div  id='flsd'></div>
      
 
      {CreateCourtAppealsCircuit('https://www.govinfo.gov/rss/uscourts-flsd.xml', 'flsd', 'District Court Southern District of Florida', 'Judicial', 'N/A', DistrictCourtSouthernFlorida)}
       
</Col>

</Row>
</Container>
</Container>
</div>


<Modal id='departmentOfStateModal' show={districtCourt13ModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDistrictCourt13ModalIsOpen(false)}>
<Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>District Court District of Delaware</h3></Modal.Header>
<DistrictCourtDelaware/>
</Modal>

<div id='branchPage'>
<Container class='bgcolor' >
  <Container id='carouselBufferThree'>
    
    <Row>


    <Col>

<h4 id='feedHeading' onClick={() => setDistrictCourt15ModalIsOpen(true)}><b> District Court Northern District of Florida</b></h4>




  <div  id='flnd'></div>
      
 
      {CreateCourtAppealsCircuit('https://www.govinfo.gov/rss/uscourts-flsd.xml', 'flnd', 'District Court Northern District of Florida', 'Judicial', 'N/A', DistrictCourtNorthernFlorida)}
       
</Col>

<Col>

<h4 id='feedHeading' onClick={() => setDistrictCourt16ModalIsOpen(true)}><b> District Court Middle District of Georgia</b></h4>




  <div  id='gamd'></div>
      
 
      {CreateCourtAppealsCircuit('https://www.govinfo.gov/rss/uscourts-gamd.xml', 'gamd', 'District Court Middle District of Georgia', 'Judicial', 'N/A', DistrictCourtMiddleGeorgia)}
       
</Col>


<Modal id='departmentOfStateModal' show={districtCourt14ModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDistrictCourt14ModalIsOpen(false)}>
<Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>District Court Southern District of Florida</h3></Modal.Header>
<DistrictCourtSouthernFlorida/>
</Modal>



</Row>
</Container>
</Container>
</div>


<Modal id='departmentOfStateModal' show={districtCourt15ModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDistrictCourt15ModalIsOpen(false)}>
<Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>District Court Northern District of Florida</h3></Modal.Header>
<DistrictCourtNorthernFlorida/>
</Modal>

<div id='branchPage'>
<Container class='bgcolor' >
  <Container id='carouselBufferThree'>
    
    <Row>







<Modal id='departmentOfStateModal' show={districtCourt16ModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDistrictCourt16ModalIsOpen(false)}>
<Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>District Court Middle District of Georgia</h3></Modal.Header>
<DistrictCourtMiddleGeorgia/>
</Modal>

<Col>

<h4 id='feedHeading' onClick={() => setDistrictCourt17ModalIsOpen(true)}><b> District Court Northern District of Georgia</b></h4>




  <div  id='gand'></div>
      
 
      {CreateCourtAppealsCircuit('https://www.govinfo.gov/rss/uscourts-gand.xml', 'gand', 'District Court Northern District of Georgia', 'Judicial', 'N/A', DistrictCourtNorthernGeorgia)}
       
</Col>

<Col>

<h4 id='feedHeading' onClick={() => setDistrictCourt18ModalIsOpen(true)}><b> District Court District of Guam</b></h4>




  <div  id='gud'></div>
      
 
      {CreateCourtAppealsCircuit('https://www.govinfo.gov/rss/uscourts-gud.xml', 'gud', 'District Court District of Guam', 'Judicial', 'N/A', DistrictCourtGuam)}
       
</Col>

</Row>
</Container>
</Container>
</div>


<Modal id='departmentOfStateModal' show={districtCourt17ModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDistrictCourt17ModalIsOpen(false)}>
<Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>District Court Northern District of Georgia</h3></Modal.Header>
<DistrictCourtNorthernGeorgia/>
</Modal>

<div id='branchPage'>
<Container class='bgcolor' >
  <Container id='carouselBufferThree'>
    
    <Row>






<Modal id='departmentOfStateModal' show={districtCourt18ModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDistrictCourt18ModalIsOpen(false)}>
<Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>District Court District of Guam</h3></Modal.Header>
<DistrictCourtGuam/>
</Modal>


<Col>

<h4 id='feedHeading' onClick={() => setDistrictCourt19ModalIsOpen(true)}><b> District Court District of Hawaii</b></h4>




  <div  id='hid'></div>
      
 
      {CreateCourtAppealsCircuit('https://www.govinfo.gov/rss/uscourts-hid.xml', 'hid', 'District Court District of Hawaii', 'Judicial', 'N/A', DistrictCourtHawaii)}
       
</Col>

<Col>

<h4 id='feedHeading' onClick={() => setDistrictCourt20ModalIsOpen(true)}><b> District Court District of Idaho</b></h4>




  <div  id='idd'></div>
      
 
      {CreateCourtAppealsCircuit('https://www.govinfo.gov/rss/uscourts-idd.xml', 'idd', 'District Court District of Idaho', 'Judicial', 'N/A', DistrictCourtIdaho)}
       
</Col>

</Row>
</Container>
</Container>
</div>


<Modal id='departmentOfStateModal' show={districtCourt19ModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDistrictCourt19ModalIsOpen(false)}>
<Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3> District Court District of Hawaii</h3></Modal.Header>
<DistrictCourtHawaii/>
</Modal>

<div id='branchPage'>
<Container class='bgcolor' >
  <Container id='carouselBufferThree'>
    
    <Row>






<Modal id='departmentOfStateModal' show={districtCourt20ModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDistrictCourt20ModalIsOpen(false)}>
<Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3> District Court District of Idaho</h3></Modal.Header>
<DistrictCourtHawaii/>
</Modal>


<Col>

<h4 id='feedHeading' onClick={() => setDistrictCourt21ModalIsOpen(true)}><b> District Court Northern District of Illinois</b></h4>




  <div  id='ilnd'></div>
      
 
      {CreateCourtAppealsCircuit('https://www.govinfo.gov/rss/uscourts-ilnd.xml', 'ilnd', 'District Court Northern District of Illinois', 'Judicial', 'N/A', DistrictCourtNorthernIllinois)}
       
</Col>

<Col>

<h4 id='feedHeading' onClick={() => setDistrictCourt22ModalIsOpen(true)}><b> District Court Southern District of Indiana</b></h4>




  <div  id='insd'></div>
      
 
      {CreateCourtAppealsCircuit('https://www.govinfo.gov/rss/uscourts-insd.xml', 'insd', 'District Court Southern District of Indiana', 'Judicial', 'N/A', DistrictCourtSouthernIndiana)}
       
</Col>

</Row>
</Container>
</Container>
</div>


<Modal id='departmentOfStateModal' show={districtCourt21ModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDistrictCourt21ModalIsOpen(false)}>
<Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>District Court Northern District of Illinois</h3></Modal.Header>
<DistrictCourtNorthernIllinois/>
</Modal>

<div id='branchPage'>
<Container class='bgcolor' >
  <Container id='carouselBufferThree'>
    
    <Row>







<Modal id='departmentOfStateModal' show={districtCourt22ModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDistrictCourt22ModalIsOpen(false)}>
<Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>District Court Southern District of Indiana</h3></Modal.Header>
<DistrictCourtSouthernIndiana/>
</Modal>

<Col>

<h4 id='feedHeading' onClick={() => setDistrictCourt23ModalIsOpen(true)}><b> District Court Northern District of Iowa</b></h4>




  <div  id='iand'></div>
      
 
      {CreateCourtAppealsCircuit('https://www.govinfo.gov/rss/uscourts-iand.xml', 'iand', 'District Court Northern District of Iowa', 'Judicial', 'N/A', DistrictCourtNorthernIowa)}
       
</Col>

<Col>

<h4 id='feedHeading' onClick={() => setDistrictCourt24ModalIsOpen(true)}><b> District Court District of Kansas</b></h4>




  <div  id='ksd'></div>
      
 
      {CreateCourtAppealsCircuit('https://www.govinfo.gov/rss/uscourts-ksd.xml', 'ksd', 'District Court District of Kansas', 'Judicial', 'N/A', DistrictCourtKansas)}
       
</Col>

</Row>
</Container>
</Container>
</div>

<Modal id='departmentOfStateModal' show={districtCourt23ModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDistrictCourt23ModalIsOpen(false)}>
<Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>District Court Northern District of Iowa</h3></Modal.Header>
<DistrictCourtNorthernIowa/>
</Modal>

<div id='branchPage'>
<Container class='bgcolor' >
  <Container id='carouselBufferThree'>
    
    <Row>







<Modal id='departmentOfStateModal' show={districtCourt24ModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDistrictCourt24ModalIsOpen(false)}>
<Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>District Court District of Kansas</h3></Modal.Header>
<DistrictCourtKansas/>
</Modal>

<Col>

<h4 id='feedHeading' onClick={() => setDistrictCourt25ModalIsOpen(true)}><b> District Court Eastern District of Kentucky</b></h4>




  <div  id='kyed'></div>
      
 
      {CreateCourtAppealsCircuit('https://www.govinfo.gov/rss/uscourts-kyed.xml', 'kyed', 'District Court Eastern District of Kentucky', 'Judicial', 'N/A', DistrictCourtEasternKentucky)}
       
</Col>

<Col>

<h4 id='feedHeading' onClick={() => setDistrictCourt26ModalIsOpen(true)}><b> District Court Western District of Kentucky</b></h4>




  <div  id='kywd'></div>
      
 
      {CreateCourtAppealsCircuit('https://www.govinfo.gov/rss/uscourts-kywd.xml', 'kywd', 'District Court Western District of Kentucky', 'Judicial', 'N/A', DistrictCourtWesternKentucky)}
       
</Col>

</Row>
</Container>
</Container>
</div>

<Modal id='departmentOfStateModal' show={districtCourt25ModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDistrictCourt25ModalIsOpen(false)}>
<Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>District Court Eastern District of Kentucky</h3></Modal.Header>
<DistrictCourtEasternKentucky/>
</Modal>









<Modal id='departmentOfStateModal' show={districtCourt26ModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDistrictCourt26ModalIsOpen(false)}>
<Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>District Court Western District of Kentucky</h3></Modal.Header>
<DistrictCourtWesternKentucky/>
</Modal>





<Modal id='departmentOfStateModal' show={districtCourt27ModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDistrictCourt27ModalIsOpen(false)}>
<Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>District Court Eastern District of Louisiana</h3></Modal.Header>
<DistrictCourtEasternLouisiana/>
</Modal>


<div id='branchPage'>
<Container class='bgcolor' >
  <Container id='carouselBufferThree'>
    
    <Row>

    <Col>

<h4 id='feedHeading' onClick={() => setDistrictCourt27ModalIsOpen(true)}><b> District Court Eastern District of Louisiana</b></h4>




  <div  id='laed'></div>
      
 
      {CreateCourtAppealsCircuit('https://www.govinfo.gov/rss/uscourts-laed.xml', 'laed', 'District Court Eastern District of Louisiana', 'Judicial', 'N/A', DistrictCourtEasternLouisiana)}
       
</Col>


<Col>

<h4 id='feedHeading' onClick={() => setDistrictCourt28ModalIsOpen(true)}><b> District Court Middle District of Louisiana</b></h4>




  <div  id='lamd'></div>
      
 
      {CreateCourtAppealsCircuit('https://www.govinfo.gov/rss/uscourts-lamd.xml', 'lamd', 'District Court Middle District of Louisiana', 'Judicial', 'N/A', DistrictCourtMiddleLouisiana)}
       
</Col>




<Modal id='departmentOfStateModal' show={districtCourt28ModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDistrictCourt28ModalIsOpen(false)}>
<Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>District Court Middle District of Louisiana</h3></Modal.Header>
<DistrictCourtMiddleLouisiana/>
</Modal>



</Row>
</Container>
</Container>
</div>



<Modal id='departmentOfStateModal' show={districtCourt28ModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDistrictCourt28ModalIsOpen(false)}>
<Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>District Court Middle District of Louisiana</h3></Modal.Header>
<DistrictCourtMiddleLouisiana/>
</Modal>

<div id='branchPage'>
<Container class='bgcolor' >
  <Container id='carouselBufferThree'>
    
    <Row>


<Col>

<h4 id='feedHeading' onClick={() => setDistrictCourt29ModalIsOpen(true)}><b> District Court District of Maine</b></h4>




  <div  id='med'></div>
      
 
      {CreateCourtAppealsCircuit('https://www.govinfo.gov/rss/uscourts-med.xml', 'med', 'District Court District of Maine', 'Judicial', 'N/A', DistrictCourtMaine)}
       
</Col>




<Modal id='departmentOfStateModal' show={districtCourt29ModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDistrictCourt29ModalIsOpen(false)}>
<Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>District Court District of Maine</h3></Modal.Header>
<DistrictCourtMaine/>
</Modal>


<Col>

<h4 id='feedHeading' onClick={() => setDistrictCourt30ModalIsOpen(true)}><b> District Court District of Maryland</b></h4>




  <div  id='mdd'></div>
      
 
      {CreateCourtAppealsCircuit('https://www.govinfo.gov/rss/uscourts-mdd.xml', 'mdd', 'District Court District of Maryland', 'Judicial', 'N/A', DistrictCourtMaryland)}
       
</Col>


</Row>
</Container>
</Container>
</div>

<Modal id='departmentOfStateModal' show={districtCourt30ModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDistrictCourt30ModalIsOpen(false)}>
<Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>District Court District of Maryland</h3></Modal.Header>
<DistrictCourtMaryland/>
</Modal>

<div id='branchPage'>
<Container class='bgcolor' >
  <Container id='carouselBufferThree'>
    
    <Row>


<Col>

<h4 id='feedHeading' onClick={() => setDistrictCourt31ModalIsOpen(true)}><b> District Court District of Massachusetts</b></h4>




  <div  id='mad'></div>
      
 
      {CreateCourtAppealsCircuit('https://www.govinfo.gov/rss/uscourts-mad.xml', 'mad', 'District Court District of Massachusetts', 'Judicial', 'N/A', DistrictCourtMassachusetts)}
       
</Col>




<Modal id='departmentOfStateModal' show={districtCourt31ModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDistrictCourt31ModalIsOpen(false)}>
<Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>District Court District of Massachusetts</h3></Modal.Header>
<DistrictCourtMassachusetts/>
</Modal>

<Col>

<h4 id='feedHeading' onClick={() => setDistrictCourt32ModalIsOpen(true)}><b> District Court Eastern District of Michigan</b></h4>




  <div  id='mied'></div>
      
 
      {CreateCourtAppealsCircuit('https://www.govinfo.gov/rss/uscourts-mied.xml', 'mied', 'District Court Eastern District of Michigan', 'Judicial', 'N/A', DistrictCourtEasternMichigan)}
       
</Col>

</Row>
</Container>
</Container>
</div>


<Modal id='departmentOfStateModal' show={districtCourt32ModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDistrictCourt32ModalIsOpen(false)}>
<Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>District Court Eastern District of Michigan</h3></Modal.Header>
<DistrictCourtEasternMichigan/>
</Modal>

<div id='branchPage'>
<Container class='bgcolor' >
  <Container id='carouselBufferThree'>
    
    <Row>


<Col>

<h4 id='feedHeading' onClick={() => setDistrictCourt33ModalIsOpen(true)}><b> District Court Western District of Michigan</b></h4>




  <div  id='miwd'></div>
      
 
      {CreateCourtAppealsCircuit('https://www.govinfo.gov/rss/uscourts-miwd.xml', 'miwd', 'District Court Western District of Michigan', 'Judicial', 'N/A', DistrictCourtWesternMichigan)}
       
</Col>




<Modal id='departmentOfStateModal' show={districtCourt33ModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDistrictCourt33ModalIsOpen(false)}>
<Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>District Court Western District of Michigan</h3></Modal.Header>
<DistrictCourtWesternMichigan/>
</Modal>

<Col>

<h4 id='feedHeading' onClick={() => setDistrictCourt34ModalIsOpen(true)}><b> District Court District of Minnesota</b></h4>




  <div  id='mnd'></div>
      
 
      {CreateCourtAppealsCircuit('https://www.govinfo.gov/rss/uscourts-mnd.xml', 'mnd', 'District Court District of Minnesota', 'Judicial', 'N/A', DistrictCourtMinnesota)}
       
</Col>


</Row>
</Container>
</Container>
</div>


<Modal id='departmentOfStateModal' show={districtCourt34ModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDistrictCourt34ModalIsOpen(false)}>
<Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>District Court District of Minnesota</h3></Modal.Header>
<DistrictCourtMinnesota/>
</Modal>





<div id='branchPage'>
<Container class='bgcolor' >
  <Container id='carouselBufferThree'>
    
    <Row>

<Col>

<h4 id='feedHeading' onClick={() => setDistrictCourt35ModalIsOpen(true)}><b> District Court Eastern District of Missouri</b></h4>




  <div  id='moed'></div>
      
 
      {CreateCourtAppealsCircuit('https://www.govinfo.gov/rss/uscourts-moed.xml', 'moed', 'District Court Eastern District of Missouri', 'Judicial', 'N/A', DistrictCourtEasternMissouri)}
       
</Col>




<Modal id='departmentOfStateModal' show={districtCourt35ModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDistrictCourt35ModalIsOpen(false)}>
<Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>District Court Eastern District of Missouri</h3></Modal.Header>
<DistrictCourtEasternMissouri/>
</Modal>

<Col>

<h4 id='feedHeading' onClick={() => setDistrictCourt36ModalIsOpen(true)}><b> District Court Western District of Missouri</b></h4>




  <div  id='mowd'></div>
      
 
      {CreateCourtAppealsCircuit('https://www.govinfo.gov/rss/uscourts-mowd.xml', 'mowd', 'District Court Western District of Missouri', 'Judicial', 'N/A', DistrictCourtWesternMissouri)}
       
</Col>

</Row>
</Container>
</Container>
</div>


<Modal id='departmentOfStateModal' show={districtCourt36ModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDistrictCourt36ModalIsOpen(false)}>
<Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>District Court Western District of Missouri</h3></Modal.Header>
<DistrictCourtWesternMissouri/>
</Modal>

<div id='branchPage'>
<Container class='bgcolor' >
  <Container id='carouselBufferThree'>
    
    <Row> 

<Col>

<h4 id='feedHeading' onClick={() => setDistrictCourt37ModalIsOpen(true)}><b> District Court District of Montana</b></h4>




  <div  id='mtd'></div>
      
 
      {CreateCourtAppealsCircuit('https://www.govinfo.gov/rss/uscourts-mtd.xml', 'mtd', 'District Court District of Montana', 'Judicial', 'N/A', DistrictCourtMontana)}
       
</Col>




<Modal id='departmentOfStateModal' show={districtCourt37ModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDistrictCourt37ModalIsOpen(false)}>
<Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3> District Court District of Montana</h3></Modal.Header>
<DistrictCourtMontana/>
</Modal>

<Col>

<h4 id='feedHeading' onClick={() => setDistrictCourt38ModalIsOpen(true)}><b> District Court District of Nebraska</b></h4>




  <div  id='ned'></div>
      
 
      {CreateCourtAppealsCircuit('https://www.govinfo.gov/rss/uscourts-ned.xml', 'ned', 'District Court District of Nebraska', 'Judicial', 'N/A', DistrictCourtNebraska)}
       
</Col>

</Row>
</Container>
</Container>
</div>


<Modal id='departmentOfStateModal' show={districtCourt38ModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDistrictCourt38ModalIsOpen(false)}>
<Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3> District Court District of Nebraska</h3></Modal.Header>
<DistrictCourtNebraska/>
</Modal>

<div id='branchPage'>
<Container class='bgcolor' >
  <Container id='carouselBufferThree'>
    
    <Row> 

<Col>

<h4 id='feedHeading' onClick={() => setDistrictCourt39ModalIsOpen(true)}><b> District Court District of New Hampshire</b></h4>




  <div  id='nhd'></div>
      
 
      {CreateCourtAppealsCircuit('https://www.govinfo.gov/rss/uscourts-nhd.xml', 'nhd', 'District Court District of New Hampshire', 'Judicial', 'N/A', DistrictCourtNewHampshire)}
       
</Col>




<Modal id='departmentOfStateModal' show={districtCourt39ModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDistrictCourt39ModalIsOpen(false)}>
<Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3> District Court District of New Hampshire</h3></Modal.Header>
<DistrictCourtNewHampshire/>
</Modal>

<Col>

<h4 id='feedHeading' onClick={() => setDistrictCourt40ModalIsOpen(true)}><b> District Court District of New Jersey</b></h4>




  <div  id='njd'></div>
      
 
      {CreateCourtAppealsCircuit('https://www.govinfo.gov/rss/uscourts-njd.xml', 'njd', 'District Court District of New Jersey', 'Judicial', 'N/A', DistrictCourtNewJersey)}
       
</Col>

</Row>
</Container>
</Container>
</div>



<Modal id='departmentOfStateModal' show={districtCourt40ModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDistrictCourt40ModalIsOpen(false)}>
<Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3> District Court District of New Jersey</h3></Modal.Header>
<DistrictCourtNewJersey/>
</Modal>

<div id='branchPage'>
<Container class='bgcolor' >
  <Container id='carouselBufferThree'>
    
    <Row> 

<Col>

<h4 id='feedHeading' onClick={() => setDistrictCourt41ModalIsOpen(true)}><b> District Court District of New Mexico</b></h4>




  <div  id='nmd'></div>
      
 
      {CreateCourtAppealsCircuit('https://www.govinfo.gov/rss/uscourts-nmd.xml', 'nmd', 'District Court District of New Mexico', 'Judicial', 'N/A', DistrictCourtNewMexico)}
       
</Col>




<Modal id='departmentOfStateModal' show={districtCourt41ModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDistrictCourt41ModalIsOpen(false)}>
<Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3> District Court District of New Mexico</h3></Modal.Header>
<DistrictCourtNewMexico/>
</Modal>

<Col>

<h4 id='feedHeading' onClick={() => setDistrictCourt42ModalIsOpen(true)}><b>District Court Eastern District of New York</b></h4>




  <div  id='nyed'></div>
      
 
      {CreateCourtAppealsCircuit('https://www.govinfo.gov/rss/uscourts-nyed.xml', 'nyed', 'District Court Eastern District of New York', 'Judicial', 'N/A', DistrictCourtEasternNewYork)}
       
</Col>


</Row>
</Container>
</Container>
</div>

<Modal id='departmentOfStateModal' show={districtCourt42ModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDistrictCourt42ModalIsOpen(false)}>
<Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3> District Court Eastern District of New York</h3></Modal.Header>
<DistrictCourtEasternNewYork/>
</Modal>

<div id='branchPage'>
<Container class='bgcolor' >
  <Container id='carouselBufferThree'>
    
    <Row> 

<Col>

<h4 id='feedHeading' onClick={() => setDistrictCourt43ModalIsOpen(true)}><b>District Court Northern District of New York</b></h4>




  <div  id='nynd'></div>
      
 
      {CreateCourtAppealsCircuit('https://www.govinfo.gov/rss/uscourts-nynd.xml', 'nynd', 'District Court Northern District of New York', 'Judicial', 'N/A', DistrictCourtNorthernNewYork)}
       
</Col>




<Modal id='departmentOfStateModal' show={districtCourt43ModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDistrictCourt43ModalIsOpen(false)}>
<Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3> District Court Northern District of New York</h3></Modal.Header>
<DistrictCourtNorthernNewYork/>
</Modal>

<Col>

<h4 id='feedHeading' onClick={() => setDistrictCourt44ModalIsOpen(true)}><b>District Court Middle District of North Carolina</b></h4>




  <div  id='ncmd'></div>
      
 
      {CreateCourtAppealsCircuit('https://www.govinfo.gov/rss/uscourts-ncmd.xml', 'ncmd', 'District Court Middle District of North Carolina', 'Judicial', 'N/A', DistrictCourtMiddleNorthCarolina)}
       
</Col>

</Row>
</Container>
</Container>
</div>


<Modal id='departmentOfStateModal' show={districtCourt44ModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDistrictCourt44ModalIsOpen(false)}>
<Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3> District Court Middle District of North Carolina</h3></Modal.Header>
<DistrictCourtMiddleNorthCarolina/>
</Modal>

<div id='branchPage'>
<Container class='bgcolor' >
  <Container id='carouselBufferThree'>
    
    <Row> 

<Col>

<h4 id='feedHeading' onClick={() => setDistrictCourt45ModalIsOpen(true)}><b>District Court District of North Dakota</b></h4>




  <div  id='ndd'></div>
      
 
      {CreateCourtAppealsCircuit('https://www.govinfo.gov/rss/uscourts-ndd.xml', 'ndd', 'District Court District of North Dakota', 'Judicial', 'N/A', DistrictCourtNorthDakota)}
       
</Col>




<Modal id='departmentOfStateModal' show={districtCourt45ModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDistrictCourt45ModalIsOpen(false)}>
<Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3> District Court District of North Dakota</h3></Modal.Header>
<DistrictCourtNorthDakota/>
</Modal>


<Col>

<h4 id='feedHeading' onClick={() => setDistrictCourt46ModalIsOpen(true)}><b>District Court District of Northern Mariana Islands</b></h4>




  <div  id='nmid'></div>
      
 
      {CreateCourtAppealsCircuit('https://www.govinfo.gov/rss/uscourts-nmid.xml', 'nmid', 'District Court District of Northern Mariana Islands', 'Judicial', 'N/A', DistrictCourtNorthernMarianaIslands)}
       
</Col>

</Row>
</Container>
</Container>
</div>


<Modal id='departmentOfStateModal' show={districtCourt46ModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDistrictCourt46ModalIsOpen(false)}>
<Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3> District Court District of Northern Mariana Islands</h3></Modal.Header>
<DistrictCourtNorthernMarianaIslands/>
</Modal>

<div id='branchPage'>
<Container class='bgcolor' >
  <Container id='carouselBufferThree'>
    
    <Row> 

<Col>

<h4 id='feedHeading' onClick={() => setDistrictCourt47ModalIsOpen(true)}><b>District Court Northern District of Ohio</b></h4>




  <div  id='ohnd'></div>
      
 
      {CreateCourtAppealsCircuit('https://www.govinfo.gov/rss/uscourts-ohnd.xml', 'ohnd', 'District Court Northern District of Ohio', 'Judicial', 'N/A', DistrictCourtNorthernOhio)}
       
</Col>




<Modal id='departmentOfStateModal' show={districtCourt47ModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDistrictCourt47ModalIsOpen(false)}>
<Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3> District Court Northern District of Ohio</h3></Modal.Header>
<DistrictCourtNorthernOhio/>
</Modal>

<Col>

<h4 id='feedHeading' onClick={() => setDistrictCourt48ModalIsOpen(true)}><b>District Court Southern District of Ohio</b></h4>




  <div  id='ohsd'></div>
      
 
      {CreateCourtAppealsCircuit('https://www.govinfo.gov/rss/uscourts-ohsd.xml', 'ohsd', 'District Court Southern District of Ohio', 'Judicial', 'N/A', DistrictCourtSouthernOhio)}
       
</Col>

</Row>
</Container>
</Container>
</div>

<Modal id='departmentOfStateModal' show={districtCourt48ModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDistrictCourt48ModalIsOpen(false)}>
<Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3> District Court Southern District of Ohio</h3></Modal.Header>
<DistrictCourtSouthernOhio/>
</Modal>

<div id='branchPage'>
<Container class='bgcolor' >
  <Container id='carouselBufferThree'>
    
    <Row> 

<Col>

<h4 id='feedHeading' onClick={() => setDistrictCourt49ModalIsOpen(true)}><b>District Court Eastern District of Oklahoma</b></h4>




  <div  id='oked'></div>
      
 
      {CreateCourtAppealsCircuit('https://www.govinfo.gov/rss/uscourts-oked.xml', 'oked', 'District Court Eastern District of Oklahoma', 'Judicial', 'N/A', DistrictCourtEasternOklahoma)}
       
</Col>




<Modal id='departmentOfStateModal' show={districtCourt49ModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDistrictCourt49ModalIsOpen(false)}>
<Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3> District Court Eastern District of Oklahoma</h3></Modal.Header>
<DistrictCourtEasternOklahoma/>
</Modal>


<Col>

<h4 id='feedHeading' onClick={() => setDistrictCourt50ModalIsOpen(true)}><b>District Court Western District of Oklahoma</b></h4>




  <div  id='okwd'></div>
      
 
      {CreateCourtAppealsCircuit('https://www.govinfo.gov/rss/uscourts-okwd.xml', 'okwd', 'District Court Western District of Oklahoma', 'Judicial', 'N/A', DistrictCourtWesternOklahoma)}
       
</Col>

</Row>
</Container>
</Container>
</div>



<Modal id='departmentOfStateModal' show={districtCourt50ModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDistrictCourt50ModalIsOpen(false)}>
<Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3> District Court Western District of Oklahoma</h3></Modal.Header>
<DistrictCourtWesternOklahoma/>
</Modal>

<div id='branchPage'>
<Container class='bgcolor' >
  <Container id='carouselBufferThree'>
    
    <Row> 

<Col>

<h4 id='feedHeading' onClick={() => setDistrictCourt51ModalIsOpen(true)}><b>District Court District of Oregon</b></h4>




  <div  id='ord'></div>
      
 
      {CreateCourtAppealsCircuit('https://www.govinfo.gov/rss/uscourts-ord.xml', 'ord', 'District Court District of Oregon', 'Judicial', 'N/A', DistrictCourtOregon)}
       
</Col>




<Modal id='departmentOfStateModal' show={districtCourt51ModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDistrictCourt51ModalIsOpen(false)}>
<Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3> District Court District of Oregon</h3></Modal.Header>
<DistrictCourtOregon/>
</Modal>

<Col>

<h4 id='feedHeading' onClick={() => setDistrictCourt52ModalIsOpen(true)}><b>District Court Eastern District of Pennsylvania</b></h4>




  <div  id='paed'></div>
      
 
      {CreateCourtAppealsCircuit('https://www.govinfo.gov/rss/uscourts-paed.xml', 'paed', 'District Court Eastern District of Pennsylvania', 'Judicial', 'N/A', DistrictCourtEasternPennsylvania)}
       
</Col>

</Row>
</Container>
</Container>
</div>


<Modal id='departmentOfStateModal' show={districtCourt52ModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDistrictCourt52ModalIsOpen(false)}>
<Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3> District Court Eastern District of Pennsylvania</h3></Modal.Header>
<DistrictCourtEasternPennsylvania/>
</Modal>

<div id='branchPage'>
<Container class='bgcolor' >
  <Container id='carouselBufferThree'>
    
    <Row> 

<Col>

<h4 id='feedHeading' onClick={() => setDistrictCourt53ModalIsOpen(true)}><b>District Court Middle District of Pennsylvania</b></h4>




  <div  id='pamd'></div>
      
 
      {CreateCourtAppealsCircuit('https://www.govinfo.gov/rss/uscourts-pamd.xml', 'pamd', 'District Court Middle District of Pennsylvania', 'Judicial', 'N/A', DistrictCourtMiddlePennslyvania)}
       
</Col>




<Modal id='departmentOfStateModal' show={districtCourt53ModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDistrictCourt53ModalIsOpen(false)}>
<Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3> District Court Middle District of Pennsylvania</h3></Modal.Header>
<DistrictCourtMiddlePennslyvania/>
</Modal>

<Col>

<h4 id='feedHeading' onClick={() => setDistrictCourt54ModalIsOpen(true)}><b>District Court Western District of Pennsylvania</b></h4>




  <div  id='pawd'></div>
      
 
      {CreateCourtAppealsCircuit('https://www.govinfo.gov/rss/uscourts-pawd.xml', 'pawd', 'District Court Western District of Pennsylvania', 'Judicial', 'N/A', DistrictCourtWesternPennsylvania)}
       
</Col>

</Row>
</Container>
</Container>
</div>


<Modal id='departmentOfStateModal' show={districtCourt54ModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDistrictCourt54ModalIsOpen(false)}>
<Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3> District Court Western District of Pennsylvania</h3></Modal.Header>
<DistrictCourtWesternPennsylvania/>
</Modal>

<div id='branchPage'>
<Container class='bgcolor' >
  <Container id='carouselBufferThree'>
    
    <Row> 

<Col>

<h4 id='feedHeading' onClick={() => setDistrictCourt55ModalIsOpen(true)}><b>District Court District of Puerto Rico</b></h4>




  <div  id='prd'></div>
      
 
      {CreateCourtAppealsCircuit('https://www.govinfo.gov/rss/uscourts-prd.xml', 'prd', 'District Court District of Puerto Rico', 'Judicial', 'N/A', DistrictCourtPuertoRico)}
       
</Col>




<Modal id='departmentOfStateModal' show={districtCourt55ModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDistrictCourt55ModalIsOpen(false)}>
<Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>District Court District of Puerto Rico</h3></Modal.Header>
<DistrictCourtPuertoRico/>
</Modal>

<Col>

<h4 id='feedHeading' onClick={() => setDistrictCourt56ModalIsOpen(true)}><b>District Court District of Rhode Island</b></h4>




  <div  id='rid'></div>
      
 
      {CreateCourtAppealsCircuit('https://www.govinfo.gov/rss/uscourts-rid.xml', 'rid', 'District Court District of Rhode Island', 'Judicial', 'N/A', DistrictCourtRhodeIsland)}
       
</Col>

</Row>
</Container>
</Container>
</div>


<Modal id='departmentOfStateModal' show={districtCourt56ModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDistrictCourt56ModalIsOpen(false)}>
<Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>District Court District of Rhode Island</h3></Modal.Header>
<DistrictCourtRhodeIsland/>
</Modal>

<div id='branchPage'>
<Container class='bgcolor' >
  <Container id='carouselBufferThree'>
    
    <Row> 

<Col>

<h4 id='feedHeading' onClick={() => setDistrictCourt57ModalIsOpen(true)}><b>District Court District of South Dakota</b></h4>




  <div  id='sdd'></div>
      
 
      {CreateCourtAppealsCircuit('https://www.govinfo.gov/rss/uscourts-sdd.xml', 'sdd', 'District Court District of South Dakota', 'Judicial', 'N/A', DistrictCourtSouthDakota)}
       
</Col>




<Modal id='departmentOfStateModal' show={districtCourt57ModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDistrictCourt57ModalIsOpen(false)}>
<Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>District Court District of South Dakota</h3></Modal.Header>
<DistrictCourtSouthDakota/>
</Modal>

<Col>

<h4 id='feedHeading' onClick={() => setDistrictCourt58ModalIsOpen(true)}><b>District Court Eastern District of Tennessee</b></h4>




  <div  id='tned'></div>
      
 
      {CreateCourtAppealsCircuit('https://www.govinfo.gov/rss/uscourts-tned.xml', 'tned', 'District Court Eastern District of Tennessee', 'Judicial', 'N/A', DistrictCourtEasternTennessee)}
       
</Col>

</Row>
</Container>
</Container>
</div>


<Modal id='departmentOfStateModal' show={districtCourt58ModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDistrictCourt58ModalIsOpen(false)}>
<Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>District Court Eastern District of Tennessee</h3></Modal.Header>
<DistrictCourtEasternTennessee/>
</Modal>

<div id='branchPage'>
<Container class='bgcolor' >
  <Container id='carouselBufferThree'>
    
    <Row> 

<Col>

<h4 id='feedHeading' onClick={() => setDistrictCourt59ModalIsOpen(true)}><b>District Court Western District of Tennessee</b></h4>




  <div  id='tnwd'></div>
      
 
      {CreateCourtAppealsCircuit('https://www.govinfo.gov/rss/uscourts-tnwd.xml', 'tnwd', 'District Court Western District of Tennessee', 'Judicial', 'N/A', DistrictCourtWesternTennessee)}
       
</Col>




<Modal id='departmentOfStateModal' show={districtCourt59ModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDistrictCourt59ModalIsOpen(false)}>
<Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>District Court Western District of Tennessee</h3></Modal.Header>
<DistrictCourtWesternTennessee/>
</Modal>

<Col>

<h4 id='feedHeading' onClick={() => setDistrictCourt60ModalIsOpen(true)}><b>District Court Middle District of Tennessee</b></h4>




  <div  id='tnmd'></div>
      
 
      {CreateCourtAppealsCircuit('https://www.govinfo.gov/rss/uscourts-tnmd.xml', 'tnmd', 'District Court Middle District of Tennessee', 'Judicial', 'N/A', DistrictCourtMiddleTennessee)}
       
</Col>


</Row>
</Container>
</Container>
</div>

<Modal id='departmentOfStateModal' show={districtCourt60ModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDistrictCourt60ModalIsOpen(false)}>
<Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>District Court Middle District of Tennessee</h3></Modal.Header>
<DistrictCourtMiddleTennessee/>
</Modal>

<div id='branchPage'>
<Container class='bgcolor' >
  <Container id='carouselBufferThree'>
    
    <Row> 

<Col>

<h4 id='feedHeading' onClick={() => setDistrictCourt61ModalIsOpen(true)}><b>District Court Northern District of Texas</b></h4>




  <div  id='txnd'></div>
      
 
      {CreateCourtAppealsCircuit('https://www.govinfo.gov/rss/uscourts-txnd.xml', 'txnd', 'District Court Northern District of Texas', 'Judicial', 'N/A', DistrictCourtNorthernTexas)}
       
</Col>




<Modal id='departmentOfStateModal' show={districtCourt61ModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDistrictCourt61ModalIsOpen(false)}>
<Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>District Court Northern District of Texas</h3></Modal.Header>
<DistrictCourtNorthernTexas/>
</Modal>

<Col>

<h4 id='feedHeading' onClick={() => setDistrictCourt62ModalIsOpen(true)}><b>District Court Southern District of Texas</b></h4>




  <div  id='txsd'></div>
      
 
      {CreateCourtAppealsCircuit('https://www.govinfo.gov/rss/uscourts-txsd.xml', 'txsd', 'District Court Southern District of Texas', 'Judicial', 'N/A', DistrictCourtSouthernTexas)}
       
</Col>

</Row>
</Container>
</Container>
</div>


<Modal id='departmentOfStateModal' show={districtCourt62ModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDistrictCourt62ModalIsOpen(false)}>
<Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>District Court Southern District of Texas</h3></Modal.Header>
<DistrictCourtSouthernTexas/>
</Modal>


<div id='branchPage'>
<Container class='bgcolor' >
  <Container id='carouselBufferThree'>
    
    <Row> 
<Col>

<h4 id='feedHeading' onClick={() => setDistrictCourt63ModalIsOpen(true)}><b>District Court Western District of Texas</b></h4>




  <div  id='txwd'></div>
      
 
      {CreateCourtAppealsCircuit('https://www.govinfo.gov/rss/uscourts-txwd.xml', 'txwd', 'District Court Western District of Texas', 'Judicial', 'N/A', DistrictCourtWesternTexas)}
       
</Col>




<Modal id='departmentOfStateModal' show={districtCourt63ModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDistrictCourt63ModalIsOpen(false)}>
<Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>District Court Western District of Texas</h3></Modal.Header>
<DistrictCourtWesternTexas/>
</Modal>

<Col>

<h4 id='feedHeading' onClick={() => setDistrictCourt64ModalIsOpen(true)}><b>District Court District of Utah</b></h4>




  <div  id='utd'></div>
      
 
      {CreateCourtAppealsCircuit('https://www.govinfo.gov/rss/uscourts-utd.xml', 'utd', 'District Court District of Utah', 'Judicial', 'N/A', DistrictCourtUtah)}
       
</Col>

</Row>
</Container>
</Container>
</div>


<Modal id='departmentOfStateModal' show={districtCourt64ModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDistrictCourt64ModalIsOpen(false)}>
<Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>District Court District of Utah</h3></Modal.Header>
<DistrictCourtUtah/>
</Modal>

<div id='branchPage'>
<Container class='bgcolor' >
  <Container id='carouselBufferThree'>
    
    <Row> 

<Col>

<h4 id='feedHeading' onClick={() => setDistrictCourt65ModalIsOpen(true)}><b>District Court of the Virgin Islands</b></h4>




  <div  id='vid'></div>
      
 
      {CreateCourtAppealsCircuit('https://www.govinfo.gov/rss/uscourts-vid.xml', 'vid', 'District Court of the Virgin Islands', 'Judicial', 'N/A', DistrictCourtVirginIslands)}
       
</Col>




<Modal id='departmentOfStateModal' show={districtCourt65ModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDistrictCourt65ModalIsOpen(false)}>
<Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>District Court of the Virgin Islands</h3></Modal.Header>
<DistrictCourtVirginIslands/>
</Modal>
             
          
<Col>

<h4 id='feedHeading' onClick={() => setDistrictCourt66ModalIsOpen(true)}><b>District Court District of Vermont</b></h4>




  <div  id='vtd'></div>
      
 
      {CreateCourtAppealsCircuit('https://www.govinfo.gov/rss/uscourts-vtd.xml', 'vtd', 'District Court District of Vermont', 'Judicial', 'N/A', DistrictCourtVermont)}
       
</Col>

</Row>
</Container>
</Container>
</div>


<Modal id='departmentOfStateModal' show={districtCourt66ModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDistrictCourt66ModalIsOpen(false)}>
<Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>District Court District of Vermont</h3></Modal.Header>
<DistrictCourtVermont/>
</Modal>    


<div id='branchPage'>
<Container class='bgcolor' >
  <Container id='carouselBufferThree'>
    
    <Row> 

<Col>

<h4 id='feedHeading' onClick={() => setDistrictCourt67ModalIsOpen(true)}><b>District Court Eastern District of Washington</b></h4>




  <div  id='waed'></div>
      
 
      {CreateCourtAppealsCircuit('https://www.govinfo.gov/rss/uscourts-waed.xml', 'waed', 'District Court Eastern District of Washington', 'Judicial', 'N/A', DistrictCourtEasternWashington)}
       
</Col>




<Modal id='departmentOfStateModal' show={districtCourt67ModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDistrictCourt67ModalIsOpen(false)}>
<Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>District Court Eastern District of Washington</h3></Modal.Header>
<DistrictCourtEasternWashington/>
</Modal>      
        
<Col>

<h4 id='feedHeading' onClick={() => setDistrictCourt68ModalIsOpen(true)}><b>District Court Northern District of West Virginia</b></h4>




  <div  id='wvnd'></div>
      
 
      {CreateCourtAppealsCircuit('https://www.govinfo.gov/rss/uscourts-wvnd.xml', 'wvnd', 'District Court Northern District of West Virginia', 'Judicial', 'N/A', DistrictCourtNorthernWestVirginia)}
       
</Col>

</Row>
</Container>
</Container>
</div>


<Modal id='departmentOfStateModal' show={districtCourt68ModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDistrictCourt68ModalIsOpen(false)}>
<Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>District Court Northern District of West Virginia</h3></Modal.Header>
<DistrictCourtNorthernWestVirginia/>
</Modal>   

<div id='branchPage'>
<Container class='bgcolor' >
  <Container id='carouselBufferThree'>
    
    <Row> 

<Col>

<h4 id='feedHeading' onClick={() => setDistrictCourt69ModalIsOpen(true)}><b>District Court District of Wyoming</b></h4>




  <div  id='wyd'></div>
      
 
      {CreateCourtAppealsCircuit('https://www.govinfo.gov/rss/uscourts-wyd.xml', 'wyd', 'District Court District of Wyoming', 'Judicial', 'N/A', DistrictCourtWyoming)}
       
</Col>

<Col>


</Col>




<Modal id='departmentOfStateModal' show={districtCourt69ModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDistrictCourt69ModalIsOpen(false)}>
<Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>District Court District of Wyoming</h3></Modal.Header>
<DistrictCourtWyoming/>
</Modal>   

</Row>
</Container>
</Container>
</div>












              <Modal id='aboutModal' show={aboutModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setAboutModalIsOpen(false)}>
            <div id='removedAlert'></div>
            <Modal.Header id='aboutModalHeader' closeButton><h3>About</h3></Modal.Header>
  <ModalBody id='aboutInfo'><p id='aboutParagraph'>Gov Glance was created to drive civic engagement and support a 
              healthier democracy by helping citizens get official Government news in an easy-to-navigate centralized location. 
              There will never be any opinion or political analysis(That's your job), only information from official Government 
              sources. Gov Glance is structured after the U.S. government that is broken into three branches Legislative, 
              Executive, and Judicial. To learn more about the structure and functions of The U.S. Government go to <a href='https://www.usa.gov/branches-of-government'>https://www.usa.gov/branches-of-government</a>.
              </p>

              <p id='aboutParagraph'>
              Gov Glance Foundation Inc. is a non-profit that was created to operate govglance.org and continue to create educational tools that help people learn about the workings of society
              </p>
            <br></br>

            <h3>Privacy</h3>

            <br></br>

              <p id='aboutParagraph'>Gov Glance does not collect any personal user information. 
              Features like collection save news items directly to your browser's local storage for your convenience
              and is not shared with Gov Glance servers. We do not collect, store, or send personal information to Google LLC, 
              but we do use some Google services to deliver our app to you. For any questions into Google’s privacy practices 
              here is a link to their privacy policy: <a href='https://policies.google.com/privacy?hl=en-US#intro'>https://policies.google.com/privacy?hl=en-US#intro</a>

              <br></br><br></br>

              For site analytics we use plausible.io an open source privacy friendly analytics provider. <a href="https://plausible.io/about" target="_blank">https://plausible.io/about</a>
            
            </p>
            <br></br>


            <h3>Contact</h3>

            <p id='aboutParagraph'>
                      Contact us via email: <a href='mailto:contact@govglance.org'>contact@govglance.org</a>

            </p>

            <br></br>


            
            </ModalBody>
          </Modal>
 
          <footer id='homeFooter'>
      <Navbar>
        <Row id='footerNav'>
      <Nav variant='pills'>
        
     <Nav.Link id='footerContent'>Gov Glance Foundation Inc.
</Nav.Link>
<Nav.Item>
<Nav.Link id='footerContent' href='mailto:contact@govglance.org'>Contact</Nav.Link>
</Nav.Item>
<Nav.Item>
<Nav.Link id='footerContent' onClick={(e) => {
                   e.preventDefault();
                   window.open('https://donate.stripe.com/28o3fXbdvbQO2RO288', "_blank")
                 }}> <HeartFill id='donateIcon'/>    Donate</Nav.Link>
</Nav.Item>
<Nav.Link onClick={(e) => {
                   e.preventDefault();
                   window.open('/about', "_self")}} id='footerContent'>Privacy</Nav.Link>
    
     
 </Nav>
 </Row>
 </Navbar>
      </footer>

           
          </div>
           </div>
           
              </BrowserView>

              <MobileView>




       <JudicialBranchMobile/>


     

              </MobileView>
              
    </>
  )
}
 }



  






export default JudicalBranch;