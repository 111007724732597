import React, { useEffect, useState } from 'react';
import { Col, Container, Modal, Row, Image, Navbar, Button, Carousel, Spinner, Nav, ModalBody } from 'react-bootstrap';
import { Files, HeartFill } from 'react-bootstrap-icons';
import { BrowserView, MobileView } from 'react-device-detect';
import { useParams } from 'react-router-dom';
import DesktopNavbar from './DesktopNavbar';
import GenerateFeedStateRepsAll from './GenerateFeedStateRepsAll';
import MobileOffCanvas from './MobileOffCanvas';
import MobileTopNavbar from './MobileTopNavbar';
import CreateFeedItemStateRepsForPage from './CreateFeedItemStateRepsForPage';
var carouselInterval = 30000;
var mobileCarouselInterval = 60000;
require('dotenv').config();

const CustomModal = ({ isOpen, onClose, title, content }) => (
  <Modal id='fullBillModal' show={isOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={onClose}>
    <Modal.Header id='billModalHeader' closeButton><h3>{title}</h3></Modal.Header>
    {content}
  </Modal>
);

const StateElectionPage = () => {
  const { stateName } = useParams();
  const [stateData, setStateData] = useState(null);
  const [showBioModal, setBioModal] = useState(false);
  const [aboutModalIsOpen, setAboutModalIsOpen] = useState(false);
  const [modalInfo, setModalInfo] = useState({
    isOpen: false,
    title: '',
    content: null
  });

  const openModal = (title, content) => {
    setModalInfo({
      isOpen: true,
      title,
      content
    });
  };

  const closeModal = () => {
    setModalInfo({
      isOpen: false,
      title: '',
      content: null
    });
  };

  const stateAbbreviations = {
    alabama: 'AL',
    alaska: 'AK',
    arizona: 'AZ',
    arkansas: 'AR',
    california: 'CA',
    colorado: 'CO',
    connecticut: 'CT',
    delaware: 'DE',
    florida: 'FL',
    georgia: 'GA',
    hawaii: 'HI',
    idaho: 'ID',
    illinois: 'IL',
    indiana: 'IN',
    iowa: 'IA',
    kansas: 'KS',
    kentucky: 'KY',
    louisiana: 'LA',
    maine: 'ME',
    maryland: 'MD',
    massachusetts: 'MA',
    michigan: 'MI',
    minnesota: 'MN',
    mississippi: 'MS',
    missouri: 'MO',
    montana: 'MT',
    nebraska: 'NE',
    nevada: 'NV',
    new_hampshire: 'NH',
    new_jersey: 'NJ',
    new_mexico: 'NM',
    new_york: 'NY',
    north_carolina: 'NC',
    north_dakota: 'ND',
    ohio: 'OH',
    oklahoma: 'OK',
    oregon: 'OR',
    pennsylvania: 'PA',
    rhode_island: 'RI',
    south_carolina: 'SC',
    south_dakota: 'SD',
    tennessee: 'TN',
    texas: 'TX',
    utah: 'UT',
    vermont: 'VT',
    virginia: 'VA',
    washington: 'WA',
    west_virginia: 'WV',
    wisconsin: 'WI',
    wyoming: 'WY',
  };

  useEffect(() => {
    const apiKey = process.env.REACT_APP_API_KEY;
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${apiKey}`,
      },
    };

    const formattedStateName = stateName.replace(/_/g, ' ');

    fetch('https://api.govglance.org/posts/recent?limit=54&skip=0&schema=united_states_of_america&table=state_election_info&order_by=updated_at', requestOptions)
      .then((response) => response.json())
      .then((data) => {
        const state = data.find((item) => item.state.toLowerCase() === formattedStateName.toLowerCase());
        if (state) {
          //console.log(`State data found: ${JSON.stringify(state)}`);
        } else {
          //console.error(`No data found for state: ${formattedStateName}`);
        }
        setStateData(state);
      })
      .catch((error) => console.error('Error fetching data:', error));
  }, [stateName]);

  if (!stateData) return <div id='homebg'><Spinner animation="grow" variant="light" /></div>;

  const {
    state,
    eac_url,
    phone_numbers,
    polling_info_urls,
    election_info,
  } = stateData;

  const formattedStateKey = state.toLowerCase().replace(/ /g, '_');
  const stateAbbreviation = stateAbbreviations[formattedStateKey];
  // console.log(`State: ${state}, Formatted Key: ${formattedStateKey}, Abbreviation: ${stateAbbreviation}`);


  const camelCaseStateName = state.split(' ').map((word, index) => {
    if (index === 0) return word.toLowerCase();
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  }).join('');
  const flagImageSrc = `../../${camelCaseStateName}Flag.png`;

  return (
    <>
      <BrowserView>
        <div id='homebg'>
          <DesktopNavbar />
          <div id='feedDisplay' style={{ padding: '20px', borderRadius: '18px', marginTop: '0em' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div id='profileImageState' style={{ width: '100px', height: '100px', overflow: 'hidden', marginLeft: '40px' }}>
                <Image id='profileImage-image' src={flagImageSrc} />
              </div>
              <div style={{ marginLeft: '20px', color: 'white', fontSize: '20px' }}>
                <h3>{state} Elections</h3>
              </div>
            </div>
            <Container>
              <div style={{ display: 'flex', flexWrap: 'wrap', gap: '30px', color: 'white', fontSize: '20px' }}>
                <div style={{ flex: '1 1 300px' }}>
                  <ul>
                    <li><a href={eac_url}>Register and Vote in {state}</a></li>
                    {Object.entries(election_info).map(([key, dates]) => (
                      <li style={{ fontSize: '16px' }} key={key}>
                        <strong>{key}:</strong>
                        <ul style={{ fontSize: '16px' }}>
                          {dates.map((date, index) => (
                            <li style={{ fontSize: '14px' }} key={index}>{date}</li>
                          ))}
                        </ul>
                      </li>
                    ))}
                  </ul>
                </div>
                <div style={{ flex: '1 1 300px' }}>
                  <ul>
                    {Object.entries(polling_info_urls).map(([key, url]) => (
                      <li key={key}>
                        <a href={url}>{key}</a>
                      </li>
                    ))}
                  </ul>
                </div>
                <div style={{ flex: '1 1 300px' }}>
                  <ul>
                    {phone_numbers.map((phone, index) => (
                      <li key={index}>{phone}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </Container>
          </div>
          <div id='branchPage'>
            <Container className='bgcolor'>
              <Container id='carouselBufferTwo'>
                <Row>
                  <Col id='legislativeColLeft'>
                    <div id='columnLefttPadding'>
                      <h4 id='billFeedHeadingElection' onClick={() =>
                        openModal(`2024 House Candidates`,
                          <GenerateFeedStateRepsAll
                            stateAbbreviation={stateAbbreviation}
                            candidateType='House' />
                        )}>
                        <b>2024 {state} House Candidates</b>
                      </h4>
                      <Carousel id='carouselInColumn' controls={false}>
                        <Carousel.Item id='legislativeCarousel' interval={carouselInterval}>
                          <Col id='homeCol'>
                            <div id='columnLefttPadding'>
                              <div id='house'></div>
                              <CreateFeedItemStateRepsForPage divID="house" candidateType="House" stateAbbreviation={stateAbbreviation} skip={0} />
                            </div>
                          </Col>
                        </Carousel.Item>
                        <Carousel.Item id='legislativeCarousel' interval={carouselInterval}>
                          <Col id='homeCol'>
                            <div id='columnLefttPadding'>
                              <div id='house2'></div>
                              <CreateFeedItemStateRepsForPage divID="house2" candidateType="House" stateAbbreviation={stateAbbreviation} skip={1} />
                            </div>
                          </Col>
                        </Carousel.Item>
                        <Carousel.Item id='legislativeCarousel' interval={carouselInterval}>
                          <Col id='homeCol'>
                            <div id='columnLefttPadding'>
                              <div id='house3'></div>
                              <CreateFeedItemStateRepsForPage divID="house3" candidateType="House" stateAbbreviation={stateAbbreviation} skip={2} />
                            </div>
                          </Col>
                        </Carousel.Item>
                        <Carousel.Item interval={carouselInterval}>
                          <div id='legislativeConInCol'>
                            <Col>
                              <div id='viewFullPageButton'>
                                <Button id='viewFullLegislativePageButtonMobile' size='lg' onClick={() =>
                                  openModal(`2024 House Candidates`,
                                    <GenerateFeedStateRepsAll
                                      stateAbbreviation={stateAbbreviation}
                                      candidateType='House' />
                                )}>View all 2024 {state} House Candidates</Button>
                              </div>
                            </Col>
                          </div>
                        </Carousel.Item>
                      </Carousel>
                    </div>
                  </Col>
                  <Col id='legislativeColLeft'>
                    <div id='columnLefttPadding'>
                      <h4 id='billFeedHeading' onClick={() =>
                        openModal(`2024 Senate Candidates`,
                          <GenerateFeedStateRepsAll
                            stateAbbreviation={stateAbbreviation}
                            candidateType='Senate' />
                        )}>
                        <b>2024 {state} Senate Candidates</b>
                      </h4>
                      <Carousel id='carouselInColumn' controls={false}>
                        <Carousel.Item id='legislativeCarousel' interval={carouselInterval}>
                          <Col id='homeCol'>
                            <div id='columnLefttPadding'>
                              <div id='senate'></div>
                              <CreateFeedItemStateRepsForPage divID="senate" candidateType="Senate" stateAbbreviation={stateAbbreviation} skip={0} />
                            </div>
                          </Col>
                        </Carousel.Item>
                        <Carousel.Item id='legislativeCarousel' interval={carouselInterval}>
                          <Col id='homeCol'>
                            <div id='columnLefttPadding'>
                              <div id='senate2'></div>
                              <CreateFeedItemStateRepsForPage divID="senate2" candidateType="Senate" stateAbbreviation={stateAbbreviation} skip={1} />
                            </div>
                          </Col>
                        </Carousel.Item>
                        <Carousel.Item id='legislativeCarousel' interval={carouselInterval}>
                          <Col id='homeCol'>
                            <div id='columnLefttPadding'>
                              <div id='senate3'></div>
                              <CreateFeedItemStateRepsForPage divID="senate3" candidateType="Senate" stateAbbreviation={stateAbbreviation} skip={2} />
                            </div>
                          </Col>
                        </Carousel.Item>
                        <Carousel.Item interval={carouselInterval}>
                          <div id='legislativeConInCol'>
                            <Col>
                              <div id='viewFullPageButton'>
                                <Button id='viewFullLegislativePageButtonMobile' size='lg' onClick={() =>
                                  openModal(`2024 Senate Candidates`,
                                    <GenerateFeedStateRepsAll
                                      stateAbbreviation={stateAbbreviation}
                                      candidateType='Senate' />
                                )}>View all 2024 {state} Senate Candidates</Button>
                              </div>
                            </Col>
                          </div>
                        </Carousel.Item>
                      </Carousel>
                    </div>
                  </Col>
                </Row>
              </Container>
            </Container>
            <CustomModal isOpen={modalInfo.isOpen} onClose={closeModal} title={modalInfo.title} content={modalInfo.content} />
          </div>
          <Modal id='aboutModal' show={aboutModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setAboutModalIsOpen(false)}>
            <div id='removedAlert'></div>
            <Modal.Header id='aboutModalHeader' closeButton><h3>About</h3></Modal.Header>
            <ModalBody id='aboutInfo'>
              <p id='aboutParagraph'>Gov Glance was created to drive civic engagement and support a healthier democracy by helping citizens get official Government news in an easy-to-navigate centralized location. There will never be any opinion or political analysis (That's your job), only information from official Government sources. Gov Glance is structured after the U.S. government that is broken into three branches Legislative, Executive, and Judicial. To learn more about the structure and functions of The U.S. Government go to <a href='https://www.usa.gov/branches-of-government'>https://www.usa.gov/branches-of-government</a>.</p>
              <p id='aboutParagraph'>Gov Glance Foundation Inc. is a non-profit that was created to operate govglance.org and continue to create educational tools that help people learn about the workings of society</p>
              <br />
              <h3>Privacy</h3>
              <br />
              <p id='aboutParagraph'>Gov Glance does not collect any personal user information. Features like collection save news items directly to your browser's local storage for your convenience and is not shared with Gov Glance servers. We do not collect, store, or send personal information to Google LLC, but we do use some Google services to deliver our app to you. For any questions into Google’s privacy practices here is a link to their privacy policy: <a href='https://policies.google.com/privacy?hl=en-US#intro'>https://policies.google.com/privacy?hl=en-US#intro</a>
                <br /><br />
                For site analytics we use plausible.io an open source privacy friendly analytics provider. <a href="https://plausible.io/about" target="_blank">https://plausible.io/about</a>
              </p>
              <br />
              <h3>Contact</h3>
              <p id='aboutParagraph'>
                Contact us via email: <a href='mailto:contact@govglance.org'>contact@govglance.org</a>
              </p>
              <br />
            </ModalBody>
          </Modal>
          <div id='navbarSpacer'></div><div id='navbarSpacer'></div>
          <footer id='homeFooter'>
            <Navbar>
              <Row id='footerNav'>
                <Nav variant='pills'>
                  <Nav.Link id='footerContent'>Gov Glance Foundation Inc.</Nav.Link>
                  <Nav.Item>
                    <Nav.Link id='footerContent' href='mailto:contact@govglance.org'>Contact</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link id='footerContent' onClick={(e) => {
                      e.preventDefault();
                      window.open('https://donate.stripe.com/28o3fXbdvbQO2RO288', "_blank")
                    }}><HeartFill id='donateIcon' /> Donate</Nav.Link>
                  </Nav.Item>
                  <Nav.Link onClick={(e) => {
                   e.preventDefault();
                   window.open('/about', "_self")}} id='footerContent'>Privacy</Nav.Link>
                </Nav>
              </Row>
            </Navbar>
          </footer>
        </div>
      </BrowserView>
      <MobileView>
        <div id='homebg'>
          <MobileTopNavbar />
          <div id="feedDisplayDepartment" style={{ padding: '10px', borderRadius: '18px' }}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div id="profileImageOnPageDepartmentState">
                  <Image id="profileImage-imageOnPageDepartment" src={flagImageSrc} />
                </div>
                <div style={{ marginLeft: '10px', color: 'white', fontSize: '18px', fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
                  <h5 id="branchHeadingMobile">{state} Elections</h5>
                </div>
              </div>
            </div>
            <div id="billText" style={{ color: 'white', fontSize: '16px', marginTop: '10px' }}>
              <ul>
                <li><a href={eac_url}>Register and Vote in {state}</a></li>
                {Object.entries(election_info).map(([key, dates]) => (
                  <li style={{ fontSize: '16px' }} key={key}>
                    <strong>{key}:</strong>
                    <ul style={{ fontSize: '16px' }}>
                      {dates.map((date, index) => (
                        <li style={{ fontSize: '14px' }} key={index}>{date}</li>
                      ))}
                    </ul>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <Button id='presidentViewMoreButton' onClick={() => setBioModal(true)}> <Files size={20}></Files> Read More</Button>
          <Modal id='fullBillModal' size="xl" aria-labelledby="contained-modal-title-vcenter" show={showBioModal} onHide={() => setBioModal(false)}>
            <Modal.Header id='billModalHeader' closeButton><h3> {state} Voting Information</h3></Modal.Header>
            <div id='fullBillFeed'>
              <ul>
                <li><a href={eac_url}>Register and Vote in {state}</a></li>
                {Object.entries(election_info).map(([key, dates]) => (
                  <li style={{ fontSize: '16px' }} key={key}>
                    <strong>{key}:</strong>
                    <ul style={{ fontSize: '16px' }}>
                      {dates.map((date, index) => (
                        <li style={{ fontSize: '14px' }} key={index}>{date}</li>
                      ))}
                    </ul>
                  </li>
                ))}
              </ul>
              <ul>
                {Object.entries(polling_info_urls).map(([key, url]) => (
                  <li key={key}>
                    <a href={url}>{key}</a>
                  </li>
                ))}
              </ul>
              <ul>
                {phone_numbers.map((phone, index) => (
                  <li key={index}>{phone}</li>
                ))}
              </ul>
            </div>
          </Modal>
          <CustomModal isOpen={modalInfo.isOpen} onClose={closeModal} title={modalInfo.title} content={modalInfo.content} />
          <h5 onClick={() =>
            openModal(`2024 House Candidates`,
              <GenerateFeedStateRepsAll
                stateAbbreviation={stateAbbreviation}
                candidateType='House' />
          )} id='presidentialFeedHeadingMobile'>2024 House Candidates</h5>
          <Col>
            <Carousel controls={false}>
              <Carousel.Item interval={mobileCarouselInterval}>
                <div id='legislativeConMobile'>
                  <Col>
                    <div className='courtNewsMobile' id='house'></div>
                    <CreateFeedItemStateRepsForPage divID="house" candidateType="House" stateAbbreviation={stateAbbreviation} skip={0} />
                  </Col>
                </div>
              </Carousel.Item>
              <Carousel.Item interval={mobileCarouselInterval}>
                <div id='legislativeConMobile'>
                  <Col>
                    <div className='courtNewsMobile' id='house2'></div>
                    <CreateFeedItemStateRepsForPage divID="house2" candidateType="House" stateAbbreviation={stateAbbreviation} skip={1} />
                  </Col>
                </div>
              </Carousel.Item>
              <Carousel.Item interval={mobileCarouselInterval}>
                <div id='legislativeConMobile'>
                  <Col>
                    <div className='courtNewsMobile' id='house3'></div>
                    <CreateFeedItemStateRepsForPage divID="house3" candidateType="House" stateAbbreviation={stateAbbreviation} skip={2} />
                  </Col>
                </div>
              </Carousel.Item>
              <Carousel.Item interval={mobileCarouselInterval}>
                <div id='legislativeConMobile'>
                  <Col>
                    <div id='viewFullPageButton'>
                      <Button id='viewFullLegislativePageButtonMobile' size='lg' onClick={() =>
                        openModal(`2024 House Candidates`,
                          <GenerateFeedStateRepsAll
                            stateAbbreviation={stateAbbreviation}
                            candidateType='House' />
                      )}>View all 2024 House Candidates</Button>
                    </div>
                  </Col>
                </div>
              </Carousel.Item>
            </Carousel>
          </Col>
          <h5 onClick={() =>
            openModal(`2024 Senate Candidates`,
              <GenerateFeedStateRepsAll
                stateAbbreviation={stateAbbreviation}
                candidateType='Senate' />
          )} id='presidentialFeedHeadingMobile'>2024 Senate Candidates</h5>
          <Col>
            <Carousel controls={false}>
              <Carousel.Item interval={mobileCarouselInterval}>
                <div id='legislativeConMobile'>
                  <Col>
                    <div className='courtNewsMobile' id='senate'></div>
                    <CreateFeedItemStateRepsForPage divID="senate" candidateType="Senate" stateAbbreviation={stateAbbreviation} skip={0} />
                  </Col>
                </div>
              </Carousel.Item>
              <Carousel.Item interval={mobileCarouselInterval}>
                <div id='legislativeConMobile'>
                  <Col>
                    <div className='courtNewsMobile' id='senate2'></div>
                    <CreateFeedItemStateRepsForPage divID="senate2" candidateType="Senate" stateAbbreviation={stateAbbreviation} skip={1} />
                  </Col>
                </div>
              </Carousel.Item>
              <Carousel.Item interval={mobileCarouselInterval}>
                <div id='legislativeConMobile'>
                  <Col>
                    <div className='courtNewsMobile' id='senate3'></div>
                    <CreateFeedItemStateRepsForPage divID="senate3" candidateType="Senate" stateAbbreviation={stateAbbreviation} skip={2} />
                  </Col>
                </div>
              </Carousel.Item>
              <Carousel.Item interval={mobileCarouselInterval}>
                <div id='legislativeConMobile'>
                  <Col>
                    <div id='viewFullPageButton'>
                      <Button id='viewFullLegislativePageButtonMobile' size='lg' onClick={() =>
                        openModal(`2024 Senate Candidates`,
                          <GenerateFeedStateRepsAll
                            stateAbbreviation={stateAbbreviation}
                            candidateType='Senate' />
                      )}>View all 2024 Senate Candidates</Button>
                    </div>
                  </Col>
                </div>
              </Carousel.Item>
            </Carousel>
          </Col>
          <Navbar id='navbarMobileBottom' fixed="bottom">
            <div id='changeBranchDropdownMobile'>
              <MobileOffCanvas />
            </div>
          </Navbar>
        </div>
      </MobileView>
    </>
  );
};

export default StateElectionPage;
