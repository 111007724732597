
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link, Switch, } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment, } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Tooltip, OverlayTrigger, Image } from 'react-bootstrap';
import { ArrowsAngleExpand,  ArrowsFullscreen, InfoCircle, FileText, ShieldShaded, PlayCircle, PauseCircle, HeartFill, Pen, People, Collection, Grid, ThreeDots, Stack, List, Files, FolderPlus, FolderCheck, Folder, Tree, FileMedical, FileMedicalFill, ChevronRight  } from 'react-bootstrap-icons'
import { BrowserView, MobileView } from 'react-device-detect';

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import Axios from 'axios'
import { render } from '@testing-library/react';
import FullBillFeed from './FullBillFeed';
import FullWhiteHouseFeed from './FullWhiteHouseFeed';
import FullUSCourtNews from './FullUSCourtNews';
import FullWhiteHouseDocuments from './FullWhiteHouseDocuments';
import DailyDigest from './DailyDigest';
import State from './State';
import Illinois from './state/Illinois';
import CollectionPage from './CollectionPage';
import NewLaws from './NewLaws';
import BillsEnrolled from './BillsEnrolled';
import DepartmentOfState from './DepartmentOfState';
import DepartmentOfTreasury from './DepartmentOfTreasury';
import CourtAppeals from './CourtAppeals';
import Reports from './Reports';
import DepartmentOfDefense from './DepartmentOfDefense';
import DepartmentOfJustice from './DepartmentOfJustice';
import DepartmentOfTheInterior from './DepartmentOfTheInterior';
import DepartmentOfAgriculture from './DepartmentOfAgriculture';
import DepartmentOfCommerce from './DepartmentOfCommerce';
import DepartmentOfLabor from './DepartmentOfLabor';
import DepartmentOfHealthAndHumanServices from './DepartmentOfHealthAndHumanServices';
import DepartmentOfHousingAndUrbanDevelopment from './DepartmentOfHousingAndUrbanDevelopment';
import DepartmentOfTransportation from './DepartmentOfTransportation';
import DepartmentOfEnergy from './DepartmentOfEnergy';
import DepartmentOfEducation from './DepartmentOfEducation';
import DepartmentOfVeteranAffairs from './DepartmentOfVeteranAffairs';
import DepartmentOfHomelandSecurity from './DepartmentOfHomelandSecurity';
import EnvironmentalProtectionAgency from './EnvironmentalProtectionAgency';
import NationalAeronauticsAndSpaceAdministration from './NationalAeronauticsAndSpaceAdministration';
import TrendingBills from './TrendingBills';
import GAOReports from './GAOReports';
import LegislativeBranch from './LegislativeBranch';
import HouseComitteeOnAgriculture from './HouseComitteeOnAgriculture';
import HouseCommitteeOnAppropriations from './HouseCommitteeOnArmedServices';
import NationalOceanicAndAtmosphericAdministration from './NationalOceanicAndAtmosphericAdministration';

import App from './App';
import Overlay from 'react-overlays/esm/Overlay';
import HouseCommitteeOnNaturalResources from './HouseCommitteeOnNaturalResources';
import NationalScienceFoundation from './NationalScienceFoundation';
import CDCNewsroom from './CDCNewsroom';
import Outbreaks from './Outbreaks'
import TravelNotices from './TravelNotices';
import FoodRecalls from './FoodRecalls'
import FoodSafety from './FoodSafety'
import MedWatch from './MedWatch'
import HealthyLivingFeatures from './HealthyLivingFeatures'
import PreventingChronicDisease from './PreventingChronicDisease'
import Covid19 from './Covid19'
import NovelCoronavirus from './NovelCoronavirus'
import VaccineUpdates from './VaccineUpdates'
import SeasonalFlu from './SeasonalFlu'
import {createDepartmentOfHealthAndHumanServices, 
  createCDCNewsroom, createCDCOutbreaks, 
  createCDCTravelNotices, createFoodSafety, 
  createFoodRecalls, createMedWatch, 
  createHealthyLivingFeatures, 
  createPreventingChronicDiseases, 
  createCovid19, createNovelCoronavirus, 
  createVaccineUpdates, createSeasonalFlu, Speak, CreateFeedItem} from "./Functions";
import DesktopNavbar from './DesktopNavbar';
import HealthMobile from './HealthMobile';

var collectionCounter = 0
var carouselInterval = 30000;
var mobileCarouselInterval = 60000;


 



//https://evening-plains-21303.herokuapp.com/











export const Health = () =>
 {
   render()
   
   {
  const [show, setShow] = useState(true);

  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [fullscreen, setFullScreen] = useState(true);
  const [statesModalIsOpen, setStatesModalIsOpen] = useState(false)

  const [fullBillModalIsOpen, setFullBillModalIsOpen] = useState(false)

  const [reportModalIsOpen, setReportModalIsOpen] = useState(false)


  const [whiteHouseFeedModalIsOpen, setWhiteHouseFeedModalIsOpen] = useState(false)
  const [whiteHouseDocsModalIsOpen, setWhiteHouseDocsModalIsOpen] = useState(false)


  const [courtNewsModalIsOpen, setCourtNewsModalIsOpen] = useState(false)
  const [courtModalIsOpen, setCourtModalIsOpen] = useState(false)

  const [courtAppealsModalIsOpen, setCourtAppealsModalIsOpen] = useState(false)

  const [newLawsModalIsOpen, setNewLawsModalIsOpen] = useState(false)

  const [billsEnrolledModalIsOpen, setBillsEnrolledModalIsOpen] = useState(false)

  const [legislativePageBillsEnrolledModalIsOpen, setLegislativePageBillsEnrolledModalIsOpen] = useState(false)


  const [departmentOfStateModalIsOpen, setDepartmentOfStateModalIsOpen] = useState(false)
  const [departmentOfTreasuryModalIsOpen, setDepartmentOfTreasuryModalIsOpen] = useState(false)
  const [departmentOfDefenseModalIsOpen, setDepartmentOfDefenseModalIsOpen] = useState(false)
  const [departmentOfJusticeModalIsOpen, setDepartmentOfJusticeModalIsOpen] = useState(false)
  const [departmentOfTheInteriorModalIsOpen, setDepartmentOfTheInteriorModalIsOpen] = useState(false)
  const [departmentOfAgricultureModalIsOpen, setDepartmentOfAgricultureModalIsOpen] = useState(false)
  const [departmentOfCommerceModalIsOpen, setDepartmentOfCommerceModalIsOpen] = useState(false)
  const [departmentOfLaborModalIsOpen, setDepartmentOfLaborModalIsOpen] = useState(false)
  const [departmentOfHealthAndHumanServicesModalIsOpen, setDepartmentOfHealthAndHumanServicesModalIsOpen] = useState(false)
  const [departmentOfHousingAndUrbanDevelopmentModalIsOpen, setDepartmentOfHousingAndUrbanDevelopmentModalIsOpen] = useState(false)
  const [departmentOfTransportationModalIsOpen, setDepartmentOfTransportationModalIsOpen] = useState(false)
  const [departmentOfEnergyModalIsOpen, setDepartmentOfEnergyModalIsOpen] = useState(false)
  const [departmentOfEducationModalIsOpen, setDepartmentOfEducationModalIsOpen] = useState(false)
  const [departmentOfVeteransAffairsModalIsOpen, setDepartmentOfVeteransAffairsModalIsOpen] = useState(false)
  const [departmentOfHomelandSecurityModalIsOpen, setDepartmentOfHomelandSecurityModalIsOpen] = useState(false)
  const [epaModalIsOpen, setEPAModalIsOpen] = useState(false)
  const [nsfModalIsOpen, setNSFModalIsOpen] = useState(false)
  const [cdcNewsroomModalIsOpen, setCDCNewsroomModalIsOpen] = useState(false)
  const [noaaModalIsOpen, setNOAAModalIsOpen] = useState(false)
  const [nasaModalIsOpen, setNASAModalIsOpen] = useState(false)
  const [trendingBillsModalIsOpen, setTrendingBillsModalIsOpen] = useState(false)
  const [goaReportsModalIsOpen, setGAOReportsModalIsOpen] = useState(false)
  const [houseComitteeOnAgricultureModalIsOpen, setHouseComitteeOnAgricultureModalIsOpen] = useState(false)
  const [houseCommitteeOnNaturalResourcesModalIsOpen, setHouseCommitteeOnNaturalResourcesModalIsOpen] = useState(false)
  const [outbreaksModalIsOpen, setOutbreaksModalIsOpen] = useState(false)
  const [travelNoticesModalIsOpen, setTravelNoticesModalIsOpen] = useState(false)
  
  const [foodRecallsModalIsOpen, setFoodRecallsModalIsOpen] = useState(false)
  const [foodSafetyModalIsOpen, setFoodSafetyModalIsOpen] = useState(false)
  const [medWatchModalIsOpen, setMedWatchModalIsOpen] = useState(false)
  const [healthyLivingFeaturesModalIsOpen, setHealthyLivingFeaturesModalIsOpen] = useState(false)
  const [preventingChronicDiseaseModalIsOpen, setPreventingChronicDiseaseModalIsOpen] = useState(false)
  const [covid19ModalIsOpen, setCovid19ModalIsOpen] = useState(false)
  const [novelCoronavirusModalIsOpen, setNovelCoronavirusModalIsOpen] = useState(false)
  const [vaccineUpdatesModalIsOpen, setVaccineUpdatesModalIsOpen] = useState(false)
  const [seasonalFluModalIsOpen, setSeasonalFluModalIsOpen] = useState(false)

  const [collectionsModalIsOpen, setCollectionsModalIsOpen] = useState(false)
  const [aboutModalIsOpen, setAboutModalIsOpen] = useState(false)



  const [declarationModalIsOpen, setDeclarationModalIsOpen] = useState(false)
  const [constitutionModalIsOpen, setConstitutionModalIsOpen] = useState(false)
  const [billOfRightsnModalIsOpen, setBillOfRightsModalIsOpen] = useState(false)


  return(
    <>
      <BrowserView>
        <div id='homePage'>
        <div id='homebg'>
        <DesktopNavbar/>
      
               <div id='sectionHeading'>      
               <Row>
              <h3 id='branchHeading' ><FileMedicalFill></FileMedicalFill><b>Health</b></h3> 
              
              </Row>
              </div> 
         
                     
            <div id='branchPage'>
              <Container class='bgcolor' >
                <Container id='carouselBufferTwo'>

                  <Row>
                    <Col id='legislativeColLeft'>
                      <div id='columnLefttPadding'>


                      <h4 id='departmentOfDefenseHeading' onClick={() => window.open('/us/departmentofhealthandhumanservices', "_self")}><b>Department of Health and Human Services</b> <ChevronRight id='clickChevron'></ChevronRight></h4>

                      <div id='healthAndHumanServicesDepartment'></div>
                      
                      {CreateFeedItem('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/health-xml/health_and_human_services.xml', 'healthAndHumanServicesDepartment', 'Department of Health and Human Services', 'Executive', 'Health', DepartmentOfHealthAndHumanServices)}


<Modal id='departmentOfStateModal' show={departmentOfHealthAndHumanServicesModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfHealthAndHumanServicesModalIsOpen(false)}>
  <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>Department of Health and Human Services</h3></Modal.Header>
  <DepartmentOfHealthAndHumanServices />
</Modal>
                       
                         
                      </div>
                    </Col>


                    <Col id='legislativeColRight'>
                      <div id='columnRightPadding'>

                      <h4 id='departmentOfJusticeHeading'><b>CDC Newsroom</b></h4>

<div id='cdcNewsroom'></div>

{CreateFeedItem('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/health-xml/cdc_newsroom.xml', 'cdcNewsroom', 'CDC Newsroom', 'N/A', 'Health', CDCNewsroom)}


<Modal id='departmentOfStateModal' show={cdcNewsroomModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCDCNewsroomModalIsOpen(false)}>
  <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>CDC Newsroom</h3></Modal.Header>
  <CDCNewsroom />
</Modal>



                      </div>
                    </Col>





                  </Row>
                </Container>
              </Container>
            </div>

            <div id='branchPage'>
              <Container class='bgcolor' >
                <Container id='carouselBufferTwo'>

                  <Row>
                    <Col id='legislativeColLeft'>
                      <div id='columnLefttPadding'>


                      <h4 id='departmentOfDefenseHeading'><b>CDC Outbreaks</b></h4>

<div id='outbreaks'></div>

{CreateFeedItem('https://tools.cdc.gov/api/v2/resources/media/285676.rss', 'outbreaks', 'CDC Outbreaks', 'N/A', 'Health', Outbreaks)}

<Modal id='departmentOfStateModal' show={outbreaksModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setOutbreaksModalIsOpen(false)}>
  <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>CDC Outbreaks</h3></Modal.Header>
  <Outbreaks />
</Modal>
                       
                         
                      </div>
                    </Col>


                    <Col id='legislativeColRight'>
                      <div id='columnRightPadding'>

                      <h4 id='departmentOfJusticeHeading'><b>CDC Travel Notices</b></h4>

<div id='travelNotices'></div>

{CreateFeedItem('https://tools.cdc.gov/api/v2/resources/media/285740.rss', 'travelNotices', 'CDC Travel Notices', 'N/A', 'Health', TravelNotices)}



<Modal id='departmentOfStateModal' show={travelNoticesModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTravelNoticesModalIsOpen(false)}>
  <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>CDC Travel Notices</h3></Modal.Header>
  <TravelNotices />
</Modal>



                      </div>
                    </Col>





                  </Row>
                </Container>
              </Container>
            </div>

            <div id='branchPage'>
              <Container class='bgcolor' >
                <Container id='carouselBufferTwo'>

                  <Row>
                    <Col id='legislativeColLeft'>
                      <div id='columnLefttPadding'>


                      <h4 id='departmentOfDefenseHeading'><b>Food Safety</b></h4>

<div id='foodSafety'></div>

{CreateFeedItem('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/health-xml/food_recalls.xml', 'foodSafety', 'Food Safety', 'N/A', 'Health', FoodSafety)}

<Modal id='departmentOfStateModal' show={foodSafetyModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setFoodSafetyModalIsOpen(false)}>
  <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>Food Safety</h3></Modal.Header>
  <FoodSafety/>
</Modal>
                       
                         
                      </div>
                    </Col>


                    <Col id='legislativeColRight'>
                      <div id='columnRightPadding'>

                      <h4 id='departmentOfJusticeHeading'><b>Food Recalls</b></h4>

<div id='foodRecalls'></div>

{CreateFeedItem('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/health-xml/food_recall.xml', 'foodRecalls', 'Food Recalls', 'N/A', 'Health', FoodRecalls)}


<Modal id='departmentOfStateModal' show={foodRecallsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setFoodRecallsModalIsOpen(false)}>
  <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>Food Recalls</h3></Modal.Header>
  <FoodRecalls />
</Modal>



                      </div>
                    </Col>





                  </Row>
                </Container>
              </Container>
            </div>

            <div id='branchPage'>
              <Container class='bgcolor' >
                <Container id='carouselBufferTwo'>

                  <Row>
                    <Col id='legislativeColLeft'>
                      <div id='columnLefttPadding'>


                      <h4 id='departmentOfDefenseHeading'><b>Med Watch</b></h4>

<div id='medWatch'></div>

{CreateFeedItem('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/health-xml/med_watch.xml', 'medWatch', 'Med Watch', 'N/A', 'Health', MedWatch)}

<Modal id='departmentOfStateModal' show={medWatchModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setMedWatchModalIsOpen(false)}>
  <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>Med Watch</h3></Modal.Header>
  <MedWatch/>
</Modal>
                       
                         
                      </div>
                    </Col>


                    <Col id='legislativeColRight'>
                      <div id='columnRightPadding'>

                      <h4 id='departmentOfJusticeHeading'><b>Healthy Living Features</b></h4>

<div id='healthyLivingFeatures'></div>

{CreateFeedItem('https://tools.cdc.gov/api/v2/resources/media/132036.rss', 'healthyLivingFeatures', 'Healthy Living Features', 'N/A', 'Health', HealthyLivingFeatures)}


<Modal id='departmentOfStateModal' show={healthyLivingFeaturesModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setHealthyLivingFeaturesModalIsOpen(false)}>
  <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>Healthy Living Features</h3></Modal.Header>
  <HealthyLivingFeatures/>
</Modal>



                      </div>
                    </Col>





                  </Row>
                </Container>
              </Container>
            </div>

            <div id='branchPage'>
              <Container class='bgcolor' >
                <Container id='carouselBufferTwo'>

                  <Row>
                    <Col id='legislativeColLeft'>
                      <div id='columnLefttPadding'>


                      <h4 id='departmentOfDefenseHeading'><b>Preventing Chronic Disease</b></h4>

<div id='preventingChronicDisease'></div>

{CreateFeedItem('https://tools.cdc.gov/api/v2/resources/media/322083.rss', 'preventingChronicDisease', 'Preventing Chronic Disease', 'N/A', 'Health', PreventingChronicDisease)}

<Modal id='departmentOfStateModal' show={preventingChronicDiseaseModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setPreventingChronicDiseaseModalIsOpen(false)}>
  <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>Preventing Chronic Disease</h3></Modal.Header>
  <PreventingChronicDisease/>
</Modal>
                       
                         
                      </div>
                    </Col>


                    <Col id='legislativeColRight'>
                      <div id='columnRightPadding'>

                      <h4 id='departmentOfJusticeHeading'><b>Covid-19</b></h4>

<div id='covid19'></div>

{CreateFeedItem('https://tools.cdc.gov/api/v2/resources/media/404952.rss', 'covid19', 'Covid-19 ', 'N/A', 'Health', Covid19)}


<Modal id='departmentOfStateModal' show={covid19ModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCovid19ModalIsOpen(false)}>
  <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>Covid-19</h3></Modal.Header>
  <Covid19/>
</Modal>



                      </div>
                    </Col>





                  </Row>
                </Container>
              </Container>
            </div>


            <div id='branchPage'>
              <Container class='bgcolor' >
                <Container id='carouselBufferTwo'>

                  <Row>
                    <Col id='legislativeColLeft'>
                      <div id='columnLefttPadding'>


                      <h4 id='departmentOfDefenseHeading'><b>Novel Coronavirus</b></h4>

<div id='novelCoronavirus'></div>

{CreateFeedItem('https://tools.cdc.gov/api/v2/resources/media/403372.rss', 'novelCoronavirus', 'Novel Coronavirus', 'N/A', 'Health', NovelCoronavirus)}



<Modal id='departmentOfStateModal' show={novelCoronavirusModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setNovelCoronavirusModalIsOpen(false)}>
  <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>Novel Coronavirus</h3></Modal.Header>
  <NovelCoronavirus/>
</Modal>
                       
                         
                      </div>
                    </Col>


                    <Col id='legislativeColRight'>
                      <div id='columnRightPadding'>

                      <h4 id='departmentOfJusticeHeading'><b>Vaccine Updates</b></h4>

<div id='vaccineUpdates'></div>

{CreateFeedItem('https://tools.cdc.gov/api/v2/resources/media/415826.rss', 'vaccineUpdates', 'Vaccine Updates', 'N/A', 'Health', VaccineUpdates)}


<Modal id='departmentOfStateModal' show={vaccineUpdatesModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setVaccineUpdatesModalIsOpen(false)}>
  <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>Vaccine Updates</h3></Modal.Header>
  <VaccineUpdates/>
</Modal>



                      </div>
                    </Col>





                  </Row>
                </Container>
              </Container>
            </div>

            <div id='branchPage'>
              <Container class='bgcolor' >
                <Container id='carouselBufferTwo'>

                  <Row>
                    <Col id='legislativeColLeft'>
                      <div id='columnLefttPadding'>


                      <h4 id='departmentOfDefenseHeading'><b>Seasonal Flu</b></h4>

<div id='seasonalFlu'></div>

{CreateFeedItem('https://tools.cdc.gov/api/v2/resources/media/277692.rss', 'seasonalFlu', 'Seasonal Flu', 'N/A', 'Health', SeasonalFlu)}

<Modal id='departmentOfStateModal' show={seasonalFluModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setSeasonalFluModalIsOpen(false)}>
  <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>Seasonal Flu</h3></Modal.Header>
  <SeasonalFlu/>
</Modal>
                       
                         
                      </div>
                    </Col>


                    <Col id='legislativeColRight'>
                      <div id='columnRightPadding'>





                      </div>
                    </Col>





                  </Row>
                </Container>
              </Container>
            </div>


       
            <Modal id='aboutModal' show={aboutModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setAboutModalIsOpen(false)}>
            <div id='removedAlert'></div>
            <Modal.Header id='aboutModalHeader' closeButton><h3>About</h3></Modal.Header>
  <ModalBody id='aboutInfo'><p id='aboutParagraph'>Gov Glance was created to drive civic engagement and support a 
              healthier democracy by helping citizens get official Government news in an easy-to-navigate centralized location. 
              There will never be any opinion or political analysis(That's your job), only information from official Government 
              sources. Gov Glance is structured after the U.S. government that is broken into three branches Legislative, 
              Executive, and Judicial. To learn more about the structure and functions of The U.S. Government go to <a href='https://www.usa.gov/branches-of-government'>https://www.usa.gov/branches-of-government</a>.
              </p>

              <p id='aboutParagraph'>
              Gov Glance Foundation Inc. is a non-profit that was created to operate govglance.org and continue to create educational tools that help people learn about the workings of society
              </p>
            <br></br>

            <h3>Privacy</h3>

            <br></br>

              <p id='aboutParagraph'>Gov Glance does not collect any personal user information. 
              Features like collection save news items directly to your browser's local storage for your convenience
              and is not shared with Gov Glance servers. We do not collect, store, or send personal information to Google LLC, 
              but we do use some Google services to deliver our app to you. For any questions into Google’s privacy practices 
              here is a link to their privacy policy: <a href='https://policies.google.com/privacy?hl=en-US#intro'>https://policies.google.com/privacy?hl=en-US#intro</a>

              <br></br><br></br>

              For site analytics we use plausible.io an open source privacy friendly analytics provider. <a href="https://plausible.io/about" target="_blank">https://plausible.io/about</a>
            
            </p>
            <br></br>


            <h3>Contact</h3>

            <p id='aboutParagraph'>
                      Contact us via email: <a href='mailto:contact@govglance.org'>contact@govglance.org</a>

            </p>

            <br></br>


            
            </ModalBody>
          </Modal>
 
          <footer id='homeFooter'>
      <Navbar>
        <Row id='footerNav'>
      <Nav variant='pills'>
        
     <Nav.Link id='footerContent'>Gov Glance Foundation Inc.
</Nav.Link>
<Nav.Item>
<Nav.Link id='footerContent' href='mailto:contact@govglance.org'>Contact</Nav.Link>
</Nav.Item>
<Nav.Item>
<Nav.Link id='footerContent' onClick={(e) => {
                   e.preventDefault();
                   window.open('https://donate.stripe.com/28o3fXbdvbQO2RO288', "_blank")
                 }}> <HeartFill id='donateIcon'/>    Donate</Nav.Link>
</Nav.Item>
<Nav.Link onClick={(e) => {
                   e.preventDefault();
                   window.open('/about', "_self")}} id='footerContent'>Privacy</Nav.Link>
    
     
 </Nav>
 </Row>
 </Navbar>
      </footer>
      


            




</div>
         </div>
          

              </BrowserView>

              <MobileView>

              <HealthMobile/>

              </MobileView>
              
    </>
  )
}
 }



  






export default Health;