import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link, Switch, } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment, } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Tooltip, OverlayTrigger, Image } from 'react-bootstrap';
import { ArrowsAngleExpand, ArrowsFullscreen, InfoCircle, FileText, ShieldShaded, PlayCircle, PauseCircle, HeartFill, Pen, People, Collection, Grid, ThreeDots, Stack, List, Files, FolderPlus, FolderCheck, Folder, Coin, Tree, ChevronRight } from 'react-bootstrap-icons'
import { BrowserView, MobileView } from 'react-device-detect';
import  "../../App.css";
import 'bootstrap/dist/css/bootstrap.min.css'
import { render } from '@testing-library/react';
import CanadaMobileTopNavbar from '../CanadaMobileTopNavbar';
import {CreateFeedItemCanadaProvence } from '../../Functions';
import AlbertaNews from './AlbertaNews';
import MobileOffCanvasCanada from '../../MobileOffCanvasCanada';

var mobileCarouselInterval = 60000;

export const AlbertaMobile = () =>   {
    render()
    {
      const [whiteHouseFeedModalIsOpen, setWhiteHouseFeedModalIsOpen] = useState(false)
      const [courtModalIsOpen, setCourtModalIsOpen] = useState(false)
      const [trendingBillsModalIsOpen, setTrendingBillsModalIsOpen] = useState(false)
      const nullEntry = [];
        var branch = "all";
    return(
       <>
      <CanadaMobileTopNavbar/>
       <div id='homebg'>
       <h5 id='stateFlagHeadingMobile'> <Image id='stateFlagMobile'  src='/albertaFlag.png'></Image>Alberta</h5>
             <Col>
               <h5 id='presidentialFeedHeadingMobile' onClick={() => setWhiteHouseFeedModalIsOpen(true)}>Updates</h5>

                  <div class='whMobile' id='delawareBills'> </div>
                  {CreateFeedItemCanadaProvence('https://api.io.canada.ca/io-server/gc/news/en/v2?location=ab48&sort=publishedDate&orderBy=desc&publishedDate%3E=2021-10-25&pick=100&format=atom&atomtitle=Alberta', 'delawareBills', 'Alberta Updates', 'N/A', 'N/A', AlbertaNews )}

                  <Modal id='mobileWhiteHouseFeedModal' show={whiteHouseFeedModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setWhiteHouseFeedModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3>Alberta Updates</h3></Modal.Header>
                  <AlbertaNews/>
                </Modal>
</Col>
<br></br>
            <h5 id='branchHeadingMobile'> <Pen></Pen> Legislative<ChevronRight id='clickChevron'></ChevronRight></h5>

       
 
                <Carousel controls={false}>
<Carousel.Item>
<div id='executiveConMobile' >
<Col>
                 <Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile'>  Coming Soon</h5></Row>
</Col>
</div>

 </Carousel.Item>
 </Carousel>
            <h5 id='branchHeadingMobile' ><People></People> Executive<ChevronRight id='clickChevron'></ChevronRight></h5>

<Carousel controls={false}>

<Carousel.Item interval={mobileCarouselInterval}>
<div id='executiveConMobile' >
<Col>
<Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile'>  Coming Soon</h5></Row>

  <Modal id='mobileWhiteHouseFeedModal' show={trendingBillsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3>Delaware Governor</h3></Modal.Header>
                  
                </Modal>
  </Col>       
</div>
  </Carousel.Item>
</Carousel>

  <h5 id='branchHeadingMobile'><ShieldShaded></ShieldShaded>   Judicial<ChevronRight id='clickChevron'></ChevronRight></h5>
            <Col>
            <Carousel controls={false}>
  <Carousel.Item interval={mobileCarouselInterval}>
<div id='executiveConMobile' >
          <Col>

          <Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile'>  Coming Soon</h5></Row>
</Col>
</div>

<Modal id='mobileWhiteHouseFeedModal' show={courtModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCourtModalIsOpen(false)}>
<Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3>Delaware Courts</h3></Modal.Header>
</Modal>

</Carousel.Item>
</Carousel>
  </Col>
  <Col>
  </Col>
           <br></br><br></br><br></br><br></br><br></br><br></br><Navbar id='navbarMobileBottom' fixed="bottom">    
<div id='changeBranchDropdownMobile'>
           <MobileOffCanvasCanada/>
            </div>
</Navbar>
          </div>
          </>
  )
}
 }
export default AlbertaMobile;

