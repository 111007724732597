import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link, Switch,  } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment,  } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Tooltip, OverlayTrigger, Image, ModalFooter } from 'react-bootstrap';
import { ArrowsAngleExpand, ArrowsFullscreen, InfoCircle, FileText,  ShieldShaded, CircleFill, Pen, People, ChatSquareQuote, ChatLeftQuote, BlockquoteRight, Collection, Grid, ThreeDots, Stack, List, Files, FolderPlus, FolderCheck, Folder, CaretRight, ChevronRight, ChevronCompactRight, CaretRightFill, ArrowRightShort,  ArrowRightCircle, PlayCircle, PauseCircle } from 'react-bootstrap-icons'
import { BrowserView, MobileView } from 'react-device-detect';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import Axios from 'axios'
import { render } from '@testing-library/react';
import FullBillFeed from './FullBillFeed';
import FullWhiteHouseFeed from './FullWhiteHouseFeed';
import FullUSCourtNews from './FullUSCourtNews';
import FullWhiteHouseDocuments from './FullWhiteHouseDocuments';
import DailyDigest from './DailyDigest';
import State from './State';
import Illinois from './state/Illinois';
import CollectionPage from './CollectionPage';
import NewLaws from './NewLaws';
import BillsEnrolled from './BillsEnrolled';
import DepartmentOfState from './DepartmentOfState';
import DepartmentOfTreasury from './DepartmentOfTreasury';
import CourtAppeals from './CourtAppeals';
import Reports from './Reports';
import DepartmentOfDefense from './DepartmentOfDefense';
import DepartmentOfJustice from './DepartmentOfJustice';
import DepartmentOfTheInterior from './DepartmentOfTheInterior';
import DepartmentOfAgriculture from './DepartmentOfAgriculture';
import DepartmentOfCommerce from './DepartmentOfCommerce';
import DepartmentOfLabor from './DepartmentOfLabor';
import DepartmentOfHealthAndHumanServices from './DepartmentOfHealthAndHumanServices';
import DepartmentOfHousingAndUrbanDevelopment from './DepartmentOfHousingAndUrbanDevelopment';
import DepartmentOfTransportation from './DepartmentOfTransportation';
import DepartmentOfEnergy from './DepartmentOfEnergy';
import DepartmentOfEducation from './DepartmentOfEducation';
import DepartmentOfVeteranAffairs from './DepartmentOfVeteranAffairs';
import DepartmentOfHomelandSecurity from './DepartmentOfHomelandSecurity';
import EnvironmentalProtectionAgency from './EnvironmentalProtectionAgency';
import NationalAeronauticsAndSpaceAdministration from './NationalAeronauticsAndSpaceAdministration';
import TrendingBills from './TrendingBills';
import GAOReports from './GAOReports';
import LegislativeBranch from './LegislativeBranch';
import HouseComitteeOnAgriculture from './HouseComitteeOnAgriculture';
import HouseCommitteeOnAppropriations from './HouseCommitteeOnArmedServices';
import HouseCommitteeOnOversightAndReform from './HouseCommitteeOnOversightAndReform';

import App from './App';
import Overlay from 'react-overlays/esm/Overlay';
import HouseCommitteeOnArmedServices from './HouseCommitteeOnArmedServices';
import HouseCommitteeOnTheBudget from './HouseCommitteeOnTheBudget';
import HouseCommitteeOnEducationAndLabor from './HouseCommitteeOnEducationAndLabor';
import HouseFloor from './HouseFloor';
import HouseCommitteeOnEnergyAndCommerce from './HouseCommitteeOnEnergyAndCommerce';
import HouseCommitteeOnEthics from './HouseCommitteeOnEthics';
import CongressionalHearings from './CongressionalHearings';
import HouseCommitteeOnFinacialServices from './HouseCommitteeOnFinacialServices';
import HouseCommitteeOnForeignAffairs from './HouseCommitteeOnForeignAffairs';
import HouseCommitteeOnHomelandSecurity from './HouseCommitteeOnHomelandSecurity';
import HouseCommitteeOnTheJudiciary from './HouseCommitteeOnTheJudiciary';
import HouseCommitteeOnNaturalResources from './HouseCommitteeOnNaturalResources';
import HouseCommitteeOnHouseAdministration from './HouseCommitteeOnHouseAdministration';
import CongressionalBudgetOffice from './CongressionalBudgetOffice';
import PresidentialProclamations from './PresidentialProclamations';
import ExecutiveOrders from './ExecutiveOrders';
import{ whiteHouseSearch, createPresidentialProclamations, createExecutiveOrders, createWhiteHouseDocuments, createWhiteHouseFeed,
    createWhiteHouseFeedMobile,createDepartmentOfState,
    createDepartmentOfDefense, 
    createDepartmentOfJustice, createDepartmentOfTheInterior,
    createDepartmentOfAgriculture, createDepartmentOfCommerce,
    createDepartmentOfCommerceMobile, createDepartmentOfLabor,
    createDepartmentOfHealthAndHumanServices, createDepartmentOfHousingAndUrbanDevelopment, createDepartmentOfEducation
    ,  createDepartmentOfVeteransAffairs, createDepartmentOfHomelandSecurity
    , createEnvironmentalProtectionAgency,
    createDepartmentOfTreasury, createNationalScienceFoundation, 
     createEnvironmentalProtectionAgencyMobile, createNationalAeronauticsAndSpaceAdministration, Speak, createFederalRegisterDefenseDepartment, 
     createFederalRegisterStateDepartment, createFederalRegisterDefense,
    CreateFeedItem, CreateFeedItemPresidentialDocuments, CreateExecutiveOrders,
    CreateFeedItemExecutiveOrders,
    CreateFeedItemBillUpdates,
    CreateFeedItemExecutiveOffice, CreateFeedItemMaterials } from './Functions'
import NationalScienceFoundation from './NationalScienceFoundation';
import FederalRegisterDefenseDepartment from './FederalRegisterDefenseDepartment';
import FederalRegisterStateDepartment from './FederalRegisterStateDepartment';
import FederalRegisterTreasuryDepartment from './FederalRegisterTreasuryDepartment';
import MobileOffCanvas from './MobileOffCanvas';
import MobileTopNavbar from './MobileTopNavbar';
import BillUpdates from './BillUpdates';
import CouncilOfEconomicAdvisers from './CouncilOfEconomicAdvisers';
import CouncilOnEnvironmentQuality from './CouncilOnEnvironmentQuality';
import DomesticPolicyCouncil from './DomesticPolicyCouncil';
import GenderPolicyCouncil from './GenderPolicyCouncil';
import NationalEconomicCouncil from './NationalEconomicCouncil';
import NationalSecurityCouncil from './NationalSecurityCouncil';
import OfficeOfIntergovernmentalAffairs from './OfficeOfIntergovernmentalAffairs';
import OfficeOfManagmentAndBudget from './OfficeOfManagmentAndBudget';
import OfficeOfNationalDrugControlPolicy from './OfficeOfNationalDrugControlPolicy';
import OfficeOfPublicEngagement from './OfficeOfPublicEngagement';
import OfficeOfScienceAndTechnologyPolicy from './OfficeOfScienceAndTechnologyPolicy';
import OfficeOfNationalCyberDirector from './OfficeOfNationalCyberDirector';
import PresidentialPersonnelOffice from './PresidentialPersonnelOffice';
import SenateAgricultureNutritionAndForestryCommittee from './SenateAgricultureNutritionAndForestryCommittee';
import SenateAppropriationCommittee from './SenateAppropriationCommittee';
import SenateArmedServicesCommittee from './SenateArmedServicesCommittee';
import SenateBankingHousingUrbanCommittee from './SenateBankingHousingUrbanCommittee';
import SenateCommerceScienceCommittee from './SenateCommerceScienceCommittee';
import SenateEnergyAndNRCommittee from './SenateEnergyAndNRCommittee';
import SenateEnvironmentAndPublicCommittee from './SenateEnvironmentAndPublicCommittee';
import SenateFinanceCommittee from './SenateFinanceCommittee';
import SenateForeignRelationsCommittee from './SenateForeignRelationsCommittee';
import SenateHealthEducationLaborCommittee from './SenateHealthEducationLaborCommittee';
import SenateHomelandSecCommittee from './SenateHomelandSecCommittee';
import SenateIndianAffairsCommittee from './SenateIndianAffairsCommittee';
import SenateRulesAndAdminCommittee from './SenateRulesAndAdminCommittee';
import SenateSmallBusinessCommittee from './SenateSmallBusinessCommittee';
import SenateVeteransAffairsCommittee from './SenateVeteransAffairsCommittee';
import SenateBudgetCommittee from './SenateBudgetCommittee';
import SenateJudiciaryCommittee from './SenateJudiciaryCommittee';
import SenateIntelligenceCommittee from './SenateIntelligenceCommittee';
import SenateFeed from './SenateFeed';
import HouseFeed from './HouseFeed';
import SenateDisplay from './SenateDisplay';



var collectionCounter = 0
var carouselInterval = 30000;
var mobileCarouselInterval = 30000;



  

 


  

export const SenateMobile = () =>   {
    render()
    
    {
        const [show, setShow] = useState(true);

       
        const [fullscreen, setFullScreen] = useState(true);
      
      
        const [fullBillModalIsOpen, setFullBillModalIsOpen] = useState(false)
      
        const [reportModalIsOpen, setReportModalIsOpen] = useState(false)
      
      
        const [whiteHouseFeedModalIsOpen, setWhiteHouseFeedModalIsOpen] = useState(false)
        const [whiteHouseDocsModalIsOpen, setWhiteHouseDocsModalIsOpen] = useState(false)
      
      
        const [courtNewsModalIsOpen, setCourtNewsModalIsOpen] = useState(false)
        const [courtModalIsOpen, setCourtModalIsOpen] = useState(false)
      
        const [courtAppealsModalIsOpen, setCourtAppealsModalIsOpen] = useState(false)
      
        const [newLawsModalIsOpen, setNewLawsModalIsOpen] = useState(false)
      
        const [billsEnrolledModalIsOpen, setBillsEnrolledModalIsOpen] = useState(false)
      
        const [legislativePageBillsEnrolledModalIsOpen, setLegislativePageBillsEnrolledModalIsOpen] = useState(false)
      
      
        const [departmentOfStateModalIsOpen, setDepartmentOfStateModalIsOpen] = useState(false)
        const [departmentOfTreasuryModalIsOpen, setDepartmentOfTreasuryModalIsOpen] = useState(false)
        const [departmentOfDefenseModalIsOpen, setDepartmentOfDefenseModalIsOpen] = useState(false)
        const [departmentOfJusticeModalIsOpen, setDepartmentOfJusticeModalIsOpen] = useState(false)
        const [departmentOfTheInteriorModalIsOpen, setDepartmentOfTheInteriorModalIsOpen] = useState(false)
        const [departmentOfAgricultureModalIsOpen, setDepartmentOfAgricultureModalIsOpen] = useState(false)
        const [departmentOfCommerceModalIsOpen, setDepartmentOfCommerceModalIsOpen] = useState(false)
        const [departmentOfLaborModalIsOpen, setDepartmentOfLaborModalIsOpen] = useState(false)
        const [departmentOfHealthAndHumanServicesModalIsOpen, setDepartmentOfHealthAndHumanServicesModalIsOpen] = useState(false)
        const [departmentOfHousingAndUrbanDevelopmentModalIsOpen, setDepartmentOfHousingAndUrbanDevelopmentModalIsOpen] = useState(false)
        const [departmentOfTransportationModalIsOpen, setDepartmentOfTransportationModalIsOpen] = useState(false)
        const [departmentOfEnergyModalIsOpen, setDepartmentOfEnergyModalIsOpen] = useState(false)
        const [departmentOfEducationModalIsOpen, setDepartmentOfEducationModalIsOpen] = useState(false)
        const [departmentOfVeteransAffairsModalIsOpen, setDepartmentOfVeteransAffairsModalIsOpen] = useState(false)
        const [departmentOfHomelandSecurityModalIsOpen, setDepartmentOfHomelandSecurityModalIsOpen] = useState(false)
        const [epaModalIsOpen, setEPAModalIsOpen] = useState(false)
        const [nsfModalIsOpen, setNSFModalIsOpen] = useState(false)
        const [nasaModalIsOpen, setNASAModalIsOpen] = useState(false)
        const [trendingBillsModalIsOpen, setTrendingBillsModalIsOpen] = useState(false)
        const [goaReportsModalIsOpen, setGAOReportsModalIsOpen] = useState(false)
        const [executiveOrdersModalIsOpen, setExecutiveOrdersModalIsOpen] = useState(false)
        const [presidentialProclamationsModalIsOpen, setPresidentialProclamationsModalIsOpen] = useState(false)
        const [statesModalIsOpen, setStatesModalIsOpen] = useState(false)
      
      
        const [collectionsModalIsOpen, setCollectionsModalIsOpen] = useState(false)
        const [aboutModalIsOpen, setAboutModalIsOpen] = useState(false)
      
      
        const [federalRegisterDefenseDepartmentModalIsOpen, setFederalRegisterDefenseDepartmentModalIsOpen] = useState(false)
  const [federalRegisterStateDepartmentModalIsOpen, setFederalRegisterStateDepartmentModalIsOpen] = useState(false)
        const [declarationModalIsOpen, setDeclarationModalIsOpen] = useState(false)
        const [constitutionModalIsOpen, setConstitutionModalIsOpen] = useState(false)
        const [billOfRightsnModalIsOpen, setBillOfRightsModalIsOpen] = useState(false)

        const [agricultureModalIsOpen, setAgricultureModalIsOpen] = useState(false)
        const [appropriationsModalIsOpen, setAppropriationsModalIsOpen] = useState(false)
        const [armedServicesModalIsOpen, setArmedServicesModalIsOpen] = useState(false)
        const [bankingModalIsOpen, setBankingsModalIsOpen] = useState(false)
        const [commerceModalIsOpen, setCommerceModalIsOpen] = useState(false)
        const [energyModalIsOpen, setEnergyModalIsOpen] = useState(false)
        const [environmentModalIsOpen, setEnvironmentModalIsOpen] = useState(false)
        const [financeModalIsOpen, setFinanceModalIsOpen] = useState(false)
        const [foreignModalIsOpen, setForeignModalIsOpen] = useState(false)
        const [healthModalIsOpen, setHealthtModalIsOpen] = useState(false)
        const [homelandModalIsOpen, setHomelandModalIsOpen] = useState(false)
        const [indianModalIsOpen, setIndianModalIsOpen] = useState(false)
        const [rulesModalIsOpen, setRulesModalIsOpen] = useState(false)
        const [smallBusinessModalIsOpen, setSmallBusinessModalIsOpen] = useState(false)
        const [veteransModalIsOpen, setVeteransModalIsOpen] = useState(false)
        const [budgetModalIsOpen, setBudgetModalIsOpen] = useState(false)
        const [judiciaryModalIsOpen, setJudiciaryModalIsOpen] = useState(false)
        const [intelligenceModalIsOpen, setIntelligenceModalIsOpen] = useState(false)
       
    
    var branch = "all";
  
    return(
       <>
   <MobileTopNavbar/>
  
  
  
  
  <div id='homebg'>

               
 
            <h5 id='branchHeadingMobile' onClick={() => window.open('/us/executiveoffice', "_self")}>Senate</h5>

       
            <Col>  
            <h5  onClick={() => window.open('/us/senators', "_self")} id='presidentialFeedHeadingMobile'>Senators<ChevronRight id='clickChevron'></ChevronRight></h5>
                        
                          
          <SenateDisplay/>
                       </Col>    

    
            <Col >
                  

                  <h5 id='presidentialFeedHeadingMobile' onClick={() => setAgricultureModalIsOpen(true)}>Committee on Agriculture, Nutrition, and Forestry Materials</h5>
                  <div class='courtNewsMobile'id='senateAgricultureNutritionAndForestCommittee'></div>
            
                    {CreateFeedItemMaterials('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/chamber/House_Feed.xml', 'senateAgricultureNutritionAndForestCommittee', 'Senate Committee on Agriculture, Nutrition, and Forestry', 'Legislative', 'Environment', HouseFeed)}

                    <Modal id='fullBillModal' show={agricultureModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" 
                    centered onHide={() =>setAgricultureModalIsOpen(false)}>
            <Modal.Header id='billModalHeader' closeButton><h3>Senate Committee on Agriculture, Nutrition, and Forestry Materials</h3></Modal.Header>
            <SenateAgricultureNutritionAndForestryCommittee/>
          </Modal>
         
              </Col>

              <Col>
              <h5 id='presidentialFeedHeadingMobile' onClick={() => setAppropriationsModalIsOpen(true)}>Committee on Appropriations Materials</h5>
                      <div class='courtNewsMobile'id='senateAppropriationsCommittee'></div>
                
                        {CreateFeedItemMaterials('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/senateCommittees/Committee%20on%20Appropriations.xml', 'senateAppropriationsCommittee', 'Senate Committee on Appropriations', 'Legislative', 'N/A', SenateAppropriationCommittee)}

                        <Modal id='fullBillModal' show={appropriationsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" 
                    centered onHide={() => setAppropriationsModalIsOpen(false)}>
            <Modal.Header id='billModalHeader' closeButton><h3>Senate Committee on Appropriations Materials</h3></Modal.Header>
            <SenateAppropriationCommittee/>
          </Modal>
              
              </Col>

              <Col>
              
              
              <h5 id='presidentialFeedHeadingMobile' onClick={() => setArmedServicesModalIsOpen(true)}>Committee on Armed Services Materials</h5>
                      <div class='courtNewsMobile'id='senateArmedServicesCommittee'></div>
                
                        {CreateFeedItemMaterials('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/senateCommittees/Committee%20on%20Armed%20Services.xml', 'senateArmedServicesCommittee', 'Senate Committee on Armed Services', 'Legislative', 'Foreign Affairs', SenateArmedServicesCommittee)}

                        <Modal id='fullBillModal' show={armedServicesModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" 
                    centered onHide={() => setArmedServicesModalIsOpen(false)}>
            <Modal.Header id='billModalHeader' closeButton><h3>Senate Committee on Armed Services Materials</h3></Modal.Header>
            <SenateArmedServicesCommittee/>
          </Modal>
              
              </Col>


              <Col>
              <h5 id='presidentialFeedHeadingMobile' onClick={() => setBankingsModalIsOpen(true)}>Committee on Banking, Housing, and Urban Affairs	Materials</h5>
                      <div class='courtNewsMobile'id='senateBankingHousingUrbanCommittee'></div>
                
                        {CreateFeedItemMaterials('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/senateCommittees/Committee%20on%20Appropriations.xml', 'senateBankingHousingUrbanCommittee', 'Senate Committee on Banking, Housing, and Urban Affairs', 'Legislative', 'Economy', SenateBankingHousingUrbanCommittee)}

                        <Modal id='fullBillModal' show={bankingModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" 
                    centered onHide={() => setBankingsModalIsOpen(false)}>
            <Modal.Header id='billModalHeader' closeButton><h3>Senate Committee on Banking, Housing, and Urban Affairs Materials</h3></Modal.Header>
            <SenateBankingHousingUrbanCommittee/>
          </Modal>
              
              </Col>

              <Col>
              
              <h5 id='presidentialFeedHeadingMobile' onClick={() => setCommerceModalIsOpen(true)}>Committee on Commerce, Science, and Transportation Materials</h5>
                      <div class='courtNewsMobile'id='senateCommerceScienceAndTransportationCommittee'></div>
                
                        {CreateFeedItemMaterials('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/senateCommittees/Committee%20on%20Commerce,%20Science,%20and%20Transportation.xml', 'senateCommerceScienceAndTransportationCommittee', 'Senate Committee on Commerce, Science, and Transportation', 'Legislative', 'N/A', SenateCommerceScienceCommittee)}

                        <Modal id='fullBillModal' show={commerceModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" 
                    centered onHide={() => setCommerceModalIsOpen(false)}>
            <Modal.Header id='billModalHeader' closeButton><h3>Senate Committee on Commerce, Science, and Transportation Materials</h3></Modal.Header>
            <SenateCommerceScienceCommittee/>
          </Modal>
              
              </Col>

              <Col>
              
              <h5 id='presidentialFeedHeadingMobile' onClick={() => setEnergyModalIsOpen(true)}>Committee on Energy and Natural Resources Materials</h5>
                      <div class='courtNewsMobile'id='senateEnergyCommittee'></div>
                
                        {CreateFeedItemMaterials('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/senateCommittees/Committee%20on%20Energy%20and%20Natural%20Resources.xml', 'senateEnergyCommittee', 'Senate Committee on Energy and Natural Resources', 'Legislative', 'N/A', SenateEnergyAndNRCommittee)}

              
                        <Modal id='fullBillModal' show={energyModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" 
                    centered onHide={() => setEnergyModalIsOpen(false)}>
            <Modal.Header id='billModalHeader' closeButton><h3>Senate Committee on Energy and Natural Resources Materials</h3></Modal.Header>
            <SenateEnergyAndNRCommittee/>
          </Modal>

              </Col>


              <Col>
              
              <h5 id='presidentialFeedHeadingMobile' onClick={() => setEnvironmentModalIsOpen(true)}>Committee on Environment and Public Work Materials</h5>
                      <div class='courtNewsMobile'id='senateEnvironmentAndPublicCommittee'></div>
                
                        {CreateFeedItemMaterials('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/senateCommittees/Committee%20on%20Environment%20and%20Public%20Works.xml', 'senateEnvironmentAndPublicCommittee', 'Senate Committee on Environment and Public Work', 'Legislative', 'N/A', SenateEnvironmentAndPublicCommittee)}
                      
                        <Modal id='fullBillModal' show={environmentModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" 
                    centered onHide={() => setEnvironmentModalIsOpen(false)}>
            <Modal.Header id='billModalHeader' closeButton><h3>Senate Committee on Environment and Public Work Materials</h3></Modal.Header>
            <SenateEnvironmentAndPublicCommittee/>
          </Modal>
              
              </Col>


<Col>
<h5 id='presidentialFeedHeadingMobile' onClick={() => setFinanceModalIsOpen(true)}>Committee on Finance Materials</h5>
                      <div class='courtNewsMobile'id='senateFinanceCommittee'></div>
                
                        {CreateFeedItemMaterials('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/senateCommittees/Committee%20on%20Finance.xml', 'senateFinanceCommittee', 'Senate Committee on Finance', 'Legislative', 'N/A', SenateFinanceCommittee)}

</Col>

<Modal id='fullBillModal' show={financeModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" 
                    centered onHide={() => setFinanceModalIsOpen(false)}>
            <Modal.Header id='billModalHeader' closeButton><h3>Senate Committee on Finance Materials</h3></Modal.Header>
            <SenateFinanceCommittee/>
          </Modal>


<Col>
<h5 id='presidentialFeedHeadingMobile' onClick={() => setForeignModalIsOpen(true)}>Committee on Foreign Relations Materials</h5>
                      <div class='courtNewsMobile'id='senateForeignRelationsCommittee'></div>
                
                        {CreateFeedItemMaterials('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/senateCommittees/Committee%20on%20Foreign%20Relations.xml', 'senateForeignRelationsCommittee', 'Senate Committee on Foreign Relations', 'Legislative', 'N/A', SenateForeignRelationsCommittee)}


                        <Modal id='fullBillModal' show={foreignModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" 
                    centered onHide={() => setForeignModalIsOpen(false)}>
            <Modal.Header id='billModalHeader' closeButton><h3>Senate Committee on Foreign Relations Materials</h3></Modal.Header>
            <SenateForeignRelationsCommittee/>
          </Modal>

</Col>


<Col>

<h5 id='presidentialFeedHeadingMobile' onClick={() => setHealthtModalIsOpen(true)}>Committee on Health, Education, Labor, and Pension Materials Materials</h5>
                      <div class='courtNewsMobile'id='senateHealthEducationLaborAndPensionsCommittee'></div>
                
                        {CreateFeedItemMaterials('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/senateCommittees/Committee%20on%20Health,%20Education,%20Labor,%20and%20Pensions.xml', 'senateHealthEducationLaborAndPensionsCommittee', 'Senate Committee on Health, Education, Labor, and Pensions', 'Legislative', 'N/A', SenateHealthEducationLaborCommittee)}

                        <Modal id='fullBillModal' show={healthModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" 
                    centered onHide={() => setHealthtModalIsOpen(false)}>
            <Modal.Header id='billModalHeader' closeButton><h3>Senate Committee on Health, Education, Labor, and Pension Materials</h3></Modal.Header>
            <SenateHealthEducationLaborCommittee/>
          </Modal>

</Col>



<Col>


<h5 id='presidentialFeedHeadingMobile' onClick={() => setHomelandModalIsOpen(true)}>Committee on Homeland Security and Governance Materials</h5>
                      <div class='courtNewsMobile'id='senateHomelandCommittee'></div>
                
                        {CreateFeedItemMaterials('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/senateCommittees/Committee%20on%20Homeland%20Security%20and%20Governmental%20Affairs.xml', 'senateHomelandCommittee', 'Senate Committee on Homeland Security and Governance', 'Legislative', 'N/A', SenateHomelandSecCommittee)}


                        <Modal id='fullBillModal' show={homelandModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" 
                    centered onHide={() => setHomelandModalIsOpen(false)}>
            <Modal.Header id='billModalHeader' closeButton><h3>Senate Committee on Homeland Security and Governance Materials</h3></Modal.Header>
            <SenateHomelandSecCommittee/>
          </Modal>

</Col>


<Col>
<h5 id='presidentialFeedHeadingMobile' onClick={() => setIndianModalIsOpen(true)}>Committee on Indian Affairs Materials</h5>
                      <div class='courtNewsMobile'id='senateIndianAffairsCommittee'></div>
                
                        {CreateFeedItemMaterials('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/senateCommittees/Committee%20on%20Indian%20Affairs.xml', 'senateIndianAffairsCommittee', 'Senate Committee on Indian Affairs', 'Legislative', 'N/A', SenateIndianAffairsCommittee)}

                        <Modal id='fullBillModal' show={indianModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" 
                    centered onHide={() => setIndianModalIsOpen(false)}>
            <Modal.Header id='billModalHeader' closeButton><h3>Senate Committee on Indian Affairs Materials</h3></Modal.Header>
            <SenateIndianAffairsCommittee/>
          </Modal>


</Col>


<Col>

<h5 id='presidentialFeedHeadingMobile' onClick={() => setRulesModalIsOpen(true)}>Committee on Rules and Administration Materials</h5>
                      <div class='courtNewsMobile'id='senateRulesAndAdminCommittee'></div>
                
                        {CreateFeedItemMaterials('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/senateCommittees/Committee%20on%20Rules%20and%20Administration.xml', 'senateRulesAndAdminCommittee', 'Senate Committee on Rules and Administration', 'Legislative', 'N/A', SenateRulesAndAdminCommittee)}

                        <Modal id='fullBillModal' show={rulesModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" 
                    centered onHide={() => setRulesModalIsOpen(false)}>
            <Modal.Header id='billModalHeader' closeButton><h3>Senate Committee on Rules and Administration Materials</h3></Modal.Header>
            <SenateRulesAndAdminCommittee/>
          </Modal>

</Col>


<Col>
<h5 id='presidentialFeedHeadingMobile' onClick={() => setSmallBusinessModalIsOpen(true)}>Committee on Small Business and Entrepreneurs Materials</h5>
                      <div class='courtNewsMobile'id='senateSmallBusinessCommittee'></div>
                
                        {CreateFeedItemMaterials('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/senateCommittees/Committee%20on%20Small%20Business%20and%20Entrepreneurship.xml', 'senateSmallBusinessCommittee', 'Senate Committee on Small Business and Entrepreneurs', 'Legislative', 'N/A', SenateSmallBusinessCommittee)}
                       
                        <Modal id='fullBillModal' show={smallBusinessModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" 
                    centered onHide={() => setSmallBusinessModalIsOpen(false)}>
            <Modal.Header id='billModalHeader' closeButton><h3>Senate Committee on Small Business and Entrepreneurs Materials</h3></Modal.Header>
            <SenateSmallBusinessCommittee/>
          </Modal>

</Col>


<Col>


<h5 id='presidentialFeedHeadingMobile' onClick={() => setVeteransModalIsOpen(true)}>Committee on Veterans' Affairs Materials</h5>
                      <div class='courtNewsMobile'id='senateVeteransAffairsCommittee'></div>
                
                        {CreateFeedItemMaterials('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/senateCommittees/Committee%20on%20Veterans\'%20Affairs.xml', 'senateVeteransAffairsCommittee', 'Senate Committee on Veterans Affairs', 'Legislative', 'N/A', SenateVeteransAffairsCommittee)}
                      
                        <Modal id='fullBillModal' show={veteransModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" 
                    centered onHide={() => setVeteransModalIsOpen(false)}>
            <Modal.Header id='billModalHeader' closeButton><h3>Senate Committee on Veterans' Affairs Materials</h3></Modal.Header>
            <SenateVeteransAffairsCommittee/>
          </Modal>

</Col>

<Col>
<h5 id='presidentialFeedHeadingMobile' onClick={() => setBudgetModalIsOpen(true)}>Committee on the Budget Materials</h5>
                      <div class='courtNewsMobile'id='senateBudgetCommittee'></div>
                
                        {CreateFeedItemMaterials('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/senateCommittees/Committee%20on%20the%20Budget.xml', 'senateBudgetCommittee', 'Senate Committee on the Budget', 'Legislative', 'N/A', SenateBudgetCommittee)}

                        <Modal id='fullBillModal' show={budgetModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" 
                    centered onHide={() => setBudgetModalIsOpen(false)}>
            <Modal.Header id='billModalHeader' closeButton><h3>Senate Committee on the Budget Materials</h3></Modal.Header>
            <SenateBudgetCommittee/>
          </Modal>

</Col>


<Col>

<h5 id='presidentialFeedHeadingMobile' onClick={() => setJudiciaryModalIsOpen(true)}>Committee on the Judiciary Materials</h5>
                      <div class='courtNewsMobile'id='senateJudiciaryCommittee'></div>
                
                        {CreateFeedItemMaterials('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/senateCommittees/Committee%20on%20the%20Judiciary.xml', 'senateJudiciaryCommittee', 'Senate Committee on the Judiciary', 'Legislative', 'N/A', SenateJudiciaryCommittee)}
                      
                      
                        <Modal id='fullBillModal' show={judiciaryModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" 
                    centered onHide={() => setJudiciaryModalIsOpen(false)}>
            <Modal.Header id='billModalHeader' closeButton><h3>Senate Committee on the Judiciary Materials</h3></Modal.Header>
            <SenateJudiciaryCommittee/>
          </Modal>

</Col>


<Col>

<h5 id='presidentialFeedHeadingMobile' onClick={() => setIntelligenceModalIsOpen(true)}>Select Committee on Intelligence Materials</h5>
                      <div class='courtNewsMobile'id='senateIntelligenceCommittee'></div>
                
                        {CreateFeedItemMaterials('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/senateCommittees/Select%20Committee%20on%20Intelligence.xml', 'senateIntelligenceCommittee', 'Select Committee on Intelligence', 'Legislative', 'N/A', SenateIntelligenceCommittee)}
                       
                        <Modal id='fullBillModal' show={intelligenceModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" 
                    centered onHide={() => setIntelligenceModalIsOpen(false)}>
            <Modal.Header id='billModalHeader' closeButton><h3>Select Committee on Intelligence Materials</h3></Modal.Header>
            <SenateIntelligenceCommittee/>
          </Modal>

</Col>

<Col>



</Col>
          <br></br>
          
            <br></br><br></br><br></br><br></br><br></br><br></br><Navbar id='navbarMobileBottom' fixed="bottom">    

          


       
<div id='changeBranchDropdownMobile'>

           <MobileOffCanvas/>
              
            </div>

           


           
 
             
 
 
 
 
 
 
</Navbar>
          </div>

          </>
  )
}
 }



  






export default SenateMobile;

