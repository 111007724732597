import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link, Switch, NavLink,  } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment,  } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Tooltip, OverlayTrigger, Image, ModalFooter } from 'react-bootstrap';
import { ArrowsAngleExpand, ArrowsFullscreen, InfoCircle, FileText, FileMedicalFill, Coin, Tree, Globe2,  ShieldShaded, CircleFill, Pen, People, ChatSquareQuote, ChatLeftQuote, BlockquoteRight, Collection, Grid, ThreeDots, Stack, List, Files, FolderPlus, X, FolderCheck, Folder, CaretRight, ChevronRight, ChevronCompactRight, CaretRightFill, ArrowRightShort,  ArrowRightCircle, PlayCircle, PauseCircle, GeoAlt, HeartFill, QuestionCircleFill, MicFill, ReceiptCutoff, Pencil } from 'react-bootstrap-icons'

import { BrowserView, MobileView } from 'react-device-detect';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import Axios from 'axios'
import { render } from '@testing-library/react';
import FullBillFeed from './FullBillFeed';
import FullWhiteHouseFeed from './FullWhiteHouseFeed';
import FullUSCourtNews from './FullUSCourtNews';
import FullWhiteHouseDocuments from './FullWhiteHouseDocuments';
import DailyDigest from './DailyDigest';
import State from './State';
import Illinois from './state/Illinois';
import CollectionPage from './CollectionPage';
import NationalScienceFoundation from './NationalScienceFoundation';
import NewLaws from './NewLaws';
import BillsEnrolled from './BillsEnrolled';
import DepartmentOfState from './DepartmentOfState';
import DepartmentOfTreasury from './DepartmentOfTreasury';
import CourtAppeals from './CourtAppeals';
import Reports from './Reports';
import DepartmentOfDefense from './DepartmentOfDefense';
import DepartmentOfJustice from './DepartmentOfJustice';
import DepartmentOfTheInterior from './DepartmentOfTheInterior';
import DepartmentOfAgriculture from './DepartmentOfAgriculture';
import DepartmentOfCommerce from './DepartmentOfCommerce';
import DepartmentOfLabor from './DepartmentOfLabor';
import DepartmentOfHealthAndHumanServices from './DepartmentOfHealthAndHumanServices';
import Outbreaks from './Outbreaks';
import TravelNotices from './TravelNotices';
import DepartmentOfHousingAndUrbanDevelopment from './DepartmentOfHousingAndUrbanDevelopment';
import DepartmentOfTransportation from './DepartmentOfTransportation';
import DepartmentOfEnergy from './DepartmentOfEnergy';
import DepartmentOfEducation from './DepartmentOfEducation';
import DepartmentOfVeteranAffairs from './DepartmentOfVeteranAffairs';
import DepartmentOfHomelandSecurity from './DepartmentOfHomelandSecurity';
import EnvironmentalProtectionAgency from './EnvironmentalProtectionAgency';
import NationalAeronauticsAndSpaceAdministration from './NationalAeronauticsAndSpaceAdministration';
import TrendingBills from './TrendingBills';
import GAOReports from './GAOReports';
import LegislativeBranch from './LegislativeBranch';
import HouseComitteeOnAgriculture from './HouseComitteeOnAgriculture';
import HouseCommitteeOnAppropriations from './HouseCommitteeOnArmedServices';
import HouseCommitteeOnOversightAndReform from './HouseCommitteeOnOversightAndReform';
import SecuritiesAndExchangeCommissionSpeechesAndStatments from './SecuritiesAndExchangeCommissionSpeechesAndStatments';
import App from './App';
import Overlay from 'react-overlays/esm/Overlay';
import HouseCommitteeOnArmedServices from './HouseCommitteeOnArmedServices';
import HouseCommitteeOnTheBudget from './HouseCommitteeOnTheBudget';
import HouseCommitteeOnEducationAndLabor from './HouseCommitteeOnEducationAndLabor';
import HouseFloor from './HouseFloor';
import FoodRecalls from './FoodRecalls';
import FoodSafety from './FoodSafety';
import HouseCommitteeOnEnergyAndCommerce from './HouseCommitteeOnEnergyAndCommerce';
import HouseCommitteeOnEthics from './HouseCommitteeOnEthics';
import CongressionalHearings from './CongressionalHearings';
import HouseCommitteeOnFinacialServices from './HouseCommitteeOnFinacialServices';
import HouseCommitteeOnForeignAffairs from './HouseCommitteeOnForeignAffairs';
import HouseCommitteeOnHomelandSecurity from './HouseCommitteeOnHomelandSecurity';
import HouseCommitteeOnTheJudiciary from './HouseCommitteeOnTheJudiciary';
import HouseCommitteeOnNaturalResources from './HouseCommitteeOnNaturalResources';
import HouseCommitteeOnHouseAdministration from './HouseCommitteeOnHouseAdministration';
import CongressionalBudgetOffice from './CongressionalBudgetOffice';
import PresidentialProclamations from './PresidentialProclamations';
import ExecutiveOrders from './ExecutiveOrders';
import SecuritiesAndExchangeCommissionPressReleases from './SecuritiesAndExchangeCommissionPressReleases';
import CDCNewsroom from './CDCNewsroom';
import HouseCommitteeOnIntelligence from './HouseCommitteeOnIntelligence';
import UNTopStories from './UNTopStories';
import
 {supremeCourtSearch, whiteHouseSearch, billFeedModal, 
  createHouseFloorToday, createSenateFloorToday, createTrendingBills,
  createNationalAeronauticsAndSpaceAdministration, 
  createEnvironmentalProtectionAgency, createDepartmentOfHomelandSecurity,
  createDepartmentOfVeteransAffairs, createDepartmentOfEducation,
  createDepartmentOfHousingAndUrbanDevelopment, 
  createDepartmentOfHealthAndHumanServices, createDepartmentOfLabor
, createDepartmentOfCommerce, createDepartmentOfAgriculture,
createDepartmentOfTheInterior, createDepartmentOfJustice,
createDepartmentOfTransportation, createDepartmentOfDefense,
createDepartmentOfTreasury, createDepartmentOfEnergy,
createDepartmentOfState, createBillsEnrolledMobile,
createBillsEnrolled, createNewLaws, createGAOReports,
createExecutiveOrders, createPresidentialProclamations, createCongressionalHearings,
createCongressionalBudgetOfficeMobile, createCongressionalBudgetOffice,
createHouseCommitteeOversightAndReform,
createHouseCommitteeOnNaturalResources,
createHouseCommitteeOnTheJudiciary,
createHouseCommitteeOnHouseAdministration,
createHouseCommitteeOnHomelandSecurity,
createHouseCommitteeOnForeignAffairs,
createHouseCommitteeOnFinancialServices,
createHouseCommitteeOnEthics,
createHouseCommitteeOnEnergyAndCommerce,
createHouseCommitteeOnEducationAndLabor,
createHouseCommitteeOnTheBudget,
createHouseCommitteeOnArmedServices,
createHouseCommitteeOnAppropriations,
createHouseCommitteeOnAgriculture,
createReports,
createBillFeedTwoMobile,
createBillFeedTwo,
createBillFeed, createWhiteHouseFeedMobile
, createWhiteHouseFeed, createWhiteHouseDocuments, 
createBillsPresentedFeed, createCourtAppealsMobile,
createCourtAppeals, createUSCourtNewsFeedMobile,
createUSCourtNewsFeed, saveToCollection,
createFullBillFeed, ListItem, Speak, feedText, variableTest, myMethod, myMethod2, createEconomicIndicators, 
createSecuritiesAndExchangeCommissionPressReleases, createNationWeatherServices, createCDCNewsroom,
createSecuritiesAndExchangeCommissionSpeechesAndStatements, createNationalScienceFoundation, createHouseCommitteeOnIntelligence,
 createUNTopStories, createCDCOutbreaks, createCDCTravelNotices, createFoodRecalls, createFoodSafety, createFederalElectionCommissionNews, createElectionAssistancCommissioneNews, createBillStatus } from './Functions'
import HomeMobile from './HomeMobile';

import EconomicIndicators from './EconomicIndicators';
import DesktopNavbar from './DesktopNavbar';
import FederalElectionCommissionNews from './FederalElectionCommissionNews';
import ElectionAssistanceComissionNews from './ElectionAssistanceComissionNews';
import BillStatus from './BillStatus';
import CollectionHomeMobile from './CollectionHomeMobile';
import DesktopNavbarCollection from './DesktopNavbarCollection';

var modalTitle =''
var noteTitle = ''
var savedTitle = ''
var savedDetailLink = ''
var savedDocLink = ''
var savedDocDate = ''
var savedDocNote = ''
var savedDocFeedName = ''
var savedDocBranchName = ''
var savedDocTopicName = ''
var savedDescription = ''
var noteAccessedIndex = 0








var collectionCounter = 0
var carouselInterval = 30000;
var mobileCarouselInterval = 60000;









  












  


export const CollectionHome = () =>   {
  render()
  
 
  {

  
  const [show, setShow] = useState(true);
  
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [fullscreen, setFullScreen] = useState(true);
  const [statesModalIsOpen, setStatesModalIsOpen] = useState(false)
  
  const [fullBillModalIsOpen, setFullBillModalIsOpen] = useState(false)
  const [billHelpModalIsOpen, setBillHelpModalIsOpen] = useState(false)

  const [reportModalIsOpen, setReportModalIsOpen] = useState(false)

  const [commentModalIsOpen, setCommentModalIsOpen] = useState(false)
  const [whiteHouseFeedModalIsOpen, setWhiteHouseFeedModalIsOpen] = useState(false)
  const [whiteHouseDocsModalIsOpen, setWhiteHouseDocsModalIsOpen] = useState(false)


  const [courtNewsModalIsOpen, setCourtNewsModalIsOpen] = useState(false)
  const [courtModalIsOpen, setCourtModalIsOpen] = useState(false)
  const [courtOpinionsModalIsOpen, setCourtOpinionsModalIsOpen] = useState(false)
  const [courtAppealsModalIsOpen, setCourtAppealsModalIsOpen] = useState(false)

  const [newLawsModalIsOpen, setNewLawsModalIsOpen] = useState(false)

  const [billsEnrolledModalIsOpen, setBillsEnrolledModalIsOpen] = useState(false)
  const [nsfModalIsOpen, setNSFModalIsOpen] = useState(false)
  const [legislativePageBillsEnrolledModalIsOpen, setLegislativePageBillsEnrolledModalIsOpen] = useState(false)
  const [secSAndSModalIsOpen, setSECSAndSModalIsOpen] = useState(false)
  const [economicIndicatorsModalIsOpen, setEconomicIndicatorsModalIsOpen] = useState(false)
  const [departmentOfStateModalIsOpen, setDepartmentOfStateModalIsOpen] = useState(false)
  const [departmentOfTreasuryModalIsOpen, setDepartmentOfTreasuryModalIsOpen] = useState(false)
  const [departmentOfDefenseModalIsOpen, setDepartmentOfDefenseModalIsOpen] = useState(false)
  const [departmentOfJusticeModalIsOpen, setDepartmentOfJusticeModalIsOpen] = useState(false)
  const [departmentOfTheInteriorModalIsOpen, setDepartmentOfTheInteriorModalIsOpen] = useState(false)
  const [departmentOfAgricultureModalIsOpen, setDepartmentOfAgricultureModalIsOpen] = useState(false)
  const [departmentOfCommerceModalIsOpen, setDepartmentOfCommerceModalIsOpen] = useState(false)
  const [departmentOfLaborModalIsOpen, setDepartmentOfLaborModalIsOpen] = useState(false)
  const [departmentOfHealthAndHumanServicesModalIsOpen, setDepartmentOfHealthAndHumanServicesModalIsOpen] = useState(false)
  const [departmentOfHousingAndUrbanDevelopmentModalIsOpen, setDepartmentOfHousingAndUrbanDevelopmentModalIsOpen] = useState(false)
  const [departmentOfTransportationModalIsOpen, setDepartmentOfTransportationModalIsOpen] = useState(false)
  const [departmentOfEnergyModalIsOpen, setDepartmentOfEnergyModalIsOpen] = useState(false)
  const [departmentOfEducationModalIsOpen, setDepartmentOfEducationModalIsOpen] = useState(false)
  const [departmentOfVeteransAffairsModalIsOpen, setDepartmentOfVeteransAffairsModalIsOpen] = useState(false)
  const [departmentOfHomelandSecurityModalIsOpen, setDepartmentOfHomelandSecurityModalIsOpen] = useState(false)
  const [epaModalIsOpen, setEPAModalIsOpen] = useState(false)
  const [nasaModalIsOpen, setNASAModalIsOpen] = useState(false)
  const [trendingBillsModalIsOpen, setTrendingBillsModalIsOpen] = useState(false)
  const [trendingBillsOptionOne, setTrendingBillsOptionOneModalIsOpen ] = useState(false)
  const [trendingBillsOptionTwo, setTrendingBillsOptionTwoModalIsOpen ] = useState(false)
  const [trendingBillsOptionThree, setTrendingBillsOptionThreeModalIsOpen ] = useState(false)
  const [trendingBillsOptionFour, setTrendingBillsOptionFourModalIsOpen ] = useState(false)
  const [trendingBillsOptionFive, setTrendingBillsOptionFiveModalIsOpen ] = useState(false)
  const [trendingBillsOptionSix, setTrendingBillsOptionSixModalIsOpen ] = useState(false)
  const [trendingBillsOptionSeven, setTrendingBillsOptionSevenModalIsOpen ] = useState(false)
  const [trendingBillsOptionEight, setTrendingBillsOptionEightModalIsOpen ] = useState(false)
  const [trendingBillsOptionNine, setTrendingBillsOptionNineModalIsOpen ] = useState(false)
  const [trendingBillsOptionTen, setTrendingBillsOptionTenModalIsOpen ] = useState(false)
  const [trendingBillsOptionEleven, setTrendingBillsOptionElevenModalIsOpen ] = useState(false)
  const [trendingBillsOptionTweleve, setTrendingBillsOptionTweleveModalIsOpen ] = useState(false)
  const [trendingBillsOptionThirteen, setTrendingBillsOptionThirteenModalIsOpen ] = useState(false)
  const [trendingBillsOptionFourteen, setTrendingBillsOptionFourteenModalIsOpen ] = useState(false)
  const [trendingBillsOptionFifteen, setTrendingBillsOptionFifteenModalIsOpen ] = useState(false)
  const [goaReportsModalIsOpen, setGAOReportsModalIsOpen] = useState(false)
    const [houseComitteeOnAgricultureModalIsOpen, setHouseComitteeOnAgricultureModalIsOpen] = useState(false)
    const [houseCommitteeOnAppropriationsModalIsOpen, setHouseCommitteeOnAppropriationsModalIsOpen] = useState(false)
    const [houseCommitteeOnArmedServicesModalIsOpen, setHouseCommitteeOnOnArmedServicesModalIsOpen] = useState(false)
    const [houseCommitteeOnTheBudgetModalIsOpen, setHouseCommitteeOnTheBudgetModalIsOpen] = useState(false)
    const [houseCommitteeOnEnergyAndCommerceModalIsOpen, setHouseCommitteeOnEnergyAndCommerceModalIsOpen] = useState(false)
    const [houseCommitteeOnEducationAndLaborModalIsOpen, setHouseCommitteeOnEducationAndLaborModalIsOpen] = useState(false)
    const [houseCommitteeOnEthicsModalIsOpen, setHouseCommitteeOnEthicsModalIsOpen] = useState(false)
    const [houseCommitteeOnFinancialServicesModalIsOpen, setHouseCommitteeOnFinancialServicesModalIsOpen] = useState(false)
    const [houseCommitteeOnForeignAffairsModalIsOpen, setHouseCommitteeOnForeignAffairsModalIsOpen] = useState(false)
    const [houseCommitteeOnHomelandSecurityModalIsOpen, setHouseCommitteeOnHomelandSecurityModalIsOpen] = useState(false)
    const [houseCommitteeOnHouseAdministrationModalIsOpen, setHouseCommitteeOnHouseAdministrationModalIsOpen] = useState(false)
    const [houseCommitteeOnTheJudiciaryModalIsOpen, setHouseCommitteeOnTheJudiciaryModalIsOpen] = useState(false)
    const [houseCommitteeOnNaturalResourcesModalIsOpen, setHouseCommitteeOnNaturalResourcesModalIsOpen] = useState(false)
    const [houseCommitteeOnOversightAndReformModalIsOpen, setHouseCommitteeOnOversightAndReformModalIsOpen] = useState(false)
    const [congressionalBudgetOfficeModalIsOpen, setCongressionalBudgetOfficeModalIsOpen] = useState(false)
    const [presidentialProclamationsModalIsOpen, setPresidentialProclamationsModalIsOpen] = useState(false)
    const [cdcNewsroomModalIsOpen, setCDCNewsroomModalIsOpen] = useState(false)
    const [executiveOrdersModalIsOpen, setExecutiveOrdersModalIsOpen] = useState(false)
    const [unTopStoriesModalIsOpen, setUNTopStoriesModalIsOpen] = useState(false)
    const [houseCommitteeOnIntelligenceModalIsOpen, setHouseCommitteeOnIntelligenceModalIsOpen] = useState(false)
    const [travelNoticesModalIsOpen, setTravelNoticesModalIsOpen] = useState(false)
  
    const [foodRecallsModalIsOpen, setFoodRecallsModalIsOpen] = useState(false)
    const [foodSafetyModalIsOpen, setFoodSafetyModalIsOpen] = useState(false)

    const [outbreaksModalIsOpen, setOutbreaksModalIsOpen] = useState(false)
    const [congressionalHearingsModalIsOpen, setCongressionalHearingsModalIsOpen] = useState(false)

    const [houseFloorModalIsOpen, setHouseFloorModalIsOpen] = useState(false)

    const [billStatusModalIsOpen, setBillStatusModalIsOpen] = useState(false)
    const [fecModalIsOpen, setFECModalIsOpen] = useState(false)
    const [eacModalIsOpen, setEACModalIsOpen] = useState(false)


  const [collectionsModalIsOpen, setCollectionsModalIsOpen] = useState(false)
  const [aboutModalIsOpen, setAboutModalIsOpen] = useState(false)
  const [secPressModalIsOpen, setSECPressModalIsOpen] = useState(false)

  const [alertModalIsOpen, setAlertModalIsOpen] = useState(true)


  const [declarationModalIsOpen, setDeclarationModalIsOpen] = useState(false)
  const [constitutionModalIsOpen, setConstitutionModalIsOpen] = useState(false)
  const [billOfRightsnModalIsOpen, setBillOfRightsModalIsOpen] = useState(false)
  const [searchQuery, setSearchQuery] = useState('');
  


var branch = "all";




function updateCollectionEntry(collectionIndex, saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionNote) 
{
  

collectionCounter = localStorage.getItem('collectionNumber')
  var collectionTitles = []
  var collectionDetailLinks = []
  var collectionDocLinks = []
  var collectionNote = []
  var title = 'title'.concat(collectionCounter-collectionIndex)
  var detailLink = 'detailLink'.concat(collectionCounter-collectionIndex)
  var docLink = 'docLink'.concat(collectionCounter-collectionIndex)
  var itemNote = 'itemNote'.concat(collectionCounter-collectionIndex)
  var itemDescription = 'itemDescription'.concat(collectionCounter-collectionIndex)


  localStorage.setItem(itemNote,saveCollectionNote)
 



    



}




function saveNote() {
  
  var searchItem = document.getElementById('noteModal').value
  

  var indexStore = noteAccessedIndex

  

  
  noteTitle = searchItem

  updateCollectionEntry(indexStore,savedTitle,savedDetailLink, savedDocLink, noteTitle, savedDescription)


  window.location.reload()




}

function pingLocalStorage(storageIndex)
{
  console.log(localStorage.getItem('branchName'.concat(storageIndex)))
  console.log(localStorage.getItem('itemDescription'.concat(storageIndex)))
}

   



function CollectionPageView(){

  const [collectionsModalIsOpen, setCollectionsModalIsOpen] = useState(false)
  // Original function values:
  // indexNumber, saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink
 
 { 
  
  function removeFromCollection(indexNumber, saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, saveCollectionNote, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName, saveCollectionDescription) {


 collectionCounter = localStorage.getItem('collectionNumber')
  var title = 'title'.concat(collectionCounter-indexNumber)
  var detailLink = 'detailLink'.concat(collectionCounter-indexNumber)
  var docLink = 'docLink'.concat(collectionCounter-indexNumber)
  var itemNote = 'itemNote'.concat(collectionCounter-indexNumber)
  var itemDate = 'itemDate'.concat(collectionCounter-indexNumber)
  var feedName = 'feedName'.concat(collectionCounter-indexNumber)
  var branchName = 'branchName'.concat(collectionCounter-indexNumber)
  var topicName = 'topicName'.concat(collectionCounter-indexNumber)
  var itemDescription = 'itemDescription'.concat(collectionCounter-indexNumber)


    localStorage.removeItem(title, saveCollectionTitle)

    localStorage.removeItem(detailLink, saveCollectionDetailLink)

    localStorage.removeItem(docLink, saveCollectionDocLink)

    localStorage.removeItem(itemNote, saveCollectionNote)

    localStorage.removeItem(itemDate, saveCollectionDate)

    localStorage.removeItem(feedName, saveCollectionFeedName)

    localStorage.removeItem(branchName, saveCollectionBranchName)

    localStorage.removeItem(topicName, saveCollectionTopicName)

    localStorage.removeItem(itemDescription, saveCollectionDescription)

    var collectionNumber = localStorage.getItem('collectionNumber')

    collectionNumber--

    var modalName = ''


   window.location.reload()

  }
  
  const [completeBillFeed, setCompleteBillFeed] = React.useState([]);
  const [docTitle, setDocTitle] = React.useState([]);
  const [docLink, setDocLink] = React.useState([]);
  const [detailLink, setDetailLink] = React.useState([]);
  const [docDate, setDocDate] = React.useState([]);
  const [docNote, setDocNote] = React.useState([]);
  const [docFeedName, setDocFeedName] = React.useState([]);
  const [docBranchName, setDocBranchName] = React.useState([]);
  const [docTopicName, setDocTopicName] = React.useState([]);
  const [docDescription, setDocDescription] = React.useState([]);
  


  React.useEffect(async () => {

   

    const fBillFeed = [];
    const fDocTitle = [];
    const fDocLink = []
    const fDetailLink = []
    const fDocDate = []
    const fDocNote = []
    const fDocFeedName = []
    const fDocBranchName = []
    const fDocTopicName = []
    const fDocDescription = []
    // for oldest (let index = 0; index <= localStorage.getItem('collectionNumber'); index++) 
    for (let index = localStorage.getItem('collectionNumber'); index >= 0; index--) 
    {



 
     const gtitle = localStorage.getItem('title'.concat(index).toString())
      const glink = localStorage.getItem('docLink'.concat(index).toString())
      const gdetail = localStorage.getItem('detailLink'.concat(index).toString())
      const gdate = localStorage.getItem('itemDate'.concat(index).toString())
      const gnote = localStorage.getItem('itemNote'.concat(index).toString())
      const gfeedname = localStorage.getItem('feedName'.concat(index).toString())
      const gbranchname = localStorage.getItem('branchName'.concat(index).toString())
      const gtopicname = localStorage.getItem('topicName'.concat(index).toString())
      const gitemdescription = localStorage.getItem('itemDescription'.concat(index).toString())
      const both = gtitle + glink
      fDocTitle.push(gtitle)
      fDocLink.push(glink)
      fBillFeed.push(both)
      fDetailLink.push(gdetail)
      fDocDate.push(gdate)
      fDocNote.push(gnote)
      fDocFeedName.push(gfeedname)
      fDocBranchName.push(gbranchname)
      fDocTopicName.push(gtopicname)
      fDocDescription.push(gitemdescription)
      
    }

    setCompleteBillFeed(fBillFeed);
    setDocTitle(fDocTitle);
    setDocLink(fDocLink);
    setDetailLink(fDetailLink)
    setDocDate(fDocDate)
    setDocNote(fDocNote)
    setDocFeedName(fDocFeedName)
    setDocBranchName(fDocBranchName)
    setDocTopicName(fDocTopicName)
    setDocDescription(fDocDescription)



  }, []);

  
    

  const renderData = () => {
    const filteredData = completeBillFeed.reduce((filtered, f, index) => {
      const title = docTitle[index] || '';
      const link = docLink[index] || '';
      const note = docNote[index] || '';
  
      if (title.toLowerCase().includes(searchQuery.toLowerCase()) ||
          link.toLowerCase().includes(searchQuery.toLowerCase()) ||
          note.toLowerCase().includes(searchQuery.toLowerCase())) {
        filtered.push({ index });
      }
  
      return filtered;
    }, []);
  
    return filteredData.map(({ index }) => {
 

  
      
      

      const title = docTitle[index];
      const link = docLink[index];
      const detail = detailLink[index]
      const note = docNote[index]

    
      if (docTitle[index] == null ) {
        
        return <div key={index} ></div>
      }


          // [x] If 2 buttons and note has no value
          else if (docLink[index] != 'null' && docNote[index] == 'null') {

            return <div key={index} >
              <div id='homebg' className='d-flex align-items-center justify-content-center'>
                <Container id='feedContainerNormalView'>
    
                  <Card.Title id='billText'>{docTitle[index]}</Card.Title>
                  <Card.Body id='billText' >
                    {docFeedName[index] + " | " + docBranchName[index] + " | " + docTopicName[index] } 
                    <br></br>
                  {docDescription[index]} 
                  <br></br>
                  {formatDate(docDate[index])}</Card.Body>
                  <br></br>
                  <div className="button-container">
                
 
                  <Button  onClick={(e) => {
                   
                    e.preventDefault();
                    window.open(detailLink[index], "_blank")
                  }}><InfoCircle></InfoCircle>Details</Button>
                  <Button  onClick={(e) => {
                    e.preventDefault();
                    window.open(
                      docLink[index], "_blank")
                  }}><FileText></FileText>Document</Button>
  <Button  onClick={() =>  {
                    noteTitle = docNote[index]
                    modalTitle= docTitle[index]
                    noteAccessedIndex = index
                    savedTitle = docTitle[index]
                    savedDetailLink = detailLink[index]
                    savedDocLink = docLink[index]
                    savedDocDate = docDate[index]
                    savedDocFeedName = docFeedName[index]
                    savedDocBranchName = docBranchName[index]
                    savedDocTopicName = docTopicName[index]
                    savedDescription = docDescription[index]

                    
                   displayNoteModal()
                  }} ><Pencil/></Button>

                  <Button  onClick={() =>
    
              
                    removeFromCollection(index, localStorage.getItem('title'.concat(index)), localStorage.getItem('detailLink'.concat(index)), localStorage.getItem('docLink'.concat(index)), null, localStorage.getItem('title'.concat(index)))
                   //localStorage.getItem('collectionNumber')

    
                  }
                  
                  
                  
                  
                  ><X></X></Button>
    </div>
    
                </Container>
                </div>
            </div>
    
          }
        

     
  // [x] If 1 button and note has no value
  else if (docLink[index] == 'null' && docNote[index] == 'null') 
  {

    return <div key={index} >
    <div id='homebg' className='d-flex align-items-center justify-content-center'>
     <Container id='feedContainerNormalView'>

     <Card.Title id='billText'>{docTitle[index]}</Card.Title>
                  <Card.Body id='billText' >{docFeedName[index] + " | " + docBranchName[index] + " | " + docTopicName[index] } 
                  <br></br>
                  {docDescription[index]} 
                  <br></br>
                  {formatDate(docDate[index])}</Card.Body>
       <br></br>
       <div className="button-container">


       <Button  onClick={(e) => {
        
         e.preventDefault();
         window.open(detailLink[index], "_blank")
       }}><InfoCircle></InfoCircle>Details</Button>
      <Button  onClick={() =>  {
                    noteTitle = docNote[index]
                    modalTitle= docTitle[index]
                    noteAccessedIndex = index
                    savedTitle = docTitle[index]
                    savedDetailLink = detailLink[index]
                    savedDocLink = docLink[index]
                    savedDocDate = docDate[index]
                    savedDocFeedName = docFeedName[index]
                    savedDocBranchName = docBranchName[index]
                    savedDocTopicName = docTopicName[index]
                    savedDescription = docDescription[index]

                    
                   displayNoteModal()
                  }} ><Pencil/></Button>
       <Button onClick={() =>

                              
         removeFromCollection(index, localStorage.getItem('title'.concat(index)), localStorage.getItem('detailLink'.concat(index)), localStorage.getItem('docLink'.concat(index)), localStorage.getItem('itemDate'.concat(index)), localStorage.getItem('itemNote'.concat(index)), localStorage.getItem('feedName'.concat(index)), localStorage.getItem('branchName'.concat(index)), localStorage.getItem('topicName'.concat(index)))

   



       }><X></X></Button>

</div>
     </Container>
     </div>
 </div>


  }

 
      // [x] If 1 button and note has value
      else if (docLink[index] == 'null' && docNote[index] != null){
        
        return <div key={index} >
           <div id='homebg' className='d-flex align-items-center justify-content-center'>
            <Container id='feedContainerNormalView'>

            <Card.Title id='billText'>{docTitle[index]}</Card.Title>
                  <Card.Body id='billText' >{docFeedName[index] + " | " + docBranchName[index] + " | " + docTopicName[index] }
                  <br></br> 
                  {docDescription[index]}
                  <br></br>
                  {formatDate(docDate[index])}
                  <br></br>
               {docNote[index]}</Card.Body>
               <br></br>
               <div className="button-container">
       

<div style={{padding: '10px'}}></div>
              <Button  onClick={(e) => {
              
                e.preventDefault();
                window.open(detailLink[index], "_blank")
              }}><InfoCircle></InfoCircle>Details</Button>
             <Button  onClick={() =>  {
                    noteTitle = docNote[index]
                    modalTitle= docTitle[index]
                    noteAccessedIndex = index
                    savedTitle = docTitle[index]
                    savedDetailLink = detailLink[index]
                    savedDocLink = docLink[index]
                    savedDocDate = docDate[index]
                    savedDocFeedName = docFeedName[index]
                    savedDocBranchName = docBranchName[index]
                    savedDocTopicName = docTopicName[index]
                    savedDescription = docDescription[index]

                    
                   displayNoteModal()
                  }} ><Pencil/></Button>
              <Button  onClick={() =>

       
                removeFromCollection(index, localStorage.getItem('title'.concat(index)), localStorage.getItem('detailLink'.concat(index)), localStorage.getItem('docLink'.concat(index)), localStorage.getItem('itemDate'.concat(index)), localStorage.getItem('itemNote'.concat(index)), localStorage.getItem('feedName'.concat(index)), localStorage.getItem('branchName'.concat(index)), localStorage.getItem('topicName'.concat(index)))




              }><X></X></Button>

</div>
            </Container>
            </div>
        </div>
      }

  
      // [x] If 2 buttons and note has value
      else  {
       
  
        return <div id='searchPadding' key={index} >
          <div id='homebg' className='d-flex align-items-center justify-content-center'>
            <Container id='feedContainerNormalView'>

            <Card.Title id='billText'>{docTitle[index]}</Card.Title>

                  <Card.Body id='billText' >
                  {docFeedName[index] + " | " + docBranchName[index] + " | " + docTopicName[index] }
                  <br></br>
                  {docDescription[index]}
                  <br></br>
                  {formatDate(docDate[index])}
                  <br></br>
                  {docNote[index]}
              </Card.Body>
              <br></br>
              <div className="button-container">
      


              <Button  onClick={(e) => {
               
                e.preventDefault();
                window.open(detailLink[index], "_blank")
              }}><InfoCircle></InfoCircle>Details</Button>
              <Button  onClick={(e) => {
                e.preventDefault();
                window.open(
                  docLink[index], "_blank")
              }}><FileText></FileText>Document</Button>
                <Button  onClick={() =>  {
                    noteTitle = docNote[index]
                    modalTitle= docTitle[index]
                    noteAccessedIndex = index
                    savedTitle = docTitle[index]
                    savedDetailLink = detailLink[index]
                    savedDocLink = docLink[index]
                    savedDocDate = docDate[index]
                    savedDocFeedName = docFeedName[index]
                    savedDocBranchName = docBranchName[index]
                    savedDocTopicName = docTopicName[index]
                    savedDescription = docDescription[index]

                    
                   displayNoteModal()
                  }} ><Pencil/></Button>
              <Button  onClick={() =>

                //pingLocalStorage(index)
                
                removeFromCollection(index, localStorage.getItem('title'.concat(index)), localStorage.getItem('detailLink'.concat(index)), localStorage.getItem('docLink'.concat(index)), null)

              }><X></X></Button>
</div>
            </Container>
            </div>
        </div>
        
       

      }
    
    });
  };

  return (
    <>


      


    


      {renderData()}
    </>
  )




}
}

function displayNoteModal()
{
  
  setCommentModalIsOpen(true)


   
  return( 
modalTitle

      )
}

function formatDate(inputDate) {
  const date = new Date(inputDate);
  const utcDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
  const day = utcDate.getDate();
  const month = utcDate.getMonth() + 1;
  const year = utcDate.getFullYear();

  return `${month}-${day}-${year}`;
}
  

  

  return (
    
    <>
    
    <div className="App">
   
    




      <BrowserView>
      <div id='homePage'>
        


        <div id='homebg'>

     

          

         <DesktopNavbar/>
         <h3 id='branchHeading'><Folder/> Collection </h3>
         <div id='searchFormPage'>
         <Form.Group className="d-flex justify-content-center align-items-center">
        <Form.Control  
          id='ggSearchCollection'
          placeholder='Search...'
          type='text'
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          color='white'
        />
        </Form.Group>
      </div>
      <br></br><br></br>
         <CollectionPageView/>

         <Modal id='fullBillModal' show={commentModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCommentModalIsOpen(false)}>
                <Modal.Header id='billModalHeader' closeButton><h3>{modalTitle}</h3></Modal.Header>



                <div id='homebg' className='d-flex align-items-center justify-content-center'>
            <Container id='feedContainerNormalView'>

              <Card.Title id='billText'>{modalTitle}</Card.Title>
              <Card.Body>{savedDocFeedName + " | " + "Branch: " + savedDocBranchName +  " | Topic: " + savedDocTopicName}
              {savedDescription}
              {formatDate(savedDocDate)}</Card.Body>
            

              <Form id='noteModalForm'>
              
              <Form.Group className="mb-3" >

                <Form.Control id="noteModal" placeholder={noteTitle}
                  type="text" />

              </Form.Group>
              </Form>

              <Button variant="primary" id='collectionNote' onClick={() => saveNote()}>
                  Save Note
                </Button>

        
             
            


            </Container>
            </div>

         
       </Modal> 
       <Modal id='aboutModal' show={aboutModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setAboutModalIsOpen(false)}>
            <div id='removedAlert'></div>
            <Modal.Header id='aboutModalHeader' closeButton><h3>About</h3></Modal.Header>
  <ModalBody id='aboutInfo'><p id='aboutParagraph'>Gov Glance was created to drive civic engagement and support a 
              healthier democracy by helping citizens get official Government news in an easy-to-navigate centralized location. 
              There will never be any opinion or political analysis(That's your job), only information from official Government 
              sources. Gov Glance is structured after the U.S. government that is broken into three branches Legislative, 
              Executive, and Judicial. To learn more about the structure and functions of The U.S. Government go to <a href='https://www.usa.gov/branches-of-government'>https://www.usa.gov/branches-of-government</a>.
              </p>

              <p id='aboutParagraph'>
              Gov Glance Foundation Inc. is a non-profit that was created to operate govglance.org and continue to create educational tools that help people learn about the workings of society
              </p>
            <br></br>

            <h3>Privacy</h3>

            <br></br>

              <p id='aboutParagraph'>Gov Glance does not collect any personal user information. 
              Features like collection save news items directly to your browser's local storage for your convenience
              and is not shared with Gov Glance servers. We do not collect, store, or send personal information to Google LLC, 
              but we do use some Google services to deliver our app to you. For any questions into Google’s privacy practices 
              here is a link to their privacy policy: <a href='https://policies.google.com/privacy?hl=en-US#intro'>https://policies.google.com/privacy?hl=en-US#intro</a>

              <br></br><br></br>

              For site analytics we use plausible.io an open source privacy friendly analytics provider. <a href="https://plausible.io/about" target="_blank">https://plausible.io/about</a>
            
            </p>
            <br></br>


            <h3>Contact</h3>

            <p id='aboutParagraph'>
                      Contact us via email: <a href='mailto:contact@govglance.org'>contact@govglance.org</a>

            </p>

            <br></br>


            
            </ModalBody>
          </Modal>
 
          <footer id='homeFooter'>
      <Navbar>
        <Row id='footerNav'>
      <Nav variant='pills'>
        
     <Nav.Link id='footerContent'>Gov Glance Foundation Inc.
</Nav.Link>
<Nav.Item>
<Nav.Link id='footerContent' href='mailto:contact@govglance.org'>Contact</Nav.Link>
</Nav.Item>
<Nav.Item>
<Nav.Link id='footerContent' onClick={(e) => {
                   e.preventDefault();
                   window.open('https://donate.stripe.com/28o3fXbdvbQO2RO288', "_blank")
                 }}> <HeartFill id='donateIcon'/>    Donate</Nav.Link>
</Nav.Item>
<Nav.Link onClick={(e) => {
                   e.preventDefault();
                   window.open('/about', "_self")}} id='footerContent'>Privacy</Nav.Link>
    
     
 </Nav>
 </Row>
 </Navbar>
      </footer>

      


    
          </div>
  

    </div>
      </BrowserView>
     

      <MobileView> 
        
        
                      <CollectionHomeMobile/>

     
      </MobileView>
      </div>
       
      


       
    </>

     
    
  );







}
}




export default CollectionHome;