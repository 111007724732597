
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link, Switch, } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment, } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Tooltip, OverlayTrigger, Image } from 'react-bootstrap';
import { ArrowsAngleExpand, ArrowsFullscreen, InfoCircle, FileText, ShieldShaded, PlayCircle, PauseCircle, Pen, People, Collection, Grid, ThreeDots, Stack, List, Files, FolderPlus, FolderCheck, Folder, Tree } from 'react-bootstrap-icons'
import { BrowserView, MobileView } from 'react-device-detect';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import Axios from 'axios'
import { render } from '@testing-library/react';
import FullBillFeed from './FullBillFeed';
import FullWhiteHouseFeed from './FullWhiteHouseFeed';
import FullUSCourtNews from './FullUSCourtNews';
import FullWhiteHouseDocuments from './FullWhiteHouseDocuments';
import DailyDigest from './DailyDigest';
import State from './State';
import Illinois from './state/Illinois';
import CollectionPage from './CollectionPage';
import NewLaws from './NewLaws';
import BillsEnrolled from './BillsEnrolled';
import DepartmentOfState from './DepartmentOfState';
import DepartmentOfTreasury from './DepartmentOfTreasury';
import CourtAppeals from './CourtAppeals';
import Reports from './Reports';
import DepartmentOfDefense from './DepartmentOfDefense';
import DepartmentOfJustice from './DepartmentOfJustice';
import DepartmentOfTheInterior from './DepartmentOfTheInterior';
import DepartmentOfAgriculture from './DepartmentOfAgriculture';
import DepartmentOfCommerce from './DepartmentOfCommerce';
import DepartmentOfLabor from './DepartmentOfLabor';
import DepartmentOfHealthAndHumanServices from './DepartmentOfHealthAndHumanServices';
import DepartmentOfHousingAndUrbanDevelopment from './DepartmentOfHousingAndUrbanDevelopment';
import DepartmentOfTransportation from './DepartmentOfTransportation';
import DepartmentOfEnergy from './DepartmentOfEnergy';
import DepartmentOfEducation from './DepartmentOfEducation';
import DepartmentOfVeteranAffairs from './DepartmentOfVeteranAffairs';
import DepartmentOfHomelandSecurity from './DepartmentOfHomelandSecurity';
import EnvironmentalProtectionAgency from './EnvironmentalProtectionAgency';
import NationalAeronauticsAndSpaceAdministration from './NationalAeronauticsAndSpaceAdministration';
import TrendingBills from './TrendingBills';
import GAOReports from './GAOReports';
import LegislativeBranch from './LegislativeBranch';
import HouseComitteeOnAgriculture from './HouseComitteeOnAgriculture';
import HouseCommitteeOnAppropriations from './HouseCommitteeOnArmedServices';
import NationalOceanicAndAtmosphericAdministration from './NationalOceanicAndAtmosphericAdministration';

import App from './App';
import Overlay from 'react-overlays/esm/Overlay';
import HouseCommitteeOnNaturalResources from './HouseCommitteeOnNaturalResources';
import NationalScienceFoundation from './NationalScienceFoundation';
import HouseCommitteeOnTransportationAndInfrastructure from './HouseCommitteeOnTransportationAndInfrastructure';
import { Speak, createEIAPressReleases, } from './Functions';
import DesktopNavbar from './DesktopNavbar';
import EIAPressReleases from './EIAPressReleases';



var collectionCounter = 0
var carouselInterval = 30000;
var mobileCarouselInterval = 60000;


 



//https://evening-plains-21303.herokuapp.com/


function saveToCollection(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionNote) {
  collectionCounter = localStorage.getItem('collectionNumber')
  collectionCounter++;

  var collectionTitles = []
  var collectionDetailLinks = []
  var collectionDocLinks = []
  var collectionNote = []
  //collectionTitles.push(saveCollectionTitle)
  //collectionDetailLinks.push(saveCollectionDetailLink)
  //collectionDocLinks.push(saveCollectionDocLink)








  var title = 'title'.concat(collectionCounter)
  var detailLink = 'detailLink'.concat(collectionCounter)
  var docLink = 'docLink'.concat(collectionCounter)
  var itemNote = 'itemNote'.concat(collectionCounter)

  localStorage.setItem(title, saveCollectionTitle)

  localStorage.setItem(detailLink, saveCollectionDetailLink)

  localStorage.setItem(docLink, saveCollectionDocLink)

  localStorage.setItem('collectionNumber', collectionCounter)
  localStorage.setItem(itemNote,saveCollectionNote)


  return collectionCounter;



}















export const Energy = () =>
 {
   render()
   
   {
  const [show, setShow] = useState(true);

  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [fullscreen, setFullScreen] = useState(true);

  const [statesModalIsOpen, setStatesModalIsOpen] = useState(false)
  const [fullBillModalIsOpen, setFullBillModalIsOpen] = useState(false)

  const [reportModalIsOpen, setReportModalIsOpen] = useState(false)


  const [whiteHouseFeedModalIsOpen, setWhiteHouseFeedModalIsOpen] = useState(false)
  const [whiteHouseDocsModalIsOpen, setWhiteHouseDocsModalIsOpen] = useState(false)


  const [courtNewsModalIsOpen, setCourtNewsModalIsOpen] = useState(false)
  const [courtModalIsOpen, setCourtModalIsOpen] = useState(false)

  const [courtAppealsModalIsOpen, setCourtAppealsModalIsOpen] = useState(false)

  const [newLawsModalIsOpen, setNewLawsModalIsOpen] = useState(false)

  const [billsEnrolledModalIsOpen, setBillsEnrolledModalIsOpen] = useState(false)

  const [legislativePageBillsEnrolledModalIsOpen, setLegislativePageBillsEnrolledModalIsOpen] = useState(false)


  const [departmentOfStateModalIsOpen, setDepartmentOfStateModalIsOpen] = useState(false)
  const [departmentOfTreasuryModalIsOpen, setDepartmentOfTreasuryModalIsOpen] = useState(false)
  const [departmentOfDefenseModalIsOpen, setDepartmentOfDefenseModalIsOpen] = useState(false)
  const [departmentOfJusticeModalIsOpen, setDepartmentOfJusticeModalIsOpen] = useState(false)
  const [departmentOfTheInteriorModalIsOpen, setDepartmentOfTheInteriorModalIsOpen] = useState(false)
  const [departmentOfAgricultureModalIsOpen, setDepartmentOfAgricultureModalIsOpen] = useState(false)
  const [departmentOfCommerceModalIsOpen, setDepartmentOfCommerceModalIsOpen] = useState(false)
  const [departmentOfLaborModalIsOpen, setDepartmentOfLaborModalIsOpen] = useState(false)
  const [departmentOfHealthAndHumanServicesModalIsOpen, setDepartmentOfHealthAndHumanServicesModalIsOpen] = useState(false)
  const [departmentOfHousingAndUrbanDevelopmentModalIsOpen, setDepartmentOfHousingAndUrbanDevelopmentModalIsOpen] = useState(false)
  const [departmentOfTransportationModalIsOpen, setDepartmentOfTransportationModalIsOpen] = useState(false)
  const [departmentOfEnergyModalIsOpen, setDepartmentOfEnergyModalIsOpen] = useState(false)
  const [departmentOfEducationModalIsOpen, setDepartmentOfEducationModalIsOpen] = useState(false)
  const [departmentOfVeteransAffairsModalIsOpen, setDepartmentOfVeteransAffairsModalIsOpen] = useState(false)
  const [departmentOfHomelandSecurityModalIsOpen, setDepartmentOfHomelandSecurityModalIsOpen] = useState(false)
  const [epaModalIsOpen, setEPAModalIsOpen] = useState(false)
  const [nsfModalIsOpen, setNSFModalIsOpen] = useState(false)
  const [noaaModalIsOpen, setNOAAModalIsOpen] = useState(false)
  const [nasaModalIsOpen, setNASAModalIsOpen] = useState(false)
  const [trendingBillsModalIsOpen, setTrendingBillsModalIsOpen] = useState(false)
  const [goaReportsModalIsOpen, setGAOReportsModalIsOpen] = useState(false)
  const [houseComitteeOnAgricultureModalIsOpen, setHouseComitteeOnAgricultureModalIsOpen] = useState(false)
  const [houseCommitteeOnNaturalResourcesModalIsOpen, setHouseCommitteeOnNaturalResourcesModalIsOpen] = useState(false)
  const [houseCommitteeOnTransportationAndInfrastructureModalIsOpen, setHouseCommitteeOnTransportationAndInfrastructureModalIsOpen] = useState(false)
  const [eiaPressReleasesModalIsOpen, setEIAPressReleasesModalIsOpen] = useState(false)


  const [collectionsModalIsOpen, setCollectionsModalIsOpen] = useState(false)
  const [aboutModalIsOpen, setAboutModalIsOpen] = useState(false)



  const [declarationModalIsOpen, setDeclarationModalIsOpen] = useState(false)
  const [constitutionModalIsOpen, setConstitutionModalIsOpen] = useState(false)
  const [billOfRightsnModalIsOpen, setBillOfRightsModalIsOpen] = useState(false)


  return(
    <>
      <BrowserView>
        <div id='homePage'>
<DesktopNavbar/>

        <div id='homebg'>

          
               <div id='sectionHeading'>      
               <Row>
              <h3 id='branchHeading' ><Tree></Tree><b>Energy</b></h3> 
              
              </Row>
              </div> 
         
                     
            <div id='branchPage'>
              <Container class='bgcolor' >
                <Container id='carouselBufferTwo'>

                  <Row>
                    <Col id='legislativeColLeft'>
                      <div id='columnLefttPadding'>


                        <h4 id='departmentOfJusticeHeading'><b>EIA Press Releases</b></h4>

                        <div id='eia'></div>
                        <div id='eia2'></div>
                        {createEIAPressReleases()}
                        <h6 id='viewMoreTag'>
                          <div class="d-grid gap-2">
                            <Button size='lg' id='departmentOfHousingAndUrbanDevelopmentViewMoreButton' onClick={() => setEIAPressReleasesModalIsOpen(true)}> <Files size={20}></Files> View More</Button></div>
                        </h6>

                        <Modal id='departmentOfStateModal' show={eiaPressReleasesModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setEIAPressReleasesModalIsOpen(false)}>
                          <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>EIA Press Releases</h3></Modal.Header>
                          <EIAPressReleases />
                        </Modal>
                         
                      </div>
                    </Col>


                    <Col id='legislativeColRight'>
                      <div id='columnRightPadding'>

                        <h4 id='departmentOfJusticeHeading'><b>EPA</b></h4>

                        <div id='epa'></div>
                        <div id='epa2'></div>
                      
                        <h6 id='viewMoreTag'>
                          <div class="d-grid gap-2">
                            <Button size='lg' id='departmentOfHousingAndUrbanDevelopmentViewMoreButton' onClick={() => setEPAModalIsOpen(true)}> <Files size={20}></Files> View More</Button></div>
                        </h6>

                        <Modal id='departmentOfStateModal' show={epaModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setEPAModalIsOpen(false)}>
                          <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>Environmental Protection Agency (EPA)</h3></Modal.Header>
                          <EnvironmentalProtectionAgency />
                        </Modal>



                      </div>
                    </Col>





                  </Row>
                </Container>
              </Container>
            </div>


            <div id='branchPage'>
              <Container class='bgcolor' >
                <Container id='carouselBufferTwo'>

                  <Row>
                    <Col id='legislativeColLeft'>
                      <div id='columnLefttPadding'>


                      <h4 id='departmentOfJusticeHeading'><b>National Science Foundation Research News</b></h4>

<div id='nsf'></div>
<div id='nsf2'></div>

<h6 id='viewMoreTag'>
  <div class="d-grid gap-2">
    <Button size='lg' id='departmentOfHousingAndUrbanDevelopmentViewMoreButton' onClick={() => setNSFModalIsOpen(true)}> <Files size={20}></Files> View More</Button></div>
</h6>

<Modal id='departmentOfStateModal' show={nsfModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setNSFModalIsOpen(false)}>
  <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>National Science Foundation Research News</h3></Modal.Header>
  <NationalScienceFoundation />
</Modal>

                      </div>
                    </Col>


                    <Col id='legislativeColRight'>
                      <div id='columnRightPadding'>

                        <h4 id='departmentOfJusticeHeading'><b>NASA</b></h4>

                        <div id='nasa'></div>
                        <div id='nasa2'></div>
                        
                        <Button size='lg' id='whDocsViewMoreButton' onClick={() => setNASAModalIsOpen(true)}> <Files size={20}></Files> View More</Button>

                        <Modal id='whiteHouseDocsModal' show={nasaModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setNASAModalIsOpen(false)}>
                          <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>National Aeronautics and Space Administration (NASA)</h3></Modal.Header>
                          <NationalAeronauticsAndSpaceAdministration />
                        </Modal>
                        {/*

                        <h4 id='departmentOfJusticeHeading'><b>NOAA Ocean Service News</b></h4>

                        <div id='noaaOSN'></div>
                        <div id='noaaOSN2'></div>
                        {createNationalOceanicAndAtmosphericAdministrationOSN()}
                        <Button size='lg' id='whDocsViewMoreButton' onClick={() => setNOAAModalIsOpen(true)}> <Files size={20}></Files> View More</Button>

                        <Modal id='whiteHouseDocsModal' show={noaaModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setNOAAModalIsOpen(false)}>
                          <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>NOAA Ocean Service News</h3></Modal.Header>
                          <createNationalOceanicAndAtmosphericAdministrationOSN />
                        </Modal>
                        */}



                      </div>
                    </Col>





                  </Row>
                </Container>
              </Container>
            </div>



         


            




</div>
         </div>
          

              </BrowserView>

        
              
    </>
  )
}
 }



  






export default Energy;