
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link, Switch, } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment, } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Tooltip, OverlayTrigger, Image } from 'react-bootstrap';
import { ArrowsAngleExpand, ArrowsFullscreen, InfoCircle, FileText, ShieldShaded, Pen, PauseCircle, PlayCircle, People, HeartFill, Collection, Grid, ThreeDots, Stack, List, Files, FolderPlus, FolderCheck, Folder, ChevronRight } from 'react-bootstrap-icons'
import { BrowserView, MobileView } from 'react-device-detect';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import Axios from 'axios'
import { render } from '@testing-library/react';
import FullBillFeed from './FullBillFeed';
import FullWhiteHouseFeed from './FullWhiteHouseFeed';
import FullUSCourtNews from './FullUSCourtNews';
import FullWhiteHouseDocuments from './FullWhiteHouseDocuments';
import DailyDigest from './DailyDigest';
import State from './State';
import Illinois from './state/Illinois';
import CollectionPage from './CollectionPage';
import NewLaws from './NewLaws';
import BillsEnrolled from './BillsEnrolled';
import DepartmentOfState from './DepartmentOfState';
import DepartmentOfTreasury from './DepartmentOfTreasury';
import CourtAppeals from './CourtAppeals';
import Reports from './Reports';
import DepartmentOfDefense from './DepartmentOfDefense';
import DepartmentOfJustice from './DepartmentOfJustice';
import DepartmentOfTheInterior from './DepartmentOfTheInterior';
import DepartmentOfAgriculture from './DepartmentOfAgriculture';
import DepartmentOfCommerce from './DepartmentOfCommerce';
import DepartmentOfLabor from './DepartmentOfLabor';
import DepartmentOfHealthAndHumanServices from './DepartmentOfHealthAndHumanServices';
import DepartmentOfHousingAndUrbanDevelopment from './DepartmentOfHousingAndUrbanDevelopment';
import DepartmentOfTransportation from './DepartmentOfTransportation';
import DepartmentOfEnergy from './DepartmentOfEnergy';
import DepartmentOfEducation from './DepartmentOfEducation';
import DepartmentOfVeteranAffairs from './DepartmentOfVeteranAffairs';
import DepartmentOfHomelandSecurity from './DepartmentOfHomelandSecurity';
import EnvironmentalProtectionAgency from './EnvironmentalProtectionAgency';
import NationalAeronauticsAndSpaceAdministration from './NationalAeronauticsAndSpaceAdministration';
import TrendingBills from './TrendingBills';
import GAOReports from './GAOReports';
import LegislativeBranch from './LegislativeBranch';
import HouseComitteeOnAgriculture from './HouseComitteeOnAgriculture';
import HouseCommitteeOnAppropriations from './HouseCommitteeOnArmedServices';

import App from './App';
import Overlay from 'react-overlays/esm/Overlay';
import ExecutiveOrders from './ExecutiveOrders';
import PresidentialProclamations from './PresidentialProclamations';
import NationalScienceFoundation from './NationalScienceFoundation';

import{ whiteHouseSearch, createPresidentialProclamations, createExecutiveOrders, createWhiteHouseDocuments, createWhiteHouseFeed,
  createWhiteHouseFeedMobile,createDepartmentOfState,
  createDepartmentOfDefense, 
  createDepartmentOfJustice, createDepartmentOfTheInterior,
  createDepartmentOfAgriculture, createDepartmentOfCommerce,
  createDepartmentOfCommerceMobile, createDepartmentOfLabor,
  createDepartmentOfHealthAndHumanServices, createDepartmentOfHousingAndUrbanDevelopment, createDepartmentOfEducation
  ,  createDepartmentOfVeteransAffairs, createDepartmentOfHomelandSecurity
  , createEnvironmentalProtectionAgency,
  createDepartmentOfTreasury, createNationalScienceFoundation, 
   createEnvironmentalProtectionAgencyMobile, createNationalAeronauticsAndSpaceAdministration, Speak, createFederalRegisterDefenseDepartment, createFederalRegisterStateDepartment, createFederalRegisterTreasuryDepartment, createFederalRegisterJusticeDepartment, createFederalRegisterInteriorDepartment, createFederalRegisterAgricultureDepartment, createFederalRegisterCommerceDepartment, createFederalRegisterLaborDepartment, createFederalRegisterHealthAndHumanServicesDepartment, createFederalRegisterHousingAndUrbanDevelopmentDepartment, createFederalRegisterHomelandSecurityDepartment, createFederalRegisterEducationDepartment, createFederalRegisterVeteransAffairsDepartment,
   CreateFeedItem, CreateFeedItemBillUpdates, CreateFeedItemWithDocButton,
   CreateFeedItemPresidentialDocuments, CreateFeedItemExecutiveOrders, CreateFeedItemCongressionalReports, CreateFeedItemExecutiveOffice, CreateFeedItemBillWithDescription, CreateFeedAPIDepartmentUS } from './Functions'
import ExecutiveBranchMobile from './ExecutiveBranchMobile';
import DesktopNavbar from './DesktopNavbar';
import DepartmentOfTheInteriorDocs from './DepartmentOfTheInteriorDocs';
import FishingAndWildlifeService from './FishingAndWildlifeService';
import GeologicalSurvey from './GeologicalSurvey';
import InteriorDeptHearings from './InteriorDeptHearings';
import IndianAffairsBureau from './IndianAffairsBureau';
import LandManagementBureau from './LandManagementBureau';
import NationalIndianGamingCommission from './NationalIndianGamingCommission';
import NationalParkService from './NationalParkService';
import ReclamationBureau from './ReclamationBureau';
import SpecialTrusteeForAmericanIndians from './SpecialTrusteeForAmericanIndians';
import SurfaceMiningReclamation from './SurfaceMiningReclamation';
import OceanEnergyManagmentBureau from './OceanEnergyManagmentBureau';
import SafetyAndEnvironmentEnforcement from './SafetyAndEnvironmentEnforcement';
import NaturalResourcesRevenueOffice from './NaturalResourcesRevenueOffice';
import TransportationMobile from './TransportationMobile';
import InteriorMobile from './InteriorMobile';
import DepartmentOfCommerceDocuments from './DepartmentOfCommerceDocuments';
import CensusBureau from './CensusBureau';
import EconomicAnalysisBureau from './EconomicAnalysisBureau';
import EconomicDevelopmentAdministration from './EconomicDevelopmentAdministration';
import EconomicsAndStatistics from './EconomicsAndStatistics';
import ForeignTradeZonesBoard from './ForeignTradeZonesBoard';
import IndustryAndSecurityBureau from './IndustryAndSecurityBureau';
import InternationalTradeAdministration from './InternationalTradeAdministration';
import MinorityBusinessDevelopmentAgency from './MinorityBusinessDevelopmentAgency';
import NationalInstituteOfStandardsAndTech from './NationalInstituteOfStandardsAndTech';
import NationalOceanicAndAtmosphericAdministration from './NationalOceanicAndAtmosphericAdministration';
import NationalTechnicalInformationService from './NationalTechnicalInformationService';
import NationalTelecommunicationsAndInformationAdmin from './NationalTelecommunicationsAndInformationAdmin';
import PatentAndTradeMarkOffice from './PatentAndTradeMarkOffice';
import UnderSecretaryForEconomicAffairs from './UnderSecretaryForEconomicAffairs';
import CommerceMobile from './CommerceMobile';

import DepartmentOfHousingAndUrbanDevelopmentDocs from './DepartmentOfHousingAndUrbanDevelopmentDocs';
import FederalHousingEnterprise from './FederalHousingEnterprise';
import HousingMobile from './HousingMobile';
import GenerateFeedDepartment from './GenerateFeedDepartment';
import DepartmentOfTreasuryDocs from './DepartmentOfTreasuryDocs';
import DepartmentOfTreasuryMobile from './DepartmentOfTreasuryMobile';



var collectionCounter = 0
var carouselInterval = 30000;
var mobileCarouselInterval = 60000;





  

 


  


export const DepartmentOfTreasuryDesk = () =>
 {
   render()
   
   {
    const [show, setShow] = useState(true);

        
    const [fullscreen, setFullScreen] = useState(true);
  
  
    const [fullBillModalIsOpen, setFullBillModalIsOpen] = useState(false)
  
    const [reportModalIsOpen, setReportModalIsOpen] = useState(false)
  
  
    const [whiteHouseFeedModalIsOpen, setWhiteHouseFeedModalIsOpen] = useState(false)
    const [whiteHouseDocsModalIsOpen, setWhiteHouseDocsModalIsOpen] = useState(false)
  
  
    const [courtNewsModalIsOpen, setCourtNewsModalIsOpen] = useState(false)
    const [courtModalIsOpen, setCourtModalIsOpen] = useState(false)
  
    const [courtAppealsModalIsOpen, setCourtAppealsModalIsOpen] = useState(false)
  
    const [newLawsModalIsOpen, setNewLawsModalIsOpen] = useState(false)
  
    const [billsEnrolledModalIsOpen, setBillsEnrolledModalIsOpen] = useState(false)
  
    const [legislativePageBillsEnrolledModalIsOpen, setLegislativePageBillsEnrolledModalIsOpen] = useState(false)
  
  
    const [departmentOfStateModalIsOpen, setDepartmentOfStateModalIsOpen] = useState(false)
    const [departmentOfTreasuryModalIsOpen, setDepartmentOfTreasuryModalIsOpen] = useState(false)
 
    const [departmentOfJusticeModalIsOpen, setDepartmentOfJusticeModalIsOpen] = useState(false)
    const [departmentOfTheInteriorModalIsOpen, setDepartmentOfTheInteriorModalIsOpen] = useState(false)
    const [departmentOfAgricultureModalIsOpen, setDepartmentOfAgricultureModalIsOpen] = useState(false)
    const [departmentOfCommerceModalIsOpen, setDepartmentOfCommerceModalIsOpen] = useState(false)
    const [departmentOfLaborModalIsOpen, setDepartmentOfLaborModalIsOpen] = useState(false)
    const [departmentOfHealthAndHumanServicesModalIsOpen, setDepartmentOfHealthAndHumanServicesModalIsOpen] = useState(false)
    const [departmentOfHousingAndUrbanDevelopmentModalIsOpen, setDepartmentOfHousingAndUrbanDevelopmentModalIsOpen] = useState(false)
    const [departmentOfTransportationModalIsOpen, setDepartmentOfTransportationModalIsOpen] = useState(false)
    const [departmentOfEnergyModalIsOpen, setDepartmentOfEnergyModalIsOpen] = useState(false)
    const [departmentOfEducationModalIsOpen, setDepartmentOfEducationModalIsOpen] = useState(false)
    const [departmentOfVeteransAffairsModalIsOpen, setDepartmentOfVeteransAffairsModalIsOpen] = useState(false)
    const [departmentOfHomelandSecurityModalIsOpen, setDepartmentOfHomelandSecurityModalIsOpen] = useState(false)
    const [epaModalIsOpen, setEPAModalIsOpen] = useState(false)
    const [nsfModalIsOpen, setNSFModalIsOpen] = useState(false)
    const [nasaModalIsOpen, setNASAModalIsOpen] = useState(false)
    const [trendingBillsModalIsOpen, setTrendingBillsModalIsOpen] = useState(false)
    const [goaReportsModalIsOpen, setGAOReportsModalIsOpen] = useState(false)
    const [executiveOrdersModalIsOpen, setExecutiveOrdersModalIsOpen] = useState(false)
    const [presidentialProclamationsModalIsOpen, setPresidentialProclamationsModalIsOpen] = useState(false)
    const [statesModalIsOpen, setStatesModalIsOpen] = useState(false)
  
  
    const [collectionsModalIsOpen, setCollectionsModalIsOpen] = useState(false)
    const [aboutModalIsOpen, setAboutModalIsOpen] = useState(false)
  
    const [housingModalIsOpen, setHousingModalIsOpen] = useState(false)
    const [federalRegisterDefenseDepartmentModalIsOpen, setFederalRegisterDefenseDepartmentModalIsOpen] = useState(false)
const [federalRegisterStateDepartmentModalIsOpen, setFederalRegisterStateDepartmentModalIsOpen] = useState(false)
    const [declarationModalIsOpen, setDeclarationModalIsOpen] = useState(false)
    const [constitutionModalIsOpen, setConstitutionModalIsOpen] = useState(false)
    const [billOfRightsnModalIsOpen, setBillOfRightsModalIsOpen] = useState(false)

    const [economicAdModalIsOpen, setEconomicAdModalIsOpen] = useState(false)
    const [environmentQualityModalIsOpen, setEnvironmentQualityModalIsOpen] = useState(false)
    const [domesticPolicyModalIsOpen, setDomesticPolicyModalIsOpen] = useState(false)
    const [genderPolicyModalIsOpen, setGenderPolicyModalIsOpen] = useState(false)
    const [nationalEconomicModalIsOpen, setNationalEconomicModalIsOpen] = useState(false)
    const [securityModalIsOpen, setSecurityModalIsOpen] = useState(false)
    const [budgetModalIsOpen, setBudgetModalIsOpen] = useState(false)
    const [drugModalIsOpen, setDrugModalIsOpen] = useState(false)
    const [publicModalIsOpen, setPublicModalIsOpen] = useState(false)
    const [scienceModalIsOpen, setScienceModalIsOpen] = useState(false)
    const [cyberModalIsOpen, setCyberModalIsOpen] = useState(false)
    const [presidentialModalIsOpen, setPresidentialModalIsOpen] = useState(false)

    const [departmentOfDefenseModalIsOpen, setDepartmentOfDefenseModalIsOpen] = useState(false)
    const [defenseDocumentsModalIsOpen, setDefenseDocumentsModalIsOpen] = useState(false)
    const [armyModalIsOpen, setArmyModalIsOpen] = useState(false)
    const [navyModalIsOpen, setNavyModalIsOpen] = useState(false)
    const [marinesModalIsOpen, setMarinesModalIsOpen] = useState(false)
    const [spaceForceModalIsOpen, setSpaceForceModalIsOpen] = useState(false)
    const [airForceModalIsOpen, setAirForceModalIsOpen] = useState(false)
    const [nationalGuardModalIsOpen, setNationalGuardModalIsOpen] = useState(false)
    const [ciaModalIsOpen, setCIAModalIsOpen] = useState(false)
    const [nsaModalIsOpen, setNSAModalIsOpen] = useState(false)
    const [armyCorpsModalIsOpen, setArmyCorpsModalIsOpen] = useState(false)

    const [coastGuardModalIsOpen, setCoastGuardModalIsOpen] = useState(false)
    const [femaModalIsOpen, setFEMAModalIsOpen] = useState(false)
    const [secretServiceModalIsOpen, setSecretServiceModalIsOpen] = useState(false)
    const [tsaModalIsOpen, setTSAModalIsOpen] = useState(false)
    const [immigrationModalIsOpen, setImmigrationModalIsOpen] = useState(false)
    const [usCustomsModalIsOpen, setUSCustomsModalIsOpen] = useState(false)
    const [enforcementsModalIsOpen, setEnforcemnetsModalIsOpen] = useState(false)
    const [homelandDocsModalIsOpen, setHomelandDocsModalIsOpen] = useState(false)
    const [treasuryModalIsOpen, setTreasuryModalIsOpen] = useState(false)
    const [showBioModal, setBioModal] = useState(false);
    const [showMissionModal, setMissionModal] = useState(false);
  return(
    <>
      <BrowserView>
        <div id='homePage'>

     

        <div id='homebg'>

          <DesktopNavbar/>
          <div id='feedDisplay' 
                  style={{ padding: '20px', borderRadius: '18px', marginTop: '0em'}}>

<div style={{ position: 'relative' }}>

     <div id='profileImage' style={{ width: '150px', height: '150px', borderRadius: '100%', overflow: 'hidden',  marginLeft: '40px' }}>
     <Image id='profileImage-image' src='/departmentOfTreasury.png' />
   </div>


   <div style={{ position: 'absolute', top: '0', left: '250px', display: 'flex', flexDirection: 'column' }}>
   <div style={{ color: 'white', fontSize: '24px', fontWeight: 'bold' }}>

</div>
<div  style={{ color: 'white', fontSize: '20px' }}> 
<span> <h3  >Department of Treasury </h3></span>
<br></br>
<div style={{ color: 'white', fontSize: '20px' }}>
<span> The Department of the Treasury is responsible for promoting inclusive economic prosperity for all Americans.</span>
<br></br>
<div style={{ color: 'white', fontSize: '18px' }}>

       <span><Link style={{color: '#21cff5'}} onClick={(e) => {
          e.preventDefault();
          window.open("https://home.treasury.gov/", "_blank");
        }}>
          https://home.treasury.gov/
          </Link></span> 
          <br></br>
<Button id='whDocsViewMoreButton' onClick={() =>  setMissionModal(true)}>  <Files size={20}></Files> Read More</Button>
</div>
  </div>

     </div>
     </div>


     </div>

           </div>

           <Modal id='fullBillModal'  size="xl" aria-labelledby="contained-modal-title-vcenter" show={showMissionModal} onHide={() => setMissionModal(false)}>
         <Modal.Header id='billModalHeader' closeButton><h3> Department of Treasury</h3></Modal.Header>
       
         <div id='fullBillFeed' >
         <p>The Department of the Treasury is responsible for promoting inclusive economic prosperity for all Americans.</p>

<p>The Department advances U.S. and global economic growth to raise American standards of living, support communities, promote racial justice, combat climate change, and foster financial stability. The Department operates systems that are critical to the nation’s financial infrastructure, such as the production of coin and currency, the disbursement of payments owed to the American public, the collection of necessary taxes, and the borrowing of funds required by congressional enactments to run the federal government. The Treasury Department also performs a critical role in enhancing national security by safeguarding our financial systems, implementing economic sanctions against foreign threats to the U.S., and identifying and targeting financial support networks that threaten our national security.</p>

<p>The Secretary of the Treasury oversees a budget of approximately $13 billion and a staff of more than 100,000 employees.</p>


<br></br>
<Link style={{color: '#21cff5'}} onClick={(e) => {
     e.preventDefault();
     window.open("https://www.whitehouse.gov/about-the-white-house/our-government/the-executive-branch/#:~:text=approximately%20%2470%20billion.-,DEPARTMENT%20OF%20THE%20TREASURY,-The%20Department%20of", "_blank");
   }}>
     https://www.whitehouse.gov/about-the-white-house/our-government/the-executive-branch/#:~:text=approximately%20%2470%20billion.-,DEPARTMENT%20OF%20THE%20TREASURY,-The%20Department%20of
     </Link>
 </div>

       </Modal>
       <br></br><br></br><br></br>
            <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferTwo'>
                   
                    <Row>

                      <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>
                        <h4 id='whBreifingFeedHeading' onClick={() => setDepartmentOfDefenseModalIsOpen(true)}><b>News</b></h4>


<div   id='housingAndUrbanDevelopmentDepartment'></div>

{CreateFeedItem('https://home.treasury.gov/system/files/126/ofac.xml', 'housingAndUrbanDevelopmentDepartment', 'Department of Treasury', 'Executive', 'Economy', DepartmentOfTreasury)}

<Modal id='whiteHouseDocsModal' show={departmentOfDefenseModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfDefenseModalIsOpen(false)}>
                <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Treasury</h3></Modal.Header>
                <DepartmentOfTreasury/>
              </Modal>
                        </div>
                      </Col>

                      <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>
                        <h4 id='whBreifingFeedHeading' onClick={() => setDefenseDocumentsModalIsOpen(true)}><b>Documents</b></h4>


<div id='frDefense'></div>

{CreateFeedItem('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=treasury-department', 'frDefense', 'Department of Treasury Documents', 'Executive', 'Economy', DepartmentOfTreasuryDocs)}

<Modal id='whiteHouseDocsModal' show={defenseDocumentsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDefenseDocumentsModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Treasury Documents</h3></Modal.Header>
                  <DepartmentOfTreasuryDocs/>
                </Modal>
                        </div>
                      </Col>


                    </Row>


                  </Container>
                </Container>
                </div>    
                <div id='branchPage'>
  <Container class='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/fiscal-service', "_self")}}><b>Bureau of the Fiscal Service</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defBfs'></div>
            {CreateFeedAPIDepartmentUS('&table_name=treasury&agency=Bureau%20of%20the%20Fiscal%20Service', 'defBfs',
              'Bureau of the Fiscal Service Documents', 'Executive',
              'Economy', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={treasuryModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTreasuryModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Bureau of the Fiscal Service</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=treasury&agency=Bureau%20of%20the%20Fiscal%20Service'
                name='Bureau of the Fiscal Service' branch='Executive' topic='Economy' buttonName='Doc' />
            </Modal>
          </div>
        </Col>

        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/cdfi-fund', "_self")}}><b>Community Development Financial Institutions Fund</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defCdfi'></div>
            {CreateFeedAPIDepartmentUS('&table_name=treasury&agency=Community%20Development%20Financial%20Institutions%20Fund', 'defCdfi',
              'Community Development Financial Institutions Fund Documents', 'Executive',
              'Economy', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={treasuryModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTreasuryModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Community Development Financial Institutions Fund</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=treasury&agency=Community%20Development%20Financial%20Institutions%20Fund'
                name='Community Development Financial Institutions Fund' branch='Executive' topic='Economy' buttonName='Doc' />
            </Modal>
          </div>
        </Col>
      </Row>
    </Container>
  </Container>
</div>

<div id='branchPage'>
  <Container class='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/comptroller-currency', "_self")}}><b>Comptroller of the Currency</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defCotc'></div>
            {CreateFeedAPIDepartmentUS('&table_name=treasury&agency=Comptroller%20of%20the%20Currency', 'defCotc',
              'Comptroller of the Currency Documents', 'Executive',
              'Economy', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={treasuryModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTreasuryModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Comptroller of the Currency</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=treasury&agency=Comptroller%20of%20the%20Currency'
                name='Comptroller of the Currency' branch='Executive' topic='Economy' buttonName='Doc' />
            </Modal>
          </div>
        </Col>

        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/customs-service', "_self")}}><b>Customs Service</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defCs'></div>
            {CreateFeedAPIDepartmentUS('&table_name=treasury&agency=Customs%20Service', 'defCs',
              'Customs Service Documents', 'Executive',
              'Economy', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={treasuryModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTreasuryModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Customs Service</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=treasury&agency=Customs%20Service'
                name='Customs Service' branch='Executive' topic='Economy' buttonName='Doc' />
            </Modal>
          </div>
        </Col>
      </Row>
    </Container>
  </Container>
</div>

<div id='branchPage'>
  <Container class='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/engraving-printing', "_self")}}><b>Engraving and Printing Bureau</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defEpb'></div>
            {CreateFeedAPIDepartmentUS('&table_name=treasury&agency=Engraving%20and%20Printing%20Bureau', 'defEpb',
              'Engraving and Printing Bureau Documents', 'Executive',
              'Economy', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={treasuryModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTreasuryModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Engraving and Printing Bureau</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=treasury&agency=Engraving%20and%20Printing%20Bureau'
                name='Engraving and Printing Bureau' branch='Executive' topic='Economy' buttonName='Doc' />
            </Modal>
          </div>
        </Col>

        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/finCEN', "_self")}}><b>Financial Crimes Enforcement Network</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defFcen'></div>
            {CreateFeedAPIDepartmentUS('&table_name=treasury&agency=Financial%20Crimes%20Enforcement%20Network', 'defFcen',
              'Financial Crimes Enforcement Network Documents', 'Executive',
              'Economy', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={treasuryModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTreasuryModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Financial Crimes Enforcement Network</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=treasury&agency=Financial%20Crimes%20Enforcement%20Network'
                name='Financial Crimes Enforcement Network' branch='Executive' topic='Economy' buttonName='Doc' />
            </Modal>
          </div>
        </Col>
      </Row>
    </Container>
  </Container>
</div>

<div id='branchPage'>
  <Container class='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/financial-research', "_self")}}><b>Financial Research Office</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defFro'></div>
            {CreateFeedAPIDepartmentUS('&table_name=treasury&agency=Financial%20Research%20Office', 'defFro',
              'Financial Research Office Documents', 'Executive',
              'Economy', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={treasuryModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTreasuryModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Financial Research Office</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=treasury&agency=Financial%20Research%20Office'
                name='Financial Research Office' branch='Executive' topic='Economy' buttonName='Doc' />
            </Modal>
          </div>
        </Col>

        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/fiscal-service', "_self")}}><b>Fiscal Service</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defFs'></div>
            {CreateFeedAPIDepartmentUS('&table_name=treasury&agency=Fiscal%20Service', 'defFs',
              'Fiscal Service Documents', 'Executive',
              'Economy', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={treasuryModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTreasuryModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Fiscal Service</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=treasury&agency=Fiscal%20Service'
                name='Fiscal Service' branch='Executive' topic='Economy' buttonName='Doc' />
            </Modal>
          </div>
        </Col>
      </Row>
    </Container>
  </Container>
</div>

<div id='branchPage'>
  <Container class='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/foreign-assets-control-office', "_self")}}><b>Foreign Assets Control Office</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defFaco'></div>
            {CreateFeedAPIDepartmentUS('&table_name=treasury&agency=Foreign%20Assets%20Control%20Office', 'defFaco',
              'Foreign Assets Control Office Documents', 'Executive',
              'Economy', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={treasuryModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTreasuryModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Foreign Assets Control Office</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=treasury&agency=Foreign%20Assets%20Control%20Office'
                name='Foreign Assets Control Office' branch='Executive' topic='Economy' buttonName='Doc' />
            </Modal>
          </div>
        </Col>

        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/irs', "_self")}}><b>Internal Revenue Service</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defIrs'></div>
            {CreateFeedAPIDepartmentUS('&table_name=treasury&agency=Internal%20Revenue%20Service', 'defIrs',
              'Internal Revenue Service Documents', 'Executive',
              'Economy', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={treasuryModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTreasuryModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Internal Revenue Service</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=treasury&agency=Internal%20Revenue%20Service'
                name='Internal Revenue Service' branch='Executive' topic='Economy' buttonName='Doc' />
            </Modal>
          </div>
        </Col>
      </Row>
    </Container>
  </Container>
</div>

<div id='branchPage'>
  <Container class='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/international-investment', "_self")}}><b>International Investment Office</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defIio'></div>
            {CreateFeedAPIDepartmentUS('&table_name=treasury&agency=International%20Investment%20Office', 'defIio',
              'International Investment Office Documents', 'Executive',
              'Economy', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={treasuryModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTreasuryModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>International Investment Office</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=treasury&agency=International%20Investment%20Office'
                name='International Investment Office' branch='Executive' topic='Economy' buttonName='Doc' />
            </Modal>
          </div>
        </Col>

        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/investment-security', "_self")}}><b>Investment Security Office</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defIso'></div>
            {CreateFeedAPIDepartmentUS('&table_name=treasury&agency=Investment%20Security%20Office', 'defIso',
              'Investment Security Office Documents', 'Executive',
              'Economy', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={treasuryModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTreasuryModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Investment Security Office</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=treasury&agency=Investment%20Security%20Office'
                name='Investment Security Office' branch='Executive' topic='Economy' buttonName='Doc' />
            </Modal>
          </div>
        </Col>
      </Row>
    </Container>
  </Container>
</div>

<div id='branchPage'>
  <Container class='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/monetary-offices', "_self")}}><b>Monetary Offices</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defMo'></div>
            {CreateFeedAPIDepartmentUS('&table_name=treasury&agency=Monetary%20Offices', 'defMo',
              'Monetary Offices Documents', 'Executive',
              'Economy', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={treasuryModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTreasuryModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Monetary Offices</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=treasury&agency=Monetary%20Offices'
                name='Monetary Offices' branch='Executive' topic='Economy' buttonName='Doc' />
            </Modal>
          </div>
        </Col>

        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/public-debt', "_self")}}><b>Public Debt Bureau</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defPdb'></div>
            {CreateFeedAPIDepartmentUS('&table_name=treasury&agency=Public%20Debt%20Bureau', 'defPdb',
              'Public Debt Bureau Documents', 'Executive',
              'Economy', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={treasuryModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTreasuryModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Public Debt Bureau</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=treasury&agency=Public%20Debt%20Bureau'
                name='Public Debt Bureau' branch='Executive' topic='Economy' buttonName='Doc' />
            </Modal>
          </div>
        </Col>
      </Row>
    </Container>
  </Container>
</div>

<div id='branchPage'>
  <Container class='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/thrift-supervision', "_self")}}><b>Thrift Supervision Office</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defTso'></div>
            {CreateFeedAPIDepartmentUS('&table_name=treasury&agency=Thrift%20Supervision%20Office', 'defTso',
              'Thrift Supervision Office Documents', 'Executive',
              'Economy', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={treasuryModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTreasuryModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Thrift Supervision Office</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=treasury&agency=Thrift%20Supervision%20Office'
                name='Thrift Supervision Office' branch='Executive' topic='Economy' buttonName='Doc' />
            </Modal>
          </div>
        </Col>

        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/us-mint', "_self")}}><b>United States Mint</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defUsm'></div>
            {CreateFeedAPIDepartmentUS('&table_name=treasury&agency=United%20States%20Mint', 'defUsm',
              'United States Mint Documents', 'Executive',
              'Economy', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={treasuryModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTreasuryModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>United States Mint</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=treasury&agency=United%20States%20Mint'
                name='United States Mint' branch='Executive' topic='Economy' buttonName='Doc' />
            </Modal>
          </div>
        </Col>
      </Row>
    </Container>
  </Container>
</div>




       


   

            <Modal id='aboutModal' show={aboutModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setAboutModalIsOpen(false)}>
            <div id='removedAlert'></div>
            <Modal.Header id='aboutModalHeader' closeButton><h3>About</h3></Modal.Header>
  <ModalBody id='aboutInfo'><p id='aboutParagraph'>Gov Glance was created to drive civic engagement and support a 
              healthier democracy by helping citizens get official Government news in an easy-to-navigate centralized location. 
              There will never be any opinion or political analysis(That's your job), only information from official Government 
              sources. Gov Glance is structured after the U.S. government that is broken into three branches Legislative, 
              Executive, and Judicial. To learn more about the structure and functions of The U.S. Government go to <a href='https://www.usa.gov/branches-of-government'>https://www.usa.gov/branches-of-government</a>.
              </p>

              <p id='aboutParagraph'>
              Gov Glance Foundation Inc. is a non-profit that was created to operate govglance.org and continue to create educational tools that help people learn about the workings of society
              </p>
            <br></br>

            <h3>Privacy</h3>

            <br></br>

              <p id='aboutParagraph'>Gov Glance does not collect any personal user information. 
              Features like collection save news items directly to your browser's local storage for your convenience
              and is not shared with Gov Glance servers. We do not collect, store, or send personal information to Google LLC, 
              but we do use some Google services to deliver our app to you. For any questions into Google’s privacy practices 
              here is a link to their privacy policy: <a href='https://policies.google.com/privacy?hl=en-US#intro'>https://policies.google.com/privacy?hl=en-US#intro</a>

              <br></br><br></br>

              For site analytics we use plausible.io an open source privacy friendly analytics provider. <a href="https://plausible.io/about" target="_blank">https://plausible.io/about</a>
            
            </p>
            <br></br>


            <h3>Contact</h3>

            <p id='aboutParagraph'>
                      Contact us via email: <a href='mailto:contact@govglance.org'>contact@govglance.org</a>

            </p>

            <br></br>


            
            </ModalBody>
          </Modal>
 
          <footer id='homeFooter'>
      <Navbar>
        <Row id='footerNav'>
      <Nav variant='pills'>
        
     <Nav.Link id='footerContent'>Gov Glance Foundation Inc.
</Nav.Link>
<Nav.Item>
<Nav.Link id='footerContent' href='mailto:contact@govglance.org'>Contact</Nav.Link>
</Nav.Item>
<Nav.Item>
<Nav.Link id='footerContent' onClick={(e) => {
                   e.preventDefault();
                   window.open('https://donate.stripe.com/28o3fXbdvbQO2RO288', "_blank")
                 }}> <HeartFill id='donateIcon'/>    Donate</Nav.Link>
</Nav.Item>
<Nav.Link onClick={(e) => {
                   e.preventDefault();
                   window.open('/about', "_self")}} id='footerContent'>Privacy</Nav.Link>
    
     
 </Nav>
 </Row>
 </Navbar>
      </footer>



</div>
         </div>
          

              </BrowserView>

              <MobileView>

    <DepartmentOfTreasuryMobile/>

              </MobileView>
              
    </>
  )
}
 }



  






export default DepartmentOfTreasuryDesk;