
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link, Switch, } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment, } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Tooltip, OverlayTrigger, Image } from 'react-bootstrap';
import { ArrowsAngleExpand, Building,  ArrowsFullscreen, InfoCircle, FileText, ShieldShaded, PlayCircle, PauseCircle, HeartFill, Pen, People, Collection, Grid, ThreeDots, Stack, List, Files, FolderPlus, FolderCheck, Folder, Tree, FileMedical, FileMedicalFill, Bricks, ChevronRight  } from 'react-bootstrap-icons'
import { BrowserView, MobileView } from 'react-device-detect';
import { render } from '@testing-library/react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import DesktopNavbar from './DesktopNavbar';
import HealthMobile from './HealthMobile';
import { CreateFeedAPIDepartmentUS, CreateFeedItem, CreateFeedItemBillWithDescription, CreateFeedItemMaterials } from './Functions';
import HouseCommitteeOnTransportationAndInfrastructure from './HouseCommitteeOnTransportationAndInfrastructure';
import HouseCommitteeOnTransportationAndInfrastructureMaterials from './HouseCommitteeOnTransportationAndInfrastructureMaterials';
import DepartmentOfHousingAndUrbanDevelopment from './DepartmentOfHousingAndUrbanDevelopment';
import DepartmentOfHousingAndUrbanDevelopmentDocs from './DepartmentOfHousingAndUrbanDevelopmentDocs';
import SenateCommerceScienceCommittee from './SenateCommerceScienceCommittee';
import SenateBankingHousingUrbanCommittee from './SenateBankingHousingUrbanCommittee';
import ArmyDocuments from './ArmyDocuments';
import MarinesPressReleases from './MarinesPressReleases';
import FederalRegisterDefenseDepartment from './FederalRegisterDefenseDepartment';
import DepartmentOfDefense from './DepartmentOfDefense';
import NavyDocuments from './NavyDocuments';
import SpaceForceNews from './SpaceForceNews';
import AirForceDocuments from './AirForceDocuments';
import NationalGuardNews from './NationalGuardNews';
import NSADocuments from './NSADocuments';
import ArmyCorpsEngineers from './ArmyCorpsEngineers';
import DefenseTopicMobile from './DefenseTopicMobile';
import HomelandSecurityDocuments from './HomelandSecurityDocuments';
import GenerateFeedDepartment from './GenerateFeedDepartment';

var collectionCounter = 0
var carouselInterval = 30000;
var mobileCarouselInterval = 60000;

export const DefensePage = () =>
 {
   render()
   {
 
  const [cdcNewsroomModalIsOpen, setCDCNewsroomModalIsOpen] = useState(false)
  const [outbreaksModalIsOpen, setOutbreaksModalIsOpen] = useState(false)
  const [travelNoticesModalIsOpen, setTravelNoticesModalIsOpen] = useState(false)
  const [foodRecallsModalIsOpen, setFoodRecallsModalIsOpen] = useState(false)
  const [foodSafetyModalIsOpen, setFoodSafetyModalIsOpen] = useState(false)
  const [medWatchModalIsOpen, setMedWatchModalIsOpen] = useState(false)
  const [healthyLivingFeaturesModalIsOpen, setHealthyLivingFeaturesModalIsOpen] = useState(false)
  const [preventingChronicDiseaseModalIsOpen, setPreventingChronicDiseaseModalIsOpen] = useState(false)
  const [covid19ModalIsOpen, setCovid19ModalIsOpen] = useState(false)
  const [novelCoronavirusModalIsOpen, setNovelCoronavirusModalIsOpen] = useState(false)
  const [vaccineUpdatesModalIsOpen, setVaccineUpdatesModalIsOpen] = useState(false)
  const [seasonalFluModalIsOpen, setSeasonalFluModalIsOpen] = useState(false)
  const [aboutModalIsOpen, setAboutModalIsOpen] = useState(false)
  const [departmentOfDefenseModalIsOpen, setDepartmentOfDefenseModalIsOpen] = useState(false)
  const [defenseDocumentsModalIsOpen, setDefenseDocumentsModalIsOpen] = useState(false)
  const [defenseModalIsOpen, setDefenseModalIsOpen] = useState(false)
  const [homelandModalIsOpen, setHomelandModalIsOpen] = useState(false);
  return(
    <>
      <BrowserView>
        <div id='homePage'>
        <div id='homebg'>
        <DesktopNavbar/>
      
               <div id='sectionHeading'>      
               <Row>
              <h3 id='branchHeading' ><Bricks/><b> Defense</b></h3> 
              
              </Row>
              </div> 

              <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferTwo'>
                   
                    <Row>

                      <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>

                        <h4 id='departmentOfDefenseHeadingInCol' onClick={() => window.open('/us/departmentofdefense', "_self")}><b>Department of Defense</b><ChevronRight id='clickChevron'></ChevronRight></h4>


<Carousel id='carouselInColumn' controls={false}  >


<Carousel.Item interval={carouselInterval}>

<h4 id='departmentOfJusticeHeading' onClick={() => setDepartmentOfDefenseModalIsOpen(true)}><b>News</b></h4>

<div id='defenseDepartment'></div>

{CreateFeedItem('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/executive-xml/defense.xml', 'defenseDepartment', 'Department of Defense', 'Executive', 'Foreign Affairs', DepartmentOfDefense)}



<Modal id='departmentOfStateModal' show={departmentOfDefenseModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfDefenseModalIsOpen(false)}>
<Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>Department of Defense Documents</h3></Modal.Header>
<FederalRegisterDefenseDepartment/>
</Modal>

</Carousel.Item>


<Carousel.Item interval={carouselInterval}>

<h4 id='departmentOfJusticeHeading' onClick={() => setDefenseDocumentsModalIsOpen(true)}><b>Documents</b></h4>

<div id='frDefense'></div>

{CreateFeedItem('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=defense-department', 'frDefense', 'Department of Defense Documents', 'Executive', 'Foreign Affairs', FederalRegisterDefenseDepartment)}


<Modal id='departmentOfStateModal' show={defenseDocumentsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDefenseDocumentsModalIsOpen(false)}>
<Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>Department of Defense Documents</h3></Modal.Header>
<FederalRegisterDefenseDepartment/>
</Modal>

</Carousel.Item>


<Carousel.Item interval={carouselInterval} >





<div id='legislativeConInCol'>


<Button id='viewFullLegislativePageButton' size='lg' href='/us/departmentofdefense' >View all Department of Defense news</Button>

</div>


</Carousel.Item>


</Carousel>
                  
                        </div>
                      </Col>

                      <Col id='legislativeColRight'>
                        <div id='columnRightPadding'>
                        <h4 id='departmentOfDefenseHeadingInCol' onClick={() => window.open('/us/departmentofhomeland', "_self")}><b>Department of Homeland Security</b><ChevronRight id='clickChevron'></ChevronRight></h4>


<Carousel id='carouselInColumn' controls={false}  >


<Carousel.Item interval={carouselInterval}>

<h4 id='departmentOfJusticeHeading'><b>Documents</b></h4>

<div id='homelandDocs'></div>

{CreateFeedItem('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=homeland-security-department', 'homelandDocs', 'Homeland Security Documents', 'Executive', 'N/A', HomelandSecurityDocuments)}





</Carousel.Item>


<Carousel.Item interval={carouselInterval} >





<div id='legislativeConInCol'>


<Button id='viewFullLegislativePageButton' size='lg' href='/us/departmentofhomeland' >View all Department of Homeland Security news</Button>

</div>


</Carousel.Item>


</Carousel>





                        </div>
                      </Col>


                    </Row>


                  </Container>
                </Container>
             </div>


  
  
             <div id='branchPage'>
        <Container className='bgcolor'>
          <Container id='carouselBufferTwo'>
            <Row>
              <Col id='legislativeColRight'>
                <div id='columnRightPadding'>
                  <h4 id='whBreifingFeedHeading' onClick={(e) => {
                    e.preventDefault();
                    window.open('/us/army', "_self")
                  }}>
                    <b>Army</b>
                    <ChevronRight id='clickChevron' />
                  </h4>
                  <div id='defArmy'></div>
                  {CreateFeedAPIDepartmentUS('&table_name=defense&agency=army', 'defArmy',
                    'Army Documents', 'Executive',
                    'Defense', 'Doc', GenerateFeedDepartment)}
                  <Modal id='whiteHouseDocsModal' show={defenseModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDefenseModalIsOpen(false)}>
                    <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton>
                      <h3>Army</h3>
                    </Modal.Header>
                    <GenerateFeedDepartment url='&table_name=defense&agency=army'
                      name='Army' branch='Executive' topic='Defense' buttonName='Doc' />
                  </Modal>
                </div>
              </Col>

              <Col id='legislativeColLeft'>
                <div id='columnLeftPadding'>
                  <h4 id='whBreifingFeedHeading' onClick={(e) => {
                    e.preventDefault();
                    window.open('/us/navy', "_self")
                  }}>
                    <b>Navy</b>
                    <ChevronRight id='clickChevron' />
                  </h4>
                  <div id='defNavy'></div>
                  {CreateFeedAPIDepartmentUS('&table_name=defense&agency=navy', 'defNavy',
                    'Navy Documents', 'Executive',
                    'Defense', 'Doc', GenerateFeedDepartment)}
                  <Modal id='whiteHouseDocsModal' show={defenseModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDefenseModalIsOpen(false)}>
                    <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton>
                      <h3>Navy</h3>
                    </Modal.Header>
                    <GenerateFeedDepartment url='&table_name=defense&agency=navy'
                      name='Navy' branch='Executive' topic='Defense' buttonName='Doc' />
                  </Modal>
                </div>
              </Col>
            </Row>
          </Container>
        </Container>
      </div>

      <div id='branchPage'>
        <Container className='bgcolor'>
          <Container id='carouselBufferTwo'>
            <Row>
              <Col id='legislativeColRight'>
                <div id='columnRightPadding'>
                  <h4 id='whBreifingFeedHeading' onClick={(e) => {
                    e.preventDefault();
                    window.open('/us/airforce', "_self")
                  }}>
                    <b>Air Force</b>
                    <ChevronRight id='clickChevron' />
                  </h4>
                  <div id='defAirForce'></div>
                  {CreateFeedAPIDepartmentUS('&table_name=defense&agency=air%20force', 'defAirForce',
                    'Air Force Documents', 'Executive',
                    'Defense', 'Doc', GenerateFeedDepartment)}
                  <Modal id='whiteHouseDocsModal' show={defenseModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDefenseModalIsOpen(false)}>
                    <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton>
                      <h3>Air Force</h3>
                    </Modal.Header>
                    <GenerateFeedDepartment url='&table_name=defense&agency=air%20force'
                      name='Air Force' branch='Executive' topic='Defense' buttonName='Doc' />
                  </Modal>
                </div>
              </Col>
              <Col id='legislativeColRight'>
                        <div id='columnRightPadding'>
                          <h4 id='presidentialFeedHeading'><b>Marines Press Releases</b></h4>
                          <div id='marinesPress'></div>

{CreateFeedItemBillWithDescription('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/executive-xml/marine.xml', 'marinesPress', 'Marines Press Release', 'Executive', 'N/A', MarinesPressReleases)}

     
                        </div>
                      </Col>
            </Row>
          </Container>
        </Container>
      </div>

                <div id='branchPage'>
  <Container class='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/defense-acquisition-regulations-system', "_self")}}><b>Defense Acquisition Regulations System</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defDars'></div>
            {CreateFeedAPIDepartmentUS('&table_name=defense&agency=Defense%20Acquisition%20Regulations%20System', 'defDars',
              'Defense Acquisition Regulations System Documents', 'Executive',
              'Defense', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={defenseModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDefenseModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Defense Acquisition Regulations System</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=defense&agency=Defense%20Acquisition%20Regulations%20System'
                name='Defense Acquisition Regulations System' branch='Executive' topic='Defense' buttonName='Doc' />
            </Modal>
          </div>
        </Col>

        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/defense-contract-audit-agency', "_self")}}><b>Defense Contract Audit Agency</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defDcaa'></div>
            {CreateFeedAPIDepartmentUS('&table_name=defense&agency=Defense%20Contract%20Audit%20Agency', 'defDcaa',
              'Defense Contract Audit Agency Documents', 'Executive',
              'Defense', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={defenseModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDefenseModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Defense Contract Audit Agency</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=defense&agency=Defense%20Contract%20Audit%20Agency'
                name='Defense Contract Audit Agency' branch='Executive' topic='Defense' buttonName='Doc' />
            </Modal>
          </div>
        </Col>
      </Row>
    </Container>
  </Container>
</div>

<div id='branchPage'>
  <Container class='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/defense-contract-management-agency', "_self")}}><b>Defense Contract Management Agency</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defDcm'></div>
            {CreateFeedAPIDepartmentUS('&table_name=defense&agency=Defense%20Contract%20Management%20Agency', 'defDcm',
              'Defense Contract Management Agency Documents', 'Executive',
              'Defense', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={defenseModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDefenseModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Defense Contract Management Agency</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=defense&agency=Defense%20Contract%20Management%20Agency'
                name='Defense Contract Management Agency' branch='Executive' topic='Defense' buttonName='Doc' />
            </Modal>
          </div>
        </Col>

        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/defense-finance-accounting-service', "_self")}}><b>Defense Finance and Accounting Service</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defDfas'></div>
            {CreateFeedAPIDepartmentUS('&table_name=defense&agency=Defense%20Finance%20and%20Accounting%20Service', 'defDfas',
              'Defense Finance and Accounting Service Documents', 'Executive',
              'Defense', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={defenseModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDefenseModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Defense Finance and Accounting Service</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=defense&agency=Defense%20Finance%20and%20Accounting%20Service'
                name='Defense Finance and Accounting Service' branch='Executive' topic='Defense' buttonName='Doc' />
            </Modal>
          </div>
        </Col>
      </Row>
    </Container>
  </Container>
</div>

<div id='branchPage'>
  <Container class='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/defense-information-systems-agency', "_self")}}><b>Defense Information Systems Agency</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defDisa'></div>
            {CreateFeedAPIDepartmentUS('&table_name=defense&agency=Defense%20Information%20Systems%20Agency', 'defDisa',
              'Defense Information Systems Agency Documents', 'Executive',
              'Defense', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={defenseModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDefenseModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Defense Information Systems Agency</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=defense&agency=Defense%20Information%20Systems%20Agency'
                name='Defense Information Systems Agency' branch='Executive' topic='Defense' buttonName='Doc' />
            </Modal>
          </div>
        </Col>

        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/defense-intelligence-agency', "_self")}}><b>Defense Intelligence Agency</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defDia'></div>
            {CreateFeedAPIDepartmentUS('&table_name=defense&agency=Defense%20Intelligence%20Agency', 'defDia',
              'Defense Intelligence Agency Documents', 'Executive',
              'Defense', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={defenseModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDefenseModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Defense Intelligence Agency</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=defense&agency=Defense%20Intelligence%20Agency'
                name='Defense Intelligence Agency' branch='Executive' topic='Defense' buttonName='Doc' />
            </Modal>
          </div>
        </Col>
      </Row>
    </Container>
  </Container>
</div>

<div id='branchPage'>
  <Container class='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/defense-legal-services-agency', "_self")}}><b>Defense Legal Services Agency</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defDlsa'></div>
            {CreateFeedAPIDepartmentUS('&table_name=defense&agency=Defense%20Legal%20Services%20Agency', 'defDlsa',
              'Defense Legal Services Agency Documents', 'Executive',
              'Defense', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={defenseModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDefenseModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Defense Legal Services Agency</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=defense&agency=Defense%20Legal%20Services%20Agency'
                name='Defense Legal Services Agency' branch='Executive' topic='Defense' buttonName='Doc' />
            </Modal>
          </div>
        </Col>

        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/defense-logistics-agency', "_self")}}><b>Defense Logistics Agency</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defDla'></div>
            {CreateFeedAPIDepartmentUS('&table_name=defense&agency=Defense%20Logistics%20Agency', 'defDla',
              'Defense Logistics Agency Documents', 'Executive',
              'Defense', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={defenseModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDefenseModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Defense Logistics Agency</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=defense&agency=Defense%20Logistics%20Agency'
                name='Defense Logistics Agency' branch='Executive' topic='Defense' buttonName='Doc' />
            </Modal>
          </div>
        </Col>
      </Row>
    </Container>
  </Container>
</div>

<div id='branchPage'>
  <Container class='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/defense-security-cooperation-agency', "_self")}}><b>Defense Security Cooperation Agency</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defDsca'></div>
            {CreateFeedAPIDepartmentUS('&table_name=defense&agency=Defense%20Security%20Cooperation%20Agency', 'defDsca',
              'Defense Security Cooperation Agency Documents', 'Executive',
              'Defense', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={defenseModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDefenseModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Defense Security Cooperation Agency</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=defense&agency=Defense%20Security%20Cooperation%20Agency'
                name='Defense Security Cooperation Agency' branch='Executive' topic='Defense' buttonName='Doc' />
            </Modal>
          </div>
        </Col>

        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/defense-technical-information-center', "_self")}}><b>Defense Technical Information Center</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defDtic'></div>
            {CreateFeedAPIDepartmentUS('&table_name=defense&agency=Defense%20Technical%20Information%20Center', 'defDtic',
              'Defense Technical Information Center Documents', 'Executive',
              'Defense', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={defenseModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDefenseModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Defense Technical Information Center</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=defense&agency=Defense%20Technical%20Information%20Center'
                name='Defense Technical Information Center' branch='Executive' topic='Defense' buttonName='Doc' />
            </Modal>
          </div>
        </Col>
      </Row>
    </Container>
  </Container>
</div>

<div id='branchPage'>
  <Container class='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/defense-threat-reduction-agency', "_self")}}><b>Defense Threat Reduction Agency</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defDtra'></div>
            {CreateFeedAPIDepartmentUS('&table_name=defense&agency=Defense%20Threat%20Reduction%20Agency', 'defDtra',
              'Defense Threat Reduction Agency Documents', 'Executive',
              'Defense', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={defenseModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDefenseModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Defense Threat Reduction Agency</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=defense&agency=Defense%20Threat%20Reduction%20Agency'
                name='Defense Threat Reduction Agency' branch='Executive' topic='Defense' buttonName='Doc' />
            </Modal>
          </div>
        </Col>

        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/national-guard-bureau', "_self")}}><b>National Guard Bureau</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defNgb'></div>
            {CreateFeedAPIDepartmentUS('&table_name=defense&agency=National%20Guard%20Bureau', 'defNgb',
              'National Guard Bureau Documents', 'Executive',
              'Defense', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={defenseModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDefenseModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>National Guard Bureau</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=defense&agency=National%20Guard%20Bureau'
                name='National Guard Bureau' branch='Executive' topic='Defense' buttonName='Doc' />
            </Modal>
          </div>
        </Col>
      </Row>
    </Container>
  </Container>
</div>

             
<div id='branchPage'>
        <Container className='bgcolor'>
          <Container id='carouselBufferTwo'>
            <Row>
              <Col id='legislativeColRight'>
                <div id='columnRightPadding'>
                  <h4 id='whBreifingFeedHeading' onClick={(e) => {
                    e.preventDefault();
                    window.open('/us/coastguard', "_self")
                  }}>
                    <b>Coast Guard</b>
                    <ChevronRight id='clickChevron' />
                  </h4>
                  <div id='defCoastGuard'></div>
                  {CreateFeedAPIDepartmentUS('&table_name=homeland_security&agency=Coast%20Guard', 'defCoastGuard',
                    'Coast Guard Documents', 'Executive',
                    'Defense', 'Doc', GenerateFeedDepartment)}
                  <Modal id='whiteHouseDocsModal' show={homelandModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setHomelandModalIsOpen(false)}>
                    <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton>
                      <h3>Coast Guard</h3>
                    </Modal.Header>
                    <GenerateFeedDepartment url='&table_name=homeland_security&agency=Coast%20Guard'
                      name='Coast Guard' branch='Executive' topic='Defense' buttonName='Doc' />
                  </Modal>
                </div>
              </Col>

              <Col id='legislativeColLeft'>
                <div id='columnLeftPadding'>
                  <h4 id='whBreifingFeedHeading' onClick={(e) => {
                    e.preventDefault();
                    window.open('/us/fema', "_self")
                  }}>
                    <b>Federal Emergency Management Agency</b>
                    <ChevronRight id='clickChevron' />
                  </h4>
                  <div id='defFema'></div>
                  {CreateFeedAPIDepartmentUS('&table_name=homeland_security&agency=Federal%20Emergency%20Management%20Agency', 'defFema',
                    'Federal Emergency Management Agency Documents', 'Executive',
                    'Defense', 'Doc', GenerateFeedDepartment)}
                  <Modal id='whiteHouseDocsModal' show={homelandModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setHomelandModalIsOpen(false)}>
                    <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton>
                      <h3>Federal Emergency Management Agency</h3>
                    </Modal.Header>
                    <GenerateFeedDepartment url='&table_name=homeland_security&agency=Federal%20Emergency%20Management%20Agency'
                      name='Federal Emergency Management Agency' branch='Executive' topic='Defense' buttonName='Doc' />
                  </Modal>
                </div>
              </Col>
            </Row>
          </Container>
        </Container>
      </div>

      <div id='branchPage'>
        <Container className='bgcolor'>
          <Container id='carouselBufferTwo'>
            <Row>
              <Col id='legislativeColRight'>
                <div id='columnRightPadding'>
                  <h4 id='whBreifingFeedHeading' onClick={(e) => {
                    e.preventDefault();
                    window.open('/us/fletc', "_self")
                  }}>
                    <b>Federal Law Enforcement Training Center</b>
                    <ChevronRight id='clickChevron' />
                  </h4>
                  <div id='defFletc'></div>
                  {CreateFeedAPIDepartmentUS('&table_name=homeland_security&agency=Federal%20Law%20Enforcement%20Training%20Center', 'defFletc',
                    'Federal Law Enforcement Training Center Documents', 'Executive',
                    'Defense', 'Doc', GenerateFeedDepartment)}
                  <Modal id='whiteHouseDocsModal' show={homelandModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setHomelandModalIsOpen(false)}>
                    <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton>
                      <h3>Federal Law Enforcement Training Center</h3>
                    </Modal.Header>
                    <GenerateFeedDepartment url='&table_name=homeland_security&agency=Federal%20Law%20Enforcement%20Training%20Center'
                      name='Federal Law Enforcement Training Center' branch='Executive' topic='Defense' buttonName='Doc' />
                  </Modal>
                </div>
              </Col>

              <Col id='legislativeColLeft'>
                <div id='columnLeftPadding'>
                  <h4 id='whBreifingFeedHeading' onClick={(e) => {
                    e.preventDefault();
                    window.open('/us/national-communications', "_self")
                  }}>
                    <b>National Communications System</b>
                    <ChevronRight id='clickChevron' />
                  </h4>
                  <div id='defNationalCommunications'></div>
                  {CreateFeedAPIDepartmentUS('&table_name=homeland_security&agency=National%20Communications%20System', 'defNationalCommunications',
                    'National Communications System Documents', 'Executive',
                    'Defense', 'Doc', GenerateFeedDepartment)}
                  <Modal id='whiteHouseDocsModal' show={homelandModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setHomelandModalIsOpen(false)}>
                    <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton>
                      <h3>National Communications System</h3>
                    </Modal.Header>
                    <GenerateFeedDepartment url='&table_name=homeland_security&agency=National%20Communications%20System'
                      name='National Communications System' branch='Executive' topic='Defense' buttonName='Doc' />
                  </Modal>
                </div>
              </Col>
            </Row>
          </Container>
        </Container>
      </div>

      <div id='branchPage'>
        <Container className='bgcolor'>
          <Container id='carouselBufferTwo'>
            <Row>
              <Col id='legislativeColRight'>
                <div id='columnRightPadding'>
                  <h4 id='whBreifingFeedHeading' onClick={(e) => {
                    e.preventDefault();
                    window.open('/us/secret-service', "_self")
                  }}>
                    <b>Secret Service</b>
                    <ChevronRight id='clickChevron' />
                  </h4>
                  <div id='defSecretService'></div>
                  {CreateFeedAPIDepartmentUS('&table_name=homeland_security&agency=Secret%20Service', 'defSecretService',
                    'Secret Service Documents', 'Executive',
                    'Defense', 'Doc', GenerateFeedDepartment)}
                  <Modal id='whiteHouseDocsModal' show={homelandModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setHomelandModalIsOpen(false)}>
                    <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton>
                      <h3>Secret Service</h3>
                    </Modal.Header>
                    <GenerateFeedDepartment url='&table_name=homeland_security&agency=Secret%20Service'
                      name='Secret Service' branch='Executive' topic='Defense' buttonName='Doc' />
                  </Modal>
                </div>
              </Col>

              <Col id='legislativeColLeft'>
                <div id='columnLeftPadding'>
                  <h4 id='whBreifingFeedHeading' onClick={(e) => {
                    e.preventDefault();
                    window.open('/us/tsa', "_self")
                  }}>
                    <b>Transportation Security Administration</b>
                    <ChevronRight id='clickChevron' />
                  </h4>
                  <div id='defTsa'></div>
                  {CreateFeedAPIDepartmentUS('&table_name=homeland_security&agency=Transportation%20Security%20Administration', 'defTsa',
                    'Transportation Security Administration Documents', 'Executive',
                    'Defense', 'Doc', GenerateFeedDepartment)}
                  <Modal id='whiteHouseDocsModal' show={homelandModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setHomelandModalIsOpen(false)}>
                    <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton>
                      <h3>Transportation Security Administration</h3>
                    </Modal.Header>
                    <GenerateFeedDepartment url='&table_name=homeland_security&agency=Transportation%20Security%20Administration'
                      name='Transportation Security Administration' branch='Executive' topic='Defense' buttonName='Doc' />
                  </Modal>
                </div>
              </Col>
            </Row>
          </Container>
        </Container>
      </div>

      <div id='branchPage'>
        <Container className='bgcolor'>
          <Container id='carouselBufferTwo'>
            <Row>
              <Col id='legislativeColRight'>
                <div id='columnRightPadding'>
                  <h4 id='whBreifingFeedHeading' onClick={(e) => {
                    e.preventDefault();
                    window.open('/us/uscis', "_self")
                  }}>
                    <b>U.S. Citizenship and Immigration Services</b>
                    <ChevronRight id='clickChevron' />
                  </h4>
                  <div id='defUscis'></div>
                  {CreateFeedAPIDepartmentUS('&table_name=homeland_security&agency=U.S.%20Citizenship%20and%20Immigration%20Services', 'defUscis',
                    'U.S. Citizenship and Immigration Services Documents', 'Executive',
                    'Defense', 'Doc', GenerateFeedDepartment)}
                  <Modal id='whiteHouseDocsModal' show={homelandModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setHomelandModalIsOpen(false)}>
                    <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton>
                      <h3>U.S. Citizenship and Immigration Services</h3>
                    </Modal.Header>
                    <GenerateFeedDepartment url='&table_name=homeland_security&agency=U.S.%20Citizenship%20and%20Immigration%20Services'
                      name='U.S. Citizenship and Immigration Services' branch='Executive' topic='Defense' buttonName='Doc' />
                  </Modal>
                </div>
              </Col>

              <Col id='legislativeColLeft'>
                <div id='columnLeftPadding'>
                  <h4 id='whBreifingFeedHeading' onClick={(e) => {
                    e.preventDefault();
                    window.open('/us/cbp', "_self")
                  }}>
                    <b>U.S. Customs and Border Protection</b>
                    <ChevronRight id='clickChevron' />
                  </h4>
                  <div id='defCbp'></div>
                  {CreateFeedAPIDepartmentUS('&table_name=homeland_security&agency=U.S.%20Customs%20and%20Border%20Protection', 'defCbp',
                    'U.S. Customs and Border Protection Documents', 'Executive',
                    'Defense', 'Doc', GenerateFeedDepartment)}
                  <Modal id='whiteHouseDocsModal' show={homelandModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setHomelandModalIsOpen(false)}>
                    <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton>
                      <h3>U.S. Customs and Border Protection</h3>
                    </Modal.Header>
                    <GenerateFeedDepartment url='&table_name=homeland_security&agency=U.S.%20Customs%20and%20Border%20Protection'
                      name='U.S. Customs and Border Protection' branch='Executive' topic='Defense' buttonName='Doc' />
                  </Modal>
                </div>
              </Col>
            </Row>
          </Container>
        </Container>
      </div>

      <div id='branchPage'>
        <Container className='bgcolor'>
          <Container id='carouselBufferTwo'>
            <Row>
              <Col id='legislativeColRight'>
                <div id='columnRightPadding'>
                  <h4 id='whBreifingFeedHeading' onClick={(e) => {
                    e.preventDefault();
                    window.open('/us/ice', "_self")
                  }}>
                    <b>U.S. Immigration and Customs Enforcement</b>
                    <ChevronRight id='clickChevron' />
                  </h4>
                  <div id='defIce'></div>
                  {CreateFeedAPIDepartmentUS('&table_name=homeland_security&agency=U.S.%20Immigration%20and%20Customs%20Enforcement', 'defIce',
                    'U.S. Immigration and Customs Enforcement Documents', 'Executive',
                    'Defense', 'Doc', GenerateFeedDepartment)}
                  <Modal id='whiteHouseDocsModal' show={homelandModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setHomelandModalIsOpen(false)}>
                    <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton>
                      <h3>U.S. Immigration and Customs Enforcement</h3>
                    </Modal.Header>
                    <GenerateFeedDepartment url='&table_name=homeland_security&agency=U.S.%20Immigration%20and%20Customs%20Enforcement'
                      name='U.S. Immigration and Customs Enforcement' branch='Executive' topic='Defense' buttonName='Doc' />
                  </Modal>
                </div>
              </Col>
            </Row>
          </Container>
        </Container>
      </div>
         
  



       
            <Modal id='aboutModal' show={aboutModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setAboutModalIsOpen(false)}>
            <div id='removedAlert'></div>
            <Modal.Header id='aboutModalHeader' closeButton><h3>About</h3></Modal.Header>
  <ModalBody id='aboutInfo'><p id='aboutParagraph'>Gov Glance was created to drive civic engagement and support a 
              healthier democracy by helping citizens get official Government news in an easy-to-navigate centralized location. 
              There will never be any opinion or political analysis(That's your job), only information from official Government 
              sources. Gov Glance is structured after the U.S. government that is broken into three branches Legislative, 
              Executive, and Judicial. To learn more about the structure and functions of The U.S. Government go to <a href='https://www.usa.gov/branches-of-government'>https://www.usa.gov/branches-of-government</a>.
              </p>

              <p id='aboutParagraph'>
              Gov Glance Foundation Inc. is a non-profit that was created to operate govglance.org and continue to create educational tools that help people learn about the workings of society
              </p>
            <br></br>

            <h3>Privacy</h3>

            <br></br>

              <p id='aboutParagraph'>Gov Glance does not collect any personal user information. 
              Features like collection save news items directly to your browser's local storage for your convenience
              and is not shared with Gov Glance servers. We do not collect, store, or send personal information to Google LLC, 
              but we do use some Google services to deliver our app to you. For any questions into Google’s privacy practices 
              here is a link to their privacy policy: <a href='https://policies.google.com/privacy?hl=en-US#intro'>https://policies.google.com/privacy?hl=en-US#intro</a>

              <br></br><br></br>

              For site analytics we use plausible.io an open source privacy friendly analytics provider. <a href="https://plausible.io/about" target="_blank">https://plausible.io/about</a>
            
            </p>
            <br></br>


            <h3>Contact</h3>

            <p id='aboutParagraph'>
                      Contact us via email: <a href='mailto:contact@govglance.org'>contact@govglance.org</a>

            </p>

            <br></br>


            
            </ModalBody>
          </Modal>
 
          <footer id='homeFooter'>
      <Navbar>
        <Row id='footerNav'>
      <Nav variant='pills'>
        
     <Nav.Link id='footerContent'>Gov Glance Foundation Inc.
</Nav.Link>
<Nav.Item>
<Nav.Link id='footerContent' href='mailto:contact@govglance.org'>Contact</Nav.Link>
</Nav.Item>
<Nav.Item>
<Nav.Link id='footerContent' onClick={(e) => {
                   e.preventDefault();
                   window.open('https://donate.stripe.com/28o3fXbdvbQO2RO288', "_blank")
                 }}> <HeartFill id='donateIcon'/>    Donate</Nav.Link>
</Nav.Item>
<Nav.Link onClick={(e) => {
                   e.preventDefault();
                   window.open('/about', "_self")}} id='footerContent'>Privacy</Nav.Link>
    
     
 </Nav>
 </Row>
 </Navbar>
      </footer>
      


            




</div>
         </div>
          

              </BrowserView>

              <MobileView>

              <DefenseTopicMobile/>

              </MobileView>
              
    </>
  )
}
 }



  






export default DefensePage;