
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link, Switch, NavLink,  } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment,  } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Tooltip, OverlayTrigger, Image, ModalFooter } from 'react-bootstrap';
import { ArrowsAngleExpand, ArrowsFullscreen, InfoCircle, FileText, FileMedicalFill, Coin, Tree, Globe2,  ShieldShaded, CircleFill, Pen, People, ChatSquareQuote, ChatLeftQuote, BlockquoteRight, Collection, Grid, ThreeDots, Stack, List, Files, FolderPlus, FolderCheck, Folder, CaretRight, ChevronRight, ChevronCompactRight, CaretRightFill, ArrowRightShort,  ArrowRightCircle, PlayCircle, PauseCircle, GeoAlt, HeartFill, QuestionCircleFill, MicFill, ReceiptCutoff, Bricks, Lightbulb } from 'react-bootstrap-icons'

import { BrowserView, MobileView } from 'react-device-detect';
import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import Axios from 'axios'
import { render } from '@testing-library/react';

import
 {supremeCourtSearch, whiteHouseSearch, billFeedModal, 
  createHouseFloorToday, createSenateFloorToday, createTrendingBills,
  createNationalAeronauticsAndSpaceAdministration, 
  createEnvironmentalProtectionAgency, createDepartmentOfHomelandSecurity,
  createDepartmentOfVeteransAffairs, createDepartmentOfEducation,
  createDepartmentOfHousingAndUrbanDevelopment, 
  createDepartmentOfHealthAndHumanServices, createDepartmentOfLabor
, createDepartmentOfCommerce, createDepartmentOfAgriculture,
createDepartmentOfTheInterior, createDepartmentOfJustice,
createDepartmentOfTransportation, createDepartmentOfDefense,
createDepartmentOfTreasury, createDepartmentOfEnergy,
createDepartmentOfState, createBillsEnrolledMobile,
createBillsEnrolled, createNewLaws, createGAOReports,
createExecutiveOrders, createPresidentialProclamations, createCongressionalHearings,
createCongressionalBudgetOfficeMobile, createCongressionalBudgetOffice,
createHouseCommitteeOversightAndReform,
createHouseCommitteeOnNaturalResources,
createHouseCommitteeOnTheJudiciary,
createHouseCommitteeOnHouseAdministration,
createHouseCommitteeOnHomelandSecurity,
createHouseCommitteeOnForeignAffairs,
createHouseCommitteeOnFinancialServices,
createHouseCommitteeOnEthics,
createHouseCommitteeOnEnergyAndCommerce,
createHouseCommitteeOnEducationAndLabor,
createHouseCommitteeOnTheBudget,
createHouseCommitteeOnArmedServices,
createHouseCommitteeOnAppropriations,
createHouseCommitteeOnAgriculture,
createReports,
createBillFeedTwoMobile,
createBillFeedTwo,
createBillFeed, createWhiteHouseFeedMobile
, createWhiteHouseFeed, createWhiteHouseDocuments, 
createBillsPresentedFeed, createCourtAppealsMobile,
createCourtAppeals, createUSCourtNewsFeedMobile,
createUSCourtNewsFeed, saveToCollection,
createFullBillFeed, ListItem, Speak, feedText, variableTest, myMethod, myMethod2, createEconomicIndicators, 
createSecuritiesAndExchangeCommissionPressReleases, createNationWeatherServices, createCDCNewsroom,
createSecuritiesAndExchangeCommissionSpeechesAndStatements, createNationalScienceFoundation, createHouseCommitteeOnIntelligence,
 createUNTopStories, createCDCOutbreaks, createCDCTravelNotices, createFoodRecalls, createFoodSafety, createFederalElectionCommissionNews, createElectionAssistancCommissioneNews, 
 createBillStatus, CreateFeedItem, CreateFeedItemWithDocButton, CreateFeedItemWithLawButton, CreateFeedItemGAOReports,
CreateFeedItemCongressionalReports,
CreateFeedItemPresidentialDocuments,
CreateFeedItemExecutiveOrders,
CreateFeedItemCourtAppeals,
CreateFeedItemEconomicIndicators,
CreateFeedItemBillUpdates,
createBIllItem,
CreateFeedItemSupremeCourtArguments,
CreateFeedItemSupremeCourtOpinions,
CreateFeedItemTest,
CreateFeedItemBillUpdatesFlip,
CreateFeedItemWithDocumentFlip,
CreateFeedItemWithDocButtonDateFix,
CreateFeedItemWithLawButtonFlip,
CreateFeedItemCanadaDateFix,
CreateFeedItemHouseOfCommons,
CreateFeedItemCanada,
CreateFeedAPIRecentCache,
CreateFeedAPIRecentCacheCanada,
CreateFeedAPI} from '../Functions'
import DesktopNavbar from '../DesktopNavbar';
import HomeMobile from '../HomeMobile';
import BillUpdatesCanadae from './Legislative/BillUpdatesCanada';
import BillUpdatesCanada from './Legislative/BillUpdatesCanada';
import CanadaDesktopNavbar from './CanadaDesktopNavbar';
import ExecutiveNewsCanada from './Executive/ExecutiveNewsCanada';
import JudicialAppointmentsCanada from './Judicial/JudicialAppointmentsCanada';
import CanadaHomeMobile from './CanadaHomeMobile';
import CanadaPMMedia from './Executive/CanadaPMMedia';
import HouseOfCommons from './Legislative/HouseOfCommons';
import StandingCommitteeOnVeteransAffairs from './Legislative/StandingCommitteeOnVeteransAffairs';
import SupremeCourtNews from './Judicial/SupremeCourtNews';
import SupremeCourtJudgements from './Judicial/SupremeCourtJudgements';
import SupremeCourtReports from './Judicial/SupremeCourtReports';
import StandingCommitteeOnFinance from './Legislative/StandingCommitteeOnFinance';
import StandingCommitteeOnInternationalTrade from './Legislative/StandingCommitteeOnInternationalTrade';
import StandingCommitteeOnEnvironment from './Legislative/StandingCommitteeOnEnvironment';
import StandingCommitteeOnAgricultureAndFood from './Legislative/StandingCommitteeOnAgricultureAndFood';
import StandingCommitteeOnNaturalResources from './Legislative/StandingCommitteeOnNaturalResources';
import StandingCommitteeOnNationalDefence from './Legislative/StandingCommitteeOnNationalDefence';
import SpecialCommitteeChinaRelation from './Legislative/SpecialCommitteeChinaRelation';
import StandingCommitteeOnIndustryAndTech from './Legislative/StandingCommitteeOnIndustryAndTech';
import GenerateFeedRecentCanada from '../GenerateFeedRecentCanada';
import GenerateFeed from '../GenerateFeed';
import BillsEnrolled from '../BillsEnrolled';

const CustomModal = ({ isOpen, onClose, title, content }) => (
  <Modal id='fullBillModal' show={isOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={onClose}>
    <Modal.Header id='billModalHeader' closeButton><h3>{title}</h3></Modal.Header>
    {content}
  </Modal>
);












var collectionCounter = 0
var carouselInterval = 30000;
var mobileCarouselInterval = 60000;

//https://evening-plains-21303.herokuapp.com/







  








export const CanadaHome = () =>   {
  render()
  
 
  {

  
  const [show, setShow] = useState(true);
  
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [fullscreen, setFullScreen] = useState(true);
  const [statesModalIsOpen, setStatesModalIsOpen] = useState(false)
  
  const [fullBillModalIsOpen, setFullBillModalIsOpen] = useState(false)
  const [billHelpModalIsOpen, setBillHelpModalIsOpen] = useState(false)

  const [reportModalIsOpen, setReportModalIsOpen] = useState(false)


  const [whiteHouseFeedModalIsOpen, setWhiteHouseFeedModalIsOpen] = useState(false)
  const [whiteHouseDocsModalIsOpen, setWhiteHouseDocsModalIsOpen] = useState(false)


  const [courtNewsModalIsOpen, setCourtNewsModalIsOpen] = useState(false)
  const [courtModalIsOpen, setCourtModalIsOpen] = useState(false)
  const [courtOpinionsModalIsOpen, setCourtOpinionsModalIsOpen] = useState(false)
  const [courtAppealsModalIsOpen, setCourtAppealsModalIsOpen] = useState(false)

  const [newLawsModalIsOpen, setNewLawsModalIsOpen] = useState(false)

  const [billsEnrolledModalIsOpen, setBillsEnrolledModalIsOpen] = useState(false)
  const [nsfModalIsOpen, setNSFModalIsOpen] = useState(false)
  const [legislativePageBillsEnrolledModalIsOpen, setLegislativePageBillsEnrolledModalIsOpen] = useState(false)
  const [secSAndSModalIsOpen, setSECSAndSModalIsOpen] = useState(false)
  const [economicIndicatorsModalIsOpen, setEconomicIndicatorsModalIsOpen] = useState(false)
  const [departmentOfStateModalIsOpen, setDepartmentOfStateModalIsOpen] = useState(false)
  const [departmentOfTreasuryModalIsOpen, setDepartmentOfTreasuryModalIsOpen] = useState(false)
  const [departmentOfDefenseModalIsOpen, setDepartmentOfDefenseModalIsOpen] = useState(false)
  const [departmentOfJusticeModalIsOpen, setDepartmentOfJusticeModalIsOpen] = useState(false)
  const [departmentOfTheInteriorModalIsOpen, setDepartmentOfTheInteriorModalIsOpen] = useState(false)
  const [departmentOfAgricultureModalIsOpen, setDepartmentOfAgricultureModalIsOpen] = useState(false)
  const [departmentOfCommerceModalIsOpen, setDepartmentOfCommerceModalIsOpen] = useState(false)
  const [departmentOfLaborModalIsOpen, setDepartmentOfLaborModalIsOpen] = useState(false)
  const [departmentOfHealthAndHumanServicesModalIsOpen, setDepartmentOfHealthAndHumanServicesModalIsOpen] = useState(false)
  const [departmentOfHousingAndUrbanDevelopmentModalIsOpen, setDepartmentOfHousingAndUrbanDevelopmentModalIsOpen] = useState(false)
  const [departmentOfTransportationModalIsOpen, setDepartmentOfTransportationModalIsOpen] = useState(false)
  const [departmentOfEnergyModalIsOpen, setDepartmentOfEnergyModalIsOpen] = useState(false)
  const [departmentOfEducationModalIsOpen, setDepartmentOfEducationModalIsOpen] = useState(false)
  const [departmentOfVeteransAffairsModalIsOpen, setDepartmentOfVeteransAffairsModalIsOpen] = useState(false)
  const [departmentOfHomelandSecurityModalIsOpen, setDepartmentOfHomelandSecurityModalIsOpen] = useState(false)
  const [epaModalIsOpen, setEPAModalIsOpen] = useState(false)
  const [nasaModalIsOpen, setNASAModalIsOpen] = useState(false)
  const [trendingBillsModalIsOpen, setTrendingBillsModalIsOpen] = useState(false)
  const [trendingBillsOptionOne, setTrendingBillsOptionOneModalIsOpen ] = useState(false)
  const [trendingBillsOptionTwo, setTrendingBillsOptionTwoModalIsOpen ] = useState(false)
  const [trendingBillsOptionThree, setTrendingBillsOptionThreeModalIsOpen ] = useState(false)
  const [trendingBillsOptionFour, setTrendingBillsOptionFourModalIsOpen ] = useState(false)
  const [trendingBillsOptionFive, setTrendingBillsOptionFiveModalIsOpen ] = useState(false)
  const [trendingBillsOptionSix, setTrendingBillsOptionSixModalIsOpen ] = useState(false)
  const [trendingBillsOptionSeven, setTrendingBillsOptionSevenModalIsOpen ] = useState(false)
  const [trendingBillsOptionEight, setTrendingBillsOptionEightModalIsOpen ] = useState(false)
  const [trendingBillsOptionNine, setTrendingBillsOptionNineModalIsOpen ] = useState(false)
  const [trendingBillsOptionTen, setTrendingBillsOptionTenModalIsOpen ] = useState(false)
  const [trendingBillsOptionEleven, setTrendingBillsOptionElevenModalIsOpen ] = useState(false)
  const [trendingBillsOptionTweleve, setTrendingBillsOptionTweleveModalIsOpen ] = useState(false)
  const [trendingBillsOptionThirteen, setTrendingBillsOptionThirteenModalIsOpen ] = useState(false)
  const [trendingBillsOptionFourteen, setTrendingBillsOptionFourteenModalIsOpen ] = useState(false)
  const [trendingBillsOptionFifteen, setTrendingBillsOptionFifteenModalIsOpen ] = useState(false)
  const [goaReportsModalIsOpen, setGAOReportsModalIsOpen] = useState(false)
    const [houseComitteeOnAgricultureModalIsOpen, setHouseComitteeOnAgricultureModalIsOpen] = useState(false)
    const [houseCommitteeOnAppropriationsModalIsOpen, setHouseCommitteeOnAppropriationsModalIsOpen] = useState(false)
    const [houseCommitteeOnArmedServicesModalIsOpen, setHouseCommitteeOnOnArmedServicesModalIsOpen] = useState(false)
    const [houseCommitteeOnTheBudgetModalIsOpen, setHouseCommitteeOnTheBudgetModalIsOpen] = useState(false)
    const [houseCommitteeOnEnergyAndCommerceModalIsOpen, setHouseCommitteeOnEnergyAndCommerceModalIsOpen] = useState(false)
    const [houseCommitteeOnEducationAndLaborModalIsOpen, setHouseCommitteeOnEducationAndLaborModalIsOpen] = useState(false)
    const [houseCommitteeOnEthicsModalIsOpen, setHouseCommitteeOnEthicsModalIsOpen] = useState(false)
    const [houseCommitteeOnFinancialServicesModalIsOpen, setHouseCommitteeOnFinancialServicesModalIsOpen] = useState(false)
    const [houseCommitteeOnForeignAffairsModalIsOpen, setHouseCommitteeOnForeignAffairsModalIsOpen] = useState(false)
    const [houseCommitteeOnHomelandSecurityModalIsOpen, setHouseCommitteeOnHomelandSecurityModalIsOpen] = useState(false)
    const [houseCommitteeOnHouseAdministrationModalIsOpen, setHouseCommitteeOnHouseAdministrationModalIsOpen] = useState(false)
    const [houseCommitteeOnTheJudiciaryModalIsOpen, setHouseCommitteeOnTheJudiciaryModalIsOpen] = useState(false)
    const [houseCommitteeOnNaturalResourcesModalIsOpen, setHouseCommitteeOnNaturalResourcesModalIsOpen] = useState(false)
    const [houseCommitteeOnOversightAndReformModalIsOpen, setHouseCommitteeOnOversightAndReformModalIsOpen] = useState(false)
    const [congressionalBudgetOfficeModalIsOpen, setCongressionalBudgetOfficeModalIsOpen] = useState(false)
    const [presidentialProclamationsModalIsOpen, setPresidentialProclamationsModalIsOpen] = useState(false)
    const [cdcNewsroomModalIsOpen, setCDCNewsroomModalIsOpen] = useState(false)
    const [executiveOrdersModalIsOpen, setExecutiveOrdersModalIsOpen] = useState(false)
    const [unTopStoriesModalIsOpen, setUNTopStoriesModalIsOpen] = useState(false)
    const [houseCommitteeOnIntelligenceModalIsOpen, setHouseCommitteeOnIntelligenceModalIsOpen] = useState(false)
    const [travelNoticesModalIsOpen, setTravelNoticesModalIsOpen] = useState(false)
  
    const [foodRecallsModalIsOpen, setFoodRecallsModalIsOpen] = useState(false)
    const [foodSafetyModalIsOpen, setFoodSafetyModalIsOpen] = useState(false)

    const [outbreaksModalIsOpen, setOutbreaksModalIsOpen] = useState(false)
    const [congressionalHearingsModalIsOpen, setCongressionalHearingsModalIsOpen] = useState(false)

    const [houseFloorModalIsOpen, setHouseFloorModalIsOpen] = useState(false)

    const [billStatusModalIsOpen, setBillStatusModalIsOpen] = useState(false)
    const [fecModalIsOpen, setFECModalIsOpen] = useState(false)
    const [eacModalIsOpen, setEACModalIsOpen] = useState(false)


  const [collectionsModalIsOpen, setCollectionsModalIsOpen] = useState(false)
  const [aboutModalIsOpen, setAboutModalIsOpen] = useState(false)
  const [secPressModalIsOpen, setSECPressModalIsOpen] = useState(false)

  const [alertModalIsOpen, setAlertModalIsOpen] = useState(true)


  const [declarationModalIsOpen, setDeclarationModalIsOpen] = useState(false)
  const [constitutionModalIsOpen, setConstitutionModalIsOpen] = useState(false)
  const [billOfRightsnModalIsOpen, setBillOfRightsModalIsOpen] = useState(false)
 
  const [modalInfo, setModalInfo] = useState({
    isOpen: false,
    title: '',
    content: null
  });

  const openModal = (title, content) => {
    setModalInfo({
      isOpen: true,
      title,
      content
    });
  };

  const closeModal = () => {
    setModalInfo({
      isOpen: false,
      title: '',
      content: null
    });
  };
   




  function BranchesTab() {
  

    return (
      <>
   
      <div id='branchesTab'>


      <Row id='branchesRow'>

{/*Health Column */}
<Col id='homeColumn'>

<h3 id='branchHeading' onClick={() =>
                            openModal('Canada Recent',
                              <GenerateFeedRecentCanada/>)}> Recent</h3>
<div  id='recent'></div> 
{CreateFeedAPIRecentCacheCanada('https://api.govglance.org/posts/recent/schema?limit=1&skip=0&schema=canada', 'recent', 
                'Canada Recents', 'N/A', 
                'N/A', 'Doc', GenerateFeedRecentCanada)}
</Col>

<Col id='homeColumn'>
<h3 id='branchHeading' >  </h3>
<div  id='recent2'></div> 
{CreateFeedAPIRecentCacheCanada('https://api.govglance.org/posts/recent/schema?limit=1&skip=1&schema=canada', 'recent2', 
                'Canada Recents', 'N/A', 
                'N/A', 'Doc', GenerateFeedRecentCanada)}
</Col>
<Col id='homeColumn'>
<h3 id='branchHeading' >  </h3>
<div  id='recent3'></div> 
{CreateFeedAPIRecentCacheCanada('https://api.govglance.org/posts/recent/schema?limit=1&skip=2&schema=canada', 'recent3', 
                'Canada Recents', 'N/A', 
                'N/A', 'Doc', GenerateFeedRecentCanada)}
</Col>

                



{/*Placeholder2 Column */}

</Row>


        <Row id='branchesRow'>

            {/*Legislative Column */}
          <Col id='homeColumn'>

              <Row id="homePageBranchRow">

                
           
                <h3 id='branchHeading' onClick={() => window.open('/canada/legislative', "_self")}> <Pen></Pen> <b id='branchHeadingBold'>Legislative</b><ChevronRight id='clickChevron'></ChevronRight></h3>
               {/*
                <Button size='md' id='congressButton' variant='primary' onClick={(e) => {
                  e.preventDefault();
                  window.open('https://www.congress.gov/', "_blank")
                }} >congress.gov</Button>


                <Button size='md' id='dailyDigestButton' variant='primary' onClick={(e) => {
                  e.preventDefault();
                  window.open('https://www.congress.gov/congressional-record', "_blank")
                }} >Congressional Record</Button>

              */}

            
            </Row>
 
            {/*Legislative Desktop Carousel*/}
            
  
          <Carousel id='homeCarousel' controls={false}  >



        
          <Carousel.Item id='legislativeCarousel' interval={carouselInterval}>
                          <div id='legislativeCon'>
                  
                      




                      <Col id='legislativeColRight'>
                        <div id='columnLefttPadding'>



                          <h4 id='feedHeadingBillsEnrolled' onClick={() => setReportModalIsOpen(true)}><b>Bill Updates</b></h4>
                          <div id='billUpdates'></div>

                          {CreateFeedAPI('&skip=0&schema=canada&table=bills&order_by=created_at', 'billUpdates', 
                            'Bill Updates', 'Legislative', 
                            'N/A', 'Bills', GenerateFeed)}


                          
              <Modal id='mobileFullBillModal' show={reportModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setReportModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Bill Updates</h3></Modal.Header>
<GenerateFeed url='&skip=0&schema=canada&table=bills&order_by=created_at' 
                            name='Bill Updates' branch='Legislative' topic='N/A' buttonName='Bill' />
</Modal>





                        </div>
                      </Col>

              </div>

            </Carousel.Item>

            <Carousel.Item id='legislativeCarousel' interval={carouselInterval}>
                          <div id='legislativeCon'>
             
                      <Col id='legislativeColRight'>
                        <div id='columnLefttPadding'>



                          <h4 id='feedHeadingBillsEnrolled' onClick={() => setTrendingBillsOptionOneModalIsOpen(true)}><b>House of Commons News </b></h4>
                          <div id='hOC'></div>
                          
                          {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
    {CreateFeedItemHouseOfCommons('https://gov-glance-canada.nyc3.digitaloceanspaces.com/legislative-xml/house_of_commons_newsroom/house_of_commons_newsroom.xml', 'hOC', 'House of Commons News', 'Legislative', 'N/A', HouseOfCommons)}

  <Modal id='mobileFullBillModal' show={trendingBillsOptionOne} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsOptionOneModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>House of Commons News</h3></Modal.Header>
<HouseOfCommons/>
</Modal>
                        </div>
                      </Col>

                     


             




                    






              </div>

            </Carousel.Item>

            <Carousel.Item id='legislativeCarousel' interval={carouselInterval}>
                          <div id='legislativeCon'>
             
                      <Col id='legislativeColRight'>
                        <div id='columnLefttPadding'>



                          <h4 id='feedHeadingBillsEnrolled' onClick={() => setTrendingBillsOptionFourModalIsOpen(true)}><b>Standing Committee on Veterans' Affairs</b></h4>
                          <div id='committee1'></div>
                          
                          {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
    {CreateFeedItem('https://gov-glance-canada.nyc3.digitaloceanspaces.com/legislative-xml/committees/acva.xml', 'committee1', 'Standing Committee On Veterans Affairs', 'Legislative', 
    'N/A', StandingCommitteeOnVeteransAffairs)}
  <Modal id='mobileFullBillModal' show={trendingBillsOptionFour} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsOptionFourModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Standing Committee on Veterans' Affairs</h3></Modal.Header>
<HouseOfCommons/>
</Modal>
                        </div>
                      </Col>

              </div>

            </Carousel.Item>

            <Carousel.Item interval={carouselInterval} >

<div id='legislativeCon'>



  <Col id='legislativeColRight'>
    <div id='columnLefttPadding'>
    


    <Button id='viewFullLegislativePageButton' size='lg' href='/canada/legislative' >View all Legislative news</Button>
 

    </div>
  </Col>
















</div>

</Carousel.Item>
      

          
                </Carousel>
          </Col>


          {/*Executive Column */}
          <Col id='homeColumn'>

          <Row id="homePageBranchRow">
                <h3 id='branchHeading' onClick={() => window.open('/canada/executive', "_self")}><People></People> <b id='branchHeadingBold'>Executive</b><ChevronRight id='clickChevron'></ChevronRight></h3>
            
           
            </Row>
            
            <Carousel id='homeCarousel' controls={false}  >




            <Carousel.Item id='legislativeCarousel' interval={carouselInterval}>
                          <div id='legislativeCon'>
                  
                      




                      <Col id='legislativeColRight'>
                        <div id='columnLefttPadding'>



                          <h4 id='feedHeadingBillsEnrolled' onClick={() => setBillsEnrolledModalIsOpen(true)}><b>Prime Minister's Office</b></h4>
                          <div id='executiveNews'></div>
                          
                                    {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
                                    {CreateFeedAPI('&schema=canada&table=prime_minister&order_by=created_at', 'executiveNews', 
                            'Prime Minister Office', 'Executive', 
                            'N/A', 'Doc', GenerateFeed)}          

                          
              <Modal id='mobileFullBillModal' show={billsEnrolledModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setBillsEnrolledModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Prime Minister's Office </h3></Modal.Header>
<GenerateFeed url='&schema=canada&table=prime_minister&order_by=created_at' 
                            name='Prime Ministers Office' branch='Executive' topic='N/A' buttonName='Doc' />
</Modal>





                        </div>
                      </Col>

                     


             




                    






              </div>

            </Carousel.Item>

            <Carousel.Item id='legislativeCarousel' interval={carouselInterval}>
                          <div id='legislativeCon'>
                  
                      




                      <Col id='legislativeColRight'>
                        <div id='columnLefttPadding'>



                          <h4 id='feedHeadingBillsEnrolled' onClick={() => setTrendingBillsOptionFiveModalIsOpen(true)}><b>Prime Minister's Office Media</b></h4>
                          <div id='pmMedia'></div>
                          
                          {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
    {CreateFeedItemCanadaDateFix('https://www.pm.gc.ca/en/media.rss', 'pmMedia', 'Prime Ministers Office Media', 'Executive', 'N/A',CanadaPMMedia)}

 



  <Modal id='mobileFullBillModal' show={trendingBillsOptionFive} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() =>  setTrendingBillsOptionFiveModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Prime Minister's Office Media</h3></Modal.Header>
<CanadaPMMedia/>
</Modal>
                        </div>
                      </Col>

                     


             




                    






              </div>

            </Carousel.Item>

            <Carousel.Item interval={carouselInterval} >

<div id='legislativeCon'>



  <Col id='legislativeColRight'>
    <div id='columnLefttPadding'>
    


    <Button id='viewFullLegislativePageButton' size='lg' href='/canada/executive' >View all Executive news</Button>
 

    </div>
  </Col>
















</div>

</Carousel.Item>
      

          
                </Carousel>
            {/*Executive Desktop Carousel*/}

    
          </Col>


            {/*Judical Column */}
            <Col id='homeColumn'>


               <Row id="homePageBranchRow">
                <h3 id='branchHeading' onClick={() => window.open('/canada/judicial', "_self")}><ShieldShaded></ShieldShaded><b id='branchHeadingBold'>Judicial</b><ChevronRight id='clickChevron'></ChevronRight></h3>
              
            
             </Row>
            
            {/*Judical Desktop Carousel*/}
            <Carousel id='homeCarousel' controls={false}  >


            <Carousel.Item id='legislativeCarousel' interval={carouselInterval}>
              <div id='legislativeCon'>
   
          <Col id='legislativeColRight'>
            <div id='columnLefttPadding'>



            <h4 id='feedHeadingBillsEnrolled' onClick={() => setTrendingBillsOptionSixModalIsOpen(true)}><b>Supreme Court News</b></h4>

<div id='courtNews'></div>
            
            {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
{CreateFeedItemCanada('https://decisions.scc-csc.ca/scc-csc/news/en/rss.do', 'courtNews', 'Supreme Court News', 'Legislative', 'N/A', SupremeCourtNews)}






<Modal id='mobileFullBillModal' show={trendingBillsOptionSix} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsOptionSixModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Supreme Court News</h3></Modal.Header>
<SupremeCourtNews/>
</Modal>
            </div>
          </Col>

  </div>

</Carousel.Item>

<Carousel.Item id='legislativeCarousel' interval={carouselInterval}>
              <div id='legislativeCon'>
      
          




          <Col id='legislativeColRight'>
            <div id='columnLefttPadding'>



              <h4 id='feedHeadingBillsEnrolled' onClick={() => setTrendingBillsOptionSevenModalIsOpen(true)}><b>Judicial Appointments</b></h4>
              <div id='judicialApts'></div>
              
                        {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
  {CreateFeedItem('https://gov-glance-canada.nyc3.digitaloceanspaces.com/judicial-xml/judicial_appointments.xml', 'judicialApts', 'Judicia Appointments', 'Legislative', 'N/A', JudicialAppointmentsCanada)}

             
  <Modal id='mobileFullBillModal' show={trendingBillsOptionSeven} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsOptionSixModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Judicial Appointments</h3></Modal.Header>
<JudicialAppointmentsCanada/>
</Modal>
              
          





            </div>
          </Col>

         


 




        






  </div>

</Carousel.Item>

<Carousel.Item id='legislativeCarousel' interval={carouselInterval}>
              <div id='legislativeCon'>
   
          <Col id='legislativeColRight'>
            <div id='columnLefttPadding'>



            <h4 id='feedHeadingBillsEnrolled' onClick={() => setTrendingBillsOptionTwoModalIsOpen(true)}><b>Supreme Court Judgements</b></h4>
          
          <div id='courtJudgements'></div>
                      
                      {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
          {CreateFeedItemCanada('https://decisions.scc-csc.ca/scc-csc/scc-csc/en/rss.do', 'courtJudgements', 'Supreme Court Judgements', 'Legislative', 'N/A', SupremeCourtJudgements)}
          
          
          <Modal id='mobileFullBillModal' show={trendingBillsOptionTwo} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsOptionTwoModalIsOpen(false)}>
          <Modal.Header id='mobileBillModalHeader' closeButton><h3>Supreme Court Judgements</h3></Modal.Header>
          <SupremeCourtJudgements/>
          </Modal>
            </div>
          </Col>

  </div>

</Carousel.Item>

<Carousel.Item id='legislativeCarousel' interval={carouselInterval}>
              <div id='legislativeCon'>
   
          <Col id='legislativeColRight'>
            <div id='columnLefttPadding'>



            <h4 id='feedHeadingBillsEnrolled' onClick={() => setTrendingBillsOptionThreeModalIsOpen(true)}><b>Supreme Court Reports</b></h4>

<div id='courtReports'></div>
            
            {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
{CreateFeedItemCanada('https://decisions.scc-csc.ca/scc-csc/scr/en/rss.do', 'courtReports', 'Supreme Court Reports', 'Legislative', 'N/A', SupremeCourtReports)}






            </div>
          </Col>

  </div>

</Carousel.Item>

<Carousel.Item interval={carouselInterval} >

<div id='legislativeCon'>



  <Col id='legislativeColRight'>
    <div id='columnLefttPadding'>
    


    <Button id='viewFullLegislativePageButton' size='lg' href='/canada/judicial' >View all Judicial news</Button>
 

    </div>
  </Col>

</div>

</Carousel.Item>

    </Carousel>
       
          </Col>
          </Row>

          <Row id='topicsRow'>
{/*Economy Column */}
 <Col id='topicColumn'>

                        <Row id="topicPageBranchRow">

                        <h3 id='branchHeading' onClick={() => window.open('/canada/economy', "_self")}> <Coin></Coin> <b id='branchHeadingBold'>Economy</b><ChevronRight id='clickChevron'></ChevronRight></h3>


                          </Row>
<Carousel id='homeCarousel' controls={false} >

  
<Carousel.Item id='legislativeCarousel' interval={carouselInterval}>
              <div id='legislativeCon'>
   
          <Col id='legislativeColRight'>
            <div id='columnLefttPadding'>



            <h4 id='feedHeadingBillsEnrolled'  onClick={() => setTrendingBillsOptionEightModalIsOpen(true)} ><b>Standing Committee on Finance</b></h4>
                          <div id='committee7'></div>
                          
                                    {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
              {CreateFeedItem('https://gov-glance-canada.nyc3.digitaloceanspaces.com/legislative-xml/committees/fina.xml', 'committee7', 'Standing Committee on Finance', 'Legislative', 
              'N/A', StandingCommitteeOnFinance )}
                       




<Modal id='mobileFullBillModal' show={trendingBillsOptionEight} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsOptionEightModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Standing Committee on Finance</h3></Modal.Header>
<StandingCommitteeOnFinance/>
</Modal>
            </div>
          </Col>

  </div>

</Carousel.Item>

<Carousel.Item id='legislativeCarousel' interval={carouselInterval}>
              <div id='legislativeCon'>
   
          <Col id='legislativeColRight'>
            <div id='columnLefttPadding'>



            <h4 id='feedHeadingBillsEnrolled' onClick={() => setTrendingBillsOptionTenModalIsOpen(true)}  ><b>Standing Committee on International Trade </b></h4>
                          <div id='committee4'></div>
                          
                                    {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
              {CreateFeedItem('https://gov-glance-canada.nyc3.digitaloceanspaces.com/legislative-xml/committees/ciit.xml', 'committee4', 'Standing Committee on International Trade ', 'Legislative', 
              'N/A',StandingCommitteeOnInternationalTrade )}
                          
<Modal id='mobileFullBillModal' show={trendingBillsOptionTen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsOptionTenModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Standing Committee on International Trade</h3></Modal.Header>
<StandingCommitteeOnInternationalTrade/>
</Modal>
            </div>
          </Col>

  </div>

</Carousel.Item>

<Carousel.Item interval={carouselInterval} >

<div id='legislativeCon'>



  <Col id='legislativeColRight'>
    <div id='columnLefttPadding'>
    


    <Button id='viewFullLegislativePageButton' size='lg' href='/canada/economy' >View all Economy news</Button>
 



    





    </div>
  </Col>
















</div>

</Carousel.Item>



 </Carousel>
                  </Col>

{/*Environment Column */}
<Col id='topicColumn'>

                        <Row id="topicPageBranchRow">

                        <h3 id='branchHeading' onClick={() => window.open('/canada/environment', "_self")}> <Tree></Tree> <b id='branchHeadingBold'>Environment</b><ChevronRight id='clickChevron'></ChevronRight></h3>


                          </Row>

                          <Carousel id='homeCarousel' controls={false} >
                          <Carousel.Item id='legislativeCarousel' interval={carouselInterval}>
              <div id='legislativeCon'>
   
          <Col id='legislativeColRight'>
            <div id='columnLefttPadding'>



            <h4 id='feedHeadingBillsEnrolled' onClick={() => setTrendingBillsOptionTenModalIsOpen(true)}><b>Standing Committee on Environment and Sustainable Development </b></h4>
<div id='committee5'></div>

          {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
{CreateFeedItem('https://gov-glance-canada.nyc3.digitaloceanspaces.com/legislative-xml/committees/envi.xml', 'committee5', 'Standing Committee on Environment and Sustainable Development', 'Legislative', 
'N/A',StandingCommitteeOnEnvironment )}
                          
<Modal id='mobileFullBillModal' show={trendingBillsOptionTen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsOptionTenModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Standing Committee on Environment and Sustainable Development</h3></Modal.Header>
<StandingCommitteeOnEnvironment/>
</Modal>
            </div>
          </Col>

  </div>

</Carousel.Item>

<Carousel.Item id='legislativeCarousel' interval={carouselInterval}>
              <div id='legislativeCon'>
   
          <Col id='legislativeColRight'>
            <div id='columnLefttPadding'>



            <h4 id='feedHeadingBillsEnrolled' onClick={() => setTrendingBillsOptionElevenModalIsOpen(true)}><b>Standing Committee on Agriculture and Agri-Food </b></h4>
<div id='committee2'></div>

          {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
{CreateFeedItem('https://gov-glance-canada.nyc3.digitaloceanspaces.com/legislative-xml/committees/agri.xml', 'committee2', 'Standing Committee on Agriculture and Agri-Food ', 'Legislative', 
'N/A', StandingCommitteeOnAgricultureAndFood )}
                          
<Modal id='mobileFullBillModal' show={trendingBillsOptionEleven} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsOptionElevenModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Standing Committee on Agriculture and Agri-Food</h3></Modal.Header>
<StandingCommitteeOnAgricultureAndFood/>
</Modal>
            </div>
          </Col>

  </div>

</Carousel.Item>

<Carousel.Item id='legislativeCarousel' interval={carouselInterval}>
              <div id='legislativeCon'>
   
          <Col id='legislativeColRight'>
            <div id='columnLefttPadding'>



            <h4 id='feedHeadingBillsEnrolled' onClick={() => setTrendingBillsOptionTweleveModalIsOpen(true)}><b>Standing Committee on Natural Resources </b></h4>
<div id='committee*'></div>

          {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
{CreateFeedItem('https://gov-glance-canada.nyc3.digitaloceanspaces.com/legislative-xml/committees/rnnr.xml', 'committee*', 'Standing Committee on Natural Resources', 'Legislative', 
'N/A', StandingCommitteeOnNaturalResources )}
                          
<Modal id='mobileFullBillModal' show={trendingBillsOptionTweleve} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsOptionTweleveModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Standing Committee on Natural Resources</h3></Modal.Header>
<StandingCommitteeOnNaturalResources/>
</Modal>
            </div>
          </Col>

  </div>

</Carousel.Item>


<Carousel.Item interval={carouselInterval} >

<div id='legislativeCon'>



  <Col id='legislativeColRight'>
    <div id='columnLefttPadding'>
    


    <Button id='viewFullLegislativePageButton' size='lg' href='/canada/environment' >View all Environment news</Button>
 



    





    </div>
  </Col>
















</div>

</Carousel.Item>



 </Carousel>
                          </Col>
{/*Foreign Affairs Column */}
<Col id='topicColumn'>

<Row id="topicPageBranchRow">

<h3 id='branchHeading' onClick={() => window.open('/canada/foreignaffairs', "_self")}> <Globe2></Globe2> <b id='branchHeadingBold'>Foreign Affairs</b><ChevronRight id='clickChevron'></ChevronRight></h3>


  </Row>
  <Carousel id='homeCarousel' controls={false} >

  <Carousel.Item id='legislativeCarousel' interval={carouselInterval}>
              <div id='legislativeCon'>
   
          <Col id='legislativeColRight'>
            <div id='columnLefttPadding'>


            <h4 id='feedHeadingBillsEnrolled' onClick={() => setTrendingBillsOptionThirteenModalIsOpen(true)}><b>Standing Committee on National Defence </b></h4>
<div id='committee10'></div>

          {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
{CreateFeedItem('https://gov-glance-canada.nyc3.digitaloceanspaces.com/legislative-xml/committees/nddn.xml', 'committee10', 'Standing Committee on National Defence', 'Legislative', 
'N/A', StandingCommitteeOnNationalDefence )}
                          
<Modal id='mobileFullBillModal' show={trendingBillsOptionThirteen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsOptionThirteenModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Standing Committee on National Defence</h3></Modal.Header>
<StandingCommitteeOnNationalDefence/>
</Modal>
            </div>
          </Col>

  </div>

</Carousel.Item>


<Carousel.Item id='legislativeCarousel' interval={carouselInterval}>
              <div id='legislativeCon'>
   
          <Col id='legislativeColRight'>
            <div id='columnLefttPadding'>



            <h4 id='feedHeadingBillsEnrolled' onClick={() => setTrendingBillsOptionFourteenModalIsOpen(true)}><b>Special Committee on the Canada–People’s Republic of China Relationship </b></h4>
<div id='committee3'></div>

          {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
{CreateFeedItem('https://gov-glance-canada.nyc3.digitaloceanspaces.com/legislative-xml/committees/cacn.xml', 'committee3', 'Special Committee on the Canada–People’s Republic of China Relationship', 'Legislative', 
'N/A',SpecialCommitteeChinaRelation )}
                          
<Modal id='mobileFullBillModal' show={trendingBillsOptionFourteen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsOptionFourteenModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Special Committee on the Canada–People’s Republic of China Relationship</h3></Modal.Header>
<SpecialCommitteeChinaRelation/>
</Modal>
            </div>
          </Col>

  </div>

</Carousel.Item>


<Carousel.Item id='legislativeCarousel' interval={carouselInterval}>
              <div id='legislativeCon'>
   
          <Col id='legislativeColRight'>
            <div id='columnLefttPadding'>



            <h4 id='feedHeadingBillsEnrolled' onClick={() => setTrendingBillsOptionFifteenModalIsOpen(true)}><b>Standing Committee on International Trade </b></h4>
<div id='committee44'></div>

          {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
{CreateFeedItem('https://gov-glance-canada.nyc3.digitaloceanspaces.com/legislative-xml/committees/ciit.xml', 'committee44', 'Standing Committee on International Trade ', 'Legislative', 
'N/A',StandingCommitteeOnInternationalTrade )}
                          
<Modal id='mobileFullBillModal' show={trendingBillsOptionFifteen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsOptionFifteenModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Standing Committee on International Trade</h3></Modal.Header>
<SupremeCourtReports/>
</Modal>
            </div>
          </Col>

  </div>

</Carousel.Item>


<Carousel.Item interval={carouselInterval} >

<div id='legislativeCon'>



  <Col id='legislativeColRight'>
    <div id='columnLefttPadding'>
    


    <Button id='viewFullLegislativePageButton' size='lg' href='/canada/foreignaffairs' >View all Foreign Affairs news</Button>
 



    





    </div>
  </Col>
















</div>

</Carousel.Item>






 </Carousel>
  </Col>

            </Row>

            <Row id='topicsRow'>

              {/*Health Column */}
 <Col id='topicColumn'>

<Row id="topicPageBranchRow">

<h3 id='branchHeading' > <FileMedicalFill></FileMedicalFill> <b id='branchHeadingBold'>Health</b><ChevronRight id='clickChevron'></ChevronRight></h3>


  </Row>
  <Carousel  controls={false} >




<Carousel.Item interval={carouselInterval} >

<div id='legislativeCon'>



  <Col id='legislativeColRight'>
    <div id='columnLefttPadding'>
    


    <Button id='viewFullLegislativePageButton' size='lg'  >Coming Soon</Button>
 



    





    </div>
  </Col>
















</div>

</Carousel.Item>



 </Carousel>



  </Col>

  <Col id='topicColumn'>

<Row id="topicPageBranchRow">

<h3 id='branchHeading' > <ReceiptCutoff></ReceiptCutoff> <b id='branchHeadingBold'>Elections</b><ChevronRight id='clickChevron'></ChevronRight></h3>


  </Row>
  <Carousel id='healthCarousel' controls={false} >




  <Carousel.Item interval={carouselInterval} >

<div id='legislativeCon'>



  <Col id='legislativeColRight'>
    <div id='columnLefttPadding'>
    


    <Button id='viewFullLegislativePageButton' size='lg'  >Coming Soon</Button>
 



    





    </div>
  </Col>
















</div>

</Carousel.Item>



 </Carousel>

 




  </Col>
  <Col id='topicColumn'>

<Row id="topicPageBranchRow">

<h3 id='branchHeading' onClick={() => window.open('/canada/science&tech', "_self")}> <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-rocket-takeoff" viewBox="0 0 16 16">
  <path d="M9.752 6.193c.599.6 1.73.437 2.528-.362.798-.799.96-1.932.362-2.531-.599-.6-1.73-.438-2.528.361-.798.8-.96 1.933-.362 2.532Z"/>
  <path d="M15.811 3.312c-.363 1.534-1.334 3.626-3.64 6.218l-.24 2.408a2.56 2.56 0 0 1-.732 1.526L8.817 15.85a.51.51 0 0 1-.867-.434l.27-1.899c.04-.28-.013-.593-.131-.956a9.42 9.42 0 0 0-.249-.657l-.082-.202c-.815-.197-1.578-.662-2.191-1.277-.614-.615-1.079-1.379-1.275-2.195l-.203-.083a9.556 9.556 0 0 0-.655-.248c-.363-.119-.675-.172-.955-.132l-1.896.27A.51.51 0 0 1 .15 7.17l2.382-2.386c.41-.41.947-.67 1.524-.734h.006l2.4-.238C9.005 1.55 11.087.582 12.623.208c.89-.217 1.59-.232 2.08-.188.244.023.435.06.57.093.067.017.12.033.16.045.184.06.279.13.351.295l.029.073a3.475 3.475 0 0 1 .157.721c.055.485.051 1.178-.159 2.065Zm-4.828 7.475.04-.04-.107 1.081a1.536 1.536 0 0 1-.44.913l-1.298 1.3.054-.38c.072-.506-.034-.993-.172-1.418a8.548 8.548 0 0 0-.164-.45c.738-.065 1.462-.38 2.087-1.006ZM5.205 5c-.625.626-.94 1.351-1.004 2.09a8.497 8.497 0 0 0-.45-.164c-.424-.138-.91-.244-1.416-.172l-.38.054 1.3-1.3c.245-.246.566-.401.91-.44l1.08-.107-.04.039Zm9.406-3.961c-.38-.034-.967-.027-1.746.163-1.558.38-3.917 1.496-6.937 4.521-.62.62-.799 1.34-.687 2.051.107.676.483 1.362 1.048 1.928.564.565 1.25.941 1.924 1.049.71.112 1.429-.067 2.048-.688 3.079-3.083 4.192-5.444 4.556-6.987.183-.771.18-1.345.138-1.713a2.835 2.835 0 0 0-.045-.283 3.078 3.078 0 0 0-.3-.041Z"/>
  <path d="M7.009 12.139a7.632 7.632 0 0 1-1.804-1.352A7.568 7.568 0 0 1 3.794 8.86c-1.102.992-1.965 5.054-1.839 5.18.125.126 3.936-.896 5.054-1.902Z"/>
</svg> <b id='branchHeadingBold'>Science & Tech</b><ChevronRight id='clickChevron'></ChevronRight></h3>


  </Row>
  <Carousel id='healthCarousel' controls={false} >



<Carousel.Item id='legislativeCarousel' interval={carouselInterval}>
              <div id='legislativeCon'>
   
          <Col id='legislativeColRight'>
            <div id='columnLefttPadding'>


            
            <h4 id='feedHeadingBillsEnrolled' onClick={() => setGAOReportsModalIsOpen(true)}><b>Standing Committee on Industry and Technology </b></h4>
<div id='committee9'></div>

          {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
{CreateFeedItem('https://gov-glance-canada.nyc3.digitaloceanspaces.com/legislative-xml/committees/indu.xml', 'committee9', 'Standing Committee on Industry and Technology', 'Legislative', 
'N/A', StandingCommitteeOnIndustryAndTech)}
                          
<Modal id='mobileFullBillModal' show={trendingBillsOptionThree} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsOptionThreeModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Standing Committee on Industry and Technology</h3></Modal.Header>
<StandingCommitteeOnIndustryAndTech/>
</Modal>
            </div>
          </Col>

  </div>

</Carousel.Item>

<Carousel.Item id='legislativeCarousel' interval={carouselInterval}>
              <div id='legislativeCon'>
   
          <Col id='legislativeColRight'>
            <div id='columnLefttPadding'>



            <h4 id='feedHeadingBillsEnrolled' onClick={() => setTrendingBillsOptionTenModalIsOpen(true)}><b>Standing Committee on Environment and Sustainable Development </b></h4>
<div id='committee55'></div>

          {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
{CreateFeedItem('https://gov-glance-canada.nyc3.digitaloceanspaces.com/legislative-xml/committees/envi.xml', 'committee55', 'Standing Committee on Environment and Sustainable Development', 'Legislative', 
'N/A',StandingCommitteeOnEnvironment )}
                          

            </div>
          </Col>

  </div>

</Carousel.Item>

  <Carousel.Item interval={carouselInterval} >

<div id='legislativeCon'>



  <Col id='legislativeColRight'>
    <div id='columnLefttPadding'>
    


    <Button id='viewFullLegislativePageButton' size='lg' href='/canada/science&tech' >View all Science and Tech news</Button>
 



    





    </div>
  </Col>


</div>

</Carousel.Item>





 </Carousel>

 




  </Col>



       {/*Placeholder2 Column */}

              </Row>

              <Row id='topicsRow'>

{/*Health Column */}
<Col id='topicColumn'>

<Row id="topicPageBranchRow">

<h3 id='branchHeading' onClick={() => window.open('/canada/defense', "_self")}> <Bricks/> <b id='branchHeadingBold'> Defense</b><ChevronRight id='clickChevron'></ChevronRight></h3>

</Row>
<Carousel  controls={false} >


<Carousel.Item id='legislativeCarousel' interval={carouselInterval}>
              <div id='legislativeCon'>
   
          <Col id='legislativeColRight'>
            <div id='columnLefttPadding'>



            <h4 id='feedHeadingBillsEnrolled' onClick={() => setTrendingBillsOptionThirteenModalIsOpen(true)}><b>Standing Committee on National Defence </b></h4>
<div id='committee102'></div>

          {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
{CreateFeedItem('https://gov-glance-canada.nyc3.digitaloceanspaces.com/legislative-xml/committees/nddn.xml', 'committee102', 'Standing Committee on National Defence', 'Legislative', 
'N/A', StandingCommitteeOnNationalDefence)}
            </div>
          </Col>

  </div>

</Carousel.Item>

<Carousel.Item interval={carouselInterval} >

<div id='legislativeCon'>



  <Col id='legislativeColRight'>
    <div id='columnLefttPadding'>
    


    <Button id='viewFullLegislativePageButton' size='lg' href='/canada/defense' >View all Defense news</Button>
 



    





    </div>
  </Col>


</div>

</Carousel.Item>



</Carousel>



</Col>

<Col id='topicColumn'>

<Row id="topicPageBranchRow">


</Row>
<Carousel id='healthCarousel' controls={false} >


</Carousel>






</Col>
<Col id='topicColumn'>

  




<path d="M9.752 6.193c.599.6 1.73.437 2.528-.362.798-.799.96-1.932.362-2.531-.599-.6-1.73-.438-2.528.361-.798.8-.96 1.933-.362 2.532Z"/>
<path d="M15.811 3.312c-.363 1.534-1.334 3.626-3.64 6.218l-.24 2.408a2.56 2.56 0 0 1-.732 1.526L8.817 15.85a.51.51 0 0 1-.867-.434l.27-1.899c.04-.28-.013-.593-.131-.956a9.42 9.42 0 0 0-.249-.657l-.082-.202c-.815-.197-1.578-.662-2.191-1.277-.614-.615-1.079-1.379-1.275-2.195l-.203-.083a9.556 9.556 0 0 0-.655-.248c-.363-.119-.675-.172-.955-.132l-1.896.27A.51.51 0 0 1 .15 7.17l2.382-2.386c.41-.41.947-.67 1.524-.734h.006l2.4-.238C9.005 1.55 11.087.582 12.623.208c.89-.217 1.59-.232 2.08-.188.244.023.435.06.57.093.067.017.12.033.16.045.184.06.279.13.351.295l.029.073a3.475 3.475 0 0 1 .157.721c.055.485.051 1.178-.159 2.065Zm-4.828 7.475.04-.04-.107 1.081a1.536 1.536 0 0 1-.44.913l-1.298 1.3.054-.38c.072-.506-.034-.993-.172-1.418a8.548 8.548 0 0 0-.164-.45c.738-.065 1.462-.38 2.087-1.006ZM5.205 5c-.625.626-.94 1.351-1.004 2.09a8.497 8.497 0 0 0-.45-.164c-.424-.138-.91-.244-1.416-.172l-.38.054 1.3-1.3c.245-.246.566-.401.91-.44l1.08-.107-.04.039Zm9.406-3.961c-.38-.034-.967-.027-1.746.163-1.558.38-3.917 1.496-6.937 4.521-.62.62-.799 1.34-.687 2.051.107.676.483 1.362 1.048 1.928.564.565 1.25.941 1.924 1.049.71.112 1.429-.067 2.048-.688 3.079-3.083 4.192-5.444 4.556-6.987.183-.771.18-1.345.138-1.713a2.835 2.835 0 0 0-.045-.283 3.078 3.078 0 0 0-.3-.041Z"/>
<path d="M7.009 12.139a7.632 7.632 0 0 1-1.804-1.352A7.568 7.568 0 0 1 3.794 8.86c-1.102.992-1.965 5.054-1.839 5.18.125.126 3.936-.896 5.054-1.902Z"/>


<Carousel id='healthCarousel' controls={false} >
</Carousel>






</Col>



{/*Placeholder2 Column */}

</Row>


              <Row id="topicPageBranchRow">



  </Row>

  <Row id='branchesRow'>

{/*Health Column */}
<Col id='homeColumn'>
<Carousel id='healthCarousel' controls={false} >
</Carousel>
</Col>

<Col id='homeColumn'>
<Carousel id='healthCarousel' controls={false} >
</Carousel>
</Col>
<Col id='homeColumn'>




<Carousel id='healthCarousel' controls={false} >

</Carousel>






</Col>

                



{/*Placeholder2 Column */}

</Row>


<br></br>

<h5 id='branchHeadingPicker'><GeoAlt/>   Provinces <ChevronRight id='clickChevron'></ChevronRight></h5>
<div id='homebg'>  
                      <Container>
                      <Col>
                      <Row className="d-flex justify-content-center align-items-center">
                  <Button size='sm' id='statesModalButtons'  onClick={()  => {
                        
                        window.open('/canada/alberta', '_self')
                      }}> <Image id='stateFlagMobile'  src='/albertaFlag.png'></Image>Alberta</Button>
                        <Button size='sm' id='statesModalButtons'  onClick={()  => {
                        
                        window.open('/canada/britishcolumbia', '_self')
                      }}><Image id='stateFlagMobile'  src='/britishColumbiaFlag.png'></Image>British Columbia</Button>
                       <Button size='sm' id='statesModalButtons'  onClick={()  => {
                        
                        window.open('/canada/manitoba', '_self')
                      }}> <Image id='stateFlagMobile'  src='/manitobaFlag.png'></Image>Manitoba</Button>
                      </Row>
                      <Row className="d-flex justify-content-center align-items-center">
                          <Button size='sm' id='statesModalButtons'  onClick={()  => {
                        
                        window.open('/canada/newbrunswick', '_self')
                      }}> <Image id='stateFlagMobile'  src='/newBrunswickFlag.png'></Image>New Brunswick</Button>
                     
                     <Button size='sm' id='statesModalButtons'  onClick={()  => {
                        
                        window.open('/canada/newfoundland&labrador', '_self')
                      }}> <Image id='stateFlagMobile'  src='/newfoundlandAndLabradorFlag.png'></Image>Newfoundland and Labrador</Button>
                     
                     <Button size='sm' id='statesModalButtons'  onClick={()  => {
                        
                        window.open('/canada/northwestterritories', '_self')
                      }}> <Image id='stateFlagMobile'  src='/northwestTerritoriesFlag.png'></Image>Northwest Territories</Button>
                     </Row>
                     <Row className="d-flex justify-content-center align-items-center">
                     <Button size='sm' id='statesModalButtons'  onClick={()  => {
                        
                        window.open('/canada/novascotia', '_self')
                      }}> <Image id='stateFlagMobile'  src='/novaScotiaFlag.png'></Image>Nova Scotia</Button>
                      <Button size='sm' id='statesModalButtons'  onClick={()  => {
                        
                        window.open('/canada/nunavut', '_self')
                      }}> <Image id='stateFlagMobile'  src='/nunavutFlag.png'></Image>Nunavut</Button>
                       <Button size='sm' id='statesModalButtons'  onClick={()  => {
                        
                        window.open('/canada/ontario', '_self')
                      }}> <Image id='stateFlagMobile'  src='/ontarioFlag.png'></Image>Ontario</Button>
                      </Row>
                      <Row className="d-flex justify-content-center align-items-center">
                       <Button size='sm' id='statesModalButtons'  onClick={()  => {
                        
                        window.open('/canada/princeedwardisland', '_self')
                      }}> <Image id='stateFlagMobile'  src='/princeEdwardIslandFlag.png'></Image>Prince Edward Island</Button>
                       <Button size='sm' id='statesModalButtons'  onClick={()  => {
                        
                        window.open('/canada/quebec', '_self')
                      }}> <Image id='stateFlagMobile'  src='/quebecFlag.png'></Image>Quebec</Button>
                      </Row>
                      <Row className="d-flex justify-content-center align-items-center">
                       <Button size='sm' id='statesModalButtons'  onClick={()  => {
                        
                        window.open('/canada/saskatchewan', '_self')
                      }}> <Image id='stateFlagMobile'  src='/saskatchewanFlag.png'></Image>Saskatchewan</Button>
                   
                       <Button size='sm' id='statesModalButtons'  onClick={()  => {
                        
                        window.open('/canada/yukon', '_self')
                      }}> <Image id='stateFlagMobile'  src='/yukonFlag.png'></Image>Yukon</Button>
                      </Row>
           
                     
                     
                     
                   
                     </Col>
                     </Container>
                     </div>

<br></br>
  
  <h5 id='branchHeadingPicker'><GeoAlt/>   Change country <ChevronRight id='clickChevron'></ChevronRight></h5>

<Col>
<Button id='selectHomeCountryDesktop'   onClick={() => window.open('/canada', "_self")}>
        🇨🇦 Canada
      </Button>

      <Button id='selectHomeCountryDesktop'   onClick={() => window.open('/mexico', "_self")}>
      🇲🇽 Mexico
      </Button>

      <Button id='selectHomeCountryDesktop' onClick={() => window.open('/uk', "_self")}>
        🇬🇧 United Kingdom
      </Button>

      <Button id='selectHomeCountryDesktop' onClick={() => window.open('/us', "_self")}>
        🇺🇸 United States
      </Button>
      </Col>

                     <br></br>
   
      </div>
      </>
    );
  }





  
  
  

  

  
  //onClick={linkToBill()}
  return (
    
    <>
    
      <div className="App">
   
    




      <BrowserView>
      <div id='homePage'>
        


        <div id='homebg'>

     

          

         <CanadaDesktopNavbar/>
       

            <BranchesTab />
            

            {/*onClick={() => } */}
           
           
        
   
         


          
            
              
             
              
            
          
            
            
          <Modal id='aboutModal' show={aboutModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setAboutModalIsOpen(false)}>
            <div id='removedAlert'></div>
            <Modal.Header id='aboutModalHeader' closeButton><h3>About</h3></Modal.Header>
  <ModalBody id='aboutInfo'><p id='aboutParagraph'>Gov Glance was created to drive civic engagement and support a 
              healthier democracy by helping citizens get official Government news in an easy-to-navigate centralized location. 
              There will never be any opinion or political analysis(That's your job), only information from official Government 
              sources. Gov Glance is structured after the U.S. government that is broken into three branches Legislative, 
              Executive, and Judicial. To learn more about the structure and functions of The U.S. Government go to <a href='https://www.usa.gov/branches-of-government'>https://www.usa.gov/branches-of-government</a>.
              </p>

              <p id='aboutParagraph'>
              Gov Glance Foundation Inc. is a non-profit that was created to operate govglance.org and continue to create educational tools that help people learn about the workings of society
              </p>
            <br></br>

            <h3>Privacy</h3>

            <br></br>

              <p id='aboutParagraph'>Gov Glance does not collect any personal user information. 
              Features like collection save news items directly to your browser's local storage for your convenience
              and is not shared with Gov Glance servers. We do not collect, store, or send personal information to Google LLC, 
              but we do use some Google services to deliver our app to you. For any questions into Google’s privacy practices 
              here is a link to their privacy policy: <a href='https://policies.google.com/privacy?hl=en-US#intro'>https://policies.google.com/privacy?hl=en-US#intro</a>

              <br></br><br></br>

              For site analytics we use plausible.io an open source privacy friendly analytics provider. <a href="https://plausible.io/about" target="_blank">https://plausible.io/about</a>
            
            </p>
            <br></br>


            <h3>Contact</h3>

            <p id='aboutParagraph'>
                      Contact us via email: <a href='mailto:contact@govglance.org'>contact@govglance.org</a>

            </p>

            <br></br>


            
            </ModalBody>
          </Modal>
 
     <footer id='homeFooter'>
      <Navbar>
        <Row id='footerNav'>
      <Nav variant='pills'>
        
     <Nav.Link id='footerContent'>Gov Glance Foundation Inc.
</Nav.Link>
<Nav.Item>
<Nav.Link id='footerContent' href='mailto:contact@govglance.org'>Contact</Nav.Link>
</Nav.Item>
<Nav.Item>
<Nav.Link id='footerContent' onClick={(e) => {
                   e.preventDefault();
                   window.open('https://donate.stripe.com/28o3fXbdvbQO2RO288', "_blank")
                 }}> <HeartFill id='donateIcon'/>    Donate</Nav.Link>
</Nav.Item>
<Nav.Link onClick={(e) => {
                   e.preventDefault();
                   window.open('/about', "_self")}} id='footerContent'>Privacy</Nav.Link>
    
     
 </Nav>
 </Row>
 </Navbar>
      </footer>

      


    
          </div>
  

    </div>
      </BrowserView>
     

      <MobileView> 
        
        
                      <CanadaHomeMobile/>

     
      </MobileView>
        </div>
      


       
    </>

     
    
  );







}
}


export default CanadaHome;
