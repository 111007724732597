import App from "../../App";
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Image, ModalFooter } from 'react-bootstrap';
import { ArrowsAngleExpand, InfoCircle, FileText, PauseCircle, PlayCircle, FolderPlus, ChevronRight, Files, Folder, Pen, ChatLeftQuote, ShieldShaded } from 'react-bootstrap-icons'
import '../../App.css';
import 'bootstrap/dist/css/bootstrap.min.css'

import Axios from 'axios'
import { render } from '@testing-library/react';

import{Speak, CreateFeedItem,
CreateFeedItemCanada} from '../../Functions'
import CanadaMobileTopNavbar from "../CanadaMobileTopNavbar";
import JudicialAppointmentsCanada from "./JudicialAppointmentsCanada";
import SupremeCourtBullitins from "./SupremeCourtBullitins";
import SupremeCourtLeave from "./SupremeCourtLeave";
import SupremeCourtReports from "./SupremeCourtReports";
import SupremeCourtJudgements from "./SupremeCourtJudgements";
import SupremeCourtNews from "./SupremeCourtNews";
import MobileOffCanvasCanada from "../../MobileOffCanvasCanada";

var collectionCounter = 0
var carouselInterval = 30000;
var mobileCarouselInterval = 60000;

export const CanadaJudicialBranchMobile = () =>   {
    render()
    
    {
        const [modalIsOpen, setModalIsOpen] = useState(false)
        const [fullBillModalIsOpen, setFullBillModalIsOpen] = useState(false)
          const [trendingBillsOptionOne, setTrendingBillsOptionOneModalIsOpen ] = useState(false)
          const [trendingBillsOptionTwo, setTrendingBillsOptionTwoModalIsOpen ] = useState(false)
          const [trendingBillsOptionThree, setTrendingBillsOptionThreeModalIsOpen ] = useState(false)
          const [trendingBillsOptionFour, setTrendingBillsOptionFourModalIsOpen ] = useState(false)
          const [trendingBillsOptionFive, setTrendingBillsOptionFiveModalIsOpen ] = useState(false)
       
        localStorage.setItem('currentPage', 'canada')
    return(
       <>
    <CanadaMobileTopNavbar/>
  <div id='homebg'>
{/*Legislative Mobile Carousel*/}
<h5 id='branchHeadingMobile'> <ShieldShaded></ShieldShaded> Judicial </h5>
{/*onClick={() => setModalIsOpen(true)}*/}
<Modal show={modalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setModalIsOpen(false)}>
<Modal.Header id='iframeModalHeader' closeButton>Congressional Record</Modal.Header>
<Card id='iframeColMobile'>
  <iframe src='https://www.congress.gov/congressional-record' height='1000px' width='410px' frameBorder='0'><div id='modalFeedMobile'></div></iframe></Card></Modal>
<Col>
  <h5 id='presidentialFeedHeadingMobile' onClick={() => setTrendingBillsOptionOneModalIsOpen(true)}>Supreme Court News</h5>

  <div id='courtNews'></div>
              
              {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
{CreateFeedItemCanada('https://decisions.scc-csc.ca/scc-csc/news/en/rss.do', 'courtNews', 'Supreme Court News', 'Legislative', 'N/A', SupremeCourtNews)}


  <Modal id='mobileFullBillModal' show={trendingBillsOptionOne} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsOptionOneModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Supreme Court News</h3></Modal.Header>
<SupremeCourtNews/>
</Modal>
</Col>

<Col>
  <h5 id='presidentialFeedHeadingMobile' onClick={() => setFullBillModalIsOpen(true)}>Judicial Appointments</h5>

  <div id='judicialApts'></div>
              
              {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
{CreateFeedItem('https://gov-glance-canada.nyc3.digitaloceanspaces.com/judicial-xml/judicial_appointments.xml', 'judicialApts', 'Judicial Appointments', 'Legislative', 'N/A', JudicialAppointmentsCanada)}

  <Modal id='mobileFullBillModal' show={fullBillModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setFullBillModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Judicial Appointments</h3></Modal.Header>
<JudicialAppointmentsCanada/>
</Modal>
</Col>

<Col>
  <h5 id='presidentialFeedHeadingMobile' onClick={() => setTrendingBillsOptionTwoModalIsOpen(true)}>Supreme Court Judgements</h5>    
  <div id='courtJudgements'></div>
              
              {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
{CreateFeedItemCanada('https://decisions.scc-csc.ca/scc-csc/scc-csc/en/rss.do', 'courtJudgements', 'Supreme Court Judgements', 'Legislative', 'N/A', SupremeCourtJudgements)}

  <Modal id='mobileFullBillModal' show={trendingBillsOptionTwo} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsOptionTwoModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Supreme Court Judgements</h3></Modal.Header>
<SupremeCourtJudgements/>
</Modal>
</Col>

<Col>
  <h5 id='presidentialFeedHeadingMobile' onClick={() => setTrendingBillsOptionThreeModalIsOpen(true)}>Supreme Court Reports</h5>

  <div id='courtReports'></div>
              
              {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
{CreateFeedItemCanada('https://decisions.scc-csc.ca/scc-csc/scr/en/rss.do', 'courtReports', 'Supreme Court Reports', 'Legislative', 'N/A', SupremeCourtReports)}

  <Modal id='mobileFullBillModal' show={trendingBillsOptionThree} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsOptionThreeModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Supreme Court Reports</h3></Modal.Header>
<SupremeCourtReports/>
</Modal>
</Col>

<Col>
  <h5 id='presidentialFeedHeadingMobile' onClick={() => setTrendingBillsOptionFourModalIsOpen(true)}>Supreme Court Bullitins</h5>

  <div id='courtBullitins'></div>
              
              {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
{CreateFeedItemCanada('https://decisions.scc-csc.ca/scc-csc/bulletins/en/rss.do', 'courtBullitins', 'Supreme Court Bullitins', 'Legislative', 'N/A', SupremeCourtBullitins)}

  <Modal id='mobileFullBillModal' show={trendingBillsOptionFour} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsOptionFourModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Supreme Court Bullitins</h3></Modal.Header>
<SupremeCourtBullitins/>
</Modal>
</Col>

<Col>
  <h5 id='presidentialFeedHeadingMobile' onClick={() => setTrendingBillsOptionFiveModalIsOpen(true)}>Supreme Court Leave</h5>
  <div id='courtLeave'></div>
              
              {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
{CreateFeedItemCanada('https://decisions.scc-csc.ca/scc-csc/scc-l-csc-a/en/rss.do', 'courtLeave', 'Supreme Court Leave', 'Legislative', 'N/A', SupremeCourtLeave)}

  <Modal id='mobileFullBillModal' show={trendingBillsOptionFive} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsOptionFiveModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Supreme Court Leave</h3></Modal.Header>
<SupremeCourtLeave/>
</Modal>
</Col>
    
<Navbar id='navbarMobileBottom' fixed="bottom">      
<div id='changeBranchDropdownMobile'>
<MobileOffCanvasCanada/>

              
            </div>

</Navbar>
</div>
</>
)
}}
export default CanadaJudicialBranchMobile;


