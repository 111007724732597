import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link, Switch,  } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment,  } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Tooltip, OverlayTrigger, Image, ModalFooter } from 'react-bootstrap';
import { ArrowsAngleExpand, ArrowsFullscreen, InfoCircle, FileText,  ShieldShaded, CircleFill, Pen, People, ChatSquareQuote, ChatLeftQuote, BlockquoteRight, Collection, Grid, ThreeDots, Stack, List, Files, FolderPlus, FolderCheck, Folder, CaretRight, ChevronRight, ChevronCompactRight, CaretRightFill, ArrowRightShort,  ArrowRightCircle, PlayCircle, PauseCircle, Link45deg } from 'react-bootstrap-icons'
import { BrowserView, MobileView } from 'react-device-detect';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import Axios from 'axios'
import { render } from '@testing-library/react';

import DepartmentOfTheInterior from './DepartmentOfTheInterior';

import{ whiteHouseSearch, createPresidentialProclamations, createExecutiveOrders, createWhiteHouseDocuments, createWhiteHouseFeed,
    createWhiteHouseFeedMobile,createDepartmentOfState,
    createDepartmentOfDefense, 
    createDepartmentOfJustice, createDepartmentOfTheInterior,
    createDepartmentOfAgriculture, createDepartmentOfCommerce,
    createDepartmentOfCommerceMobile, createDepartmentOfLabor,
    createDepartmentOfHealthAndHumanServices, createDepartmentOfHousingAndUrbanDevelopment, createDepartmentOfEducation
    ,  createDepartmentOfVeteransAffairs, createDepartmentOfHomelandSecurity
    , createEnvironmentalProtectionAgency,
    createDepartmentOfTreasury, createNationalScienceFoundation, 
     createEnvironmentalProtectionAgencyMobile, createNationalAeronauticsAndSpaceAdministration, Speak, createFederalRegisterDefenseDepartment, 
     createFederalRegisterStateDepartment, createFederalRegisterDefense,
    CreateFeedItem, CreateFeedItemPresidentialDocuments, CreateExecutiveOrders,
    CreateFeedItemExecutiveOrders,
    CreateFeedItemBillUpdates,
    CreateFeedItemExecutiveOffice,
    CreateFeedItemBillWithDescription,
    CreateFeedAPIDepartmentUS,  } from './Functions'

import MobileOffCanvas from './MobileOffCanvas';
import MobileTopNavbar from './MobileTopNavbar';

import DepartmentOfTheInteriorDocs from './DepartmentOfTheInteriorDocs';
import FishingAndWildlifeService from './FishingAndWildlifeService';
import GeologicalSurvey from './GeologicalSurvey';
import InteriorDeptHearings from './InteriorDeptHearings';
import IndianAffairsBureau from './IndianAffairsBureau';
import LandManagementBureau from './LandManagementBureau';
import NationalIndianGamingCommission from './NationalIndianGamingCommission';
import NationalParkService from './NationalParkService';
import ReclamationBureau from './ReclamationBureau';
import SpecialTrusteeForAmericanIndians from './SpecialTrusteeForAmericanIndians';
import SurfaceMiningReclamation from './SurfaceMiningReclamation';
import OceanEnergyManagmentBureau from './OceanEnergyManagmentBureau';
import SafetyAndEnvironmentEnforcement from './SafetyAndEnvironmentEnforcement';
import NaturalResourcesRevenueOffice from './NaturalResourcesRevenueOffice';
import GenerateFeedDepartment from './GenerateFeedDepartment';



var collectionCounter = 0
var carouselInterval = 30000;
var mobileCarouselInterval = 30000;



  

 


  

export const InteriorMobile = () =>   {
    render()
    
    {
        const [show, setShow] = useState(true);

        
        const [fullscreen, setFullScreen] = useState(true);
      
      
        const [fullBillModalIsOpen, setFullBillModalIsOpen] = useState(false)
      
        const [reportModalIsOpen, setReportModalIsOpen] = useState(false)
      
      
        const [whiteHouseFeedModalIsOpen, setWhiteHouseFeedModalIsOpen] = useState(false)
        const [whiteHouseDocsModalIsOpen, setWhiteHouseDocsModalIsOpen] = useState(false)
      
      
        const [courtNewsModalIsOpen, setCourtNewsModalIsOpen] = useState(false)
        const [courtModalIsOpen, setCourtModalIsOpen] = useState(false)
      
        const [courtAppealsModalIsOpen, setCourtAppealsModalIsOpen] = useState(false)
      
        const [newLawsModalIsOpen, setNewLawsModalIsOpen] = useState(false)
      
        const [billsEnrolledModalIsOpen, setBillsEnrolledModalIsOpen] = useState(false)
      
        const [legislativePageBillsEnrolledModalIsOpen, setLegislativePageBillsEnrolledModalIsOpen] = useState(false)
      
      
        const [departmentOfStateModalIsOpen, setDepartmentOfStateModalIsOpen] = useState(false)
        const [departmentOfTreasuryModalIsOpen, setDepartmentOfTreasuryModalIsOpen] = useState(false)
     
        const [departmentOfJusticeModalIsOpen, setDepartmentOfJusticeModalIsOpen] = useState(false)
        const [departmentOfTheInteriorModalIsOpen, setDepartmentOfTheInteriorModalIsOpen] = useState(false)
        const [departmentOfAgricultureModalIsOpen, setDepartmentOfAgricultureModalIsOpen] = useState(false)
        const [departmentOfCommerceModalIsOpen, setDepartmentOfCommerceModalIsOpen] = useState(false)
        const [departmentOfLaborModalIsOpen, setDepartmentOfLaborModalIsOpen] = useState(false)
        const [departmentOfHealthAndHumanServicesModalIsOpen, setDepartmentOfHealthAndHumanServicesModalIsOpen] = useState(false)
        const [departmentOfHousingAndUrbanDevelopmentModalIsOpen, setDepartmentOfHousingAndUrbanDevelopmentModalIsOpen] = useState(false)
        const [departmentOfTransportationModalIsOpen, setDepartmentOfTransportationModalIsOpen] = useState(false)
        const [departmentOfEnergyModalIsOpen, setDepartmentOfEnergyModalIsOpen] = useState(false)
        const [departmentOfEducationModalIsOpen, setDepartmentOfEducationModalIsOpen] = useState(false)
        const [departmentOfVeteransAffairsModalIsOpen, setDepartmentOfVeteransAffairsModalIsOpen] = useState(false)
        const [departmentOfHomelandSecurityModalIsOpen, setDepartmentOfHomelandSecurityModalIsOpen] = useState(false)
        const [epaModalIsOpen, setEPAModalIsOpen] = useState(false)
        const [nsfModalIsOpen, setNSFModalIsOpen] = useState(false)
        const [nasaModalIsOpen, setNASAModalIsOpen] = useState(false)
        const [trendingBillsModalIsOpen, setTrendingBillsModalIsOpen] = useState(false)
        const [goaReportsModalIsOpen, setGAOReportsModalIsOpen] = useState(false)
        const [executiveOrdersModalIsOpen, setExecutiveOrdersModalIsOpen] = useState(false)
        const [presidentialProclamationsModalIsOpen, setPresidentialProclamationsModalIsOpen] = useState(false)
        const [statesModalIsOpen, setStatesModalIsOpen] = useState(false)
      
      
        const [collectionsModalIsOpen, setCollectionsModalIsOpen] = useState(false)
        const [aboutModalIsOpen, setAboutModalIsOpen] = useState(false)
      
      
        const [federalRegisterDefenseDepartmentModalIsOpen, setFederalRegisterDefenseDepartmentModalIsOpen] = useState(false)
  const [federalRegisterStateDepartmentModalIsOpen, setFederalRegisterStateDepartmentModalIsOpen] = useState(false)
        const [declarationModalIsOpen, setDeclarationModalIsOpen] = useState(false)
        const [constitutionModalIsOpen, setConstitutionModalIsOpen] = useState(false)
        const [billOfRightsnModalIsOpen, setBillOfRightsModalIsOpen] = useState(false)

        const [economicAdModalIsOpen, setEconomicAdModalIsOpen] = useState(false)
        const [environmentQualityModalIsOpen, setEnvironmentQualityModalIsOpen] = useState(false)
        const [domesticPolicyModalIsOpen, setDomesticPolicyModalIsOpen] = useState(false)
        const [genderPolicyModalIsOpen, setGenderPolicyModalIsOpen] = useState(false)
        const [nationalEconomicModalIsOpen, setNationalEconomicModalIsOpen] = useState(false)
        const [securityModalIsOpen, setSecurityModalIsOpen] = useState(false)
        const [budgetModalIsOpen, setBudgetModalIsOpen] = useState(false)
        const [drugModalIsOpen, setDrugModalIsOpen] = useState(false)
        const [publicModalIsOpen, setPublicModalIsOpen] = useState(false)
        const [scienceModalIsOpen, setScienceModalIsOpen] = useState(false)
        const [cyberModalIsOpen, setCyberModalIsOpen] = useState(false)
        const [presidentialModalIsOpen, setPresidentialModalIsOpen] = useState(false)

        const [departmentOfDefenseModalIsOpen, setDepartmentOfDefenseModalIsOpen] = useState(false)
        const [defenseDocumentsModalIsOpen, setDefenseDocumentsModalIsOpen] = useState(false)
        const [armyModalIsOpen, setArmyModalIsOpen] = useState(false)
        const [navyModalIsOpen, setNavyModalIsOpen] = useState(false)
        const [marinesModalIsOpen, setMarinesModalIsOpen] = useState(false)
        const [spaceForceModalIsOpen, setSpaceForceModalIsOpen] = useState(false)
        const [airForceModalIsOpen, setAirForceModalIsOpen] = useState(false)
        const [nationalGuardModalIsOpen, setNationalGuardModalIsOpen] = useState(false)
        const [ciaModalIsOpen, setCIAModalIsOpen] = useState(false)
        const [nsaModalIsOpen, setNSAModalIsOpen] = useState(false)
        const [armyCorpsModalIsOpen, setArmyCorpsModalIsOpen] = useState(false)
        const [showBioModal, setBioModal] = useState(false);
        const [modalInfo, setModalInfo] = useState({
          isOpen: false,
          title: '',
          content: null
        });
      
        const openModal = (title, content) => {
          setModalInfo({
            isOpen: true,
            title,
            content
          });
        };
      
        const closeModal = () => {
          setModalInfo({
            isOpen: false,
            title: '',
            content: null
          });
        };

        var mobileCarouselInterval = 60000;
        const CustomModal = ({ isOpen, onClose, title, content }) => (
          <Modal id='fullBillModal' show={isOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={onClose}>
            <Modal.Header id='billModalHeader' closeButton><h3>{title}</h3></Modal.Header>
            {content}
          </Modal>
        );
    var branch = "all";
  
    return(
       <>
   <MobileTopNavbar/>
  
  
  
  
  <div id='homebg'>

  <CustomModal isOpen={modalInfo.isOpen} onClose={closeModal} title={modalInfo.title} content={modalInfo.content} />     
  <div id="feedDisplayDepartment" style={{ padding: '10px', borderRadius: '18px' }}>
  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div id="profileImageOnPageDepartment">
                <Image id="profileImage-imageOnPageDepartment" src="/departmentOfInterior.png" />
              </div>
              <div style={{ marginLeft: '10px', color: 'white', fontSize: '18px', fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
                <h5 id="branchHeadingMobile" > Department of the Interior</h5>
              </div>
            </div>
            <Dropdown drop="down-centered">
              <Dropdown.Toggle id="searchCountry"><Link45deg /></Dropdown.Toggle>
              <Dropdown.Menu id="docsDropdown" drop="down-centered">
                <Dropdown.Item id="docsDropdownItem" onClick={(e) => { e.preventDefault(); window.open("https://www.doi.gov/", "_blank") }}>
                  Official Site
                </Dropdown.Item>

                <Dropdown.Item id="docsDropdownItem" onClick={(e) => { e.preventDefault(); window.open("https://www.govinfo.gov/content/pkg/STATUTE-9/pdf/STATUTE-9-Pg395.pdf", "_blank") }}>
                  Establishing Act
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div id="billText" style={{ color: 'white', fontSize: '16px', marginTop: '10px' }}>
          
          The Department of the Interior (DOI) is the nation’s principal conservation agency. Its mission is to protect America’s natural resources, offer recreation opportunities, conduct scientific research, conserve and protect fish and wildlife, and honor the U.S. government’s responsibilities to American Indians, Alaskan Natives, and island communities.
          </div>

          <div style={{height: '20px',}}></div>
          {/* <div id="billText" style={{ color: 'white', fontSize: '16px', marginTop: '10px' }}>
          <b>Establishing Act</b>
          </div>
          <div id="billText" style={{ color: 'white', fontSize: '16px', marginTop: '10px' }}>
         
          Be it enacted by the Senate and House of Representatives of the
United States ofAmerica in Congress assembled, That, from and after
the passage of this act, there shall be cre...
          </div>
<Button id='presidentViewMoreButton' onClick={() =>  setBioModal(true)}>  <Files size={20}></Files> Read More</Button>
        </div>
  
            
        <Modal id='fullBillModal'  size="xl" aria-labelledby="contained-modal-title-vcenter" show={showBioModal} onHide={() => setBioModal(false)}>
         <Modal.Header id='billModalHeader' closeButton><h3> Establishing Act</h3></Modal.Header>
       
         <div id='fullBillFeed' >
      
         <p>Section 1. Be it enacted by the Senate and House of Representatives of the
United States ofAmerica in Congress assembled, That, from and after
the passage of this act, there shall be created a new executive depart- ment of the government of the United States, to be called the Department of the Interior; the head of which department shall be called the
Secretary of the Interior, who shall be appointed by the President of
the United States, by and with the advice and consent of the Senate,
and who shall hold his office by the same tenure, and receive the same
salary, as the Secretaries of the other executive departments, and who
shall perform all the duties assigned to him by this act.</p>

<p>Sec. 2. And be it further enacted, That the Secretary of the Interior
shall exercise and perform all the acts of supervision and appeal in
regard to the office of Commissioner of Patents, now exercised by the
Secretary of State; and the said Secretary of the Interior shall sign all
requisitions for the advance or payment of money out of the treasury
on estimates or accounts, subject to the same adjustment or contro
now exercised on similar estimates or accounts by the First or Fifth
Auditor and First Comptroller of the Treasury.</p>

<p>Sec. 3. And be it further enacted, That the Secretary of the Interior
shall perform all the duties in relation to the General Land Office, of
supervision and appeal, now discharged by the Secretary of the Treas- ury ; and the said Secretary of the Interior shall sign all requisitions
for the advance or payment of money out of the treasury, on estimates
or accounts, approved or certified by the Commissioner of the General
Land Office, subject to the same control now exercised by the First
Comptroller of the Treasury.</p>


<p>Sec. 4. And be it further enacted, That the supervisory power now
exercised by the Secretary of the Treasury over the accounts of the
marshals, clerks, and other officers of all the courts of the United States,
shall be exercised by the Secretary of the Interior, who shall sign all
requisitions for the advance or payment of money out of the treasury,
on estimates or accounts, subject to the same control now exercised on
like estimates or accounts by the First Auditor and First Comptroller
of the Treasury.</p>

<p>Sec. 5. And be itfurther enacted, That the Secretary of the Interior
shall exercise the supervisory and appellate powers now exercised by
the Secretary of the War Department, in relation to all the acts of the
Commissioner of Indian Affairs; and shall sign all requisitions for the
advance or payment of money out of the treasury, on estimates or
accounts, subject to the same adjustment or control now exercised on
similar estimates or accounts by the Second Auditor and Second Comp- troller of the Treasury.</p>

<p>Sec. 6. And be it further enacted, That the Secretary of the Interior
shall exercise the supervisory and appellate powers now exercised by the
Secretaries of the War and Navy Departments, in relation to all the acts
of the Commissioner of Pensions; and shall sign all requisitions for the
advance or payment of money out of the treasury, on estimates or
accounts, subject to the same adjustment or control now exercised on
similar estimates or accounts by the Third or Fourth Auditors and
Second Comptroller of the Treasury</p>

<p>Sec. 7. And be it further enacted, That the Secretary of the Interior
shall exercise all the supervisory and appellate powers now exercised
by the Secretary of State, in relation to all acts of marshals and others
in taking and returning the census of the United States; and shall
sign all requisitions for the advance or payment of money out of the
treasury, on estimates or accounts, subject to the same adjustment or control now exercised over similar estimates and accounts by the Fifth
Auditor and First Comptroller of the Treasury. </p>

<p>
Sec. 8. And be it further enacted, That the supervisory and appel- late powers now exercised by the Secretary of the Treasury over the
lead and other mines of the United States, and over the accounts of
the agents thereof, shall be exercised by the Secretary of the Interior;
who shall sign all requisitions for the advance or payment of money
out of the treasury, on estimates or accounts, subject to the same
adjustment or control now exercised on similar estimates or accounts
by the Second Auditor and Second Comptroller of the Treasury.
</p>

<p>
Sec. 9. And be it further enacted, That the supervisory and appel- late powers now exercised by the President of the United States over
the Commissioner of Public Buildings, shall be exercised by the Secre- tary of the Interior; who shall sign all requisitions for the advance or
payment of money out of the treasury, on estimates or accounts, subject
to the same adjustment or control now exercised on similar estimates
or accounts by the First Auditor and First Comptroller of the Treas- ury : Provided, That nothing in this section contained shall be construed to take from the presiding officers of the two Houses of Congress
the power now possessed by them to make and enforce rules and
regulations for the care, preservation, orderly keeping, and police
of the Capitol, and its appurtenances.
</p>

<p>
Sec. 10. And be itfurther enacted, That the Secretary of the In- terior shall have and exercise a supervisory power and control over
the Board of Inspectors and warden of the Penitentiary of the District
of Columbia; and shall sign all requisitions for the advance or payment
of money out of the treasury on estimates or accounts, subject to the
same adjustment or control now exercised on similar estimates or
accounts by the First Auditor and First Comptroller of the Treasury.
</p>
<p>
Sec. 11. And be it further enacted, That the Secretary of the
Interior is hereby authorized to appoint a chief clerk of his department,
who shall receive a salary of two thousand dollars per annum; and that
the President of the United States, on the recommendation of the said
Secretary of the Interior, may transfer from the Treasury Department
proper, to the Department of the Interior, such clerks in the office of
the Secretary of the Treasury as perform the duties over which the
supervision and control are given by this act to the Secretary of the Interior; which said clerks shall be hereafter subject to the appointing and
removing power of the Secretary of the Interior, as also the clerks in
the several bureaus heretofore appointed or removable by the heads of
departments, which bureaus are transferred by this act to the Depart- ment of the Interior.
</p>
<p>
Sec. 12. And be it further enacted, That an officer shall be ap- pointed by the President of the United States, by and with the advice
and consent of the Senate, in the Department of the Treasury, as one
of its bureaus, to be called the Commissioner of Customs, who shall
perform all the acts and exercise all the powers, now devolved by law
on the First Comptroller of the Treasury, relating to the receipts from
customs and the accounts of collectors and other officers of the customs, or connected therewith; who shall hold his office by the same
tenure, and receive the same amount of salary, as the First Auditor of
the Treasury, and payable in the same manner. And the Secretary
of the Treasury shall transfer from the office of the First Comptroller
such clerks as may be necessary to the bureau of the Commissioner of
Customs, for whom the said Secretary of the Treasury shall also
appoint one chief clerk, at a salary of seventeen hundred dollars per
annum.
</p>
<p>
Sec . 13. And be itfurther enacted, That an officer shall be appointed
in the Treasury Department by the Secretary of the Treasury, to becalled the Assistant Secretary of the Treasury, whose salary shall be
three thousand dollars per annum, payable in the same manner as that
of the Secretary of the Treasury, who shall examine all letters, contracts, and warrants, prepared for the signature of the Secretary of the
Treasury, and who shall perform all such other duties in the office of
the Secretary of the Treasury, now performed by some of his clerks,
as may be devolved on him by the Secretary of the Treasury; who
shall also appoint a clerk at a salary of seventeen hundred dollars per
annum, who shall perform such duties as a clerk in the Treasury De- partment, in aid of said Assistant Secretary, as may be assigned to him
by the Secretary of the Treasury.
</p>
<p>
y the Secretary of the Treasury.
Sec. 14. And be itfurther enacted, That the Secretary of the Treas- ury shall transfer from the office of the First Comptroller one of his
messengers, to perform the same duties in the office of the Commis- sioner of Customs, as also such portion of the contingent fund of the
office of the First Comptroller as may be required in that of the Com- missioner of Customs, in consequence of the transfer of clerks from
one office to another, or the transfer of a messenger from that office
to another. And the Secretary of the Treasury shall transfer one of
his messengers to the office of the Secretary of the Interior, as also
such portion of the contingent fund of the office of the Secretary of
the Treasury as may be required in the office of the Secretary of the
Interior, in consequence of the transfer of clerks from one department
to the other.
</p>
<p>
Sec. 15. And be itfurther enacted, That nothing in this act contained shall be so construed as to affect or impair any of the powers
conferred, or duties devolved, on the Secretary of the Treasury, in
relation to the transfer, safe-keeping, or disbursement of public moneys,
by the act of the sixth of August, one thousand eight hundred and
forty-six, entitled “ An Act to provide for the better organization of the
treasury, and for the collection, safe-keeping, transfer, and disbursement of the public revenue.”
Appro ved , March 3, 1849.
</p>

<Button id='viewBillButton' onClick={(e) => { e.preventDefault(); window.open("https://www.govinfo.gov/content/pkg/STATUTE-9/pdf/STATUTE-9-Pg395.pdf"); }}>
          <FileText></FileText> View Document
        </Button>

 </div>

       </Modal>    */}
       </div>
       <Button id='presidentViewMoreButton' onClick={() =>  setBioModal(true)}>  <Files size={20}></Files> Read More</Button>


       
<Modal id='fullBillModal'  size="xl" aria-labelledby="contained-modal-title-vcenter" show={showBioModal} onHide={() => setBioModal(false)}>
<Modal.Header id='billModalHeader' closeButton><h3> Department of the Interior</h3></Modal.Header>
       
       <div id='fullBillFeed' >
    
       <p>The Department of the Interior (DOI) is the nation’s principal conservation agency. Its mission is to protect America’s natural resources, offer recreation opportunities, conduct scientific research, conserve and protect fish and wildlife, and honor the U.S. government’s responsibilities to American Indians, Alaskan Natives, and island communities.</p>

<p>DOI manages approximately 500 million acres of surface land, about one-fifth of the land in the United States, and oversees hundreds of dams and reservoirs. Agencies within DOI include the Bureau of Indian Affairs, the Fish and Wildlife Service, and the U.S. Geological Survey. DOI also manages national parks and is responsible for protecting endangered species.</p>

<p>The Secretary of the Interior oversees about 70,000 employees and 200,000 volunteers with a budget of approximately $16 billion. Every year, DOI raises billions in revenue from energy, mineral, grazing, and timber leases, as well as recreational permits and land sales.</p>

<br></br>
<Link onClick={(e) => {
   e.preventDefault();
   window.open("https://www.whitehouse.gov/about-the-white-house/our-government/the-executive-branch/#:~:text=approximately%20%2440%20billion.-,DEPARTMENT%20OF%20THE%20INTERIOR,-The%20Department%20of", "_blank");
 }}>
   https://www.whitehouse.gov/about-the-white-house/our-government/the-executive-branch/#:~:text=approximately%20%2440%20billion.-,DEPARTMENT%20OF%20THE%20INTERIOR,-The%20Department%20of
   </Link>
</div>

     </Modal>
 
            <Col>


<h5 id='presidentialFeedHeadingMobile' onClick={() => setDepartmentOfDefenseModalIsOpen(true)}>News</h5>




  <div class='courtNewsMobile' id='defenseDepartment'></div>

  {CreateFeedItem('https://www.doi.gov/feeds/list/22038/rss.xml', 'defenseDepartment', 'Department of the Interior', 'Executive', 'N/A', DepartmentOfTheInterior)}

  <Modal id='whiteHouseDocsModal' show={departmentOfDefenseModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfDefenseModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of the Interior</h3></Modal.Header>
                  <DepartmentOfTheInterior/>
                </Modal>

  </Col>


  <Col>


<h5 id='presidentialFeedHeadingMobile' onClick={() => setDefenseDocumentsModalIsOpen(true)}>Documents</h5>


  <div class='courtNewsMobile' id='frDefense'></div>

  {CreateFeedItem('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=interior-department', 'frDefense', 'Department of the Interior Documents', 'Executive', 'N/A', DepartmentOfTheInteriorDocs)}

</Col>
              
                <Modal id='whiteHouseDocsModal' show={defenseDocumentsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDefenseDocumentsModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of the Interior Documents</h3></Modal.Header>
                  <DepartmentOfTheInteriorDocs/>
                </Modal>

                <Col>
  <Link  id='pageLink' to="/us/fish&wildlife">
    <h5 id='presidentialFeedHeadingMobile'>Fish and Wildlife Service<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Fish and Wildlife Service',
                <GenerateFeedDepartment url='&table_name=interior&agency=Fish%20and%20Wildlife%20Service'
                  name='Fish and Wildlife Service' branch='Executive' topic='Environment' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defFws'></div>
          {CreateFeedAPIDepartmentUS('&table_name=interior&agency=Fish%20and%20Wildlife%20Service', 'defFws',
            'Fish and Wildlife Service Documents', 'Executive',
            'Environment', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/fish&wildlife' >View all Fish and Wildlife Service news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/us/geological-survey">
    <h5 id='presidentialFeedHeadingMobile'>Geological Survey<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Geological Survey',
                <GenerateFeedDepartment url='&table_name=interior&agency=Geological%20Survey'
                  name='Geological Survey' branch='Executive' topic='Environment' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defGs'></div>
          {CreateFeedAPIDepartmentUS('&table_name=interior&agency=Geological%20Survey', 'defGs',
            'Geological Survey Documents', 'Executive',
            'Environment', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/geological-survey' >View all Geological Survey news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/us/hearings-appeals">
    <h5 id='presidentialFeedHeadingMobile'>Hearings and Appeals Office, Interior Department<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Hearings and Appeals Office, Interior Department',
                <GenerateFeedDepartment url='&table_name=interior&agency=Hearings%20and%20Appeals%20Office%2C%20Interior%20Department'
                  name='Hearings and Appeals Office, Interior Department' branch='Executive' topic='Environment' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defHaoi'></div>
          {CreateFeedAPIDepartmentUS('&table_name=interior&agency=Hearings%20and%20Appeals%20Office%2C%20Interior%20Department', 'defHaoi',
            'Hearings and Appeals Office, Interior Department Documents', 'Executive',
            'Environment', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/hearings-appeals' >View all Hearings and Appeals Office, Interior Department news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/us/indian-affairs">
    <h5 id='presidentialFeedHeadingMobile'>Indian Affairs Bureau<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Indian Affairs Bureau',
                <GenerateFeedDepartment url='&table_name=interior&agency=Indian%20Affairs%20Bureau'
                  name='Indian Affairs Bureau' branch='Executive' topic='Environment' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defIab'></div>
          {CreateFeedAPIDepartmentUS('&table_name=interior&agency=Indian%20Affairs%20Bureau', 'defIab',
            'Indian Affairs Bureau Documents', 'Executive',
            'Environment', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/indian-affairs' >View all Indian Affairs Bureau news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/us/indian-trust-transition">
    <h5 id='presidentialFeedHeadingMobile'>Indian Trust Transition Office<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Indian Trust Transition Office',
                <GenerateFeedDepartment url='&table_name=interior&agency=Indian%20Trust%20Transition%20Office'
                  name='Indian Trust Transition Office' branch='Executive' topic='Environment' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defItto'></div>
          {CreateFeedAPIDepartmentUS('&table_name=interior&agency=Indian%20Trust%20Transition%20Office', 'defItto',
            'Indian Trust Transition Office Documents', 'Executive',
            'Environment', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/indian-trust-transition' >View all Indian Trust Transition Office news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/us/land-management">
    <h5 id='presidentialFeedHeadingMobile'>Land Management Bureau<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Land Management Bureau',
                <GenerateFeedDepartment url='&table_name=interior&agency=Land%20Management%20Bureau'
                  name='Land Management Bureau' branch='Executive' topic='Environment' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defLmb'></div>
          {CreateFeedAPIDepartmentUS('&table_name=interior&agency=Land%20Management%20Bureau', 'defLmb',
            'Land Management Bureau Documents', 'Executive',
            'Environment', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/land-management' >View all Land Management Bureau news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/us/minerals-management">
    <h5 id='presidentialFeedHeadingMobile'>Minerals Management Service<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Minerals Management Service',
                <GenerateFeedDepartment url='&table_name=interior&agency=Minerals%20Management%20Service'
                  name='Minerals Management Service' branch='Executive' topic='Environment' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defMms'></div>
          {CreateFeedAPIDepartmentUS('&table_name=interior&agency=Minerals%20Management%20Service', 'defMms',
            'Minerals Management Service Documents', 'Executive',
            'Environment', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/minerals-management' >View all Minerals Management Service news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/us/mines-bureau">
    <h5 id='presidentialFeedHeadingMobile'>Mines Bureau<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Mines Bureau',
                <GenerateFeedDepartment url='&table_name=interior&agency=Mines%20Bureau'
                  name='Mines Bureau' branch='Executive' topic='Environment' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defMb'></div>
          {CreateFeedAPIDepartmentUS('&table_name=interior&agency=Mines%20Bureau', 'defMb',
            'Mines Bureau Documents', 'Executive',
            'Environment', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/mines-bureau' >View all Mines Bureau news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/us/national-biological">
    <h5 id='presidentialFeedHeadingMobile'>National Biological Service<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('National Biological Service',
                <GenerateFeedDepartment url='&table_name=interior&agency=National%20Biological%20Service'
                  name='National Biological Service' branch='Executive' topic='Environment' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defNbs'></div>
          {CreateFeedAPIDepartmentUS('&table_name=interior&agency=National%20Biological%20Service', 'defNbs',
            'National Biological Service Documents', 'Executive',
            'Environment', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/national-biological' >View all National Biological Service news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/us/nccc">
    <h5 id='presidentialFeedHeadingMobile'>National Civilian Community Corps<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('National Civilian Community Corps',
                <GenerateFeedDepartment url='&table_name=interior&agency=National%20Civilian%20Community%20Corps'
                  name='National Civilian Community Corps' branch='Executive' topic='Environment' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defNccc'></div>
          {CreateFeedAPIDepartmentUS('&table_name=interior&agency=National%20Civilian%20Community%20Corps', 'defNccc',
            'National Civilian Community Corps Documents', 'Executive',
            'Environment', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/nccc' >View all National Civilian Community Corps news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/us/indian-gaming">
    <h5 id='presidentialFeedHeadingMobile'>National Indian Gaming Commission<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('National Indian Gaming Commission',
                <GenerateFeedDepartment url='&table_name=interior&agency=National%20Indian%20Gaming%20Commission'
                  name='National Indian Gaming Commission' branch='Executive' topic='Environment' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defNigc'></div>
          {CreateFeedAPIDepartmentUS('&table_name=interior&agency=National%20Indian%20Gaming%20Commission', 'defNigc',
            'National Indian Gaming Commission Documents', 'Executive',
            'Environment', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/indian-gaming' >View all National Indian Gaming Commission news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/us/national-park-service">
    <h5 id='presidentialFeedHeadingMobile'>National Park Service<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('National Park Service',
                <GenerateFeedDepartment url='&table_name=interior&agency=National%20Park%20Service'
                  name='National Park Service' branch='Executive' topic='Environment' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defNps'></div>
          {CreateFeedAPIDepartmentUS('&table_name=interior&agency=National%20Park%20Service', 'defNps',
            'National Park Service Documents', 'Executive',
            'Environment', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/national-park-service' >View all National Park Service news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/us/natural-resources-revenue">
    <h5 id='presidentialFeedHeadingMobile'>Natural Resources Revenue Office<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Natural Resources Revenue Office',
                <GenerateFeedDepartment url='&table_name=interior&agency=Natural%20Resources%20Revenue%20Office'
                  name='Natural Resources Revenue Office' branch='Executive' topic='Environment' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defNrro'></div>
          {CreateFeedAPIDepartmentUS('&table_name=interior&agency=Natural%20Resources%20Revenue%20Office', 'defNrro',
            'Natural Resources Revenue Office Documents', 'Executive',
            'Environment', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/natural-resources-revenue' >View all Natural Resources Revenue Office news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/us/ocean-energy-management">
    <h5 id='presidentialFeedHeadingMobile'>Ocean Energy Management Bureau<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Ocean Energy Management Bureau',
                <GenerateFeedDepartment url='&table_name=interior&agency=Ocean%20Energy%20Management%20Bureau'
                  name='Ocean Energy Management Bureau' branch='Executive' topic='Environment' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defOemb'></div>
          {CreateFeedAPIDepartmentUS('&table_name=interior&agency=Ocean%20Energy%20Management%20Bureau', 'defOemb',
            'Ocean Energy Management Bureau Documents', 'Executive',
            'Environment', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/ocean-energy-management' >View all Ocean Energy Management Bureau news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/us/ocean-energy-enforcement">
    <h5 id='presidentialFeedHeadingMobile'>Ocean Energy Management, Regulation, and Enforcement Bureau<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Ocean Energy Management, Regulation, and Enforcement Bureau',
                <GenerateFeedDepartment url='&table_name=interior&agency=Ocean%20Energy%20Management%2C%20Regulation%2C%20and%20Enforcement%20Bureau'
                  name='Ocean Energy Management, Regulation, and Enforcement Bureau' branch='Executive' topic='Environment' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defOemreb'></div>
          {CreateFeedAPIDepartmentUS('&table_name=interior&agency=Ocean%20Energy%20Management%2C%20Regulation%2C%20and%20Enforcement%20Bureau', 'defOemreb',
            'Ocean Energy Management, Regulation, and Enforcement Bureau Documents', 'Executive',
            'Environment', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/ocean-energy-enforcement' >View all Ocean Energy Management, Regulation, and Enforcement Bureau news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/us/reclamation">
    <h5 id='presidentialFeedHeadingMobile'>Reclamation Bureau<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Reclamation Bureau',
                <GenerateFeedDepartment url='&table_name=interior&agency=Reclamation%20Bureau'
                  name='Reclamation Bureau' branch='Executive' topic='Environment' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defRb'></div>
          {CreateFeedAPIDepartmentUS('&table_name=interior&agency=Reclamation%20Bureau', 'defRb',
            'Reclamation Bureau Documents', 'Executive',
            'Environment', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/reclamation' >View all Reclamation Bureau news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/us/safety-environmental-enforcement">
    <h5 id='presidentialFeedHeadingMobile'>Safety and Environmental Enforcement Bureau<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Safety and Environmental Enforcement Bureau',
                <GenerateFeedDepartment url='&table_name=interior&agency=Safety%20and%20Environmental%20Enforcement%20Bureau'
                  name='Safety and Environmental Enforcement Bureau' branch='Executive' topic='Environment' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defSeeb'></div>
          {CreateFeedAPIDepartmentUS('&table_name=interior&agency=Safety%20and%20Environmental%20Enforcement%20Bureau', 'defSeeb',
            'Safety and Environmental Enforcement Bureau Documents', 'Executive',
            'Environment', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/safety-environmental-enforcement' >View all Safety and Environmental Enforcement Bureau news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/us/special-trustee-american-indians">
    <h5 id='presidentialFeedHeadingMobile'>Special Trustee for American Indians Office<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Special Trustee for American Indians Office',
                <GenerateFeedDepartment url='&table_name=interior&agency=Special%20Trustee%20for%20American%20Indians%20Office'
                  name='Special Trustee for American Indians Office' branch='Executive' topic='Environment' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defStaio'></div>
          {CreateFeedAPIDepartmentUS('&table_name=interior&agency=Special%20Trustee%20for%20American%20Indians%20Office', 'defStaio',
            'Special Trustee for American Indians Office Documents', 'Executive',
            'Environment', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/special-trustee-american-indians' >View all Special Trustee for American Indians Office news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/us/surface-mining">
    <h5 id='presidentialFeedHeadingMobile'>Surface Mining Reclamation and Enforcement Office<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Surface Mining Reclamation and Enforcement Office',
                <GenerateFeedDepartment url='&table_name=interior&agency=Surface%20Mining%20Reclamation%20and%20Enforcement%20Office'
                  name='Surface Mining Reclamation and Enforcement Office' branch='Executive' topic='Environment' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defSmreo'></div>
          {CreateFeedAPIDepartmentUS('&table_name=interior&agency=Surface%20Mining%20Reclamation%20and%20Enforcement%20Office', 'defSmreo',
            'Surface Mining Reclamation and Enforcement Office Documents', 'Executive',
            'Environment', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/surface-mining' >View all Surface Mining Reclamation and Enforcement Office news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>




<br></br>
          
          
            <br></br><br></br><br></br><br></br><br></br><br></br><Navbar id='navbarMobileBottom' fixed="bottom">    

          


       
<div id='changeBranchDropdownMobile'>

           <MobileOffCanvas/>
      
            </div>

           


           
 
             
 
 
 
 
 
 
</Navbar>
          </div>

          </>
  )
}
 }



  






export default InteriorMobile;

