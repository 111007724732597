
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link, Switch, } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment, } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Tooltip, OverlayTrigger, Image } from 'react-bootstrap';
import { ArrowsAngleExpand, ArrowsFullscreen, InfoCircle, FileText, ShieldShaded, Pen, PauseCircle, PlayCircle, People, HeartFill, Collection, Grid, ThreeDots, Stack, List, Files, FolderPlus, FolderCheck, Folder, ChevronRight } from 'react-bootstrap-icons'
import { BrowserView, MobileView } from 'react-device-detect';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import Axios from 'axios'
import { render } from '@testing-library/react';
import FullBillFeed from './FullBillFeed';
import FullWhiteHouseFeed from './FullWhiteHouseFeed';
import FullUSCourtNews from './FullUSCourtNews';
import FullWhiteHouseDocuments from './FullWhiteHouseDocuments';
import DailyDigest from './DailyDigest';
import State from './State';
import Illinois from './state/Illinois';
import CollectionPage from './CollectionPage';
import NewLaws from './NewLaws';
import BillsEnrolled from './BillsEnrolled';
import DepartmentOfState from './DepartmentOfState';
import DepartmentOfTreasury from './DepartmentOfTreasury';
import CourtAppeals from './CourtAppeals';
import Reports from './Reports';
import DepartmentOfDefense from './DepartmentOfDefense';
import DepartmentOfJustice from './DepartmentOfJustice';
import DepartmentOfTheInterior from './DepartmentOfTheInterior';
import DepartmentOfAgriculture from './DepartmentOfAgriculture';
import DepartmentOfCommerce from './DepartmentOfCommerce';
import DepartmentOfLabor from './DepartmentOfLabor';
import DepartmentOfHealthAndHumanServices from './DepartmentOfHealthAndHumanServices';
import DepartmentOfHousingAndUrbanDevelopment from './DepartmentOfHousingAndUrbanDevelopment';
import DepartmentOfTransportation from './DepartmentOfTransportation';
import DepartmentOfEnergy from './DepartmentOfEnergy';
import DepartmentOfEducation from './DepartmentOfEducation';
import DepartmentOfVeteranAffairs from './DepartmentOfVeteranAffairs';
import DepartmentOfHomelandSecurity from './DepartmentOfHomelandSecurity';
import EnvironmentalProtectionAgency from './EnvironmentalProtectionAgency';
import NationalAeronauticsAndSpaceAdministration from './NationalAeronauticsAndSpaceAdministration';
import TrendingBills from './TrendingBills';
import GAOReports from './GAOReports';
import LegislativeBranch from './LegislativeBranch';
import HouseComitteeOnAgriculture from './HouseComitteeOnAgriculture';
import HouseCommitteeOnAppropriations from './HouseCommitteeOnArmedServices';

import App from './App';
import Overlay from 'react-overlays/esm/Overlay';
import ExecutiveOrders from './ExecutiveOrders';
import PresidentialProclamations from './PresidentialProclamations';
import NationalScienceFoundation from './NationalScienceFoundation';

import{ whiteHouseSearch, createPresidentialProclamations, createExecutiveOrders, createWhiteHouseDocuments, createWhiteHouseFeed,
  createWhiteHouseFeedMobile,createDepartmentOfState,
  createDepartmentOfDefense, 
  createDepartmentOfJustice, createDepartmentOfTheInterior,
  createDepartmentOfAgriculture, createDepartmentOfCommerce,
  createDepartmentOfCommerceMobile, createDepartmentOfLabor,
  createDepartmentOfHealthAndHumanServices, createDepartmentOfHousingAndUrbanDevelopment, createDepartmentOfEducation
  ,  createDepartmentOfVeteransAffairs, createDepartmentOfHomelandSecurity
  , createEnvironmentalProtectionAgency,
  createDepartmentOfTreasury, createNationalScienceFoundation, 
   createEnvironmentalProtectionAgencyMobile, createNationalAeronauticsAndSpaceAdministration, Speak, createFederalRegisterDefenseDepartment, createFederalRegisterStateDepartment, createFederalRegisterTreasuryDepartment, createFederalRegisterJusticeDepartment, createFederalRegisterInteriorDepartment, createFederalRegisterAgricultureDepartment, createFederalRegisterCommerceDepartment, createFederalRegisterLaborDepartment, createFederalRegisterHealthAndHumanServicesDepartment, createFederalRegisterHousingAndUrbanDevelopmentDepartment, createFederalRegisterHomelandSecurityDepartment, createFederalRegisterEducationDepartment, createFederalRegisterVeteransAffairsDepartment,
   CreateFeedItem, CreateFeedItemBillUpdates, CreateFeedItemWithDocButton,
   CreateFeedItemPresidentialDocuments, CreateFeedItemExecutiveOrders, CreateFeedItemCongressionalReports, CreateFeedItemExecutiveOffice, CreateFeedItemBillWithDescription, CreateFeedAPIDepartmentUS } from './Functions'
import ExecutiveBranchMobile from './ExecutiveBranchMobile';
import DesktopNavbar from './DesktopNavbar';
import DepartmentOfTheInteriorDocs from './DepartmentOfTheInteriorDocs';
import FishingAndWildlifeService from './FishingAndWildlifeService';
import GeologicalSurvey from './GeologicalSurvey';
import InteriorDeptHearings from './InteriorDeptHearings';
import IndianAffairsBureau from './IndianAffairsBureau';
import LandManagementBureau from './LandManagementBureau';
import NationalIndianGamingCommission from './NationalIndianGamingCommission';
import NationalParkService from './NationalParkService';
import ReclamationBureau from './ReclamationBureau';
import SpecialTrusteeForAmericanIndians from './SpecialTrusteeForAmericanIndians';
import SurfaceMiningReclamation from './SurfaceMiningReclamation';
import OceanEnergyManagmentBureau from './OceanEnergyManagmentBureau';
import SafetyAndEnvironmentEnforcement from './SafetyAndEnvironmentEnforcement';
import NaturalResourcesRevenueOffice from './NaturalResourcesRevenueOffice';
import TransportationMobile from './TransportationMobile';
import InteriorMobile from './InteriorMobile';
import DepartmentOfCommerceDocuments from './DepartmentOfCommerceDocuments';
import CensusBureau from './CensusBureau';
import EconomicAnalysisBureau from './EconomicAnalysisBureau';
import EconomicDevelopmentAdministration from './EconomicDevelopmentAdministration';
import EconomicsAndStatistics from './EconomicsAndStatistics';
import ForeignTradeZonesBoard from './ForeignTradeZonesBoard';
import IndustryAndSecurityBureau from './IndustryAndSecurityBureau';
import InternationalTradeAdministration from './InternationalTradeAdministration';
import MinorityBusinessDevelopmentAgency from './MinorityBusinessDevelopmentAgency';
import NationalInstituteOfStandardsAndTech from './NationalInstituteOfStandardsAndTech';
import NationalOceanicAndAtmosphericAdministration from './NationalOceanicAndAtmosphericAdministration';
import NationalTechnicalInformationService from './NationalTechnicalInformationService';
import NationalTelecommunicationsAndInformationAdmin from './NationalTelecommunicationsAndInformationAdmin';
import PatentAndTradeMarkOffice from './PatentAndTradeMarkOffice';
import UnderSecretaryForEconomicAffairs from './UnderSecretaryForEconomicAffairs';
import CommerceMobile from './CommerceMobile';

import DepartmentOfHousingAndUrbanDevelopmentDocs from './DepartmentOfHousingAndUrbanDevelopmentDocs';
import FederalHousingEnterprise from './FederalHousingEnterprise';
import HousingMobile from './HousingMobile';
import GenerateFeedDepartment from './GenerateFeedDepartment';



var collectionCounter = 0
var carouselInterval = 30000;
var mobileCarouselInterval = 60000;





  

 


  


export const DepartmentOfHouseAndUrbanDevelopmentDesk = () =>
 {
   render()
   
   {
    const [show, setShow] = useState(true);

        
    const [fullscreen, setFullScreen] = useState(true);
  
  
    const [fullBillModalIsOpen, setFullBillModalIsOpen] = useState(false)
  
    const [reportModalIsOpen, setReportModalIsOpen] = useState(false)
  
  
    const [whiteHouseFeedModalIsOpen, setWhiteHouseFeedModalIsOpen] = useState(false)
    const [whiteHouseDocsModalIsOpen, setWhiteHouseDocsModalIsOpen] = useState(false)
  
  
    const [courtNewsModalIsOpen, setCourtNewsModalIsOpen] = useState(false)
    const [courtModalIsOpen, setCourtModalIsOpen] = useState(false)
  
    const [courtAppealsModalIsOpen, setCourtAppealsModalIsOpen] = useState(false)
  
    const [newLawsModalIsOpen, setNewLawsModalIsOpen] = useState(false)
  
    const [billsEnrolledModalIsOpen, setBillsEnrolledModalIsOpen] = useState(false)
  
    const [legislativePageBillsEnrolledModalIsOpen, setLegislativePageBillsEnrolledModalIsOpen] = useState(false)
  
  
    const [departmentOfStateModalIsOpen, setDepartmentOfStateModalIsOpen] = useState(false)
    const [departmentOfTreasuryModalIsOpen, setDepartmentOfTreasuryModalIsOpen] = useState(false)
 
    const [departmentOfJusticeModalIsOpen, setDepartmentOfJusticeModalIsOpen] = useState(false)
    const [departmentOfTheInteriorModalIsOpen, setDepartmentOfTheInteriorModalIsOpen] = useState(false)
    const [departmentOfAgricultureModalIsOpen, setDepartmentOfAgricultureModalIsOpen] = useState(false)
    const [departmentOfCommerceModalIsOpen, setDepartmentOfCommerceModalIsOpen] = useState(false)
    const [departmentOfLaborModalIsOpen, setDepartmentOfLaborModalIsOpen] = useState(false)
    const [departmentOfHealthAndHumanServicesModalIsOpen, setDepartmentOfHealthAndHumanServicesModalIsOpen] = useState(false)
    const [departmentOfHousingAndUrbanDevelopmentModalIsOpen, setDepartmentOfHousingAndUrbanDevelopmentModalIsOpen] = useState(false)
    const [departmentOfTransportationModalIsOpen, setDepartmentOfTransportationModalIsOpen] = useState(false)
    const [departmentOfEnergyModalIsOpen, setDepartmentOfEnergyModalIsOpen] = useState(false)
    const [departmentOfEducationModalIsOpen, setDepartmentOfEducationModalIsOpen] = useState(false)
    const [departmentOfVeteransAffairsModalIsOpen, setDepartmentOfVeteransAffairsModalIsOpen] = useState(false)
    const [departmentOfHomelandSecurityModalIsOpen, setDepartmentOfHomelandSecurityModalIsOpen] = useState(false)
    const [epaModalIsOpen, setEPAModalIsOpen] = useState(false)
    const [nsfModalIsOpen, setNSFModalIsOpen] = useState(false)
    const [nasaModalIsOpen, setNASAModalIsOpen] = useState(false)
    const [trendingBillsModalIsOpen, setTrendingBillsModalIsOpen] = useState(false)
    const [goaReportsModalIsOpen, setGAOReportsModalIsOpen] = useState(false)
    const [executiveOrdersModalIsOpen, setExecutiveOrdersModalIsOpen] = useState(false)
    const [presidentialProclamationsModalIsOpen, setPresidentialProclamationsModalIsOpen] = useState(false)
    const [statesModalIsOpen, setStatesModalIsOpen] = useState(false)
  
  
    const [collectionsModalIsOpen, setCollectionsModalIsOpen] = useState(false)
    const [aboutModalIsOpen, setAboutModalIsOpen] = useState(false)
  
    const [housingModalIsOpen, setHousingModalIsOpen] = useState(false)
    const [federalRegisterDefenseDepartmentModalIsOpen, setFederalRegisterDefenseDepartmentModalIsOpen] = useState(false)
const [federalRegisterStateDepartmentModalIsOpen, setFederalRegisterStateDepartmentModalIsOpen] = useState(false)
    const [declarationModalIsOpen, setDeclarationModalIsOpen] = useState(false)
    const [constitutionModalIsOpen, setConstitutionModalIsOpen] = useState(false)
    const [billOfRightsnModalIsOpen, setBillOfRightsModalIsOpen] = useState(false)

    const [economicAdModalIsOpen, setEconomicAdModalIsOpen] = useState(false)
    const [environmentQualityModalIsOpen, setEnvironmentQualityModalIsOpen] = useState(false)
    const [domesticPolicyModalIsOpen, setDomesticPolicyModalIsOpen] = useState(false)
    const [genderPolicyModalIsOpen, setGenderPolicyModalIsOpen] = useState(false)
    const [nationalEconomicModalIsOpen, setNationalEconomicModalIsOpen] = useState(false)
    const [securityModalIsOpen, setSecurityModalIsOpen] = useState(false)
    const [budgetModalIsOpen, setBudgetModalIsOpen] = useState(false)
    const [drugModalIsOpen, setDrugModalIsOpen] = useState(false)
    const [publicModalIsOpen, setPublicModalIsOpen] = useState(false)
    const [scienceModalIsOpen, setScienceModalIsOpen] = useState(false)
    const [cyberModalIsOpen, setCyberModalIsOpen] = useState(false)
    const [presidentialModalIsOpen, setPresidentialModalIsOpen] = useState(false)

    const [departmentOfDefenseModalIsOpen, setDepartmentOfDefenseModalIsOpen] = useState(false)
    const [defenseDocumentsModalIsOpen, setDefenseDocumentsModalIsOpen] = useState(false)
    const [armyModalIsOpen, setArmyModalIsOpen] = useState(false)
    const [navyModalIsOpen, setNavyModalIsOpen] = useState(false)
    const [marinesModalIsOpen, setMarinesModalIsOpen] = useState(false)
    const [spaceForceModalIsOpen, setSpaceForceModalIsOpen] = useState(false)
    const [airForceModalIsOpen, setAirForceModalIsOpen] = useState(false)
    const [nationalGuardModalIsOpen, setNationalGuardModalIsOpen] = useState(false)
    const [ciaModalIsOpen, setCIAModalIsOpen] = useState(false)
    const [nsaModalIsOpen, setNSAModalIsOpen] = useState(false)
    const [armyCorpsModalIsOpen, setArmyCorpsModalIsOpen] = useState(false)

    const [coastGuardModalIsOpen, setCoastGuardModalIsOpen] = useState(false)
    const [femaModalIsOpen, setFEMAModalIsOpen] = useState(false)
    const [secretServiceModalIsOpen, setSecretServiceModalIsOpen] = useState(false)
    const [tsaModalIsOpen, setTSAModalIsOpen] = useState(false)
    const [immigrationModalIsOpen, setImmigrationModalIsOpen] = useState(false)
    const [usCustomsModalIsOpen, setUSCustomsModalIsOpen] = useState(false)
    const [enforcementsModalIsOpen, setEnforcemnetsModalIsOpen] = useState(false)
    const [homelandDocsModalIsOpen, setHomelandDocsModalIsOpen] = useState(false)

    const [showBioModal, setBioModal] = useState(false);
    const [showMissionModal, setMissionModal] = useState(false);

  return(
    <>
      <BrowserView>
        <div id='homePage'>

     

        <div id='homebg'>

          <DesktopNavbar/>
          <div id='feedDisplay' 
                  style={{ padding: '20px', borderRadius: '18px', marginTop: '0em'}}>

<div style={{ position: 'relative' }}>

     <div id='profileImage' style={{ width: '150px', height: '150px', borderRadius: '100%', overflow: 'hidden',  marginLeft: '40px' }}>
     <Image id='profileImage-image' src='/departmentOfHousing.png' />
   </div>


   <div style={{ position: 'absolute', top: '0', left: '250px', display: 'flex', flexDirection: 'column' }}>
   <div style={{ color: 'white', fontSize: '24px', fontWeight: 'bold' }}>

</div>
<div  style={{ color: 'white', fontSize: '20px' }}> 
<span> <h3  >Department of Housing and Urban Development </h3></span>
<br></br>
<div style={{ color: 'white', fontSize: '20px' }}>
<span> The Department of Housing and Urban Development (HUD) is the federal agency responsible for national policies and programs that address America’s housing needs, improve and develop the nation’s communities, and enforce fair housing laws. The Department plays a major role in supporting homeownership for low- and moderate-income families through its mortgage insurance and rent subsidy programs.</span>
<br></br>
<div style={{ color: 'white', fontSize: '18px' }}>

       <span><Link style={{color: '#21cff5'}} onClick={(e) => {
          e.preventDefault();
          window.open("https://www.hud.gov/", "_blank");
        }}>
          https://www.hud.gov/
          </Link></span> 
<br></br>
<Button id='whDocsViewMoreButton' onClick={() =>  setMissionModal(true)}>  <Files size={20}></Files> Read More</Button>
</div>
  </div>

     </div>
     </div>


     </div>

           </div>

           <Modal id='fullBillModal'  size="xl" aria-labelledby="contained-modal-title-vcenter" show={showMissionModal} onHide={() => setMissionModal(false)}>
         <Modal.Header id='billModalHeader' closeButton><h3> Department of Housing and Urban Development</h3></Modal.Header>
       
         <div id='fullBillFeed' >
      
         <p>The Department of Housing and Urban Development (HUD) is the federal agency responsible for national policies and programs that address America’s housing needs, improve and develop the nation’s communities, and enforce fair housing laws. The Department plays a major role in supporting homeownership for low- and moderate-income families through its mortgage insurance and rent subsidy programs.</p>

<p>Offices within HUD include the Federal Housing Administration, which provides mortgage and loan insurance; the Office of Fair Housing and Equal Opportunity, which ensures all Americans equal access to the housing of their choice; and the Community Development Block Grant Program, which helps communities with economic development, job opportunities, and housing rehabilitation. HUD also administers public housing and homeless assistance.</p>

<p>The Secretary of Housing and Urban Development oversees more than 9,000 employees with a budget of approximately $40 billion.</p>

<br></br>
<Link style={{color: '#21cff5'}} onClick={(e) => {
     e.preventDefault();
     window.open("https://www.whitehouse.gov/about-the-white-house/our-government/the-executive-branch/#:~:text=and%20intelligence%20agencies.-,DEPARTMENT%20OF%20HOUSING%20AND%20URBAN%20DEVELOPMENT,-The%20Department%20of", "_blank");
   }}>
     https://www.whitehouse.gov/about-the-white-house/our-government/the-executive-branch/#:~:text=and%20intelligence%20agencies.-,DEPARTMENT%20OF%20HOUSING%20AND%20URBAN%20DEVELOPMENT,-The%20Department%20of
     </Link>
 </div>

       </Modal>
           <br></br> <br></br> <br></br>
            
            <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferTwo'>
                   
                    <Row>

                      <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>
                        <h4 id='whBreifingFeedHeading' onClick={() => setDepartmentOfDefenseModalIsOpen(true)}><b>News</b></h4>


<div   id='housingAndUrbanDevelopmentDepartment'></div>

{CreateFeedItem('https://www.hud.gov/sites/dfiles/Main/documents/hudrss.xml', 'housingAndUrbanDevelopmentDepartment', 'Department of Housing and Urban Development', 'Executive', 'Environment', DepartmentOfHousingAndUrbanDevelopment)}

<Modal id='whiteHouseDocsModal' show={departmentOfDefenseModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfDefenseModalIsOpen(false)}>
                <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Housing and Urban Development</h3></Modal.Header>
                <DepartmentOfHousingAndUrbanDevelopment/>
              </Modal>
                        </div>
                      </Col>

                      <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>
                        <h4 id='whBreifingFeedHeading' onClick={() => setDefenseDocumentsModalIsOpen(true)}><b>Documents</b></h4>


<div id='frDefense'></div>

{CreateFeedItem('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=housing-and-urban-development-department', 'frDefense', 'Department of Housing and Urban Development Documents', 'Executive', 'N/A', DepartmentOfHousingAndUrbanDevelopmentDocs)}

<Modal id='whiteHouseDocsModal' show={defenseDocumentsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDefenseDocumentsModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Housing and Urban Development Documents</h3></Modal.Header>
                  <DepartmentOfHousingAndUrbanDevelopmentDocs/>
                </Modal>
                        </div>
                      </Col>


                    </Row>


                  </Container>
                </Container>
                </div>    
                <div id='branchPage'>
  <Container class='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/housing-oversight', "_self")}}><b>Federal Housing Enterprise Oversight Office</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defFheo'></div>
            {CreateFeedAPIDepartmentUS('&table_name=hud&agency=Federal%20Housing%20Enterprise%20Oversight%20Office', 'defFheo',
              'Federal Housing Enterprise Oversight Office Documents', 'Executive',
              'Housing', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={housingModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setHousingModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Federal Housing Enterprise Oversight Office</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=hud&agency=Federal%20Housing%20Enterprise%20Oversight%20Office'
                name='Federal Housing Enterprise Oversight Office' branch='Executive' topic='Housing' buttonName='Doc' />
            </Modal>
          </div>
        </Col>

        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/gnma', "_self")}}><b>Government National Mortgage Association</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defGnma'></div>
            {CreateFeedAPIDepartmentUS('&table_name=hud&agency=Government%20National%20Mortgage%20Association', 'defGnma',
              'Government National Mortgage Association Documents', 'Executive',
              'Housing', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={housingModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setHousingModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Government National Mortgage Association</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=hud&agency=Government%20National%20Mortgage%20Association'
                name='Government National Mortgage Association' branch='Executive' topic='Housing' buttonName='Doc' />
            </Modal>
          </div>
        </Col>
      </Row>
    </Container>
  </Container>
</div>


       


   

            <Modal id='aboutModal' show={aboutModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setAboutModalIsOpen(false)}>
            <div id='removedAlert'></div>
            <Modal.Header id='aboutModalHeader' closeButton><h3>About</h3></Modal.Header>
  <ModalBody id='aboutInfo'><p id='aboutParagraph'>Gov Glance was created to drive civic engagement and support a 
              healthier democracy by helping citizens get official Government news in an easy-to-navigate centralized location. 
              There will never be any opinion or political analysis(That's your job), only information from official Government 
              sources. Gov Glance is structured after the U.S. government that is broken into three branches Legislative, 
              Executive, and Judicial. To learn more about the structure and functions of The U.S. Government go to <a href='https://www.usa.gov/branches-of-government'>https://www.usa.gov/branches-of-government</a>.
              </p>

              <p id='aboutParagraph'>
              Gov Glance Foundation Inc. is a non-profit that was created to operate govglance.org and continue to create educational tools that help people learn about the workings of society
              </p>
            <br></br>

            <h3>Privacy</h3>

            <br></br>

              <p id='aboutParagraph'>Gov Glance does not collect any personal user information. 
              Features like collection save news items directly to your browser's local storage for your convenience
              and is not shared with Gov Glance servers. We do not collect, store, or send personal information to Google LLC, 
              but we do use some Google services to deliver our app to you. For any questions into Google’s privacy practices 
              here is a link to their privacy policy: <a href='https://policies.google.com/privacy?hl=en-US#intro'>https://policies.google.com/privacy?hl=en-US#intro</a>

              <br></br><br></br>

              For site analytics we use plausible.io an open source privacy friendly analytics provider. <a href="https://plausible.io/about" target="_blank">https://plausible.io/about</a>
            
            </p>
            <br></br>


            <h3>Contact</h3>

            <p id='aboutParagraph'>
                      Contact us via email: <a href='mailto:contact@govglance.org'>contact@govglance.org</a>

            </p>

            <br></br>


            
            </ModalBody>
          </Modal>
 
          <footer id='homeFooter'>
      <Navbar>
        <Row id='footerNav'>
      <Nav variant='pills'>
        
     <Nav.Link id='footerContent'>Gov Glance Foundation Inc.
</Nav.Link>
<Nav.Item>
<Nav.Link id='footerContent' href='mailto:contact@govglance.org'>Contact</Nav.Link>
</Nav.Item>
<Nav.Item>
<Nav.Link id='footerContent' onClick={(e) => {
                   e.preventDefault();
                   window.open('https://donate.stripe.com/28o3fXbdvbQO2RO288', "_blank")
                 }}> <HeartFill id='donateIcon'/>    Donate</Nav.Link>
</Nav.Item>
<Nav.Link onClick={(e) => {
                   e.preventDefault();
                   window.open('/about', "_self")}} id='footerContent'>Privacy</Nav.Link>
    
     
 </Nav>
 </Row>
 </Navbar>
      </footer>



</div>
         </div>
          

              </BrowserView>

              <MobileView>

    <HousingMobile/>

              </MobileView>
              
    </>
  )
}
 }



  






export default DepartmentOfHouseAndUrbanDevelopmentDesk;