import { useEffect, useState } from "react";
import { Button, Card, Container, Form, Image, Modal, Navbar, Row, Spinner } from "react-bootstrap";
import { Files, FileText, FolderPlus, InfoCircle, Search } from "react-bootstrap-icons";
import { BrowserView, MobileView } from "react-device-detect";

import MobileOffCanvas from "./MobileOffCanvas";

var storedTitle = ''// title
var storedDetailsLink = ''// details link
var storedDocLink = ''// doc link *
var storedDate = ''// date
var storedNote = ''// note
var storedFeedName = ''// feed name
var storedBranchName = ''// branch name 
var storedTopicName = ''// topic name
var storedDescription = ''// description
var collectionCounter = localStorage.getItem('collectionNumber')

function formatDate(inputDate) {
  const date = new Date(inputDate);
  
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  return `${month}-${day}-${year}`;
}

function SaveFeedItem(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, 
  saveCollectionNote, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName, saveCollectionDescription)
{

storedTitle = saveCollectionTitle;
storedDetailsLink = saveCollectionDetailLink;
storedDocLink = saveCollectionDocLink;
storedDate = saveCollectionDate;
storedNote = saveCollectionNote;
storedFeedName = saveCollectionFeedName;
storedBranchName = saveCollectionBranchName;
storedTopicName = saveCollectionTopicName;
storedDescription = saveCollectionDescription;
}

function USJusticePage(props) {
    const [loading, setLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [showModal2, setShowModal2] = useState(false);
    const [showModal3, setShowModal3] = useState(false);
    const [showModal4, setShowModal4] = useState(false);
    const [searchQueryModal, setSearchQueryModal] = useState('');
    const [isSearchTriggered, setIsSearchTriggered] = useState(false);
    function saveNote(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName, saveCollectionDescription ){
    
      var searchItem = document.getElementById('noteModalFeedItem').value
    
         collectionCounter = localStorage.getItem('collectionNumber')
     
       collectionCounter++;
  
       
       var collectionTitles = []
        var collectionDetailLinks = []
       var collectionDocLinks = []
       var collectionNote = []
  
         var title = 'title'.concat(collectionCounter)
         var detailLink = 'detailLink'.concat(collectionCounter)
         var docLink = 'docLink'.concat(collectionCounter)
         var itemNote = 'itemNote'.concat(collectionCounter)
         var itemDate = 'itemDate'.concat(collectionCounter)
         var feedName = 'feedName'.concat(collectionCounter)
         var branchName = 'branchName'.concat(collectionCounter)
         var topicName = 'topicName'.concat(collectionCounter)
         var itemDescription = 'itemDescription'.concat(collectionCounter)
     
     
       
       
         localStorage.setItem(title,saveCollectionTitle)
         
         localStorage.setItem(detailLink,saveCollectionDetailLink)
        
         localStorage.setItem(docLink,saveCollectionDocLink)
         localStorage.setItem(itemNote,searchItem)
     
         localStorage.setItem(itemDate,saveCollectionDate)
         localStorage.setItem(feedName,saveCollectionFeedName)
         localStorage.setItem(branchName,saveCollectionBranchName)
         localStorage.setItem(topicName,saveCollectionTopicName)
     
         localStorage.setItem(itemDescription,saveCollectionDescription)
       
         localStorage.setItem('collectionNumber', collectionCounter)
  
    }
  
    const [collectionModalIsOpen, setCollectionModalIsOpen] = useState(false)
  const [error, setError] = useState(null);
  const [error2, setError2] = useState(null);
  const [error3, setError3] = useState(null);
  const [error4, setError4] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
    require('dotenv').config();
    const apiKey = process.env.REACT_APP_API_KEY;
    const requestOptions = {
      method: 'GET',
      headers: { 
        'Content-Type': 'application/json',
        "Authorization": "Bearer "+apiKey,
    },
    };

    const [presidentDocs, setPresidentDocs] = useState(null);
    const [presidentDocsModal, setPresidentDocsModal] = useState(null);
    const [memberData, setMemberData] = useState(null);
    const [memberDataModal, setMemberDataModal] = useState(null);
    const [stateOfUnion, setStateOfUnion] = useState(null);
    const [stateOfUnionModal, setStateOfUnionModal] = useState(null);
    const [laws, setLaws] = useState(null);
    const [lawsModal, setLawsModal] = useState(null);
    const [showBioModal, setBioModal] = useState(false);
    const name = props.location.state.name;
   // console.log(props)
    //console.log(name)
    
    const termStart = props.location.state.termStart;
    const termEnd = props.location.state.termEnd;

    const imageSource = props.location.state.imageSource;
    const description = props.location.state.description;
    const handleViewMoreClick = () => {

      setShowModal(true);
    
    }


    const truncateDescription = (description, maxLength) => {
      if (description.length > maxLength) {
          return description.substring(0, maxLength) + '...';
      }
      return description;
  };

  const truncatedDescription = truncateDescription(description, 200);

    const renderEndTerm = () => {
      const formattedEndTerm = formatDate(termEnd);
      if (formattedEndTerm === "12-31-1969") {
        return "Present";
      } else {
        return formattedEndTerm;
      }
    };

 
         
    return(
        <>

<BrowserView>
        <div id='homebg'>
               
               <br></br>
<div id='feedDisplay' 
                  style={{ padding: '20px', borderRadius: '18px', marginTop: '0em'}}>

<div style={{ position: 'relative' }}>
   {imageSource && (
     <div id='profileImage' style={{ width: '150px', height: '150px', borderRadius: '100%', overflow: 'hidden',  marginLeft: '40px' }}>
     <Image id='profileImage-image' src={imageSource} />
   </div>
   )}

   <div style={{ position: 'absolute', top: '0', left: '250px', display: 'flex', flexDirection: 'column' }}>
   <div style={{ color: 'white', fontSize: '24px', fontWeight: 'bold' }}>
<span>{name}</span>
</div>
<div  style={{ color: 'white', fontSize: '20px' }}> 
<span>U.S. Justice</span>
<br></br>

<span>     <div dangerouslySetInnerHTML={{ __html: truncatedDescription }} /> </span>

     </div>
     </div>


     </div>
     <br></br>
     <Button id='whDocsViewMoreButton' onClick={() =>  setBioModal(true)}>  <Files size={20}></Files> Read More</Button>
<br></br>
<div  style={{color: 'white', fontSize: '18px',
                  fontWeight: 'bold'}} >Coming Soon</div>
<Modal id='fullBillModal'  size="xl" aria-labelledby="contained-modal-title-vcenter" show={showBioModal} onHide={() => setBioModal(false)}>
         <Modal.Header id='billModalHeader' closeButton><h3> {name}'s Biography</h3></Modal.Header>
       
         <div id='fullBillFeed' >
      
         <div dangerouslySetInnerHTML={{ __html: description }} /> 

 </div>

       </Modal>
        
           </div>
           </div>
            </BrowserView>
  
            <MobileView>
              <div id='homebg'>
                <br></br>
                <br></br>
              
<div id='feedDisplay'
style={{ padding: '20px', borderRadius: '18px', marginTop: '10px', paddingTop: '8px'}}>

<div style={{display: 'flex',
                flexDirection: 'row'}}>
    {imageSource && (
      <div id='profileImageOnPage'>
      <Image id='profileImage-imageOnPage' src={imageSource} />
    </div>
    )}

    <div style={{display: 'flex', flexDirection: 'column', marginLeft: '20px'}}>
    <div style={{color: 'white', fontSize: '18px',
                  fontWeight: 'bold'}}>
<span>{name}</span>
</div>
<div  style={{color: 'white', fontSize: '16px'}}> 
 <span>U.S. Justice</span>
 <br></br>



</div>
</div>


</div>
<div style={{height: '20px',}}></div>
<div dangerouslySetInnerHTML={{ __html: truncatedDescription }} /> 
<Button id='presidentViewMoreButton' onClick={() =>  setBioModal(true)}>  <Files size={20}></Files> Read More</Button>
<br></br>

<br></br>

<div  style={{color: 'white', fontSize: '18px',
                  fontWeight: 'bold'}} >Coming soon</div>
 

    

       <Modal id='fullBillModal'  size="xl" aria-labelledby="contained-modal-title-vcenter" show={showModal3} onHide={() => setShowModal3(false)}>
          <Modal.Header id='billModalHeader' closeButton><h3> {name}'s State of the Union Addresses</h3></Modal.Header>
          <div id='searchForm'>
        <Form className="text-center">
          <Form.Group className="d-flex justify-content-center align-items-center">
          <Form.Control
  variant="light"
  id="searchInput"
  placeholder="Search..."
  type="text"
  color="white"
  value={searchQueryModal}
  onChange={(e) => setSearchQueryModal(e.target.value)}
/>
            <Button id='ggButton' onClick={() => setIsSearchTriggered(true)} >
              <Search /> 
            </Button>
          </Form.Group>
        </Form>
      </div>
          <div id='fullBillFeed' >
       
          <p id='billText'>Currently Unavailable</p>

  </div>
        </Modal>

        <Modal id='fullBillModal'  size="xl" aria-labelledby="contained-modal-title-vcenter" show={showModal4} onHide={() => setShowModal4(false)}>
          <Modal.Header id='billModalHeader' closeButton><h3> {name}'s Laws</h3></Modal.Header>
          <div id='searchForm'>
        <Form className="text-center">
          <Form.Group className="d-flex justify-content-center align-items-center">
          <Form.Control
  variant="light"
  id="searchInput"
  placeholder="Search..."
  type="text"
  color="white"
  value={searchQueryModal}
  onChange={(e) => setSearchQueryModal(e.target.value)}
/>
            <Button id='ggButton' onClick={() => setIsSearchTriggered(true)} >
              <Search /> 
            </Button>
          </Form.Group>
        </Form>
      </div>
          <div id='fullBillFeed' >
       
          <p id='billText'>Currently Unavailable</p>

  </div>
        </Modal>

       <Modal id='fullBillModal'  size="xl" aria-labelledby="contained-modal-title-vcenter" show={showModal2} onHide={() => setShowModal2(false)}>
          <Modal.Header id='billModalHeader' closeButton><h3> {name}'s Presidential Documents</h3></Modal.Header>
          <div id='searchForm'>
        <Form className="text-center">
          <Form.Group className="d-flex justify-content-center align-items-center">
          <Form.Control
  variant="light"
  id="searchInput"
  placeholder="Search..."
  type="text"
  color="white"
  value={searchQueryModal}
  onChange={(e) => setSearchQueryModal(e.target.value)}
/>
            <Button id='ggButton' onClick={() => setIsSearchTriggered(true)} >
              <Search /> 
            </Button>
          </Form.Group>
        </Form>
      </div>
          <div id='fullBillFeed' >
       
          <p id='billText'>Currently Unavailable</p>

  </div>
        </Modal>


        <Modal id='fullBillModal'  size="xl" aria-labelledby="contained-modal-title-vcenter" show={showBioModal} onHide={() => setBioModal(false)}>
         <Modal.Header id='billModalHeader' closeButton><h3> {name}'s Biography</h3></Modal.Header>
       
         <div id='fullBillFeed' >
      
         <div dangerouslySetInnerHTML={{ __html: description }} /> 

 </div>

       </Modal>
            <br></br><br></br><br></br><br></br><br></br><br></br><Navbar id='navbarMobileBottom' fixed="bottom">    

          


       
<div id='changeBranchDropdownMobile'>

           <MobileOffCanvas/>

            </div>

</Navbar>
            



            <Modal id='fullBillModal'  size="xl" aria-labelledby="contained-modal-title-vcenter" show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Header id='billModalHeader' closeButton><h3> {name}'s Executive Orders</h3></Modal.Header>
          <div id='searchForm'>
        <Form className="text-center">
          <Form.Group className="d-flex justify-content-center align-items-center">
          <Form.Control
  variant="light"
  id="searchInput"
  placeholder="Search..."
  type="text"
  color="white"
  value={searchQueryModal}
  onChange={(e) => setSearchQueryModal(e.target.value)}
/>
            <Button id='ggButton' onClick={() => setIsSearchTriggered(true)} >
              <Search /> 
            </Button>
          </Form.Group>
        </Form>
      </div>
          <div id='fullBillFeed' >
       
          <p id='billText'>Currently Unavailable</p>

  </div>
        </Modal>



            <Modal id='fullBillModal' show={collectionModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" 
centered onHide={setCollectionModalIsOpen}>
                             
                              <Modal.Header id='billModalHeader' closeButton ><h3>{storedTitle}</h3></Modal.Header>

                              <Modal.Body id='descriptionModalBody'>
                                          {storedDescription}
                                          {storedDate}
                              <div id='fullBill'>
                         <Form id='noteModalForm'>
         <Form.Group id='noteModalForm'  className="mb-3" >
           <Form.Control id="noteModalFeedItem" placeholder="Add a note here" 
             type="text"
             as={"textarea"}
             rows={2}
             />
             <br></br>

         </Form.Group>
         </Form>

         <Modal.Footer id='addNoteModalFooter'> 

<Button variant="primary" id='noteSaveToCollectionButton' onClick={() => {saveNote(storedTitle, storedDetailsLink, storedDocLink, 
  storedDate, storedFeedName, storedBranchName, storedTopicName, storedDescription) ;setCollectionModalIsOpen(false) }} >
    Save To Collection
  </Button>
  </Modal.Footer> 

           </div>
           </Modal.Body>
                              </Modal>
         
            </div>
            </div>
            </MobileView>
        </>
    )
}

export default USJusticePage;