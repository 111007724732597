import App from "../../App";
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Image, ModalFooter } from 'react-bootstrap';
import { ArrowsAngleExpand, InfoCircle, FileText, PauseCircle, PlayCircle, FolderPlus, ChevronRight, Files, Folder, Pen, ChatLeftQuote, ShieldShaded, People } from 'react-bootstrap-icons'
import '../../App.css';
import 'bootstrap/dist/css/bootstrap.min.css'

import Axios from 'axios'
import { render } from '@testing-library/react';

import{ Speak, CreateFeedItem,
    CreateFeedItemCanadaDateFix,
    CreateFeedAPI,
    CreateFeedAPIDepartmentCanada} from '../../Functions'
import CanadaMobileTopNavbar from "../CanadaMobileTopNavbar";
import ExecutiveNewsCanada from "./ExecutiveNewsCanada";
import CanadaPMMedia from "./CanadaPMMedia";
import MobileOffCanvasCanada from "../../MobileOffCanvasCanada";
import GenerateFeed from "../../GenerateFeed";
import GenerateFeedDepartmentCanada from "../../GenerateFeedDepartmentCanada";



var collectionCounter = 0
var carouselInterval = 30000;
var mobileCarouselInterval = 60000;
const CustomModal = ({ isOpen, onClose, title, content }) => (
  <Modal id='fullBillModal' show={isOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={onClose}>
    <Modal.Header id='billModalHeader' closeButton><h3>{title}</h3></Modal.Header>
    {content}
  </Modal>
);

export const CanadaExecutiveBranchMobile = () =>   {
    render()
    
    {
        const [modalIsOpen, setModalIsOpen] = useState(false)
        const [fullBillModalIsOpen, setFullBillModalIsOpen] = useState(false)
        const [trendingBillsModalIsOpen, setTrendingBillsModalIsOpen] = useState(false)
        const [showBioModal, setBioModal] = useState(false);
        const [modalInfo, setModalInfo] = useState({
          isOpen: false,
          title: '',
          content: null
        });
      
        const openModal = (title, content) => {
          setModalInfo({
            isOpen: true,
            title,
            content
          });
        };
      
        const closeModal = () => {
          setModalInfo({
            isOpen: false,
            title: '',
            content: null
          });
        };

        localStorage.setItem('currentPage', 'canada')
    return(
       <>
    <CanadaMobileTopNavbar/>
  
  
  
  
  <div id='homebg'>
  <CustomModal isOpen={modalInfo.isOpen} onClose={closeModal} title={modalInfo.title} content={modalInfo.content} />


  <div id="feedDisplayDepartment" style={{ padding: '10px', borderRadius: '18px' }}>
  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div id="profileImageOnPageDepartment">
        <Image id="profileImage-imageOnPageDepartment" src="/canadaExecutive.png" />
      </div>
      <div style={{ marginLeft: '10px', color: 'white', fontSize: '18px', fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
        {/* <People /> */}
        <h5 id='branchHeadingMobile'>Executive</h5>
      </div>
    </div>

  </div> 
  <div id="billText" style={{ color: 'white', fontSize: '16px', marginTop: '10px' }}>
  The executive branch is composed of the Monarch (represented by the Governor General), the Prime Minister and Cabinet.
  <br></br>
  <Button id='presidentViewMoreButton' onClick={() =>  setBioModal(true)}>  <Files size={20}></Files> Read More</Button>
  </div>
</div>

<Modal id='fullBillModal'  size="xl" aria-labelledby="contained-modal-title-vcenter" show={showBioModal} onHide={() => setBioModal(false)}>
         <Modal.Header id='billModalHeader' closeButton><h3> The Executive Branch</h3></Modal.Header>
       
         <div id='fullBillFeed' >
      
         <p>The executive branch is composed of the Monarch (represented by the Governor General), the Prime Minister and Cabinet.</p>

<p>The Governor General is the Monarch’s representative in Canada. They have important constitutional responsibilities, including signing bills into law, summoning and dissolving Parliament, and swearing in the Prime Minister and Cabinet.</p>


<p>The Prime Minister is the Head of Government. They traditionally play many roles including political party leader, Cabinet leader and MP. The Prime Minister determines the government’s agenda, guides policy development and selects members of the Cabinet.</p>



<p>The Cabinet develops policies to govern the country and introduces bills to transform these policies into law. Most Cabinet ministers oversee a government department. They report to Parliament and explain their department’s activities. They also provide direction to their departments on the development and implementation of government policies.</p>

<br></br>
<Link onClick={(e) => {
     e.preventDefault();
     window.open("https://www.ourcommons.ca/Members/en/ministries", "_blank");
   }}>
     https://www.ourcommons.ca/Members/en/ministries
     </Link>
 </div>

       </Modal>

{/* <h5 id='branchHeadingMobile'> <People/> Executive  <NotificationButton notificationStorage="canadaExecutiveNews"/> </h5> */}
{/*onClick={() => setModalIsOpen(true)}*/}


<CustomModal isOpen={modalInfo.isOpen} onClose={closeModal} title={modalInfo.title} content={modalInfo.content} />


<Col>
  <h5 id='presidentialFeedHeadingMobile' onClick={() => setFullBillModalIsOpen(true)}>Prime Minister's Office</h5>

  <div id='executiveNews'></div>
                          
                          {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
                          {CreateFeedAPI('&schema=canada&table=prime_minister&order_by=created_at', 'executiveNews', 
                            'Prime Minister Office', 'Executive', 
                            'N/A', 'Doc', GenerateFeed)}



  <Modal id='mobileFullBillModal' show={fullBillModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setFullBillModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Prime Minister's Office</h3></Modal.Header>
<GenerateFeed url='&schema=canada&table=prime_minister&order_by=created_at' 
                            name='Prime Ministers Office' branch='Executive' topic='N/A' buttonName='Doc' />
</Modal>
</Col>

<Col>
  <Link  id='pageLink' to="/canada/revenue-agency">
    <h5 id='presidentialFeedHeadingMobile'>Canada Revenue Agency<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false} >
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Canada Revenue Agency',
              <GenerateFeedDepartmentCanada url='&table=agencies&order_by=created_at&filter_column=agency_name&filter_string=Canada%20Revenue%20Agency'
              name='Canada Revenue Agency' branch='Executive' topic='Economy' buttonName='Doc' />)}>News</h5>
          </Row>
          <div class='courtNewsMobile' id='cra'></div>
          {CreateFeedAPIDepartmentCanada('&table=agencies&order_by=created_at&filter_column=agency_name&filter_string=Canada%20Revenue%20Agency', 'cra',
            'Canada Revenue Agency', 'Executive',
            'Economy', 'Doc', GenerateFeedDepartmentCanada)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/canada/revenue-agency' >View all Canada Revenue Agency news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/canada/impact-assessment-agency">
    <h5 id='presidentialFeedHeadingMobile'>Impact Assessment Agency of Canada<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Impact Assessment Agency of Canada',
              <GenerateFeedDepartmentCanada url='&table=agencies&order_by=created_at&filter_column=agency_name&filter_string=Impact%20Assessment%20Agency%20of%20Canada'
              name='Impact Assessment Agency of Canada' branch='Executive' topic='Environment' buttonName='Doc' />)}>News</h5>
          </Row>
          <div class='courtNewsMobile' id='impactAssessment'></div>
          {CreateFeedAPIDepartmentCanada('&table=agencies&order_by=created_at&filter_column=agency_name&filter_string=Impact%20Assessment%20Agency%20of%20Canada', 'impactAssessment',
            'Impact Assessment Agency of Canada', 'Executive',
            'Environment', 'Doc', GenerateFeedDepartmentCanada)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/canada/impact-assessment-agency' >View all Impact Assessment Agency of Canada news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/canada/food-inspection-agency">
    <h5 id='presidentialFeedHeadingMobile'>Canadian Food Inspection Agency<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Canadian Food Inspection Agency',
              <GenerateFeedDepartmentCanada url='&table=agencies&order_by=created_at&filter_column=agency_name&filter_string=Canadian%20Food%20Inspection%20Agency'
              name='Canadian Food Inspection Agency' branch='Executive' topic='Health' buttonName='Doc' />)}>News</h5>
          </Row>
          <div class='courtNewsMobile' id='foodInspection'></div>
          {CreateFeedAPIDepartmentCanada('&table=agencies&order_by=created_at&filter_column=agency_name&filter_string=Canadian%20Food%20Inspection%20Agency', 'foodInspection',
            'Canadian Food Inspection Agency', 'Executive',
            'Health', 'Doc', GenerateFeedDepartmentCanada)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/canada/food-inspection-agency' >View all Canadian Food Inspection Agency news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/canada/atlantic-opportunities-agency">
    <h5 id='presidentialFeedHeadingMobile'>Atlantic Canada Opportunities Agency<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Atlantic Canada Opportunities Agency',
              <GenerateFeedDepartmentCanada url='&table=agencies&order_by=created_at&filter_column=agency_name&filter_string=Atlantic%20Canada%20Opportunities%20Agency'
              name='Atlantic Canada Opportunities Agency' branch='Executive' topic='Economy' buttonName='Doc' />)}>News</h5>
          </Row>
          <div class='courtNewsMobile' id='atlanticOpportunities'></div>
          {CreateFeedAPIDepartmentCanada('&table=agencies&order_by=created_at&filter_column=agency_name&filter_string=Atlantic%20Canada%20Opportunities%20Agency', 'atlanticOpportunities',
            'Atlantic Canada Opportunities Agency', 'Executive',
            'Economy', 'Doc', GenerateFeedDepartmentCanada)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/canada/atlantic-opportunities-agency' >View all Atlantic Canada Opportunities Agency news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/canada/feddev-ontario">
    <h5 id='presidentialFeedHeadingMobile'>Federal Economic Development Agency for Southern Ontario<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Federal Economic Development Agency for Southern Ontario',
              <GenerateFeedDepartmentCanada url='&table=agencies&order_by=created_at&filter_column=agency_name&filter_string=Federal%20Economic%20Development%20Agency%20for%20Southern%20Ontario'
              name='Federal Economic Development Agency for Southern Ontario' branch='Executive' topic='Economy' buttonName='Doc' />)}>News</h5>
          </Row>
          <div class='courtNewsMobile' id='feddevOntario'></div>
          {CreateFeedAPIDepartmentCanada('&table=agencies&order_by=created_at&filter_column=agency_name&filter_string=Federal%20Economic%20Development%20Agency%20for%20Southern%20Ontario', 'feddevOntario',
            'Federal Economic Development Agency for Southern Ontario', 'Executive',
            'Economy', 'Doc', GenerateFeedDepartmentCanada)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/canada/feddev-ontario' >View all Federal Economic Development Agency for Southern Ontario news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col> 
 
<Col>
  <Link  id='pageLink' to="/canada/border-services-agency">
    <h5 id='presidentialFeedHeadingMobile'>Canada Border Services Agency<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Canada Border Services Agency',
              <GenerateFeedDepartmentCanada url='&table=agencies&order_by=created_at&filter_column=agency_name&filter_string=Canada%20Border%20Services%20Agency'
              name='Canada Border Services Agency' branch='Executive' topic='Defense' buttonName='Doc' />)}>News</h5>
          </Row>
          <div class='courtNewsMobile' id='borderServices'></div>
          {CreateFeedAPIDepartmentCanada('&table=agencies&order_by=created_at&filter_column=agency_name&filter_string=Canada%20Border%20Services%20Agency', 'borderServices',
            'Canada Border Services Agency', 'Executive',
            'Defense', 'Doc', GenerateFeedDepartmentCanada)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/canada/border-services-agency' >View all Canada Border Services Agency news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/canada/transportation-agency">
    <h5 id='presidentialFeedHeadingMobile'>Canadian Transportation Agency<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Canadian Transportation Agency',
              <GenerateFeedDepartmentCanada url='&table=agencies&order_by=created_at&filter_column=agency_name&filter_string=Canadian%20Transportation%20Agency'
              name='Canadian Transportation Agency' branch='Executive' topic='Infrastructure' buttonName='Doc' />)}>News</h5>
          </Row>
          <div class='courtNewsMobile' id='transportation'></div>
          {CreateFeedAPIDepartmentCanada('&table=agencies&order_by=created_at&filter_column=agency_name&filter_string=Canadian%20Transportation%20Agency', 'transportation',
            'Canadian Transportation Agency', 'Executive',
            'Infrastructure', 'Doc', GenerateFeedDepartmentCanada)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/canada/transportation-agency' >View all Canadian Transportation Agency news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/canada/northern-economic-development">
    <h5 id='presidentialFeedHeadingMobile'>Canadian Northern Economic Development Agency<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Canadian Northern Economic Development Agency',
              <GenerateFeedDepartmentCanada url='&table=agencies&order_by=created_at&filter_column=agency_name&filter_string=Canadian%20Northern%20Economic%20Development%20Agency'
              name='Canadian Northern Economic Development Agency' branch='Executive' topic='Economy' buttonName='Doc' />)}>News</h5>
          </Row>
          <div class='courtNewsMobile' id='northernEconomicDevelopment'></div>
          {CreateFeedAPIDepartmentCanada('&table=agencies&order_by=created_at&filter_column=agency_name&filter_string=Canadian%20Northern%20Economic%20Development%20Agency', 'northernEconomicDevelopment',
            'Canadian Northern Economic Development Agency', 'Executive',
            'Economy', 'Doc', GenerateFeedDepartmentCanada)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/canada/northern-economic-development' >View all Canadian Northern Economic Development Agency news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/canada/financial-consumer-agency">
    <h5 id='presidentialFeedHeadingMobile'>Financial Consumer Agency of Canada<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Financial Consumer Agency of Canada',
              <GenerateFeedDepartmentCanada url='&table=agencies&order_by=created_at&filter_column=agency_name&filter_string=Financial%20Consumer%20Agency%20of%20Canada'
              name='Financial Consumer Agency of Canada' branch='Executive' topic='Economy' buttonName='Doc' />)}>News</h5>
          </Row>
          <div class='courtNewsMobile' id='financialConsumer'></div>
          {CreateFeedAPIDepartmentCanada('&table=agencies&order_by=created_at&filter_column=agency_name&filter_string=Financial%20Consumer%20Agency%20of%20Canada', 'financialConsumer',
            'Financial Consumer Agency of Canada', 'Executive',
            'Economy', 'Doc', GenerateFeedDepartmentCanada)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/canada/financial-consumer-agency' >View all Financial Consumer Agency of Canada news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/canada/public-health-agency">
    <h5 id='presidentialFeedHeadingMobile'>Public Health Agency of Canada<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Public Health Agency of Canada',
              <GenerateFeedDepartmentCanada url='&table=agencies&order_by=created_at&filter_column=agency_name&filter_string=Public%20Health%20Agency%20of%20Canada'
              name='Public Health Agency of Canada' branch='Executive' topic='Health' buttonName='Doc' />)}>News</h5>
          </Row>
          <div class='courtNewsMobile' id='publicHealth'></div>
          {CreateFeedAPIDepartmentCanada('&table=agencies&order_by=created_at&filter_column=agency_name&filter_string=Public%20Health%20Agency%20of%20Canada', 'publicHealth',
            'Public Health Agency of Canada', 'Executive',
            'Health', 'Doc', GenerateFeedDepartmentCanada)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/canada/public-health-agency' >View all Public Health Agency of Canada news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/canada/space-agency">
    <h5 id='presidentialFeedHeadingMobile'>Canadian Space Agency<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Canadian Space Agency',
              <GenerateFeedDepartmentCanada url='&table=agencies&order_by=created_at&filter_column=agency_name&filter_string=Canadian%20Space%20Agency'
              name='Canadian Space Agency' branch='Executive' topic='Science & Tech' buttonName='Doc' />)}>News</h5>
          </Row>
          <div class='courtNewsMobile' id='spaceAgency'></div>
          {CreateFeedAPIDepartmentCanada('&table=agencies&order_by=created_at&filter_column=agency_name&filter_string=Canadian%20Space%20Agency', 'spaceAgency',
            'Canadian Space Agency', 'Executive',
            'Science & Tech', 'Doc', GenerateFeedDepartmentCanada)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/canada/space-agency' >View all Canadian Space Agency news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Navbar id='navbarMobileBottom' fixed="bottom">     
<div id='changeBranchDropdownMobile'>

                  <MobileOffCanvasCanada/>
        
              
            </div>

</Navbar>
</div>
</>
)
}}
export default CanadaExecutiveBranchMobile;


