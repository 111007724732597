import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link, Switch, NavLink,  } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment,  } from 'react';
import { Navbar, Nav, Container, Button, Modal,  Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Tooltip, OverlayTrigger, Image, ModalFooter } from 'react-bootstrap';
import { ArrowsAngleExpand, ArrowsFullscreen, List, InfoCircle, FileText, FileMedicalFill, Apple,  Offcanvas, ReceiptCutoff,  ShieldShaded, Globe, Tree, CircleFill, Pen, People, MicFill, ChatSquareQuote, ChatLeftQuote, Coin, BlockquoteRight, Collection, Grid, ThreeDots, Stack, Files, FolderPlus, FolderCheck, Folder, CaretRight, ChevronRight, ChevronCompactRight, CaretRightFill, ArrowRightShort,  ArrowRightCircle, PlayCircle, PauseCircle, GeoAlt, HeartFill, Bricks, Lightbulb } from 'react-bootstrap-icons'
import { BrowserView, MobileView } from 'react-device-detect';
import '../App.css';

import 'bootstrap/dist/css/bootstrap.min.css'



import { render } from '@testing-library/react';

import
 {supremeCourtSearch, whiteHouseSearch, billFeedModal, 
  createHouseFloorToday, createSenateFloorToday, createTrendingBills,
  createNationalAeronauticsAndSpaceAdministration, 
  createEnvironmentalProtectionAgency, createDepartmentOfHomelandSecurity,
  createDepartmentOfVeteransAffairs, createDepartmentOfEducation,
  createDepartmentOfHousingAndUrbanDevelopment, 
  createDepartmentOfHealthAndHumanServices, createDepartmentOfLabor
, createDepartmentOfCommerce, createDepartmentOfAgriculture,
createDepartmentOfTheInterior, createDepartmentOfJustice,
createDepartmentOfTransportation, createDepartmentOfDefense,
createDepartmentOfTreasury, createDepartmentOfEnergy,
createDepartmentOfState, createBillsEnrolledMobile,
createBillsEnrolled, createNewLaws, createGAOReports,
createExecutiveOrders, createPresidentialProclamations, createCongressionalHearings,
createCongressionalBudgetOfficeMobile, createCongressionalBudgetOffice,
createHouseCommitteeOversightAndReform,
createHouseCommitteeOnNaturalResources,
createHouseCommitteeOnTheJudiciary,
createHouseCommitteeOnHouseAdministration,
createHouseCommitteeOnHomelandSecurity,
createHouseCommitteeOnForeignAffairs,
createHouseCommitteeOnFinancialServices,
createHouseCommitteeOnEthics,
createHouseCommitteeOnEnergyAndCommerce,
createHouseCommitteeOnEducationAndLabor,
createHouseCommitteeOnTheBudget,
createHouseCommitteeOnArmedServices,
createHouseCommitteeOnAppropriations,
createHouseCommitteeOnAgriculture,
createReports,
createBillFeedTwoMobile,
createBillFeedTwo,
createBillFeed, createWhiteHouseFeedMobile
, createWhiteHouseFeed, createWhiteHouseDocuments, 
createBillsPresentedFeed, createCourtAppealsMobile,
createCourtAppeals, createUSCourtNewsFeedMobile,
createUSCourtNewsFeed, saveToCollection,
createFullBillFeed, ListItem, Speak, feedText, variableTest, myMethod, myMethod2,
createEconomicIndicators, 
createSecuritiesAndExchangeCommissionPressReleases, 
createSecuritiesAndExchangeCommissionSpeechesAndStatements,
createDepartmentOfCommerceMobile, createNationWeatherServices, createEnvironmentalProtectionAgencyMobile,
createNationalScienceFoundation, createHouseCommitteeOnIntelligence, createUNTopStories, createCDCNewsroom,
createCDCOutbreaks, createCDCTravelNotices, createFoodRecalls, createFoodSafety, createElectionAssistancCommissioneNews, 
createFederalElectionCommissionNews, createBillStatus, CreateFeedItem, CreateFeedItemBillUpdates,
CreateFeedItemWithDocButton, CreateFeedItemWithLawButton, CreateFeedItemPresidentialDocuments, CreateFeedItemExecutiveOrders,
CreateFeedItemCourtAppeals, CreateFeedItemEconomicIndicators, CreateFeedItemGAOReports, 
CreateFeedItemCongressionalReports, CreateFeedItemSupremeCourtArguments, CreateFeedItemTest, 
CreateFeedItemWithDocumentFlip, CreateFeedItemWithDocButtonDateFix, CreateFeedItemWithLawButtonFlip, CreateFeedItemCanada, CreateFeedItemCanadaDateFix, CreateFeedItemHouseOfCommons, CreateFeedAPIRecentCacheCanada, CreateFeedAPI  } from '../Functions'
import MobileTopNavbar from '../MobileTopNavbar';
import BillUpdatesCanada from './Legislative/BillUpdatesCanada';
import ExecutiveNewsCanada from './Executive/ExecutiveNewsCanada';
import JudicialAppointmentsCanada from './Judicial/JudicialAppointmentsCanada';
import CanadaMobileTopNavbar from './CanadaMobileTopNavbar';

import MobileOffCanvas from '../MobileOffCanvas';
import CanadaSenate from './Legislative/CanadaSenate';
import SupremeCourtNews from './Judicial/SupremeCourtNews';
import SupremeCourtJudgements from './Judicial/SupremeCourtJudgements';
import SupremeCourtReports from './Judicial/SupremeCourtReports';
import CanadaPMMedia from './Executive/CanadaPMMedia';
import HouseOfCommons from './Legislative/HouseOfCommons';
import StandingCommitteeOnVeteransAffairs from './Legislative/StandingCommitteeOnVeteransAffairs';
import StandingCommitteeOnFinance from './Legislative/StandingCommitteeOnFinance';
import StandingCommitteeOnInternationalTrade from './Legislative/StandingCommitteeOnInternationalTrade';
import StandingCommitteeOnAgricultureAndFood from './Legislative/StandingCommitteeOnAgricultureAndFood';
import StandingCommitteeOnNaturalResources from './Legislative/StandingCommitteeOnNaturalResources';
import StandingCommitteeOnEnvironment from './Legislative/StandingCommitteeOnEnvironment';
import StandingCommitteeOnNationalDefence from './Legislative/StandingCommitteeOnNationalDefence';
import SpecialCommitteeChinaRelation from './Legislative/SpecialCommitteeChinaRelation';
import StandingCommitteeOnIndustryAndTech from './Legislative/StandingCommitteeOnIndustryAndTech';
import MobileOffCanvasCanada from '../MobileOffCanvasCanada';
import GenerateFeed from '../GenerateFeed';
import GenerateFeedRecentCanada from '../GenerateFeedRecentCanada';





const mobileCarouselInterval = 60000;










  











  const setHomeCountryAndReload = (homeCountry) => {
    localStorage.setItem('homeCountry', homeCountry);
    //console.log(localStorage.getItem('homeCountry'))
    window.open('/'+localStorage.getItem('homeCountry'), "_self")
  };

  
const CanadaHomeMobile = () => {
  
  const [homeCountry, setHomeCountry] = useState('');

  useEffect(() => {
  
    const storedHomeCountry = localStorage.getItem('homeCountry');
    setHomeCountry(storedHomeCountry);
  }, []);

  render()
  
  {

//Off Canvas
const [showOffCanvas, setShowOffCanvas] = useState(false);

const handleClose = () => setShow(false);
const handleShow = () => setShow(true);

    const [show, setShow] = useState(true);
  
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [fullscreen, setFullScreen] = useState(true);
  
    
    const [fullBillModalIsOpen, setFullBillModalIsOpen] = useState(false)
  
    const [reportModalIsOpen, setReportModalIsOpen] = useState(false)
  
  
    const [whiteHouseFeedModalIsOpen, setWhiteHouseFeedModalIsOpen] = useState(false)
    const [whiteHouseDocsModalIsOpen, setWhiteHouseDocsModalIsOpen] = useState(false)
    const [statesModalIsOpen, setStatesModalIsOpen] = useState(false)
  
  
    const [courtNewsModalIsOpen, setCourtNewsModalIsOpen] = useState(false)
    const [courtModalIsOpen, setCourtModalIsOpen] = useState(false)
    const [courtOpinionsModalIsOpen, setCourtOpinionsModalIsOpen] = useState(false)
    const [courtAppealsModalIsOpen, setCourtAppealsModalIsOpen] = useState(false)
    

    const [newLawsModalIsOpen, setNewLawsModalIsOpen] = useState(false)
  
    const [billsEnrolledModalIsOpen, setBillsEnrolledModalIsOpen] = useState(false)
  
    const [legislativePageBillsEnrolledModalIsOpen, setLegislativePageBillsEnrolledModalIsOpen] = useState(false)
  
  
    const [departmentOfStateModalIsOpen, setDepartmentOfStateModalIsOpen] = useState(false)
    const [departmentOfTreasuryModalIsOpen, setDepartmentOfTreasuryModalIsOpen] = useState(false)
    const [departmentOfDefenseModalIsOpen, setDepartmentOfDefenseModalIsOpen] = useState(false)
    const [departmentOfJusticeModalIsOpen, setDepartmentOfJusticeModalIsOpen] = useState(false)
    const [departmentOfTheInteriorModalIsOpen, setDepartmentOfTheInteriorModalIsOpen] = useState(false)
    const [departmentOfAgricultureModalIsOpen, setDepartmentOfAgricultureModalIsOpen] = useState(false)
    const [departmentOfCommerceModalIsOpen, setDepartmentOfCommerceModalIsOpen] = useState(false)
    const [departmentOfLaborModalIsOpen, setDepartmentOfLaborModalIsOpen] = useState(false)
    const [departmentOfHealthAndHumanServicesModalIsOpen, setDepartmentOfHealthAndHumanServicesModalIsOpen] = useState(false)
    const [departmentOfHousingAndUrbanDevelopmentModalIsOpen, setDepartmentOfHousingAndUrbanDevelopmentModalIsOpen] = useState(false)
    const [departmentOfTransportationModalIsOpen, setDepartmentOfTransportationModalIsOpen] = useState(false)
    const [departmentOfEnergyModalIsOpen, setDepartmentOfEnergyModalIsOpen] = useState(false)
    const [departmentOfEducationModalIsOpen, setDepartmentOfEducationModalIsOpen] = useState(false)
    const [departmentOfVeteransAffairsModalIsOpen, setDepartmentOfVeteransAffairsModalIsOpen] = useState(false)
    const [departmentOfHomelandSecurityModalIsOpen, setDepartmentOfHomelandSecurityModalIsOpen] = useState(false)
    const [epaModalIsOpen, setEPAModalIsOpen] = useState(false)
    const [nasaModalIsOpen, setNASAModalIsOpen] = useState(false)
    const [trendingBillsModalIsOpen, setTrendingBillsModalIsOpen] = useState(false)
    const [trendingBillsOptionOne, setTrendingBillsOptionOneModalIsOpen ] = useState(false)
    const [trendingBillsOptionTwo, setTrendingBillsOptionTwoModalIsOpen ] = useState(false)
    const [trendingBillsOptionThree, setTrendingBillsOptionThreeModalIsOpen ] = useState(false)
    const [trendingBillsOptionFour, setTrendingBillsOptionFourModalIsOpen ] = useState(false)
    const [trendingBillsOptionFive, setTrendingBillsOptionFiveModalIsOpen ] = useState(false)
    const [trendingBillsOptionSix, setTrendingBillsOptionSixModalIsOpen ] = useState(false)
    const [trendingBillsOptionSeven, setTrendingBillsOptionSevenModalIsOpen ] = useState(false)
    const [trendingBillsOptionEight, setTrendingBillsOptionEightModalIsOpen ] = useState(false)
    const [trendingBillsOptionNine, setTrendingBillsOptionNineModalIsOpen ] = useState(false)
    const [trendingBillsOptionTen, setTrendingBillsOptionTenModalIsOpen ] = useState(false)
    const [trendingBillsOptionEleven, setTrendingBillsOptionElevenModalIsOpen ] = useState(false)
    const [trendingBillsOptionTweleve, setTrendingBillsOptionTweleveModalIsOpen ] = useState(false)
    const [trendingBillsOptionThirteen, setTrendingBillsOptionThirteenModalIsOpen ] = useState(false)
    const [goaReportsModalIsOpen, setGAOReportsModalIsOpen] = useState(false)
    const [cdcNewsroomModalIsOpen, setCDCNewsroomModalIsOpen] = useState(false)
    const [outbreaksModalIsOpen, setOutbreaksModalIsOpen] = useState(false)
    const [travelNoticesModalIsOpen, setTravelNoticesModalIsOpen] = useState(false)
    
    const [foodRecallsModalIsOpen, setFoodRecallsModalIsOpen] = useState(false)
    const [foodSafetyModalIsOpen, setFoodSafetyModalIsOpen] = useState(false)
    const [medWatchModalIsOpen, setMedWatchModalIsOpen] = useState(false)
    const [healthyLivingFeaturesModalIsOpen, setHealthyLivingFeaturesModalIsOpen] = useState(false)
    const [preventingChronicDiseaseModalIsOpen, setPreventingChronicDiseaseModalIsOpen] = useState(false)
    const [covid19ModalIsOpen, setCovid19ModalIsOpen] = useState(false)
    const [novelCoronavirusModalIsOpen, setNovelCoronavirusModalIsOpen] = useState(false)
    const [vaccineUpdatesModalIsOpen, setVaccineUpdatesModalIsOpen] = useState(false)
    const [seasonalFluModalIsOpen, setSeasonalFluModalIsOpen] = useState(false)
      const [houseComitteeOnAgricultureModalIsOpen, setHouseComitteeOnAgricultureModalIsOpen] = useState(false)
      const [houseCommitteeOnAppropriationsModalIsOpen, setHouseCommitteeOnAppropriationsModalIsOpen] = useState(false)
      const [houseCommitteeOnArmedServicesModalIsOpen, setHouseCommitteeOnOnArmedServicesModalIsOpen] = useState(false)
      const [houseCommitteeOnTheBudgetModalIsOpen, setHouseCommitteeOnTheBudgetModalIsOpen] = useState(false)
      const [houseCommitteeOnEnergyAndCommerceModalIsOpen, setHouseCommitteeOnEnergyAndCommerceModalIsOpen] = useState(false)
      const [houseCommitteeOnEducationAndLaborModalIsOpen, setHouseCommitteeOnEducationAndLaborModalIsOpen] = useState(false)
      const [houseCommitteeOnEthicsModalIsOpen, setHouseCommitteeOnEthicsModalIsOpen] = useState(false)
      const [houseCommitteeOnFinancialServicesModalIsOpen, setHouseCommitteeOnFinancialServicesModalIsOpen] = useState(false)
      const [houseCommitteeOnForeignAffairsModalIsOpen, setHouseCommitteeOnForeignAffairsModalIsOpen] = useState(false)
      const [houseCommitteeOnHomelandSecurityModalIsOpen, setHouseCommitteeOnHomelandSecurityModalIsOpen] = useState(false)
      const [houseCommitteeOnHouseAdministrationModalIsOpen, setHouseCommitteeOnHouseAdministrationModalIsOpen] = useState(false)
      const [houseCommitteeOnTheJudiciaryModalIsOpen, setHouseCommitteeOnTheJudiciaryModalIsOpen] = useState(false)
      const [houseCommitteeOnNaturalResourcesModalIsOpen, setHouseCommitteeOnNaturalResourcesModalIsOpen] = useState(false)
      const [houseCommitteeOnOversightAndReformModalIsOpen, setHouseCommitteeOnOversightAndReformModalIsOpen] = useState(false)
      const [congressionalBudgetOfficeModalIsOpen, setCongressionalBudgetOfficeModalIsOpen] = useState(false)
      const [presidentialProclamationsModalIsOpen, setPresidentialProclamationsModalIsOpen] = useState(false)
      const [economicIndicatorsModalIsOpen, setEconomicIndicatorsModalIsOpen] = useState(false)
      const [executiveOrdersModalIsOpen, setExecutiveOrdersModalIsOpen] = useState(false)
      const [secPressModalIsOpen, setSECPressModalIsOpen] = useState(false)
      const [secSAndSModalIsOpen, setSECSAndSModalIsOpen] = useState(false)
      const [unTopStoriesModalIsOpen, setUNTopStoriesModalIsOpen] = useState(false)
      const [nsfModalIsOpen, setNSFModalIsOpen] = useState(false)
  
      const [recentsModalIsOpen, setRecentsModalIsOpen] = useState(false)
    
  
      const [congressionalHearingsModalIsOpen, setCongressionalHearingsModalIsOpen] = useState(false)
  
      const [houseFloorModalIsOpen, setHouseFloorModalIsOpen] = useState(false)
  
  
  
    const [collectionsModalIsOpen, setCollectionsModalIsOpen] = useState(false)
    const [aboutModalIsOpen, setAboutModalIsOpen] = useState(false)
    const [houseCommitteeOnIntelligenceModalIsOpen, setHouseCommitteeOnIntelligenceModalIsOpen] = useState(false)
  
    const [fecModalIsOpen, setFECModalIsOpen] = useState(false)
    const [eacModalIsOpen, setEACModalIsOpen] = useState(false)
  
    const [declarationModalIsOpen, setDeclarationModalIsOpen] = useState(false)
    const [constitutionModalIsOpen, setConstitutionModalIsOpen] = useState(false)
    const [billOfRightsnModalIsOpen, setBillOfRightsModalIsOpen] = useState(false)
  
    const [alertModalIsOpen, setAlertModalIsOpen] = useState(true)
    const [billStatusModalIsOpen, setBillStatusModalIsOpen] = useState(false)
  var branch = "all";
  const nullEntry = [];

  const [notifications, setNotifications] = useState(nullEntry);


  localStorage.setItem('currentPage', 'canada')
  return(
     <>

<div id='homebg'>
<CanadaMobileTopNavbar/>
 
<Col>

<h5 id='recentsHeading' onClick={() => setRecentsModalIsOpen(true)}>Recents</h5>

<div class='courtNewsMobile' id='recent'></div> 
{CreateFeedAPIRecentCacheCanada('https://api.govglance.org/posts/recent/schema?limit=1&skip=0&schema=canada', 'recent', 
                'Canada Recent', 'N/A', 
                'N/A', 'Doc', GenerateFeed)}
        <Modal id='fullBillModal' show={recentsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setRecentsModalIsOpen(false)}>
          <Modal.Header id='billModalHeader' closeButton><h3>Recents</h3></Modal.Header>
         <GenerateFeedRecentCanada/>
        </Modal>

</Col>
     {/*   <DisplayHomeProvince/>*/} 
     <br></br>

{/*Legislative Mobile Carousel*/}
    <h5 id='branchHeadingMobile' onClick={() => window.open('/canada/legislative', "_self")}> <Pen></Pen> Legislative<ChevronRight id='clickChevron'></ChevronRight></h5>
  {/*onClick={() => setModalIsOpen(true)}*/}

<Carousel controls={false} >
{/* */}

  <Carousel.Item interval={mobileCarouselInterval}>

    <div id='legislativeConMobile'>

  <Col>
      <Row id = 'mobileFeedHeadingRow'>
        <h5 id='billFeedHeadingMobileHomeHome' onClick={() => setBillsEnrolledModalIsOpen(true)}>Bill Updates</h5></Row>
      <div class='courtNewsMobile' id='billUpdates'></div>
             {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
             {/*                                                                                       Will later be BillUpdates */}
          
             {CreateFeedAPI('&skip=0&schema=canada&table=bills&order_by=created_at', 'billUpdates', 
                            'Bill Updates', 'Legislative', 
                            'N/A', 'Bill', GenerateFeed)}
                            
             <Modal id='mobileFullBillModal' show={billsEnrolledModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setBillsEnrolledModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Bill Updates</h3></Modal.Header>
<GenerateFeed url='&skip=0&schema=canada&table=bills&order_by=created_at' 
                            name='Bill Updates' branch='Legislative' topic='N/A' buttonName='Bill' />
</Modal>
       
        </Col>


        
      
        </div>
       

    






</Carousel.Item>

<Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile'>

<Col>
  <Row id = 'mobileFeedHeadingRow'><h5 id='billFeedHeadingMobileHomeHome' onClick={() => setDepartmentOfStateModalIsOpen(true)}>Senate News</h5></Row>
  <div class='courtNewsMobile' id='senate'></div>
         {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
         {/*                                                                                       Will later be BillUpdates */}
      
         {CreateFeedAPI('&schema=canada&table=senate_news&order_by=created_at', 'senate', 
                            'Senate', 'Executive', 
                            'N/A', 'Doc', GenerateFeed)}    
                              <GenerateFeed url='&schema=canada&table=senate_news&order_by=created_at' 
                            name='Senate' branch='Legislative' topic='N/A' buttonName='Doc' />
         <Modal id='mobileFullBillModal' show={departmentOfStateModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfStateModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Senate News</h3></Modal.Header>
<GenerateFeed url='&schema=canada&table=senate_news&order_by=created_at' 
                            name='Senate' branch='Legislative' topic='N/A' buttonName='Doc' />
</Modal>
    </Col>


    
  
    </div>
   








</Carousel.Item>

<Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile'>

<Col>
  <Row id = 'mobileFeedHeadingRow'><h5 id='billFeedHeadingMobileHomeHome'onClick={() => setFoodRecallsModalIsOpen(true)}>House of Commons News</h5></Row>
  <div class='courtNewsMobile' id='hOC'></div>
                        
                        {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
  {CreateFeedItemHouseOfCommons('https://gov-glance-canada.nyc3.digitaloceanspaces.com/legislative-xml/house_of_commons_newsroom/house_of_commons_newsroom.xml', 'hOC', 'House of Commons News', 'Legislative', 'N/A', HouseOfCommons)}

  <Modal id='mobileFullBillModal' show={foodRecallsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setFoodRecallsModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>House of Commons News</h3></Modal.Header>
<HouseOfCommons/>
</Modal>
    </Col>


    
  
    </div>
   








</Carousel.Item>
<Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile'>

<Col>
  <Row id = 'mobileFeedHeadingRow'><h5 id='billFeedHeadingMobileHomeHome' onClick={() => setCDCNewsroomModalIsOpen(true)}>Standing Committee on Veterans' Affairs </h5></Row>
  <div class='courtNewsMobile' id='committee1'></div>
  {CreateFeedItem('https://gov-glance-canada.nyc3.digitaloceanspaces.com/legislative-xml/committees/acva.xml', 'committee1', 'Standing Committee On Veterans Affairs', 'Legislative', 'N/A', StandingCommitteeOnVeteransAffairs)}

<Modal id='mobileFullBillModal' show={cdcNewsroomModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCDCNewsroomModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Standing Committee on Veterans' Affairs</h3></Modal.Header>
<StandingCommitteeOnVeteransAffairs/>
</Modal>
    </Col>


    
  
    </div>
   
   








</Carousel.Item>


<Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile'>

<Col>
<div id='viewFullPageButton'>
<Button id='viewFullLegislativePageButtonMobile' size='lg' href='/canada/legislative' >View all Legislative news</Button>
</div>
</Col>

</div>



</Carousel.Item>

</Carousel>
  {/* Executive Mobile Carousel*/}
  <h5 id='branchHeadingMobile' onClick={() => window.open('/canada/executive', "_self")}><People></People> Executive<ChevronRight id='clickChevron'></ChevronRight></h5>
<Carousel controls={false}>
  <Carousel.Item interval={mobileCarouselInterval}>

    <div id='legislativeConMobile' >
      
        
        <Col>



        <Row id = 'mobileFeedHeadingRow'><h5 id='feedHeadingMobileHome' onClick={() => setFullBillModalIsOpen(true)}>Prime Minister's Office</h5></Row>


        <div class='courtNewsMobile' id='executiveNews'></div>
                          
        {CreateFeedAPI('&schema=canada&table=prime_minister&order_by=created_at', 'executiveNews', 
                            'Prime Minister Office', 'Executive', 
                            'N/A', 'Doc', GenerateFeed)}
                          
    <Modal id='mobileFullBillModal' show={fullBillModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setFullBillModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Prime Minister's Office</h3></Modal.Header>
<GenerateFeed url='&schema=canada&table=prime_minister&order_by=created_at' 
                            name='Prime Ministers Office' branch='Executive' topic='N/A' buttonName='Doc' />
</Modal>  
        
          </Col>

      
      </div>
      </Carousel.Item>



      <Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile' >
  
    
    <Col>



    <Row id = 'mobileFeedHeadingRow'><h5 id='billFeedHeadingMobileHomeHome' onClick={() => setTrendingBillsModalIsOpen(true)}>Prime Minister's Office Media</h5></Row>

<div id='pmMedia'></div>
                        
                        {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
  {CreateFeedItemCanadaDateFix('https://www.pm.gc.ca/en/media.rss', 'pmMedia', 'Prime Ministers Office Media', 'Executive', 'N/A',CanadaPMMedia)}





<Modal id='mobileFullBillModal' show={trendingBillsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Prime Minister's Office Media</h3></Modal.Header>
<CanadaPMMedia/>
</Modal>

    
      </Col>

  
  </div>
  </Carousel.Item>


      <Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile'>

<Col>
<div id='viewFullPageButton'>
<Button id='viewFullLegislativePageButtonMobile' size='lg' href='/canada/executive' >View all Executive news</Button>
</div>
</Col>

</div>



</Carousel.Item>

      </Carousel >
  

{/* Judicial Mobile Carousel*/}
  <h5 id='branchHeadingMobile' onClick={() => window.open('/canada/judicial', "_self")}><ShieldShaded></ShieldShaded>   Judicial<ChevronRight id='clickChevron'></ChevronRight></h5>
<Carousel controls={false}>

<Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile'>

    <Col>
    <Row id = 'mobileFeedHeadingRow'> <h5 id='billFeedHeadingMobileHomeHome' onClick={() => setTrendingBillsOptionOneModalIsOpen(true)}>Supreme Court News</h5></Row>

<div id='courtNews'></div>
            
            {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
{CreateFeedItemCanada('https://decisions.scc-csc.ca/scc-csc/news/en/rss.do', 'courtNews', 'Supreme Court News', 'Legislative', 'N/A', SupremeCourtNews)}






<Modal id='mobileFullBillModal' show={trendingBillsOptionOne} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsOptionOneModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Supreme Court News</h3></Modal.Header>
<SupremeCourtNews/>
</Modal>
</Col>

                         

   </div>

      
        
       

     
      
      </Carousel.Item>


  <Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile'>

    <Col>
            <Row id = 'mobileFeedHeadingRow'><h5 id='feedHeadingMobileHome' onClick={() => setNSFModalIsOpen(true)}>Judicial Appointments</h5></Row>

          
            <div class='courtNewsMobile' id='judicialApts'></div>
              
              {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
{CreateFeedItem('https://gov-glance-canada.nyc3.digitaloceanspaces.com/judicial-xml/judicial_appointments.xml', 'judicialApts', 'Judicial Appointments', 'Legislative', 'N/A', JudicialAppointmentsCanada)}

   

                         
<Modal id='mobileFullBillModal' show={nsfModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setNSFModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Judicial Appointments</h3></Modal.Header>
<JudicialAppointmentsCanada/>
</Modal>
          </Col>

      
        
       

     
      </div>
      </Carousel.Item>


      <Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile'>

    <Col>
    <Row id = 'mobileFeedHeadingRow'> <h5 id='billFeedHeadingMobileHomeHome' onClick={() => setTrendingBillsOptionTwoModalIsOpen(true)}>Supreme Court Judgements</h5></Row>
          
          <div id='courtJudgements'></div>
                      
                      {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
        {CreateFeedItemCanada('https://decisions.scc-csc.ca/scc-csc/scc-csc/en/rss.do', 'courtJudgements', 'Supreme Court Judgements', 'Legislative', 'N/A', SupremeCourtJudgements)}
        
        
         
        
        
        
          <Modal id='mobileFullBillModal' show={trendingBillsOptionTwo} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsOptionTwoModalIsOpen(false)}>
        <Modal.Header id='mobileBillModalHeader' closeButton><h3>Supreme Court Judgements</h3></Modal.Header>
        <SupremeCourtJudgements/>
        </Modal>
        </Col>






                         

   </div>

      
        
       

     
      
      </Carousel.Item>


      <Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile'>

    <Col>
    <Row id = 'mobileFeedHeadingRow'> <h5 id='billFeedHeadingMobileHomeHome' onClick={() => setTrendingBillsOptionThreeModalIsOpen(true)}>Supreme Court Reports</h5></Row>

<div id='courtReports'></div>
            
            {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
{CreateFeedItemCanada('https://decisions.scc-csc.ca/scc-csc/scr/en/rss.do', 'courtReports', 'Supreme Court Reports', 'Legislative', 'N/A', SupremeCourtReports)}

<Modal id='mobileFullBillModal' show={trendingBillsOptionThree} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsOptionThreeModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Supreme Court Reports</h3></Modal.Header>
<SupremeCourtReports/>
</Modal>
</Col>






                         

   </div>

      
        
       

     
      
      </Carousel.Item>

      


      <Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile'>

<Col>
<div id='viewFullPageButton'>
<Button id='viewFullLegislativePageButtonMobile' size='lg' href='/canada/judicial' >View all Judicial news</Button>
</div>
</Col>

</div>



</Carousel.Item>
</Carousel>


{/* Economy */}
<h5 id='branchHeadingMobile' onClick={() => window.open('/canada/economy', "_self")}> <Coin></Coin>    Economy<ChevronRight id='clickChevron'></ChevronRight></h5>
<Carousel controls={false}>

      
      <Carousel.Item interval={mobileCarouselInterval}>

      <div id='legislativeConMobile'>

<Col>
<Row id = 'mobileFeedHeadingRow'> <h5 id='billFeedHeadingMobileHomeHome' onClick={() => setDepartmentOfTreasuryModalIsOpen(true)} >Standing Committee on Finance</h5></Row>

<div id='committee7'></div>
{CreateFeedItem('https://gov-glance-canada.nyc3.digitaloceanspaces.com/legislative-xml/committees/fina.xml', 'committee7', 'Standing Committee on Finance', 'Legislative', 'N/A', StandingCommitteeOnFinance)}


             <Modal id='fullBillModal' show={departmentOfTreasuryModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfTreasuryModalIsOpen(false)}>
                     <Modal.Header id='billModalHeader' closeButton><h3> Standing Committee on Finance</h3></Modal.Header>
                   <StandingCommitteeOnFinance/>
                   </Modal>
</Col>

   </div>

      </Carousel.Item>

      <Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile'>

    <Col>
    <Row id = 'mobileFeedHeadingRow'> <h5 id='billFeedHeadingMobileHomeHome' onClick={() => setDepartmentOfDefenseModalIsOpen(true)}>Standing Committee on International Trade</h5></Row>

<div id='committee4'></div>
{CreateFeedItem('https://gov-glance-canada.nyc3.digitaloceanspaces.com/legislative-xml/committees/ciit.xml', 'committee4', 'Standing Committee on International Trade', 'Legislative', 'N/A', StandingCommitteeOnInternationalTrade)}

<Modal id='fullBillModal' show={departmentOfDefenseModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfDefenseModalIsOpen(false)}>
                     <Modal.Header id='billModalHeader' closeButton><h3> Standing Committee on Finance</h3></Modal.Header>
                   <StandingCommitteeOnInternationalTrade/>
                   </Modal>
</Col>

   </div>

      </Carousel.Item>

      <Carousel.Item interval={mobileCarouselInterval}>
    <div id='legislativeConMobile'>
     
       
  <Col>
  <div id='viewFullPageButton'>
  <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/canada/economy' >View all Economy news</Button>
</div>
  
  </Col>


      </div>
      </Carousel.Item>

      </Carousel>
{/*Election */}
      <h5 id='branchHeadingMobile' > <ReceiptCutoff></ReceiptCutoff>   Elections<ChevronRight id='clickChevron'></ChevronRight></h5>
<Carousel controls={false}>



      <Carousel.Item interval={mobileCarouselInterval}>
    <div id='legislativeConMobile'>
     
       
  <Col>
  <div id='viewFullPageButton'>
  <Button id='viewFullLegislativePageButtonMobile' size='lg'  >Coming Soon</Button>
</div>
  
  </Col>


      </div>
      </Carousel.Item>

      </Carousel>

{/*Environment */}
      <h5 id='branchHeadingMobile' onClick={() => window.open('/canada/environment', "_self")}> <Tree></Tree>    Environment<ChevronRight id='clickChevron'></ChevronRight></h5>
<Carousel controls={false}>


<Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile'>

    <Col>
    <Row id = 'mobileFeedHeadingRow'> <h5 id='billFeedHeadingMobileHomeHome' onClick={() => setDepartmentOfAgricultureModalIsOpen(true)}>Standing Committee on Environment and Sustainable Development</h5></Row>

<div id='committee52'></div>
{CreateFeedItem('https://gov-glance-canada.nyc3.digitaloceanspaces.com/legislative-xml/committees/envi.xml', 'committee52', 'Standing Committee on Environment and Sustainable Development', 'Legislative', 'N/A', StandingCommitteeOnEnvironment)}

<Modal id='fullBillModal' show={departmentOfAgricultureModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfAgricultureModalIsOpen(false)}>
                   <Modal.Header id='billModalHeader' closeButton><h3> Standing Committee on Environment and Sustainable Development </h3></Modal.Header>
                 <StandingCommitteeOnEnvironment/>
                 </Modal>
</Col>

   </div>

      </Carousel.Item>

<Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile'>

    <Col>
    <Row id = 'mobileFeedHeadingRow'> <h5 id='billFeedHeadingMobileHomeHome' onClick={() => setDepartmentOfCommerceModalIsOpen(true)}>Standing Committee on Agriculture and Agri-Food</h5></Row>

<div id='committee2'></div>
{CreateFeedItem('https://gov-glance-canada.nyc3.digitaloceanspaces.com/legislative-xml/committees/agri.xml', 'committee2', 'Standing Committee on Agriculture and Agri-Food', 'Legislative', 'N/A', StandingCommitteeOnAgricultureAndFood)}

<Modal id='fullBillModal' show={departmentOfCommerceModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfCommerceModalIsOpen(false)}>
                   <Modal.Header id='billModalHeader' closeButton><h3> Standing Committee on Agriculture and Agri-Food</h3></Modal.Header>
                 <StandingCommitteeOnAgricultureAndFood/>
                 </Modal>
</Col>

   </div>

      </Carousel.Item>


      <Carousel.Item interval={mobileCarouselInterval}>

      <div id='legislativeConMobile'>

<Col>
<Row id = 'mobileFeedHeadingRow'> <h5 id='billFeedHeadingMobileHomeHome' onClick={() => setDepartmentOfEducationModalIsOpen(true)} >Standing Committee on Natural Resources</h5></Row>

<div id='committee12'></div>
{CreateFeedItem('https://gov-glance-canada.nyc3.digitaloceanspaces.com/legislative-xml/committees/rnnr.xml', 'committee12', 'Standing Committee on Natural Resources', 'Legislative', 'N/A', StandingCommitteeOnNaturalResources)}


<Modal id='fullBillModal' show={departmentOfEducationModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfEducationModalIsOpen(false)}>
                   <Modal.Header id='billModalHeader' closeButton><h3> Standing Committee on Natural Resources</h3></Modal.Header>
                 <StandingCommitteeOnNaturalResources/>
                 </Modal>
</Col>

</div>

      </Carousel.Item>

      <Carousel.Item interval={mobileCarouselInterval}>
    <div id='legislativeConMobile'>
     
       
  <Col>
  <div id='viewFullPageButton'>
  <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/canada/environment' >View all Environment news</Button>
  </div>
  
  </Col>


      </div>
      </Carousel.Item>

      </Carousel>

{/*Foreign Affairs */}
      <h5 id='branchHeadingMobile' onClick={() => window.open('/canada/foreignaffairs', "_self")}> <Globe></Globe>    Foreign Affairs<ChevronRight id='clickChevron'></ChevronRight></h5>
<Carousel controls={false}>
 

<Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile'>

    <Col>
    <Row id = 'mobileFeedHeadingRow'> <h5 id='billFeedHeadingMobileHomeHome' onClick={() => setDepartmentOfEnergyModalIsOpen(true)}>Standing Committee on National Defence</h5></Row>

<div id='committee10'></div>
{CreateFeedItem('https://gov-glance-canada.nyc3.digitaloceanspaces.com/legislative-xml/committees/nddn.xml', 'committee10', 'Standing Committee on National Defence', 'Legislative', 'N/A', StandingCommitteeOnNationalDefence)}

<Modal id='fullBillModal' show={departmentOfEnergyModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfEnergyModalIsOpen(false)}>
                   <Modal.Header id='billModalHeader' closeButton><h3> Standing Committee on National Defence</h3></Modal.Header>
                 <StandingCommitteeOnNationalDefence/>
                 </Modal>
</Col>

   </div>

      </Carousel.Item>


      <Carousel.Item interval={mobileCarouselInterval}>

      <div id='legislativeConMobile'>

<Col>
<Row id = 'mobileFeedHeadingRow'> <h5 id='billFeedHeadingMobileHomeHome'  onClick={() => setDepartmentOfHealthAndHumanServicesModalIsOpen(true)}>Special Committee on the Canada–People’s Republic of China Relationship</h5></Row>

<div id='committee3'></div>
{CreateFeedItem('https://gov-glance-canada.nyc3.digitaloceanspaces.com/legislative-xml/committees/cacn.xml', 'committee3', 'Special Committee on the Canada–People’s Republic of China Relationship', 'Legislative', 'N/A', SpecialCommitteeChinaRelation)}

<Modal id='fullBillModal' show={departmentOfHealthAndHumanServicesModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfHealthAndHumanServicesModalIsOpen(false)}>
                   <Modal.Header id='billModalHeader' closeButton><h3> Special Committee on the Canada–People’s Republic of China Relationship</h3></Modal.Header>
                 <SpecialCommitteeChinaRelation/>
                 </Modal>
</Col>

</div>

      </Carousel.Item>


      <Carousel.Item interval={mobileCarouselInterval}>

      <div id='legislativeConMobile'>

<Col>
<Row id = 'mobileFeedHeadingRow'> <h5 id='billFeedHeadingMobileHomeHome' onClick={() => setDepartmentOfHomelandSecurityModalIsOpen(true)}>Standing Committee on International Trade</h5></Row>

<div id='committee42'></div>
{CreateFeedItem('https://gov-glance-canada.nyc3.digitaloceanspaces.com/legislative-xml/committees/ciit.xml', 'committee42', 'Standing Committee on International Trade', 'Legislative', 'N/A', StandingCommitteeOnInternationalTrade)}


<Modal id='fullBillModal' show={departmentOfHomelandSecurityModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfHomelandSecurityModalIsOpen(false)}>
                   <Modal.Header id='billModalHeader' closeButton><h3> Standing Committee on International Trade </h3></Modal.Header>
                 <StandingCommitteeOnInternationalTrade/>
                 </Modal>
</Col>

</div>

      </Carousel.Item>

      <Carousel.Item interval={mobileCarouselInterval}>
    <div id='legislativeConMobile'>
     
       
  <Col>
  <div id='viewFullPageButton'>
  <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/canada/foreignaffairs' >View all Foreign Affairs news</Button>
</div>
  
  </Col>


      </div>
      </Carousel.Item>

      </Carousel>


{/* Health*/}
<h5 id='branchHeadingMobile' > <FileMedicalFill></FileMedicalFill>    Health<ChevronRight id='clickChevron'></ChevronRight></h5>
<Carousel controls={false}>


      <Carousel.Item interval={mobileCarouselInterval}>
    <div id='legislativeConMobile'>
     
       
  <Col>
  <div id='viewFullPageButton'>
  <Button id='viewFullLegislativePageButtonMobile' size='lg'  >Coming Soon</Button>
</div>
  
  </Col>


      </div>
      </Carousel.Item>

      </Carousel>





      <h5 id='branchHeadingMobile' onClick={() => window.open('/canada/science&tech', "_self")}> <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-rocket-takeoff" viewBox="0 0 16 16">
  <path d="M9.752 6.193c.599.6 1.73.437 2.528-.362.798-.799.96-1.932.362-2.531-.599-.6-1.73-.438-2.528.361-.798.8-.96 1.933-.362 2.532Z"/>
  <path d="M15.811 3.312c-.363 1.534-1.334 3.626-3.64 6.218l-.24 2.408a2.56 2.56 0 0 1-.732 1.526L8.817 15.85a.51.51 0 0 1-.867-.434l.27-1.899c.04-.28-.013-.593-.131-.956a9.42 9.42 0 0 0-.249-.657l-.082-.202c-.815-.197-1.578-.662-2.191-1.277-.614-.615-1.079-1.379-1.275-2.195l-.203-.083a9.556 9.556 0 0 0-.655-.248c-.363-.119-.675-.172-.955-.132l-1.896.27A.51.51 0 0 1 .15 7.17l2.382-2.386c.41-.41.947-.67 1.524-.734h.006l2.4-.238C9.005 1.55 11.087.582 12.623.208c.89-.217 1.59-.232 2.08-.188.244.023.435.06.57.093.067.017.12.033.16.045.184.06.279.13.351.295l.029.073a3.475 3.475 0 0 1 .157.721c.055.485.051 1.178-.159 2.065Zm-4.828 7.475.04-.04-.107 1.081a1.536 1.536 0 0 1-.44.913l-1.298 1.3.054-.38c.072-.506-.034-.993-.172-1.418a8.548 8.548 0 0 0-.164-.45c.738-.065 1.462-.38 2.087-1.006ZM5.205 5c-.625.626-.94 1.351-1.004 2.09a8.497 8.497 0 0 0-.45-.164c-.424-.138-.91-.244-1.416-.172l-.38.054 1.3-1.3c.245-.246.566-.401.91-.44l1.08-.107-.04.039Zm9.406-3.961c-.38-.034-.967-.027-1.746.163-1.558.38-3.917 1.496-6.937 4.521-.62.62-.799 1.34-.687 2.051.107.676.483 1.362 1.048 1.928.564.565 1.25.941 1.924 1.049.71.112 1.429-.067 2.048-.688 3.079-3.083 4.192-5.444 4.556-6.987.183-.771.18-1.345.138-1.713a2.835 2.835 0 0 0-.045-.283 3.078 3.078 0 0 0-.3-.041Z"/>
  <path d="M7.009 12.139a7.632 7.632 0 0 1-1.804-1.352A7.568 7.568 0 0 1 3.794 8.86c-1.102.992-1.965 5.054-1.839 5.18.125.126 3.936-.896 5.054-1.902Z"/>
</svg>  Science & Tech<ChevronRight id='clickChevron'></ChevronRight></h5>
<Carousel controls={false}>



<Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile'>

    <Col>
    <Row id = 'mobileFeedHeadingRow'> <h5 id='billFeedHeadingMobileHomeHome' onClick={() => setDepartmentOfVeteransAffairsModalIsOpen(true)}>Standing Committee on Industry and Technology</h5></Row>

<div id='committee9'></div>
  {CreateFeedItem('https://gov-glance-canada.nyc3.digitaloceanspaces.com/legislative-xml/committees/indu.xml', 'committee9', 'Standing Committee on Industry and Technology', 'Legislative', 'N/A', StandingCommitteeOnIndustryAndTech)}


  <Modal id='fullBillModal' show={departmentOfVeteransAffairsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfVeteransAffairsModalIsOpen(false)}>
                       <Modal.Header id='billModalHeader' closeButton><h3> Standing Committee on Industry and Technology</h3></Modal.Header>
                     <StandingCommitteeOnIndustryAndTech/>
                     </Modal>
</Col>

   </div>
      </Carousel.Item>


<Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile'>

    <Col>
    <Row id = 'mobileFeedHeadingRow'> <h5 id='billFeedHeadingMobileHomeHome' onClick={() => setDepartmentOfAgricultureModalIsOpen(true)}>Standing Committee on Environment and Sustainable Development</h5></Row>

<div id='committee5'></div>
{CreateFeedItem('https://gov-glance-canada.nyc3.digitaloceanspaces.com/legislative-xml/committees/envi.xml', 'committee5', 'Standing Committee on Environment and Sustainable Development', 'Legislative', 'N/A', StandingCommitteeOnEnvironment)}

</Col>

   </div>

      </Carousel.Item>
  

      <Carousel.Item interval={mobileCarouselInterval}>
    <div id='legislativeConMobile'>
     
       
  <Col>
  <div id='viewFullPageButton'>
  <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/canada/science&tech' >View all Science and Tech news</Button>
</div>
  
  </Col>


      </div>
      </Carousel.Item>

      </Carousel>

      <h5 id='branchHeadingMobile' onClick={() => window.open('/canada/defense', "_self")}> <Bricks/>  Defense<ChevronRight id='clickChevron'></ChevronRight></h5>

<Carousel controls={false}>



<Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile'>

    <Col>
    <Row id = 'mobileFeedHeadingRow'> <h5 id='billFeedHeadingMobileHomeHome' onClick={() => setDepartmentOfEnergyModalIsOpen(true)}>Standing Committee on National Defence</h5></Row>

<div id='committee102'></div>
{CreateFeedItem('https://gov-glance-canada.nyc3.digitaloceanspaces.com/legislative-xml/committees/nddn.xml', 'committee102', 'Standing Committee on National Defence', 'Legislative', 'N/A', StandingCommitteeOnNationalDefence)}


</Col>

   </div>

      </Carousel.Item>

  

      <Carousel.Item interval={mobileCarouselInterval}>
    <div id='legislativeConMobile'>
     
       
  <Col>
  <div id='viewFullPageButton'>
  <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/canada/defense' >View all Defense news</Button>
</div>
  
  </Col>


      </div>
      </Carousel.Item>

      </Carousel>




<br></br>

<h5 id='branchHeadingMobile' > <GeoAlt/>  Provinces <ChevronRight id='clickChevron'></ChevronRight></h5>
<br></br>
<div id='homebg'>  
                      <Container>
                      <Col>
                      <Row id='statesRow'>
                  <Button size='sm' id='statesModalButtons'  onClick={() =>  {
                       
                        window.open('/canada/alberta', '_self')
                      }}> <Image id='stateFlagMobile'  src='/albertaFlag.png'></Image>Alberta</Button>
                        <Button size='sm' id='statesModalButtons'  onClick={() =>  {
                       
                        window.open('/canada/britishcolumbia', '_self')
                      }}><Image id='stateFlagMobile'  src='/britishColumbiaFlag.png'></Image>British Columbia</Button>
                       <Button size='sm' id='statesModalButtons'  onClick={() =>  {
                       
                        window.open('/canada/manitoba', '_self')
                      }}> <Image id='stateFlagMobile'  src='/manitobaFlag.png'></Image>Manitoba</Button>
                      </Row>
                      <Row>
                          <Button size='sm' id='statesModalButtons'  onClick={() =>  {
                       
                        window.open('/canada/newbrunswick', '_self')
                      }}> <Image id='stateFlagMobile'  src='/newBrunswickFlag.png'></Image>New Brunswick</Button>
                     
                     <Button size='sm' id='statesModalButtons'  onClick={() =>  {
                       
                        window.open('/canada/newfoundland&labrador', '_self')
                      }}> <Image id='stateFlagMobile'  src='/newfoundlandAndLabradorFlag.png'></Image>Newfoundland and Labrador</Button>
                     
                     <Button size='sm' id='statesModalButtons'  onClick={() =>  {
                       
                        window.open('/canada/northwestterritories', '_self')
                      }}> <Image id='stateFlagMobile'  src='/northwestTerritoriesFlag.png'></Image>Northwest Territories</Button>
                     </Row>
                     <Row>
                     <Button size='sm' id='statesModalButtons'  onClick={() =>  {
                       
                        window.open('/canada/novascotia', '_self')
                      }}> <Image id='stateFlagMobile'  src='/novaScotiaFlag.png'></Image>Nova Scotia</Button>
                      <Button size='sm' id='statesModalButtons'  onClick={() =>  {
                       
                        window.open('/canada/nunavut', '_self')
                      }}> <Image id='stateFlagMobile'  src='/nunavutFlag.png'></Image>Nunavut</Button>
                       <Button size='sm' id='statesModalButtons'  onClick={() =>  {
                       
                        window.open('/canada/ontario', '_self')
                       }}> <Image id='stateFlagMobile'  src='/ontarioFlag.png'></Image>Ontario</Button>
                      </Row>
                      <Row>
                       <Button size='sm' id='statesModalButtons'  onClick={() =>  {
                       
                        window.open('/canada/princeedwardisland', '_self')
                      }}> <Image id='stateFlagMobile'  src='/princeEdwardIslandFlag.png'></Image>Prince Edward Island</Button>
                       <Button size='sm' id='statesModalButtons'  onClick={() =>  {
                       
                        window.open('/canada/quebec', '_self')
                      }}> <Image id='stateFlagMobile'  src='/quebecFlag.png'></Image>Quebec</Button>
                       <Button size='sm' id='statesModalButtons'  onClick={() =>  {
                       
                        window.open('/canada/saskatchewan', '_self')
                      }}> <Image id='stateFlagMobile'  src='/saskatchewanFlag.png'></Image>Saskatchewan</Button>
                   
                       <Button size='sm' id='statesModalButtons'  onClick={() =>  {
                       
                        window.open('/canada/yukon', '_self')
                      }}> <Image id='stateFlagMobile'  src='/yukonFlag.png'></Image>Yukon</Button>
                      </Row>
           
                     
                     
                     
                   
                     </Col>
                     </Container>
                     </div>


                     <br></br>
  
                     <div className="d-flex flex-column align-items-center text-center">  
      <h5 id='branchHeadingMobile2'><GeoAlt/> Change country <ChevronRight id='clickChevron'></ChevronRight></h5>
</div>
<Col className="d-flex flex-column align-items-center">
  <Button id='selectHomeCountry' onClick={() => window.open('/canada', "_self")}>
    🇨🇦 Canada
  </Button>

  <Button id='selectHomeCountry' onClick={() => window.open('/mexico', "_self")}>
    🇲🇽 Mexico
  </Button>

  <Button id='selectHomeCountry' onClick={() => window.open('/uk', "_self")}>
    🇬🇧 United Kingdom
  </Button>

  <Button id='selectHomeCountry' onClick={() => window.open('/us', "_self")}>
    🇺🇸 United States
  </Button>
</Col>

                     <br></br>
<br></br>
                     <h5 id='headingSelectHomeCountry'>Support the Gov Glance Foundation  <Button id='donateButton' onClick={(e) => {
                        e.preventDefault();
                        window.open('https://donate.stripe.com/28o3fXbdvbQO2RO288', "_blank")
                      }}><HeartFill id='donateIcon'/>    Donate</Button></h5>


<br></br><br></br><br></br>
<Navbar id='navbarMobileBottom' fixed="bottom">  



       
<div id='changeBranchDropdownMobile'>



<MobileOffCanvasCanada/>

              
            </div>

</Navbar>

<br></br><br></br>
{/* 
<Offcanvas show={showOffCanvas} onHide={handleClose} {...props}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Offcanvas</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          Some text as placeholder. In real life you can have the elements you
          have chosen. Like, text, images, lists, etc.
        </Offcanvas.Body>
      </Offcanvas>
      */}
</div>
</>
)

                }}
export default CanadaHomeMobile;
