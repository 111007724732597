import App from "../../App";
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment, lazy } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Image, ModalFooter, Spinner } from 'react-bootstrap';
import { ArrowsAngleExpand, InfoCircle, FileText, PauseCircle, PlayCircle, FolderPlus, ChevronRight, Files, Folder, Pen, ChatLeftQuote } from 'react-bootstrap-icons'
import '../../App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import { render } from '@testing-library/react';

import{ CreateFeedItem,
CreateFeedItemHouseOfCommons, CreateFeedAPI} from '../../Functions'
import BillUpdatesCanada from "./BillUpdatesCanada";
import CanadaMobileTopNavbar from "../CanadaMobileTopNavbar";
import HouseOfCommons from "./HouseOfCommons";
import CanadaSenate from "./CanadaSenate";
import MobileOffCanvasCanada from "../../MobileOffCanvasCanada";
import GenerateFeed from "../../GenerateFeed";
import { Suspense } from "react";

const HeavyDependencyComponent = lazy(() => import('../../GenerateFeed'));
const CustomModal = ({ isOpen, onClose, title, content }) => (
  <Modal id='fullBillModal' show={isOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={onClose}>
    <Modal.Header id='billModalHeader' closeButton><h3>{title}</h3></Modal.Header>
    {content}
  </Modal>
);

export const CanadaLegislativeBranchMobile = () =>   {
  const [modalInfo, setModalInfo] = useState({
    isOpen: false,
    title: '',
    content: null
  });

  const openModal = (title, content) => {
    setModalInfo({
      isOpen: true,
      title,
      content
    });
  };

  const closeModal = () => {
    setModalInfo({
      isOpen: false,
      title: '',
      content: null
    });
  };
    render()
    {
        const [modalIsOpen, setModalIsOpen] = useState(false)
        
        localStorage.setItem('currentPage', 'canada')
    return(
       <>
    <CanadaMobileTopNavbar/>
  <div id='homebg'>
{/*Legislative Mobile Carousel*/}
<h5 id='branchHeadingMobile'> <Pen></Pen> Legislative </h5>
<Modal show={modalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setModalIsOpen(false)}>
<Modal.Header id='iframeModalHeader' closeButton>Congressional Record</Modal.Header>
<Card id='iframeColMobile'>
  <iframe src='https://www.congress.gov/congressional-record' height='1000px' width='410px' frameBorder='0'><div id='modalFeedMobile'></div></iframe></Card></Modal>

<Col>
  <h5 id='presidentialFeedHeadingMobile' onClick={() =>
                            openModal('Bill Updates',
                            <GenerateFeed url='&skip=0&schema=canada&table=bills&order_by=created_at' 
                            name='Bill Updates' branch='Legislative' topic='N/A' buttonName='Bill' /> )}>Bill Updates</h5>

  <div id='billUpdates'></div>
                          
  {CreateFeedAPI('&skip=0&schema=canada&table=bills&order_by=created_at', 'billUpdates', 
                            'Bill Updates', 'Legislative', 
                            'N/A', 'Bills', GenerateFeed)}
</Col>


<Col>
  <h5 id='presidentialFeedHeadingMobile' onClick={() =>
                            openModal('House of Commons',
                             <HouseOfCommons/> )}>House of Commons</h5>

  <div id='hOC'></div>
                          
                          {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
    {CreateFeedItemHouseOfCommons('https://gov-glance-canada.nyc3.digitaloceanspaces.com/legislative-xml/house_of_commons_newsroom/house_of_commons_newsroom.xml', 'hOC', 'House of Commons News', 'Legislative', 'N/A', HouseOfCommons)}

</Col>
<Col>
  <h5 id='presidentialFeedHeadingMobile' onClick={() =>
                            openModal('Senate',
                            <GenerateFeed url='&schema=canada&table=senate_news&order_by=created_at' 
                            name='Senate' branch='Legislative' topic='N/A' buttonName='Doc' />)}>Senate</h5>

  <div id='senate'></div>
                          
                          {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
                          {CreateFeedAPI('&schema=canada&table=senate_news&order_by=created_at', 'senate', 
                            'Senate', 'Executive', 
                            'N/A', 'Doc', GenerateFeed)}  
</Col>

<Col>
  <h5 id='presidentialFeedHeadingMobile' onClick={() =>
                            openModal('Standing Committee on Veterans Affairs',
                              <HeavyDependencyComponent
                                url='&skip=0&schema=canada&table=acva&order_by=created_at'
                                name='Standing Committee on Veterans Affairs' 
                                branch='Legislative' topic='N/A' buttonName='Document' />)}>Standing Committee on Veterans' Affairs</h5>
  <div id='committee1'></div>
  {CreateFeedAPI('&skip=0&schema=canada&table=acva&order_by=created_at', 'committee1',
                            'Standing Committee on Veterans Affairs', 'Legislative',
                            'N/A', 'Document', GenerateFeed)}
</Col>

<Col>
  <h5 id='presidentialFeedHeadingMobile' onClick={() =>
                            openModal('Standing Committee on Agriculture and Agri-Food',
                              <HeavyDependencyComponent
                                url='&skip=0&schema=canada&table=agri&order_by=created_at'
                                name='Standing Committee on Agriculture and Agri-Food' branch='Legislative' topic='N/A' buttonName='Document' />)}>Standing Committee on Agriculture and Agri-Food</h5>
  <div id='committee2'></div>
  {CreateFeedAPI('&skip=0&schema=canada&table=agri&order_by=created_at', 'committee2',
                            'Standing Committee on Agriculture and Agri-Food', 'Legislative',
                            'N/A', 'Document', GenerateFeed)}
</Col>


<Col>
  <h5 id='presidentialFeedHeadingMobile'onClick={() =>
                            openModal('Special Committee on the Canada–People’s Republic of China Relationship',
                              <HeavyDependencyComponent
                                url='&skip=0&schema=canada&table=cacn&order_by=created_at'
                                name='Special Committee on the Canada–People’s Republic of China Relationship' 
                                branch='Legislative' topic='N/A' 
                                buttonName='Document' />)}>Special Committee on the Canada–People’s Republic of China Relationship</h5>
  <div id='committee3'></div>
  {CreateFeedAPI('&skip=0&schema=canada&table=cacn&order_by=created_at', 'committee3',
                            'Special Committee on the Canada–People’s Republic of China Relationship', 'Legislative',
                            'N/A', 'Document', GenerateFeed)}

</Col>

<Col>
  <h5 id='presidentialFeedHeadingMobile' onClick={() =>
                            openModal('Standing Committee on International Trade',
                              <HeavyDependencyComponent
                                url='&skip=0&schema=canada&table=ciit&order_by=created_at'
                                name='Standing Committee on International Trade' 
                                branch='Legislative' topic='N/A' 
                                buttonName='Document' />)}>Standing Committee on International Trade</h5>
  <div id='committee4'></div>
  {CreateFeedAPI('&skip=0&schema=canada&table=ciit&order_by=created_at', 'committee4',
                            'Standing Committee on International Trade', 'Legislative',
                            'N/A', 'Document', GenerateFeed)}

</Col>

<Col>
  <h5 id='presidentialFeedHeadingMobile' onClick={() =>
                            openModal('Standing Committee on Environment and Sustainable Development',
                              <HeavyDependencyComponent
                                url='&skip=0&schema=canada&table=envi&order_by=created_at'
                                name='Standing Committee on Environment and Sustainable Development' 
                                branch='Legislative' topic='N/A' 
                                buttonName='Document' />)}>Standing Committee on Environment and Sustainable Development</h5>
  <div id='committee5'></div>
  {CreateFeedAPI('&skip=0&schema=canada&table=envi&order_by=created_at', 'committee5',
                            'Standing Committee on Environment and Sustainable Development', 'Legislative',
                            'N/A', 'Document', GenerateFeed)}

</Col>

<Col>
  <h5 id='presidentialFeedHeadingMobile' onClick={() =>
                            openModal('Standing Committee on Citizenship and Immigration',
                              <HeavyDependencyComponent
                                url='&skip=0&schema=canada&table=cimm&order_by=created_at'
                                name='Standing Committee on Citizenship and Immigration' 
                                branch='Legislative' topic='N/A' 
                                buttonName='Document' />)}>Standing Committee on Citizenship and Immigration</h5>
  <div id='committee6'></div>
  {CreateFeedAPI('&skip=0&schema=canada&table=cimm&order_by=created_at', 'committee6',
                            'Standing Committee on Citizenship and Immigration', 'Legislative',
                            'N/A', 'Document', GenerateFeed)}

</Col>

<Col>
  <h5 id='presidentialFeedHeadingMobile' onClick={() =>
                            openModal('Standing Committee on Finance',
                              <HeavyDependencyComponent
                                url='&skip=0&schema=canada&table=fina&order_by=created_at'
                                name='Standing Committee on Finance' 
                                branch='Legislative' topic='N/A' 
                                buttonName='Document' />)}>Standing Committee on Finance</h5>
  <div id='committee7'></div>
  {CreateFeedAPI('&skip=0&schema=canada&table=fina&order_by=created_at', 'committee7',
                            'Standing Committee on Finance', 'Legislative',
                            'N/A', 'Document', GenerateFeed)}

</Col>

<Col>
  <h5 id='presidentialFeedHeadingMobile' onClick={() =>
                            openModal('Standing Committee on Indigenous and Northern Affairs',
                              <HeavyDependencyComponent
                                url='&skip=0&schema=canada&table=inan&order_by=created_at'
                                name='Standing Committee on Indigenous and Northern Affairs' 
                                branch='Legislative' topic='N/A' 
                                buttonName='Document' />)}>Standing Committee on Indigenous and Northern Affairs</h5>
  <div id='committee8'></div>
  {CreateFeedAPI('&skip=0&schema=canada&table=inan&order_by=created_at', 'committee8',
                            'Standing Committee on Indigenous and Northern Affairs', 'Legislative',
                            'N/A', 'Document', GenerateFeed)}

</Col>

<Col>
  <h5 id='presidentialFeedHeadingMobile' onClick={() =>
                            openModal('Standing Committee on Industry and Technology',
                              <HeavyDependencyComponent
                                url='&skip=0&schema=canada&table=indu&order_by=created_at'
                                name='Standing Committee on Industry and Technology' 
                                branch='Legislative' topic='N/A' 
                                buttonName='Document' />)}>Standing Committee on Industry and Technology</h5>
  <div id='committee9'></div>
  {CreateFeedAPI('&skip=0&schema=canada&table=indu&order_by=created_at', 'committee9',
                            'Standing Committee on Industry and Technology', 'Legislative',
                            'N/A', 'Document', GenerateFeed)}
                            </Col>

<Col>
  <h5 id='presidentialFeedHeadingMobile' onClick={() =>
                            openModal('Standing Committee on National Defence',
                              <HeavyDependencyComponent
                                url='&skip=0&schema=canada&table=nddn&order_by=created_at'
                                name='Standing Committee on National Defence' 
                                branch='Legislative' topic='N/A' 
                                buttonName='Document' />)}>Standing Committee on National Defence News</h5>
  <div id='committee10'></div>
  {CreateFeedAPI('&skip=0&schema=canada&table=nddn&order_by=created_at', 'committee10',
                            'Standing Committee on National Defence', 'Legislative',
                            'N/A', 'Document', GenerateFeed)}
</Col>

<Col>
  <h5 id='presidentialFeedHeadingMobile'onClick={() =>
                            openModal('Standing Committee on Government Operations and Estimates',
                              <HeavyDependencyComponent
                                url='&skip=0&schema=canada&table=oggo&order_by=created_at'
                                name='Standing Committee on Government Operations and Estimates' 
                                branch='Legislative' topic='N/A' 
                                buttonName='Document' />)}>Standing Committee on Government Operations and Estimates</h5>
  <div id='committee11'></div>
  {CreateFeedAPI('&skip=0&schema=canada&table=oggo&order_by=created_at', 'committee11',
                            'Standing Committee on Government Operations and Estimates', 'Legislative',
                            'N/A', 'Document', GenerateFeed)}
</Col>

<Col>
  <h5 id='presidentialFeedHeadingMobile'onClick={() =>
                            openModal('Standing Committee on Natural Resources',
                              <HeavyDependencyComponent
                                url='&skip=0&schema=canada&table=rnnr&order_by=created_at'
                                name='Standing Committee on Natural Resources' 
                                branch='Legislative' topic='N/A' 
                                buttonName='Document' />)}>Standing Committee on Natural Resources</h5>
  <div id='committee12'></div>
  {CreateFeedAPI('&skip=0&schema=canada&table=oggo&order_by=created_at', 'committee*',
                            'Standing Committee on Natural Resources', 'Legislative',
                            'N/A', 'Document', GenerateFeed)}
</Col>


<Suspense fallback={<Spinner animation="grow" variant="light" />}>
                      <CustomModal isOpen={modalInfo.isOpen} onClose={closeModal} title={modalInfo.title} content={modalInfo.content} />
                    </Suspense>


      <br></br><br></br>

            <Navbar id='navbarMobileBottom' fixed="bottom">  
<div id='changeBranchDropdownMobile'>
                  <MobileOffCanvasCanada/>
  
            </div>
</Navbar>
</div>
</>
)
}}
export default CanadaLegislativeBranchMobile;


