import App from "./App";
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Image } from 'react-bootstrap';
import { ArrowsAngleExpand, InfoCircle, FileText, FolderPlus, GeoAlt } from 'react-bootstrap-icons'
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import Axios from 'axios'
import { render } from '@testing-library/react';
import { Component } from "react";









function StatesModal()
{
  return(
    <>
   <div id='fullBillFeed'>
          <Container id='feedContainer'>

  <Col>
  

  <Row> 
<Button size='sm' id='statesDesktopButtons'  onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/alabama', '_self')
                      }}> <Image id='stateFlagHomePage'  src='alabamaFlag.png'></Image> Alabama</Button>
                        <Button size='sm' id='statesDesktopButtons'  onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/alaska', '_self')
                      }}><Image id='stateFlagHomePage'  src='alaskaFlag.png'></Image> Alaska</Button>
 
<Button size='sm' id='statesDesktopButtons'  onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/arizona', '_self')
                      }}> <Image id='stateFlagHomePage'  src='arizonaFlag.png'></Image> Arizona</Button>
 
 
 
<Button size='sm' id='statesDesktopButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/arkansas', '_self')
                      }}><Image id='stateFlagHomePage'  src='arkansasFlag.png'></Image> Arkansas</Button>
 </Row>
<Row>
<Button size='sm' id='statesDesktopButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/california', '_self')
                      }}><Image id='stateFlagHomePage'  src='californiaFlag.png'></Image> California</Button>
 
 
<Button size='sm' id='statesDesktopButtons'  onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/colorado', '_self')
                      }}><Image id='stateFlagHomePage'  src='coloradoFlag.png'></Image> Colorado</Button>
 
 
 
<Button size='sm' id='statesDesktopButtons'  onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/connecticut', '_self')
                      }}><Image id='stateFlagHomePage'  src='connecticutFlag.png'></Image> Connecticut</Button>
 
<Button size='sm' id='statesDesktopButtons'  onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/delaware', '_self')
                      }}><Image id='stateFlagHomePage'  src='delawareFlag.png'></Image> Delaware</Button>
 </Row>
<Row>
<Button size='sm' id='statesDesktopButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/florida', '_self')
                      }}><Image id='stateFlagHomePage'  src='floridaFlag.png'></Image> Florida</Button>
 
 
 
<Button size='sm' id='statesDesktopButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/georgia', '_self')
                      }}><Image id='stateFlagHomePage'  src='georgiaFlag.png'></Image> Georgia</Button>
 
<Button size='sm' id='statesDesktopButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/hawaii', '_self')
                      }}><Image id='stateFlagHomePage'  src='hawaiiFlag.png'></Image> Hawaii</Button>
 
<Button size='sm' id='statesDesktopButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/idaho', '_self')
                      }}> <Image id='stateFlagHomePage'  src='idahoFlag.png'></Image> Idaho</Button>
 </Row>
 <Row>
 
<Button size='sm' id='statesDesktopButtons'  onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/illinois', '_self')
                      }}> <Image id='stateFlagHomePage'  src='illinoisFlag.png'></Image> Illinois</Button>
 
<Button size='sm' id='statesDesktopButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/indiana', '_self')
                      }}><Image id='stateFlagHomePage'  src='indianaFlag.png'></Image> Indiana</Button>
 
<Button size='sm' id='statesDesktopButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/iowa', '_self')
                      }}><Image id='stateFlagHomePage'  src='iowaFlag.png'></Image> Iowa</Button>
 
 
 
<Button size='sm' id='statesDesktopButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/kansas', '_self')
                      }}><Image id='stateFlagHomePage'  src='kansasFlag.png'></Image> Kansas</Button>
 </Row>
<Row>
<Button size='sm' id='statesDesktopButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/kentucky', '_self')
                      }}><Image id='stateFlagHomePage'  src='kentuckyFlag.png'></Image> Kentucky</Button>
 
                     
<Button size='sm' id='statesDesktopButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/louisiana', '_self')
                      }}><Image id='stateFlagHomePage'  src='louisianaFlag.png'></Image> Louisiana</Button>
 
 
 
 
<Button size='sm' id='statesDesktopButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/maine', '_self')
                      }}><Image id='stateFlagHomePage'  src='maineFlag.png'></Image> Maine</Button>
 
<Button size='sm' id='statesDesktopButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/maryland', '_self')
                      }}><Image id='stateFlagHomePage'  src='marylandFlag.png'></Image> Maryland</Button>
</Row>
<Row> 
<Button size='sm' id='statesDesktopButtons'  onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/massachusetts', '_self')
                      }}><Image id='stateFlagHomePage'  src='massachusettsFlag.png'></Image> Massachusetts</Button>
 
 
 
<Button size='sm' id='statesDesktopButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/michigan', '_self')
                      }}><Image id='stateFlagHomePage'  src='michiganFlag.png'></Image> Michigan</Button>
 
<Button size='sm' id='statesDesktopButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/minnesota', '_self')
                      }}><Image id='stateFlagHomePage'  src='minnesotaFlag.png'></Image> Minnesota</Button>
 
<Button size='sm' id='statesDesktopButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/mississippi', '_self')
                      }}><Image id='stateFlagHomePage'  src='mississippiFlag.png'></Image> Mississippi</Button>
</Row>
<Row> 
 
 
<Button size='sm' id='statesDesktopButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/missouri', '_self')
                      }}><Image id='stateFlagHomePage'  src='missouriFlag.png'></Image> Missouri</Button>
 
<Button size='sm' id='statesDesktopButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/montana', '_self')
                      }}><Image id='stateFlagHomePage'  src='montanaFlag.png'></Image> Montana</Button>
 
<Button size='sm' id='statesDesktopButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/nebraska', '_self')
                      }}><Image id='stateFlagHomePage'  src='nebraskaFlag.png'></Image> Nebraska</Button>
 
 
                    
                      <Button size='sm' id='statesDesktopButtons'  onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/nevada', '_self')
                      }}><Image id='stateFlagHomePage'  src='nevadaFlag.png'></Image> Nevada</Button>
</Row>
<Row>
 
<Button size='sm' id='statesDesktopButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/newhampshire', '_self')
                      }}><Image id='stateFlagHomePage'  src='newHampshireFlag.png'></Image> New Hampshire</Button>
 
<Button size='sm' id='statesDesktopButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/newjersey', '_self')
                      }}><Image id='stateFlagHomePage'  src='newJerseyFlag.png'></Image> New Jersey</Button>
 
 
 
<Button size='sm' id='statesDesktopButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/newmexico', '_self')
                      }}><Image id='stateFlagHomePage'  src='newMexicoFlag.png'></Image> New Mexico</Button>
 
<Button size='sm' id='statesDesktopButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/newyork', '_self')
                      }}><Image id='stateFlagHomePage'  src='newYorkFlag.png'></Image> New York</Button>
 </Row>
<Row>
<Button size='sm' id='statesDesktopButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/northcarolina', '_self')
                      }}><Image id='stateFlagHomePage'  src='northCarolinaFlag.png'></Image>North Carolina</Button>
 
 
 
<Button size='sm' id='statesDesktopButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/northdakota', '_self')
                      }}><Image id='stateFlagHomePage'  src='northDakotaFlag.png'></Image>North Dakota</Button>
 
<Button size='sm' id='statesDesktopButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/ohio', '_self')
                      }}><Image id='stateFlagHomePage'  src='ohioFlag.png'></Image>Ohio</Button>
 
<Button size='sm' id='statesDesktopButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/oklahoma', '_self')
                      }}><Image id='stateFlagHomePage'  src='oklahomaFlag.png'></Image> Oklahoma</Button>
 </Row>
 <Row>
 
<Button size='sm' id='statesDesktopButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/oregon', '_self')
                      }}><Image id='stateFlagHomePage'  src='oregonFlag.png'></Image> Oregon</Button>
 
<Button size='sm' id='statesDesktopButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/pennsylvania', '_self')
                      }}><Image id='stateFlagHomePage'  src='pennsylvaniaFlag.png'></Image> Pennsylvania</Button>
 
<Button size='sm' id='statesDesktopButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/rhodeisland', '_self')
                      }}><Image id='stateFlagHomePage'  src='rhodeIslandFlag.png'></Image> Rhode Island</Button>
 
 
 
<Button size='sm' id='statesDesktopButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/southcarolina', '_self')
                      }}><Image id='stateFlagHomePage'  src='southCarolinaFlag.png'></Image> South Carolina</Button>
 </Row>
<Row>
<Button size='sm' id='statesDesktopButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/southdakota', '_self')
                      }}><Image id='stateFlagHomePage'  src='southDakotaFlag.png'></Image> South Dakota</Button>
 
<Button size='sm' id='statesDesktopButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/tennessee', '_self')
                      }}><Image id='stateFlagHomePage'  src='tennesseeFlag.png'></Image> Tennessee</Button>
 
 
 
<Button size='sm' id='statesDesktopButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/texas', '_self')
                      }}><Image id='stateFlagHomePage'  src='texasFlag.png'></Image> Texas</Button>
 
<Button size='sm' id='statesDesktopButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/utah', '_self')
                      }}><Image id='stateFlagHomePage'  src='utahFlag.png'></Image> Utah</Button>
 </Row>
<Row>
<Button size='sm' id='statesDesktopButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/vermont', '_self')
                      }}><Image id='stateFlagHomePage'  src='vermontFlag.png'></Image> Vermont</Button>
 
 
 
<Button size='sm' id='statesDesktopButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/virginia', '_self')
                      }}><Image id='stateFlagHomePage'  src='virginiaFlag.png'></Image> Virginia</Button>
 
<Button size='sm' id='statesDesktopButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/washington', '_self')
                      }}><Image id='stateFlagHomePage'  src='washingtonFlag.png'></Image> Washington</Button>
 
<Button size='sm' id='statesDesktopButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/westvirginia', '_self')
                      }}><Image id='stateFlagHomePage'  src='westVirginiaFlag.png'></Image> West Virginia</Button>
 </Row>
 <Row>
 
<Button size='sm' id='statesDesktopButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/wisconsin', '_self')
                      }}><Image id='stateFlagHomePage'  src='wisconsinFlag.png'></Image>Wisconsin</Button>
 
<Button size='sm' id='statesDesktopButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/wyoming', '_self')
                      }}><Image id='stateFlagHomePage'  src='wyomingFlag.png'></Image>Wyoming</Button>
</Row>
 
 
 
 
 


 
 
 
 
 </Col>
 </Container>

 </div>

    </>
  )
                    }


  






export default StatesModal;