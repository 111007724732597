import App from "../../App";
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Image, ModalFooter } from 'react-bootstrap';
import { ArrowsAngleExpand, InfoCircle, FileText, PauseCircle, PlayCircle, FolderPlus, ChevronRight, Files, Folder, Pen, ChatLeftQuote, ShieldShaded, People, HeartFill } from 'react-bootstrap-icons'
import '../../App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import { render } from '@testing-library/react';
import TemplateMobileTopNavbar from "../UKMobileTopNavbar";
import MobileOffCanvas from "../../MobileOffCanvas";
import PrimeMinisterNews from "./PrimeMinisterNews";
import { CreateFeedAPI, CreateFeedAPIDepartmentUK, CreateFeedItemUK, CreateFeedItemUKAtom } from "../../Functions";
import AttorneyGeneralNews from "./AttorneyGeneralNews";
import CabinetNews from "./CabinetNews";
import DepartmentForBusiness from "./DepartmentForBusiness";
import DepartmentCultureMediaAndSport from "./DepartmentCultureMediaAndSport";
import DepartmentForEducation from "../Topics/DepartmentForEducation";
import DepartmentForEnergy from "../Topics/Science and Tech/DepartmentForEnergy";
import DepartmentForEnvironment from "../Topics/Environment/DepartmentForEnvironment";
import DepartmentForScience from "../Topics/Science and Tech/DepartmentForScience";
import DepartmentForTransport from "../Topics/DepartmentForTransport";
import DepartmentForWork from "../Topics/Economy/DepartmentForWork";
import DepartmentForHealth from "../Topics/Health/DepartmentForHealth";
import DepartmentForForeignCommonwealth from "../Topics/Foreign Affairs/DepartmentForForeignCommonwealth";
import DepartmentForHMTreasury from "../Topics/Economy/DepartmentForHMTreasury";
import DepartmentForHomeOffice from "../Topics/DepartmentForHomeOffice";
import MinistryOfDefense from "../Topics/Foreign Affairs/MinistryOfDefense";
import MinistryOfJustice from "../Topics/MinistryOfJustice";
import NorthernIrelandOffice from "../Topics/NorthernIrelandOffice";
import OfficeOfScotland from "../Topics/OfficeOfScotland";
import HouseOfCommons from "../Topics/HouseOfCommons";
import OfficeOfHouseOfLords from "../Topics/OfficeOfHouseOfLords";
import SecretaryForScotland from "../Topics/SecretaryForScotland";
import SecrataryOfWales from "../Topics/SecrataryOfWales";
import ExportFinance from "../Topics/Economy/ExportFinance";
import DepartmentForHousing from "../Topics/DepartmentForHousing";
import OffCanvasUK from "../OffCanvasUK";
import GenerateFeed from "../../GenerateFeed";
import GenerateFeedDepartmentUK from "../../GenerateFeedDepartmentUK copy2";
const CustomModal = ({ isOpen, onClose, title, content }) => (
  <Modal id='fullBillModal' show={isOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={onClose}>
    <Modal.Header id='billModalHeader' closeButton><h3>{title}</h3></Modal.Header>
    {content}
  </Modal>
);
var collectionCounter = 0  
var carouselInterval = 30000;
var mobileCarouselInterval = 60000;

export const UKExecutiveMobile = () =>   {
    render()

    {
  
          const [trendingBillsOptionTen, setTrendingBillsOptionTenModalIsOpen ] = useState(false)
          const [attorneyGeneralModal, setAttorneyGeneralModalIsOpen ] = useState(false)
          const [modal1, setModal1IsOpen] = useState(false);
          const [modal2, setModal2IsOpen] = useState(false);
          const [modal3, setModal3IsOpen] = useState(false);
          const [modal4, setModal4IsOpen] = useState(false);
          const [modal5, setModal5IsOpen] = useState(false);
          const [modal6, setModal6IsOpen] = useState(false);
          const [modal7, setModal7IsOpen] = useState(false);
          const [modal8, setModal8IsOpen] = useState(false);
          const [modal9, setModal9IsOpen] = useState(false);
          const [modal10, setModal10IsOpen] = useState(false);
          const [modal11, setModal11IsOpen] = useState(false);
          const [modal12, setModal12IsOpen] = useState(false);
          const [modal13, setModal13IsOpen] = useState(false);
          const [modal14, setModal14IsOpen] = useState(false);
          const [modal15, setModal15IsOpen] = useState(false);
          const [modal16, setModal16IsOpen] = useState(false);
          const [modal17, setModal17IsOpen] = useState(false);
          const [modal18, setModal18IsOpen] = useState(false);
          const [modal19, setModal19IsOpen] = useState(false);
          const [modal20, setModal20IsOpen] = useState(false);
          const [modal21, setModal21IsOpen] = useState(false);
          const [modal22, setModal22IsOpen] = useState(false);
          const [modal23, setModal23IsOpen] = useState(false);
          const [modalInfo, setModalInfo] = useState({
            isOpen: false,
            title: '',
            content: null
          });
        
          const openModal = (title, content) => {
            setModalInfo({
              isOpen: true,
              title,
              content
            });
          };
        
          const closeModal = () => {
            setModalInfo({
              isOpen: false,
              title: '',
              content: null
            });
          };
        localStorage.setItem('currentPage', 'uk')
    return(
       <>
    <TemplateMobileTopNavbar/>
  
  
  
  
  <div id='homebg'>
  <CustomModal isOpen={modalInfo.isOpen} onClose={closeModal} title={modalInfo.title} content={modalInfo.content} />


<h5 id='branchHeadingMobile'><People/> Executive </h5>

<Col>
  <h5 id='presidentialFeedHeadingMobile' onClick={() => setTrendingBillsOptionTenModalIsOpen(true)}>Prime Minister</h5>

  <div id='primeMinister'></div>
                          
                          {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
    {CreateFeedItemUKAtom('https://www.gov.uk/government/organisations/prime-ministers-office-10-downing-street.atom',
     'primeMinister', 'Prime Minister News', 'Executive', 'N/A', PrimeMinisterNews)}

  <Modal id='mobileFullBillModal' show={trendingBillsOptionTen} 
  size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsOptionTenModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Prime Minister</h3></Modal.Header>
<PrimeMinisterNews/>
</Modal>

    
</Col>

<Col>
  <Link  id='pageLink' to="/uk/attorney-generals-office">
    <h5 id='presidentialFeedHeadingMobile'>Attorney General's Office<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Attorney General\'s Office',
              <GenerateFeedDepartmentUK url='&table=ministerial_departments&order_by=created_at&filter_column=category&filter_string=Attorney%20Generals%20Office'
              name='Attorney Generals Office' branch='Executive' topic='N/A' buttonName='Doc' />)}>News</h5>
          </Row>
          <div class='courtNewsMobile' id='ago'></div>
          {CreateFeedAPIDepartmentUK('&table=ministerial_departments&order_by=created_at&filter_column=category&filter_string=Attorney%20Generals%20Office', 'ago',
            'Attorney General\'s Office', 'Executive',
            'N/A', 'Doc', GenerateFeedDepartmentUK)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/uk/attorney-generals-office'>View all Attorney General's Office news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>


<Col>
  <h5 id='presidentialFeedHeadingMobile' onClick={() => setModal1IsOpen(true)}>Cabinet</h5>

  <div id='cabinet'></div>
                          
                          {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
    {CreateFeedItemUKAtom('https://www.gov.uk/government/organisations/cabinet-office.atom',
     'cabinet', 'Cabinet', 'Executive', 'N/A', CabinetNews)}

  <Modal id='mobileFullBillModal' show={modal1} 
  size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setModal1IsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Cabinet</h3></Modal.Header>
<CabinetNews/>
</Modal>

    
</Col>

{/* <Col>
  <Link  id='pageLink' to="/uk/advocate-general-scotland">
    <h5 id='presidentialFeedHeadingMobile'>Office Of The Advocate General For Scotland<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Office Of The Advocate General For Scotland',
              <GenerateFeedDepartmentUK url='&table=ministerial_departments&order_by=created_at&filter_column=category&filter_string=Office%20Of%20The%20Advocate%20General%20For%20Scotland'
              name='Office Of The Advocate General For Scotland' branch='Executive' topic='Legal' buttonName='Doc' />)}>News</h5>
          </Row>
          <div class='courtNewsMobile' id='test'></div>
          {CreateFeedAPIDepartmentUK('&table=ministerial_departments&order_by=created_at&filter_column=category&filter_string=Office%20Of%20The%20Advocate%20General%20For%20Scotland', 'test',
            'Office Of The Advocate General For Scotland', 'Executive',
            'Legal', 'Doc', GenerateFeedDepartmentUK)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/uk/advocate-general-scotland'>View all Office Of The Advocate General For Scotland news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col> */}

<Col>
  <Link  id='pageLink' to="/uk/department-for-culture-media-and-sport">
    <h5 id='presidentialFeedHeadingMobile'>Department For Culture Media And Sport<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Department For Culture Media And Sport',
              <GenerateFeedDepartmentUK url='&table=ministerial_departments&order_by=created_at&filter_column=category&filter_string=Department%20For%20Culture%20Media%20And%20Sport'
              name='Department For Culture Media And Sport' branch='Executive' topic='Culture' buttonName='Doc' />)}>News</h5>
          </Row>
          <div class='courtNewsMobile' id='dcms'></div>
          {CreateFeedAPIDepartmentUK('&table=ministerial_departments&order_by=created_at&filter_column=category&filter_string=Department%20For%20Culture%20Media%20And%20Sport', 'dcms',
            'Department For Culture Media And Sport', 'Executive',
            'Culture', 'Doc', GenerateFeedDepartmentUK)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/uk/culture-media-sport'>View all Department For Culture Media And Sport news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/uk/secretary-of-state-scotland">
    <h5 id='presidentialFeedHeadingMobile'>Office Of The Secretary Of State For Scotland<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Office Of The Secretary Of State For Scotland',
              <GenerateFeedDepartmentUK url='&table=ministerial_departments&order_by=created_at&filter_column=category&filter_string=Office%20Of%20The%20Secretary%20Of%20State%20For%20Scotland'
              name='Office Of The Secretary Of State For Scotland' branch='Executive' topic='Scottish Affairs' buttonName='Doc' />)}>News</h5>
          </Row>
          <div class='courtNewsMobile' id='secretary-scotland'></div>
          {CreateFeedAPIDepartmentUK('&table=ministerial_departments&order_by=created_at&filter_column=category&filter_string=Office%20Of%20The%20Secretary%20Of%20State%20For%20Scotland', 'secretary-scotland',
            'Office Of The Secretary Of State For Scotland', 'Executive',
            'Scottish Affairs', 'Doc', GenerateFeedDepartmentUK)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/uk/secretary-of-state-scotland'>View all Office Of The Secretary Of State For Scotland news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/uk/department-for-science-innovation-and-technology">
    <h5 id='presidentialFeedHeadingMobile'>Department For Science Innovation And Technology<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Department For Science Innovation And Technology',
              <GenerateFeedDepartmentUK url='&table=ministerial_departments&order_by=created_at&filter_column=category&filter_string=Department%20For%20Science%20Innovation%20And%20Technology'
              name='Department For Science Innovation And Technology' branch='Executive' topic='Science & Tech' buttonName='Doc' />)}>News</h5>
          </Row>
          <div class='courtNewsMobile' id='dsit'></div>
          {CreateFeedAPIDepartmentUK('&table=ministerial_departments&order_by=created_at&filter_column=category&filter_string=Department%20For%20Science%20Innovation%20And%20Technology', 'dsit',
            'Department For Science Innovation And Technology', 'Executive',
            'Science & Tech', 'Doc', GenerateFeedDepartmentUK)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/uk/department-for-science-innovation-and-technology'>View all Department For Science Innovation And Technology news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>


<Col>
  <Link  id='pageLink' to="/uk/hm-treasury">
    <h5 id='presidentialFeedHeadingMobile'>HM Treasury<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('HM Treasury',
              <GenerateFeedDepartmentUK url='&table=ministerial_departments&order_by=created_at&filter_column=category&filter_string=HM%20Treasury'
              name='HM Treasury' branch='Executive' topic='Economy' buttonName='Doc' />)}>News</h5>
          </Row>
          <div class='courtNewsMobile' id='treasury'></div>
          {CreateFeedAPIDepartmentUK('&table=ministerial_departments&order_by=created_at&filter_column=category&filter_string=HM%20Treasury', 'treasury',
            'HM Treasury', 'Executive',
            'Economy', 'Doc', GenerateFeedDepartmentUK)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/uk/hm-treasury'>View all HM Treasury news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>




<Col>
  <Link  id='pageLink' to="/uk/cabinet-office">
    <h5 id='presidentialFeedHeadingMobile'>Cabinet Office<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Cabinet Office',
              <GenerateFeedDepartmentUK url='&table=ministerial_departments&order_by=created_at&filter_column=category&filter_string=Cabinet%20Office'
              name='Cabinet Office' branch='Executive' topic='N/A' buttonName='Doc' />)}>News</h5>
          </Row>
          <div class='courtNewsMobile' id='cabinet'></div>
          {CreateFeedAPIDepartmentUK('&table=ministerial_departments&order_by=created_at&filter_column=category&filter_string=Cabinet%20Office', 'cabinet',
            'Cabinet Office', 'Executive',
            'N/A', 'Doc', GenerateFeedDepartmentUK)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/uk/cabinet-office'>View all Cabinet Office news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>


<Col>
  <Link  id='pageLink' to="/uk/department-for-transport">
    <h5 id='presidentialFeedHeadingMobile'>Department For Transport<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Department For Transport',
              <GenerateFeedDepartmentUK url='&table=ministerial_departments&order_by=created_at&filter_column=category&filter_string=Department%20For%20Transport'
              name='Department For Transport' branch='Executive' topic='Infrastructure' buttonName='Doc' />)}>News</h5>
          </Row>
          <div class='courtNewsMobile' id='dft'></div>
          {CreateFeedAPIDepartmentUK('&table=ministerial_departments&order_by=created_at&filter_column=category&filter_string=Department%20For%20Transport', 'dft',
            'Department For Transport', 'Executive',
            'Infrastructure', 'Doc', GenerateFeedDepartmentUK)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/uk/department-for-transport'>View all Department For Transport news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>


<Col>
  <Link  id='pageLink' to="/uk/foreign-commonwealth-development-office">
    <h5 id='presidentialFeedHeadingMobile'>Foreign Commonwealth Development Office<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Foreign Commonwealth Development Office',
              <GenerateFeedDepartmentUK url='&table=ministerial_departments&order_by=created_at&filter_column=category&filter_string=Foreign%20Commonwealth%20Development%20Office'
              name='Foreign Commonwealth Development Office' branch='Executive' topic='N/A' buttonName='Doc' />)}>News</h5>
          </Row>
          <div class='courtNewsMobile' id='fcdo'></div>
          {CreateFeedAPIDepartmentUK('&table=ministerial_departments&order_by=created_at&filter_column=category&filter_string=Foreign%20Commonwealth%20Development%20Office', 'fcdo',
            'Foreign Commonwealth Development Office', 'Executive',
            'N/A', 'Doc', GenerateFeedDepartmentUK)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/uk/foreign-commonwealth-development-office'>View all Foreign Commonwealth Development Office news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>


<Col>
  <Link  id='pageLink' to="/uk/home-office">
    <h5 id='presidentialFeedHeadingMobile'>Home Office<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Home Office',
              <GenerateFeedDepartmentUK url='&table=ministerial_departments&order_by=created_at&filter_column=category&filter_string=Home%20Office'
              name='Home Office' branch='Executive' topic='Home Affairs' buttonName='Doc' />)}>News</h5>
          </Row>
          <div class='courtNewsMobile' id='home-office'></div>
          {CreateFeedAPIDepartmentUK('&table=ministerial_departments&order_by=created_at&filter_column=category&filter_string=Home%20Office', 'home-office',
            'Home Office', 'Executive',
            'Home Affairs', 'Doc', GenerateFeedDepartmentUK)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/uk/home-office'>View all Home Office news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/uk/department-for-education">
    <h5 id='presidentialFeedHeadingMobile'>Department For Education<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Department For Education',
              <GenerateFeedDepartmentUK url='&table=ministerial_departments&order_by=created_at&filter_column=category&filter_string=Department%20For%20Education'
              name='Department For Education' branch='Executive' topic='N/A' buttonName='Doc' />)}>News</h5>
          </Row>
          <div class='courtNewsMobile' id='dfe'></div>
          {CreateFeedAPIDepartmentUK('&table=ministerial_departments&order_by=created_at&filter_column=category&filter_string=Department%20For%20Education', 'dfe',
            'Department For Education', 'Executive',
            'N/A', 'Doc', GenerateFeedDepartmentUK)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/uk/department-for-education'>View all Department For Education news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>


<Col>
  <Link  id='pageLink' to="/uk/ministry-of-defence">
    <h5 id='presidentialFeedHeadingMobile'>Ministry Of Defense<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Ministry Of Defense',
              <GenerateFeedDepartmentUK url='&table=ministerial_departments&order_by=created_at&filter_column=category&filter_string=Ministry%20Of%20Defence'
              name='Ministry Of Defense' branch='Executive' topic='Defense' buttonName='Doc' />)}>News</h5>
          </Row>
          <div class='courtNewsMobile' id='mod'></div>
          {CreateFeedAPIDepartmentUK('&table=ministerial_departments&order_by=created_at&filter_column=category&filter_string=Ministry%20Of%20Defence', 'mod',
            'Ministry Of Defense', 'Executive',
            'Defense', 'Doc', GenerateFeedDepartmentUK)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/uk/ministry-of-defence'>View all Ministry Of Defence news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>


<Col>
  <Link  id='pageLink' to="/uk/department-for-business-and-trade">
    <h5 id='presidentialFeedHeadingMobile'>Department For Business And Trade<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Department For Business And Trade',
              <GenerateFeedDepartmentUK url='&table=ministerial_departments&order_by=created_at&filter_column=category&filter_string=Department%20For%20Business%20And%20Trade'
              name='Department For Business And Trade' branch='Executive' topic='Economy' buttonName='Doc' />)}>News</h5>
          </Row>
          <div class='courtNewsMobile' id='dbt'></div>
          {CreateFeedAPIDepartmentUK('&table=ministerial_departments&order_by=created_at&filter_column=category&filter_string=Department%20For%20Business%20And%20Trade', 'dbt',
            'Department For Business And Trade', 'Executive',
            'Economy', 'Doc', GenerateFeedDepartmentUK)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/uk/department-for-business-and-trade'>View all Department For Business And Trade news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>




<Col>
  <Link  id='pageLink' to="/uk/leader-house-commons">
    <h5 id='presidentialFeedHeadingMobile'>The Office Of The Leader Of The House Of Commons<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('The Office Of The Leader Of The House Of Commons',
              <GenerateFeedDepartmentUK url='&table=ministerial_departments&order_by=created_at&filter_column=category&filter_string=The%20Office%20Of%20The%20Leader%20Of%20The%20House%20Of%20Commons'
              name='The Office Of The Leader Of The House Of Commons' branch='Executive' topic='Government' buttonName='Doc' />)}>News</h5>
          </Row>
          <div class='courtNewsMobile' id='leader-house-commons'></div>
          {CreateFeedAPIDepartmentUK('&table=ministerial_departments&order_by=created_at&filter_column=category&filter_string=The%20Office%20Of%20The%20Leader%20Of%20The%20House%20Of%20Commons', 'leader-house-commons',
            'The Office Of The Leader Of The House Of Commons', 'Executive',
            'Government', 'Doc', GenerateFeedDepartmentUK)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/uk/leader-house-commons'>View all The Office Of The Leader Of The House Of Commons news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/uk/leader-house-lords">
    <h5 id='presidentialFeedHeadingMobile'>Office Of The Leader Of The House Of Lords<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Office Of The Leader Of The House Of Lords',
              <GenerateFeedDepartmentUK url='&table=ministerial_departments&order_by=created_at&filter_column=category&filter_string=Office%20Of%20The%20Leader%20Of%20The%20House%20Of%20Lords'
              name='Office Of The Leader Of The House Of Lords' branch='Executive' topic='Lords' buttonName='Doc' />)}>News</h5>
          </Row>
          <div class='courtNewsMobile' id='leader-house-lords'></div>
          {CreateFeedAPIDepartmentUK('&table=ministerial_departments&order_by=created_at&filter_column=category&filter_string=Office%20Of%20The%20Leader%20Of%20The%20House%20Of%20Lords', 'leader-house-lords',
            'Office Of The Leader Of The House Of Lords', 'Executive',
            'Lords', 'Doc', GenerateFeedDepartmentUK)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/uk/leader-house-lords'>View all Office Of The Leader Of The House Of Lords news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item> 
  </Carousel>
</Col>





<Col>
  <h5 id='presidentialFeedHeadingMobile' onClick={() => setModal3IsOpen(true)}>Department for Culture Media and Sport</h5>

  <div id='deptForCulture'></div>
                          
                          {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
    {CreateFeedItemUKAtom('https://www.gov.uk/government/organisations/department-for-culture-media-and-sport.atom',
     'deptForCulture', 'Department for Culture Media and Sport', 'Executive', 'Health', DepartmentCultureMediaAndSport)}

  <Modal id='mobileFullBillModal' show={modal3} 
  size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setModal3IsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Department for Culture Media and Sport</h3></Modal.Header>
<DepartmentCultureMediaAndSport/>
</Modal>

    
</Col>



<Col>
  <Link  id='pageLink' to="/uk/department-for-energy-security-and-net-zero">
    <h5 id='presidentialFeedHeadingMobile'>Department For Energy Security And Net Zero<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Department For Energy Security And Net Zero',
              <GenerateFeedDepartmentUK url='&table=ministerial_departments&order_by=created_at&filter_column=category&filter_string=Department%20For%20Energy%20Security%20And%20Net%20Zero'
              name='Department For Energy Security And Net Zero' branch='Executive' topic='Infrastructure' buttonName='Doc' />)}>News</h5>
          </Row>
          <div class='courtNewsMobile' id='desnz'></div>
          {CreateFeedAPIDepartmentUK('&table=ministerial_departments&order_by=created_at&filter_column=category&filter_string=Department%20For%20Energy%20Security%20And%20Net%20Zero', 'desnz',
            'Department For Energy Security And Net Zero', 'Executive',
            'Infrastructure', 'Doc', GenerateFeedDepartmentUK)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/uk/department-for-energy-security-and-net-zero'>View all Department For Energy Security And Net Zero news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>


<Col>
  <Link  id='pageLink' to="/uk/department-for-environment">
    <h5 id='presidentialFeedHeadingMobile'>Department For Environment Food And Rural Affairs<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Department For Environment Food And Rural Affairs',
              <GenerateFeedDepartmentUK url='&table=ministerial_departments&order_by=created_at&filter_column=category&filter_string=Department%20For%20Environment%20Food%20And%20Rural%20Affairs'
              name='Department For Environment Food And Rural Affairs' branch='Executive' topic='Environment' buttonName='Doc' />)}>News</h5>
          </Row>
          <div class='courtNewsMobile' id='defra'></div>
          {CreateFeedAPIDepartmentUK('&table=ministerial_departments&order_by=created_at&filter_column=category&filter_string=Department%20For%20Environment%20Food%20And%20Rural%20Affairs', 'defra',
            'Department For Environment Food And Rural Affairs', 'Executive',
            'Environment', 'Doc', GenerateFeedDepartmentUK)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/uk/department-for-environment'>View all Department For Environment Food And Rural Affairs news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/uk/department-for-levelling-up-housing-and-communities">
    <h5 id='presidentialFeedHeadingMobile'>Department For Levelling Up Housing And Communities<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Department For Levelling Up Housing And Communities',
              <GenerateFeedDepartmentUK url='&table=ministerial_departments&order_by=created_at&filter_column=category&filter_string=Department%20For%20Levelling%20Up%20Housing%20And%20Communities'
              name='Department For Levelling Up Housing And Communities' branch='Executive' topic='Infrastructure' buttonName='Doc' />)}>News</h5>
          </Row>
          <div class='courtNewsMobile' id='dlu-hc'></div>
          {CreateFeedAPIDepartmentUK('&table=ministerial_departments&order_by=created_at&filter_column=category&filter_string=Department%20For%20Levelling%20Up%20Housing%20And%20Communities', 'dlu-hc',
            'Department For Levelling Up Housing And Communities', 'Executive',
            'Infrastructure', 'Doc', GenerateFeedDepartmentUK)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/uk/department-for-levelling-up-housing-and-communities'>View all Department For Levelling Up Housing And Communities news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>






<Col>
  <Link  id='pageLink' to="/uk/department-for-work-and-pensions">
    <h5 id='presidentialFeedHeadingMobile'>Department For Work And Pensions<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Department For Work And Pensions',
              <GenerateFeedDepartmentUK url='&table=ministerial_departments&order_by=created_at&filter_column=category&filter_string=Department%20For%20Work%20And%20Pensions'
              name='Department For Work And Pensions' branch='Executive' topic='Economy' buttonName='Doc' />)}>News</h5>
          </Row>
          <div class='courtNewsMobile' id='dwp'></div>
          {CreateFeedAPIDepartmentUK('&table=ministerial_departments&order_by=created_at&filter_column=category&filter_string=Department%20For%20Work%20And%20Pensions', 'dwp',
            'Department For Work And Pensions', 'Executive',
            'Economy', 'Doc', GenerateFeedDepartmentUK)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/uk/department-for-work-and-pensions'>View all Department For Work And Pensions news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>



<Col>
  <Link  id='pageLink' to="/uk/department-of-health-and-social-care">
    <h5 id='presidentialFeedHeadingMobile'>Department For Health And Social Care<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Department Of Health And Social Care',
              <GenerateFeedDepartmentUK url='&table=ministerial_departments&order_by=created_at&filter_column=category&filter_string=Department%20Of%20Health%20And%20Social%20Care'
              name='Department Of Health And Social Care' branch='Executive' topic='Health' buttonName='Doc' />)}>News</h5>
          </Row>
          <div class='courtNewsMobile' id='dhsc'></div>
          {CreateFeedAPIDepartmentUK('&table=ministerial_departments&order_by=created_at&filter_column=category&filter_string=Department%20Of%20Health%20And%20Social%20Care', 'dhsc',
            'Department For Health And Social Care', 'Executive',
            'Health', 'Doc', GenerateFeedDepartmentUK)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/uk/department-of-health-and-social-care'>View all Department Of Health And Social Care news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>


<Col>
  <h5 id='presidentialFeedHeadingMobile' onClick={() => setModal12IsOpen(true)}>Foreign, Commonwealth & Development Office</h5>

  <div id='deptOfForeign'></div>
                          
                          {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
    {CreateFeedItemUKAtom('https://www.gov.uk/government/organisations/foreign-commonwealth-development-office.atom',
     'deptOfForeign', 'Foreign, Commonwealth & Development Office', 'Executive', 'Foreign Affairs', DepartmentForForeignCommonwealth)}

  <Modal id='mobileFullBillModal' show={modal12} 
  size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setModal12IsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Foreign, Commonwealth & Development Office</h3></Modal.Header>
<DepartmentForForeignCommonwealth/>
</Modal>

    
</Col>




<Col>
  <h5 id='presidentialFeedHeadingMobile' onClick={() => setModal14IsOpen(true)}>Home Office</h5>

  <div id='deptOfHomeOffice'></div>
                          
                          {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
    {CreateFeedItemUKAtom('https://www.gov.uk/government/organisations/home-office.atom',
     'deptOfHomeOffice', 'Home Office', 'Executive', 'N/A', DepartmentForHomeOffice)}

  <Modal id='mobileFullBillModal' show={modal14} 
  size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setModal14IsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Home Office</h3></Modal.Header>
<DepartmentForHomeOffice/>
</Modal>

    
</Col>


<Col>
  <Link  id='pageLink' to="/uk/ministry-of-justice">
    <h5 id='presidentialFeedHeadingMobile'>Ministry Of Justice<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Ministry Of Justice',
              <GenerateFeedDepartmentUK url='&table=ministerial_departments&order_by=created_at&filter_column=category&filter_string=Ministry%20Of%20Justice'
              name='Ministry Of Justice' branch='Executive' topic='Justice' buttonName='Doc' />)}>News</h5>
          </Row>
          <div class='courtNewsMobile' id='moj'></div>
          {CreateFeedAPIDepartmentUK('&table=ministerial_departments&order_by=created_at&filter_column=category&filter_string=Ministry%20Of%20Justice', 'moj',
            'Ministry Of Justice', 'Executive',
            'Justice', 'Doc', GenerateFeedDepartmentUK)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/uk/ministry-of-justice'>View all Ministry Of Justice news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>


<Col>
  <Link  id='pageLink' to="/uk/northern-ireland-office">
    <h5 id='presidentialFeedHeadingMobile'>Northern Ireland Office<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Northern Ireland Office',
              <GenerateFeedDepartmentUK url='&table=ministerial_departments&order_by=created_at&filter_column=category&filter_string=Northern%20Ireland%20Office'
              name='Northern Ireland Office' branch='Executive' topic='N/A' buttonName='Doc' />)}>News</h5>
          </Row>
          <div class='courtNewsMobile' id='nio'></div>
          {CreateFeedAPIDepartmentUK('&table=ministerial_departments&order_by=created_at&filter_column=category&filter_string=Northern%20Ireland%20Office', 'nio',
            'Northern Ireland Office', 'Executive',
            'N/A', 'Doc', GenerateFeedDepartmentUK)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/uk/northern-ireland-office'>View all Northern Ireland Office news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>


<Col>
  <h5 id='presidentialFeedHeadingMobile' onClick={() => setModal18IsOpen(true)}>Office of the Advocate General for Scotland</h5>

  <div id='deptOfScotland'></div>
                          
                          {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
    {CreateFeedItemUKAtom('https://www.gov.uk/government/organisations/office-of-the-advocate-general-for-scotland.atom',
     'deptOfScotland', 'Office of the Advocate General for Scotland', 'Executive', 'N/A', OfficeOfScotland)}

  <Modal id='mobileFullBillModal' show={modal18} 
  size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setModal18IsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Office of the Advocate General for Scotland</h3></Modal.Header>
<OfficeOfScotland/>
</Modal>

    
</Col>

<Col>
  <Link  id='pageLink' to="/uk/the-office-of-the-leader-of-the-house-of-commons">
    <h5 id='presidentialFeedHeadingMobile'>The Office Of The Leader Of The House Of Commons<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('The Office Of The Leader Of The House Of Commons',
              <GenerateFeedDepartmentUK url='&table=ministerial_departments&order_by=created_at&filter_column=category&filter_string=The%20Office%20Of%20The%20Leader%20Of%20The%20House%20Of%20Commons'
              name='The Office Of The Leader Of The House Of Commons' branch='Executive' topic='N/A' buttonName='Doc' />)}>News</h5>
          </Row>
          <div class='courtNewsMobile' id='commons-leader'></div>
          {CreateFeedAPIDepartmentUK('&table=ministerial_departments&order_by=created_at&filter_column=category&filter_string=The%20Office%20Of%20The%20Leader%20Of%20The%20House%20Of%20Commons', 'commons-leader',
            'The Office Of The Leader Of The House Of Commons', 'Executive',
            'N/A', 'Doc', GenerateFeedDepartmentUK)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/uk/the-office-of-the-leader-of-the-house-of-commons'>View all The Office Of The Leader Of The House Of Commons news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/uk/office-of-the-leader-of-the-house-of-lords">
    <h5 id='presidentialFeedHeadingMobile'>Office Of The Leader Of The House Of Lords<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Office Of The Leader Of The House Of Lords',
              <GenerateFeedDepartmentUK url='&table=ministerial_departments&order_by=created_at&filter_column=category&filter_string=Office%20Of%20The%20Leader%20Of%20The%20House%20Of%20Lords'
              name='Office Of The Leader Of The House Of Lords' branch='Executive' topic='N/A' buttonName='Doc' />)}>News</h5>
          </Row>
          <div class='courtNewsMobile' id='lords-leader'></div>
          {CreateFeedAPIDepartmentUK('&table=ministerial_departments&order_by=created_at&filter_column=category&filter_string=Office%20Of%20The%20Leader%20Of%20The%20House%20Of%20Lords', 'lords-leader',
            'Office Of The Leader Of The House Of Lords', 'Executive',
            'N/A', 'Doc', GenerateFeedDepartmentUK)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/uk/office-of-the-leader-of-the-house-of-lords'>View all Office Of The Leader Of The House Of Lords news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>


<Col>
  <h5 id='presidentialFeedHeadingMobile' onClick={() => setModal21IsOpen(true)}>Office of the Secretary of State for Scotland</h5>

  <div id='deptOfSecScot'></div>
                          
                          {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
    {CreateFeedItemUKAtom('https://www.gov.uk/government/organisations/office-of-the-secretary-of-state-for-scotland.atom',
     'deptOfSecScot', 'Office of the Secretary of State for Scotland ', 'Executive', 'N/A', SecretaryForScotland)}

  <Modal id='mobileFullBillModal' show={modal21} 
  size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setModal21IsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Office of the Secretary of State for Scotland </h3></Modal.Header>
<SecretaryForScotland/>
</Modal>

    
</Col>

<Col>
  <Link  id='pageLink' to="/uk/office-of-the-secretary-of-state-for-wales">
    <h5 id='presidentialFeedHeadingMobile'>Office Of The Secretary Of State For Wales<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Office Of The Secretary Of State For Wales',
              <GenerateFeedDepartmentUK url='&table=ministerial_departments&order_by=created_at&filter_column=category&filter_string=Office%20Of%20The%20Secretary%20Of%20State%20For%20Wales'
              name='Office Of The Secretary Of State For Wales' branch='Executive' topic='N/A' buttonName='Doc' />)}>News</h5>
          </Row>
          <div class='courtNewsMobile' id='wales'></div>
          {CreateFeedAPIDepartmentUK('&table=ministerial_departments&order_by=created_at&filter_column=category&filter_string=Office%20Of%20The%20Secretary%20Of%20State%20For%20Wales', 'wales',
            'Office Of The Secretary Of State For Wales', 'Executive',
            'N/A', 'Doc', GenerateFeedDepartmentUK)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/uk/office-of-the-secretary-of-state-for-wales'>View all Office Of The Secretary Of State For Wales news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>


<Col>
  <Link  id='pageLink' to="/uk/uk-export-finance">
    <h5 id='presidentialFeedHeadingMobile'>UK Export Finance<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('UK Export Finance',
              <GenerateFeedDepartmentUK url='&table=ministerial_departments&order_by=created_at&filter_column=category&filter_string=UK%20Export%20Finance'
              name='UK Export Finance' branch='Executive' topic='Economy' buttonName='Doc' />)}>News</h5>
          </Row>
          <div class='courtNewsMobile' id='ukef'></div>
          {CreateFeedAPIDepartmentUK('&table=ministerial_departments&order_by=created_at&filter_column=category&filter_string=UK%20Export%20Finance', 'ukef',
            'UK Export Finance', 'Executive',
            'Economy', 'Doc', GenerateFeedDepartmentUK)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/uk/uk-export-finance'>View all UK Export Finance news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>


<Col>

<br></br><br></br><br></br><br></br>
<br></br>
<br></br>
<h5 id='headingSelectHomeCountry'>Support the Gov Glance Foundation  <Button id='donateButton' onClick={(e) => {
                        e.preventDefault();
                        window.open('https://donate.stripe.com/28o3fXbdvbQO2RO288', "_blank")
                      }}><HeartFill id='donateIcon'/>    Donate</Button></h5>

</Col>

<br></br><br></br><br></br><br></br><br></br><br></br>

<Navbar id='navbarMobileBottom' fixed="bottom">  



       
<div id='changeBranchDropdownMobile'>

                   <OffCanvasUK/>
              
            </div>

</Navbar>
 
             
 
 
 
 
 
 

</div>



</>
)
}}
export default UKExecutiveMobile;


