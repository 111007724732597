import React, { useState } from 'react';
import { Button, Image, Modal, Navbar } from 'react-bootstrap';
import { Files, Search } from 'react-bootstrap-icons';
import { BrowserView, MobileView } from 'react-device-detect';
import CandidateJSONTable from './CandidateJSONTable';
import CandidateJSONTableMobile from './CandidateJSONTableMobile';
import MobileOffCanvas from './MobileOffCanvas'; // Replace with your component if needed

function formatDateString(dateString) {
  // Implement your date formatting logic here if needed
  return dateString; // Placeholder for now
}

function CandidatePage(props) {
  const [showModal, setShowModal] = useState(false);
  const [searchQueryModal, setSearchQueryModal] = useState('');
  const [isSearchTriggered, setIsSearchTriggered] = useState(false);

  const { name, office, state, party, district, fillingDate, id } = props.location.state;
  console.log(id)
  // Implement any truncation or formatting functions if needed
  const truncatedDescription = ''; // Implement if needed

  const handleViewMoreClick = () => {
    setShowModal(true);
  };

  return (
    <>
      <BrowserView>
        <div id='homebg'>
          <br />
          <div id='feedDisplay' style={{ padding: '20px', borderRadius: '18px', marginTop: '0em' }}>
            <div style={{ position: 'relative' }}>
              {/* Display person's image if available */}
              <div id='profileImage' style={{ width: '150px', height: '150px', borderRadius: '100%', overflow: 'hidden', marginLeft: '40px' }}>
                {/* <Image id='profileImage-image' src={imageSource} /> */}
              </div>

              <div style={{ position: 'absolute', top: '0', left: '250px', display: 'flex', flexDirection: 'column' }}>
                <div style={{ color: 'white', fontSize: '24px', fontWeight: 'bold' }}>
                  <span>{name}</span>
                </div>
                <div style={{ color: 'white', fontSize: '20px' }}>
                  <span>{office} (Candidate) {state}  {party}</span>
         
                  {office === 'House' && district && <><br /><span>{district}</span></>}
                  <br />
                  <span>{formatDateString(fillingDate)}</span>
                </div>
              </div>
            </div>

            {/* Button to view more details */}
            <br />
            <Button id='whDocsViewMoreButton' onClick={handleViewMoreClick}><Files size={20} /> Read More</Button>
            <br />
            <div style={{ color: 'white', fontSize: '18px', fontWeight: 'bold' }}>Coming Soon</div>

            {/* Modal for displaying more details */}
            <Modal id='fullBillModal' size="xl" aria-labelledby="contained-modal-title-vcenter" show={showModal} onHide={() => setShowModal(false)}>
              <Modal.Header id='billModalHeader' closeButton><h3>{name}'s Details</h3></Modal.Header>
              <div id='fullBillFeed'>
                {/* Implement additional details here */}
                <p>{/* Detailed information */}</p>
              </div>
            </Modal>
          </div>
          <div style={{ color: 'white', fontSize: '18px', fontWeight: 'bold' }}>
             2024 Election Campaign Finances
             
          <CandidateJSONTable id={id}/>
          </div>
        </div>
      </BrowserView>

      <MobileView>
        <div id='homebg'>
          <br />
          <div id='feedDisplay' style={{ padding: '20px', borderRadius: '18px', marginTop: '10px', paddingTop: '8px' }}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              {/* Display person's image if available */}
              <div id='profileImageOnPage'>
                {/* <Image id='profileImage-imageOnPage' src={imageSource} /> */}
              </div>

              <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '20px' }}>
                <div style={{ color: 'white', fontSize: '18px', fontWeight: 'bold' }}>
                  <span>{name}</span>
                </div>
                <div style={{ color: 'white', fontSize: '16px' }}>
                  <span>{office}</span>
                  <br />
                  <span>{state}</span>
                  <br />
                  <span>{party}</span>
                  {district && <><br /><span>{district}</span></>}
                </div>
              </div>
            </div>

            {/* Button to view more details */}
            <br />
            <Button id='presidentViewMoreButton' onClick={handleViewMoreClick}><Files size={20} /> Read More</Button>
            <br />

            <div style={{ color: 'white', fontSize: '18px', fontWeight: 'bold' }}>
             2024 Election Campaign Finances
             
          <CandidateJSONTableMobile id={id}/>
          </div>

            {/* Modal for displaying more details */}
            <Modal id='fullBillModal' size="xl" aria-labelledby="contained-modal-title-vcenter" show={showModal} onHide={() => setShowModal(false)}>
              <Modal.Header id='billModalHeader' closeButton><h3>{name}'s Details</h3></Modal.Header>
              <div id='fullBillFeed'>
                {/* Implement additional details here */}
                <p>{/* Detailed information */}</p>
              </div>
            </Modal>

          {/* <CandidateJSONTable id={id}/> */}
            {/* Off-canvas menu or other mobile-specific components */}
            <div id='mobileNavbarSpacer'></div>
            <Navbar id='navbarMobileBottom' fixed="bottom">  

          



<div id='navbarSpacer'></div><div id='navbarSpacer'></div>




       
<div id='changeBranchDropdownMobile'>

           <MobileOffCanvas/>
              
            </div>

           


           
 
             
 
 
 
 
 
 
</Navbar>
          </div>
        </div>
      </MobileView>
    </>
  );
}

export default CandidatePage;
