
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link, Switch, NavLink,  } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment,  } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Tooltip, OverlayTrigger, Image, ModalFooter } from 'react-bootstrap';
import { ArrowsAngleExpand, ArrowsFullscreen, InfoCircle, FileText, FileMedicalFill, Coin, Tree, Globe2,  ShieldShaded, CircleFill, Pen, People, ChatSquareQuote, ChatLeftQuote, BlockquoteRight, Collection, Grid, ThreeDots, Stack, List, Files, FolderPlus, FolderCheck, Folder, CaretRight, ChevronRight, ChevronCompactRight, CaretRightFill, ArrowRightShort,  ArrowRightCircle, PlayCircle, PauseCircle, GeoAlt, HeartFill, QuestionCircleFill, MicFill, ReceiptCutoff } from 'react-bootstrap-icons'

import { BrowserView, MobileView } from 'react-device-detect';
import '../../../App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import Axios from 'axios'
import { render } from '@testing-library/react';
import DepartmentForBusiness from '../../Executive/DepartmentForBusiness';
import { CreateFeedItemUKAtom } from '../../../Functions';
import DepartmentForWork from './DepartmentForWork';
import DepartmentForHMTreasury from './DepartmentForHMTreasury';
import ExportFinance from './ExportFinance';
import UKDesktopNavbar from '../../UKDesktopNavbar';
import UKEconomyMobile from './UKEconomyMobile';

export const UKEconomy = () =>   {
  render()

 
  {

  const [reportModalIsOpen, setReportModalIsOpen] = useState(false)
  const [trendingBillsModalIsOpen, setTrendingBillsModalIsOpen] = useState(false)
  const [trendingBillsOptionOneModalIsOpen, setTrendingBillsOptionOneModalIsOpen] = useState(false)
  const [collectionsModalIsOpen, setCollectionsModalIsOpen] = useState(false)
  const [aboutModalIsOpen, setAboutModalIsOpen] = useState(false)
  const [modal1, setModal1IsOpen] = useState(false);
const [modal2, setModal2IsOpen] = useState(false);
const [modal3, setModal3IsOpen] = useState(false);
const [modal4, setModal4IsOpen] = useState(false);
const [modal5, setModal5IsOpen] = useState(false);
const [modal6, setModal6IsOpen] = useState(false);
const [modal7, setModal7IsOpen] = useState(false);
const [modal8, setModal8IsOpen] = useState(false);
const [modal9, setModal9IsOpen] = useState(false);
const [modal10, setModal10IsOpen] = useState(false);
const [modal11, setModal11IsOpen] = useState(false);
const [modal12, setModal12IsOpen] = useState(false);
const [modal13, setModal13IsOpen] = useState(false);
const [modal14, setModal14IsOpen] = useState(false);
const [modal15, setModal15IsOpen] = useState(false);
const [modal16, setModal16IsOpen] = useState(false);
const [modal17, setModal17IsOpen] = useState(false);
const [modal18, setModal18IsOpen] = useState(false);
const [modal19, setModal19IsOpen] = useState(false);
const [modal20, setModal20IsOpen] = useState(false);
const [modal23, setModal23IsOpen] = useState(false);

  return (
    
    <>
    
      <div className="App">
   
    




      <BrowserView>
      <div id='homePage'>
        


        <div id='homebg'>

     

          

         <UKDesktopNavbar/>
       

         <div id='sectionHeading'>
            
         <h3 id='branchHeading' ><Coin></Coin> <b>Economy</b></h3>
            
            </div>
                     
           
            <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferTwo'>
                   
                    <Row>

                      <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>

                          <h4 id='feedHeadingBillsEnrolled' onClick={() => setReportModalIsOpen(true)}><b>Department for Business and Trade</b></h4>
                          <div id='deptOfBusinessAndTrade'></div>
                          
                          {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
    {CreateFeedItemUKAtom('https://www.gov.uk/government/organisations/department-for-business-and-trade.atom',
     'deptOfBusinessAndTrade', 'Department of Business and Trade', 'Executive', 'Economy', DepartmentForBusiness)}

              <Modal id='mobileFullBillModal' show={reportModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setReportModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Department of Business and Trade</h3></Modal.Header>
<DepartmentForBusiness/>
</Modal>
                        </div>
                      </Col>

                      <Col id='legislativeColRight'>
                        <div id='columnRightPadding'>
                  
                        <h4 id='feedHeadingBillsEnrolled' onClick={() => setTrendingBillsOptionOneModalIsOpen(true)}><b>Department for Work & Pensions </b></h4>
                        <div id='deptOfWork'></div>
                          
                          {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
    {CreateFeedItemUKAtom('https://www.gov.uk/government/organisations/department-for-work-pensions.atom',
     'deptOfWork', 'Department for Work & Pensions', 'Executive', 'Economy', DepartmentForWork)}


  <Modal id='mobileFullBillModal' show={trendingBillsOptionOneModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsOptionOneModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Department for Work & Pensions</h3></Modal.Header>
<DepartmentForWork/>
</Modal>
                        </div>
                      </Col>

            


                    </Row>


                  </Container>
                </Container>
             
              </div>

              <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferTwo'>
                   
                    <Row>

                      <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>

                          <h4 id='feedHeadingBillsEnrolled' onClick={() => setModal13IsOpen(true)}><b>HM Treasury</b></h4>
                          <div id='deptOfTreasury'></div>
                          
                          {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
    {CreateFeedItemUKAtom('https://www.gov.uk/government/organisations/hm-treasury.atom',
     'deptOfTreasury', 'HM Treasury', 'Executive', 'Economy', DepartmentForHMTreasury)}

              <Modal id='mobileFullBillModal' show={modal13} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setModal13IsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>HM Treasury</h3></Modal.Header>
<DepartmentForHMTreasury/>
</Modal>
                        </div>
                      </Col>

                      <Col id='legislativeColRight'>
                        <div id='columnRightPadding'>
                  
                        <h4 id='feedHeadingBillsEnrolled' onClick={() => setModal23IsOpen(true)}><b>Export Finance</b></h4>
                        <div id='exportFinance'></div>
                          
                          {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
    {CreateFeedItemUKAtom('https://www.gov.uk/government/organisations/uk-export-finance.atom',
     'exportFinance', 'Export Finance ', 'Executive', 'Economy', ExportFinance)}


  <Modal id='mobileFullBillModal' show={modal23} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setModal23IsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Export Finance</h3></Modal.Header>
<ExportFinance/>
</Modal>
                        </div>
                      </Col>

            


                    </Row>


                  </Container>
                </Container>
             
              </div>

          <Modal id='aboutModal' show={aboutModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setAboutModalIsOpen(false)}>
            <div id='removedAlert'></div>
            <Modal.Header id='aboutModalHeader' closeButton><h3>About</h3></Modal.Header>
  <ModalBody id='aboutInfo'><p id='aboutParagraph'>Gov Glance was created to drive civic engagement and support a 
              healthier democracy by helping citizens get official Government news in an easy-to-navigate centralized location. 
              There will never be any opinion or political analysis(That's your job), only information from official Government 
              sources. Gov Glance is structured after the U.S. government that is broken into three branches Legislative, 
              Executive, and Judicial. To learn more about the structure and functions of The U.S. Government go to <a href='https://www.usa.gov/branches-of-government'>https://www.usa.gov/branches-of-government</a>.
              </p>

              <p id='aboutParagraph'>
              Gov Glance Foundation Inc. is a non-profit that was created to operate govglance.org and continue to create educational tools that help people learn about the workings of society
              </p>
            <br></br>

            <h3>Privacy</h3>

            <br></br>

              <p id='aboutParagraph'>Gov Glance does not collect any personal user information. 
              Features like collection save news items directly to your browser's local storage for your convenience
              and is not shared with Gov Glance servers. We do not collect, store, or send personal information to Google LLC, 
              but we do use some Google services to deliver our app to you. For any questions into Google’s privacy practices 
              here is a link to their privacy policy: <a href='https://policies.google.com/privacy?hl=en-US#intro'>https://policies.google.com/privacy?hl=en-US#intro</a>

              <br></br><br></br>

              For site analytics we use plausible.io an open source privacy friendly analytics provider. <a href="https://plausible.io/about" target="_blank">https://plausible.io/about</a>
            
            </p>
            <br></br>


            <h3>Contact</h3>

            <p id='aboutParagraph'>
                      Contact us via email: <a href='mailto:contact@govglance.org'>contact@govglance.org</a>

            </p>

            <br></br>


            
            </ModalBody>
          </Modal>
 
     <footer id='homeFooter'>
      <Navbar>
        <Row id='footerNav'>
      <Nav variant='pills'>
        
     <Nav.Link id='footerContent'>Gov Glance Foundation Inc.
</Nav.Link>
<Nav.Item>
<Nav.Link id='footerContent' href='mailto:contact@govglance.org'>Contact</Nav.Link>
</Nav.Item>
<Nav.Item>
<Nav.Link id='footerContent' onClick={(e) => {
                   e.preventDefault();
                   window.open('https://donate.stripe.com/28o3fXbdvbQO2RO288', "_blank")
                 }}> <HeartFill id='donateIcon'/>    Donate</Nav.Link>
</Nav.Item>
<Nav.Link onClick={(e) => {
                   e.preventDefault();
                   window.open('/about', "_self")}} id='footerContent'>Privacy</Nav.Link>
    
     
 </Nav>
 </Row>
 </Navbar>
      </footer>

      


    
          </div>
  

    </div>
      </BrowserView>
     

      <MobileView> 
        
        
             <UKEconomyMobile/>         

     
      </MobileView>
        </div>
      


       
    </>

     
    
  );







}
}


export default UKEconomy;
