// useHandleContainerClick.js
import { useHistory } from 'react-router-dom';

const useHandleContainerClick = () => {
  const history = useHistory();

  const handleContainerClick = (bioGuideId, name, webURL, imageSource, chamber, startYear, stateName, party, address) => {
    // Save the name here or perform any other necessary actions
    console.log("Bio Guide ID:", bioGuideId);
    console.log("Name:", name);

    // Navigate to the representative page with bioGuideId and name as state
    history.push(`/us/representative/${bioGuideId}`, { 
      name,
      webURL,
      imageSource,
      chamber,
      startYear,
      stateName,
      party,
      address
    });
  };

  return handleContainerClick;
};

export default useHandleContainerClick;
