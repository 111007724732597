import React, { useState, useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
require('dotenv').config();
const apiKey = process.env.REACT_APP_API_KEY;

function formatDate(inputDate) {
  const date = new Date(inputDate);
  const utcDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
  const day = utcDate.getDate();
  const month = utcDate.getMonth() + 1;
  const year = utcDate.getFullYear();
  return `${month}-${day}-${year}`;
}

function CandidateJSONTableMobile({ id }) {
  const [candidateData, setCandidateData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${apiKey}`,
        },
      };

      try {
        const response = await fetch(
          `https://api.govglance.org/elections/election_candidates_financials/?candidate_id=${id}&limit=1&skip=0`,
          requestOptions
        );
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const responseData = await response.json();
        setCandidateData(responseData[0]); // Assuming data is returned as an array
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [id]);

  if (!candidateData) {
    return <Spinner animation="grow" variant="light" />;
  }

  return (
    <Table striped bordered hover size="sm" style={{ color: 'white', borderColor: 'white' }}>
      <tbody>
        <tr>
          <td style={{ color: 'white', borderColor: 'white', width: '50%' }}>
            <strong>Candidate Contribution:</strong>
          </td>
          <td style={{ color: 'white', borderColor: 'white' }}>
            ${candidateData.candidate_contribution.toLocaleString()}
          </td>
        </tr>
        <tr>
          <td style={{ color: 'white', borderColor: 'white' }}>
            <strong>Election Year:</strong>
          </td>
          <td style={{ color: 'white', borderColor: 'white' }}>
            {candidateData.candidate_election_year}
          </td>
        </tr>
        <tr>
          <td style={{ color: 'white', borderColor: 'white' }}>
            <strong>Contribution Refunds:</strong>
          </td>
          <td style={{ color: 'white', borderColor: 'white' }}>
            ${candidateData.contribution_refunds.toLocaleString()}
          </td>
        </tr>
        <tr>
          <td style={{ color: 'white', borderColor: 'white' }}>
            <strong>Contributions:</strong>
          </td>
          <td style={{ color: 'white', borderColor: 'white' }}>
            ${candidateData.contributions.toLocaleString()}
          </td>
        </tr>
        <tr>
          <td style={{ color: 'white', borderColor: 'white' }}>
            <strong>Coverage End Date:</strong>
          </td>
          <td style={{ color: 'white', borderColor: 'white' }}>
            {formatDate(candidateData.coverage_end_date)}
          </td>
        </tr>
        <tr>
          <td style={{ color: 'white', borderColor: 'white' }}>
            <strong>Disbursements:</strong>
          </td>
          <td style={{ color: 'white', borderColor: 'white' }}>
            ${candidateData.disbursements.toLocaleString()}
          </td>
        </tr>
        <tr>
          <td style={{ color: 'white', borderColor: 'white' }}>
            <strong>Individual Contributions:</strong>
          </td>
          <td style={{ color: 'white', borderColor: 'white' }}>
            ${candidateData.individual_contributions.toLocaleString()}
          </td>
        </tr>
        <tr>
          <td style={{ color: 'white', borderColor: 'white' }}>
            <strong>Individual Itemized Contributions:</strong>
          </td>
          <td style={{ color: 'white', borderColor: 'white' }}>
            ${candidateData.individual_itemized_contributions.toLocaleString()}
          </td>
        </tr>
        <tr>
          <td style={{ color: 'white', borderColor: 'white' }}>
            <strong>Individual Unitemized Contributions:</strong>
          </td>
          <td style={{ color: 'white', borderColor: 'white' }}>
            ${candidateData.individual_unitemized_contributions.toLocaleString()}
          </td>
        </tr>
        <tr>
          <td style={{ color: 'white', borderColor: 'white' }}>
            <strong>Last Cash on Hand End Period:</strong>
          </td>
          <td style={{ color: 'white', borderColor: 'white' }}>
            ${candidateData.last_cash_on_hand_end_period.toLocaleString()}
          </td>
        </tr>
        <tr>
          <td style={{ color: 'white', borderColor: 'white' }}>
            <strong>Debts Owed by Committee:</strong>
          </td>
          <td style={{ color: 'white', borderColor: 'white' }}>
            ${candidateData.last_debts_owed_by_committee.toLocaleString()}
          </td>
        </tr>
        <tr>
          <td style={{ color: 'white', borderColor: 'white' }}>
            <strong>Operating Expenditures:</strong>
          </td>
          <td style={{ color: 'white', borderColor: 'white' }}>
            ${candidateData.operating_expenditures.toLocaleString()}
          </td>
        </tr>
        <tr>
          <td style={{ color: 'white', borderColor: 'white' }}>
            <strong>Other Disbursements:</strong>
          </td>
          <td style={{ color: 'white', borderColor: 'white' }}>
            ${candidateData.other_disbursements.toLocaleString()}
          </td>
        </tr>
        {candidateData.other_political_committee_contributions && candidateData.other_receipts && candidateData.receipts && candidateData.refunded_individual_contributions && candidateData.total_offsets_to_operating_expenditures && candidateData.transfers_from_affiliated_committee && (
          <>
            <tr>
              <td style={{ color: 'white', borderColor: 'white' }}>
                <strong>Other Political Committee Contributions:</strong>
              </td>
              <td style={{ color: 'white', borderColor: 'white' }}>
                ${candidateData.other_political_committee_contributions.toLocaleString()}
              </td>
            </tr>
            <tr>
              <td style={{ color: 'white', borderColor: 'white' }}>
                <strong>Other Receipts:</strong>
              </td>
              <td style={{ color: 'white', borderColor: 'white' }}>
                ${candidateData.other_receipts.toLocaleString()}
              </td>
            </tr>
            <tr>
              <td style={{ color: 'white', borderColor: 'white' }}>
                <strong>Receipts:</strong>
              </td>
              <td style={{ color: 'white', borderColor: 'white' }}>
                ${candidateData.receipts.toLocaleString()}
              </td>
            </tr>
            <tr>
              <td style={{ color: 'white', borderColor: 'white' }}>
                <strong>Refunded Individual Contributions:</strong>
              </td>
              <td style={{ color: 'white', borderColor: 'white' }}>
                ${candidateData.refunded_individual_contributions.toLocaleString()}
              </td>
            </tr>
            <tr>
              <td style={{ color: 'white', borderColor: 'white' }}>
                <strong>Total Offsets to Operating Expenditures:</strong>
              </td>
              <td style={{ color: 'white', borderColor: 'white' }}>
                ${candidateData.total_offsets_to_operating_expenditures.toLocaleString()}
              </td>
            </tr>
            <tr>
              <td style={{ color: 'white', borderColor: 'white' }}>
                <strong>Transfers from Affiliated Committee:</strong>
              </td>
              <td style={{ color: 'white', borderColor: 'white' }}>
                ${candidateData.transfers_from_affiliated_committee.toLocaleString()}
              </td>
            </tr>
          </>
        )}
      </tbody>
    </Table>
  );
}

export default CandidateJSONTableMobile;
