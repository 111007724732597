import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link, Switch,  } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment,  } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Tooltip, OverlayTrigger, Image, ModalFooter } from 'react-bootstrap';
import { ArrowsAngleExpand, ArrowsFullscreen, InfoCircle, FileText,  ShieldShaded, CircleFill, Pen, People, ChatSquareQuote, ChatLeftQuote, BlockquoteRight, Collection, Grid, ThreeDots, Stack, List, Files, FolderPlus, FolderCheck, Folder, CaretRight, ChevronRight, ChevronCompactRight, CaretRightFill, ArrowRightShort,  ArrowRightCircle, PlayCircle, PauseCircle } from 'react-bootstrap-icons'
import { BrowserView, MobileView } from 'react-device-detect';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import Axios from 'axios'
import { render } from '@testing-library/react';
import FullBillFeed from './FullBillFeed';
import FullWhiteHouseFeed from './FullWhiteHouseFeed';
import FullUSCourtNews from './FullUSCourtNews';
import FullWhiteHouseDocuments from './FullWhiteHouseDocuments';
import DailyDigest from './DailyDigest';
import State from './State';
import Illinois from './state/Illinois';
import CollectionPage from './CollectionPage';
import NewLaws from './NewLaws';
import BillsEnrolled from './BillsEnrolled';
import DepartmentOfState from './DepartmentOfState';
import DepartmentOfTreasury from './DepartmentOfTreasury';
import CourtAppeals from './CourtAppeals';
import Reports from './Reports';
import DepartmentOfDefense from './DepartmentOfDefense';
import DepartmentOfJustice from './DepartmentOfJustice';
import DepartmentOfTheInterior from './DepartmentOfTheInterior';
import DepartmentOfAgriculture from './DepartmentOfAgriculture';
import DepartmentOfCommerce from './DepartmentOfCommerce';
import DepartmentOfLabor from './DepartmentOfLabor';
import DepartmentOfHealthAndHumanServices from './DepartmentOfHealthAndHumanServices';
import DepartmentOfHousingAndUrbanDevelopment from './DepartmentOfHousingAndUrbanDevelopment';
import DepartmentOfTransportation from './DepartmentOfTransportation';
import DepartmentOfEnergy from './DepartmentOfEnergy';
import DepartmentOfEducation from './DepartmentOfEducation';
import DepartmentOfVeteranAffairs from './DepartmentOfVeteranAffairs';
import DepartmentOfHomelandSecurity from './DepartmentOfHomelandSecurity';
import EnvironmentalProtectionAgency from './EnvironmentalProtectionAgency';
import NationalAeronauticsAndSpaceAdministration from './NationalAeronauticsAndSpaceAdministration';
import TrendingBills from './TrendingBills';
import GAOReports from './GAOReports';
import LegislativeBranch from './LegislativeBranch';
import HouseComitteeOnAgriculture from './HouseComitteeOnAgriculture';
import HouseCommitteeOnAppropriations from './HouseCommitteeOnArmedServices';
import HouseCommitteeOnOversightAndReform from './HouseCommitteeOnOversightAndReform';

import App from './App';
import Overlay from 'react-overlays/esm/Overlay';
import HouseCommitteeOnArmedServices from './HouseCommitteeOnArmedServices';
import HouseCommitteeOnTheBudget from './HouseCommitteeOnTheBudget';
import HouseCommitteeOnEducationAndLabor from './HouseCommitteeOnEducationAndLabor';
import HouseFloor from './HouseFloor';
import HouseCommitteeOnEnergyAndCommerce from './HouseCommitteeOnEnergyAndCommerce';
import HouseCommitteeOnEthics from './HouseCommitteeOnEthics';
import CongressionalHearings from './CongressionalHearings';
import HouseCommitteeOnFinacialServices from './HouseCommitteeOnFinacialServices';
import HouseCommitteeOnForeignAffairs from './HouseCommitteeOnForeignAffairs';
import HouseCommitteeOnHomelandSecurity from './HouseCommitteeOnHomelandSecurity';
import HouseCommitteeOnTheJudiciary from './HouseCommitteeOnTheJudiciary';
import HouseCommitteeOnNaturalResources from './HouseCommitteeOnNaturalResources';
import HouseCommitteeOnHouseAdministration from './HouseCommitteeOnHouseAdministration';
import CongressionalBudgetOffice from './CongressionalBudgetOffice';
import PresidentialProclamations from './PresidentialProclamations';
import ExecutiveOrders from './ExecutiveOrders';
import{ whiteHouseSearch, createPresidentialProclamations, createExecutiveOrders, createWhiteHouseDocuments, createWhiteHouseFeed,
    createWhiteHouseFeedMobile,createDepartmentOfState,
    createDepartmentOfDefense, 
    createDepartmentOfJustice, createDepartmentOfTheInterior,
    createDepartmentOfAgriculture, createDepartmentOfCommerce,
    createDepartmentOfCommerceMobile, createDepartmentOfLabor,
    createDepartmentOfHealthAndHumanServices, createDepartmentOfHousingAndUrbanDevelopment, createDepartmentOfEducation
    ,  createDepartmentOfVeteransAffairs, createDepartmentOfHomelandSecurity
    , createEnvironmentalProtectionAgency,
    createDepartmentOfTreasury, createNationalScienceFoundation, 
     createEnvironmentalProtectionAgencyMobile, createNationalAeronauticsAndSpaceAdministration, Speak, createFederalRegisterDefenseDepartment, 
     createFederalRegisterStateDepartment, createFederalRegisterDefense,
    CreateFeedItem, CreateFeedItemPresidentialDocuments, CreateExecutiveOrders,
    CreateFeedItemExecutiveOrders,
    CreateFeedItemBillUpdates,
    CreateFeedItemExecutiveOffice, CreateFeedItemMaterials, CreateFeedAPI  } from './Functions'
import NationalScienceFoundation from './NationalScienceFoundation';
import FederalRegisterDefenseDepartment from './FederalRegisterDefenseDepartment';
import FederalRegisterStateDepartment from './FederalRegisterStateDepartment';
import FederalRegisterTreasuryDepartment from './FederalRegisterTreasuryDepartment';
import MobileOffCanvas from './MobileOffCanvas';
import MobileTopNavbar from './MobileTopNavbar';
import BillUpdates from './BillUpdates';
import CouncilOfEconomicAdvisers from './CouncilOfEconomicAdvisers';
import CouncilOnEnvironmentQuality from './CouncilOnEnvironmentQuality';
import DomesticPolicyCouncil from './DomesticPolicyCouncil';
import GenderPolicyCouncil from './GenderPolicyCouncil';
import NationalEconomicCouncil from './NationalEconomicCouncil';
import NationalSecurityCouncil from './NationalSecurityCouncil';
import OfficeOfIntergovernmentalAffairs from './OfficeOfIntergovernmentalAffairs';
import OfficeOfManagmentAndBudget from './OfficeOfManagmentAndBudget';
import OfficeOfNationalDrugControlPolicy from './OfficeOfNationalDrugControlPolicy';
import OfficeOfPublicEngagement from './OfficeOfPublicEngagement';
import OfficeOfScienceAndTechnologyPolicy from './OfficeOfScienceAndTechnologyPolicy';
import OfficeOfNationalCyberDirector from './OfficeOfNationalCyberDirector';
import PresidentialPersonnelOffice from './PresidentialPersonnelOffice';
import HouseFeed from './HouseFeed';
import FederalElectionCommissionNews from './FederalElectionCommissionNews';
import HouseCommitteeOnAppropriationsMaterials from './HouseCommitteeOnAppropriationsMaterials';
import HouseCommitteeToInvestigateTheCapitolMaterials from './HouseCommitteeToInvestigateTheCapitolMaterials';
import HouseCommitteeOnIntelligenceMaterials from './HouseCommitteeOnIntelligenceMaterials';
import HouseCommitteeOnIntelligence from './HouseCommitteeOnIntelligence';
import HouseCommitteeOnTheJudiciaryMaterials from './HouseCommitteeOnTheJudiciaryMaterials';
import HouseCommitteeOnTheBudgetMaterials from './HouseCommitteeOnTheBudgetMaterials';
import HouseCommitteeOnWaysAndMeans from './HouseCommitteeOnWaysAndMeans';
import HouseCommitteeOnWaysAndMeansMaterials from './HouseCommitteeOnWaysAndMeansMaterials';
import HouseCommitteeOnVeteransAffairsMaterials from './HouseCommitteeOnVeteransAffairsMaterials';
import HouseCommitteeOnTransportationAndInfrastructure from './HouseCommitteeOnTransportationAndInfrastructure';
import HouseCommitteeOnSmallBusinessMaterials from './HouseCommitteeOnSmallBusinessMaterials';
import HouseCommitteeOnSmallBusiness from './HouseCommitteeOnSmallBusiness';
import HouseCommitteeOnScienceSpaceAndTechnology from './HouseCommitteeOnScienceSpaceAndTechnology';
import HouseCommitteeOnScienceSpaceAndTechnologyMaterials from './HouseCommitteeOnScienceSpaceAndTechnologyMaterials';
import HouseCommitteeOnRulesMaterials from './HouseCommitteeOnRulesMaterials';
import HouseCommitteeOnRules from './HouseCommitteeOnRules';
import HouseCommitteeOnOversightAndReformMaterials from './HouseCommitteeOnOversightAndReformMaterials';
import HouseCommitteeOnHouseAdministrationMaterials from './HouseCommitteeOnHouseAdministrationMaterials';
import HouseCommitteeOnHomelandSecurityMaterials from './HouseCommitteeOnHomelandSecurityMaterials';
import HouseCommitteeOnForeignAffairsMaterials from './HouseCommitteeOnForeignAffairsMaterials';
import HouseCommitteeOnFinacialServicesMaterials from './HouseCommitteeOnFinacialServicesMaterials';
import HouseCommitteeOnEthicsMaterials from './HouseCommitteeOnEthicsMaterials';
import HouseCommitteeOnEnergyAndCommerceMaterials from './HouseCommitteeOnEnergyAndCommerceMaterials';
import HouseCommitteeOnEducationAndLaborMaterials from './HouseCommitteeOnEducationAndLaborMaterials';
import HouseCommitteeOnVeteransAffairs from './HouseCommitteeOnVeteransAffairs';
import HouseCommitteeToInvestigateTheCapitol from './HouseCommitteeToInvestigateTheCapitol';
import HouseComitteeOnAgricultureMaterials from './HouseComitteeOnAgricultureMaterials';
import HouseCommitteeOnArmedServicesMaterials from './HouseCommitteeOnArmedServicesMaterials';
import HouseCommitteeOnInterstateAndForeignCommerceMaterials from './HouseCommitteeOnInterstateAndForeignCommerceMaterials';
import HouseCommitteeOnTransportationAndInfrastructureMaterials from './HouseCommitteeOnTransportationAndInfrastructureMaterials';
import HouseCommitteeOnNaturalResourcesMaterials from './HouseCommitteeOnNaturalResourcesMaterials';
import GenerateFeed from './GenerateFeed';
import HouseOfRepsDisplay from './HouseOfRepsDisplay';


var collectionCounter = 0
var carouselInterval = 30000;
var mobileCarouselInterval = 30000;



  

 


  

export const HouseMobile = () =>   {
    render()
    
    {

      const [houseFloorModalIsOpen, setHouseFloorModalIsOpen] = useState(false)
      const [committeeOnAgModalIsOpen, setCommitteeOnAgModalIsOpen] = useState(false)
      const [agMaterialsModalIsOpen, setAgMaterialsModalIsOpen] = useState(false)
      const [committeeOnAppropriationsModalIsOpen, setCommitteeOnAppropriationsModalIsOpen] = useState(false)
      const [appropriationsMaterialsModalIsOpen, setAppropriationsMaterialsModalIsOpen] = useState(false)
      const [committeeOnArmedServicesModalIsOpen, setCommitteeOnArmedServicesModalIsOpen] = useState(false)
      const [armedServicesMaterialsModalIsOpen, setArmedServicesMaterialsModalIsOpen] = useState(false)
      const [committeeOnEducationAndLaborModalIsOpen, setCommitteeOnEducationAndLaborModalIsOpen] = useState(false)
      const [educationAndLaborMaterialsModalIsOpen, setEducationAndLaborMaterialsModalIsOpen] = useState(false)
      const [committeeOnEnergyAndCommerceModalIsOpen, setCommitteeOnEnergyAndCommerceModalIsOpen] = useState(false)
      const [energyAndCommerceMaterialsModalIsOpen, setEnergyAndCommerceMaterialsModalIsOpen] = useState(false)
      const [committeeOnEthicsModalIsOpen, setCommitteeOnEthicsModalIsOpen] = useState(false)
      const [ethicsMaterialsModalIsOpen, setEthicsMaterialsModalIsOpen] = useState(false)
      const [committeeOnFinancialServicesModalIsOpen, setCommitteeOnFinacialServicesModalIsOpen] = useState(false)
      const [financialServicesMaterialsModalIsOpen, setFinancialServicesMaterialsModalIsOpen] = useState(false)
      const [committeeOnForeignAffairsModalIsOpen, setCommitteeOnForeignAffairsModalIsOpen] = useState(false)
      const [foreignAffairsMaterialsModalIsOpen, setForeignAffairsMaterialsModalIsOpen] = useState(false)
      const [committeeOnHomelandSecurityModalIsOpen, setCommitteeOnHomelandSecurityModalIsOpen] = useState(false)
      const [homelandSecurityMaterialsModalIsOpen, setHomelandSecurityMaterialsModalIsOpen] = useState(false)
      const [committeeOnHouseAdministrationModalIsOpen, setCommitteeOnHouseAdministrationModalIsOpen] = useState(false)
      const [houseAdministrationMaterialsModalIsOpen, setHouseAdministrationMaterialsModalIsOpen] = useState(false)
      const [foreignCommerceMaterialsModalIsOpen, setForeignCommerceMaterialsModalIsOpen] = useState(false)
      const [committeeOnNaturalResourcesModalIsOpen, setCommitteeOnNaturalResourcesModalIsOpen] = useState(false)
      const [naturalResourcesMaterialsModalIsOpen, setNaturalResourcesMaterialsModalIsOpen] = useState(false)
      const [committeeOnOversightModalIsOpen, setCommitteeOnOversightModalIsOpen] = useState(false)
      const [oversightMaterialsModalIsOpen, setOversightMaterialsModalIsOpen] = useState(false)
      const [committeeOnRulesModalIsOpen, setCommitteeOnRulesModalIsOpen] = useState(false)
      const [rulesMaterialsModalIsOpen, setRulesMaterialsModalIsOpen] = useState(false)
      const [committeeOnScienceAndTechModalIsOpen, setCommitteeOnScienceAndTechModalIsOpen] = useState(false)
      const [scienceAndTechMaterialsModalIsOpen, setScienceAndTechMaterialsModalIsOpen] = useState(false)
      const [committeeOnSmallBusinessModalIsOpen, setCommitteeOnSmallBusinessModalIsOpen] = useState(false)
      const [smallBusinessMaterialsModalIsOpen, setSmallBusinessMaterialsModalIsOpen] = useState(false)
      const [committeeOnTransportationModalIsOpen, setCommitteeOnTransportationModalIsOpen] = useState(false)
      const [transportationMaterialsModalIsOpen, setTransportationMaterialsModalIsOpen] = useState(false)
      const [veteransAffairsModalIsOpen, setVeteransAffairsModalIsOpen] = useState(false)
      const [veteransAffairsMaterialsModalIsOpen, setVeteransAffairsMaterialsModalIsOpen] = useState(false)
      const [committeeOnWaysAndMeansModalIsOpen, setCommitteeOnWaysAndMeansModalIsOpen] = useState(false)
      const [waysAndMeansMaterialsModalIsOpen, setWaysAndMeansMaterialsModalIsOpen] = useState(false)
      const [committeeOnTheBudgetModalIsOpen, setCommitteeOnTheBudgetModalIsOpen] = useState(false)
      const [budgetMaterialsModalIsOpen, setBudgetMaterialsModalIsOpen] = useState(false)
      const [committeeOnTheJudiciaryModalIsOpen, setCommitteeOnTheJudiciaryModalIsOpen] = useState(false)
      const [judiciaryMaterialsModalIsOpen, setJudiciaryMaterialsModalIsOpen] = useState(false)
      const [committeeOnIntelligenceModalIsOpen, setCommitteeOnIntelligenceModalIsOpen] = useState(false)
      const [intelligenceMaterialsModalIsOpen, setIntelligenceMaterialsModalIsOpen] = useState(false)
      const [capitolCommitteeModalIsOpen, setCapitolCommitteeModalIsOpen] = useState(false)
      const [capitolMaterialsModalIsOpen, setCapitolMaterialsModalIsOpen] = useState(false)
      const [fullBillModalIsOpen, setFullBillModalIsOpen] = useState(false)

    var branch = "all";
  
    return(
       <>
   <MobileTopNavbar/>
  
  
  
  
  <div id='homebg'>

               
 
  <h5 id='branchHeadingMobile' onClick={() => window.open('/us/house', "_self")}>House of Representatives</h5>
            
          
 
            
            <Col>  
            <h5  onClick={() => window.open('/us/houseofreps', "_self")} id='presidentialFeedHeadingMobile'>Members of Congress<ChevronRight id='clickChevron'></ChevronRight></h5>
                        
                          
          <HouseOfRepsDisplay/>
                       </Col>    


  <Col>
  <h5 id='presidentialFeedHeadingMobile' onClick={() => setHouseFloorModalIsOpen(true)}>House Floor Activity Today</h5>
 <div id='houseFloor'></div>
                      
                      {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
             {CreateFeedItem('https://clerk.house.gov/Home/Feed', 'houseFloor', 'House Floor Activity Today', 'Legislative', 'N/A', HouseFloor )}

</Col>

<Modal id='fullBillModal' show={houseFloorModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setHouseFloorModalIsOpen(false)}>
<Modal.Header id='billModalHeader' closeButton><h3>House Floor Activity Today</h3></Modal.Header>

<HouseFloor/>
</Modal>

                 

              
      
                  <Col>
                  <h5 id='presidentialFeedHeadingMobile' onClick={() => setCommitteeOnAgModalIsOpen(true)}>Committee on Agriculture</h5>
 
                  
                     


 <div class='courtNewsMobile' id='houseComitteeOnAgriculture'></div>
      
     

                    <Modal id='fullBillModal' show={fullBillModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setFullBillModalIsOpen(false)}>
                      <Modal.Header id='billModalHeader' closeButton><h3>Committee on Agriculture</h3></Modal.Header>
                      <GenerateFeed url='&skip=0&schema=united_states_of_america&table=committee_on_agriculture_meeting_feed&order_by=created_at' 
                            name='Committee on Agriculture' branch='Legislative' topic='N/A' buttonName='Bill' />
                    </Modal>
    
                </Col>



                <Col>
                  <h5 id='presidentialFeedHeadingMobile' onClick={() => setAgMaterialsModalIsOpen(true)}>Committee on Agriculture Materials</h5>
 
                  
                     


                  <div class='courtNewsMobile' id='houseComitteeOnAgricultueMaterials'></div>
                          
                          {CreateFeedItemMaterials('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/houseCommittees/Committee%20on%20Agriculture.xml', 'houseComitteeOnAgricultueMaterials', 'House Committee on Agriculture Materials', 'Legislative', 'N/A', HouseComitteeOnAgricultureMaterials)}

 
                </Col>

                <Modal id='fullBillModal' show={agMaterialsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setAgMaterialsModalIsOpen(false)}>
<Modal.Header id='billModalHeader' closeButton><h3>Committee on Agriculture Materials</h3></Modal.Header>

<HouseComitteeOnAgricultureMaterials/>
</Modal>



                <Col>
                <h5 id='presidentialFeedHeadingMobile' onClick={() => setCommitteeOnAppropriationsModalIsOpen(true)}>Committee on Appropriations</h5>

<div class='courtNewsMobile'id='houseCommitteeOnAppropriations'></div>


{CreateFeedItem('https://docs.house.gov/Committee/RSS.ashx?Code=AP00', 'houseCommitteeOnAppropriations', 'House Committee on Appropriations', 'Legislative', 'N/A', HouseCommitteeOnAppropriations)}

    
                </Col>

                <Modal id='fullBillModal' show={committeeOnAppropriationsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCommitteeOnAppropriationsModalIsOpen(false)}>
<Modal.Header id='billModalHeader' closeButton><h3>Committee on Appropriations</h3></Modal.Header>

<HouseCommitteeOnAppropriations/>
</Modal>

          
          
                <Col>
                <h5 id='presidentialFeedHeadingMobile' onClick={() => setAppropriationsMaterialsModalIsOpen(true)}>Committee on Appropriations Materials</h5>
                          <div class='courtNewsMobile'id='houseCommitteeOnAppropriationsMaterials'></div>
                          
                          {CreateFeedItemMaterials('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/houseCommittees/Committee%20on%20Appropriations.xml', 'houseCommitteeOnAppropriationsMaterials', 'House Committee on Appropriations Materials', 'Legislative', 'N/A', HouseCommitteeOnAppropriationsMaterials)}

    
                </Col>

                <Modal id='fullBillModal' show={appropriationsMaterialsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setAppropriationsMaterialsModalIsOpen(false)}>
<Modal.Header id='billModalHeader' closeButton><h3>Committee on Appropriations Materials</h3></Modal.Header>

<HouseCommitteeOnAppropriationsMaterials/>
</Modal>


                <Col>
                <h5 id='presidentialFeedHeadingMobile'onClick={() => setCommitteeOnArmedServicesModalIsOpen(true)}>Committee on Armed Services</h5>
                        <div class='courtNewsMobile' id='houseComitteeOnArmedServices'></div>
                  
                        {CreateFeedItem('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/house_committee-xml/Committee_on_Armed_Services_Meeting_Feed.xml	', 'houseComitteeOnArmedServices', 'House Committee on Armed Services', 'Legislative', 'Foreign Affairs', HouseCommitteeOnArmedServices)}

                </Col>

                <Modal id='fullBillModal' show={committeeOnArmedServicesModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCommitteeOnArmedServicesModalIsOpen(false)}>
<Modal.Header id='billModalHeader' closeButton><h3>Committee on Armed Services</h3></Modal.Header>

<HouseCommitteeOnArmedServices/>
</Modal>

                <Col>
                        <div id='columnRightPadding'>
                        <h5 id='presidentialFeedHeadingMobile' onClick={() => setArmedServicesMaterialsModalIsOpen(true)}>Committee on Armed Services Materials</h5>
                          <div id='houseCommitteeOnArmedServicesMaterials'></div>
                          
                          {CreateFeedItemMaterials('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/houseCommittees/Committee%20on%20Armed%20Services.xml', 'houseCommitteeOnArmedServicesMaterials', 'House Committee on Armed Services Materials', 'Legislative', 'N/A', HouseCommitteeOnArmedServicesMaterials)}

     
                        </div>
                      </Col>

                      <Modal id='fullBillModal' show={armedServicesMaterialsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setArmedServicesMaterialsModalIsOpen(false)}>
<Modal.Header id='billModalHeader' closeButton><h3>Committee on Armed Services Materials</h3></Modal.Header>

<HouseCommitteeOnArmedServicesMaterials/>
</Modal>



                <Col>
                <h5 id='presidentialFeedHeadingMobile' onClick={() => setCommitteeOnEducationAndLaborModalIsOpen(true)}>Committee on Education and Labor</h5>
                        <div class='courtNewsMobile'id='houseCommitteeOnEducationAndLabor'></div>
                        
                        {CreateFeedItem('https://docs.house.gov/Committee/RSS.ashx?Code=ED00', 'houseCommitteeOnEducationAndLabor', 'House Committee on Education and Labor', 'Legislative', 'Economy', HouseCommitteeOnEducationAndLabor)}

                </Col>

                <Modal id='fullBillModal' show={committeeOnEducationAndLaborModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCommitteeOnEducationAndLaborModalIsOpen(false)}>
<Modal.Header id='billModalHeader' closeButton><h3>Committee on Education and Labor</h3></Modal.Header>

<HouseCommitteeOnEducationAndLabor/>
</Modal>



                <Col>
                <h5 id='presidentialFeedHeadingMobile' onClick={() => setEducationAndLaborMaterialsModalIsOpen(true)}>Committee on Education and Labor Materials</h5>
                          <div class='courtNewsMobile'id='houseCommitteeOnEducationAndLaborMaterials'></div>
                          
                          {CreateFeedItemMaterials('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/houseCommittees/Committee%20on%20Education%20and%20the%20Workforce.xml', 'houseCommitteeOnEducationAndLaborMaterials', 'House Committee on Education and Labor Materials', 'Legislative', 'Economy', HouseCommitteeOnEducationAndLaborMaterials)}

                </Col>

                <Modal id='fullBillModal' show={educationAndLaborMaterialsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setEducationAndLaborMaterialsModalIsOpen(false)}>
<Modal.Header id='billModalHeader' closeButton><h3>Committee on Education and Labor Materials</h3></Modal.Header>

<HouseCommitteeOnEducationAndLaborMaterials/>
</Modal>



                <Col>
                <h5 id='presidentialFeedHeadingMobile' onClick={() => setCommitteeOnEnergyAndCommerceModalIsOpen(true)}>Committee on Energy and Commerce</h5>
                        <div class='courtNewsMobile'id='houseCommitteeOnEnergyAndCommerce'></div>
                        
                        {CreateFeedItem('https://docs.house.gov/Committee/RSS.ashx?Code=IF00', 'houseCommitteeOnEnergyAndCommerce', 'House Committee on Energy and Commerce', 'Legislative', 'N/A', HouseCommitteeOnEnergyAndCommerce)}

                </Col>

                <Modal id='fullBillModal' show={committeeOnEnergyAndCommerceModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCommitteeOnEnergyAndCommerceModalIsOpen(false)}>
<Modal.Header id='billModalHeader' closeButton><h3>Committee on Energy and Commerce</h3></Modal.Header>

<HouseCommitteeOnEnergyAndCommerce/>
</Modal>




                <Col>
                <h5 id='presidentialFeedHeadingMobile' onClick={() => setEnergyAndCommerceMaterialsModalIsOpen(true)}>Committee on Energy and Commerce Materials</h5>
                        <div class='courtNewsMobile'id='houseCommitteeOnEnergyAndCommerceMaterials'></div>
                        
                        {CreateFeedItemMaterials('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/houseCommittees/Committee%20on%20Energy%20and%20Commerce.xml', 'houseCommitteeOnEnergyAndCommerceMaterials', 'House Committee on Energy and Commerce Materials', 'Legislative', 'N/A', HouseCommitteeOnEnergyAndCommerceMaterials)}

                </Col>

                <Modal id='fullBillModal' show={energyAndCommerceMaterialsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setEnergyAndCommerceMaterialsModalIsOpen(false)}>
<Modal.Header id='billModalHeader' closeButton><h3>Committee on Energy and Commerce Materials</h3></Modal.Header>

<HouseCommitteeOnEnergyAndCommerceMaterials/>
</Modal>


                <Col>
                <h5 id='presidentialFeedHeadingMobile' onClick={() => setCommitteeOnEthicsModalIsOpen(true)}>Committee on Ethics</h5>
                        <div class='courtNewsMobile'id='houseCommitteeOnEthics'></div>

                        {CreateFeedItem('https://docs.house.gov/Committee/RSS.ashx?Code=SO00', 'houseCommitteeOnEthics', 'House Committee on Ethics', 'Legislative', 'N/A', HouseCommitteeOnEthics)}

                </Col>

                <Modal id='fullBillModal' show={committeeOnEthicsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCommitteeOnEthicsModalIsOpen(false)}>
<Modal.Header id='billModalHeader' closeButton><h3>Committee on Ethics</h3></Modal.Header>

<HouseCommitteeOnEthics/>
</Modal>



                <Col>
                <h5 id='presidentialFeedHeadingMobile' onClick={() => setEthicsMaterialsModalIsOpen(true)}>Committee on Ethics Materials</h5>
                        <div class='courtNewsMobile'id='houseCommitteeOnEthicsMaterials'></div>

                        {CreateFeedItemMaterials('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/houseCommittees/Committee%20on%20Ethics.xml', 'houseCommitteeOnEthicsMaterials', 'House Committee on Ethics Materials', 'Legislative', 'N/A', HouseCommitteeOnEthicsMaterials)}

                </Col>

                <Modal id='fullBillModal' show={ethicsMaterialsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setEthicsMaterialsModalIsOpen(false)}>
<Modal.Header id='billModalHeader' closeButton><h3>Committee on Ethics Materials</h3></Modal.Header>

<HouseCommitteeOnEthicsMaterials/>
</Modal>


                <Col>
                <h5 id='presidentialFeedHeadingMobile' onClick={() => setCommitteeOnFinacialServicesModalIsOpen(true)}>Committee on Financial Services</h5>
                        <div class='courtNewsMobile' id='houseCommitteeOnFinancialServices'></div>
                      



                        {CreateFeedItem('https://docs.house.gov/Committee/RSS.ashx?Code=BA00', 'houseCommitteeOnFinancialServices', 'House Committee on Financial Services', 'Legislative', 'Economy', HouseCommitteeOnFinacialServices)}

                </Col>

                <Modal id='fullBillModal' show={committeeOnFinancialServicesModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCommitteeOnFinacialServicesModalIsOpen(false)}>
<Modal.Header id='billModalHeader' closeButton><h3>Committee on Financial Services</h3></Modal.Header>

<HouseCommitteeOnFinacialServices/>
</Modal>


                <Col>
                <h5 id='presidentialFeedHeadingMobile' onClick={() => setFinancialServicesMaterialsModalIsOpen(true)}>Committee on Financial Services Materials</h5>
                        <div class='courtNewsMobile'id='houseCommitteeOnFinancialServicesMaterials'></div>
                      



                        {CreateFeedItemMaterials('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/houseCommittees/Committee%20on%20Financial%20Services.xml', 'houseCommitteeOnFinancialServicesMaterials', 'House Committee on Financial Services Materials', 'Legislative', 'Economy', HouseCommitteeOnFinacialServicesMaterials)}

                </Col>

                <Modal id='fullBillModal' show={financialServicesMaterialsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setFinancialServicesMaterialsModalIsOpen(false)}>
<Modal.Header id='billModalHeader' closeButton><h3>Committee on Financial Services Materials</h3></Modal.Header>

<HouseCommitteeOnFinacialServicesMaterials/>
</Modal>


                <Col>
                <h5 id='presidentialFeedHeadingMobile' onClick={() => setCommitteeOnForeignAffairsModalIsOpen(true)}>Committee on Foreign Affairs</h5>
                        <div class='courtNewsMobile'id='houseCommitteeOnForeignAffairs'></div>

                        {CreateFeedItem('https://docs.house.gov/Committee/RSS.ashx?Code=FA00', 'houseCommitteeOnForeignAffairs', 'House Committee on Foreign Affairs', 'Legislative', 'Foreign Affairs', HouseCommitteeOnForeignAffairs)}

                </Col>

                <Modal id='fullBillModal' show={committeeOnForeignAffairsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCommitteeOnForeignAffairsModalIsOpen(false)}>
<Modal.Header id='billModalHeader' closeButton><h3>Committee on Foreign Affairs</h3></Modal.Header>

<HouseCommitteeOnForeignAffairs/>
</Modal>

                <Col>
                <h5 id='presidentialFeedHeadingMobile' onClick={() => setForeignAffairsMaterialsModalIsOpen(true)}>Committee on Foreign Affairs Materials</h5>
                        <div class='courtNewsMobile'id='houseCommitteeOnForeignAffairsMaterials'></div>
                     



                        {CreateFeedItemMaterials('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/houseCommittees/Committee%20on%20Foreign%20Affairs.xml', 'houseCommitteeOnForeignAffairsMaterials', 'House Committee on Foreign Affairs Materials', 'Legislative', 'Foreign Affairs', HouseCommitteeOnForeignAffairsMaterials)}

                </Col>

                <Modal id='fullBillModal' show={foreignAffairsMaterialsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setForeignAffairsMaterialsModalIsOpen(false)}>
<Modal.Header id='billModalHeader' closeButton><h3>Committee on Foreign Affairs Materials</h3></Modal.Header>

<HouseCommitteeOnForeignAffairsMaterials/>
</Modal>

                <Col>
                <h5 id='presidentialFeedHeadingMobile' onClick={() => setCommitteeOnHomelandSecurityModalIsOpen(true)}>Committee on Homeland Security</h5>
                        <div class='courtNewsMobile'id='houseCommitteeOnHomelandSecurity'></div>
                    


                        {CreateFeedItem('https://docs.house.gov/Committee/RSS.ashx?Code=HM00', 'houseCommitteeOnHomelandSecurity', 'House Committee on Homeland Security', 'Legislative', 'Foreign Affairs', HouseCommitteeOnHomelandSecurity)}


                </Col>

                <Modal id='fullBillModal' show={committeeOnHomelandSecurityModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCommitteeOnHomelandSecurityModalIsOpen(false)}>
<Modal.Header id='billModalHeader' closeButton><h3>Committee on Homeland Security</h3></Modal.Header>

<HouseCommitteeOnHomelandSecurity/>
</Modal>




                <Col>
                <h5 id='presidentialFeedHeadingMobile' onClick={() => setHomelandSecurityMaterialsModalIsOpen(true)}>Committee on Homeland Security Materials</h5>
                        <div class='courtNewsMobile'id='houseCommitteeOnHomelandSecurityMaterials'></div>
                    


                        {CreateFeedItemMaterials('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/houseCommittees/Committee%20on%20Homeland%20Security.xml', 'houseCommitteeOnHomelandSecurityMaterials', 'House Committee on Homeland Security Materials', 'Legislative', 'Foreign Affairs', HouseCommitteeOnHomelandSecurityMaterials)}


                </Col>

                <Modal id='fullBillModal' show={homelandSecurityMaterialsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setHomelandSecurityMaterialsModalIsOpen(false)}>
<Modal.Header id='billModalHeader' closeButton><h3>Committee on Homeland Security Materials</h3></Modal.Header>
<HouseCommitteeOnHomelandSecurityMaterials/>
</Modal>



                <Col>
                <h5 id='presidentialFeedHeadingMobile' onClick={() => setCommitteeOnHouseAdministrationModalIsOpen(true)}>Committee on House Administration</h5>
                        <div class='courtNewsMobile'id='houseCommitteeOnHouseAdministration'></div>
                      


                        {CreateFeedItem('https://docs.house.gov/Committee/RSS.ashx?Code=HA00', 'houseCommitteeOnHouseAdministration', 'House Committee on House Administration', 'Legislative', 'N/A', HouseCommitteeOnHouseAdministration)}


                </Col>

                <Modal id='fullBillModal' show={committeeOnHouseAdministrationModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCommitteeOnHouseAdministrationModalIsOpen(false)}>
<Modal.Header id='billModalHeader' closeButton><h3>Committee on House Administration</h3></Modal.Header>

<HouseCommitteeOnHouseAdministration/>
</Modal>



                <Col>
                <h5 id='presidentialFeedHeadingMobile' onClick={() => setHouseAdministrationMaterialsModalIsOpen(true)}>Committee on House Administration Materials</h5>
                        <div class='courtNewsMobile'id='houseCommitteeOnHouseAdministrationMaterials'></div>
                      


                        {CreateFeedItemMaterials('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/houseCommittees/Committee%20on%20House%20Administration.xml', 'houseCommitteeOnHouseAdministrationMaterials', 'House Committee on House Administration Materials', 'Legislative', 'N/A', HouseCommitteeOnHouseAdministrationMaterials)}


                </Col>

                <Modal id='fullBillModal' show={houseAdministrationMaterialsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setHouseAdministrationMaterialsModalIsOpen(false)}>
<Modal.Header id='billModalHeader' closeButton><h3>Committee on House Administration Materials</h3></Modal.Header>

<HouseCommitteeOnHouseAdministrationMaterials/>
</Modal>


                <Col>
                <h5 id='presidentialFeedHeadingMobile' onClick={() => setForeignCommerceMaterialsModalIsOpen(true)}>Committee on Interstate and Foreign Commerce Materials</h5>
                        <div class='courtNewsMobile'id='houseCommitteeOnInterstateAndForeignCommerceMaterials'></div>
                      


                        {CreateFeedItemMaterials('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/houseCommittees/Committee%20on%20Interstate%20and%20Foreign%20Commerce.xml', 'houseCommitteeOnInterstateAndForeignCommerceMaterials', 'House Committee on Interstate and Foreign Commerce Materials', 'Legislative', 'N/A', HouseCommitteeOnInterstateAndForeignCommerceMaterials)}


                </Col>

                <Modal id='fullBillModal' show={foreignCommerceMaterialsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setForeignCommerceMaterialsModalIsOpen(false)}>
<Modal.Header id='billModalHeader' closeButton><h3>Committee on Interstate and Foreign Commerce Materials</h3></Modal.Header>

<HouseCommitteeOnInterstateAndForeignCommerceMaterials/>
</Modal>

                <Col>
                    
                <h5 id='presidentialFeedHeadingMobile' onClick={() => setCommitteeOnNaturalResourcesModalIsOpen(true)}>Committee on Natural Resources</h5>
                        <div class='courtNewsMobile'id='houseCommitteeOnNaturalResources'></div>
                  



                        {CreateFeedItem('https://docs.house.gov/Committee/RSS.ashx?Code=II00', 'houseCommitteeOnNaturalResources', 'House Committee on Natural Resources', 'Legislative', 'Environment', HouseCommitteeOnNaturalResources)}


                </Col>

                <Modal id='fullBillModal' show={committeeOnNaturalResourcesModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCommitteeOnNaturalResourcesModalIsOpen(false)}>
<Modal.Header id='billModalHeader' closeButton><h3>Committee on Natural Resources</h3></Modal.Header>

<HouseCommitteeOnNaturalResources/>
</Modal>

                <Col>
                    
                <h5 id='presidentialFeedHeadingMobile' onClick={() => setNaturalResourcesMaterialsModalIsOpen(true)}>Committee on Natural Resources Materials</h5>
                        <div class='courtNewsMobile'id='houseCommitteeOnNaturalResourcesMaterials'></div>
                  



                        {CreateFeedItemMaterials('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/houseCommittees/Committee%20on%20Natural%20Resources.xml', 'houseCommitteeOnNaturalResourcesMaterials', 'House Committee on Natural Resources Materials', 'Legislative', 'Environment', HouseCommitteeOnNaturalResourcesMaterials)}

    
                    </Col>

                    <Modal id='fullBillModal' show={naturalResourcesMaterialsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setNaturalResourcesMaterialsModalIsOpen(false)}>
<Modal.Header id='billModalHeader' closeButton><h3>Committee on Natural Resources Materials</h3></Modal.Header>

<HouseCommitteeOnNaturalResourcesMaterials/>
</Modal>


                    <Col>
                    
                    
                    <h5 id='presidentialFeedHeadingMobile' onClick={() => setCommitteeOnOversightModalIsOpen(true)}>Committee on Oversight and Reform</h5>
                        <div class='courtNewsMobile'id='houseCommitteeOnOversightAndReform'></div>
                  



                        {CreateFeedItem('https://docs.house.gov/Committee/RSS.ashx?Code=GO00', 'houseCommitteeOnOversightAndReform', 'House Committee on Oversight and Reform', 'Legislative', 'N/A', HouseCommitteeOnOversightAndReform)}

        
                        </Col>

                        <Modal id='fullBillModal' show={committeeOnOversightModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCommitteeOnOversightModalIsOpen(false)}>
<Modal.Header id='billModalHeader' closeButton><h3>Committee on Oversight and Reform</h3></Modal.Header>

<HouseCommitteeOnOversightAndReform/>
</Modal>


                        <Col>
                    
                    
                        <h5 id='presidentialFeedHeadingMobile' onClick={() => setOversightMaterialsModalIsOpen(true)} >Committee on Oversight and Reform Materials</h5>
                        <div class='courtNewsMobile'id='houseCommitteeOnOversightAndReformMaterials'></div>
                  



                        {CreateFeedItemMaterials('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/houseCommittees/Committee%20on%20Oversight%20and%20Accountability.xml', 'houseCommitteeOnOversightAndReformMaterials', 'House Committee on Oversight and Reform Materials', 'Legislative', 'N/A', HouseCommitteeOnOversightAndReformMaterials)}

        
                        </Col>

                        <Modal id='fullBillModal' show={oversightMaterialsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setOversightMaterialsModalIsOpen(false)}>
<Modal.Header id='billModalHeader' closeButton><h3>Committee on Oversight and Reform Materials</h3></Modal.Header>

<HouseCommitteeOnOversightAndReformMaterials/>
</Modal>

                        <Col>
                    
                    
                        <h5 id='presidentialFeedHeadingMobile' onClick={() => setCommitteeOnRulesModalIsOpen(true)} >Committee on Rules</h5>
                        <div class='courtNewsMobile'id='houseCommitteeOnRules'></div>
                      



                        {CreateFeedItem('https://docs.house.gov/Committee/RSS.ashx?Code=RU00', 'houseCommitteeOnRules', 'House Committee on Rules', 'Legislative', 'N/A', HouseCommitteeOnRules)}

    
                    </Col>

                    <Modal id='fullBillModal' show={committeeOnRulesModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCommitteeOnRulesModalIsOpen(false)}>
<Modal.Header id='billModalHeader' closeButton><h3>Committee on Rules</h3></Modal.Header>

<HouseCommitteeOnRules/>
</Modal>

                    <Col>
                    
                    <h5 id='presidentialFeedHeadingMobile' onClick={() => setRulesMaterialsModalIsOpen(true)} >House Committee on Rules Materials</h5>
                        <div class='courtNewsMobile'id='houseCommitteeOnRulesMaterials'></div>
                      



                        {CreateFeedItemMaterials('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/houseCommittees/Committee%20on%20Rules.xml', 'houseCommitteeOnRulesMaterials', 'House Committee on Rules Materials', 'Legislative', 'N/A', HouseCommitteeOnRulesMaterials)}


                </Col>

                <Modal id='fullBillModal' show={rulesMaterialsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setRulesMaterialsModalIsOpen(false)}>
<Modal.Header id='billModalHeader' closeButton><h3>Committee on Rules Materials</h3></Modal.Header>

<HouseCommitteeOnRulesMaterials/>
</Modal>


                <Col>
                    
                <h5 id='presidentialFeedHeadingMobile' onClick={() => setCommitteeOnScienceAndTechModalIsOpen(true)}>Committee on Science, Space, and Technology</h5>
                        <div class='courtNewsMobile'id='houseCommitteeOnScienceSpaceAndTechnology'></div>
                       
                        {CreateFeedItem('https://docs.house.gov/Committee/RSS.ashx?Code=SY00', 'houseCommitteeOnScienceSpaceAndTechnology', 'House Committee on Science, Space, and Technology', 'Legislative', 'Science & Tech', HouseCommitteeOnScienceSpaceAndTechnology)}



                </Col>

                <Modal id='fullBillModal' show={committeeOnScienceAndTechModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCommitteeOnScienceAndTechModalIsOpen(false)}>
<Modal.Header id='billModalHeader' closeButton><h3>Committee on Science, Space, and Technology</h3></Modal.Header>

<HouseCommitteeOnScienceSpaceAndTechnology/>
</Modal>


                <Col>
                    
                <h5 id='presidentialFeedHeadingMobile' onClick={() => setScienceAndTechMaterialsModalIsOpen(true)}>Committee on Science, Space, and Technology Materials</h5>
                        <div class='courtNewsMobile'id='houseCommitteeOnScienceSpaceAndTechnologyMaterials'></div>
                       
                        {CreateFeedItemMaterials('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/houseCommittees/Committee%20on%20Science,%20Space,%20and%20Technology.xml', 'houseCommitteeOnScienceSpaceAndTechnologyMaterials', 'House Committee on Science, Space, and Technology Materials', 'Legislative', 'Science & Tech', HouseCommitteeOnScienceSpaceAndTechnologyMaterials)}

    
    
                    </Col>

                    <Modal id='fullBillModal' show={scienceAndTechMaterialsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setScienceAndTechMaterialsModalIsOpen(false)}>
<Modal.Header id='billModalHeader' closeButton><h3>Committee on Science, Space, and Technology Materials</h3></Modal.Header>

<HouseCommitteeOnScienceSpaceAndTechnologyMaterials/>
</Modal>





                <Col>
                    
                <h5 id='presidentialFeedHeadingMobile' onClick={() => setCommitteeOnSmallBusinessModalIsOpen(true)}>Committee on Small Business</h5>
                        <div class='courtNewsMobile'id='houseCommitteeOnSmallBusiness'></div>
           
                        {CreateFeedItem('https://docs.house.gov/Committee/RSS.ashx?Code=SM00', 'houseCommitteeOnSmallBusiness', 'House Committee on Small Business', 'Legislative', 'Economy', HouseCommitteeOnSmallBusiness)}

                        </Col>

                        <Modal id='fullBillModal' show={committeeOnSmallBusinessModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCommitteeOnSmallBusinessModalIsOpen(false)}>
<Modal.Header id='billModalHeader' closeButton><h3>Committee on Small Business</h3></Modal.Header>

<HouseCommitteeOnSmallBusiness/>
</Modal>



                        <Col>
                    
                      <h5 id='presidentialFeedHeadingMobile' onClick={() => setSmallBusinessMaterialsModalIsOpen(true)}>Committee on Small Business Materials</h5>
                        <div class='courtNewsMobile'id='houseCommitteeOnSmallBusinessMaterials'></div>
           
                        {CreateFeedItemMaterials('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/houseCommittees/Committee%20on%20Small%20Business.xml', 'houseCommitteeOnSmallBusinessMaterials', 'House Committee on Small Business Materials', 'Legislative', 'Economy', HouseCommitteeOnSmallBusinessMaterials)}

                            </Col>

                            <Modal id='fullBillModal' show={smallBusinessMaterialsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => smallBusinessMaterialsModalIsOpen(false)}>
<Modal.Header id='billModalHeader' closeButton><h3>Committee on Small Business Materials</h3></Modal.Header>

<HouseCommitteeOnSmallBusinessMaterials/>
</Modal>


                            <Col>
                    
                            <h5 id='presidentialFeedHeadingMobile' onClick={() =>  setCommitteeOnTransportationModalIsOpen(true)}>Committee on Transportation and Infrastructure</h5>
                        <div class='courtNewsMobile'id='houseCommitteeOnTransportationAndInfrastructure'></div>
                       

                        {CreateFeedItem('https://docs.house.gov/Committee/RSS.ashx?Code=PW00', 'houseCommitteeOnTransportationAndInfrastructure', 'House Committee on Transportation and Infrastructure', 'Legislative', 'Environment', HouseCommitteeOnTransportationAndInfrastructure)}

                          </Col>

                          <Modal id='fullBillModal' show={committeeOnTransportationModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCommitteeOnTransportationModalIsOpen(false)}>
<Modal.Header id='billModalHeader' closeButton><h3>Committee on Transportation and Infrastructure</h3></Modal.Header>

<HouseCommitteeOnTransportationAndInfrastructure/>
</Modal>



                          <Col>
                    
                          <h5 id='presidentialFeedHeadingMobile' onClick={() =>  setTransportationMaterialsModalIsOpen(true)}>Committee on Transportation and Infrastructure Materials</h5>
                        <div class='courtNewsMobile'id='houseCommitteeOnTransportationAndInfrastructureMaterials'></div>
                       

                        {CreateFeedItemMaterials('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/houseCommittees/Committee%20on%20Transportation%20and%20Infrastructure.xml', 'houseCommitteeOnTransportationAndInfrastructureMaterials', 'House Committee on Transportation and Infrastructure Materials', 'Legislative', 'Environment', HouseCommitteeOnTransportationAndInfrastructureMaterials)}

                  </Col>

                  <Modal id='fullBillModal' show={transportationMaterialsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTransportationMaterialsModalIsOpen(false)}>
<Modal.Header id='billModalHeader' closeButton><h3>Committee on Transportation and Infrastructure Materials</h3></Modal.Header>

<HouseCommitteeOnTransportationAndInfrastructureMaterials/>
</Modal>


                  <Col>
                    
                  <h5 id='presidentialFeedHeadingMobile' onClick={() => setVeteransAffairsModalIsOpen(true)}>Committee on Veterans' Affairs</h5>
                        <div class='courtNewsMobile'id='houseCommitteeOnVeteransAffairs'></div>
                       



                        {CreateFeedItem('https://docs.house.gov/Committee/RSS.ashx?Code=VR00', 'houseCommitteeOnVeteransAffairs', 'House Committee on Veterans Affairs', 'Legislative', 'N/A', HouseCommitteeOnVeteransAffairs)}

            </Col>

            <Modal id='fullBillModal' show={veteransAffairsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setVeteransAffairsModalIsOpen(false)}>
<Modal.Header id='billModalHeader' closeButton><h3>Committee on Veterans' Affairs</h3></Modal.Header>

<HouseCommitteeOnVeteransAffairs/>
</Modal>



            <Col>
                    
            <h5 id='presidentialFeedHeadingMobile' onClick={() => setVeteransAffairsMaterialsModalIsOpen(true)}>Committee on Veterans' Affairs Materials</h5>
                        <div class='courtNewsMobile'id='houseCommitteeOnVeteransAffairsMaterials'></div>
                       



                        {CreateFeedItemMaterials('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/houseCommittees/Committee%20on%20Veterans%27%20Affairs.xml', 'houseCommitteeOnVeteransAffairsMaterials', 'House Committee on Veterans Affairs Materials' , 'Legislative', 'N/A', HouseCommitteeOnVeteransAffairsMaterials)}

              </Col>

              <Modal id='fullBillModal' show={veteransAffairsMaterialsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setVeteransAffairsMaterialsModalIsOpen(false)}>
<Modal.Header id='billModalHeader' closeButton><h3>Committee on Veterans' Affairs Materials</h3></Modal.Header>

<HouseCommitteeOnVeteransAffairsMaterials/>
</Modal>

              <Col>
                    
              <h5 id='presidentialFeedHeadingMobile' onClick={() => setCommitteeOnWaysAndMeansModalIsOpen(true)}>Committee on Ways and Means</h5>
                        <div class='courtNewsMobile'id='houseCommitteeOnWaysAndMeans'></div>
                       
                        {CreateFeedItem('https://docs.house.gov/Committee/RSS.ashx?Code=WM00', 'houseCommitteeOnWaysAndMeans', 'House Committee on Ways and Means', 'Legislative', 'N/A', HouseCommitteeOnWaysAndMeans)}

              </Col>

              <Modal id='fullBillModal' show={committeeOnWaysAndMeansModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCommitteeOnWaysAndMeansModalIsOpen(false)}>
<Modal.Header id='billModalHeader' closeButton><h3>Committee on Ways and Means</h3></Modal.Header>

<HouseCommitteeOnWaysAndMeans/>
</Modal>

              <Col>
                    
              <h5 id='presidentialFeedHeadingMobile' onClick={() => setWaysAndMeansMaterialsModalIsOpen(true)}>Committee on Ways and Means Materials</h5>
                        <div class='courtNewsMobile'id='houseCommitteeOnWaysAndMeansMaterials'></div>
                       
                        {CreateFeedItemMaterials('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/houseCommittees/Committee%20on%20Ways%20and%20Means.xml', 'houseCommitteeOnWaysAndMeansMaterials', 'House Committee on Ways and Means Materials', 'Legislative', 'N/A', HouseCommitteeOnWaysAndMeansMaterials)}


                    </Col>

                    <Modal id='fullBillModal' show={waysAndMeansMaterialsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setWaysAndMeansMaterialsModalIsOpen(false)}>
<Modal.Header id='billModalHeader' closeButton><h3>Committee on Ways and Means Materials</h3></Modal.Header>

<HouseCommitteeOnWaysAndMeansMaterials/>
</Modal>


                    <Col>
                    
                    <h5 id='presidentialFeedHeadingMobile' onClick={() => setCommitteeOnTheBudgetModalIsOpen(true)}>Committee on the Budget</h5>
                        <div class='courtNewsMobile'id='houseCommitteeOnTheBudget'></div>
                       
                        {CreateFeedItem('https://docs.house.gov/Committee/RSS.ashx?Code=BU00', 'houseCommitteeOnTheBudget', 'House Committee on the Budget', 'Legislative', 'Economy', HouseCommitteeOnTheBudget)}

      
                          </Col>

                          <Modal id='fullBillModal' show={committeeOnTheBudgetModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCommitteeOnTheBudgetModalIsOpen(false)}>
<Modal.Header id='billModalHeader' closeButton><h3>Committee on the Budget</h3></Modal.Header>

<HouseCommitteeOnTheBudget/>
</Modal>
  
    
                          <Col>
                    
                          <h5 id='presidentialFeedHeadingMobile' onClick={() => setBudgetMaterialsModalIsOpen(true)}>Committee on the Budget Materials</h5>
                        <div class='courtNewsMobile'id='houseCommitteeOnTheBudgetMaterials'></div>
                       
                        {CreateFeedItemMaterials('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/houseCommittees/Committee%20on%20the%20Budget.xml', 'houseCommitteeOnTheBudgetMaterials', 'House Committee on the Budget Materials', 'Legislative', 'Economy', HouseCommitteeOnTheBudgetMaterials)}

      
                          </Col>
                          <Modal id='fullBillModal' show={budgetMaterialsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setBudgetMaterialsModalIsOpen(false)}>
<Modal.Header id='billModalHeader' closeButton><h3>Committee on the Budget Materials</h3></Modal.Header>

<HouseCommitteeOnTheBudgetMaterials/>
</Modal>
  

                          <Col>
                    
                          <h5 id='presidentialFeedHeadingMobile' onClick={() => setCommitteeOnTheJudiciaryModalIsOpen(true)}>Committee on the Judiciary</h5>
                        <div class='courtNewsMobile'id='houseCommitteeOnTheJudiciary'></div>
                      

                        {CreateFeedItem('https://docs.house.gov/Committee/RSS.ashx?Code=JU00', 'houseCommitteeOnTheJudiciary', 'House Committee on the Judiciary', 'Legislative', 'N/A', HouseCommitteeOnTheJudiciary)}

      
                          </Col>

                          <Modal id='fullBillModal' show={committeeOnTheJudiciaryModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCommitteeOnTheJudiciaryModalIsOpen(false)}>
<Modal.Header id='billModalHeader' closeButton><h3>Committee on the Judiciary</h3></Modal.Header>

<HouseCommitteeOnTheJudiciary/>
</Modal>
  



                          <Col>
                          <h5 id='presidentialFeedHeadingMobile' onClick={() => setJudiciaryMaterialsModalIsOpen(true)}>Committee on the Judiciary Materials</h5>
                        <div class='courtNewsMobile'id='houseCommitteeOnTheJudiciaryMaterials'></div>
                      

                        {CreateFeedItemMaterials('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/houseCommittees/Committee%20on%20the%20Judiciary.xml', 'houseCommitteeOnTheJudiciaryMaterials', 'House Committee on the Judiciary Materials', 'Legislative', 'N/A', HouseCommitteeOnTheJudiciaryMaterials)}

      
                          </Col>

                          <Modal id='fullBillModal' show={judiciaryMaterialsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setJudiciaryMaterialsModalIsOpen(false)}>
<Modal.Header id='billModalHeader' closeButton><h3>Committee on the Judiciary Materials</h3></Modal.Header>

<HouseCommitteeOnTheJudiciaryMaterials/>
</Modal>


                          <Col>
                          <h5 id='presidentialFeedHeadingMobile' onClick={() => setCommitteeOnIntelligenceModalIsOpen(true)}>Permanent Select Committee on Intelligence</h5>
                        <div class='courtNewsMobile'id='houseCommitteeOnIntelligence'></div>
                      


                        {CreateFeedItem('https://docs.house.gov/Committee/RSS.ashx?Code=IG00', 'houseCommitteeOnIntelligence', 'Permanent Select Committee on Intelligence', 'Legislative', 'N/A', HouseCommitteeOnIntelligence)}

      
                          </Col>

                          <Modal id='fullBillModal' show={committeeOnIntelligenceModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCommitteeOnIntelligenceModalIsOpen(false)}>
<Modal.Header id='billModalHeader' closeButton><h3>Permanent Select Committee on Intelligence</h3></Modal.Header>

<HouseCommitteeOnIntelligence/>
</Modal>


                          <Col>
                          <h5 id='presidentialFeedHeadingMobile' onClick={() => setIntelligenceMaterialsModalIsOpen(true)}>Permanent Select Committee on Intelligence Materials</h5>
                        <div class='courtNewsMobile'id='houseCommitteeOnIntelligenceMaterials'></div>
                      


                        {CreateFeedItemMaterials('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/houseCommittees/Permanent%20Select%20Committee%20on%20Intelligence.xml', 'houseCommitteeOnIntelligenceMaterials', 'Permanent Select Committee on Intelligence Materials', 'Legislative', 'N/A', HouseCommitteeOnIntelligenceMaterials)}

      
                          </Col>

                          <Modal id='fullBillModal' show={intelligenceMaterialsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setIntelligenceMaterialsModalIsOpen(false)}>
<Modal.Header id='billModalHeader' closeButton><h3>Permanent Select Committee on Intelligence Materials</h3></Modal.Header>

<HouseCommitteeOnIntelligenceMaterials/>
</Modal>


                          <Col>
                          <h5 id='presidentialFeedHeadingMobile' onClick={() => setCapitolCommitteeModalIsOpen(true)}>Select Committee to Investigate the January 6th Attack on the United States Capitol</h5>
                        <div class='courtNewsMobile'id='selectCommitteeToInvestigate'></div>
                      


                        {CreateFeedItem('https://docs.house.gov/Committee/RSS.ashx?Code=IJ00', 'selectCommitteeToInvestigate', 'Select Committee to Investigate the January 6th Attack on the United States Capitol', 'Legislative', 'N/A', HouseCommitteeToInvestigateTheCapitol)}



      
                          </Col>

                          <Modal id='fullBillModal' show={capitolCommitteeModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCapitolCommitteeModalIsOpen(false)}>
<Modal.Header id='billModalHeader' closeButton><h3>Select Committee to Investigate the January 6th Attack on the United States Capitol</h3></Modal.Header>

<HouseCommitteeToInvestigateTheCapitol/>
</Modal>


                          <Col>
                          <h5 id='presidentialFeedHeadingMobile' onClick={() => setCapitolMaterialsModalIsOpen(true)}>Select Committee to Investigate the January 6th Attack on the United States Capitol Materials</h5>
                        <div class='courtNewsMobile'id='selectCommitteeToInvestigateMaterials'></div>
                      


                        {CreateFeedItemMaterials('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/houseCommittees/Select%20Committee%20to%20Investigate%20the%20January%206th%20Attack%20on%20the%20United%20States%20Capitol.xml', 'selectCommitteeToInvestigateMaterials', 'Select Committee to Investigate the January 6th Attack on the United States Capitol Materials', 'Legislative', 'N/A', HouseCommitteeToInvestigateTheCapitolMaterials)}


                        <Modal id='fullBillModal' show={capitolMaterialsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCapitolMaterialsModalIsOpen(false)}>
<Modal.Header id='billModalHeader' closeButton><h3>Select Committee to Investigate the January 6th Attack on the United States Capitol Materials</h3></Modal.Header>

<HouseCommitteeToInvestigateTheCapitolMaterials/>
</Modal>

      
                          </Col>

<Col>



</Col>



       <br></br>   


            <br></br><br></br><br></br><br></br><br></br><br></br>
            
            <Navbar id='navbarMobileBottom' fixed="bottom">    

<div id='changeBranchDropdownMobile'>

           <MobileOffCanvas/>
              
            </div>

</Navbar>
          </div>

          </>
  )
}
 }



  






export default HouseMobile;

